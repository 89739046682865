import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

const Shareholders = ({
    t,
    holders
}) => {

    const dateUtils = new DateUtils();

    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('e')}) ${t('Shareholders')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Share in')} %</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Date of birth')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Country of Residence')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('UBO')}(Y/N)</Text>
                    </View>
                </View>

                {(holders?.individual || []).map((shareholder, i) => {
                    const rowStyle = {
                        ...styles.tableRow
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                {`${shareholder.title} ${shareholder.firstName} ${shareholder.lastName}`}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.percentage}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {dateUtils.convertDateToDate(shareholder.birthDay, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.residenceCountry.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.isUbo ? 'Y' : 'N'}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Company Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Share in')} %</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Founded On')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Country')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Ultimate Parent')} (Y/N)</Text>
                    </View>
                </View>

                {(holders?.company || []).map((shareholder, i) => {
                    const rowStyle = {
                        ...styles.tableRow
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.companyName}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.percentage}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {dateUtils.convertDateToDate(shareholder.foundingDate, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.country.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.isUbo ? 'Y' : 'N'}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
};

export default withNamespaces()(Shareholders);

