import axios from 'axios';

import {
    API_BASE_URL,
    API_URL_FETCH_COUNTRIES,
    API_URL_FETCH_LANGUAGES,
    API_URL_CREATE_THIRD_PARTY,
    API_URL_CREATE_THIRD_PARTY_WITH_CSV,
    API_URL_CHECK_THIRD_PARTY_EXISTENCE,
    API_URL_CHECK_THIRD_PARTY_LICENCE_EXISTENCE,
    API_URL_CHECK_OWN_COMPANY_LICENCE_EXISTENCE
} from '../../constants/index';

export default class ThirdpartyUsersService {
	static instance;
	resource = `${API_BASE_URL}`;

	static getInstance() {
		if (!ThirdpartyUsersService.instance) {
			ThirdpartyUsersService.instance = new ThirdpartyUsersService();
		}

		return ThirdpartyUsersService.instance;
	}

    async list(params = {}){
        const response = await axios.get(`${this.resource}/organization/third_parties`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });

        return response.data.data
    } 

    async create(payload){
        const raw = axios.create();
        const response = await raw.post(API_URL_CREATE_THIRD_PARTY, payload,
            {
                headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
                },
            }
        );

        return response.data.data
    }

    async fetchCountries() {
        const response = await axios.post(API_URL_FETCH_COUNTRIES,
            {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
            }
        );

        return response.data.data
    }

    async fetchLanguages() {
        const response = await axios.get(API_URL_FETCH_LANGUAGES,
            {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
            }
        );
        return response.data.data
    }

    async uploadCSV(formData) {
        const response = await axios.post(API_URL_CREATE_THIRD_PARTY_WITH_CSV, formData, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
            },
        });

        return response.data.data
    }

    async checkThirdpartyExistence(payload) {
        const response = await axios.post(API_URL_CHECK_THIRD_PARTY_EXISTENCE,
            payload,
            {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
            }
        );

        return response.data.data
    }
    
    async checkThirdpartyLicenceExistence() {
        const response = await axios.post(API_URL_CHECK_THIRD_PARTY_LICENCE_EXISTENCE,
            {},
            {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
            }
        );

        return response.data.data
    }

    async checkOwnCompanyLicenseExistence() {
        const response = await axios.post(API_URL_CHECK_OWN_COMPANY_LICENCE_EXISTENCE,
            {},
            {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
            }
        );

        return response.data.data
    }
}
