import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from "react-router-dom";
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Navigator from "src/modules/3rd-party-management/components/navigator";
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import paginationFactory, {
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { 
    Col, 
    Container, 
    Row,
    Card,
    CardBody,
    Button,
    Spinner,
    UncontrolledTooltip
} from 'reactstrap';
import DateUtils from 'src/services/utils/DateUtils';
import { EMPTY_LIST } from 'src/common/constants';
import  { INTERNATIONAL_DATE_FORMAT } from 'src/common/constants';
import CreateAndEditModal from './components/CreateAndEditModal';
import InUsedThirdPartiesModal from './components/InUsedThirdPartiesModal';
import WithdrawRequestsModal from './components/WithdrawRequestsModal';
import DeleteQuestionnaireModal from './components/DeleteQuestionnaireModal';
import { QuestionnaireStatuses } from 'src/modules/3rd-party-management/constants';
import DeactivateQuestionnaireModal from './components/DeactivateQuestionnaireModal';
import ActivateQuestionnaireModal from './components/ActivateQuestionnaireModal';
// import FilteringComponent from './components/FilteringComponent';

const TPDDQuestionnaires = ({
    t,
    lng
}) => {
    const history = useHistory();
    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25
    });
    const [ showCreateAndEditModal, setShowCreateAndEditModal ] = useState(false);
    const [ selectedQuestionnaireToEdit, setSelectedQustionnaireToEdit ] = useState(null);
    const [ showInUsed, setShowInUsed ] = useState(null);
    const [ itemToWithdraw, setItemToWithdraw ] = useState(null);
    const [ itemToDelete, setItemToDelete ] = useState(null);
    const [ itemToDeactivate, setItemToDeactivate ] = useState(null);
    const [ itemToActivate, setItemToActivate ] = useState(null);
    const dateUtils = new DateUtils();

    const toggleQuestionnaireActivation = (item) => {
        const {
            id,
            status: currentStatus
        } = item;

        if(currentStatus === 'active'){
            setItemToDeactivate(item)
        }        
        else{
            setItemToActivate(item)
            mutateActivation(id)
        }
    }

    const {
        data: questionnairesList,
        isFetching: questionnairesAreFetching,
        isFetched: questionnairesAreFetched,
        refetch: refetchQuestionnaires
    } = useQuery({
        queryKey: [
            "3rd-party-management-fetch-questionnaires-list-query"
        ],
        queryFn: async () => {
            const service = QuestionnaireDesignerService.getInstance();

            return await service.fetchList();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t("An error occurred while fetching questionnaires list."), {
                type: "error"
            });
        }
    });

    const {
        mutate: mutateActivation
    } = useMutation({
        mutationFn: async (id) => {
            const service = QuestionnaireDesignerService.getInstance();
            return await service.activateQuestionnaire(
                id
            );
        },
        onSuccess: () => {
            toast(t("The questionnaire has been successfully activated."), {
                type: "success"
            });
            refetchQuestionnaires();
        },
        onError: () => {
            toast(t("Failed to activate the questionnaire."), {
                type: "error"
            });
        },
        onSettled: () => {
            setItemToActivate(null);
        }
    });

    const NoDataIndication = () => {
        if(questionnairesAreFetched && !questionnairesList?.length){
            return (
                <div className="alert m-0" role="alert">
                    <p className='text-center m-0'>
                        {t(EMPTY_LIST)}
                    </p>
                </div>
            )
        }

        return <></>
    }

    useEffect(() => {
        if(selectedQuestionnaireToEdit){
            setShowCreateAndEditModal(true);
        }
    }, [ selectedQuestionnaireToEdit ]);

    useEffect(() => {
        if(!showCreateAndEditModal){
            setSelectedQustionnaireToEdit(null)
        }
    }, [ showCreateAndEditModal ]);

    const tableColumns = [
        {
            dataField: "secondaryId",
            text: "ID",
            sort: true,
            key: 1,
            style:{
                width : '80px'
            },
            formatter: (cellContent, {
                type
            }) => {
                if(type !== 'custom') {
                    return '#'
                }
                return cellContent
            }
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            key: 2,
            style:{
                width : '180px'
            },
            formatter: (cellContent) => {
                return t(cellContent)
            }
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            key: 3,
            style:{
                width : '120px'
            },
            formatter: (cellContent) => {
                const statusInfos = QuestionnaireStatuses[cellContent];

                return (
                    <div 
                        className='badge p-2'
                        style={{
                            backgroundColor: `${statusInfos?.color}`
                        }}
                    >
                        {t(statusInfos?.title)}
                    </div>
                )
            }
        },
        {
            dataField: "creator",
            text: t("Creator"),
            sort: true,
            key: 4,
            style:{
                width : '180px'
            },
            formatter: (cellContent, {
                type
            }) => { 
                if(type !== 'custom') {
                    return 'SIP'
                }
                return cellContent
            }
        },
        {
            dataField: "createdAt",
            text: t("Created On"),
            sort: true,
            key: 5,
            style:{
                width : '180px'
            },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {dateUtils.convertDateToDate(
                            row.created_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                    </span>
                );
            },
        },
        {
            dataField: "inUse",
            text: t("In Use"),
            sort: false,
            key: 6,
            style:{
                width : '180px'
            },
            formatter: (cellContent, {
                id,
                inUse
            }) => {
                return (
                    <div className='d-flex justify-content-start align-items-start'>
                        {inUse ? (
                            <>
                                <span className='d-flex justify-content-center align-items-center'>
                                    <i class="ri-checkbox-fill font-size-20 text-success me-1"></i>
                                    <b>{t('Yes')}</b>
                                </span>
                                <Button
                                    color="primary"
                                    outline
                                    className="border-0"
                                    onClick={() => setShowInUsed(id)}
                                >
                                    <i class="ri-eye-line"></i>
                                </Button>
                            </>
                        ) : (
                            <span className='d-flex justify-content-center align-items-center'>
                                <i className='ri-close-line font-size-20 text-danger me-1' />
                                <b>{t('No')}</b>
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: "actions",
            text: t("Action"),
            sort: false,
            key: 7,
            style:{
                width : '220px'
            },
            formatter: (cellContent, row) => {
                if(row.status === 'deleted'){
                    return (
                        <div className='actions'>
                            <div
                                id={`clone-button-${row.id}`}
                            >
                                <Button
                                    disabled={lng !== 'en'}
                                    size="sm"
                                    color="primary"
                                    outline
                                    className="border-0"
                                    onClick={() => { setSelectedQustionnaireToEdit(row) }}
                                >
                                    <i class="ri-file-copy-line"></i>
                                </Button>
                                <UncontrolledTooltip
                                    target={`clone-button-${row.id}`}
                                >
                                    {t(lng === 'en' ? 'Clone this questionnaire.' : 'Questionnaires can only be cloned in English.')}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="actions">
                        <div
                            id={`edit-button-${row.id}`}
                        >
                            <Button
                                size="sm"
                                color="primary"
                                outline
                                className="border-0"
                                onClick={() => { history.push(`/admin/3rd-party/administration/questionnaires/${row.slug}`) }}
                            >
                                <i class="ri-pencil-line"></i>
                            </Button>
                            <UncontrolledTooltip
                                target={`edit-button-${row.id}`}
                            >
                                {t('Edit questionnaire')}
                            </UncontrolledTooltip>
                        </div>
                        <div
                            id={`clone-button-${row.id}`}
                        >
                            <Button
                                disabled={lng !== 'en'}
                                size="sm"
                                color="primary"
                                outline
                                className="border-0"
                                onClick={() => { setSelectedQustionnaireToEdit(row) }}
                            >
                                <i class="ri-file-copy-line"></i>
                            </Button>
                            <UncontrolledTooltip
                                target={`clone-button-${row.id}`}
                            >
                                {t(lng === 'en' ? 'Clone this questionnaire.' : 'Questionnaires can only be cloned in English.')}
                            </UncontrolledTooltip>
                        </div>
                        <div
                            id={`activate-button-${row.id}`}
                        >
                            <Button
                                size="sm"
                                outline
                                className="bg-transparent border-0 shadow-none"
                                onClick={() => { toggleQuestionnaireActivation(row) }}
                            >
                                {row.status === 'active' ? (
                                    <i 
                                        class="ri-toggle-fill" 
                                        style={{
                                            color: QuestionnaireStatuses['in_active'].color
                                        }}
                                    />
                                ) : (
                                    <i 
                                        class="ri-toggle-line" 
                                        style={{
                                            color: QuestionnaireStatuses['active'].color
                                        }}
                                    />                                    
                                )}
                            </Button>
                            <UncontrolledTooltip
                                target={`activate-button-${row.id}`}
                            >
                                {t(
                                    row.status === 'active' ? 
                                    'Deactivate questionnaire' : 
                                    'Activate questionnaire'
                                )}
                            </UncontrolledTooltip>
                        </div>
                        <div
                            id={`withdraw-button-${row.id}`}
                        >
                            <Button 
                                size="sm"
                                color="danger" 
                                onClick={() => { setItemToWithdraw(row) }} 
                                outline 
                                className="border-0"
                                disabled={!row.uncompletedRequestCount}
                            >
                                <i className="ri-arrow-go-back-line"></i>
                            </Button>
                            <UncontrolledTooltip
                                target={`withdraw-button-${row.id}`}
                            >
                                {t(
                                    row.uncompletedRequestCount ? 
                                    'Withdraw all uncompleted requests' : 
                                    'You can only withdraw questionnaires with at least one uncompleted request.'
                                )}
                            </UncontrolledTooltip>
                        </div>
                        <div
                            id={`delete-button-${row.id}`}
                        >
                            <Button 
                                size="sm"
                                color="danger" 
                                onClick={() => { setItemToDelete(row) }} 
                                outline 
                                className="border-0"
                            >
                                <i className="ri-delete-bin-line"></i>
                            </Button>
                            <UncontrolledTooltip
                                target={`delete-button-${row.id}`}
                            >
                                {t('Delete questionnaire')}
                            </UncontrolledTooltip>
                        </div>
                    </div>
                );
            },
        }
    ];

    return (
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Navigator 
                            breadCrumbs={[
                                {
                                    title   :   'SIP',
                                    link    :   '/admin/3rd-party/dashboard'
                                },
                                {
                                    title   :   'Questionnaires',
                                    link    :   `/admin/3rd-party/administration/questionnaires`
                                }
                            ]} 
                            pageTitle={t('Questionnaires')}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="12">
                                        <PaginationProvider
                                            pagination={paginationFactory({
                                                custom: true,
                                                page: filters.pageIndex,
                                                sizePerPage: filters.pageSize,
                                                totalSize: questionnairesList?.length || 0,
                                                withFirstAndLast: false,
                                                alwaysShowAllBtns: true,
                                                prePageText: (
                                                    <span>
                                                        <i className="ri-arrow-left-s-line"></i> {t("Back")}
                                                    </span>
                                                ),
                                                nextPageText: (
                                                    <span>
                                                        {t("Next")} <i className="ri-arrow-right-s-line"></i>
                                                    </span>
                                                ),
                                                prePageTitle: t("Pre page"),
                                                firstPageTitle: t("Next page"),
                                                showTotal:   false,
                                                paginationTotalRenderer :   (from, to, size) => {
                                                    return (
                                                        <span className="react-bootstrap-table-pagination-total">
                                                            {`${t('Total Items')} ${size}`}
                                                        </span>
                                                    )
                                                },
                                                hideSizePerPage: false,
                                                sizePerPageList: [
                                                    {
                                                        text: "25",
                                                        value: 25,
                                                    },
                                                    {
                                                        text: "50",
                                                        value: 50,
                                                    }
                                                ]
                                            })}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <>
                                                    <Row className="mb-3 mt-3">
                                                        <Col sm="12">
                                                            <div className="d-flex gap-4 justify-content-end">
                                                                {/* <FilteringComponent 
                                                                    onChange={(newFilters) => {
                                                                        setFilters({
                                                                            ...filters,
                                                                            ...newFilters,
                                                                            pageIndex: 1
                                                                        });
                                                                    }}
                                                                /> */}

                                                                <div id='create-questionnaire-btn'>
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={lng !== 'en'}
                                                                        onClick={() => {
                                                                            setShowCreateAndEditModal(true)
                                                                        }}
                                                                    >
                                                                        <i className='ri-add-line align-middle me-1' />
                                                                        {t("Add Questionnaire")}
                                                                    </Button>
                                                                    
                                                                    {lng !== 'en' && (
                                                                        <UncontrolledTooltip 
                                                                            target="create-questionnaire-btn"
                                                                        >
                                                                            {t('Questionnaires can only be created in English.')}
                                                                        </UncontrolledTooltip>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm="12">
                                                            <BootstrapTable
                                                                remote={{
                                                                    pagination: false,
                                                                    filter: false,
                                                                    sort: false,
                                                                    cellEdit: false,
                                                                    search: false,
                                                                }}
                                                                loading={ questionnairesAreFetching }
                                                                overlay={overlayFactory({
                                                                    spinner: (
                                                                        <Spinner
                                                                            animation="border"
                                                                            variant="primary"
                                                                            size="md"
                                                                        />
                                                                    ),
                                                                    text: `${t("Loading")}...`
                                                                })}
                                                                defaultSorted={[]}
                                                                keyField={"id"}
                                                                responsive
                                                                bordered={false}
                                                                data={questionnairesList || []}
                                                                striped={false}
                                                                columns={tableColumns}
                                                                wrapperClasses="table-responsive"
                                                                classes={"table tpdd-table"}
                                                                headerWrapperClasses={"thead-light"}
                                                                style={{
                                                                    overflowX: "auto",
                                                                }}
                                                                noDataIndication={() => <NoDataIndication />}
                                                                {...paginationTableProps}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="tpdd-pagination-style-1">
                                                                <PaginationTotalStandalone
                                                                    { ...paginationProps }
                                                                />

                                                                <div>
                                                                    <PaginationListStandalone {...paginationProps} />

                                                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </PaginationProvider>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>          

                <CreateAndEditModal 
                    showModal={showCreateAndEditModal}
                    hideModal={() => {
                        setShowCreateAndEditModal(false);
                    }}
                    questionnaire={selectedQuestionnaireToEdit}
                />    

                <InUsedThirdPartiesModal
                    showModal={!!showInUsed}
                    questionnaireId={showInUsed}
                    hideModal={() => setShowInUsed(null)}
                />

                <WithdrawRequestsModal
                    onSuccess={ refetchQuestionnaires }
                    itemToWithdraw={itemToWithdraw}
                    hideModal={() => setItemToWithdraw(null)}
                />

                <DeleteQuestionnaireModal
                    onSuccess={ refetchQuestionnaires }
                    itemToDelete={itemToDelete}
                    hideModal={() => setItemToDelete(null)}
                />

                <DeactivateQuestionnaireModal
                    onSuccess={ refetchQuestionnaires }
                    itemToDeactivate={itemToDeactivate}
                    hideModal={() => setItemToDeactivate(null)}
                />

                <ActivateQuestionnaireModal 
                    itemToActivate={itemToActivate}
                />
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App } = state;
	
	return {
		token,
		user,
		Organization,
		App
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
	})(
        withRouter(TPDDQuestionnaires)
    )
);