import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Board from '@asseinfo/react-kanban';
import TaskCardItem from "./components/TaskCardItem";
import { useQuery } from "@tanstack/react-query";
import KanbanBoardService from "src/modules/3rd-party-management/apis/admin/KanbanBoardService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ColumnHeader from "./components/ColumnHeader";
import PageHeader from "src/modules/3rd-party-management/components/page-header";
import FilteringComponent from "./components/FilteringComponent";

const KanbanBoard = ({
    t
}) => {
    const [ columns, setColumns ] = useState(null);
    const [ filters, setFilters ] = useState({});
    const [ boardDataIsLoading, setBoardDataIsLoading ] = useState(true);

    const {
        dataUpdatedAt: boardDataUpdated,
        data: boardData,
        refetch: refetchBoard
    } = useQuery({
		queryKey                :   [
            "3rd-party-management-fetch-kanboard-tasks-list-query",
            filters
        ],
		queryFn                 :   async () => {           
            setBoardDataIsLoading(true);

            const service = KanbanBoardService.getInstance();

            return await service.fetchList(filters);
		},
		cacheTime               :   0,
        refetchOnWindowFocus    :   false,
		onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching tasks list."), {
                type: "error",
            });
		}
	});

    useEffect(() => {
        refetchBoard();
    }, [ filters ]);

    useEffect(() => {
        if(boardData && Array.isArray(boardData)){
            const resultColumns = [];

            boardData.forEach((item, index) => {
                const itemTasks = item?.data || [];

                const obj = {
                    id              :   index,
                    title           :   item.title,
                    cards           :   itemTasks.map((task) => {
                        return {
                            id      :   task.id,
                            content :   {
                                id              :   task.id,
                                secondaryId     :   task.task_secondary_id,
                                title           :   task.name,
                                status          :   task.status,
                                endDate         :   task.ended_at,
                                priority        :   task.priority,
                                progressValue   :   100,
                                supplier        :   task.supplier,
                                isMitigation    :   task.is_mitigation,
                                relatedRisk     :   {
                                    secondaryId :   task.relatedRisk?.secondary_id || ''
                                },
                                team: [
                                    {
                                        id      :   `task-owner-${task.report_case.secondary_case_id}-${task.ended_at}`,
                                        type    :   'Task owner',
                                        name    :   task.owner_info?.name,
                                        avatar  :   task.owner_info?.avatar,
                                    },
                                    {
                                        id      :   `task-manager-${task.report_case.secondary_case_id}-${task.ended_at}`,
                                        type    :   'Task Manager',
                                        name    :   task.manager_info?.name,
                                        avatar  :   task.manager_info?.avatar,
                                    },
                                ],
                            }
                        }
                    }),
                    columnsubtitle  :   `${itemTasks.length}`,
                };

                resultColumns.push(obj);
            });

            setColumns(resultColumns);
        }
        
    }, [ boardDataUpdated ]);

    useEffect(() => {
        if(columns?.length > 0){
            setBoardDataIsLoading(false);
        }
    }, [ columns ]);

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
            </Row>
        )
    }

    const renderBoard = () => {
        return (
            <Board initialBoard={{
                    sortBy      :   filters.sortBy,
                    columns     :   columns,
                    placeholder :   null
                }}
                allowAddCard={ false }
                disableColumnDrag={ true }
                disableCardDrag={ true }
                renderColumnHeader={({ title, columnsubtitle }) => {
                    return (
                        <ColumnHeader title={ title } subtitle={ columnsubtitle } />
                    );
                }}
                renderCard={({ content }, { dragging }) => {
                    return(
                        <TaskCardItem data={ content } dragging={ false } />
                    );
                }}
                onNewCardConfirm={(draftCard) => ({
                    id: new Date().getTime(),
                    ...draftCard,
                })}
            />
        );
    }

    return (
        <div className='page-content supplier-kanban-board-page'>
            <Container fluid>
                <PageHeader title={t('Kanban Board')} />

                <Row className="mb-4">
                    <Col sm='12' className="d-flex justify-content-end">
                        <FilteringComponent 
                            onChange={(newFilters) => {
                                setFilters({
                                    ...filters,
                                    ...newFilters
                                });
                            }}
                        />
                    </Col>
                </Row>
                
                <Row>
                    <Col sm="12">
                        {
                            boardDataIsLoading ? (
                                <>{ renderLoadingSection() }</>
                            ) : (
                                <>{ renderBoard() }</>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { Organization } = state;

    return {
        user            :   state.Login.user,
        organization    :   Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(KanbanBoard))
);