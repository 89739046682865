import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Col, Row, Card } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";
import { toast } from "react-toastify";
import OverviewHeader from "../../../../components/overview-header";
import Accordion from 'react-bootstrap/Accordion';
import SimpleBar from "simplebar-react";

const Scoring = ({
    t,
    supplierId,
    lng
}) => {
    const {
        data: supplierScoreData,
        isLoading: supplierScoreIsLoading
    } = useQuery({
		queryKey: [
            `3rd-party-management-supplier-score-data`, 
            supplierId,
            lng
        ],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchScoreData(supplierId, {
                language: lng
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching the scoring data for the supplier.'), {
				type: 'error',
			});
		}
	});

    const score = supplierScoreData?.score || 0;

    let scoreExplanation = [];

    if(supplierScoreData?.explanation){
        try{
            scoreExplanation = JSON.parse(supplierScoreData?.explanation);
        }
        catch(_error){}
    }

    return (
        <div className="p-4 scoring-tab-content">
            <OverviewHeader 
                supplierId={ supplierId } 
            />

            <Card className="shadow-none mt-4">
                <Row>
                    <Col sm='12'>
                        <div class="rating-description">
                            {supplierScoreIsLoading ? (
                                <Row>
                                    <Col sm="12" className="mt-4 mb-4">
                                        <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                                    </Col>
                                    <Col sm="12" className="mb-4">
                                        <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                                    </Col>
                                    <Col sm="12" className="mb-4">
                                        <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                                    </Col>
                                </Row>
                            ) : (
                                <div className="score-list">
                                    <Accordion className="custom-accordion" defaultActiveKey="0">
                                        <Accordion.Item className="active" eventKey="0">
                                            <Accordion.Header>
                                                <span>
                                                    { t('Score changed to') } {score}
                                                </span>
                                            </Accordion.Header>
                                            
                                            <Accordion.Body>
                                                <Row>
                                                    <Col>
                                                        <SimpleBar style={{ maxHeight: "330px" }}>
                                                            <ul className="list-unstyled activity-wid">
                                                                {scoreExplanation.map((exp, index) => {
                                                                    return (
                                                                        <li key={index} className="activity-list">
                                                                            <div className="activity-icon avatar-xs">
                                                                                <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                                                    <i className="ri-subtract-line"></i>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    <h5 className="font-size-13">
                                                                                        {exp.title}
                                                                                    </h5>
                                                                                </div>

                                                                                <div>
                                                                                    <p className="text-muted mb-1">
                                                                                        {exp.explanation}
                                                                                    </p>
                                                                                </div>

                                                                                <div>
                                                                                    <p className="text-muted mb-0">
                                                                                        %{exp.score} {t("of the supplier's score were deducted")}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </SimpleBar>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default withNamespaces()(
    withRouter(Scoring)
); 