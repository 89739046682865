import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { successNotification, errorNotification } from "src/store/actions.js";
import { toast } from "react-toastify";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import OverviewHeader from "../../../../components/overview-header";
import DateUtils from "src/services/utils/DateUtils";
import {
    EMPTY_LIST,
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";
import SupplierTaskAssessmentService from "src/modules/3rd-party-management/apis/admin/SupplierTaskAssessmentService";
import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";
import {
    TasksStatuses,
    TasksPriorities
} from "src/modules/3rd-party-management/constants";
import CreateTaskModal from "src/modules/3rd-party-management/components/RisksAndTasks/tasks/new";
import { hasUserAccessToEditSupplier } from "src/modules/3rd-party-management/helpers/users";
import FilteringComponent from "./components/filtering-component";

const TaskManager = (props) => {
    const {
        t,
        supplierId
    } = props;

    const history = useHistory();

    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    });

    const [ deleteTaskId, setDeleteTaskId ] = useState(null);

    const [ createTaskModalStatus, setCreateTaskModalStatus ] = useState(false);
    const [ deleteTaskModalStatus, setDeleteTaskModalStatus ] = useState(false);

    const dateUtils = new DateUtils();

    const handleFetchSupplierBasicInformation = useQuery({
        queryKey: [
            '3rd-party-management-supplier-details-basic-infos',
            supplierId
        ],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching the basic information.'), {
                type: 'error',
            });
        }
    });

    const handleFetchSupplierTasksQuery = useQuery({
		queryKey: [
            '3rd-party-management-fetch-supplier-tasks', 
            (new URLSearchParams(
                Object.fromEntries(Object.entries(filters).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            return await service.fetchTasks(supplierId, filters);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching tasks.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierTasksOwners = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-tasks-owners'],
		queryFn: async () => {
			const service = SupplierTaskAssessmentService.getInstance();

            return await service.fetchOwners(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		},
	});

    const handleDeleteSupplierTaskMutation = useMutation({
        mutationFn: async (taskId) => {
            const service = SupplierTaskAssessmentService.getInstance();

            return await service.deleteTask(supplierId, taskId);
        },
        onSuccess: () => {
            setDeleteTaskModalStatus(false);

            handleFetchSupplierTasksQuery.refetch();
            
            toast(t("Task deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("Failed to delete task."), {
                type: "error",
            });
        }
    });

    const isLoadingUserData = (
        (handleFetchSupplierBasicInformation.isFetching || handleFetchSupplierBasicInformation.isLoading) || 
        !props.user
    );

    const hasUserAccess = !isLoadingUserData ? 
        hasUserAccessToEditSupplier(props.user, handleFetchSupplierBasicInformation.data?.assignedAnalyst?.id) : 
        false;

    const TasksTableColumns = [
        {
            dataField :   "id",
            text      :   t("Task ID"),
            sort      :   true,
            key       :   1,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, row) => {
                const taskID = row.relatedRisk && row.relatedRisk.secondary_id ? `R${row.relatedRisk.secondary_id}/T${row.secondary_id}` : `T${row.secondary_id}`;

                return (
                    <span style={{
                            cursor: !row.deleted_at ? 'pointer' : 'default',
                        }}
                        onClick={() => (!row.deleted_at && taskSelectedToShow(row.id))}>
                            {taskID}
                    </span>
                );
            }
        },
        {
            dataField :   "name",
            text      :   t("Title"),
            sort      :   true,
            key       :   2,
            style     :   {
                width : '140px'
            }
        },
        {
            dataField :   "created_at",
            text      :   t("Creation Date"),
            sort      :   false,
            key       :   3,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return (
                    <div className='dt-task-creation-date'>
                        { dateUtils.convertTimeStampToDate(cellContent, INTERNATIONAL_DATE_FORMAT) }
                    </div>
                );
            }
        },
        {
            dataField :   "ended_at",
            text      :   t("Deadline"),
            sort      :   false,
            key       :   4,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent, row) => {
                return (
                    <div className='dt-task-deadline-date'>
                        { dateUtils.convertTimeStampToDate(cellContent, INTERNATIONAL_DATE_FORMAT) }
                    </div>
                );
            }
        },
        {
            dataField :   "manager",
            text      :   t("Task Manager"),
            sort      :   true,
            key       :   5,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                return (
                    <div>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span> 
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                );
            }
        },
        {
            dataField :   "analyst",
            text      :   t("Task owner"),
            sort      :   true,
            key       :   6,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                if(cellContent?.full_name)
                    return (
                        <div>
                            <span className='dt-list-col-bullet'>{cellContent.full_name[0]}</span>{' '}
                            <span className='dt-list-col-bullet-text'>{cellContent.full_name}</span>
                        </div>
                    )

                return null
            }
        },
        {
            dataField :   "priority",
            text      :   t("Priority"),
            sort      :   true,
            key       :   7,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent) => {
                const taskPriority = TasksPriorities[cellContent];

                return (
                    <div className='dt-task-priority'>
                        <span style={{
                            color   :   `${taskPriority.color}`
                        }}>
                            { taskPriority.icon }
                        </span>

                        <span style={{ marginLeft: '4px' }}>
                            {t(taskPriority.title)}
                        </span>
                    </div>
                )
            }
        },
        {
            dataField :   "status",
            text      :   t("Status"),
            sort      :   true,
            key       :   8,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent) => {
                const taskStatus = TasksStatuses[cellContent];

                return (
                    <div className='dt-task-status text-dark' style={{
                        backgroundColor :   `${taskStatus.color}`
                    }}>
                        {t(taskStatus.title)}
                    </div>
                )
            }
        },
        {
            dataField :   "deleted_at",
            text      :   t("Deletion date"),
            sort      :   false,
            key       :   9,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return (
                    <div>
                        {cellContent ? dateUtils.convertTimeStampToDate(cellContent, INTERNATIONAL_DATE_FORMAT) : null}
                    </div>
                )
            }
        }
    ];

    if(hasUserAccess){
        TasksTableColumns.push({
            dataField :   "actions",
            text      :   t("Action"),
            sort      :   false,
            key       :   10,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, row) => {
                if(!row.deleted_at){
                    return (
                        <div className="actions">
                            <button onClick={() => {
                                setDeleteTaskId(row.id);
                                setDeleteTaskModalStatus(true);
                            }} className="btn btn-small text-danger">
                               <i className='ri-delete-bin-line text-danger'></i>
                            </button>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="text-danger">
                            {t('Deleted')}
                        </div>
                    )
                }
            }
        })
    }

    const NoDataIndication = () => (
        (handleFetchSupplierTasksQuery.isFetched && !handleFetchSupplierTasksQuery.data?.tasks?.length) ? <div className="alert m-0" role="alert">
            <p style={{
                    textAlign: "center",
                    marginBottom: 0
                }}>
                {props.t(EMPTY_LIST)}
            </p>
        </div> : <></>
    );

    const handleTableChange = (type, options) => {
        const {
            page,
            sizePerPage,

            sortField,
            sortOrder
        } = options;

        switch(type){
            case 'pagination':
                    setFilters({
                        ...filters,
                        pageIndex   :   page || 1,
                        pageSize    :   sizePerPage
                    });
                break;
            case 'sort':
                    setFilters({
                        ...filters,
                        sortField   :   sortField,
                        sortOrder   :   sortOrder.toUpperCase()
                    })
                break;
            default:
                return false;
        }
    }

    const taskSelectedToShow = (id) => {
        history.push(`/admin/3rd-party/suppliers/${supplierId}/tasks/${id}/details`);
    }

    const priorities = [];
    const statuses = [];

    for(const priorityKey in TasksPriorities){
        const priority = TasksPriorities[priorityKey];
        priorities.push({
            value       : priorityKey,
            baseLabel   : priority.title,
            label       : (
                <>
                    <span style={{
                            color	:	`${priority.color}`
                        }}>
                        { priority.icon }
                    </span>
                    <span>
                        {t(priority.title)}
                    </span>
                </>
            ),
        });
    }

    for(const statusKey in TasksStatuses){
        const status = TasksStatuses[statusKey];
        statuses.push({
            value       : statusKey,
            label       : t(status.title)
        });
    }

    const renderModals = () => {
        if(isLoadingUserData || handleFetchSupplierTasksOwners.isFetching){
            return null;
        }

        const closeDeleteTaskModal = () => {
            setDeleteTaskId(null);
            setDeleteTaskModalStatus(false);
        }

        const ownersList = (handleFetchSupplierTasksOwners.data || []).map((item) => {
            return {
                value: item.id,
                label: `${item.first_name} ${item.last_name}`,
            };
        })

        return (
            <React.Fragment>
                <CreateTaskModal
                    isOpen={createTaskModalStatus}
                    closeModal={() => setCreateTaskModalStatus(false)}
                    owners={ownersList}
                    initialTaskStatus={{ value: 'NEW', label: t('New') }}
                    priorities={ priorities }
                    successCreation={() => handleFetchSupplierTasksQuery.refetch()}
                    creationRequestPayload={{
                        supplier  :   supplierId
                    }}
                />

                <Modal size="lg" scrollable={true} isOpen={deleteTaskModalStatus} backdrop="static">
                    <ModalHeader toggle={closeDeleteTaskModal}>
                        {t("Delete Task")}
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <Label>
                                    {t("Are you sure?")}
                                </Label>
                            </Col>
                        </Row>
                        <ModalFooter>
                            <Button
                                color="danger"
                                className="waves-effect waves-light"
                                type="submit"
                                onClick={() => {
                                    handleDeleteSupplierTaskMutation.mutate(deleteTaskId);
                                }}>
                                    {t("Delete")}
                            </Button>

                            <Button
                                color="secondary"
                                className="waves-effect waves-light"
                                type="button"
                                onClick={closeDeleteTaskModal}>
                                    {t("Cancel")}
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    const renderFilters = () => {
        return (
            <Row>
                <Col sm="12">
                    <div className="d-flex justify-content-end mb-3 mt-3">
                        <FilteringComponent 
                            statuses={statuses}
                            priorities={priorities}
                            owners={{
                                isLoading: handleFetchSupplierTasksOwners.isFetching,
                                options: handleFetchSupplierTasksOwners.data
                            }}
                            onChange={(newFilters) => {
                                setFilters({
                                    ...filters,
                                    ...newFilters,
                                    pageIndex: 1
                                });
                            }}
                        />

                        {hasUserAccess && (
                            <Button color='primary'
                                onClick={() => setCreateTaskModalStatus(true)}
                                outline
                            >
                                {t('Create Task')}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }

    const renderTasksTable = () => {
        return (
            <Row>
                <Col sm='12'>
                    <PaginationProvider
                        pagination={
                            paginationFactory({
                                custom              :   true,
                                page                :   filters.pageIndex,
                                sizePerPage         :   filters.pageSize,
                                totalSize           :   handleFetchSupplierTasksQuery.data?.tasks?.itemsCount || 0,
                                withFirstAndLast    :   true,
                                alwaysShowAllBtns   :   true,
                                prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {props.t('Back')}</span>,
                                nextPageText        :   <span>{props.t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                                prePageTitle        :   props.t('Pre page'),
                                firstPageTitle      :   props.t('First page'),
                                lastPageTitle       :   props.t('Last page'),
                                showTotal           :   false,
                                paginationTotalRenderer :   (from, to, size) => {
                                    return (
                                        <span className="react-bootstrap-table-pagination-total">
                                            {`${props.t('Total Items')} ${size}`}
                                        </span>
                                    )
                                },
                                hideSizePerPage     :   false,
                                sizePerPageList     :   [
                                    {
                                        text: '25', 
                                        value: 25
                                    }, 
                                    {
                                        text: '50', 
                                        value: 50
                                    }
                                ]
                            })
                        }>
                        {({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <>
                                <Row>
                                    <Col sm='12'> 
                                        <BootstrapTable
                                            loading={ handleFetchSupplierTasksQuery.isFetching }
                                            remote={{
                                                pagination: true,
                                                filter: false, 
                                                sort: true,
                                                cellEdit: false,
                                                search: false
                                            }}
                                            onTableChange={ handleTableChange }
                                            overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : `${props.t("Loading")}...` }) }
                                            keyField={"id"}
                                            responsive
                                            bordered={ false }
                                            data={ handleFetchSupplierTasksQuery.data?.tasks || [] }
                                            striped={true}
                                            columns={ TasksTableColumns }
                                            wrapperClasses="table-responsive"
                                            classes={"table tpdd-table"}
                                            headerWrapperClasses={"thead-light"}
                                            style={{
                                                overflowX: "auto",
                                            }}
                                            noDataIndication={ () => <NoDataIndication /> }
                                            {...paginationTableProps}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <div className="tpdd-pagination-style-1">
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />
                                            
                                            <div>
                                                <PaginationListStandalone
                                                    { ...paginationProps }
                                                />
                                                
                                                <SizePerPageDropdownStandalone
                                                    { ...paginationProps }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </PaginationProvider>
                </Col>
            </Row>
        );
    }

    return (
        <div className="p-4 d-flex flex-column gap-4">
            { renderModals() }
            <Row>        
                <Col sm="12">
                    <OverviewHeader supplierId={ supplierId } />
                </Col>

                <Col sm="12">
                    <div className="p-4">
                        { renderFilters() }
                        { renderTasksTable() }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user : state.Login.user,
        token,
        organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {
            successNotification,
            errorNotification
        }
    )(memo(TaskManager)))
);