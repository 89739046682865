import axios from 'axios';

import {
    API_BASE_URL,
    API_URL_GET_DOCUMENTS,
    API_URL_DOWNLOAD_DOCUMENTS,
    API_URL_UPLOAD_DOCUMENTS
} from '../../constants/index';

export default class DocumentsService {
	static instance;

	static getInstance() {
		if (!DocumentsService.instance) {
			DocumentsService.instance = new DocumentsService();
		}

		return DocumentsService.instance;
	}

    async fetchList(params = {}){
        const response = await axios.get(API_URL_GET_DOCUMENTS, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });
        return response.data.data || []
    }

    async create(payload){
        const response = await axios.post(API_URL_UPLOAD_DOCUMENTS, payload, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response
    }

    async delete(id){
        return await axios.delete(`${API_BASE_URL}/user_manual/${id}/delete`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        })
    }
    
}
