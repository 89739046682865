import DateUtils from "src/services/utils/DateUtils";
import {
    HiChevronDoubleDown,
    HiChevronDoubleUp,
    HiMenuAlt4
} from "react-icons/hi";
import { 
    INTERNATIONAL_DATE_FORMAT,
} from "src/common/constants"; 

const dateUtils = new DateUtils();

export const SupplierStatuses = {
    "new"   :   {
        title   :   "New",
        color   :   "bg-secondary"
    },
    "under review"    :   {
        title   :   "Under Review",
        color   :   "bg-info"
    },
    "approved"  :   {
        title   :   "Approved",
        color   :   "bg-success"
    },
    "rejected"  :   {
        title   :   "Rejected",
        color   :   "bg-danger"
    }
}

export const ActionsMetaData = {
    admin: {
        delete_user: [
            {
                key: "deleted_user_name",
                title: "Deleted User" 
            }
        ],
        edit_user: [
            {
                key: "edited_user_name",
                title: "Edited User" 
            }
        ],
        create_user: [
            {
                key: "created_users",
                title: "Created Users",
                valueRenderer: ({ value }) => {
                    return (
                        <div className="d-flex justify-content-center align-items-start flex-column">
                            {(value || []).map((user) => {
                                return (
                                    <p className="mb-0 d-flex justify-content-center align-items-center">
                                        <i className="ri-check-line me-1" />
                                        {user.name}
                                    </p>
                                )
                            })}
                        </div>
                    )
                }
            }
        ]
    },
    entityManagement: {
        edit_routing: [
            {
                key: "default_supplier_owner",
                title: "Default owner"
            },
            {
                key: "default_supplier_risk_owner",
                title: "Assign risks to"
            },
            {
                key: "default_supplier_task_owner",
                title: "Assign tasks to"
            }
        ],
    
        edit_financial_setting: [
            {
                key: "financial_year_start",
                title: "Start of Financial Year",
                valueRenderer: ({ value }) => {
                    return dateUtils.convertDateToDate(
                        value,
                        INTERNATIONAL_DATE_FORMAT
                    )
                }
            },
            {
                key: "financial_year_end",
                title: "End of Financial Year",
                valueRenderer: ({ value }) => {
                    return dateUtils.convertDateToDate(
                        value,
                        INTERNATIONAL_DATE_FORMAT
                    )
                }
            }
        ],

        add_institute: [
            {
                key: 'institute_title',
                title: 'Institute Title'
            },
            {
                key: 'legalEntityTitle',
                title: 'Legal Entity Title'
            }
        ],
        edit_institute: [
            {
                key: 'institute_title',
                title: 'Institute Title'
            },
            {
                key: 'legalEntityTitle',
                title: 'Legal Entity Title'
            }
        ],
        remove_institute: [
            {
                key: 'institute_title',
                title: 'Institute Title'
            },
            {
                key: 'legalEntityTitle',
                title: 'Legal Entity Title'
            }
        ],
    
        add_division: [
            {
                key: 'division_title',
                title: 'Division Title'
            },
            {
                key: 'institute',
                title: 'Institute Title'
            },
            {
                key: 'legal_entity',
                title: 'Legal Entity'
            }
        ],
        edit_division: [
            {
                key: 'institute',
                title: 'Institute Title'
            },
            {
                key: 'legal_entity',
                title: 'Legal Entity'
            },
            {
                key: 'old_title',
                title: 'Previous Title'
            },
            {
                key: 'new_title',
                title: 'Updated Title'
            }
        ],
        remove_division: [
            {
                key: 'institute',
                title: 'Institute Title'
            },
            {
                key: 'legal_entity',
                title: 'Legal Entity'
            },
            {
                key: 'division_title',
                title: 'Division Title'
            }
        ],
    
        add_legal_entity: [
            {
                key: 'legal_entity_title',
                title: 'Legal Entity Title'
            },
            {
                key: 'short_name',
                title: 'Short Name'
            }
        ],
        edit_legal_entity: [
            {
                key: 'legal_entity_title',
                title: 'Legal Entity Title'
            }
        ],
        remove_legal_entity: [
            {
                key: 'legal_entity_title',
                title: 'Legal Entity Title'
            }
        ]
    },
    user: {
        edit_tag: [
            {
                key: 'added_tags',
                title: 'Added Keywords',
                valueRenderer: ({ value, t }) => {
                    if(!value?.length) return undefined;
    
                    return value.map((word) => t(word)).join(", ")
                },
                hideIfValueIsUndefined: true
            },
            {
                key: 'removed_tags',
                title: 'Removed Keywords',
                valueRenderer: ({ value, t }) => {
                    if(!value?.length) return undefined;
    
                    return value.map((word) => t(word)).join(", ")
                },
                hideIfValueIsUndefined: true
            }
        ],
    },
    supplier: {
        add_connected_legal: [
            {
                key: "connected_legal_name",
                title: "Connected Legal Name"
            },
            {
                key: "financial_year",
                title: "Financial Year"
            },
            {
                key: "total_revenue",
                title: "Total Revenue"
            }
        ],
        delete_connected_legal: [
            {
                key: "connected_legal_name",
                title: "Connected Legal Name"
            },
            {
                key: "financial_year",
                title: "Financial Year"
            },
            {
                key: "total_revenue",
                title: "Total Revenue"
            }
        ],
    
        add_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
        edit_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
        delete_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
    
        add_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            },
            {
                key: 'description',
                title: 'Product Description'
            }
        ],
        edit_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            },
            {
                key: 'description',
                title: 'Product Description'
            }
        ],
        delete_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            }
        ],
    
        add_team_manager: [
            {
                key: 'team_manager_title',
                title: 'Salutation'
            },
            {
                key: 'team_manager_name',
                title: 'Person Name'
            },
            {
                key: 'team_manager_email',
                title: 'Email'
            },
            {
                key: 'team_manager_position',
                title: 'Position'
            }
        ],
        edit_team_manager: [
            {
                key: 'team_manager_title',
                title: 'Salutation'
            },
            {
                key: 'team_manager_name',
                title: 'Person Name'
            },
            {
                key: 'team_manager_email',
                title: 'Email'
            },
            {
                key: 'team_manager_position',
                title: 'Position'
            }
        ],
        delete_team_manager: [
            {
                key: 'team_maanger_title',
                title: 'Salutation'
            },
            {
                key: 'team_maanger_name',
                title: 'Person Name'
            },
            {
                key: 'team_maanger_email',
                title: 'Email'
            },
            {
                key: 'team_maanger_position',
                title: 'Position'
            }
        ],
    
        create_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
        edit_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
        delete_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
    
        add_contact_person: [
            {
                key: 'contact_person_title',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phoneNumber',
                title: 'Phone Number'
            }
        ],
        edit_contact_person: [
            {
                key: 'contact_person_title',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phoneNumber',
                title: 'Phone Number'
            }
        ],
        delete_contact_person: [
            {
                key: 'contact_person_salutation',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phone',
                title: 'Phone Number'
            }
        ],
    
        add_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],
        edit_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],
        remove_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],

        update_supplier_status: [
            {
                key: 'old_status',
                title: 'Previous Status',
                valueRenderer: ({ value, t }) => {
                    return t(SupplierStatuses[value]?.title)
                }
            },
            {
                key: 'status',
                title: 'New Status',
                valueRenderer: ({ value, t }) => {
                    return t(SupplierStatuses[value]?.title)
                }
            }
        ],
        edit_supplier_analyst_admin: [
            {
                key: 'analyst_admin',
                title: 'Assigned Analyst'
            }
        ],
        supplier_toggle_flag: [
            {
                key: 'flag',
                title: 'Flag Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'True' : 'False')
                }
            }
        ],
        supplier_finding_toggle_flag: [
            {
                key: 'flag',
                title: 'Flag Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'True' : 'False')
                }
            }
        ],

        create_report_request: [
            {
                key: 'report_request_id',
                title: 'Report ID'
            },
            {
                key: 'responsible_person_name',
                title: 'Contact Person'
            },
            {
                key: 'deadline_date',
                title: 'Deadline'
            },
            {
                key: 'questionnaire_name',
                title: 'Report Type'
            }
        ],
        withdraw_report_request: [
            {
                key: 'report_request_id',
                title: 'Report ID'
            },
            {
                key: 'responsible_person_name',
                title: 'Contact Person'
            },
            {
                key: 'deadline_date',
                title: 'Deadline'
            },
            {
                key: 'questionnaire_name',
                title: 'Report Type'
            }
        ],

        supplier_finding_toggle_read_flag: [
            {
                key: 'flag',
                title: 'New Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'Read' : 'Unread')
                }
            }
        ]
    },
    ownCompany: {
        add_connected_legal: [
            {
                key: "connected_legal_name",
                title: "Connected Legal Name"
            },
            {
                key: "financial_year",
                title: "Financial Year"
            },
            {
                key: "total_revenue",
                title: "Total Revenue"
            }
        ],
        delete_connected_legal: [
            {
                key: "connected_legal_name",
                title: "Connected Legal Name"
            },
            {
                key: "financial_year",
                title: "Financial Year"
            },
            {
                key: "total_revenue",
                title: "Total Revenue"
            }
        ],
    
        add_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
        edit_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
        delete_share_holder: [
            {
                key: "type",
                title: "Type",
                valueRenderer: ({ value, t }) => {
                    return t(value === 'company' ? 'Company' : 'Individual')
                }
            },
            {
                key: "company_name",
                title: "Shareholder Name",
                valueRenderer: ({ value, otherFields }) => {
                    return otherFields?.type === 'company' ? value : `${otherFields?.first_name} ${otherFields?.last_name}`
                }
            },
            {
                key: "percentage",
                title: "Percentage",
                valueRenderer: ({ value }) => {
                    return `${value}%`
                }
            }
        ],
    
        add_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            },
            {
                key: 'description',
                title: 'Product Description'
            }
        ],
        edit_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            },
            {
                key: 'description',
                title: 'Product Description'
            }
        ],
        delete_product: [
            {
                key: 'product_name',
                title: 'Product Name'
            }
        ],
    
        add_team_manager: [
            {
                key: 'team_manager_title',
                title: 'Salutation'
            },
            {
                key: 'team_manager_name',
                title: 'Person Name'
            },
            {
                key: 'team_manager_email',
                title: 'Email'
            },
            {
                key: 'team_manager_position',
                title: 'Position'
            }
        ],
        edit_team_manager: [
            {
                key: 'team_manager_title',
                title: 'Salutation'
            },
            {
                key: 'team_manager_name',
                title: 'Person Name'
            },
            {
                key: 'team_manager_email',
                title: 'Email'
            },
            {
                key: 'team_manager_position',
                title: 'Position'
            }
        ],
        delete_team_manager: [
            {
                key: 'team_maanger_title',
                title: 'Salutation'
            },
            {
                key: 'team_maanger_name',
                title: 'Person Name'
            },
            {
                key: 'team_maanger_email',
                title: 'Email'
            },
            {
                key: 'team_maanger_position',
                title: 'Position'
            }
        ],
    
        create_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
        edit_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
        delete_supplier_location: [
            {
                key: 'type',
                title: 'Type',
                valueRenderer: ({ value, t }) => {
                    return t(value === 'office' ? 'Office' : 'Factory')
                }
            },
            {
                key: 'country',
                title: 'Country'
            },
            {
                key: 'postal_code',
                title: 'Postal Code'
            },
            {
                key: 'city',
                title: 'City'
            },
            {
                key: 'address',
                title: 'Street, Number'
            }
        ],
    
        add_contact_person: [
            {
                key: 'contact_person_title',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phoneNumber',
                title: 'Phone Number'
            }
        ],
        edit_contact_person: [
            {
                key: 'contact_person_title',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phoneNumber',
                title: 'Phone Number'
            }
        ],
        delete_contact_person: [
            {
                key: 'contact_person_salutation',
                title: 'Salutation'
            },
            {
                key: 'contact_person_name',
                title: 'Person Name',
                valueRenderer: ({ otherFields }) => {
                    return `${otherFields?.contact_person_name} ${otherFields?.contact_person_lastName}`
                }
            },
            {
                key: 'contact_person_email',
                title: 'Email'
            },
            {
                key: 'contact_person_phone',
                title: 'Phone Number'
            }
        ],
    
        add_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],
        edit_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],
        remove_legal_location: [
            {
                key: 'address',
                title: 'Address'
            }
        ],

        update_supplier_status: [
            {
                key: 'old_status',
                title: 'Previous Status',
                valueRenderer: ({ value, t }) => {
                    return t(SupplierStatuses[value]?.title)
                }
            },
            {
                key: 'status',
                title: 'New Status',
                valueRenderer: ({ value, t }) => {
                    return t(SupplierStatuses[value]?.title)
                }
            }
        ],
        edit_supplier_analyst_admin: [
            {
                key: 'analyst_admin',
                title: 'Assigned Analyst'
            }
        ],
        supplier_toggle_flag: [
            {
                key: 'flag',
                title: 'Flag Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'True' : 'False')
                }
            }
        ],
        supplier_finding_toggle_flag: [
            {
                key: 'flag',
                title: 'Flag Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'True' : 'False')
                }
            }
        ],

        create_report_request: [
            {
                key: 'report_request_id',
                title: 'Report ID'
            },
            {
                key: 'responsible_person_name',
                title: 'Responsible Person'
            },
            {
                key: 'deadline_date',
                title: 'Deadline'
            },
            {
                key: 'questionnaire_name',
                title: 'Report Type'
            }
        ],
        withdraw_report_request: [
            {
                key: 'report_request_id',
                title: 'Report ID'
            },
            {
                key: 'responsible_person_name',
                title: 'Responsible Person'
            },
            {
                key: 'deadline_date',
                title: 'Deadline'
            },
            {
                key: 'questionnaire_name',
                title: 'Report Type'
            }
        ],

        supplier_finding_toggle_read_flag: [
            {
                key: 'flag',
                title: 'New Status',
                valueRenderer: ({ value, t }) => {
                    return t(value ? 'Read' : 'Unread')
                }
            }
        ]
    },
    questionnaireDesigner: {
        create_questionnaire: [
            {
                key: "questionnaire_secondary_id",
                title: "Questionnaire ID"
            },
            {
                key: "questionnaire_name",
                title: "Questionnaire Title",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            }
        ],  
        clone_questionnaire: [
            {
                key: "questionnaire_secondary_id",
                title: "Questionnaire ID"
            },
            {
                key: "questionnaire_name",
                title: "Questionnaire Title",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            },
            {
                key: "questionnaire_clone_from",
                title: "Clone From",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            }
        ],
        update_questionnaire: [],
        active_questionnaire: [
            {
                key: "questionnaire_secondary_id",
                title: "Questionnaire ID"
            },
            {
                key: "questionnaire_name",
                title: "Questionnaire Title",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            }
        ],
        in_active_questionnaire: [
            {
                key: "questionnaire_secondary_id",
                title: "Questionnaire ID"
            },
            {
                key: "questionnaire_name",
                title: "Questionnaire Title",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            }
        ],
        delete_questionnaire: [
            {
                key: "questionnaire_secondary_id",
                title: "Questionnaire ID"
            },
            {
                key: "questionnaire_name",
                title: "Questionnaire Title",
                valueRenderer: ({ value, t }) => {     
                    return t(value)
                }
            }
        ]
    }
}

export const HistoryLogActions = {
	report      :   {   
        create                      :   'New report created',
		update_analyst              :   'Update Analyst',
		update_status               :   'Update Status',
		mark_as_delete              :   'Marked as delete',
		delete                      :   'Deleted',
		upload_attachment           :   'Uploaded attachment',
		download_user_attachment    :   'Download user attachment',
		download_analyst_attachment :   'Download analyst attachment',
		delete_attachment           :   'Delete Attachment',
		add_comment                 :   'Add Comment',
		add_internal_comment        :   'Add Internal Comment',
		update_analyst_readonly     :   'Read-Only Analysts',
		update_risk                 :   'Risk updated',
		link_case                   :   'Linked case updated',
		change_admin_description    :   'Admin description updated',
		change_admin_category       :   'Admin category updated',
		add_link_case               :   "Linked case added",
		remove_link_case            :   "Linked case removed",
	},
	risk        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New risk created',
		update_description  :   'Description updated',
		delete              :   'Deleted',
		assign_analyst      :   'Assigned to analyst',
		update_damage       :   'Damage updated',
		update_likelihood   :   'Likelihood updated',
		update_category     :   'Category updated',
		change_description  :   'Description updated by admin',
		change_category     :   'Category updated by admin',
		create_ai           :   'New Ai risk created',
	},
	task        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New task created',
		assign_analyst      :   'Assigned to analyst',
		update_description  :   'Description updated',
		update_status       :   'Status updated',
		update_priority     :   'Priority updated',
		update_deadline     :   'Deadline updated',
		update_category     :   'Category updated',
		delete              :   'Deleted',
	},
	admin       :   {
		change_active_directory_settings    :   'Active directory settings',
		edit_risk_matrix                    :   'Update matrix',
		restore_risk_matrix                 :   'Restore matrix',
		edit_user                           :   'Edit user',
		create_user                         :   'Created user',
		delete_user                         :   'Deleted user',
		change_user_status                  :   'Changed user status',
		edit_organization                   :   'Edited organization',
		update_question                     :   'Updated question',
		remove_question                     :   'Removed question',
		remove_category                     :   'Removed category',
		login                               :   'Login',
	},
	document    :   { 
        create  :   'Created', 
        remove  :   'Removed' 
    },
	user        :   { 
        approveConsent  : "Approve Consent",
		edit_tag        :  "Update keyword"  
    },
    supplier    :   {
        'onboard'               :   'New supplier created',
        'first_assessment'      :   'First assessment based on findings',
        'new_report'            :   'New report created',
        'add_comment'           :   'Add Comment',
        'add_internal_comment'  :   'Add Internal Comment',

        'add_team_manager': 'Add Management Team Person',
        'edit_team_manager': 'Edit Management Team Person',
        'delete_team_manager': 'Delete Management Team Person',

        'add_connected_legal': 'Add Connected Legal',
        'delete_connected_legal': 'Delete Connected Legal',
        'update_connected_legal': 'Edit Connected Legal',

        'add_share_holder': 'Add Shareholder',
        'edit_share_holder': 'Edit Shareholder',
        'delete_share_holder': 'Delete Shareholder',

        'add_product': 'Add Product',
        'edit_product': 'Edit Product',
        'delete_product': 'Delete Product',

        'create_supplier_location': 'Add Location',
        'edit_supplier_location': 'Edit Location',
        'delete_supplier_location': 'Delete Location',

        'add_contact_person': 'Add Contact Person',
        'edit_contact_person': 'Edit Contact Person',
        'delete_contact_person': 'Delete Contact Person',

        'update_supplier_status': 'Update Third Party Status',
        'edit_supplier_analyst_admin': 'Update Assigned Analyst',
        'edit_supplier_general_information': 'Edit General Information',
        'supplier_toggle_flag': 'Toggle Flag',
        'supplier_finding_toggle_flag': 'Toggle Finding Flag',

        'create_report_request': 'Create Report Request',
        'withdraw_report_request': 'Report Request Withdrawn',
        
        'supplier_finding_toggle_read_flag': 'Toggle Finding Read Status'
    },
    ownCompany     :   {
        'onboard_company'       :   'New company created',
        'first_assessment'      :   'First assessment based on findings',
        'new_report'            :   'New report created',
        'add_comment'           :   'Add Comment',
        'add_internal_comment'  :   'Add Internal Comment',

        'add_team_manager': 'Add Management Team Person',
        'edit_team_manager': 'Edit Management Team Person',
        'delete_team_manager': 'Delete Management Team Person',

        'add_connected_legal': 'Add Connected Legal',
        'delete_connected_legal': 'Delete Connected Legal',
        'update_connected_legal': 'Edit Connected Legal',

        'add_share_holder': 'Add Shareholder',
        'edit_share_holder': 'Edit Shareholder',
        'delete_share_holder': 'Delete Shareholder',

        'add_product': 'Add Product',
        'edit_product': 'Edit Product',
        'delete_product': 'Delete Product',

        'create_supplier_location': 'Add Location',
        'edit_supplier_location': 'Edit Location',
        'delete_supplier_location': 'Delete Location',

        'add_contact_person': 'Add Contact Person',
        'edit_contact_person': 'Edit Contact Person',
        'delete_contact_person': 'Delete Contact Person',

        'update_supplier_status': 'Update Third Party Status',
        'edit_supplier_analyst_admin': 'Update Assigned Analyst',
        'edit_supplier_general_information': 'Edit General Information',
        'supplier_toggle_flag': 'Toggle Flag',
        'supplier_finding_toggle_flag': 'Toggle Finding Flag',

        'create_report_request': 'Create Report Request',
        'withdraw_report_request': 'Report Request Withdrawn',

        'supplier_finding_toggle_read_flag': 'Toggle Finding Read Status'
    },
    entityManagement : {
        add_institute  :  'Add Institute',
        edit_institute  :  'Edit Institute',
        remove_institute : 'Remove Institute',
        add_division: 'Add Division',
        edit_division: 'Edit Division',
        remove_division: 'Remove Division',
        add_legal_entity : 'Add Legal Entity',
        edit_legal_entity: 'Edit Legal Entity',
        remove_legal_entity: 'Remove Legal Entity',
        add_legal_location: 'Add Legal Location',
        edit_legal_location: 'Edit Legal Location',
        remove_legal_location: 'Remove Legal Location',
        edit_routing: 'Edit Routing Settings',
        edit_financial_setting: 'Edit Financial Year Settings'
    },
    questionnaireDesigner: {
        create_questionnaire: 'Create Questionnaire',
        clone_questionnaire: 'Clone Questionnaire',
        update_questionnaire: 'Edit Questionnaire',
        active_questionnaire: 'Activate Questionnaire',
        in_active_questionnaire: 'Deactivate Questionnaire',
        delete_questionnaire: 'Delete Questionnaire'
    }
};

export const HistoryLogSections = {
	risk        :   'Risk Management',
	task        :   'Task Management',
	admin       :   'Administration',
	report      :   'Report',
	document    :   'Document Management',
	user        :   "User",
    supplier    :   'Supplier',
    ownCompany  :   'Company',
    entityManagement: 'Entity Management',
    connectedLegal: 'Connected Legal Entities',
    shareHolder: 'Shareholders',
    product: 'Products',
    questionnaireDesigner: 'Questionnaire Designer'
};

export const HistoryLogRoles = {
	ROLE_ANALYST_ADMIN  :   'Analyst admin',
	ROLE_ANALYST        :   'Analyst',
	ROLE_TASK_OWNER     :   'Task Owner',
	ROLE_THIRD_PARTY    :   'Third Party',
	ROLE_EMPLOYEE       :   'Employee'
};

export const TasksStatuses = {
	NEW				:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	IN_PROGRESS		:	{
		title	:	'In Progress',
		color	:	'#8792E8'
	},
	REVIEW			:	{
		title	:	'Review',
		color	:	'#85FF8A'
	},
	CLOSED			:	{
		title	:	'Closed',
		color	:	'#EDECEC'
	},
	DELETED			:	{
		title	:	'Deleted',
		color	:	'#FFDADA'
	}
}

export const TasksPriorities = {
	low		:	{
		title	:	"Low",
		color	:	"#1CBB8C",
		icon	:	<HiChevronDoubleDown />
	},
	medium	:	{
		title	:	"Medium",
		color	:	"#FCC34B",
		icon	:	<HiMenuAlt4 />
	},
	high	:	{
		title	:	"High",
		color	:	"#DC3545",
		icon	:	<HiChevronDoubleUp />
	}
};

export const SuppliersStatuses = {					
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Under Review',
		color	:	'#74B8FF'
	},
	2	:	{
		title	:	'Approved',
		color	:	'#4CD4AC'
	},
	3	:	{
		title	:	'Rejected',
		color	:	'#BE4F59'
	}
}

export const CompanySizes = {
	"0-10"	:	{
		title	:	"0-10"
	},
	"10-50"	:	{
		title	:	"10-50"
	},
	"50-100"	:	{
		title	:	"50-100"
	},
	"100-500"	:	{
		title	:	"100-500"
	},
	"500-1000"	:	{
		title	:	"500-1000"
	},
	"1000-2000"	:	{
		title	:	"1000-2000"
	},
	"2000-5000"	:	{
		title	:	"2000-5000"
	},
	"+5000"		:	{
		title	:	"+5000"
	}
};

export const RisksRates = {
    "low": {
        title: "Low",
        color: "#1CBB8C"
    },
    "medium": {
        title: "Medium",
        color: "#FCC34B"
    },
    "high": {
        title: "High",
        color: "#DC3545"
    },

    
    "insignificant": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "minor": {
        title: "Minor",
        color: "#59C94F"
    },
    "noticeable": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "critical": {
        title: "Critical",
        color: "#FF4949"
    },
    "existence - threatening": {
        title: "Existence - threatening",
        color: "#822626"
    },
}

export const customFonts = [
    {
      style: "normal",
      family: "Inter",
      weight: 400,
    },
    {
      style: "normal",
      family: "Nunito",
      weight: 400,
    },
    {
      style: "normal",
      family: "Raleway",
      weight: 400,
    },
    {
      style: "normal",
      family: "Roboto Mono",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Serif Toto",
      weight: 400,
    },
    {
      style: "normal",
      family: "Kumbh Sans",
      weight: 400,
    },
    {
      style: "normal",
      family: "Padyakke Expanded One",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Sans Cherokee",
      weight: 400,
    },
  ];

export const DocumentsTypes = [
    {
        typeKey: 'regular',
        title: 'Other'
    },
    {
        typeKey: 'user_policy',
        title: 'User policy'
    },
    {
        typeKey: 'privacy_policy',
        title: 'Privacy policy'
    }
];

export const SuppliersScorings = {
    "highRisk": {
        title: "High Risk",
        color: "#822626"
    },
    "moderate": {
        title: "Moderate Risk",
        color: "#FF4949"
    },
    "average": {
        title: "Average",
        color: "#FFA049"
    },
    "lowRisk": {
        title: "Low Risk",
        color: "#59C94F"
    },
    "excellent": {
        title: "Excellent",
        color: "#4EB8B1"
    }
}

export const SupplierLatestReportsStatuses = {
    "submitted"   :   {
        title       :   "Submitted",
        color       :   "#d4edda",
        fontColor   :   "#155724" 
    },
    "not_requested"    :   {
        title       :   "Not Requested",
        color       :   "#f5f5f5",
        fontColor   :   "#6c757d"
    },
    "requested"    :   {
        title       :   "Requested",
        color       :   "#d9f7ff",
        fontColor   :   "#6c757d"
    },
    "waiting"  :   {
        title       :   "Waiting",
        color       :   "#fff9db",
        fontColor   :   "#856404"
    },
    "expired"  :   {
        title       :   "Expired",
        color       :   "#f8d7da",
        fontColor   :   "#721c24"
    },
    "withdrawn": {
        title       :   "Withdrawn",
        color       :   "#FFA500",
        fontColor   :   "#155724"
    }
}

/**
 * The colors are the keys that api returns
 */
export const ResidualRisksRates = {
    "4EB8B1": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "59C94F": {
        title: "Minor",
        color: "#59C94F"
    },
    "FFA049": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "FF4949": {
        title: "Critical",
        color: "#FF4949"
    },
    "822626": {
        title: "Existence - threatening",
        color: "#822626"
    }
}

export const QuestionnaireStatuses = {
    'active' : {
        title: 'Active',
        color: "#4CD4AC",
        fontColor: "#ffffff" 
    },
    'in_active' : {
        title: 'Inactive',
        color: "#F4D03F",
        fontColor: "#ffffff" 
    },
    'deleted' : {
        title: 'Deleted',
        color: "#BE4F59",
        fontColor: "#ffffff" 
    }
}