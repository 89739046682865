import { useCallback, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Label, ListGroup, ListGroupItem, Badge, Row, Col } from "reactstrap";
import { API_BASE_URL, EMPTY_LIST } from "src/common/constants";
import UserUtils from "src/services/utils/UserUtils";
import axios from "axios";
import { toast } from "react-toastify";
import AsyncReactSelect from "src/components/AsyncReactSelect";
import EntityManagementService from "src/apis/EntityManagementService";
import { 
    isFeatureEnabledForActiveModule
} from "src/helpers/module_helper";

const TPDDEntitiesList = ({
    t,
    assignedAnalyst,
    user,
    reportIsDeleted,
    reportCaseId,
    defaultValues = [],
    token,
    modules
}) => {
    const entityManagementService = EntityManagementService.getInstance();

    const userUtils = new UserUtils();
    const [expandedThirdparties, setExpandedThirdparties] = useState(false);
    const [selectedLegals, setSelectedLegals] = useState({
        isLoading: false,
        items: defaultValues.map((item) => {
            return {
                value: item.id,
                label: item.title
            }
        })
    });

    const apiBaseUrl = modules.active === 'cm' ? `${API_BASE_URL}/cm` : API_BASE_URL;

    const userType = userUtils.isAnalystAdmin(user) ? 'admin' : (
        userUtils.isAnalyst(user) ? 'analyst' : 'else'
    );

    const fetchLegalEntitiesOptions = useCallback(async (params) => {
        const response = await entityManagementService.fetchEntitesList(params, modules.active);

        const options = response.legalEntities.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, []);

    const handleAddLegal = (id) => {
        setSelectedLegals((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${apiBaseUrl}/report_case/add_legal_entity`,
            {
                reportCaseId: reportCaseId,
                legalEntityId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedLegals((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.legal_entities || []).map((legal) => {
                        return {
                            value: legal.id,
                            label: legal.title
                        }
                    })
                }
            });

            toast(t("The selected legal entity has been successfully added to the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedLegals((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to add the legal entity to the list."), {
                type: "error",
            });
        })
    }

    const handleRemoveLegal = (id) => {
        setSelectedLegals((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${apiBaseUrl}/report_case/remove_legal_entity`,
            {
                reportCaseId: reportCaseId,
                legalEntityId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedLegals((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.legal_entities || []).map((legal) => {
                        return {
                            value: legal.id,
                            label: legal.title
                        }
                    })
                }
            });

            toast(t("The legal entity has been successfully removed from the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedLegals((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to remove the legal entity from the list."), {
                type: "error",
            });
        })
    }

    if(!isFeatureEnabledForActiveModule('entity_management') || userType === 'else' || (
        userType === 'analyst' && user.id !== assignedAnalyst.user?.value
    )) return null;

    return (
        <Col sm="6" md="6" lg="6" className="mb-3">
            <div className="module__tpdd-thirdparties-selection">
                <Label className="form-label text-dark d-flex justify-content-between">
                    {t("Choose related entities")}:
                    <span
                        onClick={() => setExpandedThirdparties(!expandedThirdparties)}
                        className="expand-btn btn-primary"
                    >
                        {expandedThirdparties
                        ? t("Collapse list")
                        : t("Expand list")}
                    </span>
                </Label>

                <div className="items-container">
                    <div>   
                        {selectedLegals.items.length > 0 ? (
                            <ListGroup
                                type="inline"
                                style={{
                                    maxHeight: expandedThirdparties ? "171px" : "51px"
                                }}
                            >
                                {selectedLegals.items.map((thirdparty, index) => {
                                    return (
                                        <ListGroupItem key={index}>
                                            {thirdparty.label}
                                            {(userType === 'admin' || !assignedAnalyst.isReadOnly) && (
                                                <Badge
                                                    className="delete-btn text-dark pt-2"
                                                    color="light"
                                                    onClick={() => {
                                                        handleRemoveLegal(thirdparty.value);
                                                    }}
                                                >
                                                X
                                                </Badge>
                                            )}
                                        </ListGroupItem>
                                    )
                                })}   
                            </ListGroup>
                        ) : (
                            <p
                                className="empty-list-message"
                                style={{
                                    maxHeight: expandedThirdparties ? "163px" : "43px",
                                }}
                            >
                                {t(EMPTY_LIST)}
                            </p>
                        )}

                        {!reportIsDeleted && (
                            <Row className="mt-2">
                                <Col sm='12'>
                                    <AsyncReactSelect 
                                        loadOptions={fetchLegalEntitiesOptions}
                                        settings={{
                                            queryKey: 'title',
                                            loadOptionsErrorMessage: 'Faild to fetch'
                                        }}
                                        value={[]}
                                        onChange={(e) => {
                                            handleAddLegal(e.value)
                                        }}
                                        placeholder={t("Select an entity")}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </div>
        </Col>
    )
};

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;
    const { Modules } = state;
  
    return {
        token,
        user,
        modules: Modules
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps
    )(
        TPDDEntitiesList
    )
);