import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import IndustriesService from "src/modules/3rd-party-management/apis/common/IndustriesService";

const FactorySitesAndProducts = ({
    t,
    setCompanyDetailsTabCompleted,
    goToPrevTab,
    selectedIndustryIds,
    defaultValues,
    isLoading,
    memoizedCountriesList,
    supplierType,
    ...rest
}) => {
    const formRef = useRef();

    const [ officeAddresses, setOfficeAddresses ] = useState([
        {}
    ]);

    const [ factoryAddresses, setFactoryAddresses ] = useState([
        {isTemp: true}
    ]);

    const [ selectedProducts, setSelectedProducts ] = useState([
        {}
    ]);

    const [ factoryIsOptional, setFactoryIsOptional ] = useState(true);

    const checkFactoryStepIsOptional = () => {
        if(!formRef.current) return; 

        const latestValues = formRef.current.getValues();

        const factoryResult = [];
        
        for(let i = 0; i < factoryAddresses.length; i++){
            if(!factoryAddresses[i].isDeleted){
                factoryResult.push(latestValues.factory_addresses[i])
            }
        }

        let isRequired = factoryResult.findIndex((add) => {
            return Object.keys(add).findIndex((keyName) => {
                return !!add[keyName]
            }) > -1
        }) > -1;

        setFactoryIsOptional(!isRequired);
    }

    const getLatestValuesToSend = () => {
        const values = formRef.current.getValues();

        const factoryResult = [];
        const officeResult = [];
        
        for(let i = 0; i < factoryAddresses.length; i++){
            if(!factoryAddresses[i].isDeleted && !factoryAddresses[i].isTemp){
                factoryResult.push(values.factory_addresses[i])
            }
        }

        for(let i = 0; i < officeAddresses.length; i++){
            if(!officeAddresses[i].isDeleted){
                officeResult.push(values.office_addresses[i])
            }
        }

        return {
            ...values,
            factory_addresses: factoryResult,
            office_addresses: officeResult
        };
    }
    
    const {
        data: productsList,
        isFetching: productsAreFetching
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-supplier-industry-products-list'],
        queryFn: async () => {
            const service = IndustriesService.getInstance();
            return await service.fetchProducts({ selectedIndustryIds },rest?.lng);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching products list.'), {
                type: 'error',
            });
        },
    });

    const memoizedProductsList = useMemo(() => {
        return (productsList || []).map((product) => {
            return {
                value: product.id,
                baseLabel: product.name,
                label: `${product.class} - ${t(product.name)}`
            }
        })
    }, [ productsList, t ]);

    useEffect(() => {
        setFactoryAddresses((currentState) => {
            return [...currentState].map((address) => {
                if(address.country){
                    return {
                        ...address,
                        country: {
                            ...address.country,
                            label: t(address?.country?.baseLabel)
                        }
                    }
                }
            
                return address;
            })
        });

        setOfficeAddresses((currentState) => {
            return [...currentState].map((address) => {
                if(address.country){
                    return {
                        ...address,
                        country: {
                            ...address.country,
                            label: t(address?.country?.baseLabel)
                        }
                    }
                }
            
                return address;
            })
        });
    }, [ t ]);

    useEffect(() => {
        if(!isLoading){
            if(defaultValues?.office_addresses && Array.isArray(defaultValues.office_addresses) && defaultValues.office_addresses.length > 0){
                setOfficeAddresses(
                    defaultValues.office_addresses.filter((address) => {
                        return !address.isDeleted
                    })
                );
            }

            if(defaultValues?.factory_addresses && Array.isArray(defaultValues.factory_addresses)){
                setFactoryAddresses(
                    defaultValues.factory_addresses.filter((address) => {
                        return !address.isDeleted
                    })
                );
            }

            if(defaultValues?.products && Array.isArray(defaultValues.products)){
                setSelectedProducts(
                    defaultValues.products.filter((product) => {
                        return !product.isDeleted
                    })
                )
            }
        }
    }, [ isLoading ]);

    useEffect(() => {
        checkFactoryStepIsOptional()
    }, [ factoryAddresses ]);

    const handleGoBack = () => {
        goToPrevTab(getLatestValuesToSend())
    }

    if(isLoading){
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size='sm' className="me-1" color="primary" />
                {t('Loading...')}
            </div>
        )
    }

    return (
        <AvForm
            className="needs-validation"
            onValidSubmit={() => {
                setCompanyDetailsTabCompleted(getLatestValuesToSend())
            }}
            id="factory_sites_and_products"
            autocomplete="off"
            ref={formRef}
        >
            <Card className="wizard-steps-content-container">
                <CardBody className="p-0">
                    <p className="sub-header medium">
                        {t("Location")}
                    </p>

                    <label>{t("Office Address")}: </label>
                    {officeAddresses.map((address, index) => {
                        return (
                            <Row key={index} className="mb-2" hidden={address.isDeleted}>
                                <Col md="3" sm="12">
                                    <Select
                                        placeholder={t("Country")}
                                        classNamePrefix="select2-selection"
                                        options={memoizedCountriesList}
                                        menuPortalTarget={document.body}
                                        onChange={(e) => {
                                            setOfficeAddresses((currentState) => {
                                                const newState = [
                                                    ...currentState
                                                ];

                                                newState[index] = {
                                                    ...address,
                                                    country: e
                                                };

                                                return newState;
                                            })
                                        }}
                                        value={address.country}
                                    />
                                    <AvField
                                        name={`office_addresses[${index}][country]`}
                                        type="hidden"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={address?.country || null}
                                    />
                                </Col>

                                <Col md="2" sm="12">
                                    <AvField
                                        name={`office_addresses[${index}][postalCode]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("Postal Code")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={address.postalCode}
                                    />
                                </Col>

                                <Col md="2" sm="12">
                                    <AvField
                                        name={`office_addresses[${index}][city]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("City")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={address.city}
                                    />
                                </Col>

                                <Col md="4" sm="12">
                                    <AvField
                                        name={`office_addresses[${index}][extra]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("Street, Number")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={address.extra}
                                    />
                                </Col>

                                <Col md="1" sm="12" hidden={officeAddresses.filter((add) => !add.isDeleted).length === 1}>
                                    <h4 className="p-1 text-danger">
                                        <RiDeleteBin5Line 
                                            role="button"
                                            onClick={() => {
                                                setOfficeAddresses((currentState) => {
                                                    const newState = [...currentState];
                                                    newState[index].isDeleted = true;
                                                    return newState;
                                                });

                                                if(formRef.current){
                                                    const inputs = formRef.current.getInputs();
                                                    formRef.current.unregisterInput(inputs[`office_addresses[${index}][country]`]);
                                                    formRef.current.unregisterInput(inputs[`office_addresses[${index}][postalCode]`]);
                                                    formRef.current.unregisterInput(inputs[`office_addresses[${index}][city]`]);
                                                    formRef.current.unregisterInput(inputs[`office_addresses[${index}][extra]`]);
                                                }
                                            }}
                                        />
                                    </h4>
                                </Col>
                            </Row>
                        )
                    })}

                    <Row className="mt-3">
                        <Col md="10"></Col>
                        <Col md="2">
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    setOfficeAddresses((currentState) => {
                                        return [...currentState, {}]
                                    })
                                }}
                            >
                                {" + " + t("Add Location")}
                            </Button>
                        </Col>
                    </Row>

                    <label>{t("Location")}: </label>
                    {factoryAddresses.map((address, index) => {
                        return (
                            <Row key={index} className="mb-2" hidden={address.isDeleted}>
                                <Col md="3" sm="12">
                                    <Select
                                        isClearable={true}
                                        placeholder={t("Country")}
                                        classNamePrefix="select2-selection"
                                        options={memoizedCountriesList}
                                        menuPortalTarget={document.body}
                                        onChange={(e) => {
                                            setFactoryAddresses((currentState) => {
                                                const newState = [
                                                    ...currentState
                                                ];

                                                newState[index] = {
                                                    ...address,
                                                    isTemp: false,
                                                    country: e
                                                };

                                                return newState;
                                            })
                                            checkFactoryStepIsOptional()
                                        }}
                                        value={address.country}
                                    />
                                    <AvField
                                        name={`factory_addresses[${index}][country]`}
                                        type="hidden"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: !factoryIsOptional && !address.isDeleted },
                                        }}
                                        value={address?.country || null}
                                    />
                                </Col>

                                <Col md="2" sm="12">
                                    <AvField
                                        name={`factory_addresses[${index}][postalCode]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("Postal Code")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: !factoryIsOptional && !address.isDeleted },
                                        }}
                                        value={address.postalCode}
                                        onBlur={checkFactoryStepIsOptional}
                                    />
                                </Col>

                                <Col md="2" sm="12">
                                    <AvField
                                        name={`factory_addresses[${index}][city]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("City")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: !factoryIsOptional && !address.isDeleted },
                                        }}
                                        value={address.city}
                                        onBlur={checkFactoryStepIsOptional}
                                    />
                                </Col>

                                <Col md="4" sm="12">
                                    <AvField
                                        name={`factory_addresses[${index}][extra]`}
                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                        placeholder={t("Street, Number")}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: !factoryIsOptional && !address.isDeleted },
                                        }}
                                        value={address.extra}
                                        onBlur={checkFactoryStepIsOptional}
                                    />
                                </Col>

                                <Col 
                                    md="1" 
                                    sm="12" 
                                >
                                    <h4 className="p-1 text-danger">
                                        <RiDeleteBin5Line 
                                            role="button"
                                            onClick={() => {
                                                setFactoryAddresses((currentState) => {
                                                    const newState = [...currentState];
                                                    newState[index].isDeleted = true;
                                                    return newState;
                                                });

                                                if(formRef.current){
                                                    const inputs = formRef.current.getInputs();
                                                    formRef.current.unregisterInput(inputs[`factory_addresses[${index}][country]`]);
                                                    formRef.current.unregisterInput(inputs[`factory_addresses[${index}][postalCode]`]);
                                                    formRef.current.unregisterInput(inputs[`factory_addresses[${index}][city]`]);
                                                    formRef.current.unregisterInput(inputs[`factory_addresses[${index}][extra]`]);
                                                }
                                            }}
                                        />
                                    </h4>
                                </Col>
                            </Row>
                        )
                    })}

                    <Row className="mt-3">
                        <Col md="10"></Col>
                        <Col md="2">
                            <Button
                                color="primary"
                                outline
                                onClick={() => {
                                    setFactoryAddresses((currentState) => {
                                        return [...currentState, {}]
                                    })
                                }}
                            >
                                {" + " + t("Add Location")}
                            </Button>
                        </Col>
                    </Row>

                    <label>
                        {t(supplierType === 'own' ? "List Of Products" : "List of products supplied")}
                    </label>

                    <Row className="mb-2">
                        <Col sm='12'>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }}>{t("Product Name")}</th>
                                            <th style={{ width: "70%" }}>{t("Description")}</th>
                                            <th style={{ width: "10%" }}>{t("Action")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {selectedProducts.map((product, index) => {
                                            return (
                                                <tr key={index} hidden={product.isDeleted}>
                                                    <td>
                                                        <Select
                                                            isLoading={ productsAreFetching }
                                                            isDisabled={ productsAreFetching }
                                                            placeholder={t("Select...")}
                                                            classNamePrefix="select2-selection"
                                                            options={ memoizedProductsList }
                                                            menuPortalTarget={document.body}
                                                            onChange={(e) => {
                                                                setSelectedProducts((currentState) => {
                                                                    const newState = [
                                                                        ...currentState
                                                                    ];
                    
                                                                    newState[index] = {
                                                                        ...product,
                                                                        product: e
                                                                    };
                    
                                                                    return newState;
                                                                })
                                                            }}
                                                            value={ product.product }
                                                        />
                                                        <AvField
                                                            name={`products[${index}][product]`}
                                                            type="hidden"
                                                            errorMessage={t("This field cannot be blank")}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            value={ product.product }
                                                        />
                                                    </td>

                                                    <td>
                                                        <AvField
                                                            name={`products[${index}][description]`}
                                                            type="text"
                                                            errorMessage={t("This field cannot be blank")}
                                                            className="form-control"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            value={product.description}
                                                        />
                                                    </td>

                                                    <td>
                                                        <RiDeleteBin5Line
                                                            role="button"
                                                            className="text-danger"
                                                            onClick={() => {
                                                                setSelectedProducts((currentState) => {
                                                                    const newState = [...currentState];
                                                                    newState[index].isDeleted = true;
                                                                    return newState;
                                                                });

                                                                if(formRef.current){
                                                                    const inputs = formRef.current.getInputs();
                                                                    formRef.current.unregisterInput(inputs[`products[${index}][product]`]);
                                                                    formRef.current.unregisterInput(inputs[`products[${index}][description]`]);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                        <tr>
                                            <td colSpan='3'>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            setSelectedProducts((currentState) => {
                                                                return [
                                                                    ...currentState,
                                                                    {}
                                                                ]
                                                            })
                                                        }
                                                        outline
                                                    >
                                                        {" + " + t("Add Product")}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row className="mt-2 mb-4">
                <Col sm='12' className="d-flex justify-content-between align-items-center">
                    <Button color="primary" onClick={handleGoBack} outline>
                        {t("Back")}
                    </Button>

                    <Button color="primary" type="submit">
                        {t("Next")}
                    </Button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default withNamespaces()(
    FactorySitesAndProducts
);
