import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, Label, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import Select from "react-select";
import { useMutation, useQuery } from '@tanstack/react-query';
import IndustriesService from "src/modules/3rd-party-management/apis/common/IndustriesService";
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/common/ThirdpartyUsersService";
import { RiDeleteBin5Line } from "react-icons/ri";
import SupplierService from 'src/modules/3rd-party-management/apis/thirdparty/SupplierService';
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import CreateAndEditModal from './components/CreateAndEditModal';
import DateUtils from 'src/services/utils/DateUtils';
import Accordion from 'react-bootstrap/Accordion';
import GeneralInformationImage from "src/modules/3rd-party-management/assets/images/general-information.svg";
import ContactPersonImage from "src/modules/3rd-party-management/assets/images/contact-person.svg";
import ShareholderImage from "src/modules/3rd-party-management/assets/images/shareholders.svg";
import ManagementTeamImage from "src/modules/3rd-party-management/assets/images/management-team.svg";
import LocationImage from "src/modules/3rd-party-management/assets/images/location.svg";
import ManagementTeam from './components/management-team';

const CompanyDetails = ({
    t,
    lng,
    onSubmitSucceed,
    Organization
}) => {
    const [ countries, setCountries ] = useState([]);
    const [ industries, setIndustries ] = useState([]);
	const [ companyDetails, setCompanyDetails ] = useState({});
    const [ officeAddresses, setOfficeAddresses ] = useState([{}]);
    const [ factoryAddresses, setFactoryAddresses ] = useState([{}]);
    const [ shareholders, setShareholders ] = useState({
        individual    :   [],
        company       :   []
    });
    const [ managementTeam, setManagementTeam ] = useState([]);
    const [ responsiblePerson, setResponsiblePerson ] = useState({});
    const [ responsiblePersonTitleDrop, setResponsiblePersonTitleDrop ] = useState(false);
    const [ showShareholderModal, setShowShareholderModal ] = useState(false);
    const [ selectedShareholderToEdit, setSelectedShareholderToEdit ] = useState(null);
    const [ shareholderToDelete, setShareholderToDelete ] = useState(null);
    const [ activeAccordion, setActiveAccordion ] = useState('general-information');

    const dateUtils = new DateUtils();

    const handleFetchCompanyDetailQuery = useQuery({
        queryKey: ['3rd-party-management-fetch-supplier-detail-query'],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchCompanyDetail();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled:true,
        onError: (error) => {
            toast(t('An error occurred while fetching supplier information.'), {
                type: 'error',
            });
        }
	});

    const handleFetchIndustriesListQuery = useQuery({
		queryKey: [
            '3rd-party-management-fetch-industries-list-query',
            lng
        ],
		queryFn: async () => {
			const service = IndustriesService.getInstance();

			return await service.fetchList({
                language: lng !== 'en' ? lng : undefined    
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching industries list.'), {
				type: 'error',
			});
		}
	});

    const handleFetchCountriesListQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-countries-list-query'],
		queryFn: async () => {
			const service = ThirdpartyUsersService.getInstance();

			return await service.fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const handleUpdateCompanyDetailMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();

            return await service.updateCompanyDetail(payload);
        },
        onSuccess: () => {
            onSubmitSucceed && onSubmitSucceed();
        },
        onError: () => {
            toast(t("An error occurred while updating company details."), {
                type: "error",
            });
        }
    });

    const languages = (Organization?.languages || []).map((lng) => {
        return {
            value       :   lng?.language?.id,
            baseLabel   :   lng.name,
            label       :   t(lng.name),
            code        :   lng.language_code,
        }
    });

    const handleValidSubmit = (e, values) => {
        const locations = ((values.office_addresses || []).map((address) => {
            return {
                ...address,
                type        :   'office',
                locationId  :   address.locationId || undefined
            }
        })).concat((values.factory_addresses || []).map((address) => {
            return {
                ...address,
                type        :   'manufacture',
                locationId  :   address.locationId || undefined
            }
        }));

        handleUpdateCompanyDetailMutation.mutate({
            generalInfo         :   values.company_details,
            responsiblePerson   :   values.responsible_person,
            locations           :   locations,
            individualShareHolders  :   shareholders.individual.map((shareholder) => {
                return {
                    ...shareholder,
                    shareHolderId   :   shareholder.id,
                    id              :   undefined,
                    index           :   undefined
                }
            }),
            companyShareHolders     :   shareholders.company.map((shareholder) => {
                return {
                    ...shareholder,
                    shareHolderId   :   shareholder.id,
                    id              :   undefined,
                    index           :   undefined
                }
            }),
            teamManagers: managementTeam.map((teamItem) => {
                return {
                    ...teamItem,
                    managerId: !teamItem.isNew ? teamItem.id : undefined,
                    id: undefined,
                    isNew: undefined,
                    createdBy: undefined
                }
            })
        });
    }

    useEffect(() => {
		if(handleFetchIndustriesListQuery?.data){
			const data = Array.isArray(handleFetchIndustriesListQuery.data) ? handleFetchIndustriesListQuery.data : [];

			const list = data.map((industry) => {
                return {
                    value       :   industry.id,
                    baseLabel   :   industry.title,
                    label       :   `${industry.class} - ${industry.title}`
                };
			});

            setIndustries(list);

            const newSelectedIndustries = (companyDetails?.industry || []).map((industry) => {
                return list.find((tIndustry) => tIndustry.value === industry.value)
            });

            setCompanyDetails((currentState) => {
                return {
                    ...currentState,
                    industry: newSelectedIndustries
                }
            });
		}
	}, [ handleFetchIndustriesListQuery.data ]);

    useEffect(() => {
		if(handleFetchCountriesListQuery?.data){
			const data = Array.isArray(handleFetchCountriesListQuery.data) ? handleFetchCountriesListQuery.data : [];

			const list = data.map((country) => {
                return {
                    value       :   country.id,
                    baseLabel   :   country.name,
                    label       :   t(country.name),
                    code        :   country.code,
                };
			});

            setCountries(list);
		}
	}, [ handleFetchCountriesListQuery.data, t ]);

    useEffect(() => {
        if(handleFetchCompanyDetailQuery.data){
            const {
                companyDetail,
                locations,
                teamManagers,
                responsiblePerson   :   responsiblePersonData,
                shareHolders        :   prevShareholders,
            } = handleFetchCompanyDetailQuery.data;

            const selectedIndustries = (companyDetail.industry || []).map((industry) => {
                const translatedIndustry = industries.find((tIndustry) => tIndustry.value === industry.id);

                if(translatedIndustry)
                    return translatedIndustry;
                
                return {
                    value       :   industry.id,
                    baseLabel   :   industry.name,
                    label       :   `${industry.class} - ${industry.name}`
                }
            });

            setCompanyDetails({
                name        :   companyDetail.name,
                industry    :   selectedIndustries,
                companySize :   {
                    value   :   companyDetail.companySize,
                    label   :   companyDetail.companySize + " " + t("Employees")
                },
                websiteUrl  :   companyDetail.websiteUrl,
                brands      :   companyDetail.brands
            });

            setOfficeAddresses([
                ...((locations || []).filter((location) => location.type === 'office').map((location, i) => {
                    const country = countries.find((c) => c.value === location.country);

                    return {
                        locationId  :   location.id,
                        country     :   {
                            value   :   location.country,
                            label   :   country?.label,
                            baseLabel:  country?.baseLabel 
                        },
                        postalCode  :   location.postalCode,
                        city        :   location.city,
                        address     :   location.address
                    }
                }))
            ]);

            setFactoryAddresses([
                ...((locations || []).filter((location) => location.type === 'manufacture').map((location, i) => {
                    const country = countries.find((c) => c.value === location.country);

                    return {
                        locationId  :   location.id,
                        country     :   {
                            value   :   location.country,
                            label   :   country?.label,
                            baseLabel: country?.baseLabel
                        },
                        city        :   location.city,
                        postalCode  :   location.postalCode,
                        address     :   location.address
                    }
                }))
            ]);

            const responsiblePersonLng = languages.find((lang) => lang.value === responsiblePersonData.language) || {};

            setResponsiblePerson({
                ...responsiblePersonData,
                language    :   {
                    ...responsiblePersonLng,
                    value   :   responsiblePersonData.language
                }
            });

            setShareholders({
                individual  :   (prevShareholders?.individual || []).map((shareholder) => {
                    return {
                        ...shareholder,
                        residenceCountry    :   shareholder.residenceCountry?.id,
                        type                :   'individual'
                    }
                }),
                company     :   (prevShareholders?.company || []).map((shareholder) => {
                    return {
                        ...shareholder,
                        country     :   shareholder.country?.id,
                        type        :   'company'
                    }
                })
            });

            setManagementTeam((teamManagers || []));
        }
    }, [ handleFetchCompanyDetailQuery.data]);

    useEffect(() => {
        if(!selectedShareholderToEdit){
            setShowShareholderModal(false)
        }
        else{
            setShowShareholderModal(true)
        }
    }, [selectedShareholderToEdit])
 
    useEffect(() => {
        handleFetchIndustriesListQuery.refetch();
    }, [ lng ]);

    const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;
    const countriesListIsLoading = handleFetchCountriesListQuery.isFetching || handleFetchCountriesListQuery.isLoading;

    const isFormSubmiting = handleUpdateCompanyDetailMutation.isLoading || handleUpdateCompanyDetailMutation.isFetching;

    const isDetailsLoading = handleFetchCompanyDetailQuery.isFetching || handleFetchCompanyDetailQuery.isLoading;

    return (
        <React.Fragment>
            <CreateAndEditModal
                show={showShareholderModal}
                toggle={() => {
                    setShowShareholderModal(false)
                }}
                onSubmit={(type, values, action) => {
                    if(action === 'create'){
                        setShareholders((currentHolders) => {
                            return {
                                ...currentHolders,
                                [type] : [...currentHolders[type], {...values, type : type}]
                            }
                        })
                    }
                    else{
                        setShareholders((currentHolders) => {
                            const newList = [...currentHolders[type]]
                            newList[values.index] = {
                                ...values,
                                type : type
                            }

                            return {
                                ...currentHolders,
                                [type] : newList
                            }
                        })
                    }

                    setShowShareholderModal(false)
                }}
                countries={countries}
                shareholder={selectedShareholderToEdit}
                onClosed={() => setSelectedShareholderToEdit(null)}
            />

            <Modal isOpen={ !!shareholderToDelete } backdrop="static" size="md">
                <ModalHeader toggle={() => setShareholderToDelete(null)}>
                    {t("Confirmation Form")}
                </ModalHeader>

                <ModalBody>
                    <p>
                        {t('Do you want to delete the shareholder?')}
                    </p>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => {
                        setShareholders((currentHolders) => {
                            const newList = [...currentHolders[shareholderToDelete.type]];
                            newList.splice(shareholderToDelete.index, 1);

                            setTimeout(() => {
                                setShareholderToDelete(null)
                            }, 100);

                            return {
                                ...currentHolders,
                                [shareholderToDelete.type] : newList
                            }
                        })
                    }} color="danger" type="button" size="md">
                        { t('Delete') }
                    </Button>

                    <Button onClick={() => setShareholderToDelete(null)} color="secondary" type="button" outline>
                        { t('Cancel') }
                    </Button>
                </ModalFooter>
            </Modal>

            <AvForm onValidSubmit={ handleValidSubmit } className="needs-validation m-2">
                <div>
                    <Accordion className='custom-accordion' defaultActiveKey='general-information' onSelect={(e) => setActiveAccordion(e)}>
                        <Accordion.Item 
                            eventKey='general-information' 
                            className={activeAccordion === 'general-information' ? 'active' : ''}
                        >
                            <Accordion.Header>
                                <img alt='General Information' src={ GeneralInformationImage } />
                                <span>
                                    { t('General Information') }
                                </span>
                            </Accordion.Header>
                            
                            <Accordion.Body>
                                <Row>
                                    <Col sm="12" md="6" lg="4" className="mb-3">
                                        <Label for="company-details-name">
                                            {t("Company Name")}
                                        </Label>
                                        <AvField
                                            disabled={ isDetailsLoading }
                                            id="company-details-name"
                                            required
                                            name={"company_details[name]"}
                                            type="text"
                                            errorMessage={t("This field cannot be blank")}
                                            className="form-control"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={companyDetails?.name}
                                        />
                                    </Col>

                                    <Col sm="12" md="6" lg="4" className="mb-3">
                                        <Label for="company-details-brands">
                                            {t("Brands")}
                                        </Label>
                                        <AvField
                                            disabled={ isDetailsLoading }
                                            id="company-details-brands"
                                            required
                                            name={"company_details[brands]"}
                                            type="text"
                                            errorMessage={t("This field cannot be blank")}
                                            className="form-control"
                                            validate={{
                                                required: { value: false },
                                            }}
                                            value={companyDetails?.brands}
                                        />
                                    </Col>

                                    <Col sm="12" md="6" lg="4" className="mb-3">
                                        <Label for="company-details-industry">
                                            {t("Industries")}
                                        </Label>

                                        <Select
                                            // isDisabled={ industriesListIsLoading || isDetailsLoading }
                                            isMulti
                                            isDisabled={ true }
                                            isLoading={ industriesListIsLoading || isDetailsLoading }
                                            placeholder={t("Select") + "..."}
                                            classNamePrefix="select2-selection"
                                            id='company-details-industry'
                                            options={industries}
                                            menuPortalTarget={document.body}
                                            value={companyDetails.industry}
                                        />

                                        <AvField
                                            name={"company_details[industries]"}
                                            type="hidden"
                                            errorMessage={t("This field cannot be blank")}
                                            className="form-control"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={(companyDetails.industry || []).map((industry) => industry.value.toString())}
                                        />
                                    </Col>

                                    <Col sm="12" md="6" lg="4" className="mb-3">
                                        <Label for="company-details-size">
                                            {t("Company Size")}
                                        </Label>
                                        <Select
                                            isDisabled={ isDetailsLoading }
                                            isLoading={ isDetailsLoading }
                                            placeholder={t("Select") + "..."}
                                            classNamePrefix="select2-selection"
                                            id='company-details-size'
                                            options={[
                                                {
                                                    value: "0-10",
                                                    label: "0-10 " + t("Employees"),
                                                },
                                                {
                                                    value: "10-50",
                                                    label: "10-50 " + t("Employees"),
                                                },
                                                {
                                                    value: "50-100",
                                                    label: "50-100 " + t("Employees"),
                                                },
                                                {
                                                    value: "100-500",
                                                    label: "100-500 " + t("Employees"),
                                                }
                                            ]}
                                            menuPortalTarget={document.body}
                                            onChange={(e) => {
                                                setCompanyDetails((companyDetails) => {
                                                    return {
                                                        ...companyDetails,
                                                        companySize	:	e
                                                    }
                                                });
                                            }}
                                            value={companyDetails.companySize}
                                        />
                                        <AvField name={"company_details[companySize]"}
                                            type="hidden"
                                            errorMessage={t("This field cannot be blank")}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={companyDetails?.companySize?.value}
                                        />
                                    </Col>

                                    <Col sm="12" md="6" lg="8" className="mb-3">
                                        <Label for="company-details-website">
                                            {t("Website")}
                                        </Label>
                                        <AvField name={"company_details[websiteUrl]"}
                                            disabled={ isDetailsLoading }
                                            placeholder=""
                                            type="text"
                                            errorMessage={t("This field cannot be blank")}
                                            className="form-control"
                                            validate={{
                                                required: { value: true },
                                                pattern: {
                                                    value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                                                    errorMessage: t("Please enter a valid URL"),
                                                },
                                            }}
                                            id="company-details-website"
                                            value={companyDetails?.websiteUrl}
                                        />
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item 
                            eventKey='locations'
                            className={activeAccordion === 'locations' ? 'active' : ''}
                        >
                            <Accordion.Header>
                                <img src={ LocationImage } alt="Locations" />
                                <span>
                                    { t('Locations') }
                                </span>
                            </Accordion.Header>
                            
                            <Accordion.Body>
                                <div className='mb-4'>
                                    <Label>
                                        { `${t('Office Address')}: ` }
                                    </Label>
                                    {officeAddresses.map((item, i) => {
                                        return (
                                            <Row key={i}>
                                                <AvInput 
                                                    name={`office_addresses[${i}][locationId]`}
                                                    type="hidden"
                                                    value={ item?.locationId }
                                                />

                                                <Col md="3" sm="12" className="mb-3">
                                                    <Select
                                                        isDisabled={ countriesListIsLoading || isDetailsLoading }
                                                        isLoading={ countriesListIsLoading || isDetailsLoading }
                                                        placeholder={t("Country")}
                                                        classNamePrefix="select2-selection"
                                                        options={countries}
                                                        menuPortalTarget={document.body}
                                                        onChange={(e) => {
                                                            setOfficeAddresses((currentAddresses) => {
                                                                const newAddresses = [...currentAddresses];
                                                                newAddresses[i] = { ...newAddresses[i], country: e };
                                                                return newAddresses;
                                                            });
                                                        }}
                                                        value={officeAddresses[i]?.country ? {
                                                            ...officeAddresses[i]?.country,
                                                            label: t(officeAddresses[i].country.baseLabel)
                                                        } : null}
                                                    />

                                                    <AvField
                                                        name={`office_addresses[${i}][country]`}
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={officeAddresses[i]?.country?.value}
                                                    />
                                                </Col>

                                                <Col md="2" sm="12" className="mb-3">
                                                    <AvField
                                                        name={`office_addresses[${i}][postalCode]`}
                                                        placeholder={t("Postal Code")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            officeAddresses[i].postalCode = e.target.value
                                                        }}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={officeAddresses[i]?.postalCode || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="2" sm="12" className="mb-3">
                                                    <AvField
                                                        name={`office_addresses[${i}][city]`}
                                                        placeholder={t("City")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={(e) => {
                                                            officeAddresses[i].city = e.target.value
                                                        }}
                                                        value={officeAddresses[i]?.city || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <AvField
                                                        name={`office_addresses[${i}][address]`}
                                                        placeholder={t("Street, Number")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            officeAddresses[i].address = e.target.value
                                                        }}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={officeAddresses[i]?.address || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="1" sm="12" className="mb-3" hidden={officeAddresses.length <= 1}>
                                                    <h4 style={{ cursor: "pointer" }} className="p-1 text-danger" >
                                                        <RiDeleteBin5Line onClick={() => {
                                                            setOfficeAddresses([
                                                                ...officeAddresses.filter((_, ix) => ix !== i)
                                                            ]);
                                                        }} />
                                                    </h4>
                                                </Col>
                                            </Row>
                                        );
                                    })}

                                    <Row>
                                        <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button color="primary"
                                                onClick={() => {
                                                    setOfficeAddresses([
                                                        ...officeAddresses,
                                                        {}
                                                    ])
                                                }}
                                                outline>
                                                {" + " + t("Add Location")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-4'>
                                    <Label>
                                        { `${t('Location')}: ` }
                                    </Label>
                                    
                                    {factoryAddresses.map((item, i) => {
                                        return (
                                            <Row key={i}>
                                                <AvInput 
                                                    name={`factory_addresses[${i}][locationId]`}
                                                    type="hidden"
                                                    value={ item?.locationId }
                                                />

                                                <Col md="3" sm="12" className="mb-3">
                                                    <Select placeholder={t("Country")}
                                                        isDisabled={ countriesListIsLoading || isDetailsLoading }
                                                        isLoading={ countriesListIsLoading || isDetailsLoading }
                                                        classNamePrefix="select2-selection"
                                                        id={`factoryCountry-${i}`}
                                                        options={countries}
                                                        menuPortalTarget={document.body}
                                                        onChange={(e) => {
                                                            setFactoryAddresses((currentAddresses) => {
                                                                const newAddresses = [...currentAddresses];
                                                                newAddresses[i] = { ...newAddresses[i], country: e };
                                                                return newAddresses;
                                                            });
                                                        }}
                                                        value={factoryAddresses[i]?.country ? {
                                                            ...factoryAddresses[i]?.country,
                                                            label: t(factoryAddresses[i].country.baseLabel)
                                                        } : null}
                                                    />
                                                    <AvField name={`factory_addresses[${i}][country]`}
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={factoryAddresses[i]?.country?.value || null}
                                                    />
                                                </Col>

                                                <Col md="2" sm="12" className="mb-3">
                                                    <AvField
                                                        name={`factory_addresses[${i}][postalCode]`}
                                                        placeholder={t("Postal Code")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            factoryAddresses[i].postalCode = e.target.value
                                                        }}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={factoryAddresses[i]?.postalCode || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="2" sm="12" className="mb-3">
                                                    <AvField name={`factory_addresses[${i}][city]`}
                                                        placeholder={t("City")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={(e) => {
                                                            factoryAddresses[i].city = e.target.value;
                                                        }}
                                                        value={factoryAddresses[i]?.city || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <AvField name={`factory_addresses[${i}][address]`}
                                                        placeholder={t("Street, Number")}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={(e) => {
                                                            factoryAddresses[i].address = e.target.value;
                                                        }}
                                                        value={factoryAddresses[i]?.address || ''}
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="1" sm="12" className="mb-3" hidden={factoryAddresses.length <= 1}>
                                                    <h4 style={{ cursor: "pointer" }} className="p-1 text-danger">
                                                        <RiDeleteBin5Line onClick={() => {
                                                            setFactoryAddresses([
                                                                ...factoryAddresses.filter((_, ix) => ix !== i)
                                                            ]);
                                                        }} />
                                                    </h4>
                                                </Col>
                                            </Row>
                                        );
                                    })}

                                    <Row>
                                        <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button color="primary"
                                                onClick={() => {
                                                    setFactoryAddresses([
                                                        ...factoryAddresses,
                                                        {}
                                                    ])
                                                }}
                                                outline>
                                                {" + " + t("Add Location")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item 
                            eventKey='management-team'
                            className={activeAccordion === 'management-team' ? 'active' : ''}
                        >
                            <Accordion.Header>
                                <img src={ ManagementTeamImage } alt="Management Team" />
                                <span>
                                    { t('Management Team') }
                                </span>
                            </Accordion.Header>
                            
                            <Accordion.Body>
                                <div className='mb-4'>
                                    <ManagementTeam 
                                        people={managementTeam}
                                        onTeamChange={(newList) => {
                                            setManagementTeam(newList)
                                        }}
                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item 
                            eventKey='responsible-person'
                            className={activeAccordion === 'responsible-person' ? 'active' : ''}
                        >
                            <Accordion.Header>
                                <img src={ ContactPersonImage } alt="Responsible Person" />
                                <span>
                                    { t('Responsible Person') }
                                </span>
                            </Accordion.Header>
                            
                            <Accordion.Body>
                                <div className='mb-4'>
                                    <Row>
                                        <Col sm="12">
                                            <Row>
                                                <Col md="4" sm="4" className="mb-3">
                                                    <Label for="responsible-person-firstname">
                                                        {t("First Name")}
                                                    </Label>

                                                    <AvGroup>
                                                        <div className="d-flex flex-wrap position-relative">
                                                            <ButtonDropdown className="position-absolute" 
                                                                isOpen={ responsiblePersonTitleDrop } 
                                                                toggle={() => {
                                                                    setResponsiblePersonTitleDrop(!responsiblePersonTitleDrop)
                                                                }} 
                                                                style={{
                                                                    left: "0px",
                                                                    top: "0px",
                                                                    width: "74px"
                                                                }}>
                                                                    <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                                                        <span style={{marginRight: '8px'}}>
                                                                            { t(responsiblePerson.title || 'Mr.') }
                                                                        </span>
                                                                    </DropdownToggle>

                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => { 
                                                                            setResponsiblePerson({
                                                                                ...responsiblePerson,
                                                                                title   :   'Mr.'
                                                                            });
                                                                        }}>
                                                                            { t('Mr.') }
                                                                        </DropdownItem>

                                                                        <DropdownItem onClick={() => { 
                                                                            setResponsiblePerson({
                                                                                ...responsiblePerson,
                                                                                title   :   'Mrs.'
                                                                            });
                                                                        }}>
                                                                            { t('Mrs.') }
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                            </ButtonDropdown>

                                                            <AvInput 
                                                                id={`responsible-person-title`}
                                                                name={`responsible_person[title]`}
                                                                type="hidden"
                                                                value={ responsiblePerson.title || 'Mr.'}
                                                            />
                                                
                                                            <AvInput id={`responsible-person-firstname`}
                                                                required
                                                                name={`responsible_person[name]`}
                                                                type="text"
                                                                className="form-control"
                                                                style={{
                                                                    paddingLeft: '78px',
                                                                    flexBasis : '100%'
                                                                }}
                                                                value={ responsiblePerson?.name }
                                                                disabled={ isDetailsLoading }
                                                            />

                                                            <AvFeedback style={{
                                                                flexBasis : '100%'
                                                            }}>
                                                                {t("This field cannot be blank")}
                                                            </AvFeedback>
                                                        </div>
                                                    </AvGroup>
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <Label for="responsible-person-lastname">
                                                        {t("Last Name")}
                                                    </Label>
                                                    <AvField
                                                        name={`responsible_person[lastName]`}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        id='responsible-person-lastname'
                                                        value={ responsiblePerson?.lastName }
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <Label for="responsible-person-position">
                                                        {t("Position")}
                                                    </Label>
                                                    <AvField
                                                        name={`responsible_person[position]`}
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        id='responsible-person-position'
                                                        value={ responsiblePerson?.position }
                                                        disabled={ isDetailsLoading }
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="4" sm="12" className="mb-3">
                                                    <Label for="responsible-person-language">
                                                        {t("Language")}
                                                    </Label>
                                                    <Select
                                                        isDisabled={ isDetailsLoading }
                                                        isLoading={ isDetailsLoading }
                                                        placeholder={t("Select") + "..."}
                                                        classNamePrefix="select2-selection"
                                                        id='responsible-person-language'
                                                        options={ languages }
                                                        menuPortalTarget={document.body}
                                                        onChange={(e) => {
                                                            setResponsiblePerson({
                                                                ...responsiblePerson,
                                                                language    :   e
                                                            });
                                                        }}
                                                        value={ responsiblePerson?.language ? {
                                                            ...responsiblePerson.language,
                                                            label: t(responsiblePerson.language.baseLabel)
                                                        } : null }
                                                    />
                                                    <AvField
                                                        name={`responsible_person[language]`}
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={responsiblePerson?.language?.value}
                                                    />
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <Label for="responsible-person-email">
                                                        {t("Email")}
                                                    </Label>
                                                    <AvField
                                                        name={`responsible_person[email]`}
                                                        type="email"
                                                        className="form-control"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: t("This field cannot be blank"),
                                                            },
                                                            email: { errorMessage: t("Your email is invalid") },
                                                        }}
                                                        id={`responsible-person-email`}
                                                        value={responsiblePerson?.email}
                                                        disabled={ true }
                                                    />
                                                </Col>

                                                <Col md="4" sm="12" className="mb-3">
                                                    <Label for="responsible-person-phone">
                                                        {t("Phone Number")}
                                                    </Label>

                                                    <LocalizedPhoneInput
                                                        inputClass="w-100"
                                                        country={ "us" }
                                                        inputStyle={{
                                                            height  :   '38px'
                                                        }}
                                                        disableInitialCountryGuess={ false }
                                                        disableCountryGuess={ false }
                                                        onChange={ (e) => {
                                                            setResponsiblePerson({
                                                                ...responsiblePerson,
                                                                phoneNumber    :   e
                                                            });
                                                        }}
                                                        value={ responsiblePerson?.phoneNumber }
                                                        disabled={ isDetailsLoading }
                                                    />

                                                    <AvField
                                                        name={`responsible_person[phoneNumber]`}
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        id={`responsible-person-phone`}
                                                        value={ responsiblePerson?.phoneNumber }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item 
                            eventKey='holders'
                            className={activeAccordion === 'holders' ? 'active' : ''}
                        >
                            <Accordion.Header>
                                <img src={ ShareholderImage } alt="Shareholders" />
                                <span>
                                    { t('Shareholders') }
                                </span>
                            </Accordion.Header>
                            
                            <Accordion.Body>
                                <div className='mb-4'>
                                    <Row>
                                        <Col sm='12'>
                                            <Label>
                                                {t('Individual')}
                                            </Label>
                                            <div className='table-container table-responsive'>
                                                <table className='border-0'>
                                                    <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{t('Salutation')}</th>
                                                                <th>{t('First Name')}</th>
                                                                <th>{t('Last Name')}</th>
                                                                <th>{t('Name In Local Language')}</th>
                                                                <th>{t('Last Name In Local Language')}</th>
                                                                <th>{t('Birthday')}</th>
                                                                <th>{t('Resident In Country')}</th>
                                                                <th>{t('Percentage')}</th>
                                                                <th>{t('UBO')}</th>
                                                                <th>{t('Action')}</th>
                                                            </tr>
                                                    </thead>
                                                    {industriesListIsLoading || countriesListIsLoading || isDetailsLoading ? (
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                                <td>
                                                                    <div className="dt-field dt-skeleton"></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            {!shareholders.individual.length && (
                                                                <tr>
                                                                    <td colSpan={11}>
                                                                        <Alert className='text-center' color='warning'>
                                                                            { t('This list is empty') }
                                                                        </Alert>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {shareholders.individual.map((item, i) => {
                                                                const country = countries.find((c) => c.value === item.residenceCountry)

                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{t(item.title)}</td>
                                                                        <td>{item.firstName}</td>
                                                                        <td>{item.lastName}</td>
                                                                        <td>{item.localLanguageFirstName}</td>
                                                                        <td>{item.localLanguageLastName}</td>
                                                                        <td>{item.birthDay && dateUtils.convertDateToDate(item.birthDay)}</td>
                                                                        <td>{country?.label}</td>
                                                                        <td>{item.percentage}%</td>
                                                                        <td>{item.isUbo ? t('Yes') : t('No')}</td>
                                                                        <td>
                                                                            <Button onClick={() => {
                                                                                setSelectedShareholderToEdit({
                                                                                    ...item,
                                                                                    index : i
                                                                                })
                                                                            }} outline color="primary" size="md" className="border-0">
                                                                                <i className="ri-pencil-line font-size-20"></i>
                                                                            </Button>

                                                                            <Button onClick={() => {
                                                                                setShareholderToDelete({
                                                                                    index   :   i,
                                                                                    type    :   'individual'
                                                                                })
                                                                            }} type='button' outline color="danger" size="md" className="border-0">
                                                                                <i className="ri-delete-bin-line font-size-20"></i>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    )}
                                                </table>     
                                            </div>
                                        </Col>
                                        <Col sm='12'>
                                            <Label>
                                                {t('Company')}
                                            </Label>
                                            <div className='table-container table-responsive'>
                                                <table className='border-0'>
                                                    <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{t('Name')}</th>
                                                                <th>{t('VAT ID')}</th>
                                                                <th>{t('Name In Local Language')}</th>
                                                                <th>{t('Founding Date')}</th>
                                                                <th>{t('Country')}</th>
                                                                <th>{t('Percentage')}</th>
                                                                <th>{t('Action')}</th>
                                                            </tr>
                                                    </thead>
                                                    {industriesListIsLoading || countriesListIsLoading || isDetailsLoading ? (
                                                        <tbody>
                                                            <tr>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                                <td><div className="dt-field dt-skeleton"></div></td>
                                                            </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            {!shareholders.company.length && (
                                                                <tr>
                                                                    <td colSpan={11}>
                                                                        <Alert className='text-center' color='warning'>
                                                                            { t('This list is empty') }
                                                                        </Alert>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {shareholders.company.map((item, i) => {
                                                                const country = countries.find((c) => c.value === item.country)

                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{item.companyName}</td>
                                                                        <td>{item.vatNumber}</td>
                                                                        <td>{item.localLanguageCompanyName}</td>
                                                                        <td>{item.foundingDate && dateUtils.convertDateToDate(item.foundingDate)}</td>
                                                                        <td>{country?.label}</td>
                                                                        <td>{item.percentage}%</td>
                                                                        <td>
                                                                            <Button onClick={() => {
                                                                                setSelectedShareholderToEdit({
                                                                                    ...item,
                                                                                    index : i
                                                                                })
                                                                            }} outline color="primary" size="md" className="border-0">
                                                                                <i className="ri-pencil-line font-size-20"></i>
                                                                            </Button>

                                                                            <Button onClick={() => {
                                                                                setShareholderToDelete({
                                                                                    index   :   i,
                                                                                    type    :   'company'
                                                                                })
                                                                            }} outline color="danger" size="md" className="border-0">
                                                                                <i className="ri-delete-bin-line font-size-20"></i>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    )}
                                                </table>     
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button type="button" 
                                                color="primary" 
                                                outline
                                                onClick={() => setShowShareholderModal(true)}>
                                                {" + " + t("Add Shareholder")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

                <div className="d-flex justify-content-end align-items-center mb-4">
                    <Button color="primary" 
                        className="tpdd-btn" 
                        type="submit"
                        disabled={ isFormSubmiting }>
                            {
                                isFormSubmiting && (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                )
                            }
                        {t('Next')}
                    </Button>
                </div>
            </AvForm>
        </React.Fragment>
    )
};

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        CompanyDetails
    )
);