import * as React from 'react'
import { memo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'

const ApexLine = (props) => {
    const chartData = React.useMemo(() => {
        let series = []

        if (props.createdRisksAndTasks) {
            const {
                allCreatedRisksCount = 0,
                mineCreatedRisksCount = 0,
                allMitigatedRisksCount = 0,
                mineMitigatedRisksCount = 0,
                allAcceptedRisksCount = 0,
                mineAcceptedRisksCount = 0
            } = props.createdRisksAndTasks
            series = [
                {
                    name: props.t('Total'),
                    data: [
                        allCreatedRisksCount,
                        allMitigatedRisksCount,
                        allAcceptedRisksCount
                    ]
                },
                {
                    name: props.t('Mine'),
                    data: [
                        mineCreatedRisksCount,
                        mineMitigatedRisksCount,
                        mineAcceptedRisksCount
                    ]
                }
            ]
        }

        return {
            series: series,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%', // Increased column width
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                colors: ['#5B3E62', '#78E09B', '#f46a6a'],
                xaxis: {
                    categories: [
                        props.t('Total Risks'),
                        props.t('Mitigated Risks'),
                        props.t('Accepted Risks')
                    ]
                },
                grid: {
                    borderColor: '#f1f1f1'
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            }
        }
    }, [props.t, props.createdRisksAndTasks])

    if (props.isLoading) {
        return (
            <React.Fragment>
                <div
                    className='dt-field dt-skeleton mb-2'
                    style={{ width: '30%' }}
                />
                <div
                    className='dt-field dt-skeleton'
                    style={{ width: '100%', height: '320px' }}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <h5 className='mb-3'>
                {props.t('The number of risks and tasks created')}
            </h5>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type='bar'
                height={320}
            />
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization } = state
    return {
        user: state.Login.user,
        token,
        organization: Organization
    }
}

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {})(memo(ApexLine)))
)
