import { 
	ACTION_EDIT_INFO_ORGANIZATION_FAILURE, 
	ACTION_GET_INFO_ORGANIZATION_SUCCESS, 
	ACTION_EDIT_INFO_ORGANIZATION_SUCCESS,
    ACTION_REPLACE_MODULE_CUSTOM_INFOS
} from './actionTypes';

const initialState = {
	id		:	null,
	name: '',
	logo: '',
	intro: '',
	status: '',
	isDemo: false,

	translations: [],
	introTranslations: [],

	primaryFont: null,
	path: null,
	url: null,
	full_url: null,
	primaryColor: null,
	fontColor: null,
	// secondaryColor: null,

	azureLogin: null,

	primaryLogo: '',
	primaryLogoSize: null,
	secondaryLogo: '',
	secondaryLogoSize: null,

    smallSizeLogo: '',

	languages: [],

    countryId: null,
	customerId:	null,

	city: null,
	landingPicture: null,
	signinPicture: null,

    isTPDDSupplier: false
};

export const Organization = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_GET_INFO_ORGANIZATION_SUCCESS:
			if (!action || !action.payload) {
				return state;
			}

			const {
				payload	:	organizationInfo
			} = action;
			
			return {
				...state,
				id								: 	organizationInfo.id	||	state.id,
				intro							: 	organizationInfo.intro	||	'',
				name							: 	organizationInfo.name	||	'',
				title_channel_report_tp			: 	organizationInfo.title_channel_report_tp || '',
				isDemo							: 	!!organizationInfo.isDemo || !!organizationInfo.is_demo,
				translations					:	organizationInfo.translations || state.translations,
				introTranslations				: 	organizationInfo.intro_translations || state.introTranslations,
				status							: 	organizationInfo.status,
				path							: 	organizationInfo.path || null,
				url								: 	organizationInfo.orgUrl,
				full_url                        :   organizationInfo.organization_url,       	                
				primaryFont						: 	organizationInfo.font_family 	|| 	'',
				fontColor						: 	organizationInfo.font_color 	||	'',
				primaryColor					: 	organizationInfo.primary_color || '',

				logo							: 	organizationInfo.logo || '',
				primaryLogo						: 	organizationInfo.primary_logo || '',
				primaryLogoSize					: 	organizationInfo.primary_logo_size || '',
				secondaryLogo					: 	organizationInfo.secondary_logo || '',
				secondaryLogoSize				: 	organizationInfo.secondary_logo_size || '',

                smallSizeLogo                   :   organizationInfo.small_logo || '',

				azureLogin						: 	organizationInfo.isAdSet || '',

				languages						: 	organizationInfo.languages || state.languages,
				city                            :   organizationInfo.city || '',
                
                countryId                       :   action.payload.country,
				customerId                      :	action.payload.customerId,
				signinPicture                   :   organizationInfo.signin_picture,
				landingPicture                  :   organizationInfo.landing_picture,
                isTPDDSupplier                  :   !!organizationInfo.is_tpdd_supplier
			};
		case ACTION_EDIT_INFO_ORGANIZATION_FAILURE:
			return {
				...state,
				id: action.payload.id || state.id,
				logo: '',
				name: '',
				title_channel_report_tp: action.payload.title_channel_report_tp || state.title_channel_report_tp,
				intro: '',
				isDemo: false,

				translations: action.payload.translations || state.translations,
				introTranslations: action.payload.introTranslations || state.introTranslations,

				status: action.payload.status || '',

				primaryFont: '',
				path: '',
				url: '',
				full_url: '',
				fontColor: '',
				primaryColor: '',
				// secondaryColor: "",

				azureLogin: '',

				primaryLogo: '',
				primaryLogoSize: '',
				secondaryLogo: '',
				secondaryLogoSize: '',

                smallSizeLogo: '',

				languages: action.payload.languages || state.languages,
				
				modules: action.payload.modules || state.modules,
			};
		case ACTION_EDIT_INFO_ORGANIZATION_SUCCESS:
			return {
				...state,
				logo: action.payload.logo ? action.payload.logo : state.logo,
				intro: action.payload.intro,

				translations: action.payload.translations,
				introTranslations: action.payload.introTranslations,

				name: action.payload.name,
				title_channel_report_tp: action.payload.title_channel_report_tp ? action.payload.title_channel_report_tp : state.title_channel_report_tp,

				primaryFont: action.payload.primaryFont ? action.payload.primaryFont : state.primaryFont,
				path: action.payload.path ? action.payload.path : state.path,
				url: action.payload.url ? action.payload.url : state.url,
				full_url: action.payload.full_url ? action.payload.full_url : state.full_url,

				fontColor: action.payload.fontColor ? action.payload.fontColor : state.fontColor,
				primaryColor: action.payload.primaryColor ? action.payload.primaryColor : state.primaryColor,

				azureLogin: action.payload.azureLogin ? action.payload.azureLogin : state.azureLogin,

				primaryLogo: action.payload.primaryLogo ? action.payload.primaryLogo : state.primaryLogo,
				primaryLogoSize: action.payload.primaryLogoSize ? action.payload.primaryLogoSize : state.primaryLogoSize,
				secondaryLogo: action.payload.secondaryLogo ? action.payload.secondaryLogo : state.secondaryLogo,
				secondaryLogoSize: action.payload.secondaryLogoSize ? action.payload.secondaryLogoSize : state.secondaryLogoSize,

                smallSizeLogo: action.payload.smallSizeLogo,

				languages: action.payload.languages,
				
				modules: action.payload.modules,

                countryId: action.payload.country,
				city: action.payload.city,
				customerId:	action.payload.customerId,

				landingPicture: action.payload.landing_picture,
				signinPicture: action.payload.signin_picture
			};
        case ACTION_REPLACE_MODULE_CUSTOM_INFOS: {
            if (!action || !action.payload) {
				return state;
			}
            
            const {
				payload	:	organizationInfo
			} = action;
            
            return {
                ...state,
                id								: 	organizationInfo.id	||	state.id,
				intro							: 	organizationInfo.intro	||	'',
				name							: 	organizationInfo.name	||	'',
				title_channel_report_tp			: 	organizationInfo.title_channel_report_tp || '',
				isDemo							: 	!!organizationInfo.isDemo || !!organizationInfo.is_demo,
				translations					:	organizationInfo.translations || state.translations,
				introTranslations				: 	organizationInfo.intro_translations || state.introTranslations,
				status							: 	organizationInfo.status,
				path							: 	organizationInfo.path || null,
				url								: 	organizationInfo.orgUrl,
				full_url                        :   organizationInfo.organization_url,       	                
				primaryFont						: 	organizationInfo.font_family 	|| 	'',
				fontColor						: 	organizationInfo.font_color 	||	'',
				primaryColor					: 	organizationInfo.primary_color || '',

				logo							: 	organizationInfo.logo || '',
				primaryLogo						: 	organizationInfo.primary_logo || '',
				primaryLogoSize					: 	organizationInfo.primary_logo_size || '',
				secondaryLogo					: 	organizationInfo.secondary_logo || '',
				secondaryLogoSize				: 	organizationInfo.secondary_logo_size || '',

                smallSizeLogo                   :   organizationInfo.small_logo || '',

				azureLogin						: 	organizationInfo.isAdSet || '',

				languages						: 	organizationInfo.languages || state.languages,
				city                            :   organizationInfo.city || '',
                
                countryId                       :   action.payload.country,
				customerId                      :	action.payload.customerId,
				signinPicture                   :   organizationInfo.signin_picture,
				landingPicture                  :   organizationInfo.landing_picture,
            }
        }
		default:
			return state;
	}
};
