import React, { useEffect, useRef } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import flagOnIcon from "src/assets/icons/flag-icon.png";
import flagOffIcon from "src/assets/icons/flag-icon-off.png";
import { withNamespaces } from "react-i18next";
import Form from 'react-bootstrap/Form';

import FindingsService from "src/modules/3rd-party-management/apis/admin/FindingsService";
import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify";
import DOMPurify from 'dompurify';

import Spinner from 'react-bootstrap/Spinner';
import { UncontrolledTooltip } from "reactstrap";

const FindingsListItem = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { data, t, checked, onCheckStatusChanged } = props;
    const [ isRead, setIsRead ] = React.useState(!!data.is_read);
    const [ flagged, setFlagged ] = React.useState(!!data.flag);
    const ref = useRef();

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (payload) => {
            const service = FindingsService.getInstance();

            return await service.toggleFlag(payload);
        },
        onSuccess: () => {
            setFlagged(!flagged);
            toast(t("Flag status updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating flag status."), {
                type: "error",
            });
        }
    });

    const handleToggleReadStatusMutation = useMutation({
        mutationFn: async (newsId) => {
            const service = FindingsService.getInstance();

            return await service.toggleRead(newsId);
        },
        onSuccess: () => {
            setIsRead(!isRead);
            toast(t("Read status updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating read status."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(ref.current && ref.current.checked !== checked){
            ref.current.checked = checked;
            onCheckStatusChanged && onCheckStatusChanged(checked, data);
        }
    }, [checked]);

    const goToDetailPage = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('id', data.id);
        history.push({
            search : searchParams.toString()
        });
    }

    const displayRelevancyScore = data && ('relevancyScore' in data);

    const relevancyScore = parseInt(data?.relevancyScore);

    const itemImage = data.is_bafa ? `/images/bafa/${data.image}.png` : `/images/findings/${data.image}.jpg`;

    return (
        <div className={`finding-list-item mb-2 ${isRead ? 'read' : ''}`}>
            <div className="finding-list-item-checkbox">
                <Form.Check type="checkbox" ref={ (itemRef) => ref.current = itemRef} onChange={(e) => {onCheckStatusChanged && onCheckStatusChanged(e.target.checked, data)}} />
            </div>

            <div className="finging-container d-flex align-items-center">
                <div className="image-container me-4">
                    <img src={itemImage} alt="" />
                </div>
                <div className="finding-content d-flex flex-column align-items-start w-100">
                    <div className="finding-content-row d-flex align-items-center justify-content-between w-100 mb-1">
                        <span className="category-title">
                            {data.news_agency}
                        </span>

                        <div className="d-flex align-items-center">
                            <button 
                                className="flag-btn me-2"
                                id={`toggle-read-${data.id}`}
                                disabled={ handleToggleReadStatusMutation.isLoading }
                                onClick={() => {
                                    handleToggleReadStatusMutation.mutate(data.id)
                                }}
                            >
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`toggle-read-${data.id}`}
                                    trigger="hover"
                                    >
                                    {isRead ? props.t("Mark as unread") : props.t("Mark as read")}
                                </UncontrolledTooltip>
                                {
                                    handleToggleReadStatusMutation.isLoading ? (
                                        <Spinner animation="border" variant="primary" size="sm"/>
                                    ) : (
                                        isRead ? (
                                            <i className="ri-mail-open-line font-size-20"></i>
                                        ) : (
                                            <i className="ri-mail-line font-size-20"></i>
                                        )
                                    )
                                }
                            </button>

                            <button 
                                disabled={ handleToggleFlagStatusMutation.isLoading } 
                                className="flag-btn" 
                                id={`toggle-flag-${data.id}`}
                                onClick={() => handleToggleFlagStatusMutation.mutate({
                                    newsId : data.id
                                })}>
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`toggle-flag-${data.id}`}
                                    trigger="hover"
                                    >
                                        {props.t("Toggle flag status")}
                                </UncontrolledTooltip>
                                {
                                    handleToggleFlagStatusMutation.isLoading ? (
                                    <Spinner animation="border" variant="primary" size="sm"/>
                                    ) : (
                                        flagged ? (
                                            <img src={flagOnIcon} width={20} height={20} alt=""/>
                                        ) : (
                                            <img src={flagOffIcon} width={20} height={20} alt=""/>
                                        )
                                    )
                                }
                            </button>
                        </div>
                    </div>

                    <div className="finding-content-row d-flex align-items-center justify-content-start flex-wrap w-100 mb-1">
                        {data.weight && (
                            <i
                                style={{
                                    color: `#${data.weight}`
                                }} 
                                className="ri-checkbox-blank-circle-fill me-2"
                            ></i>
                        )}
                        <h4 onClick={goToDetailPage} className="finding-title fw-bolder m-0">
                            {data.title}
                        </h4>

                        {displayRelevancyScore && (
                            <div className="relevancy-score w-100 mb-1 mt-1">
                                {t('Relevancy Score')}: 
                                <span className={'score-value ml-1 ' + (relevancyScore < 40 ? 'danger' : (relevancyScore <= 69 ? 'warning' : 'success')) }>
                                    {relevancyScore}%
                                </span>
                            </div>
                        )}

                        <a href={ data.source_link } rel="noreferrer" target="_blank" className="d-block w-100 link">
                            { data.source_link }
                        </a>
                    </div>

                    <div className="finding-content-row mb-1">
                        <div className="pt-1 w-100 m-0 desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.lead_text)}} />
                    </div>

                    { data?.release_date && (
                        <div className="finding-content-row">
                            <small className="time">
                                { formatDistanceToNow(new Date(data.release_date * 1000), { addSuffix: true }) }
                            </small>
                        </div>
                    ) }

                    <div className="finding-content-row">
                        <div className="key-words">
                            <span className="badge me-1">{ t(data?.keyword) }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(
    withRouter(FindingsListItem)
);
