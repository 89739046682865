import { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Navigator from '../../../../components/navigator'
import Comments from './components/Comments'
import { useQuery, useMutation } from '@tanstack/react-query'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { convertToHTML } from 'draft-convert'
import { ContentState, EditorState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { TbLoader } from 'react-icons/tb'
import RiskManagementService from '../../../../api/RiskManagementService'
import { INTERNATIONAL_DATE_FORMAT } from 'src/common/constants'
import DateUtils from 'src/services/utils/DateUtils'
import { ResidualRisksRates } from '../../../../constants'
import { 
    ModulesActions,
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const RiskDetailsPage = ({ t, user, Modules }) => {
    const {
        riskDetailPageHeaderText
    } = ModulesActions[Modules.active] || {}
    const routes = ModulesApiRoutes[Modules.active] || {}
    const { riskId } = useParams()
    const routerLocation = useLocation()
    const dateUtils = new DateUtils()

    const [risk, setRisk] = useState()
    const [owners, setOwners] = useState(null)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedCategoryOtherString, setSelectedCategoryOtherString] =
        useState(null)
    const [categoryOtherError, setCategoryOtherError] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] =
        useState(null)
    const [subCategoryOtherError, setSubCategoryOtherError] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedDamage, setSelectedDamage] = useState(null)
    const [selectedLikelihood, setSelectedLikelihood] = useState(null)
    const [likelihoods, setLikelihoods] = useState(null)
    const [damages, setDamages] = useState(null)
    const [locations, setLocations] = useState([])
    const [description, setDescription] = useState(EditorState.createEmpty())
    const [descriptionError, setDescriptionError] = useState(null)
    const [currency, setCurrency] = useState([])
    const [tableColors, setTableColors] = useState(null)
    const [selectedOwner, setSelectedOwner] = useState(null)

    const [breadCrumbs, setBreadCrumbs] = useState([])

    const handleChangeSubCategory = (subCategory) => {
        if (subCategory && subCategory.baseLabel !== 'Other') {
            setSelectedSubCategoryOtherString(null)
        }

        setSelectedSubCategory(subCategory)
    }

    const handleChangeCategory = (category) => {
        if (category.baseLabel !== 'Other') {
            setSelectedCategoryOtherString('')
            setSelectedSubCategoryOtherString('')
        }

        setSelectedCategory(category)

        if (category.subCategories.length === 1) {
            handleChangeSubCategory(category.subCategories[0])
        } else {
            handleChangeSubCategory(null)
        }
    }

    const handleChangeOtherCategoryString = (value) => {
        setSelectedCategoryOtherString(value)
    }

    const handleSubmitChangeCategory = async () => {
        if (
            (selectedCategory.baseLabel === 'Other' &&
                !selectedCategoryOtherString) ||
            (selectedSubCategory.baseLabel === 'Other' &&
                !selectedSubCategoryOtherString)
        ) {
            if (!selectedCategoryOtherString)
                setCategoryOtherError(t("Subcategory can't be empty."))
            if (!selectedSubCategoryOtherString)
                setSubCategoryOtherError(t("Subcategory can't be empty."))

            return
        } else {
            setCategoryOtherError(null)
            setSubCategoryOtherError(null)
        }

        const data = {
            category:
                selectedCategory.baseLabel === 'Other'
                    ? null
                    : selectedSubCategory.baseLabel === 'Other'
                    ? selectedCategory.value
                    : selectedSubCategory.value,
            otherCategory: selectedCategoryOtherString || null,
            otherSubCategory: selectedSubCategoryOtherString || null
        }

        handleUpdateRiskCategoryMutation.mutate(data)
    }

    const riskService = new RiskManagementService(routes.API_BASE_URL)

    const handleFetchRisksLocations = useQuery({
        queryKey: ['risk-management-risks-locations-query'],
        queryFn: async () => {
            return await riskService.fetchLocations()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching locations.'), {
                type: 'error'
            })
        }
    })

    const handleFetchRiskDetails = useQuery({
        queryKey: ['risk-management-fetch-risk-details'],
        queryFn: async () => {
            return await riskService.fetchRiskDetails(riskId, {})
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching risk details.'), {
                type: 'error'
            })
        }
    })

    const handleFetchRisksCategories = useQuery({
        queryKey: ['risk-management-risk-categories-query'],
        queryFn: async () => {
            return await riskService.fetchCategories()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching categories.'), {
                type: 'error'
            })
        }
    })

    const handleFetchRisksMatrix = useQuery({
        queryKey: ['risk-management-fetch-risks-matrix'],
        queryFn: async () => {
            return await riskService.fetchMatrix()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching matrix.'), {
                type: 'error'
            })
        }
    })

    const handleFetchRisksOwners = useQuery({
        queryKey: ['risk-management-fetch-risks-owners'],
        queryFn: async () => {
            return await riskService.fetchOwners()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching owners.'), {
                type: 'error'
            })
        }
    })

    const handleUpdateRiskCategoryMutation = useMutation({
        mutationFn: async (payload) => {
            return await riskService.updateRiskCategory(risk.id, payload)
        },
        onError: () => {
            toast(t('Failed to update category.'), {
                type: 'error'
            })
        },
        onSuccess: () => {
            toast(t('Category updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateRiskDamageMutation = useMutation({
        mutationFn: async (damage) => {
            return await riskService.updateRiskDamage(risk.id, {
                damage: damage.value
            })
        },
        onError: () => {
            toast(t('Failed to update damage.'), {
                type: 'error'
            })
        },
        onSuccess: ({ riskRate }, damage) => {
            setRisk({
                ...risk,
                rating: riskRate,
                damage: {
                    id: damage.value,
                    title: damage.label
                }
            })

            toast(t('Damage updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateRiskLikelihoodMutation = useMutation({
        mutationFn: async (likelihood) => {
            return await riskService.updateRiskLikelihood(risk.id, {
                likelihood: likelihood.value
            })
        },
        onError: () => {
            toast(t('Failed to update likelihood.'), {
                type: 'error'
            })
        },
        onSuccess: ({ riskRate }, likelihood) => {
            setRisk({
                ...risk,
                rating: riskRate
            })

            setSelectedLikelihood({
                value: likelihood.value,
                label: likelihood.label
            })

            toast(t('Likelihood updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateRiskDescriptionMutation = useMutation({
        mutationFn: async (payload) => {
            return await riskService.updateRiskDescription(risk.id, payload)
        },
        onError: () => {
            toast(t('The description has not been updated.'), {
                type: 'error'
            })
        },
        onSuccess: () => {
            toast(t('The description is updated successfully'), {
                type: 'success'
            })
        }
    })

    const handleUpdateRiskOwnerMutation = useMutation({
        mutationFn: async (owner) => {
            return await riskService.updateRiskOwner(risk.id, {
                owner: owner.value
            })
        },
        onError: () => {
            toast(t('Failed to update owner.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, owner) => {
            setSelectedOwner(owner)

            toast(t('Owner updated successfully.'), {
                type: 'success'
            })
        }
    })

    useEffect(() => {
        if (handleFetchRiskDetails.data) {
            setRisk(handleFetchRiskDetails.data)
        }
    }, [handleFetchRiskDetails.data])

    useEffect(() => {
        if (handleFetchRisksCategories.data) {
            setCategories(
                handleFetchRisksCategories.data.map((item) => {
                    return {
                        value: item.id,
                        label: t(item.name),
                        baseLabel: item.name,
                        subCategories: item.sub_categories.map((sub) => {
                            return {
                                value: sub.id,
                                baseLabel: sub.name,
                                label: t(sub.name)
                            }
                        })
                    }
                })
            )
        }
    }, [handleFetchRisksCategories.data])

    useEffect(() => {
        if (handleFetchRisksLocations.data) {
            setLocations(
                handleFetchRisksLocations.data.map((item) => {
                    return {
                        value: item.id,
                        baseLabel: item.name,
                        label: t(item.name)
                    }
                })
            )
        }
    }, [handleFetchRisksLocations.data])

    useEffect(() => {
        if (handleFetchRisksOwners.data) {
            setOwners(
                handleFetchRisksOwners.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.first_name} ${item.last_name}`
                    }
                })
            )
        }
    }, [handleFetchRisksOwners.data])

    useEffect(() => {
        if (risk && categories) {
            if (risk.parent_category?.is_other_category) {
                const category = categories.find((c) => c.baseLabel === 'Other')

                if (category) {
                    setSelectedCategory({
                        value: category.value,
                        baseLabel: category.baseLabel,
                        label: t(category.baseLabel),
                        subCategories: category.subCategories
                    })

                    setSelectedCategoryOtherString(risk.parent_category.name)

                    setSelectedSubCategory({
                        value: category.subCategories[0].value,
                        baseLabel: category.subCategories[0].baseLabel,
                        label: t(category.subCategories[0].baseLabel)
                    })

                    setSelectedSubCategoryOtherString(risk.category.name)
                }
            } else {
                const category = categories.find(
                    (c) => c.value === risk.parent_category?.id
                )

                setSelectedCategory({
                    value: category?.value,
                    baseLabel: category?.baseLabel,
                    label: t(category?.baseLabel),
                    subCategories: category?.subCategories
                })

                setSelectedCategoryOtherString('')

                if (risk.category?.is_other_category) {
                    const subCategory = (category?.subCategories || []).find(
                        (c) => c.baseLabel === 'Other'
                    )

                    setSelectedSubCategory({
                        value: subCategory?.value,
                        baseLabel: subCategory?.baseLabel,
                        label: t(subCategory?.baseLabel)
                    })
                    setSelectedSubCategoryOtherString(risk.category?.name)
                } else {
                    const subCategory = (category?.subCategories || []).find(
                        (c) => c.value === risk.category?.id
                    )

                    setSelectedSubCategory({
                        value: subCategory?.value,
                        baseLabel: subCategory?.baseLabel,
                        label: t(subCategory?.baseLabel)
                    })
                    setSelectedCategoryOtherString('')
                }
            }
        }
    }, [risk, categories])

    useEffect(() => {
        if (risk && locations) {
            const location = locations.find(
                (l) => l.value === risk?.location?.id
            )
            setSelectedLocation(location)
        }
    }, [risk, locations])

    useEffect(() => {
        if (risk && damages) {
            const damage = damages.find((d) => d.value === risk?.damage?.id)
            setSelectedDamage(damage)
        }
    }, [risk, damages])

    useEffect(() => {
        if (risk && owners) {
            const owner = owners.find((o) => o.value === risk?.owner_object?.id)
            setSelectedOwner(owner)
        }
    }, [risk, owners])

    useEffect(() => {
        if (risk && likelihoods) {
            const likelihood = likelihoods.find(
                (l) => l.id === risk?.likelihood?.id
            )
            if (likelihood) {
                setSelectedLikelihood({
                    value: likelihood?.id,
                    label: t(likelihood?.label)
                })
            }
        }
    }, [risk, likelihoods])

    useEffect(() => {
        if (handleFetchRisksMatrix.data) {
            setLikelihoods(handleFetchRisksMatrix.data.likelihoods)
            setCurrency(handleFetchRisksMatrix.data.currency)

            setDamages(
                handleFetchRisksMatrix.data.consequences.map((item) => {
                    return {
                        value: item.id,
                        label:
                            handleFetchRisksMatrix.data.currency +
                            ' ' +
                            Number(item.title).toLocaleString(
                                localStorage.getItem('i18nextLng')
                            )
                    }
                })
            )

            if (
                handleFetchRisksMatrix.data.matrix &&
                handleFetchRisksMatrix.data.ratings
            ) {
                const flattedMatrix = handleFetchRisksMatrix.data.matrix.flat()
                const items = []

                for (const item of handleFetchRisksMatrix.data.ratings) {
                    items.push({
                        label: item.name,
                        color: flattedMatrix.find((i) => i.name === item.name)
                            .color
                    })
                }

                setTableColors(items)
            }
        }
    }, [handleFetchRisksMatrix.data])

    useEffect(() => {
        if (risk) {
            const blocksFromHTML = risk.description
                ? convertFromHTML(risk?.description)
                : ''
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )

            setDescription(EditorState.createWithContent(state))
        }
    }, [risk])

    useEffect(() => {
        const params = new URLSearchParams(routerLocation.search)

        switch (params.get('incomming_tab')) {
            case 'my-risks':
                setBreadCrumbs([
                    {
                        title: 'Management',
                        link: '/admin/risk-management?tab=overview'
                    },
                    {
                        title: 'My Risks',
                        link: `/admin/risk-management?tab=my-risks`
                    },
                    {
                        title: 'Detail',
                        link: '#'
                    }
                ])
                break

            case 'mitigation-tasks':
                setBreadCrumbs([
                    {
                        title: 'Management',
                        link: '/admin/risk-management?tab=overview'
                    },
                    {
                        title: 'Mitigation Task',
                        link: `/admin/risk-management?tab=mitigation-tasks`
                    },
                    {
                        title: 'Detail',
                        link: '#'
                    }
                ])
                break

            case 'overview':
                setBreadCrumbs([
                    {
                        title: 'Management',
                        link: '/admin/risk-management?tab=overview'
                    },
                    {
                        title: 'Overview',
                        link: `/admin/risk-management?tab=overview`
                    },
                    {
                        title: 'Detail',
                        link: '#'
                    }
                ])
                break

            default:
                setBreadCrumbs([
                    {
                        title: 'Management',
                        link: '/admin/risk-management?tab=overview'
                    },
                    {
                        title: 'All Risks',
                        link: `/admin/risk-management?tab=all-risks`
                    },
                    {
                        title: 'Detail',
                        link: '#'
                    }
                ])
        }
    }, [t])

    const dependenciesLoading =
        handleFetchRiskDetails.isFetching ||
        handleFetchRiskDetails.isLoading ||
        handleFetchRisksCategories.isLoading ||
        handleFetchRisksCategories.isFetching

    const renderCategorySection = () => {
        const categoryIsLoading =
            handleUpdateRiskCategoryMutation.isLoading ||
            handleUpdateRiskCategoryMutation.isFetching

        return (
            <AvForm>
                <Row className='mb-3'>
                    <Col
                        sm='12'
                        md={
                            selectedCategory &&
                            selectedCategory.baseLabel === 'Other'
                                ? 2
                                : 5
                        }
                        lg={
                            selectedCategory &&
                            selectedCategory.baseLabel === 'Other'
                                ? 2
                                : 5
                        }
                    >
                        <Label className='form-label text-dark'>
                            {`${t('Risk category')}: `}
                        </Label>

                        {!dependenciesLoading && !categoryIsLoading ? (
                            <Select
                                name='category'
                                classNamePrefix='select2-selection'
                                options={categories.map((c) => {
                                    return {
                                        value: c.value,
                                        baseLabel: c.baseLabel,
                                        label: t(c.baseLabel),
                                        subCategories: c.subCategories
                                    }
                                })}
                                value={{
                                    ...selectedCategory,
                                    label: t(selectedCategory?.baseLabel)
                                }}
                                onChange={handleChangeCategory}
                                placeholder={t('Select...')}
                                menuPortalTarget={document.body}
                            />
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    <Col
                        sm='12'
                        md={3}
                        lg={3}
                        hidden={
                            !selectedCategory ||
                            (selectedCategory &&
                                selectedCategory.baseLabel !== 'Other')
                        }
                    >
                        <Label className='form-label text-dark'>{`${t(
                            'Other category'
                        )}: `}</Label>
                        {!dependenciesLoading && !categoryIsLoading ? (
                            <>
                                <AvField
                                    errorMessage={t(
                                        'This field cannot be blank'
                                    )}
                                    className='form-control'
                                    name='otherCat'
                                    type='text'
                                    value={selectedCategoryOtherString}
                                    onChange={(e) =>
                                        handleChangeOtherCategoryString(
                                            e.target.value
                                        )
                                    }
                                />
                                <p className='text-danger'>
                                    {!selectedCategoryOtherString
                                        ? t(categoryOtherError)
                                        : ''}
                                </p>
                            </>
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    <Col
                        sm='12'
                        md={
                            selectedSubCategory &&
                            selectedSubCategory.baseLabel === 'Other'
                                ? 2
                                : 5
                        }
                        lg={
                            selectedSubCategory &&
                            selectedSubCategory.baseLabel === 'Other'
                                ? 2
                                : 5
                        }
                    >
                        <Label className='form-label text-dark'>
                            {`${t('Subcategory')}: `}
                        </Label>

                        {!dependenciesLoading && !categoryIsLoading ? (
                            <Select
                                name='categorsubcategory'
                                classNamePrefix='select2-selection'
                                placeholder={t('Select...')}
                                options={
                                    selectedCategory &&
                                    selectedCategory.subCategories
                                        ? selectedCategory.subCategories.map(
                                              (c) => {
                                                  return {
                                                      value: c.value,
                                                      baseLabel: c.baseLabel,
                                                      label: t(c.baseLabel),
                                                      subCategories:
                                                          c.subCategories
                                                  }
                                              }
                                          )
                                        : []
                                }
                                value={{
                                    ...selectedSubCategory,
                                    label: t(selectedSubCategory?.baseLabel)
                                }}
                                onChange={handleChangeSubCategory}
                                menuPortalTarget={document.body}
                            />
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    <Col
                        sm='12'
                        md={3}
                        lg={3}
                        hidden={
                            !selectedSubCategory ||
                            selectedSubCategory.baseLabel !== 'Other'
                        }
                    >
                        <Label className='form-label text-dark'>{`${t(
                            'Other'
                        )}: `}</Label>

                        {!dependenciesLoading && !categoryIsLoading ? (
                            <>
                                <AvField
                                    errorMessage={t(
                                        'This field cannot be blank'
                                    )}
                                    className='form-control'
                                    name='otherCat'
                                    type='text'
                                    value={selectedSubCategoryOtherString}
                                    onChange={(e) => {
                                        setSelectedSubCategoryOtherString(
                                            e.target.value
                                        )
                                    }}
                                />
                                <p className='text-danger'>
                                    {!selectedSubCategoryOtherString
                                        ? t(subCategoryOtherError)
                                        : ''}
                                </p>
                            </>
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    <Col sm='12' md={2} lg={2} className='mt-4 pt-1'>
                        {categories && risk && (
                            <Button
                                style={{ width: '100%' }}
                                color='primary'
                                onClick={handleSubmitChangeCategory}
                            >
                                {t('Save')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </AvForm>
        )
    }

    const renderLocationSection = () => {
        const damageIsLoading =
            handleUpdateRiskDamageMutation.isLoading ||
            handleUpdateRiskDamageMutation.isFetching

        const likelihoodIsLoading =
            handleUpdateRiskLikelihoodMutation.isLoading ||
            handleUpdateRiskLikelihoodMutation.isFetching

        return (
            <Row className='mt-5'>
                <Col sm='12' md='3' lg='3'>
                    <Label className='form-label'>{`${t('location')} `}</Label>

                    {!dependenciesLoading ? (
                        <Select
                            name='location'
                            classNamePrefix='select2-selection'
                            options={locations}
                            isDisabled
                            placeholder={t('Select...')}
                            value={selectedLocation}
                            menuPortalTarget={document.body}
                        />
                    ) : (
                        <div
                            className='dt-field dt-skeleton dt-select-list'
                            style={{ marginBottom: 16 }}
                        />
                    )}
                </Col>

                <Col sm='12' md='3' lg='3' hidden={!risk?.involved_entities}>
                    <Label className='form-label'>
                        {`${t('Involved entities')} `}
                    </Label>

                    {risk ? (
                        <Label
                            className='form-label text-dark'
                            style={{ paddingTop: 6 }}
                        >
                            {risk.involved_entities
                                ? risk.involved_entities
                                : ''}
                        </Label>
                    ) : (
                        <div
                            className='dt-field dt-skeleton dt-select-list'
                            style={{ marginBottom: 16 }}
                        />
                    )}
                </Col>

                <Col sm='12' md='3' lg='3'>
                    <Label className='form-label'>{`${t('Damage')} `}</Label>

                    {!dependenciesLoading && !damageIsLoading && risk ? (
                        <Select
                            name='damage'
                            classNamePrefix='select2-selection'
                            options={damages}
                            placeholder={t('Select...')}
                            value={selectedDamage}
                            onChange={(e) => {
                                handleUpdateRiskDamageMutation.mutate(e)
                            }}
                            menuPortalTarget={document.body}
                        />
                    ) : (
                        <div className='dt-field dt-skeleton dt-select-list' />
                    )}
                </Col>

                <Col sm='12' md='3' lg='3'>
                    <Label className='form-label'>
                        {`${t('Likelihood')} `}
                    </Label>

                    {!dependenciesLoading &&
                    !likelihoodIsLoading &&
                    !damageIsLoading &&
                    likelihoods &&
                    risk ? (
                        <Select
                            name='likelihood'
                            classNamePrefix='select2-selection'
                            options={likelihoods.map((l) => {
                                return {
                                    value: l.id,
                                    label: t(l.label)
                                }
                            })}
                            placeholder={t('Select...')}
                            value={selectedLikelihood}
                            onChange={(e) => {
                                handleUpdateRiskLikelihoodMutation.mutate(e)
                            }}
                            menuPortalTarget={document.body}
                        />
                    ) : (
                        <div className='dt-field dt-skeleton dt-select-list' />
                    )}
                </Col>
            </Row>
        )
    }

    const renderDescriptionSection = () => {
        const loadingDescription =
            handleUpdateRiskDescriptionMutation.isLoading ||
            handleUpdateRiskDescriptionMutation.isFetching

        return (
            <Row className='mt-5'>
                <Col sm='12' md='12' lg='12'>
                    {risk && (
                        <>
                            <Label className='form-label'>
                                {`${t('Description')} `}
                            </Label>

                            <Editor
                                editorState={description}
                                readOnly={false}
                                toolbarClassName='toolbarClassName'
                                wrapperClassName='wrapperClassName'
                                editorClassName='editorClassName'
                                onEditorStateChange={(e) => setDescription(e)}
                                placeholder={t('Add a description...')}
                                editorStyle={{
                                    height: '10rem',
                                    overflowY: 'scroll',
                                    borderColor: '#c9c9c9',
                                    paddingRight: '2em'
                                }}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'fontFamily'
                                    ],
                                    inline: {
                                        options: [
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strikethrough',
                                            'monospace'
                                        ],
                                        bold: {
                                            className:
                                                'bordered-option-classname'
                                        },
                                        italic: {
                                            className:
                                                'bordered-option-classname'
                                        },
                                        underline: {
                                            className:
                                                'bordered-option-classname'
                                        },
                                        strikethrough: {
                                            className:
                                                'bordered-option-classname'
                                        },
                                        code: {
                                            className:
                                                'bordered-option-classname'
                                        }
                                    },
                                    blockType: {
                                        className: 'bordered-option-classname'
                                    },
                                    fontSize: {
                                        className: 'bordered-option-classname'
                                    },
                                    fontFamily: {
                                        className: 'bordered-option-classname'
                                    }
                                }}
                            />
                            <p className='text-danger'>
                                {descriptionError ? t(descriptionError) : ''}
                            </p>
                        </>
                    )}
                </Col>

                <Col>
                    {risk ? (
                        <Col lg='2' md='2' sm='6'>
                            <Button
                                color='primary'
                                style={{ width: '100%', display: 'block' }}
                                onClick={() => {
                                    if (
                                        !description
                                            .getCurrentContent()
                                            .hasText()
                                    ) {
                                        setDescriptionError(
                                            "Description can't be empty."
                                        )
                                        return
                                    }

                                    setDescriptionError(null)
                                    handleUpdateRiskDescriptionMutation.mutate({
                                        description: convertToHTML(
                                            description.getCurrentContent()
                                        ),
                                        descriptionAttachments: []
                                    })
                                }}
                            >
                                {loadingDescription ? <TbLoader /> : t('Save')}
                            </Button>
                        </Col>
                    ) : null}
                </Col>
            </Row>
        )
    }

    const renderDetailsBox = () => {
        const ownerIsLoading =
            handleUpdateRiskOwnerMutation.isLoading ||
            handleUpdateRiskOwnerMutation.isFetching
        
        const matrixIsLoading = 
            handleFetchRisksMatrix.isFetching ||
            handleFetchRisksMatrix.isLoading

        return (
            <>
                <Card
                    outline
                    body
                    className='border border-primary rounded'
                    style={{ width: 'auto' }}
                >
                    <CardHeader>{t('Details')}</CardHeader>

                    <ListGroup flush style={{ borderRadius: '0' }}>
                        <ListGroupItem className='border-bottom border-primary'>
                            <Row>
                                <Col>
                                    <p
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '26px'
                                        }}
                                    >
                                        {t('Risk Manager')}
                                    </p>
                                </Col>
                                <Col>
                                    {risk ? (
                                        <span
                                            className='badge bg-success text-white rounded p-2'
                                            style={{
                                                marginBottom: 0,
                                                display: 'block'
                                            }}
                                        >
                                            {risk.manager_object?.name}
                                        </span>
                                    ) : (
                                        <div
                                            className='dt-field dt-skeleton'
                                            style={{ height: 27 }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem className='border-bottom border-primary '>
                            <Row>
                                <Col>
                                    <p
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '36px'
                                        }}
                                    >
                                        {t('Risk owner')}
                                    </p>
                                </Col>
                                <Col>
                                    {risk && !ownerIsLoading ? (
                                        <Select
                                            required
                                            name='owner'
                                            classNamePrefix='select2-selection'
                                            options={owners}
                                            isDisabled={
                                                user.id !==
                                                risk.manager_object?.id
                                            }
                                            value={selectedOwner}
                                            onChange={(e) => {
                                                handleUpdateRiskOwnerMutation.mutate(
                                                    e
                                                )
                                            }}
                                        />
                                    ) : (
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem className='border-bottom border-primary'>
                            <Row>
                                <Col>
                                    <p
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '27px'
                                        }}
                                    >
                                        {t('Risk rating')}
                                    </p>
                                </Col>
                                <Col>
                                    {tableColors && risk ? (
                                        <span
                                            className='badge text-white rounded p-2'
                                            style={{
                                                marginBottom: 0,
                                                display: 'block',
                                                fontWeight: 'bold',
                                                backgroundColor: `#${
                                                    tableColors.find(
                                                        (i) =>
                                                            i.label === risk.rating
                                                    )?.color
                                                }`
                                            }}
                                        >
                                            {t(risk.rating)}
                                        </span>
                                    ) : (
                                        <div
                                            className='dt-field dt-skeleton'
                                            style={{ height: 26 }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem className='border-bottom border-primary'>
                            <Row>
                                <Col>
                                    <p
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '26px'
                                        }}
                                    >
                                        {t('Created')}
                                    </p>
                                </Col>
                                <Col>
                                    {risk ? (
                                        <Label
                                            style={{
                                                marginBottom: 0,
                                                lineHeight: '26px'
                                            }}
                                        >
                                            {dateUtils.convertTimeStampToDate(
                                                risk.created_at,
                                                INTERNATIONAL_DATE_FORMAT
                                            )}
                                        </Label>
                                    ) : (
                                        <div
                                            className='dt-field dt-skeleton'
                                            style={{ height: 27 }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <p
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '26px'
                                        }}
                                    >
                                        {t('Risk Mitigation')}
                                    </p>
                                </Col>
                                <Col>
                                    {risk && risk.tasks ? (
                                        risk.tasks.map((t, i) => {
                                            return t.status === 'DELETED' ? (
                                                <span>
                                                    {`T${t.secondary_id}`}
                                                    <span
                                                        hidden={
                                                            i ===
                                                            risk.tasks.length - 1
                                                        }
                                                    >
                                                        {', '}
                                                    </span>
                                                </span>
                                            ) : (
                                                <span>
                                                    {`T${t.secondary_id}`}
                                                    <span
                                                        hidden={
                                                            i ===
                                                            risk.tasks.length - 1
                                                        }
                                                    >
                                                        {', '}
                                                    </span>
                                                </span>
                                            )
                                        })
                                    ) : (
                                        <div
                                            className='dt-field dt-skeleton'
                                            style={{ height: 27 }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>

                <Card 
                    outline 
                    body 
                    style={{ width: 'auto' }}
                    className={`border border-primary rounded ${risk && !risk.residualRisk?.result ? 'd-none': ''}`}
                >
                    <CardHeader>
                        {t('Residual Risk')}
                    </CardHeader>

                    <ListGroup flush style={{ borderRadius: '0' }}>
                        {/* Damage */}
                        <ListGroupItem className='border-bottom border-primary'>
                            <Row>
                                <Col>
                                    <p style={{ marginBottom: 0, lineHeight: '26px' }}>
                                        {t('Damage')}
                                    </p>
                                </Col>
                                <Col>
                                    {!matrixIsLoading && risk ? (
                                        <span className='p-2' style={{ marginBottom: 0, display: 'block' }}>
                                            {handleFetchRisksMatrix?.data?.currency + " " + Number(
                                                risk.residualRisk?.damage
                                            ).toLocaleString(
                                                localStorage.getItem("i18nextLng")
                                            )}
                                        </span>
                                    ) : (
                                        <div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        {/* Likelihood */}
                        <ListGroupItem className='border-bottom border-primary '>
                            <Row>
                                <Col>
                                    <p style={{ marginBottom: 0, lineHeight: '36px' }}>
                                        {t('Likelihood')}
                                    </p>
                                </Col>
                                <Col>
                                    {risk ? (
                                        <span className='p-2' style={{ marginBottom: 0, display: 'block' }}>
                                            {t(risk.residualRisk?.likelihood)}
                                        </span>
                                    ) : (
                                        <div className='dt-field dt-skeleton dt-select-list'></div>
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        {/* risk rating */}
                        <ListGroupItem className='border-bottom border-primary border-0'>
                            <Row>
                                <Col>
                                    <p style={{ marginBottom: 0, lineHeight: '27px' }}>{t('Risk rating')}</p>
                                </Col>
                                <Col>
                                    {risk ? (
                                        <span
                                            className='badge text-white rounded p-2'
                                            style={{
                                                marginBottom: 0,
                                                display: 'block',
                                                fontWeight: 'bold',
                                                backgroundColor: `${ ResidualRisksRates[risk.residualRisk?.result]?.color}`,
                                            }}
                                        >
                                            {t(ResidualRisksRates[risk.residualRisk?.result]?.title)}
                                        </span>
                                    ) : (
                                        <div className='dt-field dt-skeleton' style={{ height: 26 }}></div>
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </>
        )
    }

    let pageTitleText = '...'

    if (risk && riskDetailPageHeaderText) {
        pageTitleText = riskDetailPageHeaderText({
            risk
        })
    }

    return (
        <div className='risk-management-module page-content'>
            <Container fluid>
                <Row>
                    <Col lg='12' className='mb-4'>
                        <Navigator
                            backButtonOptions={(props) => {
                                return {
                                    ...props,
                                    enable: true
                                }
                            }}
                            breadCrumbs={breadCrumbs}
                            pageTitle={pageTitleText}
                        />
                    </Col>

                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='12' md='8'>
                                        {renderCategorySection()}

                                        {renderLocationSection()}

                                        {renderDescriptionSection()}
                                    </Col>

                                    <Col lg='4' md='4' sm='12'>
                                        {renderDetailsBox()}
                                    </Col>
                                </Row>
                                {risk && (
                                    <Row>
                                        <Col sm='12'>
                                            <Comments
                                                isReadOnly={false}
                                                risk={risk}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization, Modules } = state
    return {
        user: state.Login.user,
        token,
        organization: Organization,
        Modules
    }
}

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {})(RiskDetailsPage))
)
