import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactPlayer from "react-player/file";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardText,
    Col,
    Container,
    Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { errorNotification, successNotification } from "../../store/actions";
import { API_URL_GET_TUTORIAL_DETAILS } from "../../common/constants";

const TutorialDetail = ({
    t,
    token,
    location,
    errorNotification,
}) => {
    const [breadcrumbItems] = useState([
        { title: "SIP", link: "/" },
        { title: t("Tutorial"), link: "#" },
    ]);
    const [lessonDetails, setLessonDetails] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const playerRef = useRef(null);

    const fetchDetails = () => {
        const url = API_URL_GET_TUTORIAL_DETAILS;
        const data = {
            tutorial_id:
                Number(location.pathname.split("/")[3]) ||
                Number(localStorage.getItem("lessonId")),
        };
        const header = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };

        axios
        .post(url, data, header)
        .then((res) => {
            if (res.data.status) {
                const data = res.data.message;
                if (typeof data === "object" && data !== null) {
                    setLessonDetails(data);
                }
            }
        })
        .catch((err) => {
            setLessonDetails((prevState) => ({
                ...prevState,
                lesson_error: "No Lesson Available",
            }));
            errorNotification({
                code: "fatal_error",
                message: t("No Lesson Available"),
            });
        });
    };

    const resizePlayer = () => {
        const width = window.innerWidth;
        const player = document.querySelector(".react-player");
        if (player) {
            player.style.width = width <= 768 ? "100%" : "60%";
        }
    };

    const handleProgress = (progress) => {
        setElapsedTime(progress.playedSeconds);
    };

    useEffect(() => {
        if(lessonDetails?.lesson_id && lessonDetails?.type !== 'embedded'){
            setTimeout(() => {
                window.addEventListener("resize", resizePlayer);
                resizePlayer();
            }, 300);
        }
    }, [
        lessonDetails
    ])

    useEffect(() => {
        fetchDetails();
        return () => {
            window.removeEventListener("resize", resizePlayer);
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={t("Tutorial")} breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div style={{ marginBottom: 20 }}>
                                        <CardHeader tag={"h1"}>
                                            {lessonDetails?.tutorial_name
                                                ? t(lessonDetails.tutorial_name)
                                                : "..."}
                                        </CardHeader>
                                    </div>
                                    {lessonDetails?.lesson_error ? (
                                        <Alert color="warning">{t(lessonDetails.lesson_error)}</Alert>
                                    ) : (
                                        <div>
                                            {lessonDetails?.language_id && (
                                                <div style={{ marginBottom: 20 }}>
                                                    {lessonDetails?.type === 'stream' ? (
                                                        <ReactPlayer
                                                            controls
                                                            ref={playerRef}
                                                            className="react-player"
                                                            width="60%"
                                                            height="50%"
                                                            playsinline
                                                            onProgress={handleProgress}
                                                            url={lessonDetails.video_address}
                                                        />
                                                    ) : (
                                                        <div dangerouslySetInnerHTML={{ __html: lessonDetails.video_address || "" }} />
                                                    )}
                                                </div>
                                            )}
                                            <div>
                                                {lessonDetails?.lesson_description && (
                                                    <CardText>
                                                        <span>{t("Description")}: </span>
                                                        {lessonDetails.lesson_description}
                                                    </CardText>
                                                )}
                                                {lessonDetails?.language_id && (
                                                    <CardText>
                                                        <span>{t("Language")}: </span>
                                                        {lessonDetails.language_id}
                                                    </CardText>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { App } = state;
    return { token, App };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, { errorNotification, successNotification })(
            TutorialDetail
        )
    )
);
