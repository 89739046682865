import VerticalLayout from 'src/components/VerticalLayout'
import RiskManagment from '../pages/admin'
import RiskDetailsPage from '../pages/admin/risks/details'
import TaskDetailsPage from '../pages/admin/tasks/details'

const adminRoutes = [
    {
        path: '/admin/risk-management/task/:taskId/details',
        component: TaskDetailsPage,
        layout: VerticalLayout,
        type: 'admin',
        modules: ['wb', 'cm', 'tp', 'gd']
    },
    {
        path: '/admin/risk-management/:riskId/details',
        component: RiskDetailsPage,
        layout: VerticalLayout,
        type: 'admin',
        modules: ['wb', 'cm', 'tp', 'gd']
    },
    {
        path: '/admin/risk-management',
        component: RiskManagment,
        layout: VerticalLayout,
        type: 'admin',
        modules: ['wb', 'cm', 'tp', 'gd']
    }
]

export default adminRoutes

export { adminRoutes }
