import { ROLE_ANALYST, ROLE_ANALYST_ADMIN, ROLE_EMPLOYEE, ROLE_PARTNER, ROLE_SUPER_ADMIN, ROLE_TASK_OWNER, ROLE_THIRD_PARTY } from "../../components/constants";
import UrlUtils from "./UrlUtils";

const modulesHomes = {
    wb : {
        [ROLE_ANALYST] : '/admin/home',
        [ROLE_EMPLOYEE] : '/employee/home?landing=false',
        [ROLE_THIRD_PARTY] : '/third-party/reports'
    },
    cm : {
        [ROLE_ANALYST] : '/admin/home',
        [ROLE_EMPLOYEE] : '/employee/home?landing=false',
        [ROLE_THIRD_PARTY] : '/third-party/reports'
    },
    gd : {
        [ROLE_ANALYST] : '/admin/data-protection/dashboard',
        [ROLE_THIRD_PARTY] : '/data-protection/third-party/home?landing=false',
		[ROLE_THIRD_PARTY] : '/third-party/reports'
    },
    tp : {
        [ROLE_ANALYST] : '/admin/3rd-party/manager',
		[ROLE_THIRD_PARTY] : '/3rd-party/home?landing=false'
    }
}



export default class UserUtils {
	constructor() {
		this.urlUtils = new UrlUtils();
	}

	moduleRoles = (module, role) => {
		
		let result = ""
		if(module === "wb") {
			switch(role) {
				case "ROLE_USER": 
					result = "User";
					break;
				case "ROLE_ADMIN": 
					result = "Admin";
					break;
				case "ROLE_ANALYST": 
					result = "Analyst";
					break;
				case "ROLE_ANALYST_ADMIN": 
					result = "Analyst Admin";
					break;
				case "ROLE_EMPLOYEE": 
					result = "Employee";
					break;
				case "ROLE_THIRD_PARTY": 
					result = "Third Party";
					break;
				case "ROLE_TASK_OWNER": 
					result = "Task Owner";
					break;
			}
		}
		if(module === "cm") {
			switch(role) {
				case "ROLE_USER": 
					result = "User";
					break;
				case "ROLE_ADMIN": 
					result = "Admin";
					break;
				case "ROLE_ANALYST": 
					result = "Analyst";
					break;
				case "ROLE_ANALYST_ADMIN": 
					result = "Analyst Admin";
					break;
				case "ROLE_EMPLOYEE": 
					result = "Employee";
					break;
				case "ROLE_THIRD_PARTY": 
					result = "Third Party";
					break;
				case "ROLE_TASK_OWNER": 
					result = "Task Owner";
					break;
			}
		}
		if(module === "gd") {
			switch(role) {
				case "ROLE_USER": 
					result = "User";
					break;
				case "ROLE_ADMIN": 
					result = "Admin";
					break;
				case "ROLE_ANALYST": 
					result = "Analyst";
					break;
				case "ROLE_ANALYST_ADMIN": 
					result = "Analyst Admin";
					break;
				case "ROLE_EMPLOYEE": 
					result = "Employee";
					break;
				case "ROLE_THIRD_PARTY": 
					result = "Third Party";
					break;
				case "ROLE_TASK_OWNER": 
					result = "Task Owner";
					break;
			}
		}
		if(module === "tp") {
			switch(role) {
				case "ROLE_USER": 
					result = "User";
					break;
				case "ROLE_ADMIN": 
					result = "Admin";
					break;
				case "ROLE_ANALYST": 
					result = "Analyst";
					break;
				case "ROLE_ANALYST_ADMIN": 
					result = "Analyst Admin";
					break;
				case "ROLE_EMPLOYEE": 
					result = "Employee";
					break;
				case "ROLE_THIRD_PARTY": 
					result = "Contact Person";
					break;
				case "ROLE_TASK_OWNER": 
					result = "Task Owner";
					break;
			}
		}
		
		return result;
	}

	getName(user) {
		if (user) {
			const firstName = user.first_name || "";
			const lastName = user.last_name || "";

			if (firstName) {
				return firstName + " " + lastName;
			}

			return lastName;
		}

		return "";
	}

	belongOrganization(user) {
		if (user) {
			const orgId = this.urlUtils.getOrganizationId();
			const userOrg = user.organization;

			if (orgId && userOrg) {
				const id = parseInt(orgId);
				const userOrgId = userOrg.id;

				return id === userOrgId;
			}
		}

		return false;
	}

	isThirdParty(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_THIRD_PARTY);
		}

		return false;
	}

	isSuperAdmin(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_SUPER_ADMIN);
		}

		return false;
	}

	isPartner(user) {
		if(user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_PARTNER);
		}
		
		return false;
	}

	isOrganizationThirdParty(user) {
		return this.belongOrganization(user) && this.isThirdParty(user);
	}

	notThirdParty(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_EMPLOYEE || role === ROLE_ANALYST_ADMIN || role === ROLE_ANALYST);
		}

		return false;
	}

	notOrganizationThirdParty(user) {
		return this.belongOrganization(user) && this.notThirdParty(user);
	}

	isAnalystAdmin(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_ANALYST_ADMIN);
		}

		return false;
	}

	isOrganizationAnalystAdmin(user) {
		if (user) {
			return this.belongOrganization(user) && this.isAnalystAdmin(user);
		}

		return false;
	}

	isAnalyst(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_ANALYST);
		}

		return false;
	}

	isOrganizationAnalyst(user) {
		if (user) {
			return this.belongOrganization(user) && this.isAnalyst(user);
		}

		return false;
	}

	isAnalystOrAnalystAdmin(user) {
		return this.isAnalyst(user) || this.isAnalystAdmin(user);
	}

	isEmployee(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_EMPLOYEE);
		}

		return false;
	}

	isTaskOwner(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_TASK_OWNER);
		}

		return false;
	}

	isOrganizationEmployee(user) {
		if (user) {
			return this.belongOrganization(user) && this.isEmployee(user);
		}

		return false;
	}

	getInitUser() {
		return {
			id: "",
			email: "",
			name: "",
			roles: [],
			status: 0,
			reportsCount: "0",
			position: "",
			first_name: "",
			last_name: "",
			organization: null,
			title: "",
			is_first_admin: false,
			last_login: null,
			settings: {
				lang: null,
			},
			phone_number: "",
			analyst: {
				is_enabled_live_chat: false,
			},
		};
	}

	mapDataToUser(newUser) {
		const user = this.getInitUser();

		if (newUser) {
			const keys = Object.keys(user);

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];

				if (newUser[key]) {
					user[key] = newUser[key];
				}
			}
		}

		return user;
	}

	getHiddenEmail(email) {
		const splitWord = "@";
		const splitEmails = email.split(splitWord);

		if (splitEmails && splitEmails.length > 0) {
			const emailName = splitEmails[0];
			const emailDomain = splitEmails[1];

			if (emailName) {
				let newEmailName = "";

				if (emailName.length <= 1) {
					newEmailName = "*";
				}

				if (emailName.length > 1) {
					newEmailName = emailName[0];

					for (let i = 1; i < emailName.length; i++) {
						newEmailName += "*";
					}
				}

				return newEmailName + splitWord + emailDomain;
			}
		}

		return "*";
	}

	getWorkspace(user) {
		if (!user) {
			return;
		}

		// console.log("getWorkspace.user: ", user)

		const role = user.roles[0];
		if (role === "ROLE_ANALYST") return "analyst_workspace";
		else if (role === "ROLE_EMPLOYEE" || role === "ROLE_TASK_OWNER") return "employee_workspace";
		else if (role === "ROLE_ANALYST_ADMIN" || role === "ROLE_PARTNER") return "admin_workspace";
		else return "third_party_workspace";
	}

	getUserTypeBaseRoute(user) {
		if (this.isAnalystOrAnalystAdmin(user)) return "/admin/home";
		else if (this.isEmployee(user)) return "/employee";
		else return "/third-party";
	}

	getUserHomeRoute(user, module = 'wb') {
        const moduleHomes = modulesHomes[module];

        if(!moduleHomes){
            return null;
        }

        let role = ROLE_THIRD_PARTY;

        if(this.isAnalystOrAnalystAdmin(user)){
            role = ROLE_ANALYST;
        }
        else if(this.isEmployee(user)){
            role = ROLE_EMPLOYEE;
        }

        return moduleHomes[role];
	}

	gerUserRole(role) {
		let output = "";

		switch (role) {
			case "ROLE_USER":
				output = "User";
				break;
			case "ROLE_ADMIN":
				output = "Admin";
				break;
			case "ROLE_ANALYST":
				output = "Analyst";
				break;
			case "ROLE_ANALYST_ADMIN":
				output = "Analyst Admin";
				break;
			case "ROLE_EMPLOYEE":
				output = "Employee";
				break;
			case "ROLE_THIRD_PARTY":
				output = "Supplier";
				break;
			case "ROLE_TASK_OWNER":
				output = "Task Owner";
				break;
		}

		return output;
	}

    getUserTopRole(roles){
        let topRole = null;
        if(roles.includes(ROLE_SUPER_ADMIN)){
            topRole = ROLE_SUPER_ADMIN;
        }
        else if(roles.includes(ROLE_ANALYST_ADMIN)){
            topRole = ROLE_ANALYST_ADMIN;
        }
        else if(roles.includes(ROLE_ANALYST)){
            topRole = ROLE_ANALYST;
        }
        else if(roles.includes(ROLE_TASK_OWNER)){
            topRole = ROLE_TASK_OWNER
        }
        else if(roles.includes(ROLE_EMPLOYEE)){
            topRole = ROLE_EMPLOYEE;
        }
        else if(roles.includes(ROLE_THIRD_PARTY)){
            topRole = ROLE_THIRD_PARTY;
        }
        return topRole;
    }
}
