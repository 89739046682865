import * as React from 'react'
import { memo, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'

const BarChart = (props) => {
    const [mineData, setMineData] = useState([])

    useEffect(() => {
        if (props?.createdRisksAndTasks) {
            // Extract "mine" data from props
            const {
                mineCreatedRisksCount,
                mineMitigatedRisksCount,
                mineAcceptedRisksCount
            } = props.createdRisksAndTasks

            // Set "mine" data to state
            setMineData([
                mineCreatedRisksCount,
                mineMitigatedRisksCount,
                mineAcceptedRisksCount
            ])
        }
    }, [props.createdRisksAndTasks])

    const data = {
        labels: [
            props.t('Total Risks'),
            props.t('Mitigated Risks'),
            props.t('Accepted Risks')
        ],
        datasets: [
            {
                label: 'Mine',
                backgroundColor: 'rgba(52, 195, 143, 0.8)',
                borderColor: 'rgba(52, 195, 143, 0.8)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
                hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
                data: mineData // Use mineData from state
            }
        ]
    }

    const option = {
        scales: {
            xAxes: [
                {
                    barPercentage: 0.4
                }
            ]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                padding: 30 // Adjust padding as needed
            }
        },
        layout: {
            padding: {
                top: 30 // Margin top from bar chart
            }
        }
    }

    if (props.isLoading) {
        return (
            <React.Fragment>
                <div
                    className='dt-field dt-skeleton mb-2'
                    style={{ width: '30%' }}
                />
                <div
                    className='dt-field dt-skeleton'
                    style={{ width: '100%', height: '320px' }}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <h5 className='mb-3'>
                {props.t('The number of risks and tasks created')}
            </h5>
            <Bar height={240} data={data} options={option} />
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization } = state
    return {
        user: state.Login.user,
        token,
        organization: Organization
    }
}

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {})(memo(BarChart)))
)
