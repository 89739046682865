import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Select from "react-select";
import { 
    Row, 
    Col, 
    FormGroup,
    Label,
    Alert
} from 'reactstrap';
import { 
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import QuestionnaireService from 'src/modules/3rd-party-management/apis/common/QuestionnaireService';
import {
    helpers
} from '@smartintegrity/questionnaire';
import BafaQuestionnaireService from 'src/modules/3rd-party-management/apis/admin/BafaQuestionnaireService';
import OverviewHeader from './components/overview-header';
import ComplianceQuestionnaire from './ComplianceQuestionnaire';

const QuestionsLoadingPlaceholder = () => {
    return (
        <div>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

const BafaQuestionnaire = ({
    t,
    token,
    user,
    supplierId
}) => {
    const location = useLocation();
    const [ selectedReportRequest, setSelectedReportRequest ] = useState(null);
    const [ reportCase, setReportCase ] = useState({
        id: null,
        refetch: false,
        isLoading: true,
        isCreatedBefore: false
    });
    const [ questions, setQuestions ] = useState([]);
    const [ prevAnswers, setPrevAnswers ] = useState([]);
    const [ noReportRequestFound ,setNoReportRequestFound ] = useState(false);

    const {
        isFetching: userReportRequestsAreFetching,
        data: userOpenReportRequestsList
    } = useQuery({
		queryKey: [
            '3rd-party-fetch-user-bafa-questionnaire-report-requests',
            supplierId,
            user.id
        ],
		queryFn: async () => {
			const service = BafaQuestionnaireService.getInstance();
	
			return await service.fetchUserReportRequests({
                supplier: supplierId
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		retry: 0,
		onError: ({
            response
        }) => {
            if((response.data.error).toLowerCase() === 'report_request_list_empty'){
                toast(t('It is not possible to send a report before the request is created by admin'), {
                    type: 'error',
                });

                setNoReportRequestFound(true);
            }
            else{
                toast(t('An error occurred while starting!'), {
                    type: 'error',
                });
            }

            setReportCase((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });
		}
	});

    const {
        data: fetchQuestionsResponse,
        refetch: refechQuestions,
        dataUpdatedAt: questionnaireQueryUpdatedAt,
    } = useQuery({
		queryKey: [
            '3rd-party-fetch-bafa-questionnaire-query',
            selectedReportRequest?.type,
            supplierId
        ],
		queryFn: async () => {
			const service = QuestionnaireService.getInstance();

            setNoReportRequestFound(false);

            setReportCase({
                id: null,
                refetch: false,
                isLoading: true,
                isCreatedBefore: false
            });
	
			return await service.fetchPrevFilledAnswers('tpdd', supplierId, {
                qType: selectedReportRequest.type
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		retry: 0,
		onError: ({
			response
		}) => {
			if((response.data.error).toLowerCase() === 'there is not any report for this questionnaire'){
				axios.post(`${API_BASE_URL}/report.create`, {
					"questionnaireType"     :	selectedReportRequest.type,
                    "supplier"              :   supplierId,
					"questionAnswers"	    :	[],
                    "reportRequest"         :   selectedReportRequest.value
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(() => {
					setReportCase((currentState) => {
                        return {
                            ...currentState,
                            refetch: true
                        }
                    });
				})
				.catch(() => {
                    toast(t('An error occurred while starting!'), {
                        type: 'error',
                    });
				});
			}
		},
		onSuccess : (response) => {
            setReportCase({
                id: response.reportCaseId,
                refetch: false,
                isLoading: false,
                isCreatedBefore: response.hasSubsidiary
            });
		}
	});

    useEffect(() => {
        if(selectedReportRequest?.value){
            refechQuestions();
        }
    }, [ selectedReportRequest ]);

    useEffect(() => {
        if(reportCase.refetch){
            refechQuestions();
        }
	}, [reportCase.refetch]);

    useEffect(() => {
        if(fetchQuestionsResponse?.questions){
            setPrevAnswers(helpers.makePreviousAnswersReadyForQuestionnaire(
                fetchQuestionsResponse.questions,
                fetchQuestionsResponse.questions
            ));

            setTimeout(() => {
                setQuestions(helpers.makeQuestionsReadyForQuestionnaire(
                    fetchQuestionsResponse.questions,
                    true
                ));
            }, 1000);
        }
    }, [ questionnaireQueryUpdatedAt ]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const requestID = searchParams.get('request');
        const requestsList = userOpenReportRequestsList?.request || [];

        if(requestID && requestsList.length > 0){
            const foundRequest = requestsList.find((r) => {
                return parseInt(r.id) === parseInt(requestID)
            });

            if(foundRequest){
                setSelectedReportRequest({
                    value: foundRequest.id,
                    label: `${t(foundRequest.questionnaire.title)} - ${(new Date(foundRequest.financialEndYear)).getFullYear()}`,
                    baseLabel: foundRequest.questionnaire.title,
                    type: foundRequest.questionnaire?.type,
                    financialYear: (new Date(foundRequest.financialEndYear)).getFullYear()
                });
            }
            
        }
    }, [ 
        location.search,
        userOpenReportRequestsList,
        t
    ])

    const reloadQuestionnaire = () => {
        setQuestions([]);
        setPrevAnswers([]);

        setTimeout(() => {
            refechQuestions();
        }, 500);
    }

    return (
        <div className="p-4">
            <Row className='mb-4'>
                <Col sm='12'>
                    <Alert color='info'>
                        <FormGroup>
                            <Label>
                                {t('Select the questionnaire')}
                            </Label>

                            <Select
                                isDisabled={ userReportRequestsAreFetching }
                                isLoading={ userReportRequestsAreFetching }
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                options={(userOpenReportRequestsList?.request || []).map((openRequest) => {
                                    return {
                                        value: openRequest.id,
                                        label: `${t(openRequest.questionnaire.title)} - ${(new Date(openRequest.financialEndYear)).getFullYear()}`,
                                        baseLabel: openRequest.questionnaire.title,
                                        type: openRequest.questionnaire.type,
                                        financialYear: (new Date(openRequest.financialEndYear)).getFullYear()
                                    }
                                })}
                                menuPortalTarget={document.body}
                                onChange={(e) => {
                                    setSelectedReportRequest(e)
                                }}
                                value={selectedReportRequest}
                                styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      width: '340px'
                                    }),
                                }}
                            />
                        </FormGroup>
                    </Alert>
                </Col>
            </Row>

            {noReportRequestFound && (
                <Row>
                    <Col sm='12'>
                        <Alert color="warning" className="d-flex justify-content-start">
                            <div className="d-flex align-items-center me-2">
                                <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                            </div>
                            <div className="d-flex align-items-start justify-content-center flex-column">
                                <strong>{t('Please Pay Attention')}</strong>
                                {t('It is not possible to send a report before the request is created by admin')}
                            </div>
                        </Alert>
                    </Col>
                </Row>
            )}

            {selectedReportRequest && (
                <Row>
                    <Col sm='12'>
                        {(reportCase.isLoading) && (
                            <QuestionsLoadingPlaceholder />
                        )}

                        {reportCase.id && questions.length > 0 && (
                            <>
                                <OverviewHeader 
                                    title={selectedReportRequest}
                                    reportRequest={selectedReportRequest}
                                />
                                <ComplianceQuestionnaire 
                                    questionnaireType={selectedReportRequest.type}
                                    supplierId={supplierId}
                                    questions={questions}
                                    prevAnswers={prevAnswers}
                                    reportCaseId={reportCase.id}
                                    reportIsNew={!reportCase.isCreatedBefore}
                                    subsidiariesCount={parseInt(fetchQuestionsResponse?.childrenCount)}
                                    reloadQuestionnaire={reloadQuestionnaire}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	
	return {
		token,
		user
	};
};

export default withNamespaces()(
    connect(mapStatetoProps)(
        BafaQuestionnaire
    )
);