import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { memo, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import RiskMatrix from '../../../../../components/charts/RiskMatrix'
import SingleDonutChart from '../../../../../components/charts/SingleDonutChart'
import BarChart from '../../../../../components/charts/barchart'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import RiskManagementService from '../../../../../api/RiskManagementService'
import { 
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const AnalystView = ({ t, Modules }) => {
    const routes = ModulesApiRoutes[Modules.active]
    const [overViewData, setOverViewData] = useState([])

    const riskService = new RiskManagementService(routes.API_BASE_URL)

    const handleFetchOverViewData = useQuery({
        queryKey: ['risk-managment-overView'],
        queryFn: async () => {
            return await riskService.fetchOverview()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(
                t(
                    'An error occurred while fetching risk mangment information.'
                ),
                {
                    type: 'error'
                }
            )
        }
    })

    useEffect(() => {
        if (handleFetchOverViewData.data) {
            setOverViewData(handleFetchOverViewData.data)
        }
    }, [handleFetchOverViewData.data])

    return (
        <div className='p-4'>
            <Row>
                <Col className='mt-3' sm='12' md='12' lg='12'>
                    <div className='risk-managment-card'>
                        <RiskMatrix
                            matrix={overViewData?.riskMatrix?.matrix}
                            consequences={
                                overViewData?.riskMatrix?.consequences
                            }
                            currency={overViewData?.riskMatrix?.currency}
                            likelihoods={overViewData?.riskMatrix?.likelihoods}
                            risks={null}
                            ratings={overViewData?.riskMatrix?.ratings}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className='mt-4' sm='12' md='4'>
                    <div className='risk-managment-card'>
                        <SingleDonutChart
                            title={t('Risks created')}
                            riskCreated={overViewData.riskCreated}
                        />
                    </div>
                </Col>
                <Col className='mt-4' sm='12' md='4'>
                    <div className='risk-managment-card'>
                        <SingleDonutChart
                            title={t('Mitigation tasks')}
                            mitigation={overViewData.mitigationTasks}
                        />
                    </div>
                </Col>
                <Col className='mt-4' sm='12' md='4'>
                    <div className='risk-managment-card'>
                        <BarChart
                            createdRisksAndTasks={
                                overViewData?.createdRisksAndTasks
                            }
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapToState = (state) => {
    const { Modules } = state

    return {
        Modules
    }
}

export default withNamespaces()(
    withRouter(
        connect(mapToState)(
            memo(AnalystView)
        )
    )
)
