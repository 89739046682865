import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import UploadedFiles from './uploadFiles'

const ReplaceFileModal = function (props) {
    const { t, submit, close, progress, isOpen } = props

    return (
        <React.Fragment>
            <Modal size='lg' scrollable isOpen={isOpen} backdrop='static'>
                <ModalHeader toggle={() => close()}>
                    {t('Upload a file')}
                </ModalHeader>

                <ModalBody>
                    <AvForm className='needs-validation'>
                        <Row>
                            <Col sm='12' md='12' lg='12'>
                                <UploadedFiles
                                    t={t}
                                    handleAcceptedFiles={(e) =>
                                        e.target?.files[0]
                                            ? submit(e.target.files[0])
                                            : null
                                    }
                                    uploadedFiles={[]}
                                    showProg={progress}
                                />
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withNamespaces()(ReplaceFileModal)
