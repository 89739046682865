import { withNamespaces } from "react-i18next";
import { withRouter, useHistory } from "react-router-dom";
import React, { useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter,
    Label
} from "reactstrap";
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';
import Spinner from 'react-bootstrap/Spinner';

const CreateAndEditModal = ({
    t,
    questionnaire,
    showModal,
    hideModal,
    onComplete
}) => {
    const history = useHistory();
    const editGeneralInfosFormRef = useRef();

    const {
        mutate: createQuestionnaireMutation,
        isLoading: isCreatingQuestionnaire,    
    } = useMutation({
        mutationFn: async (payload) => {
            const service = QuestionnaireDesignerService.getInstance();
            return await service.createQuestionnaire(payload);
        },
        onSuccess: ({
            slug
        }) => {
            toast(t("New questionnaire created successfully."), {
                type: "success",
            });

            onComplete && onComplete();
            hideModal();

            history.push(`/admin/3rd-party/administration/questionnaires/${slug}`); 
        },
        onError: () => {
            toast(t("An error occurred while creating new questionnaire."), {
                type: "error",
            });
        }
    });

    const {
        mutate: cloneQuestionnaireMutation,
        isLoading: iscloningQuestionnaire,    
    } = useMutation({
        mutationFn: async (payload) => {
            const service = QuestionnaireDesignerService.getInstance();
            return await service.cloneQuestionnaire(payload);
        },
        onSuccess: ({
            slug
        }) => {
            toast(t("New questionnaire created successfully."), {
                type: "success",
            });

            onComplete && onComplete();
            hideModal();

            history.push(`/admin/3rd-party/administration/questionnaires/${slug}`);    
        },
        onError: () => {
            toast(t("An error occurred while cloning the questionnaire."), {
                type: "error",
            });
        }
    });

    const actionInProgress = isCreatingQuestionnaire || iscloningQuestionnaire;

    return (
        <Modal isOpen={ showModal } backdrop="static" keyboard={ false } size="md">   
            <AvForm ref={ editGeneralInfosFormRef } onValidSubmit={(e, values) => {
                if(questionnaire){
                    // Clone
                    cloneQuestionnaireMutation({
                        questionnaire: questionnaire.id,
                        ...values,
                        fromName: undefined
                    });
                }
                else{
                    // Create
                    createQuestionnaireMutation(values);
                }
            }} className="needs-validation m-2">
                <ModalHeader toggle={hideModal}>
                    {questionnaire ? (
                        <>{t("Clone Questionnaire")}</>
                    ) : (
                        <>{t("Create New Questionnaire")}</>
                    )}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        {questionnaire && (
                            <Col sm="12" className="mb-3">
                                <Label for="questionnaire-infos-from-name-input">
                                    {t("Cloning From")}
                                </Label>
                                <AvField
                                    disabled={true}
                                    name="fromName"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: false }
                                    }}
                                    id="questionnaire-infos-from-name-input"
                                    defaultValue={questionnaire?.title}
                                />
                            </Col>
                        )}

                        <Col sm="12" className="mb-3">
                            <Label for="questionnaire-infos-name-input">
                                {t(questionnaire ? "New Title" : "Title")}
                            </Label>
                            <AvField
                                name="title"
                                type="text"
                                errorMessage={t("This field cannot be blank")}
                                className="form-control"
                                validate={{
                                    required: { value: true }
                                }}
                                id="questionnaire-infos-name-input"
                                defaultValue={questionnaire ? `${questionnaire?.title} - ${t('Copy')}` : ''}
                                placeholder={t("Enter title")}
                            />
                        </Col>
                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button onClick={hideModal} color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button disabled={actionInProgress} color="primary" type="submit" size="md">
                        {actionInProgress ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            <>
                                {questionnaire ? (
                                    <i className="ri-file-copy-line align-middle me-2"></i>
                                ) : (
                                    <i className="ri-add-line align-middle me-2"></i>
                                )}
                            </>
                        )}
                       
                        { t(questionnaire ? 'Clone' : 'Add') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
};

export default withNamespaces()(
    withRouter(CreateAndEditModal)
);