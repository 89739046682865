import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { 
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import ReactApexChart from "react-apexcharts";

import Select from "react-select";

import { useMutation, useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import {
    AvatarGroup,
    AvatarItem
} from "src/modules/3rd-party-management/components/avatar-group";

import Score from "src/modules/3rd-party-management/components/score";

import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";
import CommonService from "src/modules/3rd-party-management/apis/common/CommonService";

import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";

import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';

import FlagOnIcon from "src/modules/3rd-party-management/assets/images/flag_on.svg";
import FlagOffIcon from "src/modules/3rd-party-management/assets/images/flag_off.svg";

import OverviewHeaderContent from "src/modules/3rd-party-management/components/overview-header";

import {
    createSVGFromText
} from "src/modules/3rd-party-management/helpers/common";

import {
    SuppliersStatuses
} from "src/modules/3rd-party-management/constants";

const OverviewHeader = ({
    actionsRef,
    t,
    supplierId,
    settings
}) => {
    const defaultSettings = {
        status  :   {
            editable    :   false
        },
        analystAdmin    :   {
            editable    :   false
        },
        flagging        :   {
            editable    :   false
        }
    };

    const [ supplier, setSupplier ] = useState(null);

    const [ analystAdminsList, setAnalystAdminsList ] = useState([]);
    
    const [ showSupplierRemoveFlagModal, setShowSupplierRemoveFlagModal ] = useState(false);  

    const dateUtils = new DateUtils();

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-overview-contnet', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchOverviewContent(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching overview content.'), {
				type: 'error',
			});
		},
	});

    const handleFetchAnalystAndAdminAnalystList = useQuery({
		queryKey: ['3rd-party-management-fetch-analyst-and-admin-analyst-list'],
		queryFn: async () => {
			const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching analyst admins list.'), {
				type: 'error',
			});
		},
	});

    const handleUpdateSupplierStatusMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();

            return await service.updateStatus(payload);
        },
        onError: () => {
            toast(t("An error occurred while updating supplier status."), {
                type: "error",
            });
        }
    });

    const handleUpdateSupplierAssignedAnalystMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();

            return await service.updateAssignedAnalyst(payload);
        },
        onError: () => {
            toast(t("An error occurred while updating assigned analyst."), {
                type: "error",
            });
        }
    });

    const handleToggleSupplierFlagMutation = useMutation({
        mutationFn: async () => {
            const service = SupplierService.getInstance();

            return await service.toggleFlag(supplierId);
        },
        onSuccess: ({
            flag    :   newFlag
        }) => {
            setSupplier({
                ...supplier,
                flag    :   newFlag
            });

            toast(t("The flag status changed successfully"), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while toggling flag status."), {
                type: "error",
            });
        },
        onSettled: () => {
            setShowSupplierRemoveFlagModal(false);
        }
    });

    const statusesOptions = useMemo(() => {
        const result = [];

        for(const statusKey in SuppliersStatuses){
            result.push({
                baseLabel   :   SuppliersStatuses[statusKey]?.title,
                label       :   t(SuppliersStatuses[statusKey]?.title),
                value       :   statusKey,
                color       :   SuppliersStatuses[statusKey]?.color
            })
        } 

        return result;
    }, [t]);

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12">
                    <div className="loading-section p-5">
                        <Row className="mb-5">
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                            <Col sm="12" md="3">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '40%'}}></div>
                                <div className="dt-field dt-skeleton" style={{width: '70%'}}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                            <Col sm="12" md="4">
                                <div className="dt-field dt-skeleton mb-1" style={{width: '100%'}}></div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    const renderAssignedAnalyst = () => {
        const assignedAdminIsEditable = (settings?.analystAdmin && settings?.analystAdmin?.editable) ? true : defaultSettings.analystAdmin.editable;

        const result = [];

        if(assignedAdminIsEditable){
            const adminsListIsLoading = handleFetchAnalystAndAdminAnalystList.isFetching || handleFetchAnalystAndAdminAnalystList.isLoading;

            const adminUpdateInProgress = handleUpdateSupplierAssignedAnalystMutation.isFetching || handleUpdateSupplierAssignedAnalystMutation.isLoading;

            result.push(
                <Select
                    className="mb-2"
                    isDisabled={ adminsListIsLoading || adminUpdateInProgress }
                    isLoading={ adminsListIsLoading || adminUpdateInProgress }
                    placeholder={t("Select") + "..."}
                    classNamePrefix="select2-selection"
                    options={ analystAdminsList }
                    menuPortalTarget={ document.body }
                    onChange={ handleSupplierAssignedAnalystChange }
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            width             :   '220px',
                            backgroundColor   :   'transparent',
                            padding           :   '4px',
                            borderColor       :   '#21252980'
                        }),
                    }}
                    value={ supplier.analystAdmin }
                />
            );
        }

        if(supplier?.analystAdmin){
            const adminName = supplier.analystAdmin?.label;

            if(supplier.analystAdmin?.avatar){
                result.push(
                    <AvatarGroup max={4}>
                        <AvatarItem id={`overview-analyst-avatar-1`} 
                            title={ adminName } 
                            src={ supplier.analystAdmin.avatar }
                            alt="Analyst Avatar" 
                        />
                    </AvatarGroup>
                )
            }
            else{
                const adminShortName = ((adminName || '').match(/\b(\w)/g) || []).join('');

                const xml = (new XMLSerializer()).serializeToString(
                    createSVGFromText(`${ adminShortName }`)
                );

                result.push(
                    <AvatarGroup max={4}>
                        <AvatarItem id={`overview-analyst-avatar-1`} 
                            title={ adminName } 
                            src={`data:image/svg+xml;charset=utf-8,${ xml }`}
                            alt="Analyst Avatar" 
                        />
                    </AvatarGroup>
                )
            }
        }

        return (
            <div className="d-flex justify-content-center align-items-center flex-column">
                { result }
            </div>
        );
    }

    const handleSupplierStatusChange = (newStatus) => {
        handleUpdateSupplierStatusMutation.mutate({
            supplier    :   supplier.id,
            status      :   parseInt(newStatus.value)
        }, {
            onSuccess   :   () => {
                setSupplier({
                    ...supplier,
                    status  :   {
                        ...newStatus,
                        label   :   t(newStatus.baseLabel)
                    }
                });

                toast(t("Supplier status updated successfully."), {
                    type: "success",
                });
            }
        });
    }

    const handleSupplierAssignedAnalystChange = (newAnalyst) => {
        handleUpdateSupplierAssignedAnalystMutation.mutate({
            supplier        :   supplier.id,
            analystAdmin    :   parseInt(newAnalyst.value)
        }, {
            onSuccess   :   () => {
                setSupplier({
                    ...supplier,
                    analystAdmin    :   newAnalyst
                });

                toast(t("Assigned analyst updated successfully."), {
                    type: "success",
                });
            }
        });
    }

    const renderSupplierStatusSection = () => {
        const statusIsEditable = (settings?.status && settings?.status?.editable) ? true : defaultSettings.status.editable;

        if(statusIsEditable){
            return (
                <Select
                    isDisabled={ handleUpdateSupplierStatusMutation.isFetching || handleUpdateSupplierStatusMutation.isLoading }
                    isLoading={ handleUpdateSupplierStatusMutation.isFetching || handleUpdateSupplierStatusMutation.isLoading }
                    placeholder={t("Select") + "..."}
                    classNamePrefix="select2-selection"
                    id={`industry`}
                    options={ statusesOptions }
                    menuPortalTarget={document.body}
                    onChange={ handleSupplierStatusChange }
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            width             :   '220px',
                            backgroundColor   :   'transparent',
                            padding           :   '4px',
                            borderColor       :   '#21252980'
                        }),
                    }}
                    value={ supplier.status }
                />
            );
        }

        return (
            <span className="badge text-dark p-2" style={{
                backgroundColor     :   supplier.status.color
            }}>
                { supplier.status.label }
            </span>
        );
    }

    const renderSupplierFlaggingSection = () => {
        const flaggingIsEditable = (settings?.flagging && settings.flagging?.editable) ? true : defaultSettings.flagging.editable;

        if(flaggingIsEditable){
            const mutationInProcess = handleToggleSupplierFlagMutation.isLoading || handleToggleSupplierFlagMutation.isFetching;

            return (
                <Button onClick={() => {
                    if(supplier.flag){
                        setShowSupplierRemoveFlagModal(true);
                    }
                    else{
                        handleToggleSupplierFlagMutation.mutate();
                    }
                }} size="sm" outline className="supplier-flagging">
                    {
                        mutationInProcess ? (
                            <Spinner animation="border" variant="secondary" size="sm"/>
                        ) : (
                            <>
                                {
                                    supplier.flag ? (
                                        <img alt="" src={ FlagOnIcon } />
                                    ) : (
                                        <>
                                            <img alt="" src={ FlagOffIcon } />
                                            <span className="info">
                                                { t(`This ${supplier?.isOwnCompany ? 'Own Company' : 'Supplier'} Is Not Flagged`) }
                                            </span>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </Button>
            );
        }

        return (
            <span className="supplier-flagging">
                {
                    supplier.flag ? (
                        <img alt="" src={ FlagOnIcon } />
                    ) : (
                        <>
                            <img alt="" src={ FlagOffIcon } />
                            <span className="info">
                            { t(`This ${supplier?.isOwnCompany ? 'Own Company' : 'Supplier'} Is Not Flagged`) }
                            </span>
                        </>
                    )
                }
            </span>
        );
    }

    const renderModals = () => {
        const toggleRemoveFlagConfirmModal = () => setShowSupplierRemoveFlagModal(!showSupplierRemoveFlagModal);

        const mutationInProcess = handleToggleSupplierFlagMutation.isFetching || handleToggleSupplierFlagMutation.isLoading;

        return (
            <Modal isOpen={ !!showSupplierRemoveFlagModal } toggle={ toggleRemoveFlagConfirmModal }>
                <ModalHeader toggle={ toggleRemoveFlagConfirmModal }>
                    { t('You are removing flag') }
                </ModalHeader>
                
                <ModalBody>
                    {
                        `${t('Do you like to remove this company from the monitoring system')}?`
                    }
                </ModalBody>

                <ModalFooter>
                    <Button disabled={ mutationInProcess } onClick={ () => setShowSupplierRemoveFlagModal(false) } color="secondary" outline>
                        {t('No')}
                    </Button>

                    <Button disabled={ mutationInProcess } onClick={ () => handleToggleSupplierFlagMutation.mutate() } color="primary">
                        {
                            mutationInProcess ? (
                                <>
                                    <Spinner animation="border" variant="white" size="sm" className="me-1"/>
                                    {`${t('Updating')}...`}
                                </>
                            ) : (
                                <>{t('Yes')}</>
                            )
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    useEffect(() => {
        if(handleFetchSupplierBasicInformation.data){
            const currentStatus = {
                baseLabel   :   SuppliersStatuses[handleFetchSupplierBasicInformation.data.status]?.title,
                value       :   handleFetchSupplierBasicInformation.data.status,
                label       :   t(SuppliersStatuses[handleFetchSupplierBasicInformation.data.status]?.title),
                color       :   SuppliersStatuses[handleFetchSupplierBasicInformation.data.status]?.color
            };

            const {
                analystAdmin
            } = handleFetchSupplierBasicInformation.data;

            let currentAnalystAdmin = null;

            if(analystAdmin){
                currentAnalystAdmin = {
                    value   :   analystAdmin?.id,
                    label   :   analystAdmin?.name,
                    avatar  :   analystAdmin?.avatar 
                };
            }

            setSupplier({
                ...handleFetchSupplierBasicInformation.data,
                status          :   currentStatus,
                analystAdmin    :   currentAnalystAdmin
            });
        }
    }, [handleFetchSupplierBasicInformation.data]);

    useEffect(() => {
        if(handleFetchAnalystAndAdminAnalystList.data && Array.isArray(handleFetchAnalystAndAdminAnalystList.data)){
            const newList = handleFetchAnalystAndAdminAnalystList.data.map((analyst) => {
                return {
                    value   :   analyst.id,
                    label   :   `${analyst.first_name} ${analyst.last_name}`,
                    avatar  :   analyst.avatar
                };
            }); 

            setAnalystAdminsList(newList);
        }
    }, [ handleFetchAnalystAndAdminAnalystList.data ]);

    useEffect(() => {
        if(supplier){
            setSupplier({
                ...supplier,
                status          :   {
                    ...supplier.status,
                    label   :   t(supplier.status.baseLabel)
                }
            });
        }
    }, [t]);

    const renderContent = () => {
        const firstRowItems = [
            {
                title   :   'ID',
                value   :   supplier.secondaryId
            },
            {
                title   :   'Name',
                value   :   supplier.name
            },
            {
                title   :   'Short Name',
                value   :   supplier.shortName
            },
            {
                title   :   'Created on',
                value   :   dateUtils.convertDateToDate(
                    supplier.createdAt,
                    INTERNATIONAL_DATE_FORMAT
                )
            },
            {
                title   :   'Last Update',
                value   :   dateUtils.convertDateToDate(
                    supplier.createdAt,
                    INTERNATIONAL_DATE_FORMAT
                )
            },
            {
                title   :   'Status',
                value   :   renderSupplierStatusSection()
            }
        ];

        if(parseInt(supplier.score || 0) < 10){
            firstRowItems.push({
                title   :   'Score',
                value   :   <Score value={supplier.score} displayNumericValue={true}/>
            });
        }

        firstRowItems.push({
            title   :   'Flagging',
            value   :   renderSupplierFlaggingSection()
        });

        return (
            <OverviewHeaderContent items={[
                [...firstRowItems],
                [
                    {
                        title   :   'Assigned Analyst',
                        value   :   renderAssignedAnalyst()
                    },
                    {
                        title   :   'Mitigated Risks',
                        value   :   (
                            <>
                                <ReactApexChart
                                    options={{
                                        chart: {
                                            sparkline: {
                                                enabled: true
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        colors: ['#FF4949'],
                                        stroke: {
                                            lineCap: 'round'
                                        },
                                        plotOptions: {
                                            radialBar: {
                                                hollow: {
                                                    margin: 0,
                                                    size: '70%'
                                                },
                                                track: {
                                                    margin: 0,
                                                },
                            
                                                dataLabels: {
                                                    show: false
                                                }
                                            }
                                        }
                                    }} 
                                    series={[supplier?.completedMitigatedTasks || 0]} 
                                    type="radialBar" 
                                    height="60" 
                                />
                                <p className="text-center">{supplier?.completedMitigatedTasks || 0}%</p>
                            </>
                        )
                    },
                    {
                        title   :   'Completed Tasks',
                        value   :   (
                            <>
                                <ReactApexChart
                                    options={{
                                        chart: {
                                            sparkline: {
                                                enabled: true
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        colors: ['#195A4D'],
                                        stroke: {
                                            lineCap: 'round'
                                        },
                                        plotOptions: {
                                            radialBar: {
                                                hollow: {
                                                    margin: 0,
                                                    size: '70%'
                                                },
                                                track: {
                                                    margin: 0,
                                                },
                            
                                                dataLabels: {
                                                    show: false
                                                }
                                            }
                                        }
                                    }} 
                                    series={[supplier?.completedTasks || 0]} 
                                    type="radialBar" 
                                    height="60" 
                                />
                                <p className="text-center">{supplier?.completedTasks || 0}%</p>
                            </>
                        )
                    }
                ]
            ]} />
        )
    }

    useImperativeHandle(actionsRef, () => {
        return {
            reload  :   () => {
                handleFetchSupplierBasicInformation.refetch();
            }
        }
    });

    return (
        <React.Fragment>
            {
                handleFetchSupplierBasicInformation.isFetching || handleFetchSupplierBasicInformation.isLoading || !supplier ? (
                    <>{ renderLoadingSection() }</>
                ) : (
                    <>
                        { renderContent() }
                    </>
                )
            }
            { renderModals() }
        </React.Fragment>
    );
};

export default withNamespaces()(OverviewHeader);