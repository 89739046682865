import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class QuestionnaireService {
	static instance;

	static getInstance() {
		if (!QuestionnaireService.instance) {
			QuestionnaireService.instance = new QuestionnaireService();
		}

		return QuestionnaireService.instance;
	}

    async questions(type, organization, params){
		const response = await axios.get(`${API_BASE_URL}/questionnaire/${type}/organization/${organization}/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}

	async fetchPrevFilledAnswers(type, organization, params){
		const response = await axios.get(`${API_BASE_URL}/questionnaire/${type}/${organization}/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}
}
