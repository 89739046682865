import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import { EMPTY_LIST } from "../table-filter/constants";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableFilter from "../table-filter";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import { withRouter } from "react-router-dom";
import UserUtils from "src/services/utils/UserUtils";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import { RoleOptions } from "../table-filter/RoleOptions";
import {
  fetchUsers,
  deleteUser,
  fetchCounts,
  updateUserStatus,
} from "../../api/apiFunctions";
import { getTableColumns } from "./user-table-columns";
import { routes } from "../../data";
import DeleteUserModal from "../modal/DeleteUserModal";

const UserTable = (props) => {
  const { t, user } = props;
  const activeModule = props.modules?.active;
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    name: "",
    role: [],
  });
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [selectedFiltersDisplay, setSelectedFiltersDisplay] = useState({});

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [users, setUsers] = useState([]);

  const userUtils = new UserUtils(user);
  const getModuleRoutes = (activeModule) => routes[activeModule];

  const updateUserStatusHandler = (id, nextStatus) => {
    updateUserStatusMutation.mutate({ userId: id, nextStatus });
  };

  const tableColumns = getTableColumns({
    t,
    userUtils,
    setUserIdToDelete,
    user,
    activeModule,
    updateUserStatusHandler,
  });

  const handleFetchUsersQuery = useQuery({
    queryKey: ["user-management-fetch-list", tableFilters],
    queryFn: async () => {
      const { name, role } = tableFilters;
      return await fetchUsers({
        token: props.token,
        activeModule,
        payload: {
          name,
          role: role?.map((r) => r.value),
        },
      });
    },
    refetchOnWindowFocus: false,
    onError: () => {
      toast(t("An error occurred while fetching user list."), {
        type: "error",
      });
    },
  });

  const handleFetchUsersCountQuery = useQuery({
    queryKey: ["user-management-fetch-count-by-role"],
    queryFn: async () => {
      return await fetchCounts({
        token: props.token,
        activeModule,
      });
    },
    refetchOnWindowFocus: false,
    onError: () => {
      toast(t("An error occurred while fetching user counts."), {
        type: "error",
      });
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: async (userId) => {
      await deleteUser({
        activeModule,
        token: props.token,
        userId,
      });
    },
    onSuccess: () => {
      toast(props.t("User deleted successfully"), {
        type: "success",
      });

      setUsers((prevUsers) =>
        prevUsers.filter((item) => item.id !== userIdToDelete)
      );
      setUserIdToDelete(null);
      handleFetchUsersCountQuery.refetch();
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.error === "user_has_uncompleted_tasks") {
          toast(props.t("This employee has some uncompleted tasks."), {
            type: "error",
          });
        } else if (error.response) {
          const data = error.response.data;
          if (
            data.error === "unknown_error" &&
            data.message === "Access Denied."
          ) {
            toast(props.t("User cannot be deleted"), { type: "error" });
          } else {
            toast(props.t(data.message), { type: "error" });
          }
        }
      } else {
        toast("An error occurred while deleting the user.", { type: "error" });
      }
    },
  });

  const updateUserStatusMutation = useMutation({
    mutationFn: async ({ userId, nextStatus }) => {
      await updateUserStatus({
        activeModule,
        token: props.token,
        userId,
        payload: { status: nextStatus },
      });
    },
    onSuccess: () => {
      toast(props.t("User status updated successfully"), {
        type: "success",
      });
      handleFetchUsersQuery.refetch();
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data;
        toast(props.t(data.message), { type: "error" });
      } else {
        toast(props.t("An error occurred while updating the user status"), {
          type: "error",
        });
      }
    },
  });

  const NoDataIndication = () =>
    handleFetchUsersQuery.isFetched && !handleFetchUsersQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    let displayFilters = {};
    
    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        result[[tableFilterKey]] = filters[tableFilterKey];
        displayFilters[tableFilterKey] = filters[tableFilterKey];
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
        displayFilters[tableFilterKey] = filters[tableFilterKey];
      }
    }
    setTableFilters(result);

    setSelectedFiltersDisplay(displayFilters);
  };

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data;
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      case "sort": {
        const { sortField, sortOrder } = data;

        const sortedList = [...users].sort((a, b) => {
          if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
        setUsers(sortedList);
        break;
      }
      default:
        return false;
    }
  };
  const roleOptions = RoleOptions(activeModule);

  const deleteUserHandler = () => {
    deleteUserMutation.mutate(userIdToDelete);
  };

  const paginatedUsers = () => {
    const { pageIndex, pageSize } = tableFilters;
    const startIndex = (pageIndex - 1) * pageSize;
    return users.slice(startIndex, startIndex + pageSize);
  };

  useEffect(() => {
    if (handleFetchUsersQuery.data) {
      const users = handleFetchUsersQuery.data;
      setUsers(users);
      setTableResultTotalCount(handleFetchUsersQuery.data?.length || 0);
    }
  }, [handleFetchUsersQuery.data]);

  return (
    <div className="p-4" style={{ marginBottom: "300px" }}>
      {
        <DeleteUserModal
          isOpen={userIdToDelete}
          close={() => setUserIdToDelete(null)}
          onConfirm={deleteUserHandler}
          isLoading={deleteUserMutation?.isLoading}
        />
      }

      {isFeatureEnabledForActiveModule("list-users") ? (
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: tableFilters.pageIndex,
            sizePerPage: tableFilters.pageSize,
            totalSize: tableResultTotalCount,
            withFirstAndLast: false,
            alwaysShowAllBtns: true,
            prePageText: (
              <span>
                <i className="ri-arrow-left-s-line"></i> {props.t("Back")}
              </span>
            ),
            nextPageText: (
              <span>
                {props.t("Next")} <i className="ri-arrow-right-s-line"></i>
              </span>
            ),
            prePageTitle: props.t("Pre page"),
            firstPageTitle: props.t("Next page"),
            showTotal: true,
            hideSizePerPage: false,
            sizePerPageList: [
              {
                text: "25",
                value: 25,
              },
              {
                text: "50",
                value: 50,
              },
            ],
          })}
        >
          {({ paginationProps, paginationTableProps }) => {
            const { role } = selectedFiltersDisplay;
            let filteredRolesString = "";
            if (role) {
              const selectedRoles = role;

              const filteredRoles = roleOptions.filter((role) =>
                selectedRoles.some((selected) => selected.value === role.value)
              );

              filteredRolesString = filteredRoles
                .map((role) => t(role.label))
                .join(", ");
            }

            return (
              <React.Fragment>
                <Row className="mb-3 mt-3">
                  <Col sm="12">
                    <div className="d-flex gap-4 justify-content-between">
                      {!handleFetchUsersCountQuery?.isFetching ? (
                        <div>
                          <Label
                            hidden={!userUtils?.isSuperAdmin(user)}
                            className="me-4"
                          >{`${t("Analyst Admins/Analysts")} : ${
                            handleFetchUsersCountQuery?.data
                              ?.registeredAnalysts || 0
                          }/${
                            handleFetchUsersCountQuery?.data
                              ?.analystLicenseCount || 0
                          }`}</Label>

                          <Label
                            hidden={activeModule === "tp"}
                            className="me-4"
                          >{`${t("Task Owners")} : ${
                            handleFetchUsersCountQuery?.data
                              ?.registeredTaskOwners || 0
                          }/${
                            handleFetchUsersCountQuery?.data
                              ?.taskOwnerLicenseCount || 0
                          }`}</Label>
                        </div>
                      ) : (
                        <div className="dt-field dt-skeleton dt-select-list me-4"></div>
                      )}

                      <div className="d-flex gap-3 align-items-start">
                        <div>
                          <TableFilter
                            defaultValues={{
                              ...tableFilters,
                            }}
                            onOkButtonClicked={onTableFilterOkButtonClicked}
                          />
                          <div
                            className="d-flex gap-4 justify-content-end mt-2"
                            hidden={!role}
                          >
                            <ul
                              style={{
                                maxWidth: "312px",
                              }}
                            >
                              <li hidden={!filteredRolesString.length}>
                                {/* {t("Roles")}: {`${filteredRolesString}`} */}
                              </li>
                            </ul>
                          </div>
                        </div>

                        {isFeatureEnabledForActiveModule("ad-settings") && (
                          <div className="flex-inline text-sm-end">
                            <Link to="/admin/administration/users/ad-settings">
                              <Button
                                type="button"
                                color="primary"
                                className="btn  mb-3  "
                              >
                                {props.t("AD settings")}
                              </Button>
                            </Link>
                          </div>
                        )}
                        {isFeatureEnabledForActiveModule("new-user") && (
                          <div
                            className="flex-inline text-sm-end"
                            hidden={
                              activeModule === "tp" &&
                              !userUtils?.isSuperAdmin(user)
                            }
                          >
                            <Link to={getModuleRoutes(activeModule).newUser}>
                              <Button
                                type="button"
                                color="success"
                                className="btn mb-3  "
                              >
                                <i className="mdi mdi-plus me-1" />{" "}
                                {props.t("New user")}
                              </Button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <BootstrapTable
                      remote={{
                        pagination: true,
                        filter: false,
                        sort: true,
                        cellEdit: false,
                        search: false,
                      }}
                      loading={
                        handleFetchUsersQuery.isFetching ||
                        handleFetchUsersQuery.isLoading
                      }
                      overlay={overlayFactory({
                        spinner: (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="md"
                          />
                        ),
                        text: "Loading...",
                      })}
                      onTableChange={handleTableChange}
                      defaultSorted={[]}
                      keyField={"id"}
                      responsive
                      bordered={false}
                      data={paginatedUsers()}
                      striped={true}
                      columns={tableColumns}
                      wrapperClasses="table-responsive"
                      classes={"table tpdd-table incident-table"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        overflowX: "auto",
                      }}
                      noDataIndication={() => <NoDataIndication />}
                      {...paginationTableProps}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <div className="tpdd-pagination-style-1">
                      <PaginationListStandalone {...paginationProps} />

                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            );
          }}
        </PaginationProvider>
      ) : (
        <Alert className="mb-4" color="warning">
          {props.t("You do not have permission to access these pages")}
        </Alert>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(UserTable)))
);
