import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label, FormGroup, Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/admin/CustomizationService";
import BackgroundImage from "src/assets/images/background.svg";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";
import Dropzone from "react-dropzone";
import CropperModal from "./CropperModal";

const TPDDCompanyBackgroundCustomization = (props) => {
    const { t, fetchOrganizationDetails, reloadActiveModuleSettings } = props;
    const details = fetchOrganizationDetails.data;
    const [signinImage, setSigninImage] = useState(details?.signin_picture);
    const [landingImage, setLandingImage] = useState(details?.landing_picture);
    const [ imageToCrop, setImageToCrop ] = useState(null);

    const prevImageStyle = {
        marginTop: "10px",
        maxWidth: "100%",
        maxHeight: "200px",
    };

    const handleSubmitMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData();
            // Append logos to formData

            if (typeof signinImage === "object") {
                formData.append("signInBackground", signinImage);
            }

            if (typeof landingImage === "object") {
                formData.append("landingBackground", landingImage);
            }

            const service = CustomizationService.getInstance();
            return await service.editBackgrounds(formData);
        },
        onSuccess: () => {
            reloadActiveModuleSettings();
            toast(t("Data saved successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("Failed to save data."), {
                type: "error",
            });
        },
    });

    const restore = () => {
        setSigninImage(details?.signin_picture);
        setLandingImage(details?.landing_picture);
    };

    const handleAcceptedFile = (type, selectedFile) => {
        if (selectedFile[0].size > 1 * 1024 * 1024) {
            toast(t("File size must be less than 1MB"), {
                type: "error",
            });
            return;
        }

        if(type === 'landing'){
            setImageToCrop(selectedFile[0])
        }
        else{
            setSigninImage(selectedFile[0])
        }
    };

    const signinImagePreview = typeof signinImage === 'string' ? signinImage :  URL.createObjectURL(signinImage);
    const landingImagePreview = typeof landingImage === 'string' ? landingImage :  URL.createObjectURL(landingImage);

    return (
        <Row>
            <div className="page-tpdd-customization">
                <Row>
                    <Col className="d-flex align-items-start customization-image-col">
                        <img src={BackgroundImage} alt="Background" />
                    </Col>
                    <Col className="customization-accordion-col">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item
                                className="border-none cusom-accordion-item"
                                eventKey="0"
                            >
                                <Accordion.Header className="custom-accordion-header">
                                    <div className="p-1 custom-div-header">
                                        {`5. ${t("Background")}`}{" "}
                                        <>
                                            <span
                                                id={`section-info-button-font`}
                                                className="section-info-button"
                                            >
                                                <i className="ri-information-line" />
                                            </span>

                                            <UncontrolledPopover
                                                placement="bottom"
                                                trigger="hover"
                                                target={`section-info-button-font`}
                                            >
                                                <PopoverBody>
                                                <span>
                                                    {t(
                                                    "this is how it looks after uploading. uploader is like the one used in icon part"
                                                    )}
                                                </span>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col lg="6" md="6" sm="12" className="mt-3">
                                            <FormGroup>
                                                <Label for="fontFamily">
                                                    {t("Sign In Background Picture") + ":"}
                                                </Label>
                                                <>
                                                    <span
                                                        id={`section-info-button-bg`}
                                                        className="section-info-button"
                                                    >
                                                        <i className="ri-information-line" />
                                                    </span>

                                                    <UncontrolledPopover
                                                        placement="bottom"
                                                        trigger="hover"
                                                        target={`section-info-button-bg`}
                                                    >
                                                        <PopoverBody>
                                                        <span>
                                                            {t(
                                                            "For optimal display, use an image with a resolution of 1920x1080 pixels (16:9 aspect ratio) and a maximum file size of 1MB. Supported formats: JPEG, PNG, or JPG."
                                                            )}
                                                        </span>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </>
                                                <Dropzone
                                                    multiple={false}
                                                    onDrop={(acceptedFile) =>
                                                        handleAcceptedFile("signin", acceptedFile)
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone tpdd">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <button type="button" className="btn select-files-btn">
                                                                    {props.t('Select file...')}
                                                                </button>
                                                                <h4>
                                                                    {props.t(
                                                                        "Drop file here or click to upload"
                                                                    )}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                
                                                <img 
                                                    src={signinImagePreview} 
                                                    style={prevImageStyle} 
                                                    alt="Signin"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col lg="6" md="6" sm="12" className="mt-3">
                                            <FormGroup>
                                                <Label for="fontFamily">
                                                    {t("Landing Background Picture") + ":"}
                                                </Label>
                                                <span
                                                    id={`section-info-button-bg`}
                                                    className="section-info-button"
                                                >
                                                    <i className="ri-information-line" />
                                                </span>

                                                <UncontrolledPopover
                                                    placement="bottom"
                                                    trigger="hover"
                                                    target={`section-info-button-bg`}
                                                >
                                                    <PopoverBody>
                                                        <span>
                                                            {t(
                                                            "For optimal display, use an image with a resolution of 1920x1080 pixels (16:9 aspect ratio) and a maximum file size of 1MB. Supported formats: JPEG, PNG, or JPG."
                                                            )}
                                                        </span>
                                                    </PopoverBody>
                                                </UncontrolledPopover>

                                                <Dropzone
                                                    multiple={false}
                                                    onDrop={(acceptedFile) =>
                                                        handleAcceptedFile("landing", acceptedFile)
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone tpdd">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <button type="button" className="btn select-files-btn">
                                                                    {props.t('Select file...')}
                                                                </button>
                                                                <h4>
                                                                    {props.t(
                                                                        "Drop file here or click to upload"
                                                                    )}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>

                                                <img 
                                                    src={landingImagePreview}
                                                    style={prevImageStyle} 
                                                    alt="Landing"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                                <Row className="p-4">
                                    <Col sm="12" className="d-flex justify-content-end">
                                        <Button
                                            onClick={restore}
                                            color="primary"
                                            type="button"
                                            outline
                                            className="me-2"
                                            disabled={
                                                handleSubmitMutation.isLoading ||
                                                fetchOrganizationDetails.isLoading ||
                                                fetchOrganizationDetails.isFetching
                                            }
                                        >
                                            {t("Restore")}
                                        </Button>

                                        <Button
                                            color="primary"
                                            onClick={handleSubmitMutation.mutate}
                                            type="submit"
                                            disabled={
                                                handleSubmitMutation.isLoading ||
                                                fetchOrganizationDetails.isLoading ||
                                                fetchOrganizationDetails.isFetching
                                            }
                                        >
                                            {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>

            <CropperModal 
                image={imageToCrop}
                onCancel={() => setImageToCrop(null)}
                onDone={(file) => {
                    setLandingImage(file)
                    setImageToCrop(null)
                }}
            />
        </Row>
    );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(
      mapStatetoProps,
      mapDispachToProps
    )(TPDDCompanyBackgroundCustomization)
  )
);
