import { Link } from 'react-router-dom'
import { INTERNATIONAL_DATE_FORMAT } from 'src/common/constants'
import { Button } from 'reactstrap'
import { TasksStatuses, TasksPriorities, ResidualRisksRates } from '../../constants'

export const tableAllRisksColumnsRenderer = ({
    t,
    dateUtils,
    onDeleteButtonClicked,
    history
}) => {
    return [
        {
            dataField: 'result_color',
            text: t('Rating'),
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                width: '5%'
            },
            key: 1,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        <span
                            className='dt-risk-rating-bullet'
                            style={{
                                backgroundColor: `#${cellContent}`,
                                opacity: row.is_deleted ? 0.15 : 1
                            }}
                        />
                    </div>
                )
            }
        },
        {
            dataField: 'id',
            text: t('Risk ID'),
            sort: false,
            key: 2,
            formatter: (cellContent, row) => {
                return (
                    <span
                        style={{
                            cursor: !row.is_deleted && 'pointer'
                        }}
                        onClick={() =>
                            !row.is_deleted &&
                            history.push(
                                `/admin/risk-management/${row.id}/details?incomming_tab=all-risks`
                            )
                        }
                    >
                        {`${row.organization?.is_own_company ? 'E' : 'TP'}${row.organization?.secondary_id}/R${row.secondary_id}`}
                    </span>
                )
            }
        },
        {
            dataField: 'case_id',
            text: t('Entity'),
            headerStyle: {
                textAlign: 'center'
            },
            sort: false,
            key: 3,
            formatter: (cellContent, row) => {
                return (
                    <div className='text-center'>
                        {row.organization.name}
                    </div>
                )
            }
        },
        {
            dataField: 'category',
            text: t('Category'),
            sort: false,
            key: 4,
            formatter: (cellContent, row) => {
                return <div>{t(row.category.main.name)}</div>
            }
        },
        {
            dataField: 'category',
            text: t('Sub Category'),
            sort: false,
            key: 5,
            formatter: (cellContent, row) => {
                return <div>{t(row.category.subCategory.name)}</div>
            }
        },
        {
            dataField: "residual_risk",
            text: t("Residual Risk Rate"),
            sort: false,
            key: 6,
            style: {
                width: '120px'
            },
            formatter: (cellContent, {
                is_deleted
            }) => {
                return (
                    <div>
                        <span title={t(ResidualRisksRates[cellContent]?.title)} className="dt-risk-rating-bullet"
                            style={{
                                backgroundColor: `${ResidualRisksRates[cellContent]?.color}`,
                                opacity: is_deleted ? 0.15 : 1
                            }}
                        />
                    </div>
                )
            }
        },
        {
            dataField: 'owner_name',
            text: t('Risk owner'),
            sort: false,
            key: 7,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'manager',
            text: t('Risk Manager'),
            sort: false,
            key: 8,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex  justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'tasks',
            text: t('Mitigation'),
            style: {
                maxWidth: '200px',
                wordWrap: 'break-word'
            },
            sort: false,
            key: 9,
            formatter: (cellContent, row) => {
                return cellContent && cellContent.length
                    ? cellContent.map((t, i) => (
                          <Link onClick={() => console.log(row)} key={t.id}>
                              {`T${t.secondary_id}`}
                              <span hidden={i === cellContent.length - 1}>
                                  ,
                              </span>
                          </Link>
                      ))
                    : null
            }
        },
        {
            dataField: 'createdAt',
            text: t('Creation Date'),
            key: 10,
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <div>
                        {dateUtils.convertTimeStampToDate(
                            row.created_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                    </div>
                )
            }
        },
        {
            dataField: 'is_deleted',
            text: t('Action'),
            headerStyle: {
                paddingInline: '30px',
                textAlign: 'center'
            },
            sort: false,
            key: 11,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex justify-content-center'>
                        {row.is_deleted ? (
                            <span className='text-danger'>
                                {t('DELETED')}
                            </span>
                        ) : (
                            <Button
                                onClick={() => onDeleteButtonClicked(row)}
                                outline
                                color='danger'
                                className='border-0'
                            >
                                <i className='ri-delete-bin-line font-size-24' />
                            </Button>
                        )}
                    </div>
                )
            }
        }
    ]
}

export const tableMitigationTasksColumnsRenderer = ({ t, dateUtils, history }) => {
    return [
        {
            dataField: 'secondary_id',
            text: t('Task ID'),
            style: {
                width: '5%'
            },
            key: 0,
            formatter: (cellContent, row) => {
                const canRedirect = !row.is_deleted

                return (
                    <span
                        style={{
                            cursor: canRedirect && 'pointer'
                        }}
                        onClick={() =>
                            canRedirect &&
                            history.push(
                                `/admin/risk-management/task/${row.id}/details?incomming_tab=mitigation-tasks`
                            )
                        }
                    >
                        {`${row.supplier?.isOwnCompany ? 'E' : 'TP'}${row.supplier?.secondaryId}/R${row.relatedRisk.secondary_id}/T${row.secondary_id}`}
                    </span>
                )
            }
        },
        {
            dataField: 'name',
            text: t('Title'),
            style: {
                width: '10%'
            },
            key: 1
        },
        {
            dataField: 'relatedRisk',
            text: t('Related Risk'),
            sort: false,
            key: 2,
            formatter: (cellContent, row) => {
                const canRedirect =
                    !row.is_deleted && !row.relatedRisk.deleted

                return (
                    <span
                        style={{
                            cursor: canRedirect && 'pointer'
                        }}
                        onClick={() =>
                            canRedirect &&
                            history.push(
                                `/admin/risk-management/${row.relatedRisk.id}/details?incomming_tab=mitigation-tasks`
                            )
                        }
                    >
                        {`${row.supplier?.isOwnCompany ? 'E' : 'TP'}${row.supplier?.secondaryId}/R${row.relatedRisk.secondary_id}`}
                    </span>
                )
            }
        },
        {
            dataField: 'createdAt',
            text: t('Creation Date'),
            key: 3,
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <div>
                        {dateUtils.convertTimeStampToDate(
                            row.created_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                    </div>
                )
            }
        },
        {
            dataField: 'endedAt',
            text: t('Deadline'),
            key: 4,
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex flex-column justify-content-start align-items-baseline'>
                        {dateUtils.convertTimeStampToDate(
                            row.ended_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                        {row.ended_at && row.overdue && (
                            <span className='bg-danger me-1 badge badge-danger'>
                                {t('Overdue')}
                            </span>
                        )}
                    </div>
                )
            }
        },
        {
            dataField: 'manager',
            text: t('Task Manager'),
            sort: false,
            key: 5,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex  justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'owner',
            text: t('Task Owner'),
            sort: false,
            key: 6,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex  justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'category',
            text: t('Category'),
            sort: false,
            key: 7,
            style: {
                width: '10%'
            },
            formatter: (cellContent, row) => {
                return <div>{t(row?.category?.name)}</div>
            }
        },
        {
            dataField: 'priority',
            text: t('Priority'),
            sort: false,
            key: 8,
            formatter: (cellContent, row) => {
                const taskPriority = TasksPriorities[row.priority]
                return (
                    <div className='d-flex justify-content-start'>
                        <span
                            style={{
                                color: `${taskPriority.color}`
                            }}
                        >
                            {taskPriority.icon}
                        </span>

                        <span style={{ marginLeft: '4px' }}>
                            {t(taskPriority.title)}
                        </span>
                    </div>
                )
            }
        },
        {
            dataField: 'status',
            text: t('Status'),
            sort: false,
            key: 9,
            formatter: (cellContent, row) => {
                const taskStatus = TasksStatuses[row.status]
                return (
                    <div
                        className='dt-task-status text-dark'
                        style={{
                            backgroundColor: `${taskStatus.color}`
                        }}
                    >
                        {t(taskStatus.title)}
                    </div>
                )
            }
        },
        {
            dataField: 'deleted_at',
            text: t('Deletion Date'),
            sort: false,
            key: 10,
            formatter: (cellContent, row) => {
                return (
                    <div>
                        {row.deleted_at
                            ? dateUtils.convertTimeStampToDate(
                                  row.deleted_at,
                                  INTERNATIONAL_DATE_FORMAT
                              )
                            : null}
                    </div>
                )
            }
        }
    ]
}

export const tableMyRisksColumnsRenderer = ({
    t,
    dateUtils,
    onDeleteButtonClicked,
    history
}) => {
    return [
        {
            dataField: 'result_color',
            text: t('Risk'),
            headerStyle: {
                textAlign: 'center'
            },
            style: {
                width: '5%'
            },
            key: 1,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex justify-content-center align-items-center'>
                        <span
                            className='dt-risk-rating-bullet'
                            style={{
                                backgroundColor: `#${cellContent}`,
                                opacity: row.is_deleted ? 0.15 : 1
                            }}
                        />
                    </div>
                )
            }
        },
        {
            dataField: 'id',
            text: t('Risk ID'),
            sort: false,
            key: 2,
            formatter: (cellContent, row) => {
                return (
                    <span
                        style={{
                            cursor: !row.is_deleted && 'pointer'
                        }}
                        onClick={() =>
                            !row.is_deleted &&
                            history.push(
                                `/admin/risk-management/${row.id}/details?incomming_tab=my-risks`
                            )
                        }
                    >
                        {`${row.organization?.is_own_company ? 'E' : 'TP'}${row.organization?.secondary_id}/R${row.secondary_id}`}
                    </span>
                )
            }
        },
        {
            dataField: 'case_id',
            text: t('Entity'),
            headerStyle: {
                textAlign: 'center'
            },
            sort: false,
            key: 3,
            formatter: (cellContent, row) => {
                return (
                    <div className='text-center'>
                        {row.organization.name}
                    </div>
                )
            }
        },
        {
            dataField: 'category',
            text: t('Category'),
            sort: false,
            key: 4,
            formatter: (cellContent, row) => {
                return <div>{t(row.category.main.name)}</div>
            }
        },
        {
            dataField: 'category',
            text: t('Sub Category'),
            sort: false,
            key: 5,
            formatter: (cellContent, row) => {
                return <div>{t(row.category.subCategory.name)}</div>
            }
        },
        {
            dataField: "residual_risk",
            text: t("Residual Risk Rate"),
            sort: false,
            key: 6,
            style: {
                width: '120px'
            },
            formatter: (cellContent, {
                is_deleted
            }) => {
                return (
                    <div>
                        <span title={t(ResidualRisksRates[cellContent]?.title)} className="dt-risk-rating-bullet"
                            style={{
                                backgroundColor: `${ResidualRisksRates[cellContent]?.color}`,
                                opacity: is_deleted ? 0.15 : 1
                            }}
                        />
                    </div>
                )
            }
        },
        {
            dataField: 'owner_name',
            text: t('Risk owner'),
            sort: false,
            key: 7,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'manager',
            text: t('Risk Manager'),
            sort: false,
            key: 8,
            formatter: (cellContent, row) => {
                return cellContent ? (
                    <div className='d-flex  justify-content-center align-items-center'>
                        <span className='dt-list-col-bullet'>
                            {cellContent[0]}
                        </span>{' '}
                        <span className='dt-list-col-bullet-text'>
                            {cellContent}
                        </span>
                    </div>
                ) : null
            }
        },
        {
            dataField: 'tasks',
            text: t('Mitigation'),
            style: {
                maxWidth: '200px',
                wordWrap: 'break-word'
            },
            sort: false,
            key: 9,
            formatter: (cellContent, row) => {
                return cellContent && cellContent.length
                    ? cellContent.map((t, i) => (
                          <Link onClick={() => console.log(row)} key={t.id}>
                              {`T${t.secondary_id}`}
                              <span hidden={i === cellContent.length - 1}>
                                  ,
                              </span>
                          </Link>
                      ))
                    : null
            }
        },
        {
            dataField: 'createdAt',
            text: t('Creation Date'),
            key: 10,
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <div>
                        {dateUtils.convertTimeStampToDate(
                            row.created_at,
                            INTERNATIONAL_DATE_FORMAT
                        )}
                    </div>
                )
            }
        },
        {
            dataField: 'is_deleted',
            text: t('Action'),
            headerStyle: {
                paddingInline: '30px',
                textAlign: 'center'
            },
            sort: false,
            key: 11,
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex justify-content-center'>
                        {row.is_deleted ? (
                            <span className='text-danger'>
                                {t('DELETED')}
                            </span>
                        ) : (
                            <Button
                                onClick={() => onDeleteButtonClicked(row)}
                                outline
                                color='danger'
                                className='border-0'
                            >
                                <i className='ri-delete-bin-line font-size-24' />
                            </Button>
                        )}
                    </div>
                )
            }
        }
    ]
}

export const riskIdGenerator = ({ risk }) => {
    const supplierId = risk?.organization?.secondaryId || risk?.supplierId

    return `${risk?.organization?.isOwnCompany ? 'E' : 'TP'}${supplierId}/R${risk?.secondaryId}`
}

export const riskDetailPageHeaderText = ({ risk }) => {
    const supplierId = risk?.organization?.secondaryId || risk?.supplierId

    return `${risk?.organization?.isOwnCompany ? 'E' : 'TP'}${supplierId}/R${risk?.secondary_id}`
}

export const taskDetailPageHeaderText = ({ task }) => {
    return `${task?.organization?.isOwnCompany ? 'E' : 'TP'}${task?.organization?.secondaryId || task?.supplier}/R${
        task?.relatedRisk?.secondary_id
    }/T${task?.secondary_id}`
}

export const taskIdGenerator = ({ task }) => {
    return `${task?.organization?.isOwnCompany ? 'E' : 'TP'}${task?.organization?.secondaryId || task?.supplier}/R${
        task?.relatedRisk?.secondary_id
    }/T${task?.secondary_id}`
}