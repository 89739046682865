import axios from 'axios'

export default class TaskManagementService {
    static instance

    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    async fetchMitigationTasks(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_management/mitigation_tasks`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchTaskDetails(taskId, params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/task/${taskId}/show`,
            {
                params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || {}
    }

    async fetchOwners(payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/analystAndAdminAnalystAndTaskOwners.list`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async fetchCategories(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/mitigation-task/categories`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchManagers(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/manager.list`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskCategory(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/change_category`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskStatus(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/change_status`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskDescription(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/update`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskOwner(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/assign_analyst`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskPriority(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/change_priority`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateTaskDeadline(taskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/task/${taskId}/change_deadline`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }
}
