import { withRouter, useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useState, memo, useEffect } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import OverviewHeader from "../../../../components/overview-header";

import { Col, Row } from "reactstrap";

import SupplierReportsList from "./components/list-reports";

import SupplierReportDetails from "./components/report-details";

import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";

const Reports = ({
    t,
    supplierId,
    className
}) => {
    const history = useHistory();
    const location = useLocation();

    const [selectedReportId, setSelectedReportId] = useState(null);

    const {
        data: supplierInfos,
        isFetching: fetchingSupplierInfos,
        isLoading: loadingSupplierInfos
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		},
	});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if(searchParams.has('reportId')){
            setSelectedReportId(searchParams.get('reportId')) 
        }
        else{
            setSelectedReportId(null) 
        }
    }, [ location.search ]);

    const isLoading = loadingSupplierInfos || fetchingSupplierInfos;

    return (
        <Row className="p-4">
            <Col sm="12" className="mb-4">
                <OverviewHeader supplierId={ supplierId } />
            </Col>

            <Col sm="12">
                {isLoading ? (
                    <p className="text-center">{t('Please wait')}...</p>
                ) : (
                    <>
                        {selectedReportId ? (
                            <SupplierReportDetails 
                                supplierInfos={ supplierInfos } 
                                reportId={ selectedReportId }
                            />
                        ) : (
                            <SupplierReportsList 
                                onReportSelectedToView={(report) => {
                                    history.push({
                                        search : `?tab=reports&reportId=${report.report_case.case_id}`
                                    })
                                }} 
                                supplierId={ supplierId } 
                                supplierType={ supplierInfos?.isOwnCompany ? 'own' : 'creditor' }
                                supplierInfos={ supplierInfos }
                            />
                        )}
                    </>
                )}
            </Col>
        </Row>
    );
};

export default withNamespaces()(
    withRouter(memo(Reports))
); 