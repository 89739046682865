import { useMemo, Suspense, useCallback } from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter, useHistory } from 'react-router-dom'
import { useState, useEffect, memo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import { EMPTY_LIST } from 'src/common/constants'
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import DateUtils from 'src/services/utils/DateUtils'
import RiskManagementService from '../../../../api/RiskManagementService'
import { 
    ModulesComponents,
    ModulesActions,
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const AllRisks = ({ t, Modules }) => {
    const {
        tableAllRisksColumnsRenderer
    } = ModulesActions[Modules.active] || {}
    const routes = ModulesApiRoutes[Modules.active]
    const history = useHistory()
    const ModuleComponents = ModulesComponents[Modules.active] || null

    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    });

    const [tableResultTotalCount, setTableResultTotalCount] = useState(0)

    const [allRisk, setAllRisk] = useState([])

    const [selectedRiskToDelete, setSelectedRiskToDelete] = useState(null)

    const dateUtils = new DateUtils()

    const NoDataIndication = () =>
        handleFetchRiskManagmentQuery.isFetched &&
        !handleFetchRiskManagmentQuery.length ? (
            <div className='alert m-0' role='alert'>
                <p
                    style={{
                        textAlign: 'center',
                        marginBottom: 0
                    }}
                >
                    {t(EMPTY_LIST)}
                </p>
            </div>
        ) : (
            <></>
        )

    const riskService = new RiskManagementService(routes.API_BASE_URL)

    const handleFetchRiskManagmentQuery = useQuery({
        queryKey: ['risk-management-fetch-all-risks-query', filters],
        queryFn: async () => {
            return await riskService.fetchAllRisks({
                ...filters
            })
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching risks list.'), {
                type: 'error'
            })
        }
    })

    const handleDeleteSupplierRiskMutation = useMutation({
        mutationFn: async (riskId) => {
            return await riskService.deleteRisk(riskId)
        },
        onSuccess: () => {
            setSelectedRiskToDelete(null)
            handleFetchRiskManagmentQuery.refetch()

            toast(t('Risk deleted successfully.'), {
                type: 'success'
            })
        },
        onError: () => {
            toast(t('Failed to delete risk.'), {
                type: 'error'
            })
        }
    })

    const handleTableChange = (type, data) => {
        switch (type) {
            case 'pagination': {
                const { page, sizePerPage } = data

                setFilters({
                    ...filters,
                    pageIndex: page,
                    pageSize: sizePerPage
                })
                break
            }
            case 'sort': {
                const { sortField, sortOrder } = data

                setFilters({
                    ...filters,
                    sortField,
                    sortOrder
                })
                break
            }
            default:
                return false
        }
    }

    const renderModals = () => {
        if (
            handleFetchRiskManagmentQuery.isFetching ||
            handleFetchRiskManagmentQuery.isLoading
        ) {
            return null
        }

        const deletionIsInProcess =
            handleDeleteSupplierRiskMutation.isFetching ||
            handleDeleteSupplierRiskMutation.isLoading

        return (
            <Modal
                size='lg'
                scrollable
                isOpen={!!selectedRiskToDelete}
                backdrop='static'
            >
                <ModalHeader toggle={() => setSelectedRiskToDelete(null)}>
                    {t('Delete Risk')}
                </ModalHeader>

                <ModalBody>
                    <p>{t('Are you sure?')}</p>

                    <ModalFooter>
                        <Button
                            disabled={deletionIsInProcess}
                            color='danger'
                            type='button'
                            className='waves-effect waves-light'
                            onClick={() =>
                                handleDeleteSupplierRiskMutation.mutate(
                                    selectedRiskToDelete.id
                                )
                            }
                        >
                            {deletionIsInProcess && (
                                <Spinner
                                    animation='border'
                                    variant='danger'
                                    size='sm'
                                    className='me-1'
                                />
                            )}
                            {t('Delete')}
                        </Button>

                        <Button
                            disabled={deletionIsInProcess}
                            color='secondary'
                            className='waves-effect waves-light'
                            type='button'
                            onClick={() => {
                                setSelectedRiskToDelete(null)
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        )
    }

    const handleFilterOnChange = useCallback((newFilters) => {
        setFilters({
            ...filters,
            ...newFilters,
            pageIndex: 1
        })
    }, [])

    useEffect(() => {
        if (handleFetchRiskManagmentQuery.data) {
            setAllRisk(handleFetchRiskManagmentQuery.data.risks || [])

            setTableResultTotalCount(
                handleFetchRiskManagmentQuery.data.itemsCount || 0
            )
        }
    }, [handleFetchRiskManagmentQuery.data])

    const tableColumns = useMemo(() => {
        return tableAllRisksColumnsRenderer({
            t,
            history,
            dateUtils,
            onDeleteButtonClicked: (row) => setSelectedRiskToDelete(row)
        })
    }, [ t ])

    return (
        <div className='p-4'>
            {renderModals()}

            {tableColumns && tableColumns.length > 0 && (
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        page: filters.pageIndex,
                        sizePerPage: filters.pageSize,
                        totalSize: tableResultTotalCount,
                        withFirstAndLast: false,
                        alwaysShowAllBtns: true,
                        prePageText: (
                            <span>
                                <i className='ri-arrow-left-s-line' />{' '}
                                {t('Back')}
                            </span>
                        ),
                        nextPageText: (
                            <span>
                                {t('Next')}{' '}
                                <i className='ri-arrow-right-s-line' />
                            </span>
                        ),
                        prePageTitle: t('Pre page'),
                        firstPageTitle: t('Next page'),
                        showTotal: true,
                        hideSizePerPage: false,
                        sizePerPageList: [
                            {
                                text: '25',
                                value: 25
                            },
                            {
                                text: '50',
                                value: 50
                            }
                        ]
                    })}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <Row className='mb-5 mt-3'>
                                <Col sm='12'>
                                    <div className='d-flex gap-4 justify-content-end'>
                                        <Suspense fallback={<div>{t('Loading')}...</div>}>
                                            {
                                                ModuleComponents?.RisksFiltering ? 
                                                <ModuleComponents.RisksFiltering 
                                                    onChange={handleFilterOnChange}
                                                /> : 
                                                <div className='text-danger'>Error!</div>
                                            }
                                        </Suspense>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12'>
                                    <BootstrapTable
                                        remote={{
                                            pagination: true,
                                            filter: false,
                                            sort: true,
                                            cellEdit: false,
                                            search: false
                                        }}
                                        loading={
                                            handleFetchRiskManagmentQuery.isFetching ||
                                            handleFetchRiskManagmentQuery.isLoading
                                        }
                                        overlay={overlayFactory({
                                            spinner: (
                                                <Spinner
                                                    animation='border'
                                                    variant='primary'
                                                    size='md'
                                                />
                                            ),
                                            text: 'Loading...'
                                        })}
                                        onTableChange={handleTableChange}
                                        defaultSorted={[]}
                                        keyField='id'
                                        responsive
                                        bordered={false}
                                        data={allRisk}
                                        striped
                                        columns={tableColumns}
                                        wrapperClasses='table-responsive'
                                        classes='table tpdd-table'
                                        headerWrapperClasses='thead-light'
                                        style={{
                                            overflowX: 'auto'
                                        }}
                                        noDataIndication={() => (
                                            <NoDataIndication />
                                        )}
                                        {...paginationTableProps}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='12' md='6'>
                                    <div className='tpdd-pagination-style-1'>
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />

                                        <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </PaginationProvider>
            )}
        </div>
    )
}

const mapToState = (state) => {
    const { Organization, Modules } = state

    return {
        Organization,
        Modules
    }
}

export default withNamespaces()(
    withRouter(
        connect(mapToState)(memo(AllRisks))
    )
)
