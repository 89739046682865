import { memo, useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";

import FileUploader from "./Questions/FileUploader";
import Textbase from "./Questions/Textbase";
import Checkbox from "./Questions/Checkbox";
import Radiobox from "./Questions/Radiobox";
import CustomSelect from "src/modules/data-protection/components/questionar/Questions/CustomSelect";
import { connect } from "react-redux";

import "./style.scss";
import QuestionarConfirmForm from "./ConfirmForm";
import QuestionsLoadingPlaceholder from "./Components/QuestionsLoadingPlaceholder";
import { useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/data-protection/apis/CommonService";
import { toast } from "react-toastify";

const Questionar = (props) => {
  const [displayConfirmFilledInputsForm, setDisplayConfirmFilledInputsForm] =
    useState(false);

  const [answersResultToSubmit, setAnswersResultToSubmit] = useState({});

  const [sections, setSections] = useState([]);

  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const [preFilledAnswersModel, setPreFilledAnswersModel] = useState({});

  const [sectionsAnswers, setSectionsAnswers] = useState(null);

  const [legalEntitiesOptions, setLegalEntitiesOptions] = useState([]);

  const [shouldEntitiesUpdated, setShouldEntetiesUpdated] = useState(false);

  const {
    loading,
    questionnaireId,
    questionnaireType,
    questions,
    onValidSubmit,
    user,
    preFilledAnswers,
    resetFormModel,
    isFormSubmiting,
    settings,
    questionsRef,
    onSubmit,
    onQuestionComplete,
    onChildrenChange,
    onExtendedChange,
    t
  } = props;

  let { confirmFilledInputsForm: confirmFilledInputsFormSettings, buttons } =
    settings || {};

  // let formRef;
  const formRef = useRef(null);

  const dropzoneAcceptedFiles = {
    accepted:
      "text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
    description: [
      `${props.t("You are allowed to upload limited file types")}. ${props.t(
        "For more information"
      )}, ${props.t("visit")}`,
      <a href="https://diss-co.tech/faq-diss-co/" target="_blank">{` ${props.t(
        "FAQ"
      )} `}</a>,
      props.t("page"),
    ],
  };

  const makeQuestionsAnswersReadyToSend = (answers) => {
    const result = [];

    questions.forEach((question) => {
      const questionAnswers = answers[question.id];
      let itemResult = {
        question: question.id,
        answer: [],
      };

      if (questionAnswers) {
        switch (question.type) {
          case "textarea":
          case "text":
          case "date":
            itemResult.answer = [
              {
                title: questionAnswers,
              },
            ];
            break;

          case "checkbox":
          case "category":
            questionAnswers.forEach((eachCheckedAnswer) => {
              const elRef = document.getElementById(
                `${questionnaireId}-question-${question.id}-checkbox-${eachCheckedAnswer}`
              );

              itemResult.answer.push({
                title: elRef.attributes["label"].value,
                value: eachCheckedAnswer,
              });
            });
            break;

          case "radiobox":
            const elRef = document.getElementById(
              `${questionnaireId}-question-${question.id}-option-${questionAnswers}`
            );

            if (elRef) {
              const radioType = elRef.attributes["datatype"].value;

              let answerValue = "";

              if (radioType === "date" || radioType === "text" || radioType === "textarea") {
                const relInputRefId = elRef.attributes["datarelinputref"].value;
                const relInputRef = document.getElementById(relInputRefId);
                if (relInputRef) {
                  answerValue = relInputRef?.value;
                  itemResult.answer = [
                    {
                      title: answerValue,
                      value: questionAnswers,
                    },
                  ];
                }
              } else {
                const resultAns = [
                  {
                    title: elRef.attributes["datatitle"].value,
                    value: questionAnswers,
                  },
                ];

                const questionOption = (question.options || []).find((opt) => {
                  return (
                    Number.parseInt(opt.id) === Number.parseInt(questionAnswers)
                  );
                });

                if (questionOption && answers.sub_questions) {
                  const subQuestionsAnswers =
                    answers.sub_questions[question.id] || [];

                  if (
                    questionOption.children &&
                    questionOption.children.length > 0 &&
                    subQuestionsAnswers[questionOption.id]
                  ) {
                    const children = [];
                    const childrenAnswers =
                      subQuestionsAnswers[questionOption.id];

                    questionOption.children.forEach((optionChild) => {
                      const childAnswers = childrenAnswers[optionChild.id];
                      if (childAnswers) {
                        if (optionChild.type === "file_upload") {
                          children.push({
                            id: optionChild.id,
                            title: childAnswers[0]?.id,
                            type: "file_upload",
                            name: childAnswers[0]?.name,
                          });
                        } else {
                          children.push({
                            id: optionChild.id,
                            title: childAnswers[0],
                            type: optionChild.type,
                          });
                        }
                      }
                    });

                    resultAns[0].children = children;
                  }
                }

                itemResult.answer = resultAns;
              }
            }
            break;
          case "file_upload":
            const uploadedFiles =
              questionAnswers && Array.isArray(questionAnswers)
                ? questionAnswers
                : [];

            itemResult.answer = uploadedFiles.map((uploadedFile) => {
              return {
                title: uploadedFile.id,
                name: uploadedFile.name,
              };
            });
            break;
          case "country_list":
          case "select":
            itemResult.answer = [
              {
                title: questionAnswers.label,
                value: questionAnswers.value,
              },
            ];
            break;
          case "country_multi_select":
          case "legal_entity":
          case "multi_select":
            itemResult.answer = questionAnswers.map((option) => {
              return {
                title: option?.baseLabel || option?.label,
                value: option.value
              }
            });
            break;
        }
      }


      result.push(itemResult);
    });
    return result;
  };

  const findQuestionPreFilledAnswers = (question) => {
    const preFilledQuestionData =
      preFilledAnswers && Array.isArray(preFilledAnswers)
        ? preFilledAnswers.filter((questionData) => {
            return (questionData.id === question.id) || (questionData.title === question.title);
          })[0]
        : null;
          
    if (preFilledQuestionData && preFilledQuestionData.answers) {
      if (!question?.isReactSubQuestion) {
        switch (question.type) {
          case "textarea":
          case "text":
          case "date":
            return (
              preFilledQuestionData.answers[0]?.title ||
              question?.system_data ||
              ""
            );
          case "checkbox":
          case "category":
          case "file_upload":
          case "country_list":
          case "select":
            return preFilledQuestionData.answers;
          case "country_multi_select":
          case "multi_select":
          case "legal_entity":
            const result = preFilledQuestionData.answers
              .map((answer) => {
                const match = question.options.find(
                  (option) => option.title === answer.title
                );
                return match ? { value: match.id, title: match.title } : null;
              })
              .filter((item) => item !== null); // Filter out any nulls if no match was found

            return result;
          case "radiobox":
            return preFilledQuestionData?.answers[0];
        }
      } else {
        const answer = preFilledQuestionData.answers[0];
        if (answer && answer?.children && Array.isArray(answer.children)) {
          let preFilledSubQuestionData = answer.children.find(
            (subQuestionData) => {
              return Number.parseInt(subQuestionData.id) === question.id;
            }
          );

          if (preFilledSubQuestionData?.type === "file_upload") {
            return [
              {
                title: preFilledSubQuestionData?.title,
                name: preFilledSubQuestionData?.name,
              },
            ];
          }

          return preFilledSubQuestionData?.title;
        }
      }
    }

    return null;
  };


  const findQuestionAIFlag = (question) => {
    const preFilledQuestionData =
      preFilledAnswers && Array.isArray(preFilledAnswers)
        ? preFilledAnswers.filter((questionData) => {
            return questionData.id === question.id;
          })[0]
        : null;
    const result = preFilledQuestionData?.is_ai_filled ? preFilledQuestionData?.is_ai_filled : false;
    return result;
  }

  const groupQuestionsBySection = () => {
    const groupedQuestionsBySection = [];

    const findSection = (sectionName) => {
      return groupedQuestionsBySection.find((section) => {
        return section.name === sectionName;
      });
    };

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      let section = findSection(question.section || "");

      if (!section) {
        section = {
          name: question.section || "",
          items: [],
        };
        groupedQuestionsBySection.push(section);
      }

      section.items.push(question);
    }

    return groupedQuestionsBySection;
  };

  const groupQuestionsBySubSection = (items) => {
    const result = [];

    const findSection = (sectionName) => {
      return result.find((section) => {
        return section.name === sectionName;
      });
    };

    for (let i = 0; i < items.length; i++) {
      const question = items[i];
      let subSection = findSection(question.sub_section || "");

      if (!subSection) {
        subSection = {
          name: question.sub_section || "",
          items: [],
        };
        result.push(subSection);
      }

      subSection.items.push(question);
    }

    return result;
  };

  const renderQuetionByType = (question, index) => {
    const questionNumber = `${index}. `;
    const { t } = props;
    switch (question.type) {
      case "textarea":
      case "text":
      case "date":
        return (
          <Textbase
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            type={question.type}
            isMandatory={question.is_mandatory}
            // aiSuggestion={question?.suggestion}
            handleChange={() => {
              onQuestionComplete && onQuestionComplete(question.id);
            }}
            helpText={question?.info}
            defaultValue={findQuestionPreFilledAnswers(question)}
            isAIFilled={findQuestionAIFlag(question)}
          />
        );
      case "file_upload":
        return (
          <FileUploader
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            acceptedFiles={dropzoneAcceptedFiles}
            userToken={user.token}
            handleChange={() => onQuestionComplete && onQuestionComplete(question.id)}
            defaultValue={findQuestionPreFilledAnswers(question)}
            helpText={question?.info}
            isAIFilled={findQuestionAIFlag(question)}
          />
        );
      case "checkbox":
      case "category":
        return (
          <Checkbox
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            isMandatory={question.is_mandatory}
            options={question.options}
            onChange={() => onQuestionComplete && onQuestionComplete(question.id)}
            helpText={question?.info}
            isAIFilled={findQuestionAIFlag(question)}
          />
        );
      case "radiobox":
        return (
          <Radiobox
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            isMandatory={question.is_mandatory}
            options={question.options}
            questionRendererFunc={renderQuetionByType}
            onChange={(e, isChildren = false, isExtended = false, value) => {
              isChildren
                ? onChildrenChange(question.id, e, value)
                : isExtended || question.title == "Is data transferred to countries outside the EU?"
                ? onExtendedChange(question.id, e, value)
                : onQuestionComplete && onQuestionComplete(question.id);
            }}
            defaultValue={findQuestionPreFilledAnswers(question)}
            helpText={question?.info}
            isAIFilled={findQuestionAIFlag(question)}
          />
          
        );    
      case "select":
        return (
          <CustomSelect
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            isMandatory={question.is_mandatory}
            options={question.options}
            defaultValue={findQuestionPreFilledAnswers(question)}
            onChange={() => onQuestionComplete && onQuestionComplete(question.id)}
            helpText={question?.info}
            isAIFilled={findQuestionAIFlag(question)}
          />
        );
      case "multi_select":
        
        const newOptions = question.options.map(option => {
          return {
            ...option,
            baseLabel: option?.title
          }
        })
        return (
          <CustomSelect
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            isMandatory={question.is_mandatory}
            options={newOptions}
            defaultValue={findQuestionPreFilledAnswers(question)}
            helpText={question?.info}
            onChange={(e) => {
              onQuestionComplete && onQuestionComplete(question.id);
            }}
            isMulti={true}
            isAIFilled={findQuestionAIFlag(question)}
          />
        );
      case "legal_entity":
        if(legalEntitiesOptions?.length === 0 && !shouldEntitiesUpdated) {
          setShouldEntetiesUpdated(true)
        }
        return (
           <CustomSelect
            id={question.id}
            elRefIdPrefix={questionnaireId}
            title={questionNumber + t(question.title)}
            key={question.id}
            isMandatory={question.is_mandatory}
            options={fetchLegalEntitiesOptions.data}
            defaultValue={findQuestionPreFilledAnswers(question)}
            helpText={question?.info}
            onChange={(e) => {
              onQuestionComplete && onQuestionComplete(question.id);
            }}
            isMulti={true}
            isAIFilled={findQuestionAIFlag(question)}
          /> 
        );

      default:
        return null;
    }
  };

  const renderQuestionsList = (items) => {
    const subSections = groupQuestionsBySubSection(items);

    if (subSections.length === 1) {
      return (
        <Row>
          {subSections[0].items
            .sort((a, b) => a.sort_index - b.sort_index)
            .map((question, index) => {
              return (
                <Col sm="12" md="12" lg="12" className="mb-2" key={index}>
                  {renderQuetionByType(question, index + 1)}
                </Col>
              );
            })}
        </Row>
      );
    } else {
      return subSections.map((section) => {
        return (
          <div className="sub-section">
            <div className="sub-section-title">
              <h4>{props.t(section.name)}</h4>
            </div>
            <div className="sub-section-items">
              <Row>
                {section.items.map((question, index) => {
                  return (
                    <Col sm="12" md="12" lg="12" className="mb-2" key={index}>
                      {renderQuetionByType(question, index + 1)}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      });
    }
  };

  const renderQuestionnaireMultiInSections = () => {
    return (
      <div className={`p-2 ${displayConfirmForm ? "d-none" : ""}`}>
        <Row>
          <Col sm={12} md={2}>
            <Nav pills vertical className="section-nav">
              {sections.map((section, index) => {
                return (
                  <NavItem>
                    <NavLink
                      disabled={index > activeSectionIndex}
                      active={activeSectionIndex === index}
                    >
                      {index < activeSectionIndex && (
                        <i className="fa fa-check text-success"></i>
                      )}
                      {` ${props.t(section.name)}`}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Col>
          <Col sm={12} md={10}>
            <TabContent activeTab={activeSectionIndex}>
              {sections.map((section, index) => {
                return (
                  <TabPane tabId={index}>
                    <AvForm
                      id={questionnaireId}
                      className={`needs-validation questionnaire-form  ${props.className}`}
                      onValidSubmit={(e, values) =>
                        handleMultiSectionSubmit(section.name, values)
                      }
                      model={preFilledAnswersModel}
                      ref={formRef}
                    >
                      {renderQuestionsList(section.items)}
                      <Row>
                        <Col sm="12 d-flex justify-content-between">
                          {activeSectionIndex >= 1 ? (
                            <Button
                              onClick={() =>
                                setActiveSectionIndex(activeSectionIndex - 1)
                              }
                              color="btn btn-outline-secondary"
                              type="button"
                            >
                              {props.t("Back")}
                            </Button>
                          ) : null}

                          <Button
                            color="btn btn-primary pull-right"
                            disabled={isFormSubmiting}
                            type="submit"
                          >
                            {props.t(
                              activeSectionIndex < sections.length - 1
                                ? "Next"
                                : buttons?.submit?.title || "Save"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  };

  const renderQuestionnaireInSingleSection = () => {
    return (
      <AvForm
        id={questionnaireId}
        className={`needs-validation questionnaire-form ${
          displayConfirmForm ? "d-none" : ""
        } ${props.className}`}
        onSubmit={(e, values) => handleSubmit(e, values)}
        onValidSubmit={(e, values) => handleValidSubmit(values.question)}
        model={preFilledAnswersModel}
        // ref={(ref) => (formRef = ref)}
        ref={formRef}
      >
        {renderQuestionsList(questions)}

        {buttons?.submit?.enabled && questions.length > 0 ? (
          <Row>
            <Col sm="12">
              <Button
                color="btn btn-primary"
                type="submit"
                disabled={isFormSubmiting}
              >
                {props.t(buttons?.submit?.title || "Save")}
              </Button>
            </Col>
          </Row>
        ) : null}
      </AvForm>
    );
  };

  const renderQuestionnaireBody = () => {
    if (sections.length > 1) {
      return renderQuestionnaireMultiInSections();
    }

    return renderQuestionnaireInSingleSection();
  };

  const handleMultiSectionSubmit = (sectionName, values) => {
    setSectionsAnswers({
      ...sectionsAnswers,
      [sectionName]: values,
    });
  };

  const handleValidSubmit = (answers) => {
    const butifiedQuestionsAnswers = {
      questionnaireType: questionnaireType,
      questionAnswers: makeQuestionsAnswersReadyToSend(answers),
    };

    if (confirmFilledInputsFormSettings?.enabled) {
      setAnswersResultToSubmit(butifiedQuestionsAnswers);

      setDisplayConfirmFilledInputsForm(true);
    } else {
      if (onValidSubmit) {
        onValidSubmit(butifiedQuestionsAnswers);
      }
    }
  };

  const handleSubmit = (e, values) => {
    if (onSubmit) {
      onSubmit(e, values);
    }
  };

  const makePreFilledAnswersModelReady = () => {
    const result = {};

    questions.forEach((question) => {
      const answer = findQuestionPreFilledAnswers(question);
      switch (question.type) {
        case "checkbox":
        case "category":
          const checkedItems = (
            answer && Array.isArray(answer) ? answer : []
          ).map((ans) => {
            return ans?.value;
          });
          Object.assign(result, {
            [`question[${question.id}]`]: checkedItems,
          });
          break;
        case "radiobox":
          Object.assign(result, {
            [`question[${question.id}]`]: answer?.value,
          });
          break;
      }
    });

    setPreFilledAnswersModel(result);
  };


  const fetchLegalEntitiesOptions = useQuery({
    queryKey: ["data-protection-enteties-list"],
    queryFn: async () => {
      const service = CommonService.getInstance();
      setShouldEntetiesUpdated(false);
      return await service.legalEntities(props?.Organization?.id);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(props.t("An error occurred while fetching legal entities."), {
        type: "error",
      });
    },
    enabled: shouldEntitiesUpdated
  });

  useEffect(() => {
    if (resetFormModel === true) {
      if (sections.length > 1) {
        setActiveSectionIndex(0);
      } else {
        // formRef.reset();
        if (formRef.current) {
          formRef.current.reset();
        }
      }
    }
  }, [resetFormModel]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      makePreFilledAnswersModelReady();

      setSections(groupQuestionsBySection());
    }
  }, [questions]);

  useEffect(() => {
    if (sections && sections.length > 1) {
      setActiveSectionIndex(0);
    }
  }, [sections]);

  useEffect(() => {
    if (sectionsAnswers) {
      const currentSectionName = sections[activeSectionIndex].name;

      const nextSectionToShowIndex =
        sections.findIndex((section) => {
          return section.name === currentSectionName;
        }) + 1;

      if (nextSectionToShowIndex < sections.length) {
        setActiveSectionIndex(nextSectionToShowIndex);
      } else {
        let result = [];
        sections.forEach((section) => {
          const answers = sectionsAnswers[section.name];
          if (answers && answers.question) {
            answers.question.forEach((value, index) => {
              result[index] = value;
            });
          }
        });

        handleValidSubmit(result);
      }
    }   
  }, [sectionsAnswers]);

  useEffect(() => {
    if (questionsRef) questionsRef.current = {};
  }, []);
  
  const displayConfirmForm =
    confirmFilledInputsFormSettings?.enabled && displayConfirmFilledInputsForm;
  
  return (
    <>
      {displayConfirmForm ? (
        <QuestionarConfirmForm
          questions={questions}
          answers={answersResultToSubmit}
          isFormSubmiting={isFormSubmiting}
          onConfirmButtonClicked={() => {
            if (onValidSubmit) {
              onValidSubmit(answersResultToSubmit);
            }
          }}
          onBackButtonClicked={() => {
            setDisplayConfirmFilledInputsForm(false);
          }}
          settings={confirmFilledInputsFormSettings?.buttons}
        />
      ) : null}

      {!loading && sections.length ? (
        <>{renderQuestionnaireBody()}</>
      ) : (
        <QuestionsLoadingPlaceholder />
      )}
    </>
  );
};

const mapStatetoProps = (state) => {

  const { Organization } = state;

  return {
    Organization
  };
};

export default withNamespaces()((connect(mapStatetoProps)(Questionar)));