import { AvField, AvForm } from "availity-reactstrap-validation";
import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Button, Card, CardBody, Col, Label, Row, Spinner } from "reactstrap";
import { roles, routes } from "../../../../data";
import { useMutation } from "@tanstack/react-query";
import { createNewUser } from "../../../../api/apiFunctions";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Papa from "papaparse";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import axios from "axios";
import EntitySelection from "../../../../components/entity-selection";

const AddNewUser = (props) => {
    const { t, token, modules } = props;
    const activeModule = modules?.active;
    const history = useHistory();

    const [items, setItems] = useState([
        {
            salutation: "",
            first_name: "",
            last_name: "",
            email: "",
            position: "",
            role: "",
            entity_id: null,
            institute_id: null,
            division_id: null
        },
    ]);

    const renderSubmitErrorMessage = (val) => {
        let result = "";

        if (val?.email === "validation.email.already_used") {
            result = t("This email has already been used.");
        } else if (val === "insufficient_license_ROLE_ANALYST_ADMIN") {
            result = t("Insufficient licenses for role Analyst Admin.");
        } else if (val === "insufficient_license_ROLE_ANALYST") {
            result = t("Insufficient licenses for role Analyst.");
        } else if (val === "insufficient_license_ROLE_EMPLOYEE") {
            result = t("Insufficient licenses for role Employee.");
        } else if (val === "insufficient_license_ROLE_TASK_OWNER") {
            result = t("Insufficient licenses for role Task Owner.");
        } else {
            result = t(JSON.stringify(val));
        }

        return result;
    };

    const handleSubmitMutation = useMutation({
        mutationFn: async () => {
            const payload = items.map((i) => {
                const item = {
                    gender: i.salutation,
                    first_name: i.first_name,
                    last_name: i.last_name,
                    email: i.email,
                    position: i.position,
                    role: i.role,
                    department: i.division_id,
                };
                return item;
            });
            
            await createNewUser({
                token: token,
                payload,
                activeModule,
            });
        },
        onSuccess: () => {
            toast("User created successfully.", {
                type: "success",
            });
            history.push(routes[activeModule].listOfUsers);
        },
        onError: (err) => {
            if (axios.isAxiosError(err)) {
                const error = err?.response?.data?.message;
                Object.values(error).forEach((val) => {
                    toast(renderSubmitErrorMessage(val), {
                        type: "error",
                    });
                });
            }
        }
    });

    const addUser = () => {
        setItems([
        ...items,
        {
            salutation: "",
            first_name: "",
            last_name: "",
            email: "",
            position: "",
            role: "",
        },
        ]);
    };

    const deleteUser = (index) => {
        if (items.length > 1) {
        setItems(
            items.filter((item, itemIndex) => {
            return index !== itemIndex;
            })
        );
        }
    };

    const fieldValueChanged = (index, key, value) => {
        setItems(
        items.map((item, itemIndex) => {
            if (index === itemIndex) {
            let newValue = item;
            newValue[key] = value;
            return newValue;
            }
            return item;
        })
        );
    };

    const uploadCSV = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
        Papa.parse(files[0], {
            complete: (results, file) => {
            const data = results.data;
            const errors = results.errors;

            if (errors && errors.length > 0) {
                toast(t("Error to parse the csv file"), {
                type: "error",
                });
                return;
            }

            if (data && data.length > 0) {
                const rolesMapping = {
                "Analyst Admin": "ROLE_ANALYST_ADMIN",
                Analyst: "ROLE_ANALYST",
                Employee: "ROLE_EMPLOYEE",
                "Task Owner": "ROLE_TASK_OWNER",
                };

                let firstNameIndex = -1;
                let lastNameIndex = -1;
                let emailIndex = -1;
                let companyPositionIndex = -1;
                let roleIndex = -1;
                let headerIndex = -1;
                let salutationIndex = -1;
                const newRows = [];
                for (let i = 0; i < data.length; i++) {
                const items = data[i];
                if (items && items.length > 0 && headerIndex === -1) {
                    firstNameIndex = items.findIndex(
                    (label) => label.toLowerCase() === "first_name"
                    );
                    lastNameIndex = items.findIndex(
                    (label) => label.toLowerCase() === "last_name"
                    );
                    emailIndex = items.findIndex(
                    (label) => label.toLowerCase() === "email"
                    );
                    companyPositionIndex = items.findIndex(
                    (label) => label.toLowerCase() === "position"
                    );
                    roleIndex = items.findIndex(
                    (label) => label.toLowerCase() === "role"
                    );
                    salutationIndex = items.findIndex(
                        (label) => label.toLowerCase() === "salutation"
                    );

                    if (
                    firstNameIndex !== -1 &&
                    lastNameIndex !== -1 &&
                    emailIndex !== -1 &&
                    companyPositionIndex !== -1 &&
                    roleIndex !== -1 &&
                    salutationIndex !== -1
                    ) {
                    headerIndex = i;
                    }
                } else if (
                    headerIndex > -1 &&
                    items.length === data[headerIndex].length
                ) {
                    const userRole = rolesMapping[items[roleIndex]] || "";
                    if (!userRole) {
                    toast(t(`Invalid role: ${items[roleIndex]}`), {
                        type: "error",
                    });
                    return;
                    }
                    newRows.push({
                    first_name: items[firstNameIndex] || "",
                    last_name: items[lastNameIndex] || "",
                    email: items[emailIndex] || "",
                    position: items[companyPositionIndex] || "",
                    role: userRole,
                    salutation: items[salutationIndex] || "",
                    });
                }
                }

                if (headerIndex === -1) {
                toast(
                    t("Invalid format, please check the template for details"),
                    {
                    type: "error",
                    }
                );
                return;
                }
                if (newRows.length > 0) {
                setItems(newRows);
                }
            }
            },
        });
        }
    };

    return (
        <Card>
            <CardBody>
                {isFeatureEnabledForActiveModule("new-user-upload-csv") && (
                    <Row>
                        <Col sm="12" className="text-end">
                            <Button type="button" color="success" className="mb-2 me-2">
                            <input
                                style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: "0px",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                zIndex: "1",
                                opacity: "0",
                                cursor: "pointer",
                                }}
                                type="file"
                                onChange={uploadCSV}
                            />
                            <i className="fa fa-upload"></i>
                            {` ${t("Upload CSV")}`}
                            </Button>
                        </Col>
                    </Row>
                )}
                <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmitMutation.mutate}
                >
                    <Row>
                        <Col sm="12">
                            <div className="user-management">
                            {items?.map((user, index) => {
                                return (
                                    <Card key={index} className="mb-3 card-borderd">
                                        <CardBody>
                                            <Row className="mb-4">
                                                <Col md="2" sm="12">
                                                    <Label htmlFor={`salutation[${index}]`}>{`${t(
                                                        "Salutation"
                                                    )}:`}</Label>
                                                    <AvField
                                                        type="select"
                                                        name={`salutation[${index}]`}
                                                        className="form-control"
                                                        id={`salutation[${index}]`}
                                                        value={user?.salutation}
                                                        onChange={(e) =>
                                                        fieldValueChanged(
                                                            index,
                                                            "salutation",
                                                            e.target.value
                                                        )
                                                        }
                                                        validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: t("Please select a salutation"),
                                                        },
                                                        }}
                                                    >
                                                        <option value="" disabled>
                                                        {t("Select")}
                                                        </option>
                                                        <option value="Mr.">{t("Mr.")}</option>
                                                        <option value="Mrs.">{t("Mrs.")}</option>
                                                        <option value="Mx.">{t("Mx.")}</option>
                                                    </AvField>
                                                </Col>
                                                <Col md="5" sm="12">
                                                    <Label htmlFor={`first_name[${index}]`}>{`${t(
                                                        "First Name"
                                                    )}:`}</Label>
                                                    <AvField
                                                        name={`first_name[${index}]`}
                                                        placeholder=""
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                        required: { value: true },
                                                        }}
                                                        id={`first-name[${index}]`}
                                                        value={user?.first_name}
                                                        onChange={(e) =>
                                                        fieldValueChanged(
                                                            index,
                                                            "first_name",
                                                            e.target.value
                                                        )
                                                        }
                                                    />
                                                </Col>
                                                <Col md="5" sm="12">
                                                    <Label htmlFor={`last-name[${index}]`}>{`${t(
                                                        "Last Name"
                                                    )}:`}</Label>
                                                    <AvField
                                                        name={`last_name[${index}]`}
                                                        placeholder=""
                                                        type="text"
                                                        className="form-control"
                                                        validate={{
                                                        required: { value: false },
                                                        }}
                                                        id={`last-name[${index}]`}
                                                        value={user?.last_name}
                                                        onChange={(e) =>
                                                        fieldValueChanged(
                                                            index,
                                                            "last_name",
                                                            e.target.value
                                                        )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col md="4" sm="12">
                                                    <Label htmlFor={`email[${index}]`}>{`${t(
                                                        "Email"
                                                    )}:`}</Label>
                                                    <AvField
                                                        name={`email[${index}]`}
                                                        placeholder=""
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                        required: { value: true },
                                                        email: true,
                                                        }}
                                                        value={user?.email}
                                                        id={`email[${index}]`}
                                                        onChange={(e) =>
                                                        fieldValueChanged(
                                                            index,
                                                            "email",
                                                            e.target.value
                                                        )
                                                        }
                                                    />
                                                </Col>
                                                <Col md="4" sm="12">
                                                    <Label htmlFor={`position[${index}]`}>{`${t(
                                                        "Position"
                                                    )}:`}</Label>
                                                    <AvField
                                                        name={`position[${index}]`}
                                                        placeholder=""
                                                        type="text"
                                                        className="form-control"
                                                        id={`position`}
                                                        value={user?.position}
                                                        onChange={(e) =>
                                                        fieldValueChanged(
                                                            index,
                                                            "position",
                                                            e.target.value
                                                        )
                                                        }
                                                    />
                                                </Col>
                                                <Col md="4" sm="12">
                                                    <Label htmlFor={`role-select[${index}]`}>{`${t(
                                                        "Role"
                                                    )}:`}</Label>
                                                    <AvField
                                                        type="select"
                                                        name={`role[${index}]`}
                                                        className="form-control"
                                                        id={`role-select[${index}]`}
                                                        validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: t("Please select a role"),
                                                        },
                                                        }}
                                                        value={user?.role}
                                                        onChange={(e) => {
                                                        fieldValueChanged(
                                                            index,
                                                            "role",
                                                            e.target.value
                                                        );
                                                        }}
                                                    >
                                                        {
                                                        <>
                                                            <option value="" disabled>
                                                            {t("Select Role")}
                                                            </option>
                                                            {Object.entries(roles)
                                                            .filter(([key, value]) =>
                                                                value.modules.includes(activeModule)
                                                            )
                                                            .map(([key, value]) => (
                                                                <option key={key} value={key}>
                                                                {t(value.label)}
                                                                </option>
                                                            ))}
                                                        </>
                                                        }
                                                    </AvField>
                                                </Col>
                                            </Row>

                                            {isFeatureEnabledForActiveModule(
                                                "entity_management"
                                            ) && (
                                                <EntitySelection 
                                                    index={index}
                                                    user={user}
                                                    onChange={({section, e}) => {
                                                        if(section === 'entity'){
                                                            fieldValueChanged(
                                                                index,
                                                                "entity_id",
                                                                e.value
                                                            );
                                                            fieldValueChanged(index, "institute_id", "");
                                                            fieldValueChanged(index, "division_id", "");
                                                        }
                                                        else if(section === 'institute'){
                                                            fieldValueChanged(
                                                                index,
                                                                "institute_id",
                                                                e.value
                                                            );
                                                            fieldValueChanged(index, "division_id", "");
                                                        }
                                                        else if(section === 'division'){
                                                            fieldValueChanged(
                                                                index,
                                                                "division_id",
                                                                e.value
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}

                                            <div className="d-flex justify-content-between">
                                                <Button
                                                    color="danger"
                                                    onClick={() => deleteUser(index)}
                                                >
                                                    <i className="fa fa-trash"></i>{" "}
                                                    {t("Delete Contact")}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Button color="secondary" type="button" onClick={addUser}>
                                {t("Add User")}
                            </Button>
                            <Button color="primary" type="submit" style={{ float: "right" }}>
                                {handleSubmitMutation.isLoading ? (
                                    <Spinner />
                                ) : (
                                    t("Send Invite")
                                )}
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, Modules } = state;
  return {
    token,
    user,
    Organization,
    modules: Modules,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(memo(AddNewUser)))
);
