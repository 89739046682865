import { Fragment, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { errorNotification, successNotification } from '../../../../../store/actions';
import { AUTH_API_DOMAIN_URL } from '../../../../../common/constants';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { actionDefinitions, userRoll } from '../constants';
import axios from 'axios';
import prevIcon from '../../../../../assets/icons/prevIcon-white.png';
// import warningIcon from '../../../../../../src/assets/icons/warningIcon.png';
import './styles.scss';

const HistoryLogDetail = (props) => {
	const { id } = useParams();
	const [logDetails, setLogDetails] = useState(null);
	const [logDetails_isLoading, setLogDetails_isLoading] = useState();
	const getLogDetail = async (token = props.token) => {
		setLogDetails_isLoading(true);

		try {
			setLogDetails([]);

			const response = await axios.get(`${AUTH_API_DOMAIN_URL}log/${id}/show`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.data.data) {
				setLogDetails(response.data.data);
			}

			setLogDetails_isLoading(false);
		} catch (error) {}

		setLogDetails_isLoading(false);
	};

	useEffect(() => {
		getLogDetail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		const currentTitle = document.title;

		document.title = `${props.t('History Log Details')} | SIP`;

		return () => {
			document.title = currentTitle;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.t]);

	const handleSectionAction = {
		risk: {
			upload_files: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Files Name")}</p>
						</Col>
						<Col md={10}>
							{logDetails?.metadata?.files &&
								logDetails?.metadata?.files.map((file, index) => {
									return (
										<p className='description' key={index}>
											{file.name + ' '} /
										</p>
									);
								})}
						</Col>
					</Row>
				</>
			),
			upload_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			download_files: <></>,
			download_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			update_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			delete_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			create_comment: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Comment")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.comment}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Attachments")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>
								{logDetails?.metadata?.attachments &&
									logDetails?.metadata?.attachments.map((file, index) => {
										return (
											<p className='description' key={index}>
												{file.name + ' '} /
											</p>
										);
									})}
							</p>
						</Col>
					</Row>
				</>
			),
			create: (
				<>
					{logDetails?.metadata?.attachments &&
						logDetails?.metadata?.attachments.map((file) => {
							return <p className='description'>{file.origin_name + ' '} /</p>;
						})}
				</>
			),
			create_ai: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Case ID")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.secondary_case_id}</p>
						</Col>
					</Row>
					{/* <Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Risk ID")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.secondary_risk_id}</p>
						</Col>
					</Row> */}
					{
						logDetails?.metadata?.tasks?.length 
						&& logDetails?.metadata?.tasks.map(task => {
							return (
							<>
								<Row className='d-flex'>
									<Col md={2}>
										<p className='title-left'>{props.t("ID")}</p>
									</Col>
									<Col md={10}>
										<p className='description'>{task.secondary_id}</p>
									</Col>
								</Row>
								<Row className='d-flex'>
									<Col md={2}>
										<p className='title-left'>{props.t("Description")}</p>
									</Col>
									<Col md={10}>
										<p className='description'>{task.description}</p>
									</Col>
								</Row>
							</>
						)
						}) 
					}
				</>
			),
			update_description: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Description")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.description}</p>
						</Col>
					</Row>
				</>
			),
			delete: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			assign_analyst: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Analyst Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.analyst_name}</p>
						</Col>
					</Row>
				</>
			),
			update_damage: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Damage")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.damage}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("New Risk")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.new_risk}</p>
						</Col>
					</Row>
				</>
			),
			update_likelihood: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Likelihood")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.likelihood}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("New Risk")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.new_risk}</p>
						</Col>
					</Row>
				</>
			),
			update_category: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Category")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.category}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Sub Category")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.sub_category}</p>
						</Col>
					</Row>
				</>
			),
		},

		task: {
			upload_files: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Files Name")}</p>
						</Col>
						<Col md={10}>
							{logDetails?.metadata?.files &&
								logDetails?.metadata?.files.map((file) => {
									return <p className='description'>{file.name + ' '} /</p>;
								})}
						</Col>
					</Row>
				</>
			),
			upload_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Files Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),
			download_files: <></>,

			download_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),

			update_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),

			delete_file: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),

			create_comment: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Comment")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.comment}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Attachments")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>
								{logDetails?.metadata?.attachments &&
									logDetails?.metadata?.attachments.map((file, index) => {
										return (
											<p className='description' key={index}>
												{file.name + ' '} /
											</p>
										);
									})}
							</p>
						</Col>
					</Row>
				</>
			),

			create: <></>,

			update_description: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Description")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.description}</p>
						</Col>
					</Row>
				</>
			),

			assign_analyst: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Analyst Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.analyst_name}</p>
						</Col>
					</Row>
				</>
			),

			update_status: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Status")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.status}</p>
						</Col>
					</Row>
				</>
			),

			update_priority: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Priority")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.priority}</p>
						</Col>
					</Row>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Status")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.status}</p>
						</Col>
					</Row>
				</>
			),

			update_deadline: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Deadline")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.deadline}</p>
						</Col>
					</Row>
				</>
			),

			update_category: (
				<Row className='d-flex'>
					<Col md={2}>
						<p className='title-left'>{props.t("Deadline")}</p>
					</Col>
					<Col md={10}>
						<p className='description'>{logDetails?.metadata?.deadline}</p>
					</Col>
				</Row>
			),

			delete: <></>,
		},

		case: {
			create: <></>,

			update_analyst: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Analyst Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.analyst_name}</p>
						</Col>
					</Row>
				</>
			),

			update_status: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Status")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.status}</p>
						</Col>
					</Row>
				</>
			),

			mark_as_delete: <></>,

			delete: <></>,

			upload_attachment: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("File Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.file_name}</p>
						</Col>
					</Row>
				</>
			),

			download_user_attachment: <></>,

			download_analyst_attachment: <></>,

			delete_attachment: <></>,

			add_comment: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Comment")}</p>
						</Col>
						<Col md={10}>
							<div className='description' dangerouslySetInnerHTML={{ __html: logDetails?.metadata?.comment }}></div>
						</Col>
					</Row>

					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Files")}</p>
						</Col>
						<Col md={10}>
							{logDetails?.metadata?.files &&
								logDetails?.metadata?.files.map((file, index) => {
									return (
										<p key={index} className='description'>
											{file.name + ' '} /
										</p>
									);
								})}
						</Col>
					</Row>
				</>
			),

			add_internal_comment: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Comment")}</p>
						</Col>
						<Col md={10}>
							<div className='description' dangerouslySetInnerHTML={{ __html: logDetails?.metadata?.comment }}></div>
						</Col>
					</Row>

					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Files")}</p>
						</Col>
						<Col md={10}>
							{logDetails?.metadata?.files &&
								logDetails?.metadata?.files.map((file, index) => {
									return (
										<p key={index} className='description'>
											{file.name + ' '} /
										</p>
									);
								})}
						</Col>
					</Row>
				</>
			),

			// update_analyst_readonly: (
			// 	<>
			// 		<Row className='d-flex'>
			// 			<Col md={2}>
			// 				<p className='title-left'>Added analyst</p>
			// 			</Col>
			// 			<Col md={10}>
			// 				<p className='description'>{logDetails?.metadata?.added_analyst?.full_name}</p>
			// 			</Col>
			// 		</Row>

			// 		<Row className='d-flex'>
			// 			<Col md={2}>
			// 				<p className='title-left'>Removed analyst</p>
			// 			</Col>
			// 			<Col md={10}>
			// 				<p className='description'>{logDetails?.metadata?.removed_analyst?.full_name}</p>
			// 			</Col>
			// 		</Row>
			// 	</>
			// ),

			update_risk: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Risk")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.risk}</p>
						</Col>
					</Row>
				</>
			),

			link_case: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Link case ID")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.link_case_secondary_id}</p>
						</Col>
					</Row>
				</>
			),

			remove_link_case: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Link case ID")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.link_case_secondary_id}</p>
						</Col>
					</Row>
				</>
			),

			add_link_case: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Link case ID")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.link_case_secondary_id}</p>
						</Col>
					</Row>
				</>
			),

            add_third_party: (
                <Row className='d-flex'>
                    <Col md={2}>
                        <p className='title-left'>{props.t("Third Party")}</p>
                    </Col>
                    <Col md={10}>
                        <div className='description'>
                            {logDetails?.metadata?.added_tp}
                        </div>
                    </Col>
                </Row>
            ),
            remove_third_party: (
                <Row className='d-flex'>
                    <Col md={2}>
                        <p className='title-left'>{props.t("Third Party")}</p>
                    </Col>
                    <Col md={10}>
                        <div className='description'>
                            {logDetails?.metadata?.removed_tp}
                        </div>
                    </Col>
                </Row>
            ),
            add_report_legal_entity: (
                <Row className='d-flex'>
                    <Col md={2}>
                        <p className='title-left'>{props.t("Legal Entity")}</p>
                    </Col>
                    <Col md={10}>
                        <div className='description'>
                            {logDetails?.metadata?.added_legal}
                        </div>
                    </Col>
                </Row>
            ),
            remove_report_legal_entity: (
                <Row className='d-flex'>
                    <Col md={2}>
                        <p className='title-left'>{props.t("Legal Entity")}</p>
                    </Col>
                    <Col md={10}>
                        <div className='description'>
                            {logDetails?.metadata?.removed_legal}
                        </div>
                    </Col>
                </Row>
            ),
		},

		admin: {
			change_active_directory_settings: <></>,

			edit_risk_matrix: <></>,

			restore_risk_matrix: <></>,

			edit_user: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Editted User Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.edited_user_name}</p>
						</Col>
					</Row>
				</>
			),

			create_user: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Created User Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>
								{logDetails?.metadata?.created_users &&
									logDetails?.metadata?.created_users.map((user) => {
										return <p className='description'>{user.name + ' '} /</p>;
									})}
							</p>
						</Col>
					</Row>
				</>
			),

			delete_user: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Deleted User Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.deleted_user_name}</p>
						</Col>
					</Row>
				</>
			),

			change_user_status: (
				<>
					<Row className='d-flex'>
						<Col md={2}>
{}							<p className='title-left'>{props.t("Changed User Name")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.changed_user_name}</p>
						</Col>
					</Row>

					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Status")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.status}</p>
						</Col>
					</Row>
				</>
			),

			edit_organization: <></>,

			update_question: <></>,

			remove_question: (
				<>
					{' '}
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Question")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.question}</p>
						</Col>
					</Row>
				</>
			),

			remove_category: (
				<>
					{' '}
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Question")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.question}</p>
						</Col>
					</Row>
				</>
			),
		},

		document: {
			create: (
				<>
					{' '}
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Title")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.title}</p>
						</Col>
					</Row>
				</>
			),
			remove: (
				<>
					{' '}
					<Row className='d-flex'>
						<Col md={2}>
							<p className='title-left'>{props.t("Title")}</p>
						</Col>
						<Col md={10}>
							<p className='description'>{logDetails?.metadata?.title}</p>
						</Col>
					</Row>
				</>
			),
		},
	};

	const convertHtmlToText = (html) => {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	  };

	const metadata = logDetails?.metadata;
	return (
		
		<Fragment>
			<div className='page-content history-logs-details-page'>
				<Link className='back-button' to='/admin/administration/history-logs'>
					<img src={prevIcon} alt='Back Icon' />
					{props.t("Back To HistoryLog")}
				</Link>

				<Container fluid>
					<Row>
						<Col xl='12'>
							<Card>
								{!logDetails_isLoading ? (
									<CardBody>
										<Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'>{props.t("Activities")}</p>
											</Col>
											<Col md={2}>
												<p className='title-right'>{props.t(logDetails?.section)}</p>
											</Col>
										</Row>

										{logDetails?.section === 'task' && (
											<Row className='d-flex'>
												<Col md={2}>
													<p className='title-left'>{props.t("Case ID")}</p>
												</Col>
												<Col md={2}>
													<p className='description'>{logDetails?.metadata?.secondary_case_id}</p>
												</Col>
											</Row>
										)}

										{logDetails?.section === 'risk' && (
											<Row className='d-flex'>
												<Col md={2}>
													<p className='title-left'>{props.t("Case ID")}</p>
												</Col>
												<Col md={2}>
													<p className='description'>{logDetails?.metadata?.secondary_case_id}</p>
												</Col>
											</Row>
										)}

										{logDetails?.section === 'case' && (
											<Row className='d-flex'>
												<Col md={2}>
													<p className='title-left'>{props.t("Case ID")}</p>
												</Col>
												<Col md={2}>
													<p className='description'>{logDetails?.metadata?.secondary_case_id}</p>
												</Col>
											</Row>
										)}

										{logDetails?.section && logDetails?.action && handleSectionAction[logDetails.section][logDetails.action]}

										{/* <Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'>{props.t("User ID")}</p>
											</Col>
											<Col className='description' md={2}>
												<p>{logDetails?.user_id}</p>
											</Col>
										</Row> */}

										<Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'>{props.t("User Roll")}</p>
											</Col>
											<Col className='description' md={2}>
												<p>{props.t(userRoll[logDetails?.user_role])}</p>
											</Col>
										</Row>
										<Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'> {props.t("Date")}</p>
											</Col>
											<Col className='description' md={2}>
												<p>{logDetails?.created_at}</p>
											</Col>
										</Row>
										<Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'>{props.t("Browser/Platform")}</p>
											</Col>
											<Col className='description' md={2}>
												<p>{`${props.t(logDetails?.browser)} / ${props.t(logDetails?.platform)}`}</p>
											</Col>
										</Row>
										<Row className='d-flex'>
											<Col md={2}>
												<p className='title-left'> {props.t("Description")}</p>
											</Col>
											<Col className='description d-flex align-items-center' md={6}>
												{/* <img className='icon-style' src={warningIcon} alt='Warning Icon' /> */}
												{logDetails && (
													<p className='m-0 ml-2'>
														{logDetails?.section && logDetails?.action && props.t(actionDefinitions[logDetails?.section][logDetails?.action])}
													</p>
												)}
											</Col>
										</Row>
										
										<div hidden={!metadata?.secondary_risk_id} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Risk ID")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.secondary_case_id + "-" + metadata?.secondary_risk_id}
														</p>
													)}
												</Col>
										</div>

										<div hidden={!metadata?.secondary_task_id} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Task ID")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.secondary_case_id + "-" + metadata?.secondary_task_id}
														</p>
													)}
												</Col>
										</div>

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.type} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Type")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{props.t(metadata?.type)}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.analyst_id} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Changed Analyst Id")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.analyst_id}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.status} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Status")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.status}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.file_name} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("File Name")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.file_name}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.comment} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Comment")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{convertHtmlToText(metadata?.comment)}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.added_analyst} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Added Analyst")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.added_analyst?.full_name}
														</p>
													)}
												</Col>
											</div>
										{/* </Row> */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.removed_analyst} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Removed Analyst")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.removed_analyst}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.risk} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("New Risk")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.risk}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.link_case_secondary_id} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Linked Case Id")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.link_case_secondary_id}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.files} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Files")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.files && metadata?.files.map((file, index) => {
																return (
																	<p className='description' key={index}>
																		{file.name + ' '} /
																	</p>
																);
															})}
														</p>
													)}			
													</Col>
												</div>
											{/* </Row>  */}

											{/* <Row className='d-flex'> */}
												<div hidden={!metadata?.category} style={{display: "flex"}}>
													<Col md={2}>
														<p className='title-left'>{props.t("Category")}</p>
													</Col>
													<Col className='description d-flex align-items-center' md={6}>
														{logDetails && (
															<p className='m-0 ml-2'>
																{metadata?.category}
															</p>
														)}
													</Col>
												</div>
											{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.attachments} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Attachments")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.attachments}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											{/* <div hidden={!metadata?.description} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Description")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.description}
														</p>
													)}
												</Col>
											</div> */}
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											{/* <div hidden={!metadata?.deadline} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Deadline")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.deadline}
														</p>
													)}
												</Col>
											</div> */}
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.edited_user_id} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Edited User ID")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.edited_user_id}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.question} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Question Text")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.question}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.title} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Title")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.title}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.added_category} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Added Category")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.added_category}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.removed_category} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Removed Category")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.removed_category}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* <Row className='d-flex'> */}
											<div hidden={!metadata?.tasks} style={{display: "flex"}}>
												<Col md={2}>
													<p className='title-left'>{props.t("Migration tasks")}</p>
												</Col>
												<Col className='description d-flex align-items-center' md={6}>
													{logDetails && (
														<p className='m-0 ml-2'>
															{metadata?.tasks}
														</p>
													)}
												</Col>
											</div>
										{/* </Row>  */}

										{/* QUESTIONS */}
										{
											metadata?.changes?.length && metadata?.changes.map(change => {
												if(['question', 'category', 'question_option', 'category_option'].includes(change?.type)
												&&
												['insert', 'update', 'update_options'].includes(change.operation)
											) {
												return (
													<>
														{change.changes.map((c, index) => {
																return index%2 === 0 ? 
																	(
																		<div style={{display: "flex"}}>
																			<Col md={2}>
																				<p className='title-left'>{props.t("Before")}</p>
																			</Col>
																			<Col className='description d-flex align-items-center' md={6}>
																				<p className='m-0 ml-2'>
																					<strong>{props.t('Title')}: </strong>{c.title} <span className='p-5'>|</span> <strong>{props.t('Type')}: </strong>{c.type}
																				</p>
																			</Col>
																		</div>
																	) :
																	(<div style={{display: "flex"}}>
																		<Col md={2}>
																			<p className='title-left'>{props.t("After")}</p>
																		</Col>
																		<Col className='description d-flex align-items-center' md={6}>
																			<p className='m-0 ml-2'>
																				<strong>{props.t('Title')}: </strong>{c.title} <span className='p-5'>|</span> <strong>{props.t('Type')}: </strong>{c.type}
																			</p>
																		</Col>
																	</div>)
															})
														}

														{change?.options?.length && change?.options?.map((c, index) => {
															// console.log("C", c.changes.title)
															return (
																<div style={{display: "flex"}}>
															 			<Col md={2}>
																				<p className='title-left'>{props.t("Option") + " " + index}</p>
																			</Col>
																		<Col className='description d-flex align-items-center' md={6}>
																			<p className='m-0 ml-2'>
																				<strong>{props.t('Title')}: </strong>{c.changes.title} <span className='p-5'>|</span> <strong>{props.t('Type')}: </strong>{c.type}
																			</p>
																		</Col>
																	</div>
																	)
															// return console.log(opt)
															// opt.changes.map(c => {
																
																// return index%2 === 0 ? 
																// 	(
																// 		
																// 	) :
																// 	(<div style={{display: "flex"}}>
																// 		<Col md={2}>
																// 			<p className='title-left'>{props.t("After")}</p>
																// 		</Col>
																// 		<Col className='description d-flex align-items-center' md={6}>
																// 			<p className='m-0 ml-2'>
																// 				<strong>{props.t('Title')}: </strong>{c.title} <span className='p-5'>|</span> <strong>{props.t('Type')}: </strong>{c.type}
																// 			</p>
																// 		</Col>
																// 	</div>)
																// })
															})
															
														}
													</>
												)

												} 
											})
										}
									</CardBody>
								) : (
									<CardBody>
										<div>
											<p>{props.t("Loading")} ...</p>
										</div>
									</CardBody>
								)}
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	);
};

const mapStatetoProps = (state) => {
	const activeModule = localStorage.getItem('module');

	return {
		organization: state.Organization,
		token: state.Login.token,
		Feature: activeModule === 'wb' ? state.Feature : activeModule === 'cm' ? state.CMFeature : null,
	};
};

const mapDispachToProps = (dispach) => {
	return {
		errorNotification: (message) => dispach(errorNotification(message)),
		successNotification: (message) => dispach(successNotification(message)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(HistoryLogDetail)));
