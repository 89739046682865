import axios from "axios";
import { getUrls } from "../data";


export const fetchCounts = async ({ token, activeModule }) => {
  try {
    const moduleUrls = getUrls()[activeModule];
    const response = await axios.get(moduleUrls["fetchUserCounts"], {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data?.data || [];
    
  } catch (error) {
    throw error;
  }
};

export const fetchUsers = async ({ token, payload, activeModule }) => {
  try {
    const moduleUrls = getUrls()[activeModule];
    const response = await axios.post(moduleUrls["fetchUsers"], payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data?.data || [];
  } catch (error) {
    throw error;
  }
};

export const fetchOtherUsers = async ({ token, activeModule }) => {
  try {
    const moduleUrls = getUrls()[activeModule];
    const response = await axios.get(moduleUrls["fetchOtherUsers"], {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data?.data || [];
  } catch (error) {
    throw error;
  }
};

export const createNewUser = async ({ token, payload, activeModule }) => {
  const moduleUrls = getUrls()[activeModule];
  try {
    await axios.post(
      moduleUrls["createNewUser"],
      payload,
      {        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const createNewExistingUser = async ({ token, payload, activeModule }) => {
  const moduleUrls = getUrls()[activeModule];
  try {
    await axios.post(
      moduleUrls["createNewExistingUser"],
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async ({ token, userId, activeModule }) => {
  const moduleUrls = getUrls()[activeModule];
  try {
    await axios.post(
      moduleUrls["deleteUser"],
      { user_id: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const updateUserStatus = async ({
  token,
  userId,
  activeModule,
  payload,
}) => {
  const moduleUrls = getUrls()[activeModule];
  try {
    await axios.post(
      `${moduleUrls["baseUrl"]}/user/${userId}/change_status`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
