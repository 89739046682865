import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class BafaQuestionnaireService {
	static instance;

	static getInstance() {
		if (!BafaQuestionnaireService.instance) {
			BafaQuestionnaireService.instance = new BafaQuestionnaireService();
		}

		return BafaQuestionnaireService.instance;
	}

    async fetchSubsidiaries(organizationId, params = {}) {
        const response = await axios.get(`${API_BASE_URL}/subsidiary/company/${organizationId}/list`,{
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`
            }
        });

        return response.data?.data || []; 
    }

    async addSubsidiaries(payload = {}){
        const response = await axios.post(`${API_BASE_URL}/subsidiary/add`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data?.data;
    }

    async fetchUserReportRequests(params = {}) {
        const response = await axios.get(`${API_BASE_URL}/supplier/report/request/open_list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response.data.data;
    }

}
