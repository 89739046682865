import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap";
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';

const DeleteQuestionnaireModal = ({
    t,
    onSuccess,
    itemToDelete,
    hideModal
}) => {
    const {
        mutate: mutateDeletion,
        isLoading: deletionInProcess
    } = useMutation({
        mutationFn: async () => {
            const service = QuestionnaireDesignerService.getInstance();
            return await service.deleteQuestionnaire(
                itemToDelete.id
            );
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("Questionnaire deleted successfully."), {
                type: "success"
            });

            hideModal();
        },
        onError: () => {
            toast(t("There was an error while deleting the questionnaire."), {
                type: "error"
            });
        }
    });

    return (
        <Modal size="md" isOpen={ itemToDelete }>
            <ModalHeader toggle={ hideModal }>
                {t('Confirm Questionnaire Deletion')}
            </ModalHeader>
                
            <ModalBody>
                <p>
                    {t('Are you sure you want to delete this questionnaire? This action cannot be undone.')}
                </p>
            </ModalBody>

            <ModalFooter>
                <Button 
                    color="danger" 
                    onClick={ hideModal }
                    disabled={deletionInProcess}
                > 
                    { t('Cancel') }
                </Button>

                <Button 
                    type="button" 
                    color="primary"
                    onClick={mutateDeletion}
                    disabled={deletionInProcess}
                >
                    {
                        deletionInProcess && (
                            <Spinner animation="border" variant="primary" size="sm"/>
                        )
                    }
                    { t('Delete') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(DeleteQuestionnaireModal);