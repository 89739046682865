import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class KanbanBoardService {
    static instance;

    static getInstance() {
        if (!KanbanBoardService.instance) {
            KanbanBoardService.instance = new KanbanBoardService();
        }

        return KanbanBoardService.instance;
    }

    async fetchList(params = {}) {
        const response = await axios.get(`${API_BASE_URL}/task/kanban_board`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`
                }
            }
        );

        return response.data?.data || null;
    } 
}
