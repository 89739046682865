import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class QuestionnaireDesignerService {
	static instance;

	static getInstance() {
		if (!QuestionnaireDesignerService.instance) {
			QuestionnaireDesignerService.instance = new QuestionnaireDesignerService();
		}

		return QuestionnaireDesignerService.instance;
	}

    async fetchList(params = {}){
		const response = await axios.get(`${API_BASE_URL}/questionnaire/list`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}

    async fetchActiveList(params = {}){
		const response = await axios.get(`${API_BASE_URL}/questionnaire/dropdown`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`
			}
		});

		return response.data.data || [];
	}

    async createQuestionnaire(payload = {}){
        const response = await axios.post(`${API_BASE_URL}/questionnaire/create`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }

    async cloneQuestionnaire(payload = {}){
        const response = await axios.post(`${API_BASE_URL}/questionnaire/clone`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }

    async fetchQuestionnaireDetail(questionnaireId, params){
		const response = await axios.get(`${API_BASE_URL}/questionnaire/${questionnaireId}/admin/detail`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			}
		});

		return response.data.data || [];
	}

    async updateQuestionnaire(organization, questionnaireId, payload){
        const response = await axios.post(`${API_BASE_URL}/questionnaire/${questionnaireId}/update`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data.data;
    }
    
    async fetchQuestionnaireThirdpartiesList(questionnaireId, params = {}) {
        const response = await axios.get(`${API_BASE_URL}/questionnaire/${questionnaireId}/organizations`, {
			params: params,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`
			}
		});

		return response.data.data || [];
    }

    async withdrawQuestionnaireRequests(questionnaireId, payload = {}) {
        const response = await axios.post(`${API_BASE_URL}/questionnaire/${questionnaireId}/withdraw `, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }

    async deleteQuestionnaire(questionnaireId, params = {}) {
        const response = await axios.delete(`${API_BASE_URL}/questionnaire/${questionnaireId}/delete`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });
  
        return response.data.data;
    }
    
    async deactivateQuestionnaire(questionnaireId, payload = {}) {
        const response = await axios.post(`${API_BASE_URL}/questionnaire/${questionnaireId}/in_active `, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }
    
    async activateQuestionnaire(questionnaireId, payload = {}) {
        const response = await axios.post(`${API_BASE_URL}/questionnaire/${questionnaireId}/active `, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              }
            }
        );
  
        return response.data.data;
    }
}
