import { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import TableFilter from "src/components/TableFilter";
import DateUtils from "src/services/utils/DateUtils";
import {
    createStaticRanges
} from 'react-date-range'
import { startOfYear, endOfYear, subYears, subMonths, startOfMonth, startOfDay } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "src/modules/3rd-party-management/constants";

const Filtering = ({
    t,
    token,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        'createdAt[from]': null,
        'createdAt[to]': null,
        supplierIds: null,
        companyIds: null
    });
    const [ suppliersFilter, setSuppliersFilter ] = useState({
        pageSize: 50,
        pageIndex: 1,
        name: ''
    });
    const [ ownCompaniesFilter, setOwnCompaniesFilter ] = useState({
        pageSize: 50,
        pageIndex: 1,
        name: ''
    });

    const initRef = useRef(false);
    const dateUtils = new DateUtils();

    const {
        isFetching: fetchingSuppliers,
        data: suppliers,
        refetch: refetchSuppliers
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-suppliers-filter-list-query',
            (new URLSearchParams(
                Object.fromEntries(Object.entries(suppliersFilter).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
        queryFn: async () => {
            const response = await axios.get(`${API_BASE_URL}/organization/providers`, {
                params: suppliersFilter,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
    
            return response.data.data;
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching third parties list.'), {
                type: 'error',
            });
        }
    });

    const {
        isFetching: fetchingOwnCompanies,
        data: ownCompanies,
        refetch: refetchOwnCompanies
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-own-companies-filter-list-query',
            (new URLSearchParams(
                Object.fromEntries(Object.entries(ownCompaniesFilter).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
        queryFn: async () => {
            const response = await axios.get(`${API_BASE_URL}/organization/providers`, {
                params: {
                    ...ownCompaniesFilter,
                    ownCompanies: 1
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            return response.data.data;
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching companies list.'), {
                type: 'error',
            });
        }
    });

    const customStaticRanges = useMemo(() => {
        return createStaticRanges([
            {
                label: "Last year",
                range: () => ({
                    startDate: startOfYear(subYears(new Date(), 1)),
                    endDate: endOfYear(subYears(new Date(), 1))
                })
            },
            {
                label: "This year",
                range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: new Date()
                })
            },
            {
                label: "Last three month",
                range: () => ({
                    startDate: subMonths(new Date(), 3),
                    endDate: new Date()
                })
            },
            {
                label: "This month",
                range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: new Date()
                })
            },
            {
                label: "Today",
                range: () => ({
                    startDate: startOfDay(new Date()),
                    endDate: new Date()
                })
            }
        ])
    }, [])

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Filter by date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters['createdAt[from]'],
                    to: tableFilters['createdAt[to]']
                },
                customStaticRanges: customStaticRanges
            },
            {
                type: 'check_list',
                name: 'supplierIds',
                title: 'Supplier',
                icon: <i className="ri-team-line" />,
                isLoading: fetchingSuppliers,
                defaultValue: tableFilters?.supplierIds,
                data: suppliers || {},
                searchInListOption: {
                    enabled: true,
                    remote: true,
                    defaultValue: suppliersFilter?.name,
                    handler: (value) => {
                        setSuppliersFilter((currentState) => {
                            return {...currentState, pageIndex: 1, name: value}
                        })
                    }
                },
                pagination: {
                    enabled: true,
                    totalCountKey: 'itemsCount',
                    pageIndexKey: 'pageIndex',
                    pageSizeKey: 'pageSize',
                    dataKey: 'thirdParties',
                    handler: ({ pageIndex, pageSize }) => {
                        setSuppliersFilter((currentState) => {
                            return {...currentState, pageIndex: pageIndex, pageSize: pageSize}
                        })
                    }
                },
                formatter: (item) => {
                    return item.name
                }
            },
            {
                type: 'check_list',
                name: 'companyIds',
                title: 'Own Company',
                icon: <i className="ri-building-4-line" />,
                isLoading: fetchingOwnCompanies,
                defaultValue: tableFilters?.companyIds,
                data: ownCompanies || {},
                searchInListOption: {
                    enabled: true,
                    remote: true,
                    defaultValue: ownCompaniesFilter?.name,
                    handler: (value) => {
                        setOwnCompaniesFilter((currentState) => {
                            return {...currentState, pageIndex: 1, name: value}
                        })
                    }
                },
                pagination: {
                    enabled: true,
                    totalCountKey: 'itemsCount',
                    pageIndexKey: 'pageIndex',
                    pageSizeKey: 'pageSize',
                    dataKey: 'thirdParties',
                    handler: ({ pageIndex, pageSize }) => {
                        setOwnCompaniesFilter((currentState) => {
                            return {...currentState, pageIndex: pageIndex, pageSize: pageSize}
                        })
                    }
                },
                formatter: (item) => {
                    return item.name
                }
            }
        ]
    }, [
        tableFilters,
        customStaticRanges,

        fetchingSuppliers,
        suppliersFilter,
        suppliers,

        fetchingOwnCompanies,
        ownCompaniesFilter,
        ownCompanies
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}
            
            if ('filter-date' in filters) {
                result['createdAt[from]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD'
                )

                result['createdAt[to]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            result.supplierIds = filters.supplierIds?.length > 0 ? filters.supplierIds : undefined

            result.companyIds = filters.companyIds?.length > 0 ? filters.companyIds : undefined

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                'createdAt[from]': null,
                'createdAt[to]': null,
                supplierIds: null,
                companyIds: null
            })
        }
    }

    useEffect(() => {
        refetchSuppliers();
    }, [ suppliersFilter ]);

    useEffect(() => {
        refetchOwnCompanies();
    }, [ ownCompaniesFilter ]);

    useEffect(() => {
        if(!initRef.current){
            initRef.current = true;
            return;
        }
        
        onChange && onChange(tableFilters)
    }, [ 
        tableFilters,
        onChange
    ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='filter-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login;

    return { token };
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        Filtering
    )
);