import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import ReactApexChart from "react-apexcharts";
import { EMPTY_LIST } from "src/common/constants";
import Spinner from 'react-bootstrap/Spinner';
import paperImage from "src/modules/3rd-party-management/assets/images/paper.svg";
import DateUtils from "src/services/utils/DateUtils";
import SupplierReportsService from "src/modules/3rd-party-management/apis/thirdparty/SupplierReportsService";
import OverviewHeader from "../../components/overview-header";
import MessageBox from "src/modules/3rd-party-management/components/MessageBox";

import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import {
    Container, Col, Row, Card, CardBody
} from "reactstrap";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import { 
    SupplierLatestReportsStatuses 
} from "src/modules/3rd-party-management/constants";
const Reports = ({
    t,
    organization
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        pageIndex   :   1,
        pageSize    :   25
    });

    const dateUtils = new DateUtils();

    const {
        data: reportsList,
        isFetching: reportsAreFetching,
        isFetched: reportsFetched,
        refetch: refetchReportsList
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-thirdparty-myreports', tableFilters.pageIndex, tableFilters.pageSize],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.list(organization.id, tableFilters);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching reports list.'), {
				type: 'error',
			});
		},
	});

    const handleTableChange = (type, {
        page,
        sizePerPage
    }) => {
        switch(type){
            case 'pagination':
                    setTableFilters({
                        ...tableFilters,
                        pageIndex   :   page,
                        pageSize    :   sizePerPage
                    });
                break;
            default:
                return false;
        }
    }

    useEffect(() => {
        refetchReportsList();
    }, [ tableFilters ]);

    const tableColumns = [
        {
            dataField :   "request_date",
            text      :   t("Request Date"),
            sort      :   false,
            key       :   0,
            style     :   {
                width: '120px'
            },
            formatter :  (cellContent, row) => {
                return dateUtils.convertTimestampToOnlyDate(row?.created_at, INTERNATIONAL_DATE_FORMAT)
            }
        },
        {
            dataField :   "questionnaire",
            text      :   t("Report Type"),
            sort      :   false,
            key       :   1,
            style     :   {
                width: '120px'
            },
            formatter :  (cellContent) => {
                return t(cellContent?.title)
            }
        },
        {
            dataField :   "financial_end_year",
            text      :   t('Financial Year'),
            sort      :   false,
            key       :   3,
            style     :   {
                width: '120px'
            },
            formatter : (cellContent) => {
                if(cellContent)
                    return dateUtils.convertTimestampToOnlyDate(cellContent, INTERNATIONAL_DATE_FORMAT)
                return ''
            }
        },
        {
            dataField :   "deadline_date",
            text      :   t("Deadline"),
            sort      :   false,
            key       :   4,
            style     :   {
                width: '120px'
            },
            formatter :  (cellContent, row) => {
                return dateUtils.convertTimestampToOnlyDate(row?.deadline_date, INTERNATIONAL_DATE_FORMAT)
            }
        },
        {
            dataField :   "contact_person",
            text      :   t("Contact Person"),
            sort      :   false,
            key       :   5,
            style     :   {
                width: '160px'
            },
            formatter : (cellContent, row) => {
                return row.responsible_person?.full_name;
            }
        },
        {
            dataField :   "report_case",
            text      :   t("Report ID"),
            sort      :   false,
            key       :   6,
            style     :   {
                width: '120px'
            },
            formatter : (cellContent, row) => {
                return row?.report_case?.caseIdV2;
            }
        },
        {
            dataField :   "submit_date",
            text      :   t("Submission Date"),
            sort      :   false,
            key       :   7,
            style     :   {
                width: '160px'
            },
            formatter :  (cellContent, row) => {
                if(!row.report_case?.is_completed){
                    return null;
                }

                return (
                    <div>
                        <span>
                            { dateUtils.convertTimestampToOnlyDate(row.report_case.submission_date, INTERNATIONAL_DATE_FORMAT) }
                        </span><br/>
                        <span className="text-muted">
                            { t('Updated on') }: { dateUtils.convertTimestampToOnlyDate(row.report_case.updated_at || row.report_case.submission_date, INTERNATIONAL_DATE_FORMAT) }
                        </span>
                    </div>
                )
            }
        },
        {
            dataField :   "status",
            text      :   t('Status'),
            sort      :   false,
            key       :   9,
            style     :   {
                width: '80px'
            },
            formatter : (cellContent) => {
                const statusOptions = SupplierLatestReportsStatuses[cellContent];
                
                return (
                    <span className='badge text-dark' style={{backgroundColor: statusOptions?.color}}>
                        { t(statusOptions?.title) }
                    </span>
                );
            }
        },
        {
            dataField :   "actions",
            text      :   t("Action"),
            sort      :   false,
            key       :   10,
            style     :   {
                width: '160px'
            },
            formatter : (cellContent, row) => {
                return (
                    <div className="actions">
                        {row.report_case?.is_completed ? (
                            <Link 
                                className="btn btn-outline-primary border-0" 
                                to={`/third-party/3rd-party/reports/${row.report_case.case_id}`}
                            >
                                <i className="ri-eye-line"></i>
                            </Link>
                        ) : (
                            <>
                                {((reportsList?.openRequests || []).findIndex((openRequest) => {
                                    return openRequest.id === row.id
                                }) > -1) && (
                                    <Link 
                                        className="btn btn-outline-primary border-0" 
                                        title={t('Complete the questionnaire')}
                                        to="/third-party/3rd-party/questioner"
                                    >
                                        <i className="ri-survey-line"></i>
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        }
    ];

    const renderReportsStatistics = () => {
        const reportsDetails = reportsList?.reportsDetails;
        const totalRequestsCount = parseInt(reportsDetails?.totalReportRequest || 0);
        const pendingRequestsCount = parseInt(reportsDetails?.totalPendingRequests || 0);
        const completedRequestsCount = parseInt(reportsDetails?.totalReports || 0);


        const pendingRequestsPercent = pendingRequestsCount && (pendingRequestsCount / totalRequestsCount * 100).toFixed(1);
        const completedRequestsPercent = completedRequestsCount && (completedRequestsCount / totalRequestsCount * 100).toFixed(1);

        return (
            <Row>
                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Open requests')}
                                        </p>
                                        <h4 className="mb-0">{ pendingRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4">
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                sparkline: {
                                                    enabled: true
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            colors: ['#FF4949'],
                                            stroke: {
                                                lineCap: 'round'
                                            },
                                            plotOptions: {
                                                radialBar: {
                                                    hollow: {
                                                        margin: 0,
                                                        size: '70%'
                                                    },
                                                    track: {
                                                        margin: 0,
                                                    },
                                                    dataLabels: {
                                                        show: true,
                                                        name : {
                                                            show : false
                                                        },
                                                        value : {
                                                            show : true,
                                                            offsetY: 6
                                                        }
                                                    }
                                                }
                                            }
                                        }} 
                                        series={[pendingRequestsPercent]} 
                                        type="radialBar" 
                                        height="60" 
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Reports submitted')}
                                        </p>
                                        <h4 className="mb-0">{ completedRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4">
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                sparkline: {
                                                    enabled: true
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            colors: ['#FF4949'],
                                            stroke: {
                                                lineCap: 'round'
                                            },
                                            plotOptions: {
                                                radialBar: {
                                                    hollow: {
                                                        margin: 0,
                                                        size: '70%'
                                                    },
                                                    track: {
                                                        margin: 0,
                                                    },
                                                    dataLabels: {
                                                        show: true,
                                                        name : {
                                                            show : false
                                                        },
                                                        value : {
                                                            show : true,
                                                            offsetY: 6
                                                        }
                                                    }
                                                }
                                            }
                                        }} 
                                        series={[completedRequestsPercent]} 
                                        type="radialBar" 
                                        height="60" 
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="4">
                    <Card className="report-statistics-card">
                        <CardBody>
                            <Row>
                                <Col sm="6" md="8">
                                    <div className="overflow-hidden">
                                        <p className="text-truncate">
                                            {t('Total number of report')}
                                        </p>
                                        <h4 className="mb-0">{ totalRequestsCount }</h4>
                                    </div>
                                </Col>

                                <Col sm="6" md="4" className="d-flex justify-content-end">
                                    <img style={{
                                        maxWidth: '32px'
                                    }} src={paperImage} alt="" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    const NoDataIndication = () => {
        if(reportsFetched && !reportsList?.requests?.length){
            return (
                <div className="alert m-0" role="alert">
                    <p style={{
                            textAlign: "center",
                            marginBottom: 0
                        }}>
                        {t(EMPTY_LIST)}
                    </p>
                </div>
            )
        }
        return <></>
    }

    return (
        <div className='page-content'>
			<Container fluid>
                <Row>
                    <Col sm="12" className="mb-4">
                        <OverviewHeader className='tp-view'/>
                    </Col>

                    <Col sm="12" className="mb-4">
                        { renderReportsStatistics() }  
                    </Col>

                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <PaginationProvider
                                    pagination={
                                        paginationFactory({
                                            custom              :   true,
                                            page                :   tableFilters.pageIndex,
                                            sizePerPage         :   tableFilters.pageSize,
                                            totalSize           :   reportsList?.itemsCount || 0,
                                            withFirstAndLast    :   false,
                                            alwaysShowAllBtns   :   true,
                                            prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {t('Back')}</span>,
                                            nextPageText        :   <span>{t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                                            prePageTitle        :   t('Pre page'),
                                            firstPageTitle      :   t('Next page'),
                                            showTotal           :   false,
                                            paginationTotalRenderer :   (from, to, size) => {
                                                return (
                                                    <span className="react-bootstrap-table-pagination-total">
                                                        {`${t('Total Items')} ${size}`}
                                                    </span>
                                                )
                                            },
                                            hideSizePerPage     :   false,
                                            sizePerPageList     :   [
                                                {
                                                    text: '25', 
                                                    value: 25
                                                }, 
                                                {
                                                    text: '50', 
                                                    value: 50
                                                }
                                            ]
                                        })
                                    }>
                                    {
                                        ({
                                            paginationProps,
                                            paginationTableProps
                                        }) => (
                                            <React.Fragment>
                                                <Row>
                                                    <Col sm="12">
                                                        <BootstrapTable
                                                            remote={{
                                                                pagination: true,
                                                                filter: false, 
                                                                sort: true,
                                                                cellEdit: false,
                                                                search: false
                                                            }}
                                                            loading={reportsAreFetching}
                                                            overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : `${t("Loading")}...` }) }
                                                            onTableChange={ handleTableChange }
                                                            defaultSorted={[]}
                                                            keyField={"id"}
                                                            responsive
                                                            bordered={false}
                                                            data={ reportsList?.requests || [] }
                                                            striped={false}
                                                            columns={tableColumns}
                                                            wrapperClasses="table-responsive"
                                                            classes={"table tpdd-table"}
                                                            headerWrapperClasses={"thead-light"}
                                                            style={{
                                                                overflowX: "auto",
                                                            }}
                                                            noDataIndication={ () => <NoDataIndication /> }
                                                            { ...paginationTableProps }
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm="12">
                                                        <div className="tpdd-pagination-style-1">
                                                            <PaginationTotalStandalone
                                                                { ...paginationProps }
                                                            />

                                                            <div>
                                                                <PaginationListStandalone
                                                                    { ...paginationProps }
                                                                />
                                                                
                                                                <SizePerPageDropdownStandalone
                                                                    { ...paginationProps }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                    }
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm="12">
                        <MessageBox
                            settings={{
                                messageListUrl: '/supplier/comment/list',
                                sendMessageUrl: '/supplier/comment/add',
                                attachmentDownloadUrl: '/supplier/attachment/',
                                attachmentUploadUrl: '/supplier/attachment/upload',
                                attachmentDeleteUrl: '/supplier/attachment/',
                                supplierId: organization.id,
                                headerOptions:{
                                    displayFilters : false,
                                    title : 'Communication'
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user : state.Login.user,
      token,
      organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(Reports)))
);