import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import TableFilter from "src/components/TableFilter";

const FilteringComponent = ({
    t,
    statuses,
    owners,
    priorities,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        status: null,
        owner: null,
        priority: null
    });
    const initRef = useRef(false);

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'radio_list',
                name: 'status',
                title: 'Task Status',
                icon: <i className="ri-checkbox-circle-line" />,
                isLoading: false,
                defaultValue: tableFilters.status,
                data: (statuses.options || []).map((item) => ({
                    ...item,
                    id: item.value
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.label
                }
            },
            {
                type: 'radio_list',
                name: 'priority',
                title: 'Priority',
                icon: <i className="ri-arrow-up-down-fill" />,
                isLoading: false,
                defaultValue: tableFilters.priority,
                data: (priorities.options || []).map((item) => ({
                    ...item,
                    id: item.value
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return t(item.baseLabel) 
                }
            },
            {
                type: 'radio_list',
                name: 'owner',
                title: 'Owner',
                icon: <i className="ri-user-line" />,
                isLoading: owners.isLoading,
                defaultValue: tableFilters.owner,
                data: (owners?.options || []).map((item) => ({
                    ...item,
                    id: item.value
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.label
                }
            }
        ]
    }, [
        tableFilters,
        statuses,
        owners,
        priorities,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}
            if ('status' in filters) {
                result.status = filters.status !== 'all' ? filters.status : undefined
            }

            if ('owner' in filters) {
                result.owner = filters.owner !== 'all' ? filters.owner : undefined
            }

            if ('priority' in filters) {
                result.priority = filters.priority !== 'all' ? filters.priority : undefined
            }

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                status: null,
                owner: null,
                priority: null
            })
        }
    }

    useEffect(() => {
        if(!initRef.current){
            initRef.current = true;
            return;
        }

        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='status'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);