import { withNamespaces } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import {
    DateRangePicker,
    defaultInputRanges,
    defaultStaticRanges
} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'

const DateFilter = withNamespaces()(({
    t,
    name,
    defaultValue,
    onFilterChange,
    customStaticRanges,
    ...restProps
}) => {
    const [values, setValues] = useState({
        from: defaultValue?.from ? new Date(defaultValue?.from) : null,
        to: defaultValue?.to ? new Date(defaultValue?.to) : null
    })

    const datePickerTranlations = useMemo(() => {
        return {
            staticRanges: (customStaticRanges || defaultStaticRanges).map((d) => ({...d, label: t(d.label)})),
            inputRanges: defaultInputRanges.map((d) => ({...d, label: t(d.label)})),
            startDatePlaceholder: t('Early'),
            endDatePlaceholder: t('Continuous')
        }
    }, [t])

    useEffect(() => {
        if (values.from && values.to) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: values
                })
        }
    }, [values])

    return (
        <DateRangePicker
            ranges={[
                {
                    startDate: values.from,
                    endDate: values.to,
                    key: name
                }
            ]}
            onChange={(range) => {
                setValues({
                    from: range[name].startDate,
                    to: range[name].endDate
                })
            }}
            rangeColors={['#5664d2']}
            locale={locales[restProps?.lng] || locales.enUS}
            {...datePickerTranlations}
            showDateDisplay={false}
        />
    )
})

export { DateFilter }
