import axios from 'axios';

import {
    API_URL_GET_ADMIN_DASHBAORD
} from '../../constants/index';

export default class DashboardService {
	static instance;

	static getInstance() {
		if (!DashboardService.instance) {
			DashboardService.instance = new DashboardService();
		}

		return DashboardService.instance;
	}

    async fetch(params = {}){
        const response = await axios.get(API_URL_GET_ADMIN_DASHBAORD, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
        return response.data.data
    }
}
