import { withNamespaces } from "react-i18next";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from "reactstrap";
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';
import Spinner from 'react-bootstrap/Spinner';
import { EMPTY_LIST } from "src/common/constants";
import { SupplierLatestReportsStatuses } from "src/modules/3rd-party-management/constants";

const InUsedThirdPartiesModal = ({
    t,
    questionnaireId,
    showModal,
    hideModal
}) => {
    const {
        data: thirdPartiesList,
        isFetching: fetchingThirdpartiesList
    } = useQuery({
        queryKey: [
            "3rd-party-management-fetch-questionnaire-thirdparties-query",
            questionnaireId
        ],
        queryFn: async () => {
            const service = QuestionnaireDesignerService.getInstance();

            return await service.fetchQuestionnaireThirdpartiesList(questionnaireId);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: showModal,
        onError: () => {
            toast(t("Failed to load third parties list."), {
                type: "error"
            });
        }
    });

    const thirdparties = (thirdPartiesList || []).filter((item) => {
        return !item.isOwnCompany
    });

    const ownCompanies = (thirdPartiesList || []).filter((item) => {
        return item.isOwnCompany
    });

    return (
        <Modal isOpen={ showModal } backdrop="static" keyboard={ false } size="lg">   
            <ModalHeader toggle={hideModal}>
                {t("Questionnaire Usage Details")}
            </ModalHeader>

            <ModalBody>
                <p>
                    {t('This questionnaire is currently being used by the following third parties and own companies.')}
                </p>

                <h6>{t('Third Parties')}</h6>
                <div 
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                    className="mb-2"
                >
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>{t('ID')}</th>
                                <th>{t('Company')}</th>
                                <th>{t('Last Report Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchingThirdpartiesList ? (
                                <tr>
                                    <td colSpan='3' className="text-center">
                                        <Spinner 
                                            animation="border" 
                                            size="sm" 
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {thirdparties.length ? (
                                        <>
                                            {thirdparties.map((tp, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {tp.secondaryId}
                                                        </td>
                                                        <td>
                                                            {tp.name}
                                                        </td>
                                                        <td>
                                                            <div className='badge p-2 text-dark' style={{
                                                                backgroundColor: `${SupplierLatestReportsStatuses[tp.status]?.color}`
                                                            }}>
                                                                { t(SupplierLatestReportsStatuses[tp.status]?.title) }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan='3' className="text-center">
                                                {t(EMPTY_LIST)}
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>

                <h6>{t('Own Companies')}</h6>
                <div
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                >
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>{t('ID')}</th>
                                <th>{t('Company')}</th>
                                <th>{t('Last Report Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchingThirdpartiesList ? (
                                <tr>
                                    <td colSpan='3' className="text-center">
                                        <Spinner 
                                            animation="border" 
                                            size="sm" 
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {ownCompanies.length ? (
                                        <>
                                            {ownCompanies.map((tp, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {tp.secondaryId}
                                                        </td>
                                                        <td>
                                                            {tp.name}
                                                        </td>
                                                        <td>
                                                            <div className='badge p-2 text-dark' style={{
                                                                backgroundColor: `${SupplierLatestReportsStatuses[tp.status]?.color}`
                                                            }}>
                                                                { t(SupplierLatestReportsStatuses[tp.status]?.title) }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan='3' className="text-center">
                                                {t(EMPTY_LIST)}
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button onClick={hideModal} color="primary" type="button" outline>
                    { t('Close') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(
    InUsedThirdPartiesModal
);