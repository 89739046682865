import { withRouter, useHistory, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo, useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import DateUtils from "src/services/utils/DateUtils";
import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import { 
    Button, 
    Card, 
    CardBody, 
    CardTitle, 
    Col, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row, 
    Spinner 
} from "reactstrap";

import RiskMatrix from "src/modules/3rd-party-management/components/RisksAndTasks/risks/matrix";

import CreateRiskModal from "src/modules/3rd-party-management/components/RisksAndTasks/risks/new";

import SupplierRiskAssessmentService from "src/modules/3rd-party-management/apis/admin/SupplierRiskAssessmentService";
import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";

import OverviewHeader from "../../../../components/overview-header";

import {
    EMPTY_LIST,
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";
import { AvForm } from "availity-reactstrap-validation";
import { hasUserAccessToEditSupplier } from "src/modules/3rd-party-management/helpers/users";
import { ResidualRisksRates } from "src/modules/3rd-party-management/constants";
import FilteringComponent from "./components/filtering-component";

const RiskAssessment = (props) => {
    const {
        t,
        supplierId
    } = props;

    const history = useHistory();

    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    });

    const [ matrix, setMatrix ] = useState([]);
    const [ consequences, setConsequences ] = useState(null);
    const [ ratings, setRatings ] = useState([]);
    const [ likelihoods, setLikelihoods ] = useState(null);
    const [ currency, setCurrency ] = useState(null);
    const [ damages, setDamages ] = useState(null);
    const [ owners, setOwners ] = useState(null);
    const [ tableColors, setTableColors ] = useState(null);
    const [ categories, setCategories]= useState([]);
    const [ locations, setLocations ] = useState([]);
    const [ deleteRiskId, setDeleteRiskId ] = useState(false);

    const [ createRiskModalStatus, setCreateRiskModalStatus ] = useState(false);
    const [ deleteRiskModalStatus, setDeleteRiskModalStatus ] = useState(false);

    const dateUtils = new DateUtils();

    const handleFetchSupplierBasicInformation = useQuery({
        queryKey: [
            '3rd-party-management-supplier-details-basic-infos',
            supplierId
        ],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching the basic information.'), {
                type: 'error',
            });
        }
    });

    const handleFetchSupplierRisksQuery = useQuery({
		queryKey: [
            '3rd-party-management-fetch-supplier-risks',
            (new URLSearchParams(
                Object.fromEntries(Object.entries(filters).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchRisks(supplierId, filters);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching risks.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksMatrix = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-matrix', supplierId],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchMatrix(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching matrix.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksOwners = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-owners'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchOwners(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksCategories = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-categories'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchCategories(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching categories.'), {
				type: 'error',
			});
		},
	});

    const handleFetchSupplierRisksLocations = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-risks-locations'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchLocations(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching locations.'), {
				type: 'error',
			});
		},
	});

    const handleDeleteSupplierRiskMutation = useMutation({
        mutationFn: async (riskId) => {
            const service = SupplierRiskAssessmentService.getInstance();
    
            return await service.deleteRisk(supplierId, riskId);
        },
        onSuccess: () => {
            setDeleteRiskModalStatus(false);
            handleFetchSupplierRisksQuery.refetch();
            handleFetchSupplierRisksMatrix.refetch();

            toast(t("Risk deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("Failed to delete risk."), {
                type: "error",
            });
        }
    });

    const isLoadingUserData = (
        (handleFetchSupplierBasicInformation.isFetching || handleFetchSupplierBasicInformation.isLoading) ||
        !props.user
    );

    const hasUserAccess = !isLoadingUserData ? 
        hasUserAccessToEditSupplier(props.user, handleFetchSupplierBasicInformation.data?.assignedAnalyst?.id) : 
        false;

    const RisksTableColumns = [
        {
            dataField :   "rating",
            text      :   t("Rating"),
            sort      :   true,
            key       :   1,
            style     :   {
                width : '80px'
            },
            formatter : (_cellContent, {
                result_color,
                is_deleted,
                id
            }) => {
                return (
                    <div>
                        <span className="dt-risk-rating-bullet"
                            style={{
                                backgroundColor: `#${result_color}`,
                                opacity: is_deleted ? 0.15 : 1,
                                cursor: !is_deleted
                                    ? "pointer"
                                    : "default",
                            }}
                            onClick={() => !is_deleted && riskSelectedToShow(id) } 
                        />
                    </div>
                )
            }
        },
        {
            dataField :   "id",
            text      :   t("Risk ID"),
            sort      :   true,
            key       :   2,
            style     :   {
                width : '80px'
            },
            formatter : (_cellContent, {
                is_deleted,
                secondary_id,
                id
            }) => {
                const style = {};
                if(!is_deleted){
                    style.cursor = 'pointer';
                    style.textDecoration = 'underline';
                }

                return (
                    <span
                        style={style}
                        onClick={() => !is_deleted && riskSelectedToShow(id) }
                    >
                        {"R" + secondary_id}
                    </span>
                )
            }
        },
        {
            dataField :   "category",
            text      :   t("Category"),
            sort      :   true,
            key       :   3,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                if(cellContent && cellContent.parent) {
                    return (
                        <div>
                            <span className="dt-list-col-result-description">
                                {t(cellContent.parent.name)}
                            </span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "sub_category",
            text      :   t("Sub Category"),
            sort      :   false,
            key       :   4,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, {
                category
            }) => {
                if(category){
                    return (
                        <div>
                            <span className="dt-list-col-result-description">
                                {t(category.name)}
                            </span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "residualRisk",
            text      :   t("Residual Risk Rate"),
            sort      :   true,
            key       :   5,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, {
                residual_risk,
                is_deleted
            }) => {
                return (
                    <div>
                        <span title={t(ResidualRisksRates[residual_risk]?.title)} className="dt-risk-rating-bullet"
                            style={{
                                backgroundColor: `${ResidualRisksRates[residual_risk]?.color}`,
                                opacity: is_deleted ? 0.15 : 1
                            }}
                        />
                    </div>
                )
            }
        },
        {
            dataField :   "description",
            text      :   t("Description"),
            sort      :   false,
            key       :   6,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, {
                id,
                is_deleted
            }) => {
                return (
                    <div className="dt-list-col-result-description"
                            style={{
                                cursor: !is_deleted
                                ? "pointer"
                                : "default",
                            }}
                            onClick={() => !is_deleted && riskSelectedToShow(id) }
                        >
                        { decodeHTMLEntities(cellContent).replace(/<[^>]+>/g, "") }
                    </div>
                );
            }
        },
        {
            dataField :   "owner",
            text      :   t("Risk owner"),
            sort      :   true,
            key       :   7,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, row) => {
                if(row.owner_object?.name){
                    return (
                        <div>
                            <span className='dt-list-col-bullet'>{row.owner_object.name[0]}</span>{' '}
                            <span className='dt-list-col-bullet-text'>{row.owner_object.name.name}</span>
                        </div>
                    )
                }

                return null
            }
        },
        {
            dataField :   "manager",
            text      :   t("Risk Manager"),
            sort      :   true,
            key       :   8,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                return (
                    <>
                        <span className="dt-list-col-bullet">
                            {cellContent[0]}
                        </span>{" "}
                        <span className="dt-list-col-bullet-text">
                            {cellContent}
                        </span>
                    </>
                )
            }
        },
        {
            dataField :   "tasks",
            text      :   t("Mitigation"),
            sort      :   false,
            key       :   9,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent) => {
                if(cellContent && cellContent.length > 0){
                    return cellContent.map((task, i) => {
                        return (
                            <Link to={`/admin/3rd-party/suppliers/${supplierId}/tasks/${task.id}/details`}>
                                {`T${task.secondary_id}`}
                                <span hidden={i === cellContent.length - 1}>{', '}</span>
                            </Link>
                        );
                    })
                }

                return null
            }
        },
        {
            dataField :   "createdAt",
            text      :   t("Creation Date"),
            sort      :   true,
            key       :   10,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, {
                created_at
            }) => {
                return (
                    <div>
                        {created_at ? dateUtils.convertTimeStampToDate(created_at, INTERNATIONAL_DATE_FORMAT) : null}
                    </div>
                )
            }
        }
    ];

    if(hasUserAccess){
        RisksTableColumns.push({
            dataField :   "actions",
            text      :   t("Action"),
            sort      :   false,
            key       :   11,
            style     :   {
                width : '120px'
            },
            formatter : (_cellContent, row) => {
                if(!row.deleted_at){
                    return (
                        <div className="actions">
                            <button onClick={() => {
                                setDeleteRiskId(row.id);
                                setDeleteRiskModalStatus(true);
                            }} className="btn btn-small text-danger">
                               <i className='ri-delete-bin-line text-danger'></i>
                            </button>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="text-danger">
                            {t('Deleted')}
                        </div>
                    )
                }
            }
        })
    }

    const NoDataIndication = () => (
        (handleFetchSupplierRisksQuery.isFetched && !handleFetchSupplierRisksQuery.data?.risks?.length) ? 
            (
                <div className="alert m-0" role="alert">
                    <p style={{
                            textAlign: "center",
                            marginBottom: 0
                        }}>
                        {props.t(EMPTY_LIST)}
                    </p>
                </div> 
            ) : 
            <></>
    );

    const handleTableChange = (type, options) => {
        const {
            page,
            sizePerPage,

            sortField,
            sortOrder
        } = options;

        switch(type){
            case 'pagination':
                    setFilters({
                        ...filters,
                        pageIndex   :   page || 1,
                        pageSize    :   sizePerPage
                    });
                break;
            case 'sort':
                    setFilters({
                        ...filters,
                        sortField   :   sortField,
                        sortOrder   :   sortOrder.toUpperCase()
                    })
                break;
            default:
                return false;
        }
    }

    useEffect(() => {
        if(handleFetchSupplierRisksMatrix.data){
            setMatrix(handleFetchSupplierRisksMatrix.data.matrix);
            setConsequences(handleFetchSupplierRisksMatrix.data.consequences);
            setRatings(handleFetchSupplierRisksMatrix.data.ratings);
            setLikelihoods(handleFetchSupplierRisksMatrix.data.likelihoods);
            setCurrency(handleFetchSupplierRisksMatrix.data.currency);
            setDamages(
                handleFetchSupplierRisksMatrix.data.consequences.map((item) => {
                    return {
                        value: item.id,
                        label:
                        handleFetchSupplierRisksMatrix.data.currency +
                        " " +
                        Number(item.title).toLocaleString(
                            localStorage.getItem("i18nextLng")
                        ),
                    };
                })
            );

            // calculating matrix colors for caption
            if (handleFetchSupplierRisksMatrix.data.matrix && handleFetchSupplierRisksMatrix.data.ratings) {
                const flattedMatrix = handleFetchSupplierRisksMatrix.data.matrix.flat();
                const items = [];

                for (const item of handleFetchSupplierRisksMatrix.data.ratings) {
                    items.push({
                        label: item.name,
                        color: flattedMatrix.find((i) => i.name === item.name).color,
                    });
                }

                setTableColors(items);
            }
        }
    }, [handleFetchSupplierRisksMatrix.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksOwners.data){
            setOwners(handleFetchSupplierRisksOwners.data.map((item) => {
                return {
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                };
            }));
        }
    }, [handleFetchSupplierRisksOwners.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksCategories.data){
            setCategories(handleFetchSupplierRisksCategories.data.map((item) => {
                return {
                    value: item.id,
                    label: t(item.name),
                    baseLabel: item.name,
                    subCategories: item.sub_categories.map((sub) => {
                        return {
                            value: sub.id,
                            baseLabel: sub.name,
                            label: t(sub.name),
                        };
                    }),
                };
            }));
        }
    }, [handleFetchSupplierRisksCategories.data]);

    useEffect(() => {
        if(handleFetchSupplierRisksLocations.data){
            setLocations(handleFetchSupplierRisksLocations.data.map((item) => {
                return {
                    value: item.id,
                    baseLabel: item.name,
                    label: t(item.name),
                };
            }));
        }
    }, [handleFetchSupplierRisksLocations.data]);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters({
            ...filters,
            ...newFilters,
            pageIndex: 1
        });
    }, []);

    const riskSelectedToShow = (id) => {
        history.push(`/admin/3rd-party/suppliers/${supplierId}/risks/${id}/details`);
    }

    const decodeHTMLEntities = (text) => {
        const parser = new DOMParser();
        const decodedText = parser.parseFromString(
            `<!doctype html><body>${text}`,
            "text/html"
        ).body.textContent;
        return decodedText;
    };

    const renderRiskMatrix = () => {
        return (
            <Card className="mb-4">
                <CardBody>
                    <CardTitle className="mb-4">
                        {t('Risk Matrix')}
                    </CardTitle>

                    <RiskMatrix
                        matrix={matrix}
                        consequences={consequences}
                        currency={currency}
                        likelihoods={likelihoods}
                        risks={null}
                        ratings={ratings}
                        showSupplierId={false}
                        isLoading={ handleFetchSupplierRisksMatrix.isFetching || handleFetchSupplierRisksMatrix.isLoading }
                    />
                </CardBody>
            </Card>
        );
    }

    const renderModals = () => {
        const closeDeleteModal = () => {
            setDeleteRiskId(null);
            setDeleteRiskModalStatus(false);
        }

        return (
            <React.Fragment>
                <CreateRiskModal
                    isOpen          =   { createRiskModalStatus }
                    close           =   { () => setCreateRiskModalStatus(false) }
                    currency        =   { currency }
                    categories      =   { categories }
                    locations       =   { locations }
                    damages         =   { damages }
                    likelihoods     =   {
                        likelihoods ? likelihoods.map((l) => {
                            return {
                                value: l.id,
                                label: l.title,
                            };
                        }) : null
                    }
                    owners          =   { owners }
                    creationRequestPayload = {{
                        supplier    :   supplierId
                    }}
                    onRiskCreationSuccess   =   {() => {
                        handleFetchSupplierRisksQuery.refetch();
                        handleFetchSupplierRisksMatrix.refetch();
                    }}
                />
            
                <Modal size='lg' scrollable={true} isOpen={deleteRiskModalStatus} backdrop='static'>

                    <ModalHeader toggle={closeDeleteModal}>
                        {t('Delete Risk')}
                    </ModalHeader>

                    <ModalBody>
                        <AvForm className='needs-validation'>
                            <Row>
                                <Label>
                                    {t('Are you sure?')}
                                </Label>
                            </Row>

                            <ModalFooter>
                                <Button
                                    color='danger'
                                    className='waves-effect waves-light'
                                    type='submit'
                                    onClick={() => {
                                        handleDeleteSupplierRiskMutation.mutate(deleteRiskId);
                                    }}>
                                        {t('Delete')}
                                </Button>

                                <Button color='secondary'
                                    className='waves-effect waves-light'
                                    type='button'
                                    onClick={closeDeleteModal}>
                                        {t('Cancel')}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    const renderFilters = () => {
        return (
            <Row>
                <Col sm="12">
                    <div className="d-flex justify-content-end mb-3 mt-3">
                        <FilteringComponent 
                            owners={handleFetchSupplierRisksOwners}
                            countries={handleFetchSupplierRisksLocations}
                            onChange={handleFilterChange}
                        />

                        {hasUserAccess && (
                            <Button color="primary"
                                onClick={() => {
                                    setCreateRiskModalStatus(true);
                                }}
                                outline
                                >
                                {t("Create Risk")}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }

    const renderRisksTable = () => {
        return (
            <Row>
                <Col sm='12'>
                    <PaginationProvider
                        pagination={
                            paginationFactory({
                                custom              :   true,
                                page                :   filters.pageIndex,
                                sizePerPage         :   filters.pageSize,
                                totalSize           :   handleFetchSupplierRisksQuery.data?.risks?.itemsCount || 0,
                                withFirstAndLast    :   true,
                                alwaysShowAllBtns   :   true,
                                prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {props.t('Back')}</span>,
                                nextPageText        :   <span>{props.t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                                prePageTitle        :   props.t('Pre page'),
                                firstPageTitle      :   props.t('First page'),
                                lastPageTitle       :   props.t('Last page'),
                                showTotal           :   false,
                                paginationTotalRenderer :   (from, to, size) => {
                                    return (
                                        <span className="react-bootstrap-table-pagination-total">
                                            {`${props.t('Total Items')} ${size}`}
                                        </span>
                                    )
                                },
                                hideSizePerPage     :   false,
                                sizePerPageList     :   [
                                    {
                                        text: '25', 
                                        value: 25
                                    }, 
                                    {
                                        text: '50', 
                                        value: 50
                                    }
                                ]
                            })
                        }>
                        {({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <React.Fragment>
                                <Row>
                                    <Col sm="12">
                                        <BootstrapTable
                                            loading={ handleFetchSupplierRisksQuery.isFetching }
                                            remote={{
                                                pagination: true,
                                                filter: false, 
                                                sort: true,
                                                cellEdit: false,
                                                search: false
                                            }}
                                            overlay={ 
                                                overlayFactory({ 
                                                    spinner: <Spinner animation="border" variant="primary" size="md"/>, 
                                                    text : `${props.t("Loading")}...`
                                                }) 
                                            }
                                            keyField={"id"}
                                            responsive
                                            bordered={ false }
                                            data={ handleFetchSupplierRisksQuery.data?.risks || [] }
                                            striped={true}
                                            columns={ RisksTableColumns }
                                            onTableChange={ handleTableChange }
                                            wrapperClasses="table-responsive"
                                            classes={"table tpdd-table"}
                                            headerWrapperClasses={"thead-light"}
                                            style={{
                                                overflowX: "auto",
                                            }}
                                            noDataIndication={ () => <NoDataIndication /> }
                                            { ...paginationTableProps }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <div className="tpdd-pagination-style-1">
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />
                                            
                                            <div>
                                                <PaginationListStandalone
                                                    { ...paginationProps }
                                                />
                                                
                                                <SizePerPageDropdownStandalone
                                                    { ...paginationProps }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </PaginationProvider>
                </Col>
            </Row>
        );
    }

    return (
        <div className="p-4 d-flex flex-column gap-4">
            { renderModals() }
            <Row>
                <Col sm="12">
                    <OverviewHeader supplierId={supplierId} />
                </Col>

                <Col sm="12">
                    { renderRiskMatrix() }
                </Col>

                <Col sm="12" className="mt-4 mb-4">
                    { renderFilters() }

                    { renderRisksTable() }              
                </Col>
            </Row>  
        </div> 
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user : state.Login.user,
        token,
        organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(RiskAssessment)))
);