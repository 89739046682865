import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { withRouter, useParams } from "react-router-dom";
import React, { useMemo, useState } from 'react';
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import Navigator from "src/modules/3rd-party-management/components/navigator";
import { QuestionnaireDesigner } from '@smartintegrity/questionnaire';
import { UncontrolledAlert, Col, Container, Row, Tooltip } from 'reactstrap';

const TPDDQuestionnaireDesigner = ({
    t,
    lng,
	Organization
}) => {
    const params = useParams();
    const questionnaireID = params.slug;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const {
        isFetching: fetchingQuestionnaireDetial,
        isLoading: loadingQuestionnaireDetail,
        refetch: refetchQuestionnaireDetail,
        data: questionnaireDetail
    } = useQuery({
        queryKey: ["3rd-party-admin-questionaire-details", questionnaireID],
        queryFn: async () => {
          const service = QuestionnaireDesignerService.getInstance();
    
          return await service.fetchQuestionnaireDetail(questionnaireID);
        },
        cacheTime: 0,
        enabled: true,
        refetchOnWindowFocus: false,
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') console.error(error);
    
          toast(t("An error occurred while fetching questionnaire detail."), {
            type: "error"
          });
        }
    });

    const {
        mutate      :   updateQuestionnaire,
        isLoading   :   updatingQuestionnaire   
    } = useMutation({
        mutationFn: async (payload) => {
          const service = QuestionnaireDesignerService.getInstance();
    
          return await service.updateQuestionnaire(Organization.id, questionnaireID, payload);
        },
        onSuccess: () => {
            refetchQuestionnaireDetail();
            
            toast(t("Questionnaire saved successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while saving the questionnaire."), {
                type: "error",
            });
        }
    });

    const handleSaveQuestionnaire = ({
        title, 
        elements
    }) => {
        const result = {
            title: title,
            questions: []
        }

        let questionSort = 0;

        elements.forEach((page) => {
            const {
                image: pageImage,
                title: pageTitle,
                elements: pageElements
            } = page;

            (pageElements || []).forEach((element) => {
                const questions = element.elementType === 'question' ? [element] : (element.elements || [])
                const subSection = element.elementType === 'panel' ? element.title : undefined
            
                questions.forEach((question) => {
                    const {
                        options
                    } = question

                    result.questions.push({
                        id: lng !== 'en' ? question.dbID : undefined,
                        basedOn: question.basedOn ? {
                            question: question.basedOn.question,
                            option: question.basedOn.option
                        } : undefined,
                        frontId: question.id,
                        title: question.title,
                        sortIndex: questionSort++,
                        isMandatory: !!question.isMandatory,
                        type: question.questionType,
                        section: pageTitle,
                        subSection: subSection,
                        icon: pageImage,
                        options: !options?.length ? undefined : options.map((option) => {
                            const  optionsChildren =  (option?.children || []).map((optionChild) => {
                                return {
                                    id: lng !== 'en' ? optionChild.dbID : undefined,
                                    title: optionChild.title,
                                    type: optionChild.questionType,
                                    children: (optionChild?.options || []).map((childOption) => {
                                        return {
                                            id: lng !== 'en' ? childOption.dbID : undefined,
                                            title: childOption.title,
                                            type: optionChild.questionType
                                        }
                                    })
                                    // isMandatory: optionChild.isMandatory
                                }
                            })

                            return {
                                id: lng !== 'en' ? option.dbID : undefined,
                                frontId: option.id,
                                type: question.questionType,
                                title: option.title,
                                children: !optionsChildren.length ? undefined : optionsChildren
                            }
                        })
                    })
                })
            })
        }) 

        updateQuestionnaire(result)
    }

    const questionnaireDefaultValue = useMemo(() => {
        if(!questionnaireDetail?.questions) return [];

        const questionsList = questionnaireDetail.questions;

        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.baseTitle === sectionName;
            });
        }

        for(let i = 0; i < questionsList.length; i++){
            const question = questionsList[i];
            let section = findSection(question.section || '');
            const q = {
                id                  :   question.front_id,    
                basedOn             :   question.based_on,
                dbID                :   question.id,
                elementType :   'question',
                baseTitle   :   question.title,
                title       :   t(question.title),
                questionType:   question.type,
                placeholder :   question.title,
                isMandatory :   !!question.is_mandatory,
                isDeletable :   !!question.is_deletable,
                options     :   (question?.options || []).map((option) => {
                    return {
                        id          :   option.front_id || `el-${Date.now()}-${Math.random()}`,
                        dbID        :   option.id,  
                        baseTitle   :   option.title,
                        title       :   t(option.title),
                        children    :   (option?.children || []).map((child) => {
                            return {
                                id          :   child.id,
                                dbID        :   child.id,
                                baseTitle   :   child.title,
                                title       :   t(child.title),
                                questionType:   child.type,
                                isMandatory :   false,
                                isDeletable :   true,
                                options     :   (child?.children || []).map((childOption) => {
                                    return {
                                        id          :   childOption.id,
                                        dbID        :   childOption.id,
                                        baseTitle   :   childOption.title,
                                        title       :   t(childOption.title)
                                    }
                                })
                            }
                        })   
                    }
                }) 
            }

            if(!section){
                section = {
                    elementType :   'page',
                    baseTitle   :   question.section || '',
                    title       :   t(question.section || ''),
                    image       :   question?.icon,
                    elements    :   []
                }
                groupedQuestionsBySection.push(section);
            }

            if(question.sub_section){
                let subSection = section.elements.find((el) => {
                    return el.baseTitle === question.sub_section
                })

                if(!subSection){
                    subSection = {
                        elementType: 'panel',
                        baseTitle: question.sub_section,
                        title: t(question.sub_section),
                        elements: []
                    }

                    section.elements.push(subSection)
                }

                subSection.elements.push(q)
            }
            else{
                section.elements.push(q);
            }
        }

        if(!groupedQuestionsBySection.length){
            groupedQuestionsBySection.push({
                elementType :   'page',
                baseTitle   :   'Page 1',
                title       :   t('Page 1'),
                elements    :   []
            });
        }

        return groupedQuestionsBySection;
    }, [ questionnaireDetail?.questions ])

    const pageTitle = questionnaireDetail?.questionnaireDetail?.title || '...';

    return (
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Navigator 
                            backButtonOptions={(options) => {
                                return {
                                    ...options,
                                    enable: true
                                }
                            }}
                            breadCrumbs={[
                                {
                                    title   :   'SIP',
                                    link    :   '/admin/3rd-party/dashboard'
                                },
                                {
                                    title   :   'Questionnaire Designer',
                                    link    :   '/admin/3rd-party/administration/questionnaires'
                                },
                                {
                                    title   :   pageTitle,
                                    link    :   '#'
                                }
                            ]} 
                            pageTitle={
                                <>
                                    {`${pageTitle} `}
                                    <i className="fa fa-info-circle ml-2"
                                        aria-hidden="true"
                                        id="tooltipTarget"
                                        style={{ cursor: 'pointer' }}
                                    />

                                    <Tooltip
                                        placement="right"
                                        isOpen={tooltipOpen}
                                        target="tooltipTarget"
                                        toggle={toggleTooltip}
                                    >
                                        {t("The questionnaire designer is only editable in English.")}
                                    </Tooltip>
                                </>
                            }
                        />
                    </Col>
                </Row>

                {lng !== 'en' && (
                    <Row>
                        <Col sm='12'>
                            <UncontrolledAlert dis color="warning" className="d-flex justify-content-start">
                                <div className="d-flex align-items-center me-2">
                                    <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                                </div>
                                <div className="d-flex align-items-start justify-content-center flex-column">
                                    <strong>{t('Language Restriction Notice!')}</strong>
                                    {t('You can only make changes to the questionnaire designer in English. In non-English languages, modifications are restricted to editing titles and sorting elements.')}
                                </div>
                            </UncontrolledAlert>
                        </Col>
                    </Row>
                )}

                <Row className='mb-3'>
                    <Col sm='12'>
                        <QuestionnaireDesigner 
                            savingInprocess={ updatingQuestionnaire }
                            isLoading={ fetchingQuestionnaireDetial || loadingQuestionnaireDetail }
                            onSaveButtonClicked={ handleSaveQuestionnaire } 
                            questionnaireBasicData={ questionnaireDetail?.questionnaireDetail }
                            defaultModel={ questionnaireDefaultValue }
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App } = state;
	
	return {
		token,
		user,
		Organization,
		App
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
	})(
        withRouter(TPDDQuestionnaireDesigner)
    )
);