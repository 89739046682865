import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import AIGetHelpIcon from "src/modules/3rd-party-management/assets/images/microchip/white.png";
import AIBotty from "src/modules/3rd-party-management/assets/images/bottyAi-2.png";

import { Button, Col, Row, Progress, Spinner } from "reactstrap";
import { useQuery, useMutation } from "@tanstack/react-query";
import FindingsService from "src/modules/3rd-party-management/apis/admin/FindingsService";
import { toast } from "react-toastify";
import DOMPurify from 'dompurify';

import ModerateIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Moderate.svg";
import WeakIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Weak.svg";
import StrongIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Strong.svg";


const FindingDetailBody = ({
    finding,
    t,
    setAiBasedRiskAssessmentModalStatus
}) => {
    const [ aiScoreClass, setAiScoreClass ] = useState(null);
    const [ showTranslatedVersion, setShowTranslatedVersion ] = useState(false)

    const handleFetchFindingScoreQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-finding-ai-score-query'],
		queryFn: async () => {
			const service = FindingsService.getInstance();

            return await service.getAIScore({
                promt : `${finding.news_agency || ''}, ${finding.about_url || ''}`
            });
		},
		cacheTime: 0,
        enabled: false,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching ai score.'), {
				type: 'error',
			});
		},
	});

    const {
        isLoading: translateToEnglishInprocess,
        mutate: startTranslateToEnglishMutation,
        data: translateInEnglishContent
    } = useMutation({
        mutationFn: async () => {
            const service = FindingsService.getInstance();

            return await service.translateToEnglish(finding.id);
        },
        onSuccess: () => {
            setShowTranslatedVersion(true)
        },
        onError: () => {
            toast(t("An error occurred while translating the content to english"), {
                type: "error",
            });
        }
    });

    const renderAiScoreClassImage = () => {
        let resultImage;

        const scoreClass = (aiScoreClass || '').toString().toLowerCase();

        if(scoreClass === 'class a'){
            resultImage = WeakIcon;
        }
        else if(scoreClass === 'class b'){
            resultImage = ModerateIcon;
        }
        else{
            resultImage = StrongIcon;
        }

        return (
            <img src={ resultImage } alt="" />
        );
    }

    const renderAiScoreClassBox = () => {
        if(handleFetchFindingScoreQuery.isLoading || handleFetchFindingScoreQuery.isFetching || !aiScoreClass){
            return (
                <Col sm="12">
                    <div className="dt-field dt-skeleton w-100" style={{height: '220px'}}></div>    
                </Col>
            );
        }
        
        return (
            <div className="side-box diss-co-score">
                <div className="scoring">
                    { renderAiScoreClassImage() }
                </div>
            </div>
        );
    }

    useEffect(() => {
        if(finding){
            handleFetchFindingScoreQuery.refetch();
        }
    }, [finding]);

    useEffect(() => {
        if(handleFetchFindingScoreQuery.data && handleFetchFindingScoreQuery.data.out){
            setAiScoreClass(handleFetchFindingScoreQuery.data.out);
        }
    }, [ handleFetchFindingScoreQuery.data ]);

    const displayRelevancyScore = finding && ('relevancyScore' in finding);

    const relevancyScore = parseInt(finding?.relevancyScore);

    const itemImage = finding.is_bafa ? `/images/bafa/${finding.image}.png` : `/images/findings/${finding.image}.jpg`;

    return (
        <Row className="d-flex flex-lg-row-reverse">
            <Col sm="12" md="12" lg="4" xl="3" className="d-none d-lg-block">
                <Row>
                    <Col sm="6" md="6" lg="12">
                        <div className="side-box ai-help-box mb-4">
                            <h5>
                                { t('Risk Assessment Support by AI') }
                            </h5>

                            <div className="image-box">
                                <img src={AIBotty} alt="Risk Assessment Support by AI" />
                            </div>

                            <Button onClick={() => {
                                setAiBasedRiskAssessmentModalStatus(true)
                            }} className="d-flex justify-content-center w-100" color="primary">
                                <img className="me-2" src={AIGetHelpIcon} alt="" />
                                { t('Get Help From AI') }
                            </Button>
                        </div>
                    </Col>
                    
                    {displayRelevancyScore && (
                        <Col sm="6" md="6" lg="12">
                            <div className="side-box relevancy-score mb-4">
                                <h5>
                                    { t('Relevancy Score') }
                                </h5>

                                <div className="score-progress-container mt-2 w-100">
                                    <Progress className={relevancyScore < 40 ? 'danger' : (relevancyScore <= 69 ? 'warning' : 'success') } value={relevancyScore} />
                                    <span className="percent">{relevancyScore}%</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    
                    <Col sm="6" md="6" lg="12">
                        { renderAiScoreClassBox() }
                    </Col>
                </Row>
            </Col>

            <Col sm="12" md="12" lg="8" xl="9">
                <div className="finding-content">
                    <div className="finding-content-title">
                        <h5>
                            {finding.weight && (
                                <i
                                    style={{
                                        color: `#${finding.weight}`
                                    }} 
                                    className="ri-checkbox-blank-circle-fill me-2"
                                ></i>
                            )}
                            {(showTranslatedVersion && translateInEnglishContent?.data?.title) || finding.title}
                        </h5>
                    </div>

                    {('language_code' in finding) && finding.language_code !== 'en' && (
                        <div className="finding-actions-container mt-4">
                            {!showTranslatedVersion ? (
                                <Button disabled={translateToEnglishInprocess} outline color="primary" onClick={() => {
                                    if(!translateInEnglishContent?.data){
                                        startTranslateToEnglishMutation()
                                    }
                                    else{
                                        setShowTranslatedVersion(true)
                                    }
                                }}>
                                    {translateToEnglishInprocess ? (
                                        <>
                                            <Spinner size='sm' className="me-1" />
                                            { t('Translating') }...
                                        </>
                                    ) : (
                                        <>
                                            { t('Translate To English') }
                                        </>
                                    )}
                                </Button>
                            ) : (
                                <Button outline color="primary" onClick={() => {
                                    setShowTranslatedVersion(false)
                                }}>
                                    { t('See original') }
                                </Button>
                            )}
                        </div>
                    )}

                    <div className="finding-content-image mt-3 mb-4">
                        <img src={itemImage} alt="finding" />
                    </div>

                    <div className="d-lg-none mb-4">
                        <Row>
                            <Col md={displayRelevancyScore ? '4' : '6'} lg="12" className="mb-4">
                                <div className="side-box ai-help-box">
                                    <h5>
                                        { t('Risk Assessment Support by AI') }
                                    </h5>

                                    <div className="image-box">
                                        <img src={AIBotty} alt="Risk Assessment Support by AI" />
                                    </div>

                                    <Button onClick={() => {
                                        setAiBasedRiskAssessmentModalStatus(true)
                                    }} className="d-flex justify-content-center w-100" color="primary">
                                        <img className="me-2" src={AIGetHelpIcon} alt="" />
                                        { t('Get Help From AI') }
                                    </Button>
                                </div>
                            </Col>

                            {displayRelevancyScore && (
                                <Col md="4" lg="12" className="mb-4">
                                    <div className="side-box relevancy-score">
                                        <h5>
                                            { t('Relevancy Score') }
                                        </h5>

                                        <div className="score-progress-container mt-2 w-100">
                                            <Progress className={relevancyScore < 40 ? 'danger' : (relevancyScore <= 69 ? 'warning' : 'success') } value={relevancyScore} />
                                            <span className="percent">{relevancyScore}%</span>
                                        </div>
                                    </div>
                                </Col>
                            )}

                            <Col md={displayRelevancyScore ? '4' : '6'} lg="12">
                                { renderAiScoreClassBox() }
                            </Col>
                        </Row>
                    </div>

                    <div className="finding-content-body mb-4" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((showTranslatedVersion && translateInEnglishContent?.data?.body) || finding.body)}} />

                    <div className="finding-source-link mb-4">
                        <a href={finding.source_link} rel="noreferrer" target="_blank">
                            { finding.source_link }
                        </a>                            
                    </div>

                    {/* <div className="finding-actions-container">
                        <Button disabled={ handleToggleFlagStatusMutation.isLoading } color="primary" className="tpdd-btn" onClick={() => handleToggleFlagStatusMutation.mutate({
                            newsId : finding.id
                        })}>
                            {
                                handleToggleFlagStatusMutation.isLoading ? (
                                    <Spinner animation="border" variant="white" size="sm"/>
                                ) : (
                                    <>{ t(finding.flag ? 'Unflag' : 'Flag') }</>
                                )
                            }
                        </Button>
                    </div> */}
                </div>
            </Col>
        </Row>
    )
}

export default withNamespaces()(
    FindingDetailBody
);