import { useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import { convertToHTML } from 'draft-convert'
import { ContentState, EditorState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { TbLoader } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import Select from 'react-select'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from 'reactstrap'

import Navigator from '../../../../components/navigator'
import DateUtils from 'src/services/utils/DateUtils'
import DownloadAttachments from './components/DownloadAttachments'
import Comments from './components/Comments'
import TaskManagementService from '../../../../api/TaskManagementService'

import {
    DESCRIPTION_IS_REQUIRED,
    INTERNATIONAL_DATE_FORMAT,
    INVALID_DEADLINE_DATE,
    CATEGORY_IS_REQUIRED
} from 'src/common/constants'
import { TasksStatuses, TasksPriorities } from '../../../../constants'
import { 
    ModulesActions,
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const TaskDetails = function ({ t, user, Modules }) {
    const {
        taskDetailPageHeaderText
    } = ModulesActions[Modules.active]
    const routes = ModulesApiRoutes[Modules.active]
    const { taskId } = useParams()
    const dateUtils = new DateUtils()
    const isTaskOwnerEmployee = false

    const [breadCrumbs] = useState([
        {
            title: 'Management',
            link: '/admin/risk-management?tab=overview'
        },
        {
            title: 'Mitigation Task',
            link: `/admin/risk-management?tab=mitigation-tasks`
        },
        {
            title: 'Detail',
            link: '#'
        }
    ])

    const [task, setTask] = useState(null)
    const [categories, setCategories] = useState([])
    const [owners, setOwners] = useState([])

    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categoryOtherError] = useState(null)
    const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] =
        useState('')
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [description, setDescription] = useState(EditorState.createEmpty())
    const [descriptionError, setDescriptionError] = useState(null)
    const [selectedOwner, setSelectedOwner] = useState(null)
    const [selectedPriority, setSelectedPriority] = useState(null)
    const [selectedDeadline, setSelectedDeadline] = useState('')

    const priorities = []
    const taskStatuses = []

    for (const priorityKey in TasksPriorities) {
        const priority = TasksPriorities[priorityKey]
        priorities.push({
            value: priorityKey,
            baseLabel: priority.title,
            label: (
                <>
                    <span
                        style={{
                            color: `${priority.color}`
                        }}
                    >
                        {priority.icon}
                    </span>
                    <span>{t(priority.title)}</span>
                </>
            )
        })
    }

    for (const statusKey in TasksStatuses) {
        const status = TasksStatuses[statusKey]
        taskStatuses.push({
            value: statusKey,
            label: t(status.title),
            baseLabel: status.title
        })
    }

    const taskService = new TaskManagementService(routes.API_BASE_URL)

    const { isLoading: categoriesLoading } = useQuery({
        queryKey: ['risk-management-fetch-tasks-categories'],
        queryFn: async () => {
            return await taskService.fetchCategories()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching categories.'), {
                type: 'error'
            })
        },
        onSuccess: (data) => {
            setCategories(
                data.map((item) => {
                    return {
                        value: item.id,
                        baseLabel: item.name,
                        label: t(item.name)
                    }
                })
            )
        }
    })

    useQuery({
        queryKey: ['risk-management-fetch-tasks-owners'],
        queryFn: async () => {
            return await taskService.fetchOwners()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching owners.'), {
                type: 'error'
            })
        },
        onSuccess: (data) => {
            setOwners(
                data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.first_name} ${item.last_name}`,
                        baseLabel: `${item.first_name} ${item.last_name}`
                    }
                })
            )
        }
    })

    const { isLoading: taskDetailsLoading } = useQuery({
        queryKey: ['risk-management-fetch-task-details'],
        queryFn: async () => {
            return await taskService.fetchTaskDetails(taskId)
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('Failed to fetch task.'), {
                type: 'error'
            })
        },
        onSuccess: (data) => {
            setTask(data)

            const blocksFromHTML = data.description
                ? convertFromHTML(data?.description)
                : ''
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )

            setDescription(EditorState.createWithContent(state))

            setSelectedStatus({
                value: data.status,
                label: t(TasksStatuses[data.status]?.title),
                baseLabel: TasksStatuses[data.status]?.title
            })

            setSelectedPriority({
                value: data.priority,
                baseLabel: TasksPriorities[data.priority]?.title,
                label: (
                    <>
                        <span
                            style={{
                                color: `${
                                    TasksPriorities[data.priority]?.color
                                }`
                            }}
                        >
                            {TasksPriorities[data.priority]?.icon}
                        </span>
                        <span>{t(TasksPriorities[data.priority]?.title)}</span>
                    </>
                )
            })

            if (data.ended_at) {
                setSelectedDeadline(
                    dateUtils.convertTimeStampToDate(
                        data.ended_at,
                        'YYYY-MM-DD'
                    )
                )
            }
        }
    })

    const handleUpdateTaskCategoryMutation = useMutation({
        mutationFn: async (newCategory) => {
            return await taskService.updateTaskCategory(taskId, {
                category: newCategory.value
            })
        },
        onError: () => {
            toast(t('Failed to update category.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, newCategory) => {
            setSelectedCategory(newCategory)

            toast(t('Category updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateTaskStatusMutation = useMutation({
        mutationFn: async (newStatus) => {
            return await taskService.updateTaskStatus(taskId, {
                status: newStatus.value
            })
        },
        onError: () => {
            toast(t('Failed to update status.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, newStatus) => {
            setSelectedStatus(newStatus)

            toast(t('Status updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateTaskDescriptionMutation = useMutation({
        mutationFn: async (payload) => {
            return await taskService.updateTaskDescription(taskId, payload)
        },
        onError: () => {
            toast(t('The description has not been updated.'), {
                type: 'error'
            })
        },
        onSuccess: () => {
            toast(t('The description is updated successfully'), {
                type: 'success'
            })
        }
    })

    const handleUpdateTaskOwnerMutation = useMutation({
        mutationFn: async (owner) => {
            return await taskService.updateTaskOwner(taskId, {
                owner: owner.value
            })
        },
        onError: () => {
            toast(t('Failed to update owner.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, owner) => {
            setSelectedOwner(owner)

            toast(t('Owner updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateTaskPriorityMutation = useMutation({
        mutationFn: async (newPriority) => {
            return await taskService.updateTaskPriority(taskId, {
                priority: newPriority.value
            })
        },
        onError: () => {
            toast(t('Failed to update priority.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, newPriority) => {
            setSelectedPriority(newPriority)

            toast(t('Priority updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleUpdateTaskDeadlineMutation = useMutation({
        mutationFn: async (payload) => {
            return await taskService.updateTaskDeadline(taskId, payload)
        },
        onError: () => {
            toast(t('Failed to update deadline.'), {
                type: 'error'
            })
        },
        onSuccess: (_data, newDeadline) => {
            setSelectedDeadline(newDeadline.endedAt)

            toast(t('Deadline updated successfully.'), {
                type: 'success'
            })
        }
    })

    const handleChangeCategory = (category) => {
        handleUpdateTaskCategoryMutation.mutate(category)
    }

    const handleChangeStatus = async (status) => {
        handleUpdateTaskStatusMutation.mutate(status)
    }

    const handleDescriptionSubmit = async () => {
        if (!description.getCurrentContent().hasText()) {
            setDescriptionError(DESCRIPTION_IS_REQUIRED)
            return
        } else {
            setDescriptionError(null)
        }

        handleUpdateTaskDescriptionMutation.mutate({
            description: convertToHTML(description.getCurrentContent()),
            descriptionAttachments: []
        })
    }

    const handleChangeOwner = async (owner) => {
        handleUpdateTaskOwnerMutation.mutate(owner)
    }

    const handleChangePriority = async (priority) => {
        handleUpdateTaskPriorityMutation.mutate(priority)
    }

    const handleChangeDeadline = async (deadline) => {
        let date = new Date()
        date = date.toISOString().split('T')[0]
        if (
            Number(deadline.replaceAll('-', '')) <
            Number(date.replaceAll('-', ''))
        ) {
            toast(t(INVALID_DEADLINE_DATE), {
                type: 'error'
            })

            return false
        }

        handleUpdateTaskDeadlineMutation.mutate({
            endedAt: deadline
        })
    }

    const renderContent = () => {
        return (
            <>
                <Row>
                    <Col sm='12' md='6' lg='3'>
                        <Label className='form-label'>{`${t('Title')} `}</Label>
                        <br />
                        {!taskDetailsLoading && task ? (
                            <Label className='form-label text-secondary '>
                                {task.name}
                            </Label>
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    <Col
                        sm='12'
                        md={
                            selectedCategory &&
                            selectedCategory.baseLabel.toLowerCase() === 'other'
                                ? 3
                                : 4
                        }
                        lg={
                            selectedCategory &&
                            selectedCategory.baseLabel.toLowerCase() === 'other'
                                ? 3
                                : 4
                        }
                    >
                        <Label className='form-label'>{`${t(
                            'Category'
                        )} `}</Label>
                        <br />

                        {!handleUpdateTaskCategoryMutation.isLoading &&
                        !categoriesLoading &&
                        !taskDetailsLoading &&
                        categories &&
                        task ? (
                            <Select
                                name='category'
                                classNamePrefix='select2-selection'
                                options={categories.map((c) => {
                                    return {
                                        value: c.value,
                                        baseLabel: c.baseLabel,
                                        label: t(c.baseLabel)
                                    }
                                })}
                                value={{
                                    ...selectedCategory,
                                    label: t(selectedCategory?.baseLabel)
                                }}
                                onChange={(e) => {
                                    handleChangeCategory(e)
                                }}
                                menuPortalTarget={document.body}
                                isDisabled={
                                    !!(
                                        isTaskOwnerEmployee ||
                                        task.status === 'CLOSED'
                                    )
                                }
                            />
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>

                    {/* subcategory */}
                    <Col
                        sm='12'
                        md='3'
                        lg='3'
                        hidden={
                            !selectedCategory ||
                            selectedCategory.baseLabel.toLowerCase() !== 'other'
                        }
                    >
                        <Label className='form-label text-dark'>{`${t(
                            'Subcategory'
                        )} `}</Label>
                        <InputGroup>
                            <Input
                                name='sub-cat'
                                type='text'
                                className='form-control'
                                value={selectedSubCategoryOtherString}
                                validate={{ required: { value: true } }}
                                onChange={(e) => {
                                    setSelectedSubCategoryOtherString(
                                        e.target.value
                                    )
                                }}
                                disabled={!!isTaskOwnerEmployee}
                            />
                            {!isTaskOwnerEmployee ? (
                                <Button
                                    color='primary'
                                    onClick={(_e) => {
                                        // handleSubmitChangeCategory(
                                        //     null,
                                        //     selectedSubCategoryOtherString
                                        // )
                                    }}
                                >
                                    {t('Save')}
                                </Button>
                            ) : null}
                        </InputGroup>
                        <br />
                        <p className='text-danger'>
                            {categoryOtherError ? t(categoryOtherError) : ''}
                        </p>
                    </Col>

                    {/* status */}
                    <Col
                        sm='12'
                        md={
                            selectedCategory &&
                            selectedCategory.baseLabel.toLowerCase() === 'other'
                                ? 3
                                : 4
                        }
                        lg={
                            selectedCategory &&
                            selectedCategory.baseLabel.toLowerCase() === 'other'
                                ? 3
                                : 4
                        }
                    >
                        <Label className='form-label'>{`${t(
                            'Status'
                        )} `}</Label>
                        <br />
                        {!handleUpdateTaskStatusMutation.isLoading &&
                        !taskDetailsLoading &&
                        taskStatuses &&
                        task ? (
                            <Select
                                required
                                name='status'
                                classNamePrefix='select2-selection'
                                options={
                                    isTaskOwnerEmployee
                                        ? taskStatuses.filter(
                                              (s) => s.taskOwner
                                          )
                                        : taskStatuses
                                }
                                value={{
                                    ...selectedStatus,
                                    label: t(selectedStatus?.baseLabel)
                                }}
                                onChange={(status) => {
                                    handleChangeStatus(status)
                                }}
                                menuPortalTarget={document.body}
                                isDisabled={task?.status === 'CLOSED'}
                            />
                        ) : (
                            <div
                                className='dt-field dt-skeleton dt-select-list'
                                style={{ marginBottom: 16 }}
                            />
                        )}
                    </Col>
                </Row>

                <Row className='mt-5'>
                    {/* description */}
                    <Col sm='12' md='12' lg='12'>
                        <Label className='form-label'>{`${t(
                            'Description'
                        )} `}</Label>
                        {task && task.description ? (
                            isTaskOwnerEmployee ? (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: task.description
                                    }}
                                />
                            ) : (
                                <Fragment>
                                    <br />
                                    <Editor
                                        editorState={description}
                                        toolbarClassName='toolbarClassName'
                                        wrapperClassName='wrapperClassName'
                                        editorClassName='editorClassName'
                                        onEditorStateChange={(e) => {
                                            setDescription(e)
                                        }}
                                        placeholder={t('Add a description...')}
                                        editorStyle={{
                                            height: '10rem',
                                            overflowY: 'scroll',
                                            borderColor: '#c9c9c9',
                                            paddingRight: '2em'
                                        }}
                                        toolbar={{
                                            options: [
                                                'inline',
                                                'blockType',
                                                'fontSize',
                                                'fontFamily'
                                            ],
                                            inline: {
                                                options: [
                                                    'bold',
                                                    'italic',
                                                    'underline',
                                                    'strikethrough',
                                                    'monospace'
                                                ],
                                                bold: {
                                                    className:
                                                        'bordered-option-classname'
                                                },
                                                italic: {
                                                    className:
                                                        'bordered-option-classname'
                                                },
                                                underline: {
                                                    className:
                                                        'bordered-option-classname'
                                                },
                                                strikethrough: {
                                                    className:
                                                        'bordered-option-classname'
                                                },
                                                code: {
                                                    className:
                                                        'bordered-option-classname'
                                                }
                                            },
                                            blockType: {
                                                className:
                                                    'bordered-option-classname'
                                            },
                                            fontSize: {
                                                className:
                                                    'bordered-option-classname'
                                            },
                                            fontFamily: {
                                                className:
                                                    'bordered-option-classname'
                                            }
                                        }}
                                    />
                                    <br />
                                    <p className='text-danger'>
                                        {descriptionError
                                            ? t(descriptionError)
                                            : ''}
                                    </p>
                                </Fragment>
                            )
                        ) : null}
                    </Col>
                </Row>

                <Row>
                    {task && task.description && !isTaskOwnerEmployee ? (
                        <Col lg='2' md='2' sm='6' className=''>
                            <Button
                                className='bg-primary'
                                style={{ display: 'block', width: '100%' }}
                                onClick={(_e) => handleDescriptionSubmit()}
                            >
                                {handleUpdateTaskDescriptionMutation.isLoading ? (
                                    <TbLoader />
                                ) : (
                                    t('Save')
                                )}
                            </Button>
                        </Col>
                    ) : null}

                    {task &&
                    task.description_attachments &&
                    task.description_attachments.length > 0 ? (
                        <Col
                            lg='10'
                            md='10'
                            sm='6'
                            hidden={
                                !(
                                    task &&
                                    task.description_attachments &&
                                    task.description_attachments.length > 0
                                )
                            }
                        >
                            <DownloadAttachments
                                type='task'
                                style={{ display: 'block', width: '100%' }}
                                showAttachments={false}
                                files={task.description_attachments}
                            />
                        </Col>
                    ) : null}
                </Row>
            </>
        )
    }

    const renderDetailsBox = () => {
        return (
            <Card
                outline
                body
                className='border border-primary rounded'
                style={{
                    width: 'auto'
                }}
            >
                <CardHeader>{t('Details')}</CardHeader>
                <br />
                <ListGroup flush>
                    <ListGroupItem className='border-bottom border-primary'>
                        <Row>
                            <Col>
                                <p>{t('Task Manager')}</p>
                            </Col>
                            <Col>
                                {task ? (
                                    <span
                                        className='badge bg-success text-white rounded p-2'
                                        style={{
                                            marginBottom: 0,
                                            display: 'block'
                                        }}
                                    >
                                        {task?.manager?.name}
                                    </span>
                                ) : (
                                    <div
                                        className='dt-field dt-skeleton'
                                        style={{ height: 27 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>

                    {/* owners */}
                    <ListGroupItem className='border-bottom border-primary '>
                        <Row>
                            <Col>
                                <p>{t('Task owner')}</p>
                            </Col>
                            <Col>
                                {task ? (
                                    isTaskOwnerEmployee ? (
                                        <span
                                            className='badge bg-success text-white rounded p-2'
                                            style={{
                                                marginBottom: 0,
                                                display: 'block'
                                            }}
                                        >
                                            {t('Yourself')}
                                        </span>
                                    ) : !handleUpdateTaskOwnerMutation.isLoading &&
                                      owners ? (
                                        <Select
                                            required
                                            name='owner'
                                            classNamePrefix='select2-selection'
                                            options={owners}
                                            isDisabled={
                                                user.id !== task?.manager?.id
                                            }
                                            value={selectedOwner}
                                            onChange={(e) => {
                                                handleChangeOwner(e)
                                            }}
                                        />
                                    ) : (
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    )
                                ) : (
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>

                    {/* priority */}
                    <ListGroupItem className='border-bottom border-primary'>
                        <Row>
                            <Col>
                                <p>{t('Priority')}</p>
                            </Col>
                            <Col>
                                {!handleUpdateTaskPriorityMutation.isLoading &&
                                priorities &&
                                task ? (
                                    <Select
                                        required
                                        name='priority'
                                        classNamePrefix='select2-selection'
                                        options={priorities}
                                        value={selectedPriority ? {
                                            ...selectedPriority,
                                            label: t(selectedPriority.baseLabel)
                                        } : null}
                                        onChange={(e) => {
                                            handleChangePriority(e)
                                        }}
                                        isDisabled={!!isTaskOwnerEmployee}
                                    />
                                ) : (
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>

                    {/* created at */}
                    <ListGroupItem className='border-bottom border-primary'>
                        <Row>
                            <Col>
                                <p>{t('Created')}</p>
                            </Col>
                            <Col>
                                {task ? (
                                    <Label
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '26px'
                                        }}
                                    >
                                        {dateUtils.convertTimeStampToDate(
                                            task.created_at,
                                            INTERNATIONAL_DATE_FORMAT
                                        )}
                                    </Label>
                                ) : (
                                    <div
                                        className='dt-field dt-skeleton'
                                        style={{ height: 27 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>

                    {/* started at */}
                    <ListGroupItem className='border-bottom border-primary'>
                        <Row>
                            <Col>
                                <p>{t('Start Date')}</p>
                            </Col>
                            <Col>
                                {task ? (
                                    <Label
                                        style={{
                                            marginBottom: 0,
                                            lineHeight: '26px'
                                        }}
                                    >
                                        {dateUtils.convertTimeStampToDate(
                                            task.started_at,
                                            INTERNATIONAL_DATE_FORMAT
                                        )}
                                    </Label>
                                ) : (
                                    <div
                                        className='dt-field dt-skeleton'
                                        style={{ height: 27 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>

                    {/* deadline */}
                    <ListGroupItem>
                        <Row>
                            <Col>
                                <p>{t('Deadline')}</p>
                            </Col>
                            <Col>
                                {!handleUpdateTaskDeadlineMutation.isLoading &&
                                task ? (
                                    <Input
                                        name='deadlineDate'
                                        min={dateUtils
                                            .convertTimestampToOnlyDate(
                                                task.started_at
                                            )
                                            .toString()
                                            .split('/')
                                            .reverse()
                                            .join('-')}
                                        type='date'
                                        value={selectedDeadline}
                                        onChange={(e) =>
                                            handleChangeDeadline(e.target.value)
                                        }
                                        disabled={!!isTaskOwnerEmployee}
                                    />
                                ) : (
                                    <div className='dt-field dt-skeleton dt-select-list' />
                                )}
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        )
    }

    useEffect(() => {
        if (task && owners) {
            const owner = owners.find((o) => o.value === task?.owner_object?.id)
            setSelectedOwner(owner)
        }
    }, [owners, task])

    useEffect(() => {
        if (task && categories) {
            const category = categories.find(
                (o) => o.value === task.category?.id
            )
            category && setSelectedCategory(category)
        }
    }, [categories, task])

    let pageTitleText = '...'

    if (task && taskDetailPageHeaderText) {
        pageTitleText = taskDetailPageHeaderText({
            task
        })
    }

    return (
        <div className='risk-management-module page-content'>
            <Container fluid>
                <Row>
                    <Col lg='12' className='mb-4'>
                        <Navigator
                            backButtonOptions={(props) => {
                                return {
                                    ...props,
                                    enable: true
                                }
                            }}
                            breadCrumbs={breadCrumbs}
                            pageTitle={pageTitleText}
                        />
                    </Col>

                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='12' md='8'>
                                        {renderContent()}
                                    </Col>

                                    <Col lg='4' md='4' sm='12'>
                                        {renderDetailsBox()}
                                    </Col>
                                </Row>

                                {task && (
                                    <Row>
                                        <Col sm='12'>
                                            <Comments
                                                isReadOnly={false}
                                                task={task}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    return {
        user: state.Login.user,
        token,
        Modules: state.Modules
    }
}

export default withNamespaces()(connect(mapStatetoProps, {})(TaskDetails))
