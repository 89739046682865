import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";

import OverviewHeader from "../../../../components/overview-header";

import Accordion from 'react-bootstrap/Accordion';
import EditSupplierGeneralInfosModal from "./components/edit-general-infos-modal";
import GeneralInformationImage from "src/modules/3rd-party-management/assets/images/general-information.svg";
import ContactPersonImage from "src/modules/3rd-party-management/assets/images/contact-person.svg";
import ShareholderImage from "src/modules/3rd-party-management/assets/images/shareholders.svg";
import LegalEntitiesImage from "src/modules/3rd-party-management/assets/images/legal-entities.svg";
import ManagementTeamImage from "src/modules/3rd-party-management/assets/images/management-team.svg";
import LocationImage from "src/modules/3rd-party-management/assets/images/location.svg";

import { Col, Label, Row } from "reactstrap";
  
import ContactPeople from "./components/contact-people";
import ManagementTeam from "./components/management-team";

import IndustriesService from "src/modules/3rd-party-management/apis/common/IndustriesService";

import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import Shareholders from "./components/shareholders";
import LegalEntites from "./components/legal-entities";
import Locations from "./components/locations";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import { hasUserAccessToEditSupplier } from "src/modules/3rd-party-management/helpers/users";
import { connect } from "react-redux";

const BasicInformation = ({
    user,
    t,
    supplierId,
    lng
}) => {

    const [ activeAccordion, setActiveAccordion ] = useState(null);
    const [ supplierInfos, setSupplierInfos ] = useState(null);
    const [ industries, setIndustries ] = useState([]);
    const [ selectedSupplierToEditId, setSelectedSupplierToEditId ] = useState(null);

    const overviewHeaderRef = useRef();

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: [
            '3rd-party-management-supplier-details-basic-infos',
            supplierId
        ],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		},
        onSuccess: (data) => {
            setSupplierInfos({
                ...data,
                industries  :   (data.industries || []).map((industry) => {
                    const translatedIndustry = industries.find((tIndustry) => tIndustry.value === industry.id);

                    if(translatedIndustry)
                        return translatedIndustry
                    
                    return {
                        value: industry?.id,
                        baseLabel: industry?.name,
                        label: `${industry?.class} - ${industry?.name}`
                    }
                })
            });
        }
	});

    const handleFetchIndustriesListQuery = useQuery({
        queryKey: [
            '3rd-party-management-fetch-industries-list-query',
            lng
        ],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList({
                language: lng !== 'en' ? lng : undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching industries list.'), {
                type: 'error',
            });
        }
    });

    useEffect(() => {
        if(handleFetchIndustriesListQuery?.data){
            const list = (handleFetchIndustriesListQuery.data || []).map((industry) => {
                return {
                    value       :   industry.id,
                    label       :   `${industry.class} - ${industry.title}`,
                    baseLabel   :   industry.title,
                    keywords    :   industry?.keywords || []
                };
            });
    
            setIndustries(list);
    
            const newSelectedIndustries = (supplierInfos?.industries || []).map((industry) => {
                return list.find((listIndustry) => listIndustry.value === industry.value)
            });
    
            setSupplierInfos((currentState) => {
                return {
                    ...currentState,
                    industries: newSelectedIndustries
                }
            });
        }
    }, [ handleFetchIndustriesListQuery.data ]);

    useEffect(() => {
        handleFetchIndustriesListQuery.refetch();
    }, [ lng ]);

    const onGeneralInfoUpdateCompleted = () => {
        handleFetchSupplierBasicInformation.refetch();

        const {
            reload  :   reloadOverviewHeader
        } = (overviewHeaderRef?.current || {});

        reloadOverviewHeader && reloadOverviewHeader();
    }

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12" className="mt-4 mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
            </Row>
        )
    }

    const dataIsLoading = (
        handleFetchSupplierBasicInformation.isLoading || handleFetchSupplierBasicInformation.isFetching
    );

    const hasUserAccess = (!dataIsLoading && supplierInfos) ? 
        hasUserAccessToEditSupplier(user, supplierInfos.assignedAnalyst?.id) : 
        false;

    return (
        <div className="p-4">
            <OverviewHeader supplierId={ supplierId } actionsRef={ overviewHeaderRef } />
            
            {
                !dataIsLoading && supplierInfos ?  (
                    <>
                        <Accordion className="custom-accordion mt-4" defaultActiveKey={ activeAccordion }>
                            <Accordion.Item onClick={() => setActiveAccordion('general-information')} className={activeAccordion === 'general-information' ? 'active' : ''} eventKey={ 'general-information' }>
                                <Accordion.Header>
                                    <img src={ GeneralInformationImage } alt="" />
                                    <span>
                                        { t('General Information') }
                                    </span>

                                    { hasUserAccess && (
                                        <span onClick={ (e) => {
                                            e.stopPropagation();
                                            setSelectedSupplierToEditId(supplierId);
                                        } } className="edit-general-infos-btn">
                                            <i className="ri-pencil-line"></i>
                                        </span>
                                    )}
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Row>
                                        <Col sm="12" md="6" lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('Short Name')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.shortName }
                                            </p>
                                        </Col>

                                        {supplierInfos.isOwnCompany && supplierInfos.legalEntities.parentLegal && (
                                            <Col sm="12" md="6" lg="3" className="mb-2">
                                                <Label className="form-label">
                                                    {t('Parent Entity')}:
                                                </Label>
                                                <p>
                                                    {`${supplierInfos.legalEntities.parentLegal.title} ${supplierInfos.legalEntities.parentLegal.shortName ? '(' + supplierInfos.legalEntities.parentLegal.shortName + ')' : ''}`}
                                                </p>
                                            </Col>  
                                        )}

                                        <Col sm="12" md="6" lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('VAT ID')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.vatNumber }
                                            </p>
                                        </Col>

                                        <Col sm="12" md="6" lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('Brands')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.brands }
                                            </p>
                                        </Col>

                                        <Col sm="12" md="6" lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('Industries')}:
                                            </Label>
                                            <p>
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {
                                                        (supplierInfos.industries || [])
                                                            .map((industry) => industry.label)
                                                            .join('\n')
                                                    }
                                                </div>
                                            </p>
                                        </Col>
                                        
                                        <Col sm="12" md="6"  lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('Employee Size')}:
                                            </Label>
                                            <p>
                                                {CompanySizes[supplierInfos.employeeSize] ? (
                                                    <>
                                                        { `${CompanySizes[supplierInfos.employeeSize]?.title} ${t("Employees")}` }
                                                    </>
                                                ) : null}
                                            </p>
                                        </Col>

                                        <Col sm="12" md="6" lg="3" className="mb-2">
                                            <Label className="form-label">
                                                {t('Website')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.websiteUrl }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12">
                                            <Label className="form-label">
                                                {t('Description')}:
                                            </Label>
                                            <div dangerouslySetInnerHTML={{__html: supplierInfos.description}} />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('locations')} className={activeAccordion === 'locations' ? 'active' : ''} eventKey={ 'locations' }>
                                <Accordion.Header>
                                    <img src={ LocationImage } alt="location" />
                                    <span>
                                        { t('Location') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Locations 
                                        supplierId={ supplierId }
                                        readOnly={ !hasUserAccess }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('management-team')} className={activeAccordion === 'management-team' ? 'active' : ''} eventKey={ 'management-team' }>
                                <Accordion.Header>
                                    <img src={ ManagementTeamImage } alt="" />
                                    <span>
                                        { t('Management Team') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ManagementTeam 
                                        readOnly={ !hasUserAccess }
                                        supplierId={supplierId} 
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('contact-person')} className={activeAccordion === 'contact-person' ? 'active' : ''} eventKey={ 'contact-person' }>
                                <Accordion.Header>
                                    <img src={ ContactPersonImage } alt="" />
                                    <span>
                                        { t(supplierInfos?.isOwnCompany ? 'Responsible Person' : 'Contact Person') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ContactPeople 
                                        readOnly={ !hasUserAccess }
                                        supplierType={ supplierInfos?.isOwnCompany ? 'own' : 'creditor' } 
                                        supplierId={ supplierId } 
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('shareholders')} className={activeAccordion === 'shareholders' ? 'active' : ''} eventKey={ 'shareholders' }>
                                <Accordion.Header>
                                    <img src={ ShareholderImage } alt="" />
                                    <span>
                                        { t('Shareholders') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Shareholders 
                                        supplierId={ supplierId } 
                                        readOnly={ !hasUserAccess }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            {!supplierInfos?.isOwnCompany && isFeatureEnabledForActiveModule('entity_management') && (
                                <Accordion.Item onClick={() => setActiveAccordion('legalentities')} className={activeAccordion === 'legalentities' ? 'active' : ''} eventKey={ 'legalentities' }>
                                    <Accordion.Header>
                                        <img src={ LegalEntitiesImage } alt="" />
                                        <span>
                                            { t('Connected Legal Entities') }
                                        </span>
                                    </Accordion.Header>
                                    
                                    <Accordion.Body>
                                        <LegalEntites
                                            supplierId={ supplierId } 
                                            readOnly = { !hasUserAccess }
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </Accordion>
                    </>
                ) : (
                    <>
                        { renderLoadingSection() }
                    </>
                )
            }

            <EditSupplierGeneralInfosModal 
                closeModal={() => setSelectedSupplierToEditId(null)}
                supplierId={selectedSupplierToEditId}
                onComplete={onGeneralInfoUpdateCompleted}
            />
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { user } = state.Login;

    return {
        user
    };
};

export default withNamespaces()(
    connect(mapStatetoProps, {})(
        withRouter(memo(BasicInformation))
    )
); 