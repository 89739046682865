import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import GeneralInformationImage from "src/modules/3rd-party-management/assets/images/general-information.svg";
import ShareholderImage from "src/modules/3rd-party-management/assets/images/shareholders.svg";
import ManagementTeamImage from "src/modules/3rd-party-management/assets/images/management-team.svg";
import LocationImage from "src/modules/3rd-party-management/assets/images/location.svg";
import ProductsImage from "src/modules/3rd-party-management/assets/images/products-list.svg";
import { useQuery } from '@tanstack/react-query';
import SupplierService from 'src/modules/3rd-party-management/apis/thirdparty/SupplierService';
import { toast } from 'react-toastify';
import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import Locations from './components/locations';
import ManagementTeam from './components/management-team';
import Shareholders from './components/shareholders';
import EditSupplierGeneralInfosModal from './components/edit-general-infos-modal';
import ProductsList from './components/products';

const BasicInformation = ({
    t,
    Organization
}) => {
    const {
        data: basicInfos = {},
        refetch: refetchBasicInfos,
        isFetching: basicInfosFetching,
        isLoading: basicInfosLoading
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-supplier-general-information-query',
            Organization.id
        ],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchGeneralInformation(Organization.id);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: true,
        onError: () => {
            toast(t('n error occurred while fetching the basic information.'), {
                type: 'error'
            });
        }
    });

    const [ activeAccordion, setActiveAccordion ] = useState('general-information');
    const [ showGeneralEditModal, setShowGeneralEditModal ] = useState(false);

    const dataIsLoading = basicInfosFetching || basicInfosLoading;

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <Accordion className='custom-accordion' defaultActiveKey='general-information' onSelect={(e) => setActiveAccordion(e)}>
                            <Accordion.Item 
                                eventKey='general-information' 
                                className={activeAccordion === 'general-information' ? 'active' : ''}
                            >
                                <Accordion.Header>
                                    <img alt='General Information' src={ GeneralInformationImage } />
                                    <span>
                                        { t('General Information') }
                                    </span>

                                    <span onClick={ (e) => {
                                        e.stopPropagation();
                                        setShowGeneralEditModal(true);
                                    } } className="edit-general-infos-btn">
                                        <i className="ri-pencil-line"></i>
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Row>
                                        <Col sm="12" md="6" lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Company Name')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    { basicInfos.name }
                                                </p>
                                            )}
                                            
                                        </Col>

                                        <Col sm="12" md="6" lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Short Name')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    { basicInfos.shortName }
                                                </p>
                                            )}
                                            
                                        </Col>

                                        <Col sm="12" md="6" lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Brands')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    { basicInfos.brands }
                                                </p>
                                            )}
                                        </Col>

                                        <Col sm="12" md="6" lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Industries')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {
                                                            (basicInfos.industries || [])
                                                                .map((industry) => `${industry.class} - ${industry.name}`)
                                                                .join('\n')
                                                        }
                                                    </div>
                                                </p>
                                            )}
                                        </Col>
                                        
                                        <Col sm="12" md="6"  lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Employee Size')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    {CompanySizes[basicInfos.employeeSize] ? (
                                                        <>
                                                            { `${CompanySizes[basicInfos.employeeSize]?.title} ${t("Employees")}` }
                                                        </>
                                                    ) : null}
                                                </p>
                                            )}
                                        </Col>

                                        <Col sm="12" md="6" lg="4" className="mb-2">
                                            <Label className="form-label">
                                                {t('Website')}:
                                            </Label>
                                            {dataIsLoading ? (
                                                <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                            ) : (
                                                <p>
                                                    { basicInfos.websiteUrl }
                                                </p>
                                            )}
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item 
                                eventKey='locations'
                                className={activeAccordion === 'locations' ? 'active' : ''}
                            >
                                <Accordion.Header>
                                    <img src={ LocationImage } alt="Locations" />
                                    <span>
                                        { t('Locations') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Locations 
                                        supplierId={ Organization.id }
                                        readOnly={ false }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item 
                                eventKey='products'
                                className={activeAccordion === 'products' ? 'active' : ''}
                            >
                                <Accordion.Header>
                                    <img src={ ProductsImage } alt="Products" />
                                    <span>
                                        { t('Products') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                {!dataIsLoading && (
                                    <ProductsList 
                                        industryIds={ basicInfos.industries.map(industry => industry.id) } 
                                        supplierId={ Organization.id } 
                                    />
                                )}
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item 
                                eventKey='management-team'
                                className={activeAccordion === 'management-team' ? 'active' : ''}
                            >
                                <Accordion.Header>
                                    <img src={ ManagementTeamImage } alt="Management Team" />
                                    <span>
                                        { t('Management Team') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ManagementTeam 
                                        readOnly={ false }
                                        supplierId={ Organization.id } 
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item 
                                eventKey='holders'
                                className={activeAccordion === 'holders' ? 'active' : ''}
                            >
                                <Accordion.Header>
                                    <img src={ ShareholderImage } alt="Shareholders" />
                                    <span>
                                        { t('Shareholders') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Shareholders 
                                        supplierId={ Organization.id } 
                                        readOnly={ false }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        {showGeneralEditModal && (
                            <EditSupplierGeneralInfosModal 
                                closeModal={() => setShowGeneralEditModal(false)}
                                supplierId={Organization.id}
                                onComplete={refetchBasicInfos}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        BasicInformation
    )
);