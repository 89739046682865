import React, { useState, useEffect } from 'react';
import { Card, Row, Col, CardBody, Container, Button } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import axios from 'axios';
import { 
    API_URL_GET_TUTORIAL_ADMIN_LIST,
    API_URL_GET_TUTORIAL_LESSONS
} from '../../common/constants';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import UserUtils from '../../services/utils/UserUtils';

const TutorialHome = ({
    t,
    token,
    user,
    Modules
}) => {
	const [breadcrumbItems] = useState([
		{ title: 'SIP', link: '#' },
		{ title: t('Tutorial'), link: '#' },
	]);
	const [list, setList] = useState([]);
	const [userRole, setUserRole] = useState(null);

	const fetchLessons = () => {
		axios.get(
            `${userRole === 'admin' ? API_URL_GET_TUTORIAL_ADMIN_LIST : API_URL_GET_TUTORIAL_LESSONS}/${Modules.active}`
        , {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            setList((res?.data?.message || []).map((item) => {
                return {
                    ...item,
                    language_label: t(item.language_name),
                    role_label: t(item.role)  
                }
            }));
        });
	};

	useEffect(() => {
		const userUtils = new UserUtils();
		if (userUtils.isAnalystAdmin(user)) {
			setUserRole('admin');
		} else if (userUtils.isEmployee(user)) {
			setUserRole('employee');
		} else if (userUtils.isThirdParty(user)) {
			setUserRole('third-party');
		}
	}, [token, user]);

	useEffect(() => {
        if(userRole){
            fetchLessons();
        }
	}, [ userRole ]);

    useEffect(() => {
        setList((currentState) => {
            return currentState.map((item) => {             
                return {
                    ...item,
                    language_label: t(item.language_name),
                    role_label: t(item.role)  
                }
            })
        })
    }, [ t ]);

	const seeDetails = (cell, row) => {
		let id = row.tutorial_id;
		let name = row.tutorial_name;

		return (
			<Link
				to={{
					pathname: `/${userRole}/tutorial/${id}/detail`,
					tutorial_id: id,
					tutorial_name: name,
				}}
			>
				<Button type='button' color='btn btn-primary' className='btn-rounded mb-2 me-2'>
                    {t('Play')}
				</Button>
			</Link>
		);
	};

	const columns = [
		{
			dataField: 'tutorial_name',
			text: t('Tutorial name'),
			sort: true
		},
		{
			dataField: 'language_name',
			text: t('Language'),
			sort: true,
            formatter: (cell, row) => { return row.language_label }
		},
		{
			dataField: 'role',
			text: t('Role'),
			sort: true,
            hidden: !userRole || ['third-party', 'employee'].includes(userRole),
            formatter: (cell, row) => { return row.role_label }
		},
		{
			dataField: 'see_details',
			text: t('See details'),
			formatter: seeDetails,
			sort: false,
		},
	];

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<Breadcrumbs title={t('Tutorial')} breadcrumbItems={breadcrumbItems} />
					<Row>
						<Col className='col-12'>
							<Card>
								<CardBody>
									<PaginationProvider pagination={paginationFactory({
                                        sizePerPage: 25,
                                        totalSize: list.length,
                                        custom: true
                                    })} keyField='lesson_id' columns={columns} data={list}>
										{({ paginationProps, paginationTableProps }) => (
											<ToolkitProvider keyField='lesson_id' columns={columns} data={list} search>
												{(toolkitProps) => (
													<React.Fragment>
														<Row>
															<Col xl='12'>
																<div className='table-responsive'>
																	<BootstrapTable
																		keyField={'lesson_id'}
																		responsive
																		bordered={false}
																		striped={false}
																		classes={'table align-middle table-nowrap'}
																		headerWrapperClasses={'thead-light'}
																		{...toolkitProps.baseProps}
																		{...paginationTableProps}
																	/>
																</div>
															</Col>
														</Row>

														<Row className='align-items-md-center mt-30'>
															<Col className='inner-custom-pagination d-flex'>
																<div className='d-inline'>
																	<SizePerPageDropdownStandalone {...paginationProps} />
																</div>
																<div className='text-md-right ms-auto'>
																	<PaginationListStandalone {...paginationProps} />
																</div>
															</Col>
														</Row>
													</React.Fragment>
												)}
											</ToolkitProvider>
										)}
									</PaginationProvider>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Modules } = state;
	return { token, user, App, Modules };
};

export default withNamespaces()(connect(mapStatetoProps, null)(TutorialHome));
