import { withNamespaces } from "react-i18next";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Spinner
} from "reactstrap";

const ActivateQuestionnaireModal = ({
    t,
    itemToActivate
}) => {
    return (
        <Modal size="md" isOpen={ itemToActivate }>
            <ModalHeader>
                {t('Questionnaire Activation')}
            </ModalHeader>
                
            <ModalBody>
                <p>
                    <Spinner size='sm' color="primary" className="me-2" />
                    {t('Activating the questionnaire. Please wait')}...
                </p>
            </ModalBody>
        </Modal>
    )
};

export default withNamespaces()(ActivateQuestionnaireModal);