import { useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import CreateAndEditModal from './components/CreateAndEditModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/common/ThirdpartyUsersService";
import ShareholdersService from "src/modules/3rd-party-management/apis/common/ShareholdersService";
import { toast } from "react-toastify";
import { Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import DateUtils from 'src/services/utils/DateUtils';
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';

const Shareholders = ({
    t,
    supplierId,
    readOnly
}) => {
    const dateUtils = new DateUtils();
    const [ showShareholderModal, setShowShareholderModal ] = useState(false);
    const [ selectedShareholderToEdit, setSelectedShareholderToEdit ] = useState(null);
    const [ shareholderToDelete, setShareholderToDelete ] = useState(null);

    const {
        data    :   countriesData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-countries-list-query'],
		queryFn: async () => {
			const service = ThirdpartyUsersService.getInstance();

			return await service.fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const {
        data        :   shareholdersData,
        refetch     :   refetchShareholdersList,
        isFetching  :   fetchingShareholdersInProcess
    } = useQuery({
		queryKey: [
            '3rd-party-management-fetch-shareholders-list',
            supplierId
        ],
		queryFn: async () => {
			const service = ShareholdersService.getInstance();

			return await service.fetchList(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching shareholders list.'), {
				type: 'error',
			});
		}
	});

    const {
        isLoading   :   createShareholderMutationInProcess,
        mutate      :   createShareholderMutation
    } = useMutation({
        mutationFn: async (payload) => {
            const service = ShareholdersService.getInstance();
            return await service.create(payload);
        },
        onSuccess: () => {
            refetchShareholdersList();

            setShowShareholderModal(false);

            toast(t("New shareholder created successfully"), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while creating new shareholder."), {
                type: "error",
            });
        }
    });

    const {
        isLoading   :   updateShareholderMutationInProcess,
        mutate      :   updateShareholderMutation
    } = useMutation({
        mutationFn: async (payload) => {
            const service = ShareholdersService.getInstance();
            return await service.update(selectedShareholderToEdit?.id, payload);
        },
        onSuccess: () => {
            refetchShareholdersList();

            setShowShareholderModal(false);

            toast(t("The shareholder updated successfully"), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating the shareholder."), {
                type: "error",
            });
        }
    });

    const {
        isLoading   :   deleteShareholderMutationInProcess,
        mutate      :   deleteShareholderMutation
    } = useMutation({
        mutationFn: async () => {
            const service = ShareholdersService.getInstance();
            return await service.delete(shareholderToDelete.id);
        },
        onSuccess: () => {
            refetchShareholdersList();

            setShareholderToDelete(null);

            toast(t("The shareholder deleted successfully"), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting the shareholder."), {
                type: "error",
            });
        }
    });

    const {
        isLoading   :   checkingShareholdersSanctionsInProccess,
        mutate      :   mutateShareholdersSanctionsCheck
    } = useMutation({
        mutationFn: async () => {
            const service = ShareholdersService.getInstance();
            return await service.checkSanctions(supplierId);
        },
        onSuccess: (data) => {
            toast(t("Number of found sanction items") + `: ${data?.countOfSanctions || 0}`, {
                type: "info",
            });
        },
        onError: () => {
            toast(t("An error occurred while checking sanctions."), {
                type: "error",
            });
        }
    });

    const countries = useMemo(() => {
        return (countriesData || []).map((country) => {
            return {
                value   :   country.id,
                label   :   t(country.name),
                code    :   country.code,
            };
        });
    }, [ countriesData, t ])

    useEffect(() => {
        if(selectedShareholderToEdit){
            setShowShareholderModal(true)
        }
    }, [selectedShareholderToEdit])

    const renderModals = () => {
        if(readOnly) return null;
        
        return (
            <>
                <CreateAndEditModal
                    show={showShareholderModal}
                    toggle={() => {
                        setShowShareholderModal(false)
                    }}
                    onSubmit={(type, values, action) => {
                        if(action === 'create'){
                            createShareholderMutation({
                                ...values,
                                supplier    :   supplierId,
                                type        :   type
                            })
                        }
                        else{
                            updateShareholderMutation({
                                ...values,
                                type        :   type
                            })
                        }
                    }}
                    countries={countries}
                    shareholder={selectedShareholderToEdit}
                    onClosed={() => setSelectedShareholderToEdit(null)}
                    submitInProcess={createShareholderMutationInProcess || updateShareholderMutationInProcess}
                />

                <Modal isOpen={ !!shareholderToDelete } backdrop="static" size="md">
                    <ModalHeader toggle={() => setShareholderToDelete(null)}>
                        {t("Confirmation Form")}
                    </ModalHeader>

                    <ModalBody>
                        <p>
                            {t('Do you want to delete the shareholder?')}
                        </p>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={deleteShareholderMutation} disabled={ deleteShareholderMutationInProcess } color="danger" type="button" size="md">
                            {
                                deleteShareholderMutationInProcess && (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                )
                            }
                            { t('Delete') }
                        </Button>

                        <Button onClick={() => setShareholderToDelete(null)} disabled={ deleteShareholderMutationInProcess } color="secondary" type="button" outline>
                            { t('Cancel') }
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    const companyShareHolders = shareholdersData?.shareHolders?.company || [];
    const individualShareHolders = shareholdersData?.shareHolders?.individual || [];

    return (
        <div className="mb-4">
            <Row>
                <Col sm='12'>
                    <Label>
                        {t('Individual')}
                    </Label>
                    <div className='table-container'>
                        <table className='border-0'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('Salutation')}</th>
                                    <th>{t('First Name')}</th>
                                    <th>{t('Last Name')}</th>
                                    <th>{t('Name In Local Language')}</th>
                                    <th>{t('Last Name In Local Language')}</th>
                                    <th>{t('Birthday')}</th>
                                    <th>{t('Resident In Country')}</th>
                                    <th>{t('Percentage')}</th>
                                    <th>{t('UBO')}</th>
                                    {!readOnly && (
                                        <th>{t('Action')}</th>
                                    )}
                                </tr>
                            </thead>
                            {fetchingShareholdersInProcess ? (
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                        <td>
                                            <div className="dt-field dt-skeleton"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {!individualShareHolders?.length && (
                                        <tr>
                                            <td colSpan={11}>
                                                <Alert className='text-center' color='warning'>
                                                    { t('This list is empty') }
                                                </Alert>
                                            </td>
                                        </tr>
                                    )}
                                    {individualShareHolders?.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.localLanguageFirstName}</td>
                                                <td>{item.localLanguageLastName}</td>
                                                <td>{item.birthDay && dateUtils.convertDateToDate(item.birthDay)}</td>
                                                <td>{t(item.residenceCountry?.name)}</td>
                                                <td>{item.percentage}%</td>
                                                <td>{item.isUbo ? t('Yes') : t('No')}</td>
                                                {!readOnly && (
                                                    <td>
                                                        <Button onClick={() => {
                                                            setSelectedShareholderToEdit({
                                                                ...item,
                                                                type : 'individual'
                                                            })
                                                        }} type='button' outline color="primary" size="md" className="border-0">
                                                            <i className="ri-pencil-line font-size-20"></i>
                                                        </Button>

                                                        <Button onClick={() => {
                                                            setShareholderToDelete(item)
                                                        }} type='button' outline color="danger" size="md" className="border-0">
                                                            <i className="ri-delete-bin-line font-size-20"></i>
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )}
                        </table>     
                    </div>
                </Col>
                <Col sm='12'>
                    <Label>
                        {t('Company')}
                    </Label>
                    <div className='table-container'>
                        <table className='border-0'>
                            <thead>
                                    <tr>
                                        <th>{t('#')}</th>
                                        <th>{t('Name')}</th>
                                        <th>{t('VAT ID')}</th>
                                        <th>{t('Name In Local Language')}</th>
                                        <th>{t('Founding Date')}</th>
                                        <th>{t('Country')}</th>
                                        <th>{t('Percentage')}</th>
                                        {!readOnly && (
                                            <th>{t('Action')}</th>
                                        )}
                                    </tr>
                            </thead>
                            {fetchingShareholdersInProcess ? (
                                <tbody>
                                    <tr>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                        <td><div className="dt-field dt-skeleton"></div></td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {!companyShareHolders?.length && (
                                        <tr>
                                            <td colSpan={8}>
                                                <Alert className='text-center' color='warning'>
                                                    { t('This list is empty') }
                                                </Alert>
                                            </td>
                                        </tr>
                                    )}

                                    {companyShareHolders?.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.companyName}</td>
                                                <td>{item.vatNumber}</td>
                                                <td>{item.localLanguageCompanyName}</td>
                                                <td>{item.foundingDate && dateUtils.convertDateToDate(item.foundingDate)}</td>
                                                <td>{t(item.country?.name)}</td>
                                                <td>{item.percentage}%</td>
                                                {!readOnly && (
                                                    <td>
                                                        <Button onClick={() => {
                                                            setSelectedShareholderToEdit({
                                                                ...item,
                                                                type : 'company'
                                                            })
                                                        }} type='button' outline color="primary" size="md" className="border-0">
                                                            <i className="ri-pencil-line font-size-20"></i>
                                                        </Button>

                                                        <Button onClick={() => {
                                                            setShareholderToDelete(item)
                                                        }} outline color="danger" size="md" className="border-0">
                                                            <i className="ri-delete-bin-line font-size-20"></i>
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )}
                        </table>     
                    </div>
                </Col>
            </Row>

            {!readOnly && (
                <Row className="mt-3">
                    <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="button" 
                            color="primary" 
                            outline
                            className="me-2"
                            onClick={() => setShowShareholderModal(true)}>
                            {" + " + t("Add Shareholder")}
                        </Button>

                        {/* SPIK 7307 */}
                        {false && isFeatureEnabledForActiveModule('sanction') && (
                            <Button type="button" 
                                color="danger" 
                                disabled={checkingShareholdersSanctionsInProccess}
                                onClick={mutateShareholdersSanctionsCheck}>
                                    {checkingShareholdersSanctionsInProccess ? (
                                        <>
                                            <Spinner className="me-2" variant="white" size="sm"/>
                                            {t("Checking Sanctions...")}
                                        </>
                                    ) : (
                                        <>
                                            <i className='ri-alert-line me-2' /> 
                                            {t("Check Sanctions")}
                                        </>
                                    )}
                            </Button>
                        )}
                    </Col>
                </Row>
            )}

            { renderModals() }
        </div>
    )
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        Shareholders
    )
);