import { withNamespaces } from 'react-i18next'
import React, { createRef, useEffect, useRef, useState } from 'react'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap'


import { FilterTabContent } from './components/FilterTabContent'

const TableFilter = ({ t, tabs, defaultActiveTab, onOkButtonClicked,  className }) => {
    const [activeTab, setActiveTab] = useState(
        tabs.find((tab) => tab.name === defaultActiveTab) || tabs[0]
    )
    const [showElseTabs, setShowElseTabs] = useState(false)
    const [filters, setFilters] = useState({})
    const [ hiddenTabsStartIndex, setHiddenTabsStartIndex ] = useState(-1)
    const [ activeTabFiltersCount, setActiveTabFiltersCount ] = useState(0)  

    const navRefs = useRef([]);

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => setDropdownOpen((prevState) => !prevState)

    const tabFilterChanged = ({ filterName, value }) => {
        setFilters((currentState) => {
            const newState = { ...currentState }
            newState[filterName] = value
            return newState
        })

        activeTab.onFilterChange && activeTab.onFilterChange(value)
    }

    const calculateActiveTabFiltersCount = () => {
        setActiveTabFiltersCount(
            Object.entries(filters).reduce((count, [key, value]) => {
                if (Array.isArray(value)) {
                    return count + (value.length > 0 ? 1 : 0);
                }
                return count + (value !== null && value !== undefined && value !== 'all' ? 1 : 0);
            }, 
            0)
        )
    }

    const resetFilters = () => {
        setFilters({})
        setActiveTabFiltersCount(0)

        onOkButtonClicked && onOkButtonClicked()
        setDropdownOpen(false)
    }

    useEffect(() => {
        setActiveTab(
            tabs.find(
                (tab) => tab.name === (activeTab?.name || defaultActiveTab)
            ) || tabs[0]
        )
    }, [tabs])

    useEffect(() => {
        if(dropdownOpen){
            let width = 0
            let foundIndex = -1
            for(let i = 0; i < navRefs.current.length; i++){
                width += navRefs.current[i]?.current?.offsetWidth || 0;

                if(width >= 410){
                    foundIndex = i
                    break;
                }
            }

            setHiddenTabsStartIndex(foundIndex)
        }
        else{
            setHiddenTabsStartIndex(-1)
        }
    }, [dropdownOpen])

    useEffect(() => {
        if(!showElseTabs){
            setActiveTab(tabs[0])
        }
    }, [showElseTabs])

    if(navRefs.current.length !== tabs.length){
        navRefs.current = Array(tabs.length)
        .fill()
        .map((_, i) => navRefs.current[i] || createRef());        
    }

    return (
        <div className={`custom-table-filter${className ? ` ${className}` : ''}`}>

            {activeTabFiltersCount > 0 && (
                <Button
                    onClick={resetFilters}
                    color='secondry'
                    size='sm'
                    className="border-0 shadow-none me-3 reset-btn"
                >
                    {t('Reset')}
                </Button>
            )}
            
                     
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color='secondary' outline>
                    <i className='ri-filter-3-fill' />
                    <span>{t('Filter')}</span>

                    {
                        activeTabFiltersCount > 0 && (
                        <span className="filter-badge">{activeTabFiltersCount}</span>
                     )}
                </DropdownToggle>

                <DropdownMenu className='dropdown-menu-end'>
                    <div className='filters-container'>
                        <div className='navs-container d-flex justify-content-center'>
                            <Nav tabs>
                                {tabs.map((tab, index) => {
                                    return (
                                        <NavItem
                                            hidden={hiddenTabsStartIndex >= 0 && index > hiddenTabsStartIndex && !showElseTabs}
                                            key={index}
                                            onClick={() => setActiveTab(tab)}
                                            className={`${
                                                activeTab?.name === tab.name &&
                                                'active'
                                            }`}
                                        >
                                            <NavLink innerRef={navRefs.current[index]}>
                                                {tab.icon && (
                                                    tab.icon
                                                )}
                                                <span>{t(tab.title)}</span>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })}
                                {hiddenTabsStartIndex > -1 && (
                                    <NavItem
                                        key='nav-else-btn'
                                    >
                                        <NavLink onClick={() => setShowElseTabs(!showElseTabs)}>
                                            {showElseTabs ? (
                                                <>
                                                    <i className='ri-close-line' />
                                                    <span>{t('Show Less')}</span>
                                                </>
                                            ): (
                                                <>
                                                    <i className='ri-more-line' />
                                                    <span>{t('Show More')}</span>
                                                </>
                                            )}
                                            
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </div>

                        <div className='navs-content-container'>
                            <TabContent activeTab={activeTab.name}>
                                {tabs.map((tab, index) => {
                                    return (
                                        <TabPane key={index} tabId={tab.name}>
                                            <FilterTabContent
                                                {...tab}
                                                defaultValue={tab.defaultValue}
                                                onFilterChange={tabFilterChanged}
                                            />
                                        </TabPane>
                                    )
                                })}
                            </TabContent>
                        </div>

                        <div className='actions-container gap-3 d-flex align-items-center justify-content-end'>
                            <Button
                                color='primary'
                                size='sm'
                                onClick={() => {
                                    onOkButtonClicked && onOkButtonClicked(filters)
                                    calculateActiveTabFiltersCount()
                                    setDropdownOpen(false)
                                }}
                            >
                                {t('OK')}
                            </Button>

                            <Button
                                onClick={resetFilters}
                                color='secondry'
                                size='sm'
                            >
                                {t('Reset')}
                            </Button>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default withNamespaces()(TableFilter)
