import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import OverviewHeader from "../../../../components/overview-header";
import MessageBox from "src/modules/3rd-party-management/components/MessageBox";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import SupplierInternalCommunicationService from "src/modules/3rd-party-management/apis/admin/SupplierInternalCommunicationService";

const InternalCommunication = ({ supplierId, t }) => {
  const [ suggestionList, setSuggestionList ] = useState([]);
  
  const handleFetchCommunicationMentionSuggestionListQuery = useQuery({
		queryKey              : ["3rd-party-management-fetch-internal-communication-mention-suggestion-list-query", supplierId],
		queryFn               : async () => {
      const service = new SupplierInternalCommunicationService();

      return await service.mentionCommentSearch(supplierId, {
        query : ''
      });
		},
		cacheTime             : 0,
    refetchOnWindowFocus  : false,
		onError               : (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
		},
	});

  useEffect(() => {
    const {
      data
    } = handleFetchCommunicationMentionSuggestionListQuery;

    if(data && Array.isArray(data)){
      const list = data.map((item) => {
        return {
          text    : item.full_name,
          url     : item.id,
          value   : item.full_name
        }
      });

      setSuggestionList(list);
    }
  }, [ handleFetchCommunicationMentionSuggestionListQuery.data ]);

  return (
    <div className="p-4 d-flex flex-column gap-4">
      <OverviewHeader supplierId={supplierId} />
      
      <MessageBox
         settings={{
            headerOptions           :   {
                displayFilters  :   false,
                title           :   'Internal Communication'
            },
            messageListUrl        : '/supplier/comment/list_internal',
            sendMessageUrl        : '/supplier/comment/add_internal',
            attachmentDownloadUrl : '/supplier/attachment/',
            attachmentUploadUrl   : '/supplier/attachment/upload',
            attachmentDeleteUrl   : '/supplier/attachment/',
            supplierId            : supplierId,
            mention               : {
                enable          : true,
                suggestionList  : suggestionList
            }
        }}
        />
    </div>
  );
};

export default withNamespaces()(withRouter(InternalCommunication));