import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Button,
    Label,
    Input
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import InputPhoneNumber from "../../../../components/Fields/InputPhoneNumber";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import UserUtils from "../../../../services/utils/UserUtils";
import { ROLE_EMPLOYEE, ROLE_TASK_OWNER } from "../../../../components/constants";
import axios from "axios";
import { getUrls, routes, roles, validRolesForAnalystAdmin } from "./data";
import EntityManagementService from "src/apis/EntityManagementService";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import AsyncReactSelect from "src/components/AsyncReactSelect";

const EditUser = (props) => {
    const { t, token, modules, history, user: currentUser } = props;

    const activeModule = modules?.active;
    const userUtils = new UserUtils();

    const params = useParams();

    const [inputPhoneNumber, setInputPhoneNumber] = useState(null);
    const [user, setUser] = useState({ gender: "" });
    const [taskOwnerRoleDurationHistory, setTaskOwnerRoleDurationHistory] =
        useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);

    const entityManagementService = EntityManagementService.getInstance();

    const handleSubmit = async (e, values) => {
        try {
            const response = await axios.post(
                getUrls()[activeModule].editUser,
                {
                    ...values,
                    role: values.role || user.topRole,
                    user_id: user.id,
                    phone_number: inputPhoneNumber,
                    department: selectedDivision?.value || null
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                toast(t("Data was saved successfully"), {
                    type: "success",
                });
                history.push(routes[activeModule].listOfUsers);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 403) {
                    toast(t("Access Denied."), {
                        type: "error",
                    });
                } else if (error.response.data.error === "user_has_uncompleted_tasks") {
                    toast(t("This employee has some uncompleted tasks."), {
                        type: "error",
                    });
                } else if (
                    error.response.data.error === "organization_task_owner_limitation"
                ) {
                    toast(t("Task ownership licence is over."), {
                        type: "error",
                    });
                }
            } else {
                toast(t("Failed to edit user."), {
                    type: "error"
                });
            }
        }
    };

    const fetchLegalEntitiesOptions = useCallback(async (params) => {
        const response = await entityManagementService.fetchEntitesList(params, modules.active);

        const options = response.legalEntities.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, []);

    const fetchLegalInstitutesOptions = useCallback(async (params = {}) => {
        const response = await entityManagementService.fetchInstituteList(params, selectedEntity.value, modules.active);

        const options = response.institutes.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, [ selectedEntity?.value ]);

    const fetchLegalDivisionsOptions = useCallback(async (params = {}) => {
        const response = await entityManagementService.fetchDivisionList(params, selectedInstitute.value, modules.active);

        const options = response.divisions.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, [ selectedInstitute?.value ]);

    const fetchUserInfos = useQuery({
        queryKey: ["fetch-user-info-query", params.id],
        queryFn: async () => {
            const response = await axios.get(
                getUrls()[activeModule].getUserDetails + `?user_id=${params?.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            return response.data?.data || {};
        },
        enabled: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t("An error occurred while fetching user info list."), {
                type: "error",
            });
        },
        onSuccess: (data) => {
            const employeeInfo = data.employeeInfo;
            const department = employeeInfo?.department || {};

            setUser((prev) => ({
                ...employeeInfo,
                gender: employeeInfo.gender || "",
                topRole: userUtils.getUserTopRole(employeeInfo.roles)
            }));

            if(department && department.division_id){
                setSelectedEntity({
                    value: department.legal_id,
                    label: department.legal_name
                });
    
                setSelectedInstitute({
                    value: department.institute_id,
                    label: department.institute_name 
                });
    
                setSelectedDivision({
                    value: department.division_id,
                    label: department.division_name 
                });
            }

            if (data.taskOwnerRoleDurationHistory) {
                setTaskOwnerRoleDurationHistory(data.taskOwnerRoleDurationHistory);
            }
        }
    });

    const availableRolesToAssign = useMemo(() => {
        if(
            currentUser && 
            modules.active && 
            user.id
        ){
            if(userUtils.isSuperAdmin(currentUser) && user.topRole !== 'ROLE_SUPER_ADMIN'){
                return Object.keys(roles).filter((roleKey) => {
                    return roles[roleKey].modules.includes(modules.active)
                }).map((item) => {
                    return {
                        value: item,
                        label: t(roles[item].label)
                    }
                })
            }
            else{
                if(userUtils.isAnalystAdmin(currentUser) && !["ROLE_SUPER_ADMIN", "ROLE_ANALYST_ADMIN", "ROLE_ANALYST"].includes(user.topRole)){
                    return Object.keys(validRolesForAnalystAdmin).filter((roleKey) => {
                        return validRolesForAnalystAdmin[roleKey].modules.includes(modules.active)
                    }).map((item) => {
                        return {
                            value: item,
                            label: t(validRolesForAnalystAdmin[item].label)
                        }
                    })
                }
            }
        }

        return null;
    }, [ 
        currentUser, 
        user,
        modules.active, 
        t 
    ]);

    useEffect(() => {
        fetchUserInfos.refetch();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={t("Edit user")}
                        breadcrumbItems={[
                            { title: "SIP", link: "/" },
                            {
                                title: t("Edit user"),
                                link: routes[activeModule].listOfUsers,
                            },
                            { title: t("User Management"), link: "#" }
                        ]}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{t("User Profile")}</h4>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={handleSubmit}
                                    >
                                        <Row>
                                            <Col sm='12' md='2' className="mb-3">
                                                <Label className="form-label" htmlFor="salutation">
                                                    {t("Salutation")}
                                                </Label>
                                                <AvField
                                                    type="select"
                                                    name="gender"
                                                    id="salutation"
                                                    value={user.gender}
                                                    onChange={(e) =>
                                                        setUser((prev) => ({
                                                            ...prev,
                                                            gender: e.target.value,
                                                        }))
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: t("Please select a salutation"),
                                                        }
                                                    }}
                                                    className="form-control"
                                                >
                                                    <option value="" disabled>
                                                    {t("Select Salutation")}
                                                    </option>
                                                    <option value="Mr">{t("Mr")}</option>
                                                    <option value="Mrs">{t("Mrs")}</option>
                                                    <option value="Mx">{t("Mx")}</option>
                                                </AvField>
                                            </Col>

                                            <Col sm='12' md='5' className="mb-3">
                                                <Label className="form-label" htmlFor="first-name">
                                                    {t("First name")}
                                                </Label>
                                                <AvField
                                                    name="first_name"
                                                    placeholder=""
                                                    type="text"
                                                    errorMessage={t("This field cannot be blank")}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={user.first_name || ""}
                                                    id="first-name"
                                                />
                                            </Col>

                                            <Col sm='12' md='5' className="mb-3">
                                                <Label className="form-label" htmlFor="lastname">
                                                    {t("Last name")}
                                                </Label>
                                                <AvField
                                                    name="last_name"
                                                    placeholder=""
                                                    type="text"
                                                    errorMessage={t("This field cannot be blank")}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={user.last_name || ""}
                                                    id="lastname"
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm='12' md='6' className="mb-3">
                                                <Label
                                                    className="form-label"
                                                    htmlFor="validationTooltipUsername"
                                                >
                                                    {t("Email")}
                                                </Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                    <span
                                                        className="input-group-text"
                                                        id="validationTooltipUsernamePrepend"
                                                    >
                                                        @
                                                    </span>
                                                    </InputGroupAddon>
                                                    <Input
                                                        value={user.email || ""}
                                                        disabled={true}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationTooltipUsername"
                                                        placeholder="Username"
                                                    />
                                                </InputGroup>
                                            </Col>

                                            {availableRolesToAssign && (
                                                <Col sm='12' md='6' className="mb-3">
                                                    <Label htmlFor="user-role">
                                                        {t("Role")}
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name='role'
                                                        className="form-control"
                                                        id='user-role'
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: t("Please select a role"),
                                                            }
                                                        }}
                                                        value={user.topRole}
                                                    >
                                                        {
                                                            <>
                                                                <option value="" disabled>
                                                                    {t("Select Role")}
                                                                </option>
                                                                {availableRolesToAssign.map((item, index) => (
                                                                    <option key={index} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        }
                                                    </AvField>
                                                </Col>
                                            )}
                                        </Row>

                                        <Row>
                                            <Col sm='12' md='6' className="mb-3">
                                                <Label
                                                    className="form-label"
                                                    htmlFor="company-position"
                                                >
                                                    {`${t("Company Position")} ${t("(Optional)")}`}
                                                </Label>
                                                <AvField
                                                    name="position"
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                    id="company-position"
                                                    value={user.position || ""}
                                                />
                                            </Col>

                                            <Col sm='12' md='6' className="mb-3">
                                                <Label className="form-label" htmlFor="phone-number">
                                                    {`${t("Phone number")} ${t("(Optional)")}`}
                                                </Label>
                                                <div className="sip-phone-input">
                                                    <InputPhoneNumber
                                                        id="phone-number"
                                                        name="phone_number"
                                                        required={false}
                                                        style={{ width: "100%" }}
                                                        placeholder=""
                                                        errorMessage=""
                                                        onChange={(phone) => setInputPhoneNumber(phone)}
                                                        value={user.phone_number || ""}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        {isFeatureEnabledForActiveModule("entity_management") && (
                                            <Row className="mb-4">
                                                <Label
                                                    className="form-label"
                                                    htmlFor="company-position"
                                                >
                                                    {`${t("Department")}`}
                                                </Label>

                                                <Col sm="12" md="4">
                                                    <Label className="form-label" htmlFor="entity">
                                                        {`${t("Entity")}`}
                                                    </Label>

                                                    <AsyncReactSelect 
                                                        loadOptions={fetchLegalEntitiesOptions}
                                                        settings={{
                                                            queryKey: 'title',
                                                            loadOptionsErrorMessage: 'Faild to fetch'
                                                        }}
                                                        value={selectedEntity}
                                                        onChange={(e) => {
                                                            setSelectedEntity(e);
                                                            setSelectedInstitute(null);
                                                            setSelectedDivision(null);
                                                        }} 
                                                        placeholder={t("Select an entity")}
                                                    />
                                                </Col>

                                                {selectedEntity && (
                                                    <Col sm="12" md="4">
                                                        <Label className="form-label" htmlFor="institute">
                                                            {`${t("Institute")}`}
                                                        </Label>
                                                        
                                                        <AsyncReactSelect 
                                                            loadOptions={fetchLegalInstitutesOptions}
                                                            value={selectedInstitute}
                                                            settings={{
                                                                queryKey: 'title',
                                                                loadOptionsErrorMessage: 'Faild to fetch'
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedInstitute(e)
                                                                setSelectedDivision(null);
                                                            }} 
                                                            placeholder={t("Select an institute")}
                                                        />
                                                    </Col>
                                                )}

                                                {selectedEntity && selectedInstitute && (
                                                    <Col sm="12" md="4">
                                                        <Label className="form-label" htmlFor="division">
                                                            {`${t("Division")}`}
                                                        </Label>
                                                        
                                                        <AsyncReactSelect 
                                                            loadOptions={fetchLegalDivisionsOptions}
                                                            value={selectedDivision}
                                                            settings={{
                                                                queryKey: 'title',
                                                                loadOptionsErrorMessage: 'Faild to fetch'
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedDivision(e);
                                                            }} 
                                                            placeholder={t("Select a division")}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        )}

                                        <Button color="primary" type="submit">
                                            {t("Save")}
                                        </Button>
                                    </AvForm>

                                    {taskOwnerRoleDurationHistory &&
                                    Array.isArray(taskOwnerRoleDurationHistory) &&
                                    taskOwnerRoleDurationHistory.length > 0 ? (
                                        <Fragment>
                                            <br />
                                            <br />
                                            <br />
                                            <p
                                                className="card-title"
                                                style={{ fontWeight: "bold" }}
                                            >{`${t("Task Owner Role Duration Logs")}:`}</p>
                                            {taskOwnerRoleDurationHistory.map((i) => {
                                                return (
                                                    <div style={{ marginBottom: "10px" }}>
                                                        <div
                                                        style={{
                                                            padding: "10px",
                                                            border: "1px solid #dadada",
                                                            display: "inline-block",
                                                            borderRadius: "5px",
                                                        }}
                                                        >
                                                        <span>
                                                            <span style={{ fontWeight: "bold" }}>
                                                            {t("From")}
                                                            </span>
                                                            : {i.createdAt}
                                                        </span>{" "}
                                                        <span
                                                            style={{
                                                            fontWeight: "bold",
                                                            padding: "0 10px",
                                                            }}
                                                        >
                                                            &gt;&gt;&gt;&gt;&gt;
                                                        </span>{" "}
                                                        <span>
                                                            <span style={{ fontWeight: "bold" }}>
                                                            {t("To")}
                                                            </span>
                                                            : {i.updatedAt ? i.updatedAt : t("Present")}
                                                        </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { App, Modules } = state;
  return { user, token, App, modules: Modules };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps)(EditUser)));
