/* eslint-disable no-undef */
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { AvForm } from 'availity-reactstrap-validation'
import { useState, useEffect } from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { CgLink } from 'react-icons/cg'
import { FaRegComment } from 'react-icons/fa'
import { createMarkup } from 'src/services/utils/Markup'
import {
    Button,
    Col,
    Container,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'
import { convertToHTML } from 'draft-convert'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import axios from 'axios'

import UploadedFiles from './uploadFiles'
import DeletionFileModal from './DeleteFileModal'
import ReplaceFileModal from './ReplaceFileModal'
import DownloadAttachments from './DownloadAttachments'
import DateUtils from 'src/services/utils/DateUtils'
import { useQuery } from '@tanstack/react-query'
import { 
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const Comments = function (props) {
    const dateUtils = new DateUtils()
    const { t, task, user, token, isReadOnly, Modules } = props
    const apiRoutes = ModulesApiRoutes[Modules.active]

    const [activeTab, setActiveTab] = useState('comments')
    const [description, setDescription] = useState(EditorState.createEmpty())
    const [showAttachmentsList, setShowAttachmentsList] = useState(false)
    const [showAttachmentsProgress, setShowAttachmentsProgress] =
        useState(false)
    const [attachmentsList, setAttachmentsList] = useState([])
    const [deleteFileModalStatus, setDeleteFileModalStatus] = useState(false)
    const [deleteFileId, setDeleteFileId] = useState(false)
    const [replaceFileModalStatus, setReplaceFileModalStatus] = useState(false)
    const [replaceFileId, setReplaceFileId] = useState(false)
    const [showAttachmentsReplaceProgress, setShowAttachmentsReplaceProgress] =
        useState(false)
    const [renderAttachmentsList, setRenderAttachmentsList] = useState(true)
    const [comments, setComments] = useState([])

    const handleFetchComments = useQuery({
        queryKey: ['risk-management-fetch-comments'],
        queryFn: async () => {
            return await axios.get(
                `${apiRoutes.API_BASE_URL}/task/${task.id}/comments`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
        },
        enabled: true,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('Failed to fetch comments.'), {
                type: 'error'
            })
        }
    })

    const handleRemoveAttachment = (id) => {
        const index = attachmentsList.findIndex((i) => i.id === id)
        if (index > -1) {
            const oldArray = Array.from(attachmentsList)
            oldArray.splice(index, 1)
            setAttachmentsList(oldArray)
        }
    }

    const handleUploadAttachments = async (fs, token) => {
        try {
            setShowAttachmentsProgress(true)
            const files = []
            const formData = new FormData()

            for (const f in fs.target.files) {
                if (fs.target.files.hasOwnProperty(f)) {
                    files.push(fs.target.files[f])
                }
            }

            files.map((file) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: file.size
                })
                formData.append('files[]', file)
            })

            const result = await axios.post(
                `${apiRoutes.API_BASE_URL}/task/upload_files`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (result.status === 200) {
                const fileData = result.data.data

                const uploadedFiles = fileData.map((fd, i) => {
                    return {
                        id: fd.id,
                        file: fd,
                        name: files[i].name,
                        preview: files[i].preview,
                        formattedSize: files[i].formattedSize
                    }
                })

                setAttachmentsList((oldArray) => [
                    ...oldArray,
                    ...uploadedFiles
                ])
            } else {
                setShowAttachmentsProgress(false)

                toast(
                    t(
                        'Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support'
                    ),
                    {
                        type: 'error'
                    }
                )
                setAttachmentsList([])
            }
        } catch (error) {
            if (process?.env?.NODE_ENV) {
                console.error(
                    'this error only appears in the development environment:\nerror while uploading attachments:',
                    error
                )
            }

            toast(
                t(
                    'Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support'
                ),
                {
                    type: 'error'
                }
            )

            setAttachmentsList([])
        }

        setShowAttachmentsProgress(false)
    }

    const onUploadModalChange = (file, comment) => {
        if (parseInt(comment.created_by.id) !== parseInt(user.id)) {
            toast(t('This file can only be updated by creator'), {
                type: 'error'
            })
        } else {
            const id = file.id
            this.setState({
                ...this.state,
                fileUpdateId: id,
                uploadFileModalActivated: true
            })
        }
    }

    const handleSubmit = async (id, token) => {
        try {
            if (!description.getCurrentContent().hasText()) {
                toast(t('Comment could not be null.'), {
                    type: 'error'
                })
                return false
            }

            const data = {
                task: task.id,
                content: convertToHTML(description.getCurrentContent()),
                attachments: attachmentsList.map((f) => String(f.file.id))
            }

            const result = await axios.post(
                `${apiRoutes.API_BASE_URL}/task_comment/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (result.status === 200) {
                toast(t('Comment added successfully.'), {
                    type: 'success'
                })

                setDescription(EditorState.createEmpty())
                setAttachmentsList([])
                handleFetchComments.refetch()
                setRenderAttachmentsList(false)
            } else {
                toast(t('Failed to submit comment.'), {
                    type: 'error'
                })
            }
        } catch (error) {
            const err = error?.response?.data?.errors?.content[0]

            if (process?.env?.NODE_ENV) {
                console.error(
                    'this error only appears in the development environment:\nerror while submitting comment',
                    error
                )
            }

            toast(t('Failed to submit comment.'), {
                type: 'error'
            })
        }
    }

    const handleDeleteFile = async (fileId, token) => {
        try {
            const result = await axios.delete(
                `${apiRoutes.API_BASE_URL}/task/attachment/${fileId}/delete`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (result.status === 200) {
                toast(t('File deleted successfully.'), {
                    type: 'success'
                })

                setDeleteFileId(null)
                setDeleteFileModalStatus(false)
                handleFetchComments.refetch()
            } else {
                toast(t('Failed to delete file.'), {
                    type: 'error'
                })
            }
        } catch (error) {
            if (process?.env?.NODE_ENV) {
                console.error(
                    'this error only appears in the development environment:\nerror while deleting files:',
                    error
                )
            }

            toast(t('You are not authorized to delete this file'), {
                type: 'error'
            })
        }
    }

    const handleDownloadFile = async (fileId, fileName, token) => {
        try {
            const result = await axios.get(
                `${apiRoutes.API_BASE_URL}/task/attachment/${fileId}/download_file`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (result.status === 200) {
                const blob = new Blob([result.data], { type: '*' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            } else {
                toast(t('Failed to download file.'), {
                    type: 'error'
                })
            }
        } catch (error) {
            if (process?.env?.NODE_ENV) {
                console.error(
                    'this error only appears in the development environment:\nerror while downloading file:',
                    error
                )
            }

            toast(t('Failed to download file.'), {
                type: 'error'
            })
        }
    }

    const handleReplaceFile = async (file, currentFileId, token) => {
        try {
            setShowAttachmentsReplaceProgress(true)

            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: file.size
            })

            const formData = new FormData()
            formData.append('file', file)

            const result = await axios.post(
                `${apiRoutes.API_BASE_URL}/task/attachment/${currentFileId}/update`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (result.status === 200) {
                toast(t('Selected file updated successfully.'), {
                    type: 'success'
                })

                updateAttachmentName(result.data.data.name, currentFileId)
            } else {
                toast(t('Failed to update selected file.'), {
                    type: 'error'
                })
            }
        } catch (error) {
            if (process?.env?.NODE_ENV) {
                console.error(
                    'this error only appears in the development environment:\nerror while updating attachment:',
                    error
                )
            }

            toast(t('Failed to update selected file.'), {
                type: 'error'
            })
        }

        fetchComments()
        setShowAttachmentsReplaceProgress(false)
        setReplaceFileId(null)
        setReplaceFileModalStatus(false)
    }

    useEffect(() => {
        const list = handleFetchComments?.data?.data?.data
        if (list && Array.isArray(list)) {
            setComments(list)
        }
    }, [handleFetchComments.data])


    const loadingComments =
        handleFetchComments.isLoading || handleFetchComments.isFetching

    return (
        <>
            <DeletionFileModal
                fileId={deleteFileId}
                isOpen={deleteFileModalStatus}
                close={() => {
                    setDeleteFileId(null)
                    setDeleteFileModalStatus(false)
                }}
                acceptDeletion={(id) => {
                    handleDeleteFile(id, token)
                }}
            />

            <ReplaceFileModal
                fileId={replaceFileId}
                isOpen={replaceFileModalStatus}
                close={() => {
                    setReplaceFileId(null)
                    setReplaceFileModalStatus(false)
                }}
                acceptDeletion={(id) => {
                    handleDeleteFile(id, token)
                }}
                submit={(file) => {
                    handleReplaceFile(file, replaceFileId, token)
                }}
                progress={showAttachmentsReplaceProgress}
            />

            <AvForm
                className='needs-validation mb-5'
                onValidSubmit={() => handleSubmit(task.id, token)}
            >
                <Row className='mt-5'>
                    <Col sm='12' md='12' lg='12'>
                        <Editor
                            editorState={description}
                            toolbarClassName='toolbarClassName'
                            wrapperClassName='wrapperClassName'
                            editorClassName='editorClassName'
                            onEditorStateChange={(e) => setDescription(e)}
                            placeholder={t('Add a comment...')}
                            editorStyle={{
                                height: '10rem',
                                overflowY: 'scroll',
                                borderColor: '#c9c9c9'
                            }}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'list',
                                    'textAlign',
                                    'colorPicker',
                                    'link',
                                    'remove',
                                    'history'
                                ],
                                inline: {
                                    options: [
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strikethrough',
                                        'monospace'
                                    ],
                                    bold: {
                                        className: 'bordered-option-classname'
                                    },
                                    italic: {
                                        className: 'bordered-option-classname'
                                    },
                                    underline: {
                                        className: 'bordered-option-classname'
                                    },
                                    strikethrough: {
                                        className: 'bordered-option-classname'
                                    },
                                    code: {
                                        className: 'bordered-option-classname'
                                    }
                                },
                                blockType: {
                                    className: 'bordered-option-classname'
                                },
                                fontSize: {
                                    className: 'bordered-option-classname'
                                }
                            }}
                            toolbarCustomButtons={[
                                <div
                                    key='1'
                                    style={{
                                        textAlign: 'right',
                                        flex: 'auto'
                                    }}
                                >
                                    <Button
                                        color='secondary'
                                        disabled={isReadOnly}
                                        outline
                                        onClick={(_e) => {
                                            setShowAttachmentsList(
                                                !showAttachmentsList
                                            )
                                        }}
                                    >
                                        <CgLink />
                                        {' ' + t('Attach')}
                                    </Button>
                                    <Button
                                        color='primary'
                                        outline
                                        className='m-1'
                                        disabled={isReadOnly}
                                    >
                                        <FaRegComment />
                                        {' ' + t('Add Comment')}
                                    </Button>
                                </div>
                            ]}
                            readOnly={isReadOnly}
                        />
                    </Col>
                </Row>

                {renderAttachmentsList ? (
                    <Row hidden={!showAttachmentsList}>
                        <Col>
                            <UploadedFiles
                                uploadedFiles={attachmentsList}
                                handleAcceptedFiles={(e) =>
                                    handleUploadAttachments(e, token)
                                }
                                showProg={showAttachmentsProgress}
                                handleClickDeleteFiles={(e) =>
                                    handleRemoveAttachment(e.id)
                                }
                            />
                        </Col>
                    </Row>
                ) : null}
            </AvForm>

            {!loadingComments && (
                <Container fluid>
                    <Row>
                        <Col sm='12' md='12' lg='12'>
                            {/* tabs buttons */}
                            <Nav className='mb-2' tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === 'comments'
                                        })}
                                        onClick={() => {
                                            setActiveTab('comments')
                                        }}
                                    >
                                        {t('Comments')}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === 'attachements'
                                        })}
                                        onClick={() => {
                                            setActiveTab('attachements')
                                        }}
                                    >
                                        {t('Attachments')}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            {/* tabs contents */}
                            <TabContent
                                activeTab={activeTab}
                                className='mb-5 area'
                            >
                                {/* comments */}
                                <TabPane tabId='comments'>
                                    {comments && comments.length > 0 ? (
                                        comments
                                            .sort((a, b) =>
                                                a.created_at < b.created_at
                                                    ? 1
                                                    : -1
                                            )
                                            .map((comment) => {
                                                const fullName =
                                                    comment.created_by.full_name
                                                return (
                                                    <Row
                                                        style={{
                                                            borderBottom:
                                                                '1px solid #c9c9c9'
                                                        }}
                                                        className='m-2'
                                                        key={comment.id}
                                                    >
                                                        <Col
                                                            sm='12'
                                                            md='2'
                                                            lg='2'
                                                        >
                                                            <Label
                                                                className={
                                                                    fullName ===
                                                                    task?.owner
                                                                        ? 'badge bg-info text-white rounded p-2 text-black'
                                                                        : fullName ===
                                                                          task?.manager
                                                                        ? 'badge bg-success text-white rounded p-2 text-black'
                                                                        : 'badge bg-warning text-white rounded p-2 text-black'
                                                                }
                                                            >
                                                                {fullName}
                                                            </Label>
                                                        </Col>

                                                        <Col
                                                            sm='12'
                                                            md='4'
                                                            lg='6'
                                                        >
                                                            <div
                                                                className='preview'
                                                                dangerouslySetInnerHTML={createMarkup(
                                                                    comment.content
                                                                )}
                                                            />
                                                        </Col>

                                                        <Col
                                                            sm='12'
                                                            md='2'
                                                            lg='2'
                                                        >
                                                            <p>
                                                                {dateUtils.convertTimestamp(
                                                                    comment.created_at
                                                                )}
                                                            </p>
                                                        </Col>

                                                        <Col
                                                            sm='12'
                                                            md='2'
                                                            lg='2'
                                                            className='mb-2'
                                                            hidden={
                                                                comment
                                                                    .attachments
                                                                    .length ===
                                                                0
                                                            }
                                                        >
                                                            <DownloadAttachments
                                                                files={
                                                                    comment.attachments
                                                                }
                                                                commentSection
                                                                onUploadModalChange={
                                                                    onUploadModalChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                    ) : (
                                        <div
                                            className='alert alert-warning'
                                            role='alert'
                                        >
                                            <p style={{ textAlign: 'center' }}>
                                                {t('This list is empty')}
                                            </p>
                                        </div>
                                    )}
                                </TabPane>

                                {/* attachments */}
                                <TabPane tabId='attachements'>
                                    <Row
                                        style={{
                                            overflowY: 'scroll',
                                            overflowX: 'scroll',
                                            borderBottom: '1px solid #c9c9c9',
                                            maxHeight: '20rem'
                                        }}
                                        className='mt-3'
                                    >
                                        <Col
                                            sm='12'
                                            md='12'
                                            lg='12'
                                            style={{
                                                height: '20rem',
                                                overflowY: 'scroll',
                                                overflowX: 'scroll'
                                            }}
                                        >
                                            {comments
                                                ? comments
                                                      .sort((a, b) =>
                                                          a.created_at <
                                                          b.created_at
                                                              ? 1
                                                              : -1
                                                      )
                                                      .map((comment) => {
                                                          if (
                                                              comment &&
                                                              comment.attachments &&
                                                              comment
                                                                  .attachments
                                                                  .length > 0
                                                          ) {
                                                              const fullName =
                                                                  comment
                                                                      .created_by
                                                                      .full_name
                                                              return (
                                                                  <Row
                                                                      className='p-1'
                                                                      hidden={
                                                                          !comments.length >
                                                                          0
                                                                      }
                                                                      key={
                                                                          comment.id
                                                                      }
                                                                  >
                                                                      <Col
                                                                          sm='12'
                                                                          md='2'
                                                                          lg='2'
                                                                      >
                                                                          <Label
                                                                              style={{
                                                                                  width: '100%',
                                                                                  whiteSpace:
                                                                                      'nowrap',
                                                                                  overflow:
                                                                                      'hidden',
                                                                                  textOverflow:
                                                                                      'ellipsis'
                                                                              }}
                                                                              className={
                                                                                  fullName ===
                                                                                  task?.owner
                                                                                      ? 'badge bg-info text-white rounded p-2'
                                                                                      : fullName ===
                                                                                        task?.manager
                                                                                      ? 'badge bg-success text-white rounded p-2'
                                                                                      : 'badge bg-warning text-white rounded p-2'
                                                                              }
                                                                          >
                                                                              {
                                                                                  fullName
                                                                              }
                                                                          </Label>
                                                                      </Col>

                                                                      <Col
                                                                          sm='12'
                                                                          md='8'
                                                                          lg='8'
                                                                      >
                                                                          <Row>
                                                                              <ul>
                                                                                  {comment.attachments.map(
                                                                                      (
                                                                                          item,
                                                                                          index
                                                                                      ) => {
                                                                                          return (
                                                                                              <li
                                                                                                  // style={{ width: "48rem" }}
                                                                                                  key={
                                                                                                      item.id
                                                                                                  }
                                                                                              >
                                                                                                  <Row
                                                                                                      style={{
                                                                                                          backgroundColor:
                                                                                                              index %
                                                                                                                  2 ===
                                                                                                              0
                                                                                                                  ? '#dee2e6'
                                                                                                                  : 'white'
                                                                                                      }}
                                                                                                  >
                                                                                                      <Col
                                                                                                          sm='12'
                                                                                                          md='7'
                                                                                                          lg='7'
                                                                                                      >
                                                                                                          <span
                                                                                                              className='text-primary'
                                                                                                              onClick={() => {
                                                                                                                  handleDownloadFile(
                                                                                                                      item.id,
                                                                                                                      item.origin_name,
                                                                                                                      token
                                                                                                                  )
                                                                                                              }}
                                                                                                              style={{
                                                                                                                  cursor: 'pointer',
                                                                                                                  userSelect:
                                                                                                                      'none',
                                                                                                                  fontSize: 14
                                                                                                              }}
                                                                                                          >
                                                                                                              {t(
                                                                                                                  item.origin_name
                                                                                                              )}
                                                                                                          </span>
                                                                                                      </Col>
                                                                                                      <Col
                                                                                                          sm='12'
                                                                                                          md='5'
                                                                                                          lg='5'
                                                                                                      >
                                                                                                          <span
                                                                                                              style={{
                                                                                                                  cursor: 'pointer'
                                                                                                              }}
                                                                                                              className='text-primary'
                                                                                                              onClick={() => {
                                                                                                                  setReplaceFileId(
                                                                                                                      item.id
                                                                                                                  )
                                                                                                                  setReplaceFileModalStatus(
                                                                                                                      true
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              <span
                                                                                                                  style={{
                                                                                                                      cursor: 'pointer'
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {' '}
                                                                                                                  {t(
                                                                                                                      'Update this version'
                                                                                                                  )}
                                                                                                              </span>
                                                                                                          </span>
                                                                                                          <span>
                                                                                                              {
                                                                                                                  ' | '
                                                                                                              }
                                                                                                          </span>
                                                                                                          <span
                                                                                                              style={{
                                                                                                                  cursor: 'pointer'
                                                                                                              }}
                                                                                                              className='text-danger'
                                                                                                              onClick={() => {
                                                                                                                  setDeleteFileId(
                                                                                                                      item.id
                                                                                                                  )
                                                                                                                  setDeleteFileModalStatus(
                                                                                                                      true
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              {t(
                                                                                                                  'Delete'
                                                                                                              )}
                                                                                                          </span>
                                                                                                      </Col>
                                                                                                  </Row>
                                                                                              </li>
                                                                                          )
                                                                                      }
                                                                                  )}
                                                                              </ul>
                                                                          </Row>
                                                                      </Col>
                                                                      <Col
                                                                          sm='12'
                                                                          md='2'
                                                                          lg='2'
                                                                      >
                                                                          {dateUtils.convertTimestamp(
                                                                              comment.created_at
                                                                          )}
                                                                      </Col>
                                                                  </Row>
                                                              )
                                                          }
                                                      })
                                                : null}

                                            {task?.description_attachments &&
                                            task?.description_attachments
                                                .length > 0 ? (
                                                <Row className='p-1'>
                                                    <Col sm='12' md='2' lg='2'>
                                                        <Label
                                                            style={{
                                                                width: '100%',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis'
                                                            }}
                                                            className='badge bg-success text-white rounded p-2'
                                                        >
                                                            {task?.manager}
                                                        </Label>
                                                    </Col>

                                                    <Col sm='12' md='8' lg='8'>
                                                        <Row>
                                                            <ul>
                                                                {task?.description_attachments.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                <Row
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            index %
                                                                                                2 ===
                                                                                            0
                                                                                                ? '#dee2e6'
                                                                                                : 'white'
                                                                                    }}
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                >
                                                                                    <Col
                                                                                        sm='12'
                                                                                        md='7'
                                                                                        lg='7'
                                                                                    >
                                                                                        <span
                                                                                            className='text-primary'
                                                                                            onClick={() => {
                                                                                                handleDownloadFile(
                                                                                                    item.id,
                                                                                                    item.origin_name,
                                                                                                    token
                                                                                                )
                                                                                            }}
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                userSelect:
                                                                                                    'none',
                                                                                                fontSize: 14
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                item.origin_name
                                                                                            )}
                                                                                        </span>
                                                                                    </Col>
                                                                                    <Col
                                                                                        sm='12'
                                                                                        md='5'
                                                                                        lg='5'
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            className='text-primary'
                                                                                            onClick={() => {
                                                                                                setReplaceFileId(
                                                                                                    item.id
                                                                                                )
                                                                                                setReplaceFileModalStatus(
                                                                                                    true
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                            >
                                                                                                {' '}
                                                                                                {t(
                                                                                                    'Update this version'
                                                                                                )}
                                                                                            </span>
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                ' | '
                                                                                            }
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            className='text-danger'
                                                                                            onClick={() => {
                                                                                                setDeleteFileId(
                                                                                                    item.id
                                                                                                )
                                                                                                setDeleteFileModalStatus(
                                                                                                    true
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            {t(
                                                                                                'Delete'
                                                                                            )}
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                            </li>
                                                                        )
                                                                    }
                                                                )}
                                                            </ul>
                                                        </Row>
                                                    </Col>
                                                    <Col sm='12' md='2' lg='2'>
                                                        {dateUtils.convertTimestampToOnlyDate(
                                                            task.created_at
                                                        )}
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization, Modules } = state
    return {
        user: state.Login.user,
        token,
        organization: Organization,
        Modules
    }
}

export default withNamespaces()(connect(mapStatetoProps, {})(Comments))
