import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation";
import { Button, ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import CommonService from "src/modules/3rd-party-management/apis/common/CommonService";
import { toast } from "react-toastify";
import UserUtils from "src/services/utils/UserUtils";

const OwnCompanyContactModal = ({
    t,
    displayModal,
    toggleModal,
    handleSubmit,
    memorizedAnalystList,
}) => {
    const [ selectedAnalyst, setSelectedAnalyst ] = useState(); 


    return (
        <Modal 
            isOpen={ displayModal } 
            backdrop="static" 
            keyboard={ false } 
            size='md'
            className="contact-person-creation-modal">
            
            <AvForm className="needs-validation m-2"
            onValidSubmit={ (e, values) => {
                handleSubmit(e, values)
            } }>
                <ModalHeader toggle={ () => toggleModal(false) }>
                    {t("Add A New Responsible Person")}
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <Label for="responsible-person-language">
                                {t("Select user")}
                            </Label>
                            <Select
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                id='responsible-person-language'
                                options={ memorizedAnalystList }
                                onChange={(e) => setSelectedAnalyst(e)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                
                            />
                            <AvField
                                name={`responsible_person[user]`}
                                type="hidden"
                                errorMessage={t("This field cannot be blank")}
                                validate={{
                                    required: { value: true },
                                }}
                                value={ selectedAnalyst?.value }
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={ () => toggleModal(false) } color="primary" type="button" outline>
                        { t('Cancel') }
                    </Button>

                    <Button color="primary" type="submit" size="md">
                        { t('Add') }
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

const OwnCompany = ({
    t,
    setCompanyDetailsTabCompleted,
    defaultValues,
    goToPrevTab
}) => {
    const userUtils = new UserUtils();
    const [stepIsSkippable, setStepIsSkippable] = useState(true);
    
    const [ contacts, setContacts ] = useState((defaultValues && Array.isArray(defaultValues)) ? defaultValues.filter((contact) => {
        return !contact.isDeleted
    }) : []);

    const [ showCreationModal, setShowCreationModal ] = useState(false);

    const {
        data: analystList,
        isFetching: fetchingAnalysts,
        isLoading: loadingAnalysts,
        refetch: fetchAnalystsList
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-analyst-list'],
		queryFn: async () => {
			const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: () => {
			toast(t('An error occurred while fetching analysts list.'), {
				type: 'error',
			});
		},
	});

    const memorizedAnalystList = useMemo(() => {
        return (analystList || []).map((analyst) => {
            return {
                value: analyst.id,
                label: `${analyst.first_name} ${analyst.last_name}`
            }
        });
    }, [ analystList ]);

    useEffect(() => {
        setStepIsSkippable(contacts.filter((contact) => {
            return !contact.isDeleted && !contact.isTemp
        }).length <= 0);
    }, [ contacts ]);

    useEffect(() => {
        fetchAnalystsList(); 
    }, []);

    return (
        <>
            <Card className="wizard-steps-content-container">
                <CardBody className="p-0">
                    <p className="sub-header medium">
                        {t("Responsible Person")}
                    </p>

                    <Row>
                        <Col>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('Full Name')}</th>
                                        <th>{t('Email')}</th>
                                        <th>{t('Department')}</th>
                                        <th>{t('User Role')}</th>
                                        <th>{t('Phone Number')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts.map((contact, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {`${contact.salutation ? (contact.salutation + ' ') : ''}${contact.firstName} ${contact.lastName}`}
                                                </td>
                                                <td>{contact.email}</td>
                                                <td>{contact.department}</td>
                                                <td>{userUtils.gerUserRole(contact.role)}</td>
                                                <td>
                                                    {contact.phoneNumber && (
                                                        <LocalizedPhoneInput
                                                            readOnly
                                                            inputClass="w-100"
                                                            country={ "us" }
                                                            disableInitialCountryGuess={ false }
                                                            disableCountryGuess={ false }
                                                            value={contact.phoneNumber}
                                                            className="background-transparent"
                                                        />
                                                    )}
                                                </td>
                                                <td>
                                                    <Button
                                                        color="danger"
                                                        onClick={() => setContacts((currentState) => {
                                                            const newState = [...currentState];
                                                            newState.splice(index, 1);
                                                            return newState;
                                                        })}
                                                        outline
                                                    >
                                                        <span>{t("Delete Person")} </span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    {!contacts.length && (
                                        <tr>
                                            <td colSpan='7'>
                                                <div className="alert alert-warning text-center">
                                                    <p className="m-0">{t('This list is empty')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md="10"></Col>
                        <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                disabled={fetchingAnalysts || loadingAnalysts}
                                color="primary" 
                                outline
                                onClick={() => setShowCreationModal(true)}
                            >
                                {" + " + t("Add A Person")}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row className="mt-2 mb-4">
                <Col sm='12' className="d-flex justify-content-between align-items-center">
                    <Button color="primary" onClick={() => {
                        goToPrevTab(contacts)
                    }} outline>
                        {t("Back")}
                    </Button>

                    <Button color="primary" onClick={() => {
                        setCompanyDetailsTabCompleted(contacts)
                    }}>
                        {stepIsSkippable ? (
                            <>
                                {t('Skip')}
                            </>
                        ) : (
                            <>
                                {t('Next')}
                            </>
                        )}
                    </Button>
                </Col>
            </Row>

            <OwnCompanyContactModal 
                t={t}
                displayModal={showCreationModal}
                toggleModal={() => setShowCreationModal(!showCreationModal)}
                handleSubmit={(e, values) => {
                    setContacts((currentState) => {
                        const addedUser = analystList.find((user) => user.id === values.responsible_person.user)
                        return [...currentState, {
                            id: addedUser.id,
                            salutation: addedUser.salutation,
                            role: addedUser.role,
                            firstName: addedUser.first_name,
                            lastName: addedUser.last_name,
                            email: addedUser?.email,
                            phoneNumber: addedUser?.phoneNumber
                        }];
                    });

                    setShowCreationModal(false);
                }}
                memorizedAnalystList={memorizedAnalystList}
            />
        </>
    )
}

const Creditor = ({
    t,
    organization,
    setCompanyDetailsTabCompleted,
    defaultValues,
    goToPrevTab
}) => {
    const formRef = useRef();

    const defaultContacts = (defaultValues && Array.isArray(defaultValues)) ? defaultValues.filter((contact) => {
        return !contact.isDeleted
    }).map((contact) => {
        return {
            ...contact,
            emailValidationStatus: 'valid'
        }
    }) : [];
    
    const [ contacts, setContacts ] = useState(defaultContacts.length > 0 ? defaultContacts : [{
        isTemp: true
    }]);

    const [ stepIsSkippable, setStepIsSkippable ] = useState(!defaultContacts.length);

    const [ titleDrops, setTitleDrops ] = useState([]);

    const {
        mutate      :   checkUserExistenceMutation
    } = useMutation({
        mutationFn: async (payload) => {
            const service = CommonService.getInstance();

            return await service.checkUserExistence(payload);
        }
    });

    useEffect(() => {
        setContacts((currentState) => {
            return [
                ...currentState
            ].map((contact) => {
                if(contact.language){
                    return {
                        ...contact,
                        language: {
                            ...contact.language,
                            label: t(contact.language.baseLabel)
                        }
                    }
                }

                return contact;
            })
        });
    }, [ t ]);

    const memoizedLanguagesList = useMemo(() => {
        return (organization?.languages || []).map((lng) => {
            return {
                value: lng.language?.id,
                baseLabel: lng.name,
                label: t(lng.name),
                code : lng.language_code,
            }
        });
    }, [ organization?.languages, t ]);

    const getValuesToSave = () => {
        const currentState = formRef.current.getValues();

        return (currentState?.contacts || []).filter((contact) => {
            return !!contact.email
        }).map((contact) => {
            return {
                ...contact,
                title: contact.title || "Mr."
            }
        })
    }

    const formOnChange = () => {
        const values = formRef.current.getValues();

        const result = (values.contacts || []).filter(((contact) => {
            const keys = Object.keys(contact);
            
            let found = false;

            for(let i = 0; i < keys.length; i++){
                if(contact[keys[i]]){
                    found = true;
                    break;
                }
            }

            return found
        }));

        setStepIsSkippable(!result.length)
    }

    const formEvents = {
        onChange: stepIsSkippable ? formOnChange : undefined
    }

    const contactsToDisplay = contacts.filter((contact) => !contact.isDeleted);

    return (
        <AvForm
            className="needs-validation"
            {...formEvents}
            onValidSubmit={() => {
                if(!contacts.filter((c) => c.emailValidationStatus === 'invalid' || c.emailValidationStatus === 'in_process').length){
                    return setCompanyDetailsTabCompleted(getValuesToSave());
                }
            }}
            autocomplete="off"
            ref={formRef}
            id="contact_person">

                <Card className="wizard-steps-content-container">
                    <CardBody className="p-0">
                        <p className="sub-header medium">
                            {t("Contact Person")}
                        </p>

                        {contactsToDisplay.length > 0 ? (
                            <>
                                {contacts.map((contact, i) => {
                                    return (
                                        <Row hidden={contact.isDeleted} className="mb-4" key={i}>
                                            <Row className="mb-4">
                                                <Col md="4" sm="4">
                                                    <Label>
                                                        {t("First Name")}: 
                                                    </Label>

                                                    <AvGroup>
                                                        <div className="d-flex flex-wrap position-relative">
                                                            <ButtonDropdown 
                                                                className="position-absolute" 
                                                                isOpen={ titleDrops[i] } 
                                                                toggle={() => setTitleDrops((drops) => {
                                                                    drops[i] = !drops[i];

                                                                    return [...drops];
                                                                })} 
                                                                style={{
                                                                    left: "0px",
                                                                    top: "0px",
                                                                    width: "94px",
                                                                    height: "34px"
                                                                }}
                                                            >
                                                                <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid #b5b5b5' }}>
                                                                    <span style={{marginRight: '8px'}}>{ t(contact.title || 'Mr.') }</span>
                                                                </DropdownToggle>

                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        setContacts((currentState) => {
                                                                            const newState = [
                                                                                ...currentState
                                                                            ];
                            
                                                                            newState[i] = {
                                                                                ...contact,
                                                                                title: 'Mr.'
                                                                            };
                            
                                                                            return newState;
                                                                        });
                                                                    }}>
                                                                        { t('Mr.') }
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => { 
                                                                        setContacts((currentState) => {
                                                                            const newState = [
                                                                                ...currentState
                                                                            ];
                            
                                                                            newState[i] = {
                                                                                ...contact,
                                                                                title: 'Mrs.'
                                                                            };
                            
                                                                            return newState;
                                                                        });
                                                                    }}>
                                                                        { t('Mrs.') }
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        setContacts((currentState) => {
                                                                            const newState = [
                                                                                ...currentState
                                                                            ];
                            
                                                                            newState[i] = {
                                                                                ...contact,
                                                                                title: 'Mx.'
                                                                            };
                            
                                                                            return newState;
                                                                        });
                                                                    }}>
                                                                        { t('Mx.') }
                                                                    </DropdownItem>
                                                                </DropdownMenu>

                                                                <AvField
                                                                    name={`contacts[${i}][title]`}
                                                                    type="hidden"
                                                                    className="form-control"
                                                                    validate={{
                                                                        required: { value: false }
                                                                    }}
                                                                    value={contact.title}
                                                                />
                                                            </ButtonDropdown>

                                                            <AvInput 
                                                                name={`contacts[${i}][firstName]`}
                                                                autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                                required={!stepIsSkippable}
                                                                type="text"
                                                                className="form-control"
                                                                style={{
                                                                    paddingLeft: '98px',
                                                                    flexBasis : '100%'
                                                                }}
                                                                defaultValue={contact.firstName}
                                                            />

                                                            <AvFeedback style={{
                                                                flexBasis : '100%'
                                                            }}>{t("This field cannot be blank")}</AvFeedback>
                                                        </div>
                                                    </AvGroup>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label>
                                                        {t("Last Name")}: 
                                                    </Label>
                                                    <AvField
                                                        name={`contacts[${i}][lastName]`}
                                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: !stepIsSkippable },
                                                        }}
                                                        defaultValue={contact.lastName}
                                                    />
                                                </Col>

                                                {/* Position */}
                                                <Col md="4" sm="12">
                                                    <Label>
                                                        {t("Position")}: 
                                                    </Label>
                                                    <AvField
                                                        name={`contacts[${i}][position]`}
                                                        autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                        type="text"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: !stepIsSkippable },
                                                        }}
                                                        defaultValue={contact.position}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Col md="4" sm="12">
                                                    <Label>{t("Language")}: </Label>
                                                    <Select
                                                        placeholder={t("Select") + "..."}
                                                        classNamePrefix="select2-selection"
                                                        options={ memoizedLanguagesList }
                                                        menuPortalTarget={document.body}
                                                        onChange={(e) => {
                                                            setContacts((currentState) => {
                                                                const newState = [
                                                                    ...currentState
                                                                ];
                
                                                                newState[i] = {
                                                                    ...contact,
                                                                    language: e
                                                                };
                
                                                                return newState;
                                                            });
                                                        }}
                                                        value={contact?.language}
                                                    />
                                                    <AvField
                                                        name={`contacts[${i}][language]`}
                                                        placeholder=""
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: !stepIsSkippable },
                                                        }}
                                                        value={contact?.language}
                                                    />
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label>{t("Email")}: </Label>
                                                    <AvGroup>
                                                        {!contact.isDeleted && (
                                                            <AvField
                                                                name={`contacts[${i}][email]`}
                                                                autocomplete="invalid-type" // this is a trick to hide autocomplete in some browsers like edge
                                                                type="email"
                                                                className="form-control"
                                                                validate={{
                                                                    required: {
                                                                        value: !stepIsSkippable,
                                                                        errorMessage: t("This field cannot be blank"),
                                                                    },
                                                                    email: { errorMessage: t("Your email is invalid") }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if(!e.target.value){
                                                                        setContacts((currentContacts) => {
                                                                            const newContacts = [...currentContacts];
                                                                            newContacts[i] = { ...newContacts[i], emailValidationStatus: null };
                                                                            return newContacts;
                                                                        });
                                                                        return;
                                                                    }

                                                                    if(contact.emailValidationStatus !== 'in_process'){
                                                                        setContacts((currentContacts) => {
                                                                            const newContacts = [...currentContacts];
                                                                            newContacts[i] = { ...newContacts[i], emailValidationStatus: 'in_process' };
                                                                            return newContacts;
                                                                        });

                                                                        checkUserExistenceMutation({
                                                                            email : e.target.value
                                                                        }, {
                                                                            onSuccess: () => {
                                                                                setContacts((currentContacts) => {
                                                                                    const newContacts = [...currentContacts];
                                                                                    newContacts[i] = { ...newContacts[i], emailValidationStatus: 'valid' };
                                                                                    return newContacts;
                                                                                });
                                                                            },
                                                                            onError: () => {
                                                                                setContacts((currentContacts) => {
                                                                                    const newContacts = [...currentContacts];
                                                                                    newContacts[i] = { ...newContacts[i], emailValidationStatus: 'invalid' };
                                                                                    return newContacts;
                                                                                });
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                disabled={contact.emailValidationStatus === 'in_process'}
                                                                defaultValue={contact.email}
                                                            />
                                                        )}

                                                        {contact.emailValidationStatus === 'invalid' && (
                                                            <AvFeedback valid={false} className='d-block'>
                                                                {t('Email already exists')}
                                                            </AvFeedback>
                                                        )}
                                                    </AvGroup>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label>{t("Phone Number")}: </Label>
                                                    <LocalizedPhoneInput
                                                        country={ "us" }
                                                        disableInitialCountryGuess={ false }
                                                        disableCountryGuess={ false }
                                                        inputClass="w-100"
                                                        onChange={ (e) => {
                                                            setContacts((currentContacts) => {
                                                                const newContacts = [...currentContacts];
                                                                newContacts[i] = { ...newContacts[i], phoneNumber: e };
                                                                return newContacts;
                                                            });
                                                        }}
                                                        value={ contact?.phoneNumber }
                                                    />
                                                    <AvField
                                                        name={`contacts[${i}][phoneNumber]`}
                                                        type="hidden"
                                                        errorMessage={t("This field cannot be blank")}
                                                        validate={{
                                                            required: { value: !stepIsSkippable },
                                                        }}
                                                        value={ contact?.phoneNumber }
                                                    />
                                                </Col>
                                                
                                                <Col
                                                    md="2"
                                                    sm="12"
                                                    className="pt-4 mt-2"
                                                >
                                                    <Button
                                                        color="danger"
                                                        onClick={() => {
                                                            if(formRef.current){
                                                                const inputs = formRef.current.getInputs();
                                                                /**
                                                                 * because of onBlur event definition unregister function not works on email
                                                                 * so we have to check this field with isDelete property
                                                                 */
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][email]`]);
                                                                
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][title]`]);
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][firstName]`]);
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][lastName]`]);
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][position]`]);
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][language]`]);
                                                                formRef.current.unregisterInput(inputs[`contacts[${i}][phoneNumber]`]);
                                                            }

                                                            setContacts((currentState) => {
                                                                const newState = [...currentState];
                                                                newState[i].isDeleted = true;
                                                                return newState;
                                                            });
                                                        }}
                                                        outline
                                                    >
                                                        <span>{t("Delete Contact")} </span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="alert alert-warning text-center">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}

                        <Row className="mt-4">
                            <Col md="10"></Col>
                            <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setContacts((currentState) => {
                                            return [
                                                ...currentState,
                                                {}
                                            ]
                                        })
                                    }} outline>
                                    {" + " + t("Add A Contact Person")}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Row className="mt-2 mb-4">
                    <Col sm='12' className="d-flex justify-content-between align-items-center">
                        <Button type="button" color="primary" onClick={() => {
                            goToPrevTab(getValuesToSave())
                        }} outline>
                            {t("Back")}
                        </Button>

                        <Button color="primary" type="submit" disabled={contacts.filter((c) => c.emailValidationStatus === 'in_process').length > 0}>
                            {stepIsSkippable ? (
                                <>
                                    {t('Skip')}
                                </>
                            ) : (
                                <>
                                    {t('Next')}
                                </>
                            )}
                        </Button>
                    </Col>
                </Row>

        </AvForm>
    )
}

const Contacts = ({
    t,
    supplierType,
    ...elseProps
}) => {

    if(supplierType === 'own'){
        return (
            <OwnCompany 
                t={t}
                {...elseProps}
            />
        )
    }

    return (
        <Creditor 
            t={t}
            {...elseProps}
        />
    );
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    return {
        organization  : Organization
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )
(Contacts));
