import * as React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { memo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'

// components
import Tabs from './components/tabs'
import Overview from './components/overview'
import AllRisks from './components/all-risks'
import MitigationTasks from './components/mitigation-tasks'
import MyRisks from './components/my-risks'

const RiskManagement = () => {
    const location = useLocation()

    const renderContent = () => {
        const searchParams = new URLSearchParams(location.search)

        let result = null

        switch (searchParams.get('tab')) {
            case 'overview':
                result = <Overview />
                break
            case 'all-risks':
                result = <AllRisks />
                break
            case 'my-risks':
                result = <MyRisks />
                break
            case 'mitigation-tasks':
                result = <MitigationTasks />
                break
            default:
                break
        }

        return (
            <React.Fragment>
                <Tabs />

                {result}
            </React.Fragment>
        )
    }

    return (
        <div className='page-content risk-management-module'>
            <Container fluid>
                <Row>
                    <Col lg='12' className='mb-4'>
                        <div className='details-content-wrapper'>
                            {renderContent()}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization } = state
    return {
        user: state.Login.user,
        token: token,
        organization: Organization
    }
}

export default withRouter(connect(mapStatetoProps, {})(memo(RiskManagement)))
