import { withNamespaces } from "react-i18next";
import { useState, useMemo } from "react";
import { Alert, Button, Col, Row, Spinner, Table } from "reactstrap";
import NewLegalModal from "./components/NewLegalModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CommonService from "src/modules/3rd-party-management/apis/common/CommonService";
import DateUtils from "src/services/utils/DateUtils";
import { STANDARD_DATE_FORMAT } from "src/components/constants";
import SupplierLegalEntityService from "src/modules/3rd-party-management/apis/admin/SupplierLegalEntityService";
import * as CurrencyFormat from 'react-currency-format';

const LegalEntites = ({
    t,
    supplierId,
    readOnly
}) => {
    const [ showNewLegalModal, setShowNewLegalModal ] = useState(false);
    const [ deletingItemsList, setDeletingItemsList ] = useState([]);
    
    const dateUtils = new DateUtils();

    const {
        data            :   legalEntitiesData,
        isFetching      :   legalEntitiesListIsLoading
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entities-list'],
        queryFn: async () => {
            const service = CommonService.getInstance();

            return await service.fetchLegalEntities();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const {
        data            :   supplierEntities,
        isFetching      :   supplierEntitiesAreFetching,
        refetch         :   refetchSupplierLegals
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-supplier-entities-list', supplierId],
        queryFn: async () => {
            const service = SupplierLegalEntityService.getInstance();

            return await service.fetchSupplierEntities(supplierId);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const {
        mutate: removeConnectedLegal
    } = useMutation({
        mutationFn: async (legalId) => {
            const service = SupplierLegalEntityService.getInstance();

            return await service.removeConnectedLegal(legalId);
        },
        onError: () => {
            toast(t("An error occurred while deleting legal entity."), {
                type: "error",
            });
        },
        onSuccess: () => {
            refetchSupplierLegals()
        }
    });

    const entitiesOptions = useMemo(() => {
        return (legalEntitiesData?.legalEntities || []).map((legal) => {
            return {
                value   :   legal.id,
                label   :   legal.title
            }
        });
    }, [ legalEntitiesData ]);

    const renderModals = () => {
        if(readOnly) return null;
        
        return (
            <NewLegalModal 
                toggle={ () => setShowNewLegalModal(!showNewLegalModal) } 
                show={ showNewLegalModal } 
                supplierId={ supplierId }
                entitiesOptions={entitiesOptions}
                onSuccess={ () => refetchSupplierLegals() }
            />
        )
    }

    return (
        <Row>
            <Col sm='12' className="mb-4">
                <div className="table-responsive products-list">
                    <Table borderless>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    { t('Full Name') }
                                </th>
                                <th>
                                    { t('Mandate ID') }
                                </th>
                                <th>
                                    { t('Creditor Number') }
                                </th>
                                <th>
                                    { t('Financial Year') }
                                </th>
                                <th>
                                    { t('Total Revenue') }
                                </th>
                                {!readOnly && (
                                    <th>
                                        { t('Action') }
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {!supplierEntitiesAreFetching && !supplierEntities?.length && (
                                <tr>
                                    <td colSpan='8'>
                                        <Alert className='text-center' color='warning'>
                                            { t('This list is empty') }
                                        </Alert>
                                    </td>
                                </tr>
                            )}
                            
                            {
                                (supplierEntities || []).map((legal, index) => {
                                    return (
                                        <tr key={ index }>
                                            <td>
                                                { index + 1 }
                                            </td>

                                            <td>
                                                { legal.name }
                                            </td>

                                            <td>
                                                { legal.mandateId }
                                            </td>

                                            <td>
                                                { legal.creditorNumber }
                                            </td>

                                            <td>
                                                { dateUtils.convertDateToDate(legal.financialEndYear, STANDARD_DATE_FORMAT) }
                                            </td>

                                            <td>
                                                <CurrencyFormat 
                                                    value={legal.totalRevenue}
                                                    decimalScale={2}
                                                    displayType='text'
                                                    thousandSeparator={true} 
                                                    prefix={'€ '} 
                                                />
                                            </td>

                                            {!readOnly && (
                                                <td>
                                                    <div className="d-flex justify-content-start">
                                                        {
                                                            <Button 
                                                                onClick={() => {
                                                                    setDeletingItemsList((currentState) => {
                                                                        return [...currentState, legal.id];
                                                                    });

                                                                    removeConnectedLegal(legal.id, {
                                                                        onSettled: () => {
                                                                            setDeletingItemsList((currentState) => {
                                                                                const newState = [...currentState];

                                                                                const index = newState.findIndex((item) => item === legal.id);

                                                                                if(index >= 0){
                                                                                    newState.splice(index, 1)
                                                                                }

                                                                                return newState;
                                                                            });
                                                                        }
                                                                    });
                                                                }}
                                                                outline 
                                                                color="danger" 
                                                                size="md" 
                                                                disabled={deletingItemsList.includes(legal.id)}
                                                                className="border-0">
                                                                    {deletingItemsList.includes(legal.id) ? (
                                                                        <Spinner animation="border" variant="danger" size="sm"/>
                                                                    ) : (
                                                                        <i className="ri-delete-bin-line font-size-20"></i>
                                                                    )}
                                                            </Button>
                                                        }
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>

            {!readOnly && (
                <Col sm="12" className="mb-4 d-flex justify-content-end">
                    <Button type="button" 
                        color="primary" 
                        outline
                        className="me-2"
                        disabled={legalEntitiesListIsLoading}
                        onClick={() => setShowNewLegalModal(true)}
                    >
                        {" + " + t("Add Entity")}
                    </Button>
                </Col>
            )}

            { renderModals() }
        </Row>
    );
};

export default withNamespaces()(LegalEntites);