import { useMemo, Suspense } from 'react'
import { withNamespaces } from 'react-i18next'
import { withRouter, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import overlayFactory from 'react-bootstrap-table2-overlay'
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import { EMPTY_LIST } from 'src/common/constants'
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import { Row, Col } from 'reactstrap'
import DateUtils from 'src/services/utils/DateUtils'
import TaskManagementService from '../../../../api/TaskManagementService'
import { 
    ModulesComponents,
    ModulesActions,
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'

const MitigationTasks = ({ t, Organization, Modules }) => {
    const {
        tableMitigationTasksColumnsRenderer
    } = ModulesActions[Modules.active] || {}
    const routes = ModulesApiRoutes[Modules.active]
    const history = useHistory()
    const ModuleComponents = ModulesComponents[Modules.active] || null

    const [filters, setFilters] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    })

    const [tableResultTotalCount, setTableResultTotalCount] = useState(0)

    const [tasks, setTasks] = useState([])

    const dateUtils = new DateUtils()

    const NoDataIndication = () =>
        handleFetchMitigationTaskQuery.isFetched &&
        !handleFetchMitigationTaskQuery.length ? (
            <div className='alert m-0' role='alert'>
                <p
                    style={{
                        textAlign: 'center',
                        marginBottom: 0
                    }}
                >
                    {t(EMPTY_LIST)}
                </p>
            </div>
        ) : (
            <></>
        )

    const taskService = new TaskManagementService(routes.API_BASE_URL)

    const handleFetchMitigationTaskQuery = useQuery({
        queryKey: ['risk-management-fetch-all-tasks-query', filters],
        queryFn: async () => {
            return await taskService.fetchMitigationTasks({
                ...filters
            })
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onSuccess: ({ tasks, itemsCount }) => {
            setTasks(tasks || [])

            setTableResultTotalCount(itemsCount || 0)
        },
        onError: (_error) => {
            toast(t('An error occurred while fetching tasks list.'), {
                type: 'error'
            })
        }
    })

    const handleTableChange = (type, data) => {
        switch (type) {
            case 'pagination': {
                const { page, sizePerPage } = data

                setFilters({
                    ...filters,
                    pageIndex: page,
                    pageSize: sizePerPage
                })
                break
            }
            case 'sort': {
                const { sortField, sortOrder } = data

                setFilters({
                    ...filters,
                    sortField,
                    sortOrder
                })
                break
            }
            default:
                return false
        }
    }

    const tableColumns = useMemo(() => {
        return tableMitigationTasksColumnsRenderer({
            t,
            history,
            Organization,
            dateUtils
        })
    }, [ t ])

    return (
        <div className='p-4'>
            {tableColumns && tableColumns.length > 0 && (
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        page: filters.pageIndex,
                        sizePerPage: filters.pageSize,
                        totalSize: tableResultTotalCount,
                        withFirstAndLast: false,
                        alwaysShowAllBtns: true,
                        prePageText: (
                            <span>
                                <i className='ri-arrow-left-s-line' />{' '}
                                {t('Back')}
                            </span>
                        ),
                        nextPageText: (
                            <span>
                                {t('Next')}{' '}
                                <i className='ri-arrow-right-s-line' />
                            </span>
                        ),
                        prePageTitle: t('Pre page'),
                        firstPageTitle: t('Next page'),
                        showTotal: true,
                        hideSizePerPage: false,
                        sizePerPageList: [
                            {
                                text: '25',
                                value: 25
                            },
                            {
                                text: '50',
                                value: 50
                            }
                        ]
                    })}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <Row className='mb-5 mt-3'>
                                <Col sm='12'>
                                    <div className='d-flex gap-4 justify-content-end'>
                                        <Suspense fallback={<div>{t('Loading')}...</div>}>
                                            {
                                                ModuleComponents?.TasksFiltering ? 
                                                <ModuleComponents.TasksFiltering 
                                                    onChange={(newFilters) => {
                                                        setFilters({
                                                            ...filters,
                                                            ...newFilters,
                                                            pageIndex: 1
                                                        });
                                                    }}
                                                /> : 
                                                <div className='text-danger'>Error!</div>
                                            }
                                        </Suspense>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12'>
                                    <BootstrapTable
                                        remote={{
                                            pagination: true,
                                            filter: false,
                                            sort: true,
                                            cellEdit: false,
                                            search: false
                                        }}
                                        loading={
                                            handleFetchMitigationTaskQuery.isFetching ||
                                            handleFetchMitigationTaskQuery.isLoading
                                        }
                                        overlay={overlayFactory({
                                            spinner: (
                                                <Spinner
                                                    animation='border'
                                                    variant='primary'
                                                    size='md'
                                                />
                                            ),
                                            text: 'Loading...'
                                        })}
                                        onTableChange={handleTableChange}
                                        defaultSorted={[]}
                                        keyField='id'
                                        responsive
                                        bordered={false}
                                        data={tasks}
                                        striped
                                        columns={tableColumns}
                                        wrapperClasses='table-responsive'
                                        classes='table tpdd-table'
                                        headerWrapperClasses='thead-light'
                                        style={{
                                            overflowX: 'auto'
                                        }}
                                        noDataIndication={() => (
                                            <NoDataIndication />
                                        )}
                                        {...paginationTableProps}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='12' md='6'>
                                    <div className='tpdd-pagination-style-1'>
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />

                                        <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </PaginationProvider>
            )}
        </div>
    )
}

const mapToState = (state) => {
    const { Organization, Modules } = state

    return {
        Organization,
        Modules
    }
}

export default withNamespaces()(
    withRouter(connect(mapToState, {})(MitigationTasks))
)
