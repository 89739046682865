import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap";
import SupplierReportsService from "src/modules/3rd-party-management/apis/admin/SupplierReportsService";

const WithdrawRequest = ({
    t,
    supplierId,
    supplierType,
    onSuccess,
    itemToWithdraw,
    hideModal
}) => {
    const {
        mutate: mutateWithdraw,
        isLoading: withdrawInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierReportsService.getInstance();
            return await service.withdraw(
                itemToWithdraw.id
            );
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("The report request has been successfully withdrawn."), {
                type: "success",
            });

            hideModal();
        },
        onError: () => {
            toast(t("Failed to withdraw the report request. Please try again later."), {
                type: "error"
            });
        }
    });

    return (
        <Modal size="md" isOpen={ itemToWithdraw }>
            <ModalHeader toggle={ hideModal }>
                {`${t("Withdraw Report Request")}?`}
            </ModalHeader>
                
            <ModalBody>
                <p>
                    {t('Are you sure you want to withdraw this report request? This action cannot be undone.')}
                </p>
            </ModalBody>

            <ModalFooter>
                <Button 
                    color="danger" 
                    onClick={ hideModal }
                    disabled={withdrawInProcess}
                > 
                    { t('Cancel') }
                </Button>

                <Button 
                    type="button" 
                    color="primary"
                    onClick={mutateWithdraw}
                    disabled={withdrawInProcess}
                >
                    {
                        withdrawInProcess && (
                            <Spinner animation="border" variant="primary" size="sm"/>
                        )
                    }
                    { t('Withdraw') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(WithdrawRequest);