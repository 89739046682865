import { useImperativeHandle, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from '@tanstack/react-query';
import DocumentsService from 'src/modules/3rd-party-management/apis/admin/DocumentsService';
import { toast } from 'react-toastify';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { DocumentsTypes } from 'src/modules/3rd-party-management/constants';
import UserUtils from 'src/services/utils/UserUtils';
import { API_URL_DOWNLOAD_DOCUMENTS } from 'src/modules/3rd-party-management/constants';
import axios from 'axios';

const defaultSettings = {
    actions: {
        delete: true,
        view : true
    }
}

const ListDocuments = ({
    actionsRef,
    token,
    t,
    user,

    settings
}) => {
    const [ documentToDelete, setDocumentToDelete ] = useState(null);
    const userUtils = new UserUtils();

    const isActionEnable = (action) => {
        return (action in (settings?.actions || {})) ? !!settings.actions[action] : defaultSettings.actions[action]
    }

    const columns = [
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
        },
        {
            dataField: "language",
            text: t("Language"),
            sort: true,
            formatter: (cell) => {
                return cell?.name
            }
        },
        {
            dataField: "role",
            text: t("Role"),
            sort: true,
            formatter: (cell , row) => {
                switch(row?.role){
                    case 'ROLE_USER':
                        return t("User");
                    case 'ROLE_EMPLOYEE':
                        return t("Employee");
                    case 'ROLE_THIRD_PARTY':
                        return t("Third Party");
                    case 'ROLE_ANALYST':
                        return t("Analyst");
                    case 'ROLE_ANALYST_ADMIN':
                        return t("Analyst Admin");
                    case 'ROLE_PARTNER':
                        return t("Partner");
                    case 'ROLE_RESELLER':
                        return t("Reseller");
                    case 'ROLE_TASK_OWNER':
                        return t("Task Owner");
                    default:
                        return t("Analyst/Employee");
                }
            }
        },
        {
            dataField: "type",
            text: t("Type"),
            sort: true,
            formatter: (cell) => {
                return t(DocumentsTypes.find((dType) => dType.typeKey === cell)?.title)
            }
        },
        {
            dataField: "download",
            text: t("Download"),
            sort: false,
        },
        {
            dataField: "actions",
            text: t("Actions"),
            sort: false,
            formatter: (cell, row) => {
                return (
                    <>
                        {userUtils.isAnalystOrAnalystAdmin(user) && isActionEnable('delete') && (
                            <Button
                                onClick={() => setDocumentToDelete(row)}
                                color="link"
                                className="waves-effect me-1 text-danger"
                            >
                                <i className="ri-delete-bin-line font-size-22"></i>
                            </Button>
                        )}
                        {isActionEnable('view') && (
                            <Button
                                color="link"
                                className="waves-effect me-1"
                                onClick={() => downloadFile(row)}
                            >
                                <i className="ri-eye-line font-size-22"></i>
                            </Button>
                        )}
                    </>
                )
            }
        }
    ];

    const {
        data: documents = [],
        isFetching: fetchingDocumentsInprocess,
        refetch: refetchDocumentsQuery
    } = useQuery({
        queryKey: ["3rd-party-fetch-documents-list-query"],
        queryFn: async () => {
            return await (DocumentsService.getInstance()).fetchList();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t("An error occurred while fetching documents list."), {
                type: "error"
            });
        }
    });

    const {
        mutate: mutateDeleteDocument,
        isLoading: deletingDocumentInprocess
    } = useMutation({
        mutationFn: async () => {
            return await (DocumentsService.getInstance()).delete(documentToDelete.id);
        },
        onSuccess: () => {
            refetchDocumentsQuery();

            toast(t("Item deleted successfully."), {
                type: "success"
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting the document"), {
                type: "error"
            });
        },
        onSettled: () => {
            setDocumentToDelete(null);
        }
    });

    const downloadFile = ({
        id,
        title
    }) => {
        axios.get(`${API_URL_DOWNLOAD_DOCUMENTS}/${id}/download`, {
            responseType: "arraybuffer",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
            onDownloadProgress: (e) => {
                const {
                    total,
                    loaded
                } = e;

                const downloadedPercent = Math.round((loaded * 100) / total);

                if(downloadedPercent >= 100){}
            },
        })
        .then((blob) => {
            const link = document.createElement("a");
        
            link.href = window.URL.createObjectURL(new Blob([blob.data]));
            link.setAttribute("download", `${title}.pdf`);
        
            document.body.appendChild(link);
        
            link.click();
            link.remove();
        })
        .catch(() => {
            toast(t("An error occurred while downloading the file."), {
                type: "error"
            });
        });
    };

    const renderModals = () => {
        const closeDeleteModal = () => setDocumentToDelete(null)
        return (
            <Modal
                size="lg"
                scrollable={true}
                isOpen={documentToDelete}
                backdrop="static"
            >
                <ModalHeader toggle={closeDeleteModal}>
                    {t(`Delete ${documentToDelete?.title}`)}
                </ModalHeader>
                <ModalBody>
                    <p>
                        {t("Are you sure?")}
                    </p>

                    <ModalFooter>
                        <Button
                            color="danger"
                            className="waves-effect waves-light"
                            type="button"
                            disabled={deletingDocumentInprocess}
                            onClick={() => mutateDeleteDocument()}
                        >
                            {deletingDocumentInprocess && (
                                <Spinner size='sm' color='white' className='me-1' />
                            )}
                            {t("Delete")}
                        </Button>

                        <Button
                            color="secondary"
                            className="waves-effect waves-light"
                            type="button"
                            onClick={closeDeleteModal}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        )
    }

    useImperativeHandle(actionsRef, () => {
        return {
            reload  :   () => {
                refetchDocumentsQuery();
            }
        }
    });

    return (
        <Card>
            <CardBody>
                <PaginationProvider
                    pagination={paginationFactory({
                        sizePerPage: 100,
                        totalSize: documents.length,
                        custom: true,
                    })}
                    keyField="id"
                    columns={columns}
                    data={documents}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={documents}
                            search
                        >
                            {(toolkitProps) => (
                                <>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={[]}
                                                    classes="table align-middle table-nowrap"
                                                    headerWrapperClasses="thead-light"
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
            </CardBody>

            { renderModals() }
        </Card>
    );
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization } = state;
	
	return {
		token,
		user,
		organization: Organization
	};
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
        })(
            ListDocuments
        )
    )
);