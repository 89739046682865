import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class SupplierProductService {
	static instance;

	static getInstance() {
		if (!SupplierProductService.instance) {
			SupplierProductService.instance = new SupplierProductService();
		}

		return SupplierProductService.instance;
	}

    async list(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/products`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || null;
    }

    async create(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/product/add`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async update(productId, payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/product/${productId}/edit`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async delete(productId){
        const response = await axios.delete(`${API_BASE_URL}/supplier/product/${productId}/delete`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }
}
