import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import UrlUtils from '../../../services/utils/UrlUtils';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import BarChart from './Charts/BarChart';
import Channels from './Charts/Channels';
import RiskRatings from './Charts/RiskRatings';
import DamageAnalytics from './Charts/DamageAnalytics';
import KnobChartCasesAssignedToMe from './Charts/KnobChartCasesAssignedToMe';
import MiniWidgets from './MiniWidgets';
import RevenueByLocations from './RevenueByLocations';
import LatestTransactions from './LatestTransactions';
import CategoriesAnalytics from './CategoriesAnalytics';
import ColumnWithDataLabels from './ColumnWithDataLabels';
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { onGetInfoOrganizationSuccess } from '../../../store/organization/actions';
import { errorNotification, loginUserSuccessful, apiError, appLoadStatusChanged, changePreloader } from '../../../store/actions';
import { changeWorkspace } from '../../../store/workspace/actions';
import {
	API_URL_ADMIN_DASHBOARD,
	API_URL_ADMIN_DASHBOARD_PARTNER_LIST,
	API_URL_GET_USER_DETAIL,
	API_URL_VERIFY_ORGANIZATION,
	CM_API_URL_ADMIN_DASHBOARD,
	CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST,
	CM_API_URL_GET_USER_DETAIL,
} from '../../../common/constants';
import DownloadStatistics from './components/DownloadStatistics';
import Filtering from './components/Filtering';

const Dashboard = ({
    t,
    modules,
    user,
    token
}) => {
    const urlUtils = new UrlUtils();
    const userRoles = user?.roles || [];

    const [ filters, setFilters ] = useState({});
    const [ selectedClient, setSelectedClient ] = useState();
    const [ userRoleLoaded, setUserRoleLoaded ] = useState(false);

    const handleFilterOnChange = useCallback((newFilters) => {
        setFilters((currentState) => ({...currentState, ...newFilters}));
    }, []);

    const {
        data: dashboardData
    } = useQuery({
        queryKey: [
            "wb-cm-fetch-admin-dashboard-query", 
            filters
        ],
        queryFn: async () => {
            const url = modules.active === 'wb' ? API_URL_ADMIN_DASHBOARD : CM_API_URL_ADMIN_DASHBOARD;

            const response = await axios.get(url, {
                params: {
                    ...filters,
                    excludeDeleted: (filters.exclude || []).includes('deleted_cases'),
                    excludeTestCases:(filters.exclude || []).includes('test_cases'),
                    exclude: undefined
                },
                headers: {
					Authorization: `Bearer ${token}`,
				}
            })

            return response.data?.data || {};
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: userRoleLoaded,
        onError: () => {
            toast(t("An error occurred while fetching dashboard data."), {
                type: "error"
            });
        }
    });

    const {
        isFetching: fetchingPartners,
        data: partnersList,
        refetch: refetchPartnersQuery
    } = useQuery({
        queryKey: [
            "fetch-partners-query",
            user?.id
        ],
        queryFn: async () => {
            const url = modules.active === 'wb' ? API_URL_ADMIN_DASHBOARD_PARTNER_LIST : CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST;

            const response = await axios.get(url, {
                headers: {
					Authorization: `Bearer ${token}`,
				}
            })

            return response.data?.data || [];
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            toast(t("An error occurred while fetching organizations list."), {
                type: "error"
            });
        }
    });

    const {
        mutate: organizationVerifyMutation,
        isLoading: orgVerificationIsLoading
    } = useMutation({
        mutationFn: async ({
            orgId,
            orgDomain
        }) => {
            return await axios.post(
                API_URL_VERIFY_ORGANIZATION,
                {
                    id: orgId,
                    url: decodeURI(orgDomain),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
        },
        onSuccess: (_response, {
            orgId,
            orgDomain,
            orgName
        }) => {
            localStorage.setItem('org', urlUtils.getAppRootPath(orgId, orgName));
            const newBaseUrl = urlUtils.getAppRootPath(orgId, decodeURI(orgDomain));
            window.location.replace(`${newBaseUrl}/${window.location.pathname.split('/', 4)[3]}/dashboard`);
        },
        onError: () => {
            toast(t("Failed to verify organization. please try again."), {
                type: "error"
            });
        },
    });

    const {
        mutate: userVerificationMutation,
        isLoading: userVerificationIsLoading
    } = useMutation({
        mutationFn: async ({
            orgId
        }) => {
            const url = modules.active === 'wb' ? API_URL_GET_USER_DETAIL : CM_API_URL_GET_USER_DETAIL;

            return await axios.post(
                url,
                {
                    organizationId: orgId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
        },
        onSuccess: (_response, orgInfo) => {
            organizationVerifyMutation(orgInfo)
        },
        onError: () => {
            toast(t("Failed to verify organization. please try again."), {
                type: "error"
            });
        }
    });

    const breadcrumbItems = useMemo(() => {
        return [
            { title: 'SIP', link: '/' },
            { title: 'Dashboard', link: '#' }
        ]
    }, []);

    const clients = useMemo(() => {
        let options = [];
        if(user && user.roles.includes('ROLE_PARTNER')){
            options = [
                {
                    label: t('All'),
                    value: 'all',
                    extra: {
                        id: user.organization.id,
                        name: user.organization.name,
                        code: user.organization.country_code,
                        domain: user.orgUrl
                    }
                },
                {
                    label: t('My own company'),
                    value: 'own',
                    extra: {
                        id: user.organization.id,
                        name: user.organization.name,
                        code: user.organization.country_code,
                        domain: user.orgUrl
                    }
                },
                ...(partnersList || []).map((par) => ({
                    value: par.id,
                    label: par.name,
                    extra: {
                        id: par.id,
                        name: par.name,
                        code: par.code,
                        domain: par.domain
                    }
                }))
            ];

            let orgToSearch = localStorage.getItem('filterBy') || user.organization.id || urlUtils.getOrgId();

            setSelectedClient(
                options.find((option) => option.value.toString() === orgToSearch.toString())
            );
        }

        return options;
    }, [
        t,
        user,
        partnersList
    ]);

    const totalCases = useMemo(() => {
        const result = [];
        if(dashboardData?.totalCases){
            result.push({
                amount: dashboardData.totalCases,
                percent: 100,
                title: 'Total',
                icon: 'ri-stack-line',
                displayChart: false,
            });
            
            const statuses = dashboardData?.statuses || {};

            for (const item in statuses) {
                if (!['assigned', 'deleted'].includes(statuses[item].title)) {
                    let title = '';
                    if (statuses[item].title === 'closed') {
                        title = 'closed';
                    } else if (statuses[item].title === 'in progress') {
                        title = 'In Progress';
                    } else if (statuses[item].title === 'new') {
                        title = 'New';
                    } else if (statuses[item].title === 'pending deletion') {
                        title = 'Pending Deletion';
                    } else {
                        title = statuses[item].title;
                    }

                    result.push({
                        amount: statuses[item].amount,
                        percent: statuses[item].percent,
                        title: t(title),
                        icon: null,
                        displayChart: true,
                    });
                }
            }
        }
        
        return result;
    }, [
        dashboardData?.totalCases,
        dashboardData?.statuses,
        t
    ]);

    const channels = useMemo(() => {
        const result = [];
        if(dashboardData?.sources){
            const sources = dashboardData?.sources;
    
            for (const item in sources) {
                const title = t(sources[item].title);
                result.push({
                    amount: sources[item].amount,
                    title: title,
                    percent: sources[item].percent,
                });
            }
        }
        return result;
    }, [
        dashboardData?.sources,
        t
    ]);

    const risksRatings = useMemo(() => {
        const result = [];
        if(dashboardData?.riskRate){
            const riskRate = dashboardData?.riskRate;
            for (const item in riskRate) {
                result.push({
                    color: riskRate[item].color,
                    amount: riskRate[item].amount,
                    title: riskRate[item].title,
                    percent: riskRate[item].percent,
                });
            }
        }
        return result;
    }, [
        dashboardData?.riskRate
    ]);

    const topCountries = useMemo(() => {
        const result = [];
        if(dashboardData?.topCountries){
            for (const [key, value] of Object.entries(dashboardData.topCountries)) {
                if (Number(value) !== 0) {
                    result[key] = value;
                }
            }
        }
        return result;
    }, [
        dashboardData?.topCountries
    ]);

    const categories = useMemo(() => {
        const result = [];
        const categories = dashboardData?.categories || {};

        for (const item in categories) {
            result.push({
                amount: categories[item].amount,
                title: t(categories[item].title),
                percent: categories[item].percent,
            });
        }

        return result;
    }, [
        dashboardData?.categories,
        t
    ]);

    const listTypes = useMemo(() => {
        const result = {
            percents: [],
            titles: []
        };
        const types = dashboardData?.types || {};

        for (const item in types) {
            result.percents.push(parseInt(types[item].percent));
            result.titles.push(t(types[item].title));
        }
        return result;
    }, [
        dashboardData?.types,
        t
    ]);

    useEffect(() => {
        if(user){
            let filterPartner;
            if(user.roles.includes('ROLE_PARTNER')){ 
                if(localStorage.getItem('filterBy') === 'all'){
                    filterPartner = localStorage.getItem('filterBy')
                }
                refetchPartnersQuery();  
            }       

            setFilters((currentState) => (
                {
                    ...currentState, 
                    partner: filterPartner
                }
            ));

            setTimeout(() => {
                setUserRoleLoaded(true);
            }, 250);
        }
    }, [
        user
    ]);

    useEffect(() => {
        if(selectedClient){
            const orgInStorage = localStorage.getItem('filterBy');

            if(orgInStorage !== selectedClient.value.toString()){
                localStorage.setItem('filterBy', selectedClient.value);

                userVerificationMutation({
                    orgId: selectedClient.extra.id,
                    orgDomain: selectedClient.extra.domain,
                    orgName: selectedClient.extra.name.replace(/\s+/g, '-').toLowerCase()
                });
            }
        }
    }, [ selectedClient ]);

    return (
        <div className='page-content'>
            <Container fluid>
                <Breadcrumbs 
                    title='Dashboard' 
                    breadcrumbItems={breadcrumbItems} 
                />

                <Row className='mb-4 mt-4'>
                    <Col sm='12' md='6' lg='4'>
                        {userRoles.includes('ROLE_PARTNER') && (
                            <div style={{
                                maxWidth: '220px'
                            }}>
                                <Select
                                    isDisabled={fetchingPartners || userVerificationIsLoading || orgVerificationIsLoading}
                                    isLoading={fetchingPartners || userVerificationIsLoading || orgVerificationIsLoading}
                                    name='sortByOrganization'
                                    classNamePrefix='select2-selection'
                                    options={clients}
                                    onChange={(e) => {
                                        setSelectedClient(e)
                                    }}
                                    placeholder={t('Filter by organization')}
                                    value={selectedClient}
                                />
                            </div>
                        )}
                    </Col>

                    <Col sm='12' md='6' lg='8' className='d-flex justify-content-end align-items-start'>
                        <Filtering 
                            onChange={handleFilterOnChange}
                        />

                        {isFeatureEnabledForActiveModule('dashboard-download-statistics-button') && (
                            <DownloadStatistics 
                                filters={filters} 
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col xl={8}>
                        {isFeatureEnabledForActiveModule('total-cases') && (
                            <MiniWidgets 
                                reports={totalCases} 
                            />
                        )}

                        {isFeatureEnabledForActiveModule('amount-of-cases') && (
                            <DamageAnalytics 
                                data={dashboardData?.typesByDate || []} 
                            />
                        )}
                        
                        <Row style={{ alignContent: 'stretch' }} className='row-equal-height'>
                            {isFeatureEnabledForActiveModule('channels') && (
                                <Col lg={6} style={{ height: '100%' }}>
                                    <Channels 
                                        data={channels} 
                                    />
                                </Col>
                            )}
                            {isFeatureEnabledForActiveModule('risk-rate') && (
                                <Col lg={6} style={{ height: '100%' }}>
                                    <RiskRatings 
                                        data={risksRatings} 
                                    />
                                </Col>
                            )}
                        </Row>

                        {isFeatureEnabledForActiveModule('heatmap') && (
                            <RevenueByLocations 
                                countries={dashboardData?.allCountries || {}} 
                                topCountries={topCountries} 
                            />
                        )}
                    </Col>
                    <Col xl={4}>
                        {isFeatureEnabledForActiveModule('top5category') && (
                            <CategoriesAnalytics 
                                categories={categories} 
                            />
                        )}

                        {isFeatureEnabledForActiveModule('case-type') && modules.active !== 'cm' && (
                            <ColumnWithDataLabels 
                                options={listTypes} 
                            />
                        )}

                        {isFeatureEnabledForActiveModule('processing-time') && (
                            <BarChart 
                                title='Processing time in days' 
                                data={dashboardData?.investigationTime || {}} 
                            />
                        )}

                        {isFeatureEnabledForActiveModule('cases-assigned-to-me') && (
                            <KnobChartCasesAssignedToMe 
                                data={dashboardData?.assignedToMe || []} 
                            />
                        )} 
                    </Col>
                </Row>

                {isFeatureEnabledForActiveModule('lateast-cases') && (
                    <LatestTransactions 
                        orgIsAll={selectedClient?.value === 'all'} 
                        categories={categories} 
                        excludeDeleted={(filters.exclude || []).includes('deleted_cases')} 
                        excludeTestCases={(filters.exclude || []).includes('test_cases')} 
                    />
                )}
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App } = state;
	const organization = state.Organization;
    const { 
		Modules: modules 
	} = state;

	return {
		token,
		user,
		App,
		organization,
        modules
	};
};

const mapDispachToProps = (dispatch) => {
	return {
		apiError: (error) => dispatch(apiError(error)),
		appLoadStatusChanged: (status) => dispatch(appLoadStatusChanged(status)),
		errorNotification: (message) => dispatch(errorNotification(message)),
		changePreloader: (val) => dispatch(changePreloader(val)),
		changeWorkspace: (workspace) => dispatch(changeWorkspace(workspace)),
		loginUserSuccessful: (user) => dispatch(loginUserSuccessful(user)),
		handleGetInfoOrganizationSuccess: (val) => dispatch(onGetInfoOrganizationSuccess(val)),
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, mapDispachToProps)(Dashboard)
	)
)