import { useMemo, useState } from "react";
import { withNamespaces } from "react-i18next"
import { Button, Col, FormGroup, Label, ListGroup, ListGroupItem, Row, Spinner } from "reactstrap";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import BafaQuestionnaireService from "src/modules/3rd-party-management/apis/admin/BafaQuestionnaireService";
import { connect } from "react-redux";

const SelectSubsidiaries = ({
    t,
    onValidSubmit,
    organization,
    supplierId,
    reportCaseId
}) => {
    const [ selectedSubsidiaries, setSelectedSubsidiaries ] = useState([]);
    const [ displayEmptyListError, setDisplayEmptyListError ] = useState(false);
    const [ subsidiariesWithNoReport, setSubsidiariesWithNoReport ] = useState([]);

    const {
        data: subsidiariesList,
        isFetching: subsidiariesListIsFetching
    } = useQuery({
		queryKey: [
            '3rd-party-management-subsidiaries-list-query',
            supplierId
        ],
		queryFn: async () => {
			const service = BafaQuestionnaireService.getInstance();

            return await service.fetchSubsidiaries(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: true,
		onError: () => {
			toast(t('An error occurred while fetching subsidiaries list.'), {
				type: 'error'
			});
		}
	});

    const {
        mutate: addSelectedSubsidiariesMutate,
        isLoading: addingSubsidiariesInprocess 
    } = useMutation({
        mutationFn: async (payload) => {
            const service = BafaQuestionnaireService.getInstance();
            return await service.addSubsidiaries(payload);
        },
        onSuccess: () => {
            onValidSubmit && 
                onValidSubmit(selectedSubsidiaries)
        },
        onError: (error) => {
            toast(t("Some subsidiaries have not filled their reports."), {
                type: "error"
            });

            const errorsList = error.response?.data?.error?.hasErrorSubsidiaries || [];

            if(errorsList.length > 0){
                setSubsidiariesWithNoReport(errorsList)
            }
        }
    });

    const memoizedSubsidiaries = useMemo(() => {
        return (subsidiariesList || []).filter((subsidiary) => {
            return selectedSubsidiaries.findIndex((selectedItem) => {
                return selectedItem.value === subsidiary.id
            }) < 0
        }).map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [ subsidiariesList, selectedSubsidiaries ])

    const handleNextButtonClick = () => {
        if(!selectedSubsidiaries.length){
            return setDisplayEmptyListError(true)
        }

        addSelectedSubsidiariesMutate({
            reportCase: reportCaseId,
            subsidiaries: selectedSubsidiaries.map((subsidiary) => {
                return (subsidiary.value).toString()
            })
        })
    }

    return (
        <div>
            <Row className='mb-3'>
                <Col sm='12' md='12' lg='8'>
                    <FormGroup>
                        <Label>
                            {t("Please select the subsidiaries you want to include in your report")}:
                        </Label>
                        <Select
                            isDisabled={subsidiariesListIsFetching}
                            isLoading={subsidiariesListIsFetching}
                            isMulti
                            classNamePrefix="select2-selection"
                            options={memoizedSubsidiaries}
                            value={[]}
                            placeholder={t("Select...")}
                            menuPortalTarget={document.body}
                            hideSelectedOptions
                            onChange={(newValue) => {
                                setSelectedSubsidiaries((currentState) => {
                                    return [...currentState, {...newValue[0]}];
                                });
                                setDisplayEmptyListError(false);
                            }}
                        />

                        <ListGroup className="mt-2">
                            {selectedSubsidiaries.map((selectedSubsidiary) => {
                                return (
                                    <ListGroupItem className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {selectedSubsidiary.label}

                                            {subsidiariesWithNoReport.includes(selectedSubsidiary.value) && (
                                                <span className='d-block invalid-feedback'>
                                                    {t('This subsidiary has not submitted the report.')}
                                                </span>
                                            )}
                                        </div>

                                        <Button 
                                            outline 
                                            color="danger"
                                            className="border-0"
                                            onClick={() => {
                                                setSelectedSubsidiaries((currentState) => {
                                                    const newState = [...currentState].filter((subsidiary) => {
                                                        return subsidiary.value !== selectedSubsidiary.value
                                                    });

                                                    setDisplayEmptyListError(!newState.length);

                                                    return newState;
                                                })
                                            }}
                                        >
                                            <i className="ri-delete-bin-line font-size-20" />
                                        </Button>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>

                        {displayEmptyListError && (
                            <span className='d-block invalid-feedback'>
                                {t('No subsidiaries have been selected. Please select at least one to proceed with the report.')}
                            </span>
                        )}
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm='12'>
                    <Button
                        disabled={addingSubsidiariesInprocess}
                        color='primary'
                        type='button'
                        onClick={handleNextButtonClick}
                    >
                        {addingSubsidiariesInprocess ? (
                            <>
                                <Spinner 
                                    className="me-2" 
                                    animation="border" 
                                    variant="info" 
                                    size="sm"
                                />
                                {t('Please wait')}...
                            </>
                        ) : (
                            <>
                                {t('Next')}
                            </>
                        )}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const mapStatetoProps = (state) => {
	const { Organization: organization } = state;
	
	return {
		organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps)(
        SelectSubsidiaries
    )
);