import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import TableFilter from "src/components/TableFilter";
import DateUtils from "src/services/utils/DateUtils";
import {
    createStaticRanges
} from 'react-date-range'
import { startOfYear, endOfYear, subYears, subMonths, startOfMonth, startOfDay } from "date-fns";

const Filtering = ({
    t,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        'createdAt[from]': null,
        'createdAt[to]': null,
        exclude: null
    });

    const initRef = useRef(false);
    const dateUtils = new DateUtils();

    const customStaticRanges = useMemo(() => {
        return createStaticRanges([
            {
                label: "Last year",
                range: () => ({
                    startDate: startOfYear(subYears(new Date(), 1)),
                    endDate: endOfYear(subYears(new Date(), 1))
                })
            },
            {
                label: "This year",
                range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: new Date()
                })
            },
            {
                label: "Last three month",
                range: () => ({
                    startDate: subMonths(new Date(), 3),
                    endDate: new Date()
                })
            },
            {
                label: "This month",
                range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: new Date()
                })
            },
            {
                label: "Today",
                range: () => ({
                    startDate: startOfDay(new Date()),
                    endDate: new Date()
                })
            }
        ])
    }, [])

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Filter by date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters['createdAt[from]'],
                    to: tableFilters['createdAt[to]']
                },
                customStaticRanges: customStaticRanges
            },
            {
                type: 'check_list',
                name: 'exclude',
                title: 'Exclude',
                icon: <i className="ri-eye-off-line" />,
                isLoading: false,
                defaultValue: tableFilters.exclude,
                data: [
                    {
                        id: 'deleted_cases',
                        title: 'Deleted cases'
                    },
                    {
                        id: 'test_cases',
                        title: 'Test Cases'
                    }
                ],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (filterItem) => {
                    return t(filterItem.title)
                }
            }
        ]
    }, [
        tableFilters,
        customStaticRanges,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}
            
            if ('filter-date' in filters) {
                result['createdAt[from]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD'
                )

                result['createdAt[to]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            if ('exclude' in filters) {
                result.exclude = Array.isArray(filters.exclude) && filters.exclude.length > 0 ? filters.exclude : undefined
            }

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                'createdAt[from]': null,
                'createdAt[to]': null,
                exclude: null
            })
        }
    }

    useEffect(() => {
        if(!initRef.current){
            initRef.current = true;
            return;
        }
        
        onChange && onChange(tableFilters)
    }, [ 
        tableFilters,
        onChange
    ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='filter-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(
    Filtering
);