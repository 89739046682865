import * as React from 'react'
import { useState, useEffect, memo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { TasksStatuses, RisksRates } from '../../constants'

const DonutChart = (props) => {
    const [totalData, setTotalData] = useState([])
    const [mineData, setMineData] = useState([])
    const [colors, setColors] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        if (props.riskCreated) {
            const data = Object.keys(props.riskCreated?.allRisks || {})
            const allRisks = props.riskCreated?.allRisks || {}
            const mineRisks = props.riskCreated?.mineRisks || {}
            const totalRiskData = data.map(
                (label, index) => allRisks[label]?.amount || 0
            )
            const mineRiskData = data.map(
                (label, index) => mineRisks[label]?.amount || 0
            )
            const labelsData = data.map((label) =>
                props.t(allRisks[label]?.title || '')
            )
            const colors = data.map((label, index) => {
                const color =
                    allRisks[label]?.color ||
                    RisksRates[allRisks[label]?.title?.toLowerCase()]?.color ||
                    '#8792E8' // Default color if not found
                return color.startsWith('#') ? color : '#' + color
            })
            setLabels(labelsData)
            setTotalData(totalRiskData)
            setMineData(mineRiskData)
            setColors(colors)
        }
        if (props.mitigation) {
            const data = Object.keys(props.mitigation?.all || {})
            const all = props.mitigation?.all || {}
            const mine = props.mitigation?.mine || {}
            const totalMitigationData = data.map(
                (label) => all[label]?.amount || 0
            )
            const mineMitigationData = data.map(
                (label) => mine[label]?.amount || 0
            )
            const labelsData = data.map((label) =>
                props.t(TasksStatuses[label]?.title || '')
            )

            const colors = data.map((label) => {
                const color =
                    all[label]?.color ||
                    TasksStatuses[label]?.color ||
                    '#8792E8' // Default color if not found

                return color.startsWith('#') ? color : '#' + color
            })

            setLabels(labelsData)
            setTotalData(totalMitigationData)
            setMineData(mineMitigationData)
            setColors(colors)
        }
    }, [props.mitigation, props.riskCreated, props.t])

    const optionsTotal = {
        labels: labels,
        colors: colors,
        legend: {
            show: false
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                colors: colors
            },
            formatter: function (val, { seriesIndex }) {
                return Math.round(val) + '%'
            }
        },
        title: {
            text: `${props.t('Total')}`,
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: '500',
                color: '#263238'
            }
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 200
                    }
                }
            }
        ]
    }

    const optionsMine = {
        labels: labels,
        colors: colors,
        legend: {
            show: false
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                colors: colors
            },
            formatter: function (val, { seriesIndex }) {
                return Math.round(val) + '%'
            }
        },
        title: {
            text: `${props.t('Mine')}`,
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 500,
                color: '#4F4F4F'
            }
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 200
                    }
                }
            }
        ]
    }

    if (props.isLoading) {
        return (
            <React.Fragment>
                <div
                    className='dt-field dt-skeleton mb-2'
                    style={{ width: '30%' }}
                />
                <div
                    className='dt-field dt-skeleton'
                    style={{ width: '100%', height: '320px' }}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <h5 className='mb-3'>{props.title}</h5>
            <Row>
                <Col className='mt-3' sm='6' md='6'>
                    <div className='d-flex'>
                        <ReactApexChart
                            options={optionsTotal}
                            series={totalData}
                            type='donut'
                            height='280'
                        />
                    </div>
                </Col>
                <Col className='mt-3' sm='6' md='6'>
                    <div className='d-flex '>
                        <ReactApexChart
                            options={optionsMine}
                            series={mineData}
                            type='donut'
                            height='280'
                        />
                    </div>
                </Col>
            </Row>
            <div className='mb-2 mt-4 d-flex justify-content-center align-items-center flex-wrap'>
                {labels.map((label, index) => (
                    <div key={index} className='d-flex align-items-center me-4'>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: colors[index],
                                borderRadius: '50%',
                                marginRight: '5px'
                            }}
                        />
                        <span style={{ fontSize: '14px' }}>
                            {props.t(label)}
                        </span>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { token } = state.Login
    const { Organization } = state
    return {
        user: state.Login.user,
        token,
        organization: Organization
    }
}

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {})(memo(DonutChart)))
)
