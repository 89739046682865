const actionDefinitions = {
	case: {
		create: 'New case created',
		update_analyst: 'Update Analyst',
		update_status: 'Update Status',
		mark_as_delete: 'Marked as delete',
		delete: 'Deleted',
		upload_attachment: 'Uploaded attachment',
		download_user_attachment: 'Download user attachment',
		download_analyst_attachment: 'Download analyst attachment',
		delete_attachment: 'Delete Attachment',
		add_comment: 'Add Comment',
		add_internal_comment: 'Add Internal Comment',
		update_analyst_readonly: 'Read-Only Analysts',
		update_risk: 'Risk updated',
		link_case: 'Linked case updated',
		change_admin_description: 'Admin description updated',
		change_admin_category: 'Admin category updated',
		add_link_case: "Linked case added",
		remove_link_case: "Linked case removed",
        add_third_party: "Add related third party",
        remove_third_party: "Remove related third party",
        add_report_legal_entity: "Add related legal entity",
        remove_report_legal_entity: "Remove related legal entity"
	},

	risk: {
		upload_files: 'Uploaded files',
		upload_file: 'Uploaded file',
		download_files: 'Download files',
		download_file: 'Download file',
		update_file: 'Upload file',
		delete_file: 'Delete file',
		create_comment: 'New comment created',
		create: 'New risk created',
		update_description: 'Description updated',
		delete: 'Deleted',
		assign_analyst: 'Assigned to analyst',
		update_damage: 'Damage updated',
		update_likelihood: 'Likelihood updated',
		update_category: 'Category updated',
		change_description: 'Description updated by admin',
		change_category: 'Category updated by admin',
		create_ai: 'New Ai risk created',
	},

	task: {
		upload_files: 'Uploaded files',
		upload_file: 'Uploaded file',
		download_files: 'Download files',
		download_file: 'Download file',
		update_file: 'Upload file',
		delete_file: 'Delete file',
		create_comment: 'New comment created',
		create: 'New task created',
		assign_analyst: 'Assigned to analyst',
		update_description: 'Description updated',
		update_status: 'Status updated',
		update_priority: 'Priority updated',
		update_deadline: 'Deadline updated',
		update_category: 'Category updated',
		delete: 'Deleted',
	},

	admin: {
		change_active_directory_settings: 'Active directory settings',
		edit_risk_matrix: 'Update matrix',
		restore_risk_matrix: 'Restore matrix',
		edit_user: 'Edit user',
		create_user: 'Created user',
		delete_user: 'Deleted user',
		change_user_status: 'Changed user status',
		edit_organization: 'Edited organization',
		update_question: 'Updated question',
		remove_question: 'Removed question',
		remove_category: 'Removed category',
		login: 'Login',
	},

	document: { create: 'Created', remove: 'Removed' },

	user: { approveConsent: "approve_consent" }
};

const sectionDefinitions = {
	risk: 'Risk Management',
	task: 'Task Management',
	admin: 'Administration',
	case: 'Case Management',
	document: 'Document Management',
	user: "User"
};

const userRoll = {
	ROLE_ANALYST_ADMIN: 'Analyst admin',
	ROLE_ANALYST: 'Analyst',
	ROLE_TASK_OWNER: 'Task Owner',
	ROLE_THIRD_PARTY: 'Third Party',
	ROLE_EMPLOYEE: 'Employee'
};
export { actionDefinitions, sectionDefinitions, userRoll };
