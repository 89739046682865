import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { RiFileList3Line, RiMailLine, RiUserAddLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { getCurrentLanguage } from 'src/i18n';
import homeBackgroundImage from "src/modules/3rd-party-management/assets/images/3rd-party-home-Background.png";
import {
    ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE,
    ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
    ROUTE_PATH_THIRD_PARTY_REPLACE_CONTACT_PERSON
} from "src/modules/3rd-party-management/constants";

const Home = ({
    t, 
    Organization,
    modules
}) => {
    const [translation, setTranslation] = useState({});
    const [backgroundImage, setBackgroundImage] = useState(homeBackgroundImage);

    useEffect(() => {
        if (Organization.translations && Organization.translations.length > 0) {
            const selectedLanguage = getCurrentLanguage();

            setTranslation(Organization.translations.find(item => item.lang === selectedLanguage && item.module === 'tp') || {});
        }
    }, [Organization.translations, t]);

    useEffect(() => {   
        if(Organization.landingPicture){
            setBackgroundImage(Organization.landingPicture)
        }
    }, [ Organization.landingPicture ])

    return (
        <div 
            style={{
                marginTop: '70px'
            }}
        >
            <Container fluid>
                <Row>
                    <Col>
                        <div className="third-party-home-page">

                            <div 
                                className='third-party-header' 
                                style={{
                                    backgroundImage: `url(${backgroundImage})`
                                }}
                            />

                            <div className='actions'>
                                <Link 
                                    to={ROUTE_PATH_THIRD_PARTY_REPORT_LIST}
                                >
                                    <div className='action-button-container'>
                                        <RiMailLine  
                                            className='quick-access-box-icon'
                                        />
                                        <span 
                                            className='quick-access-box-text'
                                        >
                                            {t("My reports")}
                                        </span>
                                    </div>
                                </Link>

                                <Link 
                                    to={ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE}
                                >
                                    <div className='action-button-container active'>
                                        <RiFileList3Line 
                                            className='quick-access-box-icon'
                                        />
                                        <span 
                                            className='quick-access-box-text'
                                        >
                                            {t("Fill questionnaire")}
                                        </span>
                                    </div>
                                </Link>

                                <Link
                                    to={ROUTE_PATH_THIRD_PARTY_REPLACE_CONTACT_PERSON}
                                >
                                    <div className='action-button-container'>
                                        <RiUserAddLine
                                            className='quick-access-box-icon' 
                                        />
                                        <span 
                                            className='quick-access-box-text'
                                        >
                                            {t("Replace contact person")}
                                        </span>
                                    </div>
                                </Link>
                            </div>

                            <div className='translation-content mt-3 mb-5'>
                                {modules?.isLoading ? (
                                    <Row>
                                        <Col sm='12' className='mb-4'>
                                            <p style={{width: '30%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '50%'}} className={`dt-field dt-skeleton`}></p>
                                        </Col>
                                        <Col sm='12'>
                                            <p style={{width: '30%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                                            <p style={{width: '50%'}} className={`dt-field dt-skeleton`}></p>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col sm='12'>
                                            <div className='introText' dangerouslySetInnerHTML={{ __html: translation?.intro }} />
                                        </Col>
                                    </Row>
                                )}
                            </div> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
	const { App, Organization, Modules : modules } = state;

	return {
		App,
		Organization,
		token: state.Login.token,
        modules
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
})(Home)));