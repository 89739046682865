import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import TableFilter from "src/components/TableFilter";

const FilteringComponent = ({
    t,
    ratings,
    owners,
    managers,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        rating: null,
        owner: null,
        manager: null
    });
    const initRef = useRef(false);

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'radio_list',
                name: 'rating',
                title: 'Risk rating',
                icon: <i className="ri-bar-chart-line" />,
                isLoading: ratings.isLoading,
                defaultValue: ratings?.default,
                data: (ratings?.options || []).map((item) => ({
                    ...item,
                    id: item.name
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return t(item.name) 
                }
            },
            {
                type: 'radio_list',
                name: 'owner',
                title: 'Owner',
                icon: <i className="ri-user-line" />,
                isLoading: owners.isLoading,
                defaultValue: owners?.default,
                data: (owners?.options || []).map((item) => ({
                    ...item,
                    id: item.value
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.label
                }
            },
            {
                type: 'radio_list',
                name: 'manager',
                title: 'Manager',
                icon: <i className="ri-user-settings-fill" />,
                isLoading: managers.isLoading,
                defaultValue: managers?.default,
                data: (managers?.options || []).map((item) => ({
                    ...item,
                    id: item.value
                })),
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.label
                }
            }
        ]
    }, [
        tableFilters,
        ratings,
        owners,
        managers,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}
            if ('rating' in filters) {
                result.rating = filters.rating !== 'all' ? filters.rating : undefined
            }

            if ('owner' in filters) {
                result.owner = filters.owner !== 'all' ? filters.owner : undefined
            }

            if ('manager' in filters) {
                result.manager = filters.manager !== 'all' ? filters.manager : undefined
            }

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                rating: null,
                owner: null,
                manager: null
            })
        }
    }

    useEffect(() => {
        if(!initRef.current){
            initRef.current = true;
            return;
        }

        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='rating'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);