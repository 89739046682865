import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class SupplierLocationService {
	static instance;

	static getInstance() {
		if (!SupplierLocationService.instance) {
			SupplierLocationService.instance = new SupplierLocationService();
		}

		return SupplierLocationService.instance;
	}

    async list(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/locations`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || null;
    }

    async create(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/location/add`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async update(supplierId, locationId, payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/location/${locationId}/edit`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async delete(supplierId, locationId){
        const response = await axios.delete(`${API_BASE_URL}/supplier/location/${locationId}/delete`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }
}
