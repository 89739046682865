import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap";
import QuestionnaireDesignerService from 'src/modules/3rd-party-management/apis/admin/QuestionnaireDesignerService';

const DeactivateQuestionnaireModal = ({
    t,
    onSuccess,
    itemToDeactivate,
    hideModal
}) => {
    const {
        mutate: mutateDeactivation,
        isLoading: deactivationInProcess
    } = useMutation({
        mutationFn: async () => {
            const service = QuestionnaireDesignerService.getInstance();
            return await service.deactivateQuestionnaire(
                itemToDeactivate.id
            );
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("The questionnaire has been successfully deactivated."), {
                type: "success"
            });

            hideModal();
        },
        onError: () => {
            toast(t("Failed to deactivate the questionnaire."), {
                type: "error"
            });
        }
    });

    return (
        <Modal size="md" isOpen={ itemToDeactivate }>
            <ModalHeader toggle={ hideModal }>
                {t('Deactivate Questionnaire')}?
            </ModalHeader>
                
            <ModalBody>
                <p>
                    {t('Are you sure you want to deactivate this questionnaire? Once deactivated, it will no longer be available for selection in the report request creation process. You can reactivate it anytime if needed.')}
                </p>
            </ModalBody>

            <ModalFooter>
                <Button 
                    color="danger" 
                    onClick={ hideModal }
                    disabled={deactivationInProcess}
                > 
                    { t('Cancel') }
                </Button>

                <Button 
                    type="button" 
                    color="primary"
                    onClick={mutateDeactivation}
                    disabled={deactivationInProcess}
                >
                    {
                        deactivationInProcess && (
                            <Spinner animation="border" variant="primary" size="sm"/>
                        )
                    }
                    { t('Deactivate') }
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(DeactivateQuestionnaireModal);