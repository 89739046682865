import * as React from 'react'
import { AvForm } from 'availity-reactstrap-validation'
import { Component } from 'react'
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap'

export class DeleteConfirmationModal extends Component {
    /** ********************** COMPONENT INITIALIZATION ********************** */
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }

    /** *************************** RENDER ****************************** */
    render() {
        const { modalActivated, onModalClosed, onDeleteSubmit, t, isFile } =
            this.props
        return (
            <React.Fragment>
                <Modal
                    size='lg'
                    scrollable
                    isOpen={modalActivated}
                    backdrop='static'
                >
                    <ModalHeader toggle={onModalClosed}>
                        {!isFile ? t('Delete Task') : t('Delete file')}
                    </ModalHeader>
                    <ModalBody>
                        <AvForm
                            className='needs-validation'
                            onValidSubmit={this.handleSubmit}
                        >
                            <Row>
                                <Label>{t('Are you sure?')}</Label>
                            </Row>

                            <ModalFooter>
                                <Button
                                    color='danger'
                                    className='waves-effect waves-light'
                                    type='submit'
                                    onClick={onDeleteSubmit}
                                >
                                    {t('Delete').toUpperCase()}
                                </Button>
                                <Button
                                    color='secondary'
                                    className='waves-effect waves-light'
                                    type='button'
                                    onClick={onModalClosed}
                                >
                                    {t('Cancel').toUpperCase()}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

export default DeleteConfirmationModal
