import { withRouter, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, memo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import OverviewHeader from "../../components/overview-header";
import {
    AnswerSheet,
    helpers
} from '@smartintegrity/questionnaire';
import {
    Col, 
    Row, 
    Button, 
    Container, 
    Modal, 
    ModalHeader, 
    ModalBody,
    Spinner,
    Label,
    Card,
    CardBody
} from "reactstrap";
import SupplierReportsService from "src/modules/3rd-party-management/apis/thirdparty/SupplierReportsService";
import ReportDetailsPDF from './export/PDF';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import { API_BASE_URL } from "src/modules/3rd-party-management/constants";

const ReportDetails = (props) => {
    const {
        t,
        organization
    } = props;

    const { id : reportId } = useParams();

    const [questions, setQuestions] = useState([]);

    const [answers, setAnswers] = useState([]);

    const [ exportPDFInprocess, setExportPDFInprocess ] = useState(false);

    const dateUtils = new DateUtils();

    const {
        data: reportDetailsData,
        isFetching: reportDetailIsFetching,
        isLoading: reportDetailIsLoading
    } = useQuery({
		queryKey: ['3rd-party-management-thirdparty-fetch-supplier-report-details', reportId],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.details(reportId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});
		},
	});

    const {
        refetch: fetchReportPDFData
    } = useQuery({
		queryKey: ['3rd-party-management-thirdparty-create-report-pdf-details-query', reportId],
		queryFn: async () => {
            setExportPDFInprocess(true);
			const service = SupplierReportsService.getInstance();

            return await service.fetchPDFData(reportId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});

            setExportPDFInprocess(false);
		},
        onSuccess: async ({
            answers
        }) => {
            const fileName = `report-${reportId}.pdf`;
            const blob = await pdf( 
                <ReportDetailsPDF 
                    organization={organization} 
                    data={{
                        sections:  groupQuestionsBySection(answers)
                    }}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setExportPDFInprocess(false);
        }
	});

    useEffect(() => {
        if(reportDetailsData?.qa){
            setAnswers(
                helpers.makeAnswersReadyForAnswerSheet(
                    reportDetailsData?.qa, 
                    reportDetailsData?.qa
                )
            );

            setTimeout(() => {
                setQuestions(helpers.makeQuestionsReadyForAnswerSheet(
                    reportDetailsData?.qa,
                    true
                ))
            }, 1000);
        }
        
    }, [ reportDetailsData ]);

    const groupQuestionsBySection = (questions) => {
        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    icon : question.icon,
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const renderLoadingSection = () => {
        return (
            <React.Fragment>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const renderExportPDFWaitingModal = () => {
        return (
            <Modal isOpen={ exportPDFInprocess } toggle={ () => setExportPDFInprocess(false) }>
                <ModalHeader toggle={ () => setExportPDFInprocess(false) }>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Third Party Report')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    {t('Exporting as PDF')}...
                </ModalBody>
            </Modal>
        )
    }

    const questionnaireIsLoading = reportDetailIsFetching || reportDetailIsLoading;

    return (
        <div className='page-content report-details-page'>
            <Container fluid>
                <Row>
                    <Col sm="12" className="mb-4">
                        <OverviewHeader className='tp-view'/>
                    </Col>
                </Row>
                {
                    questionnaireIsLoading ? (
                        renderLoadingSection()
                    ) : (
                        <Row>
                            <Col sm="12" className="d-flex flex-row-reverse justify-content-start align-items-center mb-3">
                                <Button color="primary" onClick={fetchReportPDFData}>
                                    { t('Download Report PDF') }
                                </Button>
                            </Col>

                            <Col sm="12" className="mb-3">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm="12" md="6" lg="4" className="mb-4">
                                                <div className="d-flex">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-information-line"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1 align-self-center overflow-hidden">
                                                        <Label className="form-label">
                                                            {t('Report Type')}:
                                                        </Label>
                                                        <p className="text-muted mb-0">
                                                            {t(reportDetailsData?.detail?.questionnaire?.title)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col sm="12" md="6" lg="4" className="mb-4">
                                                <div className="d-flex">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-shield-user-line"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1 align-self-center overflow-hidden">
                                                        <Label className="form-label">
                                                            {t('Contact Person')}:
                                                        </Label>
                                                        <p className="text-muted mb-0">
                                                            {reportDetailsData?.detail?.contactPerson?.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col> 

                                            <Col sm="12" md="6" lg="4" className="mb-4">
                                                <div className="d-flex">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-money-dollar-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1 align-self-center overflow-hidden">
                                                        <Label className="form-label">
                                                            {t('Financial Year')}:
                                                        </Label>
                                                        <p className="text-muted mb-0">
                                                            {reportDetailsData?.detail?.financialEndYear && (
                                                                <>
                                                                    {dateUtils.convertTimeStampToDate(
                                                                        reportDetailsData.detail.financialEndYear,
                                                                        'YYYY'
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col sm="12" md="6" lg="4" className="mb-4">
                                                <div className="d-flex">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-calendar-event-line"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1 align-self-center overflow-hidden">
                                                        <Label className="form-label">
                                                            {t('Submission Date')}:
                                                        </Label>
                                                        <p className="text-muted mb-0">
                                                            {reportDetailsData?.detail?.submissionDate && (
                                                                <>
                                                                    {dateUtils.convertTimeStampToDate(
                                                                        reportDetailsData.detail.submissionDate,
                                                                        INTERNATIONAL_DATE_FORMAT
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col sm="12" md="6" lg="8" className="mb-4">
                                                <div className="d-flex">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-calendar-todo-fill"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-1 align-self-center overflow-hidden">
                                                        <Label className="form-label">
                                                            {t('Deadline')}:
                                                        </Label>
                                                        <p className="text-muted mb-0">
                                                            {reportDetailsData?.detail?.deadlineDate && (
                                                                <>
                                                                    {dateUtils.convertTimeStampToDate(
                                                                        reportDetailsData.detail.deadlineDate,
                                                                        INTERNATIONAL_DATE_FORMAT
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <AnswerSheet 
                                    settings={{
                                        buttons	:	{
                                            back	:	{
                                                enabled	:	false
                                            },
                                            confirm	:	{
                                                enabled	:	false
                                            }
                                        },
                                        fileDownloaderOptions: {
                                            downloadRequestOptions: {
                                                headers: {
                                                    Authorization: `Bearer ${props.token}`
                                                },
                                                payload: {},
                                                requestUrl: ({
                                                    questionId,
                                                    attachmentId
                                                }) => {
                                                    return `${API_BASE_URL}/report/attachment/${attachmentId}/download`
                                                }
                                            }
                                        }
                                    }}
                                    questions={ questions }
                                    answers={ answers }
                                />
                            </Col>

                            { renderExportPDFWaitingModal() }
                        </Row>
                    )
                }
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user : state.Login.user,
        organization : Organization,
        token
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(ReportDetails)))
);