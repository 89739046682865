import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import { VectorMap } from "react-jvectormap";

const RisksMap = ({
    t,
    countries,
    ratings
}) => {

    const renderVectorMap = () => {
        const result = {};

        for(const cKey in countries){
            result[cKey] = `#${countries[cKey]?.color}`;
        }
   
        return (
            <VectorMap
                map={"world_mill"}
                backgroundColor="transparent"
                zoomOnScroll={false}
                onRegionTipShow={(e, el, code) => {
                    el.text(t(el.text()) + " (" + countries[code]?.amount + ")");
                }}
                onRegionOut={(e, code) => {
                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => {
                        el.style.display = "none";
                    });
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0,
                        cursor: "pointer",
                    },
                    hover: {
                        "fill-opacity": 0.8,
                        cursor: "pointer",
                    },
                    selected: {
                        fill: "#212121",
                        stroke: "#d1d1d1",
                        "stroke-width": 1,
                    },
                    selectedHover: {
                        "fill-opacity": 0.8,
                    },
                }}
                regionsSelectable={false}
                series={{
                    regions: [
                        {
                            values: result,
                            normalizeFunction: "polynomial",
                        },
                    ],
                }}
            />
        )
    }

    return (
        <Card>
            <CardBody>
                <div className="mb-4">
                    <CardTitle>
                        { t("Risk Heatmap") }
                    </CardTitle>
                </div>
                <div id="usa-vectormap">
                    { renderVectorMap() }
                </div>
                <div className="d-flex justify-content-center mt-5">
                    {(ratings || []).map((rating) => {
                        return (
                            <div class="text-center ml-4">
                                <p class="mb-2 text-truncate">
                                    <i class="mdi mdi-circle font-size-20 me-1" style={{
                                        color : `#${rating?.color}`
                                    }}></i> 
                                    {t(rating?.long_name)}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </CardBody>
        </Card>
    )
};

export default withNamespaces()(RisksMap);