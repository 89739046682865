import { lazy } from 'react';
import * as TPExports from './tp';
import * as GDExports from './gd';
import * as WBExports from './wb';
import * as CMExports from './cm';

export const ModulesComponents = {
    tp: {
        RisksFiltering: lazy(() => import('./tp/components/risks-filtering')),
        TasksFiltering: lazy(() => import('./tp/components/tasks-filtering'))
    },
    gd: {
        RisksFiltering: lazy(() => import('./gd/components/risks-filtering')),
        TasksFiltering: lazy(() => import('./gd/components/tasks-filtering'))
    },
    wb: {
        RisksFiltering: lazy(() => import('./wb/components/risks-filtering')),
        TasksFiltering: lazy(() => import('./wb/components/tasks-filtering'))
    },
    cm: {
        RisksFiltering: lazy(() => import('./cm/components/risks-filtering')),
        TasksFiltering: lazy(() => import('./cm/components/tasks-filtering'))
    }
}

export const ModulesActions = {
    tp: TPExports.Actions,
    gd: GDExports.Actions,
    wb: WBExports.Actions,
    cm: CMExports.Actions
}

export const ModulesApiRoutes = {
    tp: TPExports.APIRoutes,
    gd: GDExports.APIRoutes,
    wb: WBExports.APIRoutes,
    cm: CMExports.APIRoutes,
}