import { withNamespaces } from "react-i18next";
import { Row, Col, CardBody, Card, CardTitle, UncontrolledTooltip } from "reactstrap";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
	Container
} from 'reactstrap';

import DashboardService from "../../../apis/admin/DashboardService";

import DownloadStatistics from './components/DownloadStatistics';
import RisksMap from './components/RiskMap';
import Widget from "./components/Widget";

import { Polar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";

import RiskMatrix from "src/modules/3rd-party-management/components/RisksAndTasks/risks/matrix";

import {
	SuppliersStatuses,
	TasksStatuses
} from 'src/modules/3rd-party-management/constants';
import PageHeader from "src/modules/3rd-party-management/components/page-header";
import Filtering from "./components/Filtering";

const Dashboard = ({
    t
}) => {
	const [ thirdPartiesCount, setThirdpartiesCount ] = useState(0);
	const [ riskAssessmentCount, setRiskAssessmentCount ] = useState(0);
	const [ statusesList, setStatusesList ] = useState([]);
	const [ countriesList, setCountriesList ] = useState([]);
	const [ riskRateCategory, setRiskRateCategory ] = useState(null);
	const [ suppliersScore, setSuppliersScore ] = useState(null);
	const [ suppliersByDate, setSuppliersByDate ] = useState(null);
	const [ myTasks, setMyTasks ] = useState(null);
	const [ riskRates, setRiskRates] = useState(null);
	const [ gotReports, setGotReports ] = useState(null);
	const [ dueDiligenceReports, setDueDiligenceReports ] = useState(null);
	const [ riskRateMatrix, setRiskRateMatrix ] = useState(null);
	const [ countriesRatings, setCountriesRatings ] = useState([]);
    const [ filters, setFilters ] = useState({});

    const handleFetchDashboardQuery = useQuery({
		queryKey: [
            "3rd-party-management-fetch-admin-dashboard-query", 
            filters
        ],
		queryFn: async () => {
		  const service = DashboardService.getInstance();

          const supplierIds = [...filters?.supplierIds || [], ...filters?.companyIds || []];
	
		  return await service.fetch({
            ...filters,
            supplierIds: supplierIds.length > 0 ? supplierIds : undefined,
            companyIds: undefined
          });
		},
		cacheTime: 0,
        refetchOnWindowFocus: false,
		onError: (error) => {
		  if (process.env.NODE_ENV === 'development') console.error(error);
	
		  toast(t("An error occurred while fetching dashboard data."), {
			type: "error",
		  });
		},
	});

    const handleFilterOnChange = useCallback((newFilters) => {
        setFilters((currentFilters) => ({...currentFilters, ...newFilters}));
    }, []);

	useEffect(() => {
		const { data } = handleFetchDashboardQuery;

		if(data){
			setStatusesList(data?.statuses || []);
			setCountriesList(data?.allCountries || []);
			setThirdpartiesCount(data?.thirdParties || 0);
			setRiskRateCategory(data?.riskRateCategory || null)
			setSuppliersScore(data?.suppliersScore || null);
			setSuppliersByDate(data?.suppliersByDate || null);
			setMyTasks(data?.myTasks || null);
			setRiskRates(data?.riskRate || null);
			setGotReports(data?.reportsByDate || null);
			setDueDiligenceReports(data?.dueDiligenceReports || null);
			setRiskAssessmentCount(data?.riskAssessmentCount || 0);
			setRiskRateMatrix(data?.riskRateMatrix || null);
			setCountriesRatings(data?.rating || [])
		}
	}, [handleFetchDashboardQuery.data]);

	const loadingDashboard = handleFetchDashboardQuery.isLoading || handleFetchDashboardQuery.isFetching;

	const convertHexToRGBA = (hexCode, opacity = 1) => {  
		let hex = hexCode.replace('#', '');
		
		if (hex.length === 3) {
			hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
		}    
		
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);
		
		/* Backward compatibility for whole number based opacity values. */
		if (opacity > 1 && opacity <= 100) {
			opacity = opacity / 100;   
		}
	
		return `rgba(${r},${g},${b},${opacity})`;
	};

	const createColorFromString = (str) => {
		const hashCode = (str) => {
			var hash = 0;
			for (var i = 0; i < str.length; i++) {
			   hash = str.charCodeAt(i) + ((hash << 5) - hash);
			}
			return hash;
		} 
	
		const intToRGB = (i) => {
			var c = (i & 0x00FFFFFF)
				.toString(16)
				.toUpperCase();
		
			return "00000".substring(0, 6 - c.length) + c;
		}

		return `#${intToRGB(hashCode(str))}`;
	}

	const renderWidgetsList = () => {
		if(loadingDashboard || !statusesList.length){
			return (
				<div className="mb-4">
					<Row className="mb-3">
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
					</Row>
					<Row>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
					</Row>
				</div>
            );
		}

		const widgets = [];

		for (const item in statusesList) {
			widgets.push({
				amount	:	statusesList[item].amount,
				title	: 	SuppliersStatuses[statusesList[item]?.key]?.title,
				icon	: 	null,
				chart: {
					show	:	true,
					percent : 	statusesList[item]?.percent || 0
				},
				color	:	convertHexToRGBA(SuppliersStatuses[statusesList[item]?.key]?.color, 0.2)
			});
		}

		return (
			<>
				<Row>
					<Col lg={4}>
						<Widget {...{
							amount	:	thirdPartiesCount,
							title	: 	'Third Parties',
							icon	: 	'ri-stack-line'
						}} />
					</Col>
					<Col lg={4}>
						<Widget {...{
							amount	:	riskAssessmentCount,
							title	: 	'Risk Assessments',
							icon	: 	'ri-stack-line'
						}} />
					</Col>
					{
						widgets.length > 0 && (
							<Col lg={4}>
								<Widget {...widgets[0]} />
							</Col>
						)
					}
				</Row>
				<Row>
					{
						widgets.slice(1).map((widgetData) => {
							return (
								<Col lg={4}>
									<Widget {...widgetData} />
								</Col>
							)
						})
					}
				</Row>
			</>
		);
	};

	const renderRiskRateCategoryChart = () => {
		if(loadingDashboard){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			);
		}
		const labels = (riskRateCategory || []).map((item) => t(item.name)); // x-axis labels

		const series = [
			{
				name: t("Amount"), 
				data: riskRateCategory?.map((item) => item.amount), 
			},
		];

		const colors = (riskRateCategory || []).map((item) => createColorFromString(item.name)); 

		const options = {
			chart: {
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "50%",
					distributed: true, 
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: true,
			},
			stroke: {
				show: true,
				width: 1,
				colors: ["#f1f1f1"],
			},
			colors: colors,
			xaxis: {
				categories: labels, 
			},
			yaxis: {
				labels: {
					formatter: (val) => parseInt(val),
				},
			},
			legend: {
				show: true,
				position: "bottom", 
				labels: {
					usePointStyle: true, 
				},
				formatter: (seriesName, opts) => {
					return labels[opts.seriesIndex] || seriesName;
				},
			},
			grid: {
				borderColor: "#f1f1f1",
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: (val) => `${val} risks`,
				},
			},
		};


        return (
            <Card>
				<CardBody>
					<CardTitle>
						<strong>{ t('Top 7 Risk Categories') }</strong>
					</CardTitle>
                    <ReactApexChart 
                        options={options} 
                        series={series} 
                        type="bar" 
                        height={320} 
                    />
                </CardBody>
            </Card>
        );

	}

	const renderSuppliersScoreChart = () => {
		if(loadingDashboard || !suppliersScore){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			);
		}

		const dataSetData = [];
        const percents = [];

		const options = {
			legend: {
				position : 'bottom',
				labels : {
					usePointStyle : true
				}
			},
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const percents = dataset.percents || [];
                        const label = data.labels[tooltipItem.index] || "";
                        const value = dataset.data[tooltipItem.index] || 0;

                        return `${label}: ${value} (${percents[tooltipItem.index]}%)`;
                    }
                }
            }
		};

		const labels = [
			t("Less than 3"),
			t("3-6"),
			t("6-8"),
			t("More than 8")
		];

		const colors = [
			"#FF3D60",
			"#FCB92C",
			"#5664D2",
			"#1CBB8C"
		];

		for(const scoreName in suppliersScore){
			dataSetData.push(suppliersScore[scoreName]?.amount);
            percents.push(suppliersScore[scoreName]?.percent)
		}

		const data = {
			datasets	:	[{
				data				: 	dataSetData,
				backgroundColor		: 	colors,
				label				: 	"Third-party Score",
				hoverBorderColor	:	"#fff",
                percents            :   percents
			}],
			labels		:	labels
		};

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t("Third-party Score") }
						</CardTitle>
					</div>
					<div className="mb-3">
						<Polar height={270} data={ data } options={ options }/>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderSuppliersPerMonthChart = () => {
		if(loadingDashboard || !suppliersByDate){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			)
		}

		const dataSetData = [];

		const statuses = {
			"added"		:	{
				title	:	'Added',
				color	:	'#414FBD'
			},
			"total"		:	{
				title	:	'Total',
				color	:	'#3EC295'
			},
			"deleted"	:	{
				title	:	'Deleted',
				color	:	'#FCBD36'
			}
		};

		const {
			dates : labels,
			data,
			text
		} = suppliersByDate;

		let maxValue = 0;

		for(const keyName in data){
			const color = statuses[keyName]?.color;

			const labelData = [];

			for (let labelIndex = 0; labelIndex < labels.length; labelIndex++) {
				const label = labels[labelIndex];
				
				const val = data[keyName][label] || 0;

				labelData.push(val);

				if(val > maxValue){
					maxValue = val;
				}
			}

			dataSetData.push({
				label						:	t(statuses[keyName]?.title),
				fill						:	true,
				lineTension					:	0.5,
				backgroundColor				:	convertHexToRGBA(color, 0.2),
				borderColor					:	color,
				borderCapStyle				:	'butt',
				borderDash					:	[],
				borderDashOffset			:	0.0,
				borderJoinStyle				:	'miter',
				pointBorderColor			:	color,
				pointBackgroundColor		:	color,
				pointBorderWidth			:	1,
				pointHoverRadius			:	5,
				pointHoverBackgroundColor	:	color,
				pointHoverBorderColor		:	"#fff",
				pointHoverBorderWidth		:	2,
				pointRadius					:	1,
				pointHitRadius				:	10,
				data						:	labelData
			});
		}

		const options = {
			legend: {
				position : 'bottom',
				labels : {
					usePointStyle : true
				}
			},
			scales: {
				yAxes: [{
					ticks: {
						max			: maxValue + 1,
						min			: 0,
						stepSize	: 1
					}
				}],
                xAxes: [{
					ticks: {
						callback: (value) => {
                            const splitedValue = value.split(' ')

                            return `${t(splitedValue[0])} ${t(splitedValue[1])}`;
                        }
					}
				}]
			},
            tooltips: {
                callbacks: {
                    title: (positions, dataset) => {
                        const splitedLabel = (dataset.labels[positions[0].index] || '').split(' ')
                        return `${t(splitedLabel[0])} ${t(splitedLabel[1])}`;
                    }
                }
            }
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t(`Third Party Per ${text.charAt(0).toUpperCase() + text.slice(1)}`) }
						</CardTitle>
					</div>
					<div className="mb-3">
						<Line height={270} data={{
							labels		: 	labels,
							datasets	:	dataSetData
						}} options={options} />
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderMyTasksChart = () => {
		if(loadingDashboard || !myTasks){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const index in myTasks){
			series.push(myTasks[index]?.amount);
			labels.push(t(TasksStatuses[index]?.title));
			colors.push(TasksStatuses[index]?.color);
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Tasks')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="255"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};

	const renderDueDiligenceChart = () => {
		if(loadingDashboard || !dueDiligenceReports){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const statuses = {
			missing		:	{
				title	:	'Missing',
				color	:	'#F693FF'
			},
			received	:	{
				title	:	'Received ',
				color	:	'#4CD4AC'
			},
			overdue		:	{
				title	:	'Overdue',
				color	:	'#FFEE93'
			}
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const statusKey in statuses){
			series.push(parseInt(dueDiligenceReports[statusKey] || 0));
			labels.push(t((statuses[statusKey]?.title).trim()));
			colors.push(statuses[statusKey]?.color);
		}
		
		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Due Diligence Reports')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="270"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderRiskLevelsChart = () => {
		if(loadingDashboard || !riskRates){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const index in riskRates){
			series.push(riskRates[index].amount);
			labels.push(t(riskRates[index].title));
			colors.push(`#${riskRates[index].color}`);
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Third Party Risk Level')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="255"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderReceivedReportsByDataChart = () => {
		if(loadingDashboard || !gotReports){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '20%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const names = [];
		const amounts = [];
		const colors = [];

		const {
			dates : labels,
			data,
			text
		} = gotReports;

        let totalCount = 0;

		for(const title in data){
			names.push(title);
			
			const itemData = {
				data : []
			};

            let count = 0;

			labels.forEach((label) => {
				itemData.data.push(parseInt(data[title][label]));
                count += parseInt(data[title][label]);
			});

            totalCount =+ count;

			amounts.push(itemData);

			colors.push(createColorFromString(title));
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t(`Reports Received Per ${text.charAt(0).toUpperCase() + text.slice(1)}`) }
							<i className="ri-information-line ms-2" id="receivedReportsInfoIcon" />
                            <UncontrolledTooltip
                                placement="top"
                                target="receivedReportsInfoIcon"
                                trigger="hover"
                                >
                                    {t(
                                    	"These reports include both third-party data and reports from your own company."
                                    )}
                            </UncontrolledTooltip>
						</CardTitle>
					</div>
					<div className="mb-3">
						<ReactApexChart
							options={{
								colors : colors,
								legend: {
									show: false,
								},
								chart: {
									toolbar: {
										show: false,
									},
								},
								plotOptions: {
									bar: {
										horizontal: false,
										distributed: true
									},
								},
								dataLabels: {
									enabled: true,
									position: "center",
									style: {
										fontSize: "12px",
									},
									formatter: function (val) {
										return val;
									},
								},
								xaxis: {
									position: "bottom",
									categories: labels,
									axisBorder: {
										show: true,
									},
									axisTicks: {
										show: false,
									},
                                    labels: {
                                        formatter: (val) => {
                                            const splitedVal = val.split(' ')
                                            return `${t(splitedVal[0])} ${t(splitedVal[1])}`;
                                        }
                                    }
								},
                                yaxis: {
                                    show: totalCount > 0 ? true : false, 
                                    labels: {
                                        formatter: (val) => {
                                            return val.toFixed(0)
                                        }
                                    }
                                },
								tooltip: {
									theme: "light",
									enabled: true,
									followCursor: true,
								}
							}}
							series={ amounts }
							type="bar"
							height="230px"
						/>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderLocations = () => {
		if(loadingDashboard || !countriesRatings || !countriesList){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '20%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '360px'}}></div>
					</CardBody>
				</Card>
            );
		}

		return (
			<RisksMap countries={ countriesList } ratings={ countriesRatings } />
		);
	}

    return (
		<div className='page-content supplier-dashboard-page'>
			<Container fluid>
				<PageHeader title={t('Dashboard')} />

				<Row className="mb-4 mt-4">
					<Col lg="12">
                        <div className="d-flex justify-content-end align-items-start">
                            <Filtering 
                                onChange={handleFilterOnChange}
                            />
                            <DownloadStatistics 
                                filters={filters} 
                            />
                        </div>
					</Col>
				</Row>

				<Row>
					<Col lg={9}>
						{ renderWidgetsList() }

						<Row>
							<Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            {t('Risk Matrix')}
                                        </CardTitle>

                                        <RiskMatrix
                                            matrix={riskRateMatrix?.matrix}
                                            consequences={riskRateMatrix?.consequences}
                                            currency={riskRateMatrix?.currency}
                                            likelihoods={riskRateMatrix?.likelihoods}
                                            risks={null}
                                            ratings={riskRateMatrix?.ratings}
                                            isLoading={ loadingDashboard }
                                        />
                                    </CardBody>
                                </Card>
							</Col>
						</Row>
					</Col>
					
					<Col lg={3}>
						{ renderRiskLevelsChart() }

						{ renderMyTasksChart() }
					</Col>
				</Row>

				<Row>
					<Col lg={9}>
						{ renderReceivedReportsByDataChart() }
					</Col>

					<Col lg={3}>
						{ renderDueDiligenceChart() }
					</Col>
				</Row>

				<Row>
                    <Col lg={6}>
						{ renderRiskRateCategoryChart() }
					</Col>

                    <Col lg='3'>
                        { renderSuppliersScoreChart() }
                    </Col>	

                    <Col lg='3'>
                        { renderSuppliersPerMonthChart() }  
                    </Col>				
				</Row>

				<Row>
					<Col lg={12}>
						{ renderLocations() }
					</Col>
				</Row>
			</Container>
		</div>
    );
}

export default withNamespaces()(Dashboard);