import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class ContactPersonService {
	static instance;

	static getInstance() {
		if (!ContactPersonService.instance) {
			ContactPersonService.instance = new ContactPersonService();
		}

		return ContactPersonService.instance;
	}

    async replace(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/contact_person/replacement`, payload,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`
                }
            }
        );
  
        return response.data.data
    }
}
