import axios from 'axios'
import { API_BASE_URL } from 'src/common/constants'

export default class ThirdpartyManagementService {
    static instance

    static getInstance() {
        if (!ThirdpartyManagementService.instance) {
            ThirdpartyManagementService.instance = new ThirdpartyManagementService()
        }
        return ThirdpartyManagementService.instance
    }

    async fetchSuppliersList(params = {}, module = 'wb') {
        const response = await axios.get(
            `${API_BASE_URL}${module === 'cm' ? '/cm' : ''}/organization/suppliers`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

}
