import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AdminView from './components/AdminView'
import AnalystView from './components/AnalystView'

const Overview = ({ user }) => {
    const roles = user?.roles || []

    if (roles.includes('ROLE_ANALYST_ADMIN')) {
        return <AdminView />
    }

    return <AnalystView />
}

const mapStatetoProps = (state) => {
    return {
        user: state.Login.user
    }
}

export default withRouter(connect(mapStatetoProps, {})(Overview))
