import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import Select from "react-select";

const AsyncReactSelect = ({
    t,
    loadOptions: fetchOptions,
    settings,
    ...props
}) => {
    const [ options, setOptions ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ hasMore, setHasMore ] = useState(true);
    const [ searchText, setSearchText ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState();
    const menuRef = useRef(null);
    const observer = useRef(null);
    const paginationRef = useRef({
        page: 1,
        loading: false,
        hasMore: true,
        searchText: ''
    });
    const searchInitCall = useRef(null);

    const loadOptions = async () => {
        if (paginationRef.current.loading || !paginationRef.current.hasMore) return;
        
        setLoading(true);
        setErrorMessage(null);
        try{
            const {
                options,
                pageIndex,
                pageSize,
                totalCount
            } = await fetchOptions({
                pageIndex: paginationRef.current.page,
                pageSize: 25,
                [settings?.queryKey || 'q']: paginationRef.current.searchText || undefined
            });
    
            setOptions((prev) => {
                return [...prev, ...options];
            });
    
            setHasMore((parseInt(pageIndex) * parseInt(pageSize)) < parseInt(totalCount));
    
            paginationRef.current.page += 1;
        }
        catch(err){
            setErrorMessage(settings?.loadOptionsErrorMessage || 'Faild to fetch');
        }
        setLoading(false);
    };

    const handleScroll = () => {
        if (!menuRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            loadOptions();
        }
    };

    const attachScrollListener = () => {
        if (!menuRef.current) return;
        menuRef.current.addEventListener("scroll", handleScroll);
    };

    const resetPagination = () => {
        paginationRef.current.page = 1;
        setLoading(false);
        setHasMore(true);
        setOptions([]);
    }
    
    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new MutationObserver(() => {
            const menuList = document.querySelector(".select__menu-list");
            if (menuList) {
                menuRef.current = menuList;
                attachScrollListener();
            }
        });

        observer.current.observe(
            document.body, 
            { 
                childList: true, 
                subtree: true 
            }
        );

        return () => observer.current.disconnect();
    }, []);

    useEffect(() => {
        paginationRef.current.loading = loading;
        paginationRef.current.hasMore = hasMore;  
    }, [ loading, hasMore ]);

    useEffect(() => {
        resetPagination();

        setTimeout(() => {
            loadOptions();
        }, 250);
    }, [ fetchOptions ]);

    useEffect(() => {
        if(!searchInitCall?.current) {
            searchInitCall.current = true;
            return;
        }

        paginationRef.current.searchText = searchText;

        const timeOutId = setTimeout(() => {
            resetPagination();

            setTimeout(() => {
                loadOptions();
            }, 250);
        }, 750);

        return () => clearTimeout(timeOutId);
    }, [ searchText ])

    return (
        <>
            <Select
                {...props}
                options={options}
                isLoading={loading}
                menuPortalTarget={document.body}
                classNamePrefix="asyn__select"
                onInputChange={(input) => {
                    setSearchText(input)
                }}
                filterOption={(e) => e}
                onMenuOpen={() => {
                    setTimeout(() => {
                        const menuList = document.querySelector(".asyn__select__menu-list");
                        if (menuList) {
                            menuRef.current = menuList;
                            attachScrollListener();
                        }
                    }, 0);
                }}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: errorMessage ? 'red' : base.borderColor,
                        boxShadow: 'none',
                    }),
                }}
            />

            {errorMessage && (
                <div className="d-block invalid-feedback mt-1">
                    {t(errorMessage)}
                </div>
            )}
        </>
    );
};

export default withNamespaces()(
    AsyncReactSelect
);