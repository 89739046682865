import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class SupplierService {
	static instance;

	static getInstance() {
		if (!SupplierService.instance) {
			SupplierService.instance = new SupplierService();
		}

		return SupplierService.instance;
	}

    async fetchOverviewContent(params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/header`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }

    async fetchCompanyDetail(params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/general_information`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }

    async updateCompanyDetail(payload = {}) {
        const response = await axios.post(`${API_BASE_URL}/supplier/edit_information`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data.data;
    }

    async fetchUserReportRequests(params = {}) {
        const response = await axios.get(`${API_BASE_URL}/supplier/report/request/open_list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });

        return response.data.data;
    }

    async fetchGeneralInformation(orgId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/third_party/${orgId}/detail`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }
}
