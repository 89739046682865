import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TabPane, Col, Button, Row } from 'reactstrap';
import {
	INTERNATIONAL_DATE_FORMAT,
	FETCH_TASKS_FAILED,
	DELETE_TASK_SUCCESSFUL,
	DELETE_TASK_FAILED,
	FETCH_OWNERS_FAILED,
	FETCH_TASKS_CATEGORIES_FAILED,
	EMPTY_LIST,
} from '../../../../common/constants';

import {
    TasksImpacts
} from 'src/common/constants';

import { HiChevronDoubleUp, HiChevronDoubleDown, HiMenuAlt4 } from 'react-icons/hi';
import axios from 'axios';
import DateUtils from '../../../../services/utils/DateUtils';
import MemorizedDeleteTaskModal from './DeleteTaskModal';
import MemorizedCreateTaskModal from './CreateTaskModal';
import MemorizedTaskDetails from './TaskDetails';
import TableWithPagination from "../../../../components/Pagination";
import './TaskManager.scss';
import FilteringComponent from './FilteringComponent';


const TaskManager = function (props) {
	TaskManager.displayName = 'Memorized Component - Task Manager';
	if (process?.env?.NODE_ENV === 'development') console.log('---- rendering memorized component >>> TaskManager');

	const { isActive, authToken, reportId, t, successNotification, errorNotification, isDeleted, deletedByName, module } = props;
	const dateUtils = new DateUtils();

	const [deleteTaskModalStatus, setDeleteTaskModalStatus] = useState(false);
	const [deleteTaskId, setDeleteTaskId] = useState(false);
	const [createTaskModalStatus, setCreateTaskModalStatus] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedTaskId, setSelectedTaskId] = useState(null);

	const [tasks, setTasks] = useState(null);
	const [categories, setCategories] = useState(null);
	const [owners, setOwners] = useState(null);

	const [loadingTasks, setLoadingTasks] = useState(false);
	const [loadingCategories, setLoadingCategories] = useState(false);
	const [loadingOwners, setLoadingOwners] = useState(false);

	const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
	const [selectedPriorityFilter, setSelectedPriorityFilter] = useState(null);
	const [selectedOwnerFilter, setSelectedOwnerFilter] = useState(null);

	const [pageSize, setPageSize] = useState(100);
	const [pageIndex, setPageIndex] = useState(1);
	const [itemsCount,setItemsCount] = useState(0);

	const [sort, setSort] = useState({
        sortField:  null,
        order : null
      })

	const priorities = useMemo(() => {
		return [
			{
				value: 'low',
				baseLabel: 'Low',
				label: (
					<>
						<HiChevronDoubleDown className='text-info' />
						<span>{t('Low')}</span>
					</>
				),
			},
			{
				value: 'medium',
				baseLabel: 'Medium',
				label: (
					<>
						<HiMenuAlt4 className='text-success' />
						<span>{t('Medium')}</span>
					</>
				),
			},
			{
				value: 'high',
				baseLabel: 'High',
				label: (
					<>
						<HiChevronDoubleUp className='text-danger' />
						<span>{t('High')}</span>
					</>
				),
			},
		];
	}, [t]);

	const statuses = useMemo(() => {
		return [
			{ value: 'NEW', label: t('New') },
			{ value: 'IN_PROGRESS', label: t('In Progress') },
			{ value: 'REVIEW', label: t('Review') },
			{ value: 'CLOSED', label: t('Closed') },
			{ value: 'DELETED', label: t('Deleted') },
		];
	}, [t]);

	const handleFilterRisks = async ({
        status, 
        priority, 
        owner
    }) => {
		let shouldFetch = false;
		if (!selectedStatusFilter || (selectedStatusFilter && !status) || selectedStatusFilter.value !== status) {
			shouldFetch = true;
			setSelectedStatusFilter(statuses.find((s) => s.value === status));
		}
		if (!selectedPriorityFilter || (selectedPriorityFilter && !priority) || selectedPriorityFilter.value !== priority.value) {
			shouldFetch = true;
			setSelectedPriorityFilter(priorities.find((p) => p.value === priority));
		}
		if (!selectedOwnerFilter || (selectedOwnerFilter && !owner) || selectedOwnerFilter.value !== owner.value) {
			shouldFetch = true;
			setSelectedOwnerFilter(owners.find((o) => o.value === owner));
		}

		if (shouldFetch) {
			// getRisksAbortController.abort();
			// getRisksAbortController = new AbortController();
			// abortController.abort();
			// setAbortController(new AbortController());

			handleFetchTasks(status, priority, owner, reportId, authToken);
		}
	};

	/**
	 * this method fetches the tasks for the selected case.
	 * @param {Object} caseId the ID of the case you want get tasks for.
	 * @param {String} token authorization token.
	 */
	const handleFetchTasks = useCallback(
		async (status, priority, owner, caseId, token, indexOfPage=1) => {
			setLoadingTasks(true);
			setTasks(null);

			const requestParams = {};
			if (status) requestParams['status'] = status;
			if (priority) requestParams['priority'] = priority;
			if (owner) requestParams['owner'] = owner;

			try {
				const result = await axios.post(
					module === 'wb'
						? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/report/${caseId}/tasks`
						: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/report/${caseId}/tasks`,
					{
						pageSize: pageSize,
						pageIndex: indexOfPage,
						...requestParams,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					setTasks(result.data.data.tasks);
					setItemsCount(result.data.data.itemsCount)
				} else {
					errorNotification({
						message: t(FETCH_TASKS_FAILED),
					});
				}
			} catch (error) {
				if ((process?.env?.NODE_ENV === 'development' && !axios.isAxiosError(error)) || (axios.isAxiosError(error) && error.name !== 'CanceledError')) {
					console.error('this error only appears in the development environment:\nerror while fetching tasks', error);
				}

				if (!axios.isAxiosError(error) || (axios.isAxiosError(error) && error.name !== 'CanceledError')) {
					errorNotification({
						message: t(FETCH_TASKS_FAILED),
					});
				}
			}
			setLoadingTasks(false);
		},
		[errorNotification, t],
	);

	/**
	 * this method deletes the task.
	 * @param {String} taskId the ID of the task you want to delete.
	 * @param {String} caseId the ID of the case that is owner of the task.
	 * @param {String} token authorization token.
	 */
	const handleDeleteTask = useCallback(
		async (taskId, caseId, token) => {
			setIsDeleting(true);
			try {
				const result = await axios.delete(
					module === 'wb'
						? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/task/${taskId}/delete`
						: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/task/${taskId}/delete`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					successNotification({
						message: t(DELETE_TASK_SUCCESSFUL),
					});

					if (caseId) {
						handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, caseId, token);
						setDeleteTaskModalStatus(false);
						setDeleteTaskId(null);
					}

					return true;
				} else {
					errorNotification({
						message: t(DELETE_TASK_FAILED),
					});

					if (caseId) {
						setDeleteTaskModalStatus(false);
						setDeleteTaskId(null);
					}

					return false;
				}
			} catch (error) {
				if (process?.env?.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while deleting the task:', error);
				}   

                if(error?.response?.status === 400){
                    errorNotification({
                        message: t(error.response?.data?.error)
                    });
                }
                else{
                    errorNotification({
                        message: t(DELETE_TASK_FAILED),
                    });
                }

				if (caseId) {
					setDeleteTaskModalStatus(false);
					setDeleteTaskId(null);
				}

				return false;
			} finally {
				setIsDeleting(false);
			}
		},
		[handleFetchTasks, successNotification, errorNotification, t, selectedStatusFilter, selectedPriorityFilter, selectedOwnerFilter, isDeleting],
	);

	/**
	 * this method fetches the list of owners.
	 * @param {String} token authorization token.
	 */
	const handleFetchOwners = useCallback(
		async (token) => {
			setLoadingOwners(true);
			try {
				const result = await axios.post(
					module === 'wb'
						? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/analystAndAdminAnalystAndTaskOwners.list`
						: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/analystAndAdminAnalystAndTaskOwners.list`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					const owners = result.data.data.map((item) => {
						return {
							value: item.id,
							label: `${item.first_name} ${item.last_name}`,
						};
					});

					setOwners(owners);
				} else {
					errorNotification({
						message: t(FETCH_OWNERS_FAILED),
					});
				}
			} catch (error) {
				if (process?.env?.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching owners:', error);
				}

				errorNotification({
					message: t(FETCH_OWNERS_FAILED),
				});
			}
			setLoadingOwners(false);
		},
		[errorNotification, t],
	);

	/**
	 * this method fetches the list of tasks categories.
	 * @param {String} token authorization token.
	 */
	const handleFetchCategories = useCallback(
		async (token) => {
			setLoadingCategories(true);
			const isMitigationTask = tasks?.find((task) => task.id === selectedTaskId)?.is_mitigation ?? false;
			const mitigationPath = isMitigationTask ? 'mitigation-' : '';
			try {
				const result = await axios.get(
					module === 'wb'
						? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/${mitigationPath}task/categories`
						: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/${mitigationPath}task/categories`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					const categories = result.data.data.map((item) => {
						return {
							value: item.id,
							baseLabel: item.name,
							label: t(item.name),
						};
					});

					setCategories(categories);
				} else {
					errorNotification({
						message: t(FETCH_TASKS_CATEGORIES_FAILED),
					});
				}
			} catch (error) {
				if (process?.env?.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching categories:', error);
				}

				errorNotification({
					message: t(FETCH_TASKS_CATEGORIES_FAILED),
				});
			}
			setLoadingCategories(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification, t],
	);

	useEffect(() => {
		if (isActive) {
			if (!tasks && !loadingTasks) handleFetchTasks(null, null, null, reportId, authToken);
			if (!categories && !loadingCategories) handleFetchCategories(authToken);
			if (!owners && !loadingOwners) handleFetchOwners(authToken);
		}
	}, [
		isActive,
		authToken,
		reportId,
		tasks,
		categories,
		owners,
		loadingTasks,
		loadingCategories,
		loadingOwners,
		handleFetchTasks,
		handleFetchCategories,
		handleFetchOwners,
	]);

    useEffect(() => {
        isActive && handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, reportId, authToken);
    }, [isActive])

	useEffect(() => {
		if (selectedPriorityFilter) {
			const priority = priorities.find((p) => p.value === selectedPriorityFilter.value);
			setSelectedPriorityFilter(priority);
		}
		if (selectedStatusFilter) {
			const status = statuses.find((p) => p.value === selectedStatusFilter.value);
			setSelectedStatusFilter(status);
		}

		handleFetchCategories(authToken);
		handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, reportId, authToken);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	useEffect(() => {
		if (window.location.pathname.includes('task')) {
			const str = window.location.pathname.split('/');
			const taskId = Number(str.slice(str.length - 1, str.length)[0]);
			setSelectedTaskId(taskId);
		}
	}, []);

	useEffect(() => {
		if(isActive) document.title = 'Report Details - Task Manager | SIP';
	}, [isActive]);

	const onTableChange = (type, newState) => {
		if(type === 'sort' && (newState.sortField !== sort.sortField || newState.sortOrder !== sort.order)){
			setSort({
					  sortField: newState.sortField,
					  order: newState.sortOrder
				});
			return handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, reportId, authToken);
		//   this.setState({
		// 	sort: {
		// 	  sortField: newState.sortField,
		// 	  order: newState.sortOrder
		// 	}
		//   }, () => {
		// 	this.fetchReports(this.getFilters());
		//   });
		}
	  }

	const handleProvideTableColumns = () => {
		let columns = [];
		
		columns = columns.concat([
		  {
			dataField: "secondary_id",
			text: props.t("Task ID"),
			sort: false,
			headerStyle: { minWidth: "4rem" },
			formatter: (value, row) => {
				const formatted = `T${value}`;					
				return (
					<strong 
					className='dt-task-id' 
					style={{ fontWeight: 'bold', cursor: !row.deleted_at ? 'pointer' : 'default' }}
					onClick={() => (!row.deleted_at ? setSelectedTaskId(row.id) : null)}
					>
						{formatted}
					</strong>
				  );
			},
		  },
		//   {
		// 	dataField: "name",
		// 	text: props.t("Title"),
		// 	sort: false,
		// 	style: { display: "table-cell", maxWidth: "200px" },
		// 	headerStyle: { maxWidth: "200px" },
		// 	formatter: (value, row) => {
		// 		return (
		// 			<div
		// 			className={`dt-task-title ${row.deleted_at ? '' : 'hoverable'}`}
		// 			style={{
		// 				cursor: !row.deleted_at ? 'pointer' : 'default',
		// 			}}
		// 			onClick={() => (!row.deleted_at ? setSelectedTaskId(row.id) : null)}>
		// 				{value}
		// 			</div>
		// 		)
		// 	}
		//   },
		{
			dataField: "name",
			text: props.t("Title"),
			sort: false,
			style: { 
				display: "table-cell", 
				maxWidth: "200px",
				whiteSpace: "nowrap", // ensures the text stays in one line
				overflow: "hidden",   // hides the overflow
				textOverflow: "ellipsis" // adds ellipsis to overflow text
			},
			headerStyle: { maxWidth: "200px" },
			formatter: (value, row) => {
				return (
					<div
					className={`dt-task-title ${row.deleted_at ? '' : 'hoverable'}`}
					style={{
						cursor: !row.deleted_at ? 'pointer' : 'default',
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis"
					}}
					onClick={() => (!row.deleted_at ? setSelectedTaskId(row.id) : null)}>
						{value}
					</div>
				)
			}
		},
		
		  {
			dataField: "created_at",
			text: props.t("Creation Date"),
			sort: false,
			formatter: (value) => {
			  const formattedDate = dateUtils.convertTimeStampToDate(value, INTERNATIONAL_DATE_FORMAT);
			  return <div className='dt-task-creation-date'>{formattedDate}</div>;
			},
		  },
		  {
			dataField: "ended_at",
			text: props.t("Deadline"),
			sort: false,
			formatter: (value) => {
				const formattedDate = dateUtils.convertTimeStampToDate(value, INTERNATIONAL_DATE_FORMAT);
				return <div className='dt-task-deadline-date'>{formattedDate}</div>;;
			  },
		  },
		  {
			dataField: "manager",
			text: props.t("Task Manager"),
			sort: true,
			// headerStyle: { maxWidth: "3rem" },
			formatter: (value) => {		  
			  return (
				<div className='dt-task-manager'>
				  <strong className="d-inline-flex align-items-center justify-content-center avatar-xs rounded-circle bg-primary">
					{value ? value[0] : null}
				  </strong>{" "}
				  <span className='text-container'>
					{value ? value : ""}
				  </span>
				</div>
			  );
			},
		  },				
		  {
			dataField: "analyst",
			text: props.t("Task Owner"),
			sort: true,
			// headerStyle: { maxWidth: "10px" },
			formatter: (value) => {
				return (value && (
						<div className='dt-task-manager'>
							<strong className="d-inline-flex align-items-center justify-content-center avatar-xs rounded-circle bg-primary">
								{value.full_name[0]}
							</strong>{" "}
							<span className='text-container'>
								{value.full_name}
							</span>
						</div>
					)
				);
			},
		  },
        //   {
		// 	dataField: "impact_on",
		// 	text: props.t("Impact On"),
		// 	sort: false,
		// 	formatter: (value) => {	
        //         return (
        //             <span>
        //                 {t(TasksImpacts[value]?.title)}
        //             </span>
        //         )
		// 	},
		//   },
        //   {
		// 	dataField: "effectiveness",
		// 	text: props.t("Effectiveness"),
		// 	sort: false,
		// 	formatter: (value) => {	
        //         return (
        //             <span>
        //                 {value}
        //             </span>
        //         )
		// 	},
		//   },
		  {
			dataField: "priority",
			text: props.t("Priority"),
			sort: true,
			formatter: (value) => {
				return(
					<a
						className={"text-dark"}
						href={"#"}
						// onClick={() => onTableClick(row.id)}
					>
						<span>
						{value.toLowerCase() === 'high' ? 
							<HiChevronDoubleUp className="text-danger" />
						 : value.toLowerCase() === 'medium' ? (
							<HiMenuAlt4 className="text-success" />
						) : (
							<HiChevronDoubleDown className="text-info" />
						)}
						</span>{" "}
						{
							value
							? props.t(value.slice(0, 1).toUpperCase() + value.slice(1))
							: null
						}
					</a>
				)
			}
			// {
			//   let iconClass = '';
			//   let priorityText = props.t(priority.toLowerCase());
		  
			//   switch (priority.toLowerCase()) {
			// 	case 'high':
			// 	  iconClass = 'HiChevronDoubleUp text-danger';
			// 	  break;
			// 	case 'medium':
			// 	  iconClass = 'HiMenuAlt4 text-success';
			// 	  break;
			// 	case 'low':
			// 	  iconClass = 'HiChevronDoubleDown text-info';
			// 	  break;
			// 	default:
			// 	  iconClass = '';
			//   }
		  
			//   return (
			// 	`<div className='dt-task-priority'>` +
			// 	  `<i class='${iconClass}'></i>` +
			// 	  `<span style='margin-left: 4px'>${priorityText}</span>` +
			// 	`</div>`
			//   );
			// },
		  },		  
		  {
			dataField: "status",
			text: props.t("Status"),
			sort: true,
			formatter: (value) => {
				
			  return (
				<div
				style={{
					padding: "0 10px",
					lineHeight: "25px",
					color: "#ffffff",
					borderRadius: "4px",
					fontSize: "12px",
					display: "inline-block",
					width: "auto"
				}}

				className={
				'dt-task-status ' +
				(value.toLowerCase() === 'review'
					? 'bg-info'
					: value.toLowerCase() === 'new'
					? 'bg-success'
					: value.toLowerCase() === 'in_progress'
					? 'bg-primary'
					: value.toLowerCase() === 'deleted'
					? 'bg-danger'
					: 'bg-secondary')
				}
				>
				  {props.t(value.toLowerCase()) === props.t('in_progress')
					? props.t('IN PROGRESS')
					: props.t(value)}
				</div>
			  );
			},
		  },
		  {
			dataField: "",
			text: props.t(""),
			sort: false,
			formatter: (value, row) => {
			  return (
				!row.deleted_at ? (
					<div
						style={{
							fontSize: 20,
							// textAlign: 'center',
							display: 'block',
							cursor: 'pointer',
						}}
						onClick={() => {
							setDeleteTaskId(row.id);
							setDeleteTaskModalStatus(true);
						}}
					>
						<span>
							<i className='ri-delete-bin-line text-danger'></i>
						</span>
						
					</div>
				) : (
					<div
						style={{
							fontWeight: 300,
							textAlign: 'center',
							display: 'block',
							color: 'red',
						}}
					>
						{t('Deleted')}
					</div>
				)
			  );
			},
		  },
		  							  
		//   {
		// 	dataField: "deleted_at",
		// 	text: props.t("Deletion date"),
		// 	sort: true,
		// 	formatter: (value) => {
		// 		const formattedDate = value ? dateUtils.convertTimeStampToDate(value, INTERNATIONAL_DATE_FORMAT) : null;
		// 		return formattedDate;
		// 	  },
		//   },
		]);
	
		return columns;
	  }
	  
	return (
		<TabPane tabId='task_management'>
			{!selectedTaskId ? (
				<Col lg='12' md='12'>
					{/* create task modal */}
					<MemorizedCreateTaskModal
						t={t}
						authToken={props.authToken}
						isOpen={createTaskModalStatus}
						close={() => setCreateTaskModalStatus(false)}
						currentUser={props.currentUser}
						owners={owners}
						initialTaskStatus={{ value: 'NEW', label: t('New') }}
						priorities={priorities}
						riskId={null}
						reportId={reportId}
						successCreation={() =>
							handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, reportId, authToken)
						}
						successNotification={props.successNotification}
						errorNotification={props.errorNotification}
						module={module}
					/>

					{/* delete task modal */}
					<MemorizedDeleteTaskModal
						t={t}
						isOpen={deleteTaskModalStatus}
						taskId={deleteTaskId}
						isDeleting={isDeleting}
						close={() => {
							setDeleteTaskId(null);
							setDeleteTaskModalStatus(false);
						}}
						acceptDeletion={(id) => {
							handleDeleteTask(id, props.reportId, props.authToken);
						}}
					/>

					<br />
					<br />
					<br />

					<Row>
                        <Col sm="12">
                            <div className="d-flex justify-content-end mb-3 mt-3">
                                <FilteringComponent 
                                    statuses={{
                                        options: statuses
                                    }}
                                    priorities={{
                                        options: priorities
                                    }}
                                    owners={{
                                        isLoading: loadingOwners,
                                        options: owners
                                    }}
                                    onChange={(newFilters) => {
                                        handleFilterRisks(newFilters)
                                    }}
                                />

                                <Button
									onClick={() => setCreateTaskModalStatus(true)}
									outline
									disabled={isDeleted || deletedByName}
								>
									{t('Create Task')}
								</Button>
                            </div>
                        </Col>
                    </Row>

					<br />

					{!loadingTasks && <TableWithPagination
                      remote={{
                        pagination: false,
                        filter: false, 
                        sort: true,
                        cellEdit: false,
                        search: false
                      }}
                      onTableChange={onTableChange}
                      columns={handleProvideTableColumns()}
                      defaultSorted={[{
                        dataField: sort.sortField,
                        order: sort.order
                      }]}
                      pagination={{
                        sizePerPage: pageSize,
                        totalSize: itemsCount,
                        custom: true
                      }}
                      pageIndex={pageIndex}
                      totalSize={itemsCount}
                      sizePerPage={pageSize}
						data={ tasks?.length ? tasks : []}
						fetchData={async (pI) => {
							return handleFetchTasks(selectedStatusFilter?.value, selectedPriorityFilter?.value, selectedOwnerFilter?.value, reportId, authToken, Number(pI.pageIndex))}
						}
                    />
					}

					{/* tasks list */}
					{/* <table className='dt-list dt-tasks-list'>
						<thead>
							<tr> */}
								{/* task id */}
								{/* <td style={{ width: '5%' }}>{t('Task ID')}</td> */}

								{/* title */}
								{/* <td style={{ width: '16%' }}>{t('Title')}</td> */}

								{/* creation date */}
								{/* <td style={{ width: '7%' }}>{t('Creation Date')}</td> */}

								{/* deadline */}
								{/* <td style={{ width: '7%' }}>{t('Deadline')}</td> */}

								{/* task manager */}
								{/* <td style={{ width: '12.5%' }}>{t('Task Manager')}</td> */}

								{/* task owner */}
								{/* <td style={{ width: '12.5%' }}>{t('Task owner')}</td> */}

								{/* priority */}
								{/* <td style={{ width: '10%' }}>{t('Priority')}</td> */}

								{/* status */}
								{/* <td style={{ width: '10%' }}>{t('Status')}</td> */}

								{/* deletion date */}
								{/* <td style={{ width: '10%' }}>{t('Deletion date')}</td> */}

								{/* actions */}
								{/* <td style={{ width: '10%' }}></td> */}
							{/* </tr>
						</thead>

						<tbody>
							{!loadingTasks ? (
								tasks && tasks.length > 0 ? (
									tasks.map((task) => {
										return (
											<tr key={task.id}> */}
												{/* task id */}
												{/* <td style={{ width: '5%' }}>
													<div
														className='dt-task-id'
														style={{
															cursor: !task.deleted_at ? 'pointer' : 'default',
														}}
														onClick={() => (!task.deleted_at ? setSelectedTaskId(task.id) : null)}
													>
														{'T' + task.secondary_id}
													</div>
												</td> */}

												{/* title */}
												{/* <td style={{ width: '16%' }}>
													<div
														className={`dt-task-title ${task.deleted_at ? '' : 'hoverable'}`}
														style={{
															cursor: !task.deleted_at ? 'pointer' : 'default',
														}}
														onClick={() => (!task.deleted_at ? setSelectedTaskId(task.id) : null)}
													>
														{task.name}
													</div>
												</td> */}

												{/* creation date */}
												{/* <td style={{ width: '7%' }}>
													<div className='dt-task-creation-date'>{dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}</div>
												</td> */}

												{/* deadline */}
												{/* <td style={{ width: '7%' }}>
													<div className='dt-task-deadline-date'>{dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}</div>
												</td> */}

												{/* task manager */}
												{/* <td style={{ width: '12.5%' }}>
													<div>
														<span className='dt-list-col-bullet'>{task.manager[0]}</span> <span className='dt-list-col-bullet-text'>{task.manager}</span>
													</div>
												</td> */}

												{/* task owner */}
												{/* <td style={{ width: '12.5%' }}>
													{
														task.analyst?.full_name ? (
															<div>
																<span className='dt-list-col-bullet'>{task.analyst.full_name[0]}</span>{' '}
																<span className='dt-list-col-bullet-text'>{task.analyst.full_name}</span>
															</div>
														) : null
													}
													
												</td> */}

												{/* priority */}
												{/* <td style={{ width: '10%' }}>
													<div className='dt-task-priority'>
														{task.priority === 'high'.toLowerCase() ? (
															<HiChevronDoubleUp className='text-danger' />
														) : task.priority.toLowerCase() === 'medium' ? (
															<HiMenuAlt4 className='text-success' />
														) : (
															<HiChevronDoubleDown className='text-info' />
														)}
														<span style={{ marginLeft: '4px' }}>{t(task.priority)}</span>
													</div>
												</td> */}

												{/* status */}
												{/* <td style={{ width: '10%' }}>
													<div
														className={
															'dt-task-status ' +
															(task.status.toLowerCase() === 'review'
																? 'bg-info'
																: task.status.toLowerCase() === 'new'
																? 'bg-success'
																: task.status.toLowerCase() === 'in_progress'
																? 'bg-primary'
																: task.status.toLowerCase() === 'deleted'
																? 'bg-danger'
																: 'bg-secondary')
														}
													>
														{t(task.status.toLowerCase()) === t('in_progress') ? t('IN PROGRESS') : t(task.status)}
													</div>
												</td> */}

												{/* deletion date */}
												{/* <td style={{ width: '10%' }}>
													<div>{task.deleted_at ? dateUtils.convertTimeStampToDate(task.deleted_at, INTERNATIONAL_DATE_FORMAT) : null}</div>
												</td> */}

												{/* actions */}
												{/* <td style={{ width: '10%' }}>
													{!task.deleted_at ? (
														<div
															style={{
																fontSize: 20,
																textAlign: 'center',
																display: 'block',
																cursor: 'pointer',
															}}
															onClick={() => {
																setDeleteTaskId(task.id);
																setDeleteTaskModalStatus(true);
															}}
														>
															<i className='ri-delete-bin-line text-danger'></i>
														</div>
													) : (
														<div
															style={{
																fontWeight: 300,
																textAlign: 'center',
																display: 'block',
																color: 'red',
															}}
														>
															{t('Deleted')}
														</div>
													)}
												</td>
											</tr> */}
										{/* );
									})
								) : (
									<tr>
										<td colSpan={10}>
											<div className='alert alert-warning' role='alert'>
												<p style={{ textAlign: 'center', marginBottom: 0, width: '100%' }}>{t(EMPTY_LIST)}</p>
											</div>
										</td>
									</tr>
								)
							) : (
								<>
									<tr> */}
										{/* task id */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-id dt-skeleton'></span>
											</div>
										</td> */}

										{/* title */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-title dt-skeleton'></span>
											</div>
										</td> */}

										{/* creation date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-creation-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* deadline */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deadline-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* task manager */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* task owner */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* priority */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-priority dt-skeleton'></span>
											</div>
										</td> */}

										{/* status */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-status dt-skeleton'></span>
											</div>
										</td> */}

										{/* deletion date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deletion-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* actions */}
										{/* <td style={{ width: '10%' }}></td>
									</tr>
									<tr> */}
										{/* task id */}
										{/* <td style={{ width: '5%' }}>
											<div>
												<span className='dt-task-id dt-skeleton'></span>
											</div>
										</td> */}

										{/* title */}
										{/* <td style={{ width: '16%' }}>
											<div>
												<span className='dt-task-title dt-skeleton'></span>
											</div>
										</td> */}

										{/* creation date */}
										{/* <td style={{ width: '7%' }}>
											<div>
												<span className='dt-task-creation-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* deadline */}
										{/* <td style={{ width: '7%' }}>
											<div>
												<span className='dt-task-deadline-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* task manager */}
										{/* <td style={{ width: '12.5%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* task owner */}
										{/* <td style={{ width: '12.5%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* priority */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-priority dt-skeleton'></span>
											</div>
										</td> */}

										{/* status */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-status dt-skeleton'></span>
											</div>
										</td> */}

										{/* deletion date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deletion-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* actions */}
										{/* <td style={{ width: '10%' }}></td>
									</tr>
									<tr> */}
										{/* task id */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-id dt-skeleton'></span>
											</div>
										</td> */}

										{/* title */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-title dt-skeleton'></span>
											</div>
										</td> */}

										{/* creation date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-creation-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* deadline */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deadline-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* task manager */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* task owner */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* priority */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-priority dt-skeleton'></span>
											</div>
										</td> */}

										{/* status */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-status dt-skeleton'></span>
											</div>
										</td> */}

										{/* deletion date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deletion-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* actions */}
										{/* <td style={{ width: '10%' }}></td>
									</tr>
									<tr> */}
										{/* task id */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-id dt-skeleton'></span>
											</div>
										</td> */}

										{/* title */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-title dt-skeleton'></span>
											</div>
										</td> */}

										{/* creation date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-creation-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* deadline */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deadline-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* task manager */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* task owner */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* priority */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-priority dt-skeleton'></span>
											</div>
										</td> */}

										{/* status */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-status dt-skeleton'></span>
											</div>
										</td> */}

										{/* deletion date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deletion-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* actions */}
										{/* <td style={{ width: '10%' }}></td>
									</tr>
									<tr> */}
										{/* task id */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-id dt-skeleton'></span>
											</div>
										</td> */}

										{/* title */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-title dt-skeleton'></span>
											</div>
										</td> */}

										{/* creation date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-creation-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* deadline */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deadline-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* task manager */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* task owner */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-list-col-bullet dt-skeleton'></span>
												<span className='dt-list-col-bullet-text dt-skeleton'></span>
											</div>
										</td> */}

										{/* priority */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-priority dt-skeleton'></span>
											</div>
										</td> */}

										{/* status */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-status dt-skeleton'></span>
											</div>
										</td> */}

										{/* deletion date */}
										{/* <td style={{ width: '10%' }}>
											<div>
												<span className='dt-task-deletion-date dt-skeleton'></span>
											</div>
										</td> */}

										{/* actions */}
										{/* <td style={{ width: '10%' }}></td>
									</tr>
								</>
							)}
						</tbody>
					</table> */}
				</Col>
			) : (
				// task details
				<MemorizedTaskDetails
					categories={categories}
					owners={owners}
					authToken={props.authToken}
					taskId={selectedTaskId}
					t={t}
					successNotification={props.successNotification}
					errorNotification={props.errorNotification}
					deleteTask={handleDeleteTask}
					back={() => {
						handleFetchTasks(null, null, null, reportId, authToken);
						setSelectedTaskId(null);
					}}
					secondaryId={props.secondaryId}
					currentUser={props.currentUser}
					taskStatuses={statuses}
					priorities={priorities}
					module={module}
				/>
			)}
		</TabPane>
	);
};

export default memo(TaskManager);
