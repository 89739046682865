import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  NavLink,
  NavItem,
  TabPane,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { post } from "../../helpers/request_helper";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { successNotification, errorNotification } from "../../store/actions";
import {
  REPORT_STATUS_ASSIGNED,
  REPORT_STATUS_CLOSED,
  REPORT_STATUS_PENDING_DELETION,
  REPORT_STATUS_DELETED,
} from "./../../components/constants/Reports.js";
import {
  // API addresses
  API_URL_REPORT_DELETE,
  API_URL_REPORT_ASSIGNED_ANALYST,
  API_URL_GET_REPORT_DETAIL,
  API_URL_GET_COMMENT_LIST,
  API_URL_GET_INTERNAL_COMMENT_LIST,
  API_URL_UPDATE_REPORT_STATUS,
  // API_URL_REPORT_LINK_CASE,
  API_URL_REPORT_MARK_AS_DELETE,
  API_URL_CUSTOMER_API,
  API_URL_REPORT_TRANSLATOR_CONTECNT,
  API_URL_QUESTION_DETAILS_FORM_ORGANIZATION,
  API_URL_VERIFY_ORGANIZATION,
  API_URL_TRANSLATION_LIST,
  // messages
  UPDATE_READONLY_ANALYSTS_SUCCESSFUL,
  UPDATE_READONLY_ANALYSTS_FAILED,
  FETCH_CASES_FAILED,
  UPDATE_LINK_CASE_SUCCESSFUL,
  UPDATE_LINK_CASE_FAILED,
  FETCH_ANALYSTS_FAILED,
  UPDATE_ASSIGNED_ANALYST_SUCCESSFUL,
  UPDATE_ASSIGNED_ANALYST_FAILED,
  FETCH_ANALYSTS_AND_ANALYST_ADMINS_FAILED,
  VERIFY_ORGANIZATION_FAILED,
  FETCH_ORGANIZATION_QUESTIONS_FAILED,
  CASE_ALREADY_HAS_BEEN_DELETED,
  ASSIGN_CASE_TO_AN_ANALYST,
  CLOSE_CASE_BEFORE_PENDING_DELETION,
  SET_PENDING_DELETION_BEFORE_DELETE,
  UPDATE_STATUS_SUCCESSFUL,
  UPDATE_STATUS_FAILED,
  DELETE_CASE_SUCCESSFUL,
  DELETE_CASE_FAILED,
  FETCH_CASE_LOGS_FAILED,
  FETCH_CASE_INTERNAL_COMMENTS_FAILED,
  FETCH_CASE_COMMENTS_FAILED,
  FETCH_ORGANIZATION_TRANSLATIONS_FAILED,
  AUTH_API_DOMAIN_URL,
  READ_ONLY_ANALYST_GRANT_FULL_ACCESS,
  API_URL_UPDATE_REPORT_RISK_RATE,
  FAILED_TO_CHANGE_RISK_RATE,
  API_URL_GET_VOICE_REPORT_DETAIL,
  ASSIGN_CASE_BEFORE_CHANGING_STATUS,
  API_URL_REPORT_CATEGORY_LIST_CASE,
  API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
  API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
  CM_API_URL_GET_REPORT_DETAIL,
  CM_API_URL_GET_VOICE_REPORT_DETAIL,
  CM_API_URL_QUESTION_DETAILS_FORM_ORGANIZATION,
  CM_API_URL_REPORT_TRANSLATOR_CONTECNT,
  CM_API_URL_REPORT_ASSIGNED_ANALYST,
  CM_API_URL_REPORT_DELETE,
  CM_API_URL_REPORT_CATEGORY_LIST_CASE,
  // CM_API_URL_REPORT_LINK_CASE,
  CM_API_URL_GET_COMMENT_LIST,
  CM_API_URL_GET_INTERNAL_COMMENT_LIST,
  CM_API_URL_UPDATE_REPORT_RISK_RATE,
  CM_API_URL_REPORT_MARK_AS_DELETE,
  CM_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
  CM_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
  CM_API_URL_UPDATE_REPORT_STATUS,
  API_URL_REPORT_ADD_LINK_CASE,
  CM_API_URL_REPORT_ADD_LINK_CASE,
  API_URL_REPORT_REMOVE_LINK_CASE,
  CM_API_URL_REPORT_REMOVE_LINK_CASE,
} from "../../common/constants";

import {
  API_BASE_URL as GDPR_API_BASE_URL,
  API_URL_REPORT_ASSIGNED_ANALYST as GDPR_API_URL_REPORT_ASSIGNED_ANALYST,
  API_URL_UPDATE_REPORT_STATUS as GDPR_API_URL_UPDATE_REPORT_STATUS,
  API_URL_REPORT_CATEGORY_LIST_CASE as GDPR_API_URL_REPORT_CATEGORY_LIST_CASE,
  API_URL_REPORT_REMOVE_LINK_CASE as GDPR_API_URL_REPORT_REMOVE_LINK_CASE,
  API_URL_REPORT_ADD_LINK_CASE as GDPR_API_URL_REPORT_ADD_LINK_CASE,
  API_URL_GET_COMMENT_LIST as GDPR_API_URL_GET_COMMENT_LIST,
  API_URL_GET_INTERNAL_COMMENT_LIST as GDPR_API_URL_GET_INTERNAL_COMMENT_LIST,
  API_URL_REPORT_MARK_AS_DELETE as GDPR_API_URL_REPORT_MARK_AS_DELETE,
  API_URL_UPDATE_REPORT_RISK_RATE as GDPR_API_URL_UPDATE_REPORT_RISK_RATE,
  API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION as GDPR_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
  API_URL_REPORT_UPDATE_ADMIN_CATEGORIES as GDPR_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
  API_URL_REPORT_DELETE as GDPR_API_URL_REPORT_DELETE,
  API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST as GDPR_API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST, 
  API_URL_GET_REPORT_DETAIL as GDPR_API_URL_GET_REPORT_DETAIL } from "src/modules/data-protection/constants/ApiRoutes.js";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserUtils from "../../services/utils/UserUtils";
import classnames from "classnames";
import DateUtils from "../../services/utils/DateUtils";
import UrlUtils from "../../services/utils/UrlUtils";
import axios from "axios";
import "./ReportDetails.scss";

// components
import MemorizedReportFields from "./Components/ReportComponents/ReportFields";
import MemorizedReportContents from "./Components/ReportComponents/ReportContents";
import MemorizedReportContent from "src/modules/data-protection/pages/reporting/Components/ReportComponents/ReportContents";
import MemorizedReportTranslations from "./Components/ReportComponents/ReportTranslations";
import MemorizedReporterDetails from "./Components/ReportComponents/ReporterDetails";
import MemorizedInternalComments from "./Components/InternalComments";
import MemorizedMessages from "./Components/Messages";
import MemorizedDeletionReasonModal from "./Components/ReportComponents/DeletionReasonModal";
import MemorizedRiskAssessment from "./Components/RiskComponents/RiskAssessment";
import MemorizedTaskManager from "./Components/TaskComponents/TaskManager";
import ReportVoiceContents from "./ReportVoiceContents";
import ReportVoiceTranslator from "./ReportVoiceTranslator";
import BottyPopup from "./Components/BottyPopup/index.jsx";
import ReportIncidenService from "src/modules/data-protection/pages/admin/data-subject-request/components/api/ReportIncidenService";

class ReportDetails extends Component {

  constructor(props) {
    super(props);

		document.title = 'Report Details | SIP';
    this.handleFetchReportDetails = this.handleFetchReportDetails.bind(this);
    this.handleFetchAnalystsAndAnalystAdmins =
      this.handleFetchAnalystsAndAnalystAdmins.bind(this);
    this.handleFetchAnalysts = this.handleFetchAnalysts.bind(this);
    this.handleGetAllCases = this.handleFetchCases.bind(this);
    this.handleRenderReportTab = this.handleRenderReportTab.bind(this);
    this.handleFetchCommentsList = this.handleFetchCommentsList.bind(this);
    this.handleChangeTranslation = this.handleChangeTranslation.bind(this);
    this.handleChangeReadOnlyAnalysts =
      this.handleChangeReadOnlyAnalysts.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeAssignedAnalyst =
      this.handleChangeAssignedAnalyst.bind(this);
    this.handleChangeLinkedCase = this.handleChangeLinkedCase.bind(this);
    this.handleSetPendingDeletionStatus =
      this.handleSetPendingDeletionStatus.bind(this);
    this.handleUpdateAdminDescription =
      this.handleUpdateAdminDescription.bind(this);
    this.handleUpdateAdminCategories =
      this.handleUpdateAdminCategories.bind(this);
    this.handleToggleDeletionReasonModal =
      this.handleToggleDeletionReasonModal.bind(this);
    this.handleToggleTab = this.handleToggleTab.bind(this);
    this.handleAddItemToInternalComments =
      this.handleAddItemToInternalComments.bind(this);
    this.handleAddItemToMessages = this.handleAddItemToMessages.bind(this);
    this.handleSetReportRiskField = this.handleSetReportRiskField.bind(this);
    this.handleChangeRiskRate = this.handleChangeRiskRate.bind(this);

    this.urlUtils = new UrlUtils();
    this.dateUtils = new DateUtils();
    this.userUtils = new UserUtils();

    this.phoneAvailableTimes = [
      {
        optionId: "7_9",
        value: this.props.t("7am - 9am"),
      },
      {
        optionId: "9_12",
        value: this.props.t("9am - 12pm"),
      },
      {
        optionId: "12_3",
        value: this.props.t("12pm - 3pm"),
      },
      {
        optionId: "3_6",
        value: this.props.t("3pm - 6pm"),
      },
      {
        optionId: "6_8",
        value: this.props.t("6pm - 8pm"),
      },
      {
        optionId: "custom",
        value: this.props.t("Customize"),
      },
    ];

    this.state = {
      suggestionsListAnalyst: [],
      report: null,
      analystsAndAnalystAdmins: null,
      analysts: null,
      cases: null,
      comments: null,
      internalComments: null,
      logs: null,
      categories: null,
      organizationQuestions: null,
      organizationQuestionsCategories: null,
      organizationQuestionsLocations: null,
      legalEntities: [],
      organizationLanguages: null,
      organizationLogo: null,
      translatedAnswers: null,
      mainTranslation: {},
      showDeletionReasonModal: false,
      loading: true,
      casesLoading: true,
      analystsAndAnalystAdminsLoading: false,
      analystsLoading: true,
      organizationLanguagesLoading: true,
      translatedAnswersLoading: false,
      internalCommentsLoading: true,
      internalCommentsFetched: false,
      messagesLoading: true,
      messagesLoadingFetched: false,
      aiText: null,
      manualText: null,
      bottyRiskAI: false,

      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Report"), link: "#" },
      ],
      activeTab:
        window.location.href.includes("task") || localStorage.getItem("taskTab")
          ? "tasks"
          : window.location.href.includes("risk") ||
            localStorage.getItem("riskTab")
          ? "risks"
          : "report_details",
      reportCaseId: this.props.match.params.caseId
        ? this.props.match.params.caseId
        : localStorage.getItem("caseId"),
      organizationId: this.urlUtils.getOrganizationId(),
      readonlyAnalysts: [],
      isReadOnlyAnalyst: false,
      secondaryId: "",
      riskIsLoading: false,
      isVoice: false,
      isMessagesTabIntervalSet: false,
      restrictedAnalyst: false,
      GDPRreport: []
    };
  }

  handleSetReportRiskField(risk) {
    const newReport = Object.create(this.state.report);
    newReport.risk = risk;
    this.setState({ report: newReport });
  }

  /**
   * this method activates a that with ID.
   * @param {String} tabId the ID of the tab you want to activate.
   */
  handleToggleTab(tabId) {
    if (this.state.activeTab !== tabId) {
      this.setState({
        activeTab: tabId,
        bottyRiskAI: false
      });
    }

    // Check and modify the URL
    this.checkAndModifyURL();
  }

  checkAndModifyURL() {
    
    // Get the current URL
    const currentUrl = window.location.href;
    // console.log("Current URl Called", currentUrl);
    // Find the position of "report/detail" in the URL
    const reportIndex = currentUrl.indexOf("/report/");
    // console.log("Index of current URl Called", reportIndex);
    if (reportIndex !== -1) {
      // Determine if "detail" follows "/report/"
      const detailIndex = currentUrl.indexOf("/report/detail", reportIndex);
      let newUrl;
  
      if (detailIndex === -1) {
        // If "detail" is not found, add "detail" to the URL
        newUrl = currentUrl.substring(0, reportIndex + "/report/".length) + "detail";
      } else {
        // If "detail" is found, retain the URL part up to "report/detail" (inclusive)
        newUrl = currentUrl.substring(0, detailIndex + "/report/detail".length);
      }
  
      // console.log("New URL Called", newUrl);
  
      // Ensure the new URL has the same origin as the current document
      if (new URL(newUrl, window.location.origin).origin === window.location.origin) {
        // Use the history API to update the URL without reloading the page
        window.history.pushState({}, '', newUrl);
      } else {
        console.error("New URL does not match the document's origin, cannot update URL.");
      }
    }
  }

  /**
   * this method adds a new item to messages list.
   * @param {Object} item the item you want to add.
   */
  handleAddItemToMessages(item) {
    this.setState({
      comments: this.state.comments ? [...this.state.comments, item] : [item],
    });
  }

  /**
   * this method adds a new item to internal comments list.
   * @param {Object} item the item you want to add.
   */
  handleAddItemToInternalComments(item) {
    this.setState({
      internalComments: this.state.internalComments
        ? [...this.state.internalComments, item]
        : [item],
    });
  }

  /**
   * this method checks that the selected status is right according to the status flow of the system and finally calls the API send new status to the server.
   * @param {Object} newStatus selected status.
   * @param {Object} caseStatus current status of the case.
   * @param {String} caseId the ID of the case you want to update status for.
   * @param {Object} caseAnalyst the case's analyst.
   * @param {String} token authorization token.
   * @returns nothing.
   */
  handleChangeStatus(newStatus, caseStatus, caseId, caseAnalyst, token) {
    // avoid any change if the new status is equal to the current status of the case.
    if (newStatus === caseStatus) return;

    if (!caseAnalyst) {
      this.props.errorNotification({
        message: this.props.t(ASSIGN_CASE_BEFORE_CHANGING_STATUS),
      });
      return;
    }

    // avoid change the status, if selected case has been already deleted.
    if (caseStatus === REPORT_STATUS_DELETED) {
      this.props.errorNotification({
        message: this.props.t(CASE_ALREADY_HAS_BEEN_DELETED),
      });
      return;
    }
    // avoid change the status to ASSIGNED, if there is no analyst assigned to the case.
    else if (newStatus === REPORT_STATUS_ASSIGNED && !caseAnalyst) {
      this.props.errorNotification({
        message: this.props.t(ASSIGN_CASE_TO_AN_ANALYST),
      });
      return;
    } else if (newStatus === REPORT_STATUS_PENDING_DELETION) {
      // avoid change the status to pending deletion, if the case already is not closed.
      if (caseStatus !== REPORT_STATUS_CLOSED) {
        this.props.errorNotification({
          message: this.props.t(CLOSE_CASE_BEFORE_PENDING_DELETION),
        });
        return;
      }
      // open deletion reason modal.
      else {
        this.handleToggleDeletionReasonModal();
        return;
      }
    } else if (newStatus === REPORT_STATUS_DELETED) {
      // avoid delete case if the current status of the case is not equal to PENDING DELETION.
      if (caseStatus !== REPORT_STATUS_PENDING_DELETION) {
        this.props.errorNotification({
          message: this.props.t(SET_PENDING_DELETION_BEFORE_DELETE),
        });
        return;
      }

      // delete case.
      else {
        this.handleDeleteReport(caseId, token);
        return;
      }
    }

    // update status if the flow was currect.
    this.handleUpdateStatus(caseId, newStatus, token);
  }

  /**
   * this method toggles the deletion reason's modal.
   */
  handleToggleDeletionReasonModal() {
    this.setState((prevState) => ({
      showDeletionReasonModal: !prevState.showDeletionReasonModal,
    }));
  }

  /**
   * this method checks that the current user has employee role or not.
   * @returns {Boolean} returns true if the user was employee and else false if was not.
   */
  isEmployee() {
    return this.userUtils.isEmployee(this.props.currentUser);
  }

  /**
   * this method checks that the current user has third-party role or not.
   * @returns {Boolean} returns true if the user was third-party and else false if was not.
   */
  isThirdParty() {
    return this.userUtils.isThirdParty(this.props.currentUser);
  }

  isPartner() {
    return this.userUtils.isPartner(this.props.currentUser)
  }

  /**
   * this method checks that the current user has analyst-admin role or not.
   * @returns {Boolean} returns true if the user was analyst-admin and else false if was not.
   */
  isAnalystAdmin() {
    return this.userUtils.isAnalystAdmin(this.props.currentUser);
  }

  /**
   * this method checks that the current user has analyst or analyst-admin role or not.
   * @returns {Boolean} returns true if the user was analyst or analyst-admin and else false if was not.
   */
  isAnalystOrAnalystAdmin() {
    return this.userUtils.isAnalystOrAnalystAdmin(this.props.currentUser);
  }

  /**
   * this method fetches details of the case.
   * @param {String} caseId the ID of the case that you want to fetch details.
   * @param {String} token authorization token.
   */
  async handleFetchReportDetails(caseId, token) {
    try {
      const result = await axios.post(
				this.props.modules.active === 'wb' ? API_URL_GET_REPORT_DETAIL : this.props.modules.active === 'gd' ? GDPR_API_URL_GET_REPORT_DETAIL : CM_API_URL_GET_REPORT_DETAIL,
				{
					report_case_id: caseId || localStorage.getItem('caseId'),
				},
				{
					headers: {
						Authorization: `Bearer ${token || localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
					},
				},
			);

      if (result.status === 200) {
        if(result?.data?.data === "restricted_analyst") {
          this.setState({
            restrictedAnalyst: true,
            activeTab: "risk_assessment",
            report: [],
          })
        } else {
          if (result.data.data?.type === 4) {
            this.setState({
              isVoice: true,
            });
          }
          const report = result.data.data;
          
          report.analyst_assigned = report.analyst_assigned?.id
            ? {
                label: `${report.analyst_assigned.first_name} ${report.analyst_assigned.last_name}`,
                value: report.analyst_assigned.id,
              }
            : null;
          this.setState({ report });
        }
      }
    } catch (error) {
      console.log("error while fetching report:", error);
    }
  }

  async handleFetchVoiceReportDetails(caseId, token) {
    try {
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_GET_VOICE_REPORT_DETAIL : CM_API_URL_GET_VOICE_REPORT_DETAIL,
        {
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("RESULT", result);
      //   if (result.status === 200) {
      //     const report = result.data.data;
      //     report.analyst_assigned = report.analyst_assigned?.id
      //       ? {
      //           label: `${report.analyst_assigned.first_name} ${report.analyst_assigned.last_name}`,
      //           value: report.analyst_assigned.id,
      //         }
      //       : null;
      //     this.setState({ report });
      //   }
    } catch (error) {
      console.log("error while fetching report:", error);
    }
  }

  /**
   * this method fetches the list of analysts and analyst admins according to the current user's organization.
   * @param {String} token authorization token.
   */
  async handleFetchAnalystsAndAnalystAdmins(token) {
    this.setState({ analystsAndAnalystAdminsLoading: true });
    try {
      const result = await axios.post(
				this.props.modules.active === 'gd' ? `${GDPR_API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST}` 
          : this.props.modules.active === 'wb'
					? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/analystAndAdminAnalyst.list`
					: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/analystAndAdminAnalyst.list`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200) {
        if (result.data.data) {
          const analysts = result.data.data.map((item) => {
            return {
              label: `${item.first_name} ${item.last_name}`,
              value: item.id,
            };
          });

          this.setState({
            analystsAndAnalystAdmins: analysts,
          });
        }
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching analysts and analyst admins:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_ANALYSTS_AND_ANALYST_ADMINS_FAILED),
      });
    }
    this.setState({ analystsAndAnalystAdminsLoading: false });
  }

  async getAnalystAndAdminAnalystList(token) {
    try {
      const result = await axios.get(
				this.props.modules.active === 'wb' ? `${AUTH_API_DOMAIN_URL}report/${this.state.reportCaseId}/mentionCommentSearch?query` :
         this.props.modules.active === 'gd'
					? `${GDPR_API_BASE_URL}/report/${this.state.reportCaseId}/mentionCommentSearch?query`
					: `${AUTH_API_DOMAIN_URL}cm/report/${this.state.reportCaseId}/mentionCommentSearch?query`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      let items = [];
      if (result.status === 200) {
        if (result.data.data) {
          result.data.data.map((item) => {
            items.push({
              text: `${item.full_name}`,
              url: item.id,
              value: `${item.full_name}`,
            });
          });
        }
      }

      this.setState({
        suggestionsListAnalyst: items,
      });
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching suggestions list analyst:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_ANALYSTS_AND_ANALYST_ADMINS_FAILED),
      });
    }
  }

  /**
   * this method fetches the list of analyst (not analyst admins).
   * @param {String} caseId the ID of the case that you want to fetch analysts for that.
   * @param {String} token authorization token.
   */
  async handleFetchAnalysts(caseId, token) {
    try {
      const result = await axios.get(
				this.props.modules.active === 'wb'  
        ? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/report/${caseId}/analyst.list`:
          this.props.modules.active === 'gd'
					? `${GDPR_API_BASE_URL}/report/${caseId}/analyst.list`
					: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/report/${caseId}/analyst.list`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200) {
        this.setState({analystsLoading: false,})
        if (result.data.data) {
          const analysts = result.data.data.map((item) => {
            return {
              label: `${item.firstName} ${item.lastName}`,
              value: item.id,
            };
          });

          this.setState({
            analysts: analysts,
          });
        }
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_ANALYSTS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching analysts (not analyst admins):",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_ANALYSTS_FAILED),
      });
    }
  }

  /**
   * this method fetches case according to the caseId.
   * the cases that return from this method will shown in the drop-down menu of the link case field.
   * @param {String} caseId the ID of the case you want to prepare drop-down menu for that.
   * @param {String} token authorization token.
   */
  async handleFetchCases(caseId, token) {
    this.setState({ casesLoading: true });
    try {
      const result = await axios.get(
				this.props.modules.active === 'wb' ?
        `${API_URL_CUSTOMER_API}report/${caseId}/list_select_cases` :
         this.props.modules.active === 'gd' ?
         `${GDPR_API_BASE_URL}/report/${caseId}/list_select_cases` : `${API_URL_CUSTOMER_API}cm/report/${caseId}/list_select_cases`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200) {
        if (result.data.data) {
          this.setState({
            cases: result.data.data.map((item) => {
              return {
                value: item.case_id,
                label: `${item.secondary_case_id} - ${
                  item.description.length > 70
                    ? item.description.substring(0, 70) + "..."
                    : item.description
                }`,
              };
            }),
          });
        }
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_CASES_FAILED),
        });
      }
      this.setState({ casesLoading: false });
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching cases:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_CASES_FAILED),
      });
    }
  }

  async handleFetchAssetBasicInformation(caseId) {
    const service = ReportIncidenService.getInstance();
    try {
      const data = await service.FetchIncidentReportOverView(caseId);
      this.setState({ GDPRreport: data });
    } catch (error) {
      this.props.errorNotification({
        message: this.props.t("An error occurred while fetching overview content."),
      });
    }
  }



  /**
   * this method fetches questions of the organization.
   * @param {String} organizationId the ID of the organization you want to receive questions from.
   * @param {String} caseType the type of the case.
   * @param {String} caseId the ID of the case you want to fetch questions.
   * @param {String} token authorization token.
   */
  async handleFetchOrganizationQuestions(
    organizationId,
    caseType,
    caseId,
    token
  ) {
    try {
      const result = await axios.post(
				this.props.modules.active === 'wb' ? API_URL_QUESTION_DETAILS_FORM_ORGANIZATION :
           CM_API_URL_QUESTION_DETAILS_FORM_ORGANIZATION,
				{
					organization_id: organizationId,
					typeReport: caseType,
					case_id: caseId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200) {
        if (result.data.data) {
          const {
            legalEntities,
            questions,
            location,
            report_category: categories,
          } = result.data.data;
          this.setState({
            organizationQuestions:
              questions && Array.isArray(questions)
                ? questions.sort((a, b) => {
                    return a.sort - b.sort;
                  })
                : [],
            organizationQuestionsCategories:
              categories && Array.isArray(categories) ? categories : [],
            organizationQuestionsLocations:
              location && Array.isArray(location) ? location : [],
            legalEntities: legalEntities && Array.isArray(legalEntities) ? legalEntities : [],
          });
        }
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_ORGANIZATION_QUESTIONS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching organization's questions:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_ORGANIZATION_QUESTIONS_FAILED),
      });
    }
  }

  /**
   * this method gets organization information.
   * @param {String} organizationId the ID of the organization.
   * @param {String} url URL.
   * @param {String} token authorization token.
   */
  async handleFetchVerifyOrganization(organizationId, url, token) {
    try {
      const result = await axios.post(
        API_URL_VERIFY_ORGANIZATION,
        {
          id: organizationId,
          url: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        if (result.data.data) {
          const organizationLanguages = result.data.data.languages.map(
            (lang) => {
              return {
                id: lang.id,
                label: lang.name,
                value: lang.language_code,
                direction: lang.direction,
                isDefault: lang.is_default,
              };
            }
          );

          this.setState({
            organizationLanguages: organizationLanguages,
            organizationLogo: result.data.data.logo,
            organizationLanguagesLoading: false,
          });
        }
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching organization verify:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(VERIFY_ORGANIZATION_FAILED),
      });
    }
  }

  /**
   * this method fetches translations for the contents of the selected case.
   * @param {String} caseId the ID of the case you want to get translations for.
   * @param {String} languageCode the language code you want to get translatinos for.
   * @param {String} organizationId the ID of the organization of the case.
   * @param {String} token authorization token.
   */
  async handleChangeTranslation(caseId, languageCode, organizationId, token) {
    try {
      this.setState({
        translatedAnswersLoading: true,
      });

      this.handleGetOrganizationTranslations(
        organizationId,
        languageCode,
        token
      );
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_REPORT_TRANSLATOR_CONTECNT : this.props.modules.active === 'gd'  ?`${GDPR_API_BASE_URL}/translate-content.json`: CM_API_URL_REPORT_TRANSLATOR_CONTECNT,
        {
          codeLang: languageCode,
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        if (result.data.data) {
          const report = Object.create(this.state.report);
          report.question_answer = result.data.data.Questions;
          report.category = result.data.data.Category;

          this.setState({
            translatedAnswers: result.data.data.Questions,
            translatedAnswersLoading: false,
          });
        }
      }
    } catch (error) {
      console.log("error while fetching translation content:", error);
    }
  }

  /**
   * this method fetches the translations of a organization according to the lanugage code.
   * @param {String} organizationId the ID of the organization that you want to get translations for.
   * @param {String} languageCode the language code you want to get translations for.
   * @param {String} token authorization token.
   */
  async handleGetOrganizationTranslations(organizationId, languageCode, token) {
    try {
			const result = await axios.post(
				API_URL_TRANSLATION_LIST,
				{
					code_lang: languageCode,
					organization_id: organizationId.toString(),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				if (result.data) {
					this.setState({ mainTranslation: result.data });
				}
			} else {
				this.props.errorNotification({
					message: this.props.t(FETCH_ORGANIZATION_TRANSLATIONS_FAILED),
				});
			}
		} catch (error) {
			console.log(error);
			if (process?.env?.NODE_ENV === 'development') {
				console.error("this error only appears in the development environment:\nerror while fetching organization's translations:", error);
			}

			if (error.message !== 'parsed is undefined') {
				this.props.errorNotification({
					message: this.props.t(FETCH_ORGANIZATION_TRANSLATIONS_FAILED),
				});
			}
		}
  }

  /**
   * this method updates the assigned analyst.
   * @param {Object} report report.
   * @param {String} analystId the ID of the new analyst to assign to the case.
   * @param {Object} currentAnalyst the analyst that already assigned to the case.
   * @param {Object[]} analystsAndAnalystAdmins analysts and analyst admins to prepare drop-down menu.
   * @param {token} token authorization token.
   */
  async handleChangeAssignedAnalyst(
    report,
    analystId,
    currentAnalyst,
    analystsAndAnalystAdmins,
    token
  ) {
    try {
      // temporarily set new analyst
      const selectedAnalyst = analystsAndAnalystAdmins.find(
        (i) => i.value === analystId
      );
      if (!currentAnalyst) {
        currentAnalyst = {
          value: selectedAnalyst.value,
          label: selectedAnalyst.label,
        };
      } else {
        currentAnalyst["value"] = selectedAnalyst.value;
        currentAnalyst["label"] = selectedAnalyst.label;
      }

      // send data to server
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_REPORT_ASSIGNED_ANALYST: this.props.modules.active === 'gd' ? GDPR_API_URL_REPORT_ASSIGNED_ANALYST : CM_API_URL_REPORT_ASSIGNED_ANALYST,
        {
          analyst_id: analystId,
          report_case_id: report.case_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200 && result.data.ok) {
        const newReport = Object.create(this.state.report);
        newReport.analyst_assigned = {
          value: result.data.data.id,
          label: `${result.data.data.first_name} ${result.data.data.last_name}`,
        };
        newReport.readonly_analysts = newReport.readonly_analysts.filter(
          (i) => i !== result.data.data.id
        );
        if (report.status < 2) newReport.status = 2;
        this.setState({ report: newReport });

        if (result.data.data.commonReadonly) {
          this.props.successNotification({
            message: this.props.t(READ_ONLY_ANALYST_GRANT_FULL_ACCESS),
          });
        } else {
          this.props.successNotification({
            message: this.props.t(UPDATE_ASSIGNED_ANALYST_SUCCESSFUL),
          });
        }
        this.getAnalystAndAdminAnalystList(this.props.token)
      } else {
        const selectedAnalyst = analystsAndAnalystAdmins.find(
          (i) => i.value === report.analyst_assigned.id
        );
        currentAnalyst.value = selectedAnalyst.value;
        currentAnalyst.label = selectedAnalyst.label;

        this.props.errorNotification({
          message: this.props.t(UPDATE_ASSIGNED_ANALYST_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while updating assigned analyst:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(UPDATE_ASSIGNED_ANALYST_FAILED),
      });
    }
  }

  /**
   * this method deletes the selected case.
   * @param {String} caseId the ID of the case that you want to delete.
   * @param {String} token authorization token.
   */
  async handleDeleteReport(caseId, token) {
    try {
      const result = await axios.post(
				this.props.modules.active === 'gd' ?
        GDPR_API_URL_REPORT_DELETE :
         this.props.modules.active === 'wb' ? 
         API_URL_REPORT_DELETE : CM_API_URL_REPORT_DELETE,
				{
					report_case_id: caseId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200) {
        const newReport = Object.create(this.state.report);
        newReport.status = 5;
        newReport.deleted_by_name = result.data.data.deleted_by_name;
        newReport.is_deleted_content = false;
        this.setState({ report: newReport });

        this.props.successNotification({
          message: this.props.t(DELETE_CASE_SUCCESSFUL),
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(DELETE_CASE_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while deleting the case:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(DELETE_CASE_FAILED),
      });
    }
  }

  /**
   * this method updates the status of the case.
   * @param {String} caseId the ID of the case you want to update status for.
   * @param {Number} status selected status.
   * @param {String} token authorization token.
   */
  async handleUpdateStatus(caseId, status, token) {
    try {
      const result = await post(
        this.props.modules.active === 'wb' ?  API_URL_UPDATE_REPORT_STATUS :  this.props.modules.active === 'gd' ? GDPR_API_URL_UPDATE_REPORT_STATUS :CM_API_URL_UPDATE_REPORT_STATUS,
        {
          status: status,
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        const newReport = Object.create(this.state.report);
        newReport.status = status;
        newReport.status_updating_date = result.data.data.updatedAt;
        if (result.data.data.closingDate) {
          newReport.closing_date = result.data.data.closingDate;
        } else {
          newReport.closing_date = "";
        }
        this.setState({ report: newReport });

        this.props.successNotification({
          message: this.props.t(UPDATE_STATUS_SUCCESSFUL),
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(UPDATE_STATUS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while updating status:",
          error
        );
      }

      if (error === "fatal_error") {
        this.props.errorNotification({
          message: this.props.t(
            "Please assign an analyst to the case before proceeding."
          ),
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(UPDATE_STATUS_FAILED),
        });
      }
    }
  }

  /**
   * Fetches the categories.
   *
   * @param {string} token The authentication token.
   */
  async handleFetchCategories(token) {
    try {
      const response = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_REPORT_CATEGORY_LIST_CASE : 
        this.props.modules.active === 'gd' ? GDPR_API_URL_REPORT_CATEGORY_LIST_CASE : CM_API_URL_REPORT_CATEGORY_LIST_CASE,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({
          categories: response.data.data,
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching categories:",
          error
        );
      }
    }
  }

  /**
   * this method updates the linked case of a case.
   * @param {String} caseId the ID of the case that the new case must link to that.
   * @param {String} linkCaseId the ID of the new case that you want to set as linked case.
   * @param {String} token authorization token.
   */
  async handleChangeLinkedCase(caseId, linkCaseId, token, action) {
    // const newReport = Object.create(this.state.report);
    // newReport.addedLinkedCase = linkCaseId;
    // this.setState({ report: newReport });

    if(action === "remove") {
      try {
        const result = await axios.post(
          this.props.modules.active === 'wb'  ? API_URL_REPORT_REMOVE_LINK_CASE:
          this.props.modules.active === 'gd'  ? GDPR_API_URL_REPORT_REMOVE_LINK_CASE : CM_API_URL_REPORT_REMOVE_LINK_CASE,
          {
            reportCase: caseId,
            removedLinkedCase: linkCaseId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
  
        if (result.status === 200) {
          // const newReport = Object.create(this.state.report);
          // const linkedCases = this.state.report.linked_cases;
          
          // console.log(linkedCases.splice( linkedCases.indexOf(linkCaseId), 1))
          const indexToRemove = this.state.report.linked_cases.indexOf(linkCaseId);
          if (indexToRemove !== -1) {
            this.state.report.linked_cases.splice(indexToRemove, 1);
          }

          // console.log(this.state.report, newReport)
          // this.setState({ report: newReport });
  
          this.props.successNotification({
            message: this.props.t(UPDATE_LINK_CASE_SUCCESSFUL),
          });
        } else {
          this.props.errorNotification({
            message: this.props.t(UPDATE_LINK_CASE_FAILED),
          });
        }
      } catch (error) {
        if (process?.env?.NODE_ENV === "development") {
          console.error(
            "this error only appears in the development environment:\nerror while updating linked case:",
            error
          );
        }
  
        this.props.errorNotification({
          message: this.props.t(UPDATE_LINK_CASE_FAILED),
        });
      }
    } else {
      try {
        const result = await axios.post(
          this.props.modules.active === 'wb'  ? API_URL_REPORT_ADD_LINK_CASE :
          this.props.modules.active === 'gd' ? GDPR_API_URL_REPORT_ADD_LINK_CASE : CM_API_URL_REPORT_ADD_LINK_CASE,
          {
            reportCase: caseId,
            addedLinkedCase: linkCaseId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
  
        if (result.status === 200) {
          // const newReport = Object.create(this.state.report);
          // newReport.linked_case_id = linkCaseId;
          // this.setState({ report: newReport });
          const linkedCases = this.state.report.linked_cases;
          this.state.report.linked_cases.push(linkCaseId);
          this.props.successNotification({
            message: this.props.t(UPDATE_LINK_CASE_SUCCESSFUL),
          });
        } else {
          this.props.errorNotification({
            message: this.props.t(UPDATE_LINK_CASE_FAILED),
          });
        }
      } catch (error) {
        if (process?.env?.NODE_ENV === "development") {
          console.error(
            "this error only appears in the development environment:\nerror while updating linked case:",
            error
          );
        }
  
        this.props.errorNotification({
          message: this.props.t(UPDATE_LINK_CASE_FAILED),
        });
      }
    }

    
  }

  /**
   *
   * @param {*} caseId the ID of the case you want to get comments for.
   * @param {*} token authorization token.
   */
  async handleFetchCommentsList(caseId, token) {

    try {
      this.setState({ messagesLoading: true });
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_GET_COMMENT_LIST :
        this.props.modules.active === 'cm' ?   CM_API_URL_GET_COMMENT_LIST : GDPR_API_URL_GET_COMMENT_LIST,
        {
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        console.timeLog(result.data.data)
        this.setState({
          comments: result.data.data,
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_CASE_COMMENTS_FAILED),
        });
      }
    } catch (error) {
      console.log("error while fetching comments: ", error);
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching comments:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_CASE_COMMENTS_FAILED),
      });
    }
    this.setState({
      messagesFetched: true,
      messagesLoading: false,
    });
  }

  /**
   * this method fetches the internal comments of the case.
   * @param {String} caseId the ID of the case you want to get internal comments for.
   * @param {String} token authorization token.
   */
  async handleFetchInternalCommentsList(caseId, token) {
    try {
      this.setState({ internalCommentsLoading: true });
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_GET_INTERNAL_COMMENT_LIST :   this.props.modules.active === 'gd'  ? GDPR_API_URL_GET_INTERNAL_COMMENT_LIST : CM_API_URL_GET_INTERNAL_COMMENT_LIST,
        {
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        this.setState({
          internalComments: result.data.data,
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_CASE_INTERNAL_COMMENTS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching internal comments:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_CASE_INTERNAL_COMMENTS_FAILED),
      });
    }

    this.setState({
      internalCommentsFetched: true,
      internalCommentsLoading: false,
    });
  }

  /**
   * this method fetches the logs of the selected case.
   * @param {String} caseId the ID of the case you want to update status for.
   * @param {String} token authorization token.
   */
  async handleFetchReportLogs(caseId, token) {
    try {
      const result = await axios.get(
        this.props.modules.active === 'wb' ? `${API_URL_CUSTOMER_API}report/${caseId}/showLog`:
        this.props.modules.active === 'gd' ? `${GDPR_API_BASE_URL}/report/${caseId}/showLog` : `${API_URL_CUSTOMER_API}cm/report/${caseId}/showLog`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        this.setState({
          logs: result.data.data,
        });
      } else {
        this.props.errorNotification({
          message: this.props.t(FETCH_CASE_LOGS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching the logs of the case:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FETCH_CASE_LOGS_FAILED),
      });
    }
  }

  async handleChangeRiskRate(caseId, risk, token) {
    this.setState({
      riskIsLoading: true,
    });

    try {
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_UPDATE_REPORT_RISK_RATE:
        this.props.modules.active === 'gd' ? GDPR_API_URL_UPDATE_REPORT_RISK_RATE : CM_API_URL_UPDATE_REPORT_RISK_RATE,
        {
          risk_rate: risk,
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        const newReport = Object.create(this.state.report);
        newReport.risk_rate = risk;
        this.setState({ report: newReport });
      } else {
        this.props.errorNotification({
          message: this.props.t(FAILED_TO_CHANGE_RISK_RATE),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while updating risk rate:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(FAILED_TO_CHANGE_RISK_RATE),
      });
    }

    this.setState({
      riskIsLoading: false,
    });
  }

  /**
   * this method updates the list of read-only analysts.
   * @param {String[]|Number[]} readOnlyAnalystsIds list of read-only analysts IDs.
   * @param {String} token athorization token.
   */
  async handleChangeReadOnlyAnalysts(readOnlyAnalystsIds, token) {
    try {
      this.setState({ analystsLoading: true });
      const result = await axios.post(
				this.props.modules.active === 'wb' 
        ? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/report/${this.state.reportCaseId}/changeReadonlyAnalysts` :
            this.props.modules.active === 'gd'
					? `${GDPR_API_BASE_URL}/report/${this.state.reportCaseId}/changeReadonlyAnalysts`
					: `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/report/${this.state.reportCaseId}/changeReadonlyAnalysts`,
				{ readonlyAnalysts: readOnlyAnalystsIds },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

      if (result.status === 200 && result.data.ok) {
        const newReport = Object.create(this.state.report);
        newReport.readonly_analysts = readOnlyAnalystsIds.map((i) => Number(i));
        this.setState({ report: newReport });

        this.props.successNotification({
          message: this.props.t(UPDATE_READONLY_ANALYSTS_SUCCESSFUL),
        });
        this.setState({ analystsLoading: false });
      } else {
        this.props.errorNotification({
          message: this.props.t(UPDATE_READONLY_ANALYSTS_FAILED),
        });
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while updating read-only analysts list:",
          error
        );
      }

      this.props.errorNotification({
        message: this.props.t(UPDATE_READONLY_ANALYSTS_FAILED),
      });
    }
    this.getAnalystAndAdminAnalystList(this.props.token);
  }

  /**
   * this method sets the status of the case to pending deletion.
   * @param {String} caseId the ID of the case you want to update status for.
   * @param {String} deletionReason the deletion reason.
   * @param {String} token authorization token.
   */
  async handleSetPendingDeletionStatus(caseId, deletionReason, token) {
    try {
      const result = await axios.post(
        this.props.modules.active === 'wb' ? API_URL_REPORT_MARK_AS_DELETE :
        this.props.modules.active === 'gd' ? GDPR_API_URL_REPORT_MARK_AS_DELETE : CM_API_URL_REPORT_MARK_AS_DELETE,
        {
          reason: deletionReason,
          report_case_id: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200 && result.data.ok) {
        const newReport = Object.create(this.state.report);
        newReport.status = {
          value: 6,
          label: this.props.t("Pending Deletion"),
        };
        this.setState({ report: newReport });

        this.props.successNotification({
          code: "saved",
          message: this.props.t("Data was saved successfully"),
        });
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data.error === "fatal_error"
      ) {
        this.props.errorNotification({
          code: "fatal_error",
          message: this.props.t(error.response?.data.message.status),
        });
      }
    }
  }

  async handleUpdateAdminDescription(caseId, adminDescription, token) {
    try {
      const result = await axios.put(
        this.props.modules.active === 'wb' ? API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION:
        this.props.modules.active === 'gd' ? GDPR_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION : CM_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
        {
          description: adminDescription,
          reportCaseId: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200 && result.data.ok) {
        const newReport = Object.create(this.state.report);
        newReport.admin_description = adminDescription;

        this.setState({
          report: newReport,
        });
        this.props.successNotification({
          code: "saved",
          message: this.props.t("Admin description updated successfully."),
        });
      }
    } catch (error) {
      this.props.errorNotification({
        message: this.props.t("Failed to update admin description."),
      });
    }
  }

  async handleUpdateAdminCategories(caseId, adminCategories, token) {
    try {
      const result = await axios.put(
        this.props.modules.active === 'wb' ? API_URL_REPORT_UPDATE_ADMIN_CATEGORIES
        :  this.props.modules.active === 'gd' ?  GDPR_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES : CM_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
        {
          category: adminCategories,
          reportCaseId: caseId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200 && result.data.ok) {
        const newReport = Object.create(this.state.report);
        newReport.admin_category = adminCategories;

        this.setState({
          report: newReport,
        });
        this.props.successNotification({
          code: "saved",
          message: this.props.t("Admin categories updated successfully."),
        });
      }
    } catch (error) {
      this.props.errorNotification({
        message: this.props.t("Failed to update admin categories."),
      });
    }
  }

  async initializer() {
    this.handleFetchVerifyOrganization(
      this.urlUtils.getOrganizationId(),
      decodeURI(this.urlUtils.getUrlParam())
    );

    await this.handleFetchReportDetails(
      this.state.reportCaseId,
      this.props.token
    );

    // await this.handleFetchVoiceReportDetails(
    //   this.state.reportCaseId,
    //   this.props.token
    // );

    if (this.isAnalystOrAnalystAdmin()) {
      this.handleFetchAnalystsAndAnalystAdmins(this.props.token);
      this.getAnalystAndAdminAnalystList(this.props.token);
      !this.state.restrictedAnalyst && this.handleFetchAnalysts(this.state.report.case_id, this.props.token);
      !this.state.restrictedAnalyst && this.handleFetchCases(this.state.report.case_id, this.props.token);
      this.handleFetchCategories(this.props.token);
    }

    if (
      this.state.report &&
      // !this.state.isVoice &&
      !this.state.restrictedAnalyst &&
      !this.state.report.is_deleted_content &&
      !this.state.report.deleted_by_name &&
      this.props.modules.active !== "gd"
    ) {
      await this.handleFetchOrganizationQuestions(
        this.props.Organization.id,
        this.state.report.type,
        this.state.report.case_id,
        this.props.token
      );

      
      if (!this.state.report?.is_reporter_analyst_only && !this.isEmployee() && !this.isThirdParty()) {
        this.handleFetchInternalCommentsList(
          this.state.report.case_id,
          this.props.token
        );
      }
    }
    if(   this.state.report &&
      // !this.state.isVoice &&
      !this.state.restrictedAnalyst &&
      !this.state.report.is_deleted_content &&
      !this.state.report.deleted_by_name && this.props.modules.active === "gd"){
        await  this.handleFetchAssetBasicInformation(this.state.report.case_id);
    }
    !this.state.restrictedAnalyst && this.handleFetchCommentsList(this.state.report.case_id, this.props.token);
    // if (isFeatureEnabledForActiveModule("history-logs")) {
    //   this.handleFetchReportLogs(this.state.report.case_id, this.props.token);
    // }
  }

  async componentDidMount() {
    if (window.location.pathname.includes("case")) {
      if (window.location.pathname.includes("case")) {
        const str = window.location.pathname.split("/");
        const caseId = str.slice(str.length - 1, str.length)[0];
        localStorage.setItem("caseId", caseId);
      }
    }

    await this.initializer();

    if (window.location.pathname.includes("task")) {
      this.handleToggleTab("task_management");
    } else if (window.location.pathname.includes("risk")) {
      this.handleToggleTab("risk_assessment");
    }

    // console.log("REPORT", this.state.report);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.restrictedAnalyst && prevState.reportCaseId !== localStorage.getItem("caseId")) {
      // reset some properies of the state
      this.setState({
        suggestionsListAnalyst: [],
        report: null,
        analystsAndAnalystAdmins: null,
        analysts: null,
        cases: null,
        comments: null,
        internalComments: null,
        logs: null,
        organizationQuestions: null,
        organizationQuestionsCategories: null,
        organizationQuestionsLocations: null,
        organizationLanguages: null,
        organizationLogo: null,
        translatedAnswers: null,
        mainTranslation: {},
        showDeletionReasonModal: false,
        loading: true,
        casesLoading: true,
        analystsAndAnalystAdminsLoading: false,
        analystsLoading: true,
        organizationLanguagesLoading: true,
        translatedAnswersLoading: false,
        internalCommentsLoading: true,
        messagesLoading: true,
        activeTab:
          window.location.href.includes("task") ||
          localStorage.getItem("taskTab")
            ? "task_management"
            : window.location.href.includes("risk") ||
              localStorage.getItem("riskTab")
            ? "risk_assessment"
            : "report_details",
        readonlyAnalysts: [],
        isReadOnlyAnalyst: false,
        secondaryId: "",
        reportCaseId: localStorage.getItem("caseId"),
      });
    }

    if (!this.state.restrictedAnalyst && prevState.reportCaseId !== this.state.reportCaseId) {
      this.initializer();
    }

    
    if (!this.state.restrictedAnalyst && this.state.activeTab === "messages" && !this.state.isMessagesTabIntervalSet) {
      this.setState({ isMessagesTabIntervalSet: true });
      this.messagesTabInterval = setInterval(() => {
        this.handleFetchCommentsList(this.state.report.case_id, this.props.token);
      }, 30000);
    } else if (!this.state.restrictedAnalyst && this.state.activeTab !== "messages" && this.state.isMessagesTabIntervalSet) {
      clearInterval(this.messagesTabInterval);
      this.setState({ isMessagesTabIntervalSet: false });
    }
  }

  onRiskAiBottySubmit = () => {
    console.log("Clicked")
    this.handleToggleTab("risk_assessment");
    this.setState({bottyRiskAI: true})
  }


  /**
   * this method renders the report details DOM nodes.
   * @param {Object} report selected report
   * @param {String} token authorization token.
   * @returns DOM nodes.
   */
  handleRenderReportTab(report, token) {
    return (
      <TabPane tabId="report_details" className="pt-4">
        <MemorizedReportFields
          t={this.props.t}
          isTest={report?.is_test}
          onRiskAiBottySubmit={this.onRiskAiBottySubmit}
          showBottyPopup={report?.show_botty_popup}
          reportType={this.state.report?.type}
          description={report?.description}
          type={this.props.modules.active !== "gd" ? report?.type  : this.state.GDPRreport?.questionnaire?.toLowerCase()}
          submissionDate={report?.submission_date}
          closingDate={report?.closing_date}
          showAnonymizeButton={!!report?.show_anonymize_button}
          anonymizedBefore={!!report?.is_anonymized_before}
          statusUpdatingDate={report?.status_updating_date}
          source={report?.source}
          id={report?.case_id}
          isDeleted={report?.is_deleted_content}
          status={report?.status}
          reportLoading={report ? false : true}
          isAnalystAdmin={this.isAnalystAdmin()}
          isAnalystOrAnalystAdmin={this.isAnalystOrAnalystAdmin()}
          isThirdParty={this.isThirdParty()}
          isPartner={this.isPartner()}
          isEmployee={this.isEmployee()}
          cases={this.state.cases}
          errorNotification={this.props.errorNotification}
          successNotification={this.props.successNotification}
          linkedCase={
            this.state.cases && report
              ? this.state.cases.find((i) => i.value === report.linked_case_id)
              : null
          }
          linkedCases = {
            this.state.cases && report ? report?.linked_cases?.map(c => this.state.cases.find((i) => i.value === c)) : null
          } 
          linkedToCases={
            this.state.cases && report ? report?.linked_to_cases?.map(c => this.state.cases.find((i) => i.value === c)) : null
          }
          casesLoading={this.state.casesLoading}
          analystsAndAnalystAdmins={this.state.analystsAndAnalystAdmins}
          analysts={this.state.analysts}
          assignedAnalyst={report?.analyst_assigned}
          analystsAndAnalystAdminsLoading={
            this.state.analystsAndAnalystAdminsLoading
          }
          analystsLoading={this.state.analystsLoading}
          hasRiskAssessmentFeature={isFeatureEnabledForActiveModule(
            "risk-assessment"
          )}
          fetchReport={this.handleFetchReportDetails}
          hasReadOnlyPermission={isFeatureEnabledForActiveModule(
            "read-only-permission"
          )}
          riskRate={report?.risk_rate}
          risk={report?.risk}
          reasonForDeletion={report?.reason_for_deletion}
          deletedByName={report?.deleted_by_name}
          isReadOnlyAnalyst={report?.is_read_only_analyst}
          readOnlyAnalysts={report?.readonly_analysts}
          handleChangeReadOnlyAnalysts={this.handleChangeReadOnlyAnalysts}
          handleChangeStatus={this.handleChangeStatus}
          handleChangeAssignedAnalyst={this.handleChangeAssignedAnalyst}
          handleChangeLinkedCase={this.handleChangeLinkedCase}
          authToken={token}
          organizationLogo={this.state.organizationLogo}
          organizationQuestions={this.state.organizationQuestions}
          organizationQuestionsCategories={
            this.state.organizationQuestionsCategories
          }
          legalEntities={this.state.legalEntities}
          currentUser={this.props.currentUser}
          internalComments={this.state.internalComments}
          comments={this.state.comments}
          // hasHistoryLogsFeature={isFeatureEnabledForActiveModule(
          //   "history-logs"
          // )}
          logs={this.state.logs}
          attachments={report?.attachments}
          // most remove below line
          // most remove below line
          // most remove below line
          report={report ? report : null}
          handleChangeRiskRate={this.handleChangeRiskRate}
          riskIsLoading={this.state.riskIsLoading}
          categories={this.state.categories}
          adminDescription={report?.admin_description}
          adminCategories={report?.admin_category}
          handleUpdateAdminDescription={this.handleUpdateAdminDescription}
          handleUpdateAdminCategories={this.handleUpdateAdminCategories}
          isActive={this.state.activeTab === 'report_details'}
          />

        {report ? (
          report.is_deleted_content ? (
            // deleted case
            <Alert color="warning">
              {this.props.t("This case is deleted.")}
            </Alert>
          ) : (
            // existing case
            <>
              {!this.state.isVoice ? (<>
              {  this.props.modules.active !== "gd" ? 
                <MemorizedReportContents
                  t={this.props.t}
                  questions={report?.contents?.Questions}
                  reportLoading={report ? false : true}
                />
                : <>
                   <MemorizedReportContent
                   t={this.props.t}
                 questions={this.state.GDPRreport?.question_answers}
                 reportLoading={report ? false : true}
                 />
                 </>}
                </>
              ) : (
                <ReportVoiceContents
                  report={report}
                  onSetAITranscript={(e) => this.setState({ aiText: e })}
                  onSetManualTranscript={(e) =>
                    this.setState({ manualText: e }, () => this.forceUpdate())
                  }
                  t={this.props.t}
                  isAnalystAdmin={this.isAnalystAdmin()}
                  isAnalystOrAnalystAdmin={this.isAnalystOrAnalystAdmin()}
                  isThirdParty={this.isThirdParty()}
                  isEmployee={this.isEmployee()}
                />
              )}

              {!this.state.isVoice &&
              this.props.currentUser &&
              this.props.currentUser.roles &&
              this.props.currentUser.roles[0] !== "ROLE_THIRD_PARTY" &&
              this.props.currentUser.roles[0] !== "ROLE_EMPLOYEE" &&
              isFeatureEnabledForActiveModule(
                "translator-contents"
              ) ? (
                <MemorizedReportTranslations
                  t={this.props.t}
                  isDeleted={report.is_deleted_content}
                  isAnalystOrAnalystAdmin={this.isAnalystOrAnalystAdmin()}
                  organizationLanguages={this.state.organizationLanguages}
                  organizationLanguagesLoading={
                    this.state.organizationLanguagesLoading
                  }
                  organizationId={this.props.Organization.id}
                  translatedAnswers={this.state.translatedAnswers}
                  mainTranslation={this.state.mainTranslation}
                  id={report.case_id}
                  handleChangeTranslation={this.handleChangeTranslation}
                  authToken={token}
                  translatedAnswersLoading={this.state.translatedAnswersLoading}
                />
              ) : this.state.isVoice &&
                this.props?.currentUser?.roles[0] !== "ROLE_THIRD_PARTY" &&
                this.props?.currentUser?.roles[0] !== "ROLE_EMPLOYEE" &&
                (this.state.aiText || this.state.manualText) ? (
                <ReportVoiceTranslator
                  organizationLanguages={this.state.organizationLanguages}
                  aiText={this.state.aiText}
                  manualText={this.state.manualText}
                  t={this.props.t}
                  id={report.case_id}
                  authToken={token}
                  report={report}
                />
              ) : null}
            </>
          )
        ) : null}

        {/* confidentially and meeting */}
        {report &&
        !report.is_deleted_content &&
        (report.type === 2 || report.type === 3 || report.type === 4) ? (
          <MemorizedReporterDetails
            t={this.props.t}
            isAnalystOrAnalystAdmin={this.isAnalystOrAnalystAdmin()}
            reportingType={report.reporting_type}
            reportType={report.type}
            reportOwnerEmail={report.owner_info?.email}
            reportOwnerGender={report.owner_info?.gender}
            reportOwnerName={report.owner_info?.name}
            reportOwnerPosition={report.owner_info?.position}
            reportOwnerRelationCompany={report.owner_info?.relation_company}
            reportOwnerRoles={report.owner_info?.roles[0]}
            reportOwnerOrganization={report.owner_info?.organization}
            reportOwnerPhoneNumber={report.owner_info?.phone}
            reportPosition={report.position}
            reportFirstName={report.first_name}
            reportLastName={report.last_name}
            reportEmail={report.email}
            reportRelationCompany={report.relation_company}
            reportThirdPartyOrganization={report.third_party_organization}
            reportTitle={report.title}
            reportDateAvailableTimeFrom={report.date_available_time_from}
            reportDateAvailableTimeTo={report.date_available_time_to}
            reportPhoneAvailableTime={report.phone_available_time}
            phoneAvailableTimes={this.phoneAvailableTimes}
            organization={this.props.Organization}
            otherOrganization={report?.other_organization}
            activeModule={this.props.modules.active}
          />
        ) : null}
      </TabPane>
    );
  }

  render() {
    return (
      <React.Fragment>
        <MemorizedDeletionReasonModal
          t={this.props.t}
          isDeleted={this.state.report?.is_deleted_content}
          handleSetPendingDeletionStatus={this.handleSetPendingDeletionStatus}
          reportId={this.state.report?.case_id}
          authToken={this.props.token}
          isOpen={this.state.showDeletionReasonModal}
          handleToggleDeletionReasonModal={this.handleToggleDeletionReasonModal}
        />

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.report?.secondary_case_id}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    {/* tab buttons */}
                    <Nav tabs>
                      {/* details */}
                      {!this.state.restrictedAnalyst &&  <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "report_details",
                          })}
                          onClick={() => {
                            this.handleToggleTab("report_details");
                          }}
                          disabled={
                            !this.state.report ||
                            this.state.report?.is_deleted_content
                          }
                        >
                          <span className="d-block">
                            {this.props.t("Report")}
                          </span>
                        </NavLink>
                      </NavItem> }

                      {/* messages */}
                      {!this.state.report ||
                      (this.state.report && !this.state.restrictedAnalyst &&
                        this.state.report.is_deleted_content) ? null : (
                        <NavItem hidden={!this.state.report.has_access_message}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTab === "messages",
                            })}
                            onClick={() => {
                              this.handleToggleTab("messages");
                            }}
                            disabled={
                              !this.state.report ||
                              this.state.report?.is_deleted_content
                            }
                          >
                            <span className="d-block">
                              {this.props.t("Messages")}
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* internal comments */}
                      {!this.state.report ||
                      this.state.report?.is_reporter_analyst_only ||
                      (this.state.report && 
                        this.state.report
                          .is_deleted_content) ? null : this.isAnalystOrAnalystAdmin() ? (
                            !this.state.restrictedAnalyst && <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active:
                                this.state.activeTab === "internal_comments",
                            })}
                            onClick={() => {
                              this.handleToggleTab("internal_comments");
                            }}
                            disabled={
                              !this.state.report ||
                              this.state.report?.is_deleted_content
                            }
                          >
                            <span className="d-block">
                              {this.props.t("Internal Comments")}
                            </span>
                          </NavLink>
                        </NavItem>
                      ) : null}

                      {/* risk assessment */}
                      {!this.state.report ||
                      this.state.report?.is_reporter_analyst_only ||
                      (this.state.report &&
                        this.state.report.is_deleted_content)
                        ? null
                        : isFeatureEnabledForActiveModule(
                            "risk-assessment"
                          ) &&
                          this.isAnalystOrAnalystAdmin() && (
                            <NavItem title="Risk Assessment">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active:
                                    this.state.activeTab === "risk_assessment",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("risk_assessment");
                                }}
                                disabled={
                                  !this.state.report ||
                                  this.state.report.is_deleted_content
                                }
                              >
                                <span className="d-block">
                                  {this.props.t("Risk Assessment")}
                                </span>
                              </NavLink>
                            </NavItem>
                          )}

                      {/* task manager */}
                      {!this.state.report ||
                      this.state.report?.is_reporter_analyst_only ||
                      (this.state.report &&
                        this.state.report.is_deleted_content)
                        ? null
                        : isFeatureEnabledForActiveModule(
                            "task-manager"
                          ) &&
                          this.isAnalystOrAnalystAdmin() && (
                            <NavItem title="Task Manager">
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active:
                                    this.state.activeTab === "task_management",
                                })}
                                onClick={() => {
                                  this.handleToggleTab("task_management");
                                }}
                                disabled={
                                  !this.state.report ||
                                  this.state.report.is_deleted_content
                                }
                              >
                                <span className="d-block">
                                  {this.props.t("Task Manager")}
                                </span>
                              </NavLink>
                            </NavItem>
                          )}
                    </Nav>

                    {/* tab contents */}
                    <TabContent activeTab={ this.state.activeTab }>
                      {/* details */}
                      {!this.state.restrictedAnalyst && this.handleRenderReportTab(
                        this.state.report,
                        this.props.token
                      )}

											{/* internal comments */}
											{!this.state.restrictedAnalyst &&  this.isAnalystOrAnalystAdmin() ? (
												<MemorizedInternalComments
													t={this.props.t}
													reportId={this.state.report?.case_id}
													reportType={this.state.report?.type}
													authToken={this.props.token}
													errorNotification={this.props.errorNotification}
													successNotification={this.props.successNotification}
													comments={this.state.internalComments}
													currentUser={this.props.currentUser}
													addItem={this.handleAddItemToInternalComments}
													suggestionsListAnalyst={this.state.suggestionsListAnalyst}
													isLoading={this.state.internalCommentsLoading}
													isFetched={this.state.internalCommentsFetched}
													isDeleted={this.state.report?.status === REPORT_STATUS_DELETED}
													isReadOnlyAnalyst={this.state.report?.is_read_only_analyst}
													currentUserId={this.props.currentUser.id}
													module={this.props.modules.active}
													isActive={this.state.activeTab === 'internal_comments'}
                          fetchReport={() => this.handleFetchInternalCommentsList(this.state.report.case_id, this.props.token)}
												/>
											) : null}

											{/* messages */}
											{!this.state.restrictedAnalyst  && <MemorizedMessages
												t={this.props.t}
                        report={this.state.report}
												reportId={this.state.report?.case_id}
												reportType={this.state.report?.type}
												authToken={this.props.token}
												errorNotification={this.props.errorNotification}
												successNotification={this.props.successNotification}
												messages={this.state.comments}
												currentUser={this.props.currentUser}
												addItem={this.handleAddItemToMessages}
												isLoading={this.state.messagesLoading}
												isFetched={this.state.messagesFetched}
												isDeleted={this.state.report?.status === REPORT_STATUS_DELETED}
												isReadOnlyAnalyst={this.state.report?.is_read_only_analyst}
												module={this.props.modules.active}
												isActive={this.state.activeTab === 'messages'}
                        fetchReport={() => this.handleFetchCommentsList(this.state.report.case_id, this.props.token)}
											/>}

                      {/* risk assessment */}
                      {
                      this.state.report &&
                      !this.state.report.is_deleted_content &&
                      isFeatureEnabledForActiveModule(
                        "risk-assessment"
                      ) &&
                      this.isAnalystOrAnalystAdmin() ? (
                        <MemorizedRiskAssessment
                          t={this.props.t}
                          authToken={this.props.token}
                          reportId={this.state.reportCaseId}
                          secondaryId={this.state.report?.secondary_case_id}
                          errorNotification={this.props.errorNotification}
                          successNotification={this.props.successNotification}
                          currentUser={this.props.currentUser}
                          isActive={this.state.activeTab === "risk_assessment"}
                          bottyRiskAI={this.state.bottyRiskAI}
                          unFlaggBottyRiskAI={() => this.setState({bottyRiskAI: false})}
                          handleSetReportRiskField={this.handleSetReportRiskField}
                          isDeleted={this.state.report?.is_deleted_content}
                          deletedByName={this.state.report?.deleted_by_name}
                          reportLocation={!this.state.restrictedAnalyst && this.state.report?.locations[0]}
                          reportCategories={this.state.report?.contents?.Questions?.find(i=>i.title==='Category')?.answer?.map(i=>i.title).join(', ')}
                          module={this.props.modules.active}
                          Feature={this.props.Feature}
                          adminDescription={this.state.report?.admin_description}
                          adminCategory={this.state.report?.admin_category}
                          reportType={this.state.report?.type}
                        />
                      ) : null}

                      {/* task manager */}
                      {this.state.report &&
                      !this.state.report.is_deleted_content &&
                      isFeatureEnabledForActiveModule(
                        "task-manager"
                      ) &&
                      this.isAnalystOrAnalystAdmin() ? (
                        <MemorizedTaskManager
                          t={this.props.t}
                          authToken={this.props.token}
                          reportId={this.state.reportCaseId}
                          errorNotification={this.props.errorNotification}
                          successNotification={this.props.successNotification}
                          currentUser={this.props.currentUser}
                          isActive={
                            this.state.activeTab === "task_management"
                              ? true
                              : false
                          }
                          isDeleted={this.state.report?.is_deleted_content}
                          deletedByName={this.state.report?.deleted_by_name}
                          module={this.props.modules.active}
                        />
                      ) : null}
                    </TabContent>
                    {/* <BottyPopup 
                      t={this.props.t} 
                      showBottyPopup={props?.showBottyPopup} 
                      // reportType={reportType} 
                      // adminDescription={adminDescription} 
                      // adminCategory={adminCategories} 
                      // errorNotification={props.errorNotification} 
                      // successNotification={props.successNotification} 
                      // authToken={props.authToken}
                      // reportId={localStorage.getItem("caseId")}
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
      
    );
  }
}

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { App, CaseId, Organization, Modules } = state;

  return {
		token,
		currentUser,
		App,
		CaseId,
		Organization,
        modules: Modules
	};
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ReportDetails)
  )
);
