import { connect } from "react-redux";
import { useCallback, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label } from "reactstrap";
import AsyncReactSelect from "src/components/AsyncReactSelect";
import EntityManagementService from "src/apis/EntityManagementService";

const EntitySelection = ({
    t,
    index,
    modules,
    onChange
}) => {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    
    const entityManagementService = EntityManagementService.getInstance();

    const fetchLegalEntitiesOptions = useCallback(async (params) => {
        const response = await entityManagementService.fetchEntitesList(params, modules.active);

        const options = response.legalEntities.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, []);

    const fetchLegalInstitutesOptions = useCallback(async (params = {}) => {
        if(!selectedEntity?.value) {
            return {
                options: [],
                pageIndex: 1,
                pageSize: 0,
                totalCount: 0
            };
        }
        
        const response = await entityManagementService.fetchInstituteList(params, selectedEntity.value, modules.active);

        const options = response.institutes.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, [ selectedEntity?.value ]);

    const fetchLegalDivisionsOptions = useCallback(async (params = {}) => {
        if(!selectedInstitute?.value) {
            return {
                options: [],
                pageIndex: 1,
                pageSize: 0,
                totalCount: 0
            };
        }

        const response = await entityManagementService.fetchDivisionList(params, selectedInstitute.value, modules.active);

        const options = response.divisions.map((entity) => {
            return {
                value: entity.id,
                label: entity.title
            }
        });

        return {
            options: options,
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            totalCount: response.itemsCount
        }
    }, [ selectedInstitute?.value ]);

    return (
        <Row className="mb-4">
            <Col md="4" sm="12">
                <Label htmlFor={`entity-${index}`}>
                    {t("Entity")}: 
                </Label>

                <AsyncReactSelect 
                    id={`entity-${index}`}
                    loadOptions={fetchLegalEntitiesOptions}
                    settings={{
                        queryKey: 'title',
                        loadOptionsErrorMessage: 'Faild to fetch'
                    }}
                    value={selectedEntity}
                    onChange={(e) => {
                        setSelectedEntity(e);
                        setSelectedInstitute(null);
                        setSelectedDivision(null);

                        onChange && onChange({
                            section: 'entity',
                            e: e
                        })
                    }} 
                    placeholder={t("Select an entity")}
                />
            </Col>

            <Col md="4" sm="12">
                <Label htmlFor={`institute-${index}`}>
                    {t("Institute")}:
                </Label>
                <AsyncReactSelect 
                    id={`institute-[${index}]`}
                    loadOptions={fetchLegalInstitutesOptions}
                    value={selectedInstitute}
                    settings={{
                        queryKey: 'title',
                        loadOptionsErrorMessage: 'Faild to fetch'
                    }}
                    onChange={(e) => {
                        setSelectedInstitute(e)
                        setSelectedDivision(null);

                        onChange && onChange({
                            section: 'institute',
                            e: e
                        })
                    }} 
                    placeholder={t("Select an institute")}
                />
            </Col>

            <Col md="4" sm="12">
                <Label htmlFor={`division-${index}`}>
                    {t("Division")}:
                </Label>
                <AsyncReactSelect 
                    id={`division-${index}`}
                    loadOptions={fetchLegalDivisionsOptions}
                    value={selectedDivision}
                    settings={{
                        queryKey: 'title',
                        loadOptionsErrorMessage: 'Faild to fetch'
                    }}
                    onChange={(e) => {
                        setSelectedDivision(e);

                        onChange && onChange({
                            section: 'division',
                            e: e
                        })
                    }} 
                    placeholder={t("Select a division")}
                />
            </Col>
        </Row>
    )
}

const mapStatetoProps = (state) => {
    const { Modules } = state;
    return {
        modules: Modules
    };
};

export default withNamespaces()(
    connect(mapStatetoProps)(EntitySelection)
)