import { withNamespaces } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, CardTitle, Button } from 'reactstrap';
import axios from 'axios';
import { toast } from "react-toastify";
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import {
	API_BASE_URL,
	ROUTE_PATH_THIRDPARTY_HOME
} from '../../../constants';
import {
	confirmPromptHide
} from 'src/store/actions';
import Spinner from 'react-bootstrap/Spinner';
import CompanyDetails from './tabs-content/company-details';
import FillQuestionnaire from './tabs-content/fill-questionnaire';
import { useQuery } from '@tanstack/react-query';
import SupplierService from 'src/modules/3rd-party-management/apis/thirdparty/SupplierService';
import {
    AnswerSheet
} from '@smartintegrity/questionnaire';

const ThirdPartyQuestioner = (props) => {
    const {
        t,
		exitConfirmPromptOptions,
		hideConfirmPrompt
    } = props;

    const history = useHistory();

	const [ questionnaireData, setQuestionnaireData ] = useState({
		reportCaseId	:	null,
		questions		:	[],
		answers			:	null
	});

    const [ isCreateNewFormSubmiting, setIsCreateNewFormSubmiting ] = useState(false);
	const [ declarationFormConfirmed, setDeclarationFormConfirmed ] = useState(false);
	const [ tabs, setTabs ] = useState([
		{
			name		:	'company-details',
			title		:	'Company Details',
			completed	:	false,
			isCurrent	:	true
		},
		{
			name		:	'fill-questions-step',
			title		:	'Fill Questionnaire',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'review-details',
			title		:	'Review Details',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'declaration',
			title		:	'Declaration',
			completed	:	false,
			isCurrent	:	false
		}	
	]);

    const {
        data: openReportRequestData,
        isFetching: openReportRequestDataIsFetching
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-supplier-report-requests',
            'tpdd',
            props.Organization.id
        ],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchUserReportRequests();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: 0,
        onError: ({
            response
        }) => {
            hideConfirmPrompt();
            
            if(response.data.error === 'report_request_list_empty'){
                toast(t('It is not possible to send a report before the request is created by admin'), {
                    type: 'error',
                });
            }
            else{
                toast(t('An error occurred while starting!'), {
                    type: 'error',
                });
            }

            history.push('/third-party/3rd-party/home');
        }
    });

	const setActiveTab = (tab) => {
		setTabs((tabs) => {
			const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);
			const nextActiveTabIndex = tabs.findIndex((t) => t.name === tab);

			if(currentActiveTabIndex > -1){
				tabs[currentActiveTabIndex].completed = true;
				tabs[currentActiveTabIndex].isCurrent = false;
			}

			if(nextActiveTabIndex > -1){
				tabs[nextActiveTabIndex].isCurrent = true;
			}

			return [
				...tabs
			];
		});
	}

	const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);

		axios.post(`${API_BASE_URL}/report/${questionnaireData.reportCaseId}/submit`, questionnaireData.answers, {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
		.then(() => {
			toast(t('Request Submitted'), {
				type: 'success',
			});

			(exitConfirmPromptOptions.readyToShow) && hideConfirmPrompt();

			setTimeout(() => {
				props.history.push(ROUTE_PATH_THIRDPARTY_HOME);
			}, 250);
		})
		.catch((error) => {
			console.error(error);

			toast(t('Error!'), {
				type: 'error',
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
		});
	}

	const renderStepsWizard = () => {
		const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

		return (
			<Row>
				<Col sm={12}>
					<div className="m-2 bg-white wizard-steps-container mb-5">
						<div className="twitter-bs-wizard">
							<ul className="twitter-bs-wizard-nav nav-justified nav nav-pills p-3">
								{
									tabs.map((tab, index) => {
										return (
											<NavItem key={ index } className={ tab.completed ? 'completed' : '' }>
												<NavLink className={ (index <= currentActiveTabIndex ? 'active ' : '') + (tab.isCurrent ? 'current' : '') }>
													<span className="step-number">
														{
															tab.completed ? (
																<i className='fa fa-check'></i>
															) : ( index + 1 )
														}
													</span>

													<span className="step-title">
														{props.t(tab.title)}
													</span>
												</NavLink>
											</NavItem>
										);
									})
								}
							</ul>
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	useEffect(() => {
		if(questionnaireData.answers){
			setActiveTab('review-details');
		}
	}, [ questionnaireData.answers ]);

	const activeTab = tabs.find((t) => t.isCurrent);

    return (
        <div className='page-content thirdparty-questionnaire'>
			<Container fluid>
				{ renderStepsWizard() }

                {!openReportRequestDataIsFetching && openReportRequestData?.request?.id ? (
                    <Row>
                        <Col sm={12}>
                            {
                                activeTab.name === 'company-details' && (
                                    <CompanyDetails onSubmitSucceed={() => {
                                        setActiveTab('fill-questions-step');
                                    }} />
                                )
                            }

                            {
                                activeTab.name === 'fill-questions-step' && (
                                    <FillQuestionnaire 
                                        openReportRequestId={openReportRequestData.request.id}
                                        questionnaireType={openReportRequestData.request.questionnaire.type}
                                        onBackButtonClicked={() => setActiveTab('company-details')}
                                        onValidSubmit={ ({
                                            reportCaseId,
                                            questions,
                                            answers
                                        }) => {
                                            setQuestionnaireData({
                                                reportCaseId	:	reportCaseId,
                                                questions		:	questions,
                                                answers			:	answers
                                            });
                                        }}
                                    />
                                )
                            }

                            { 
                                activeTab.name === 'review-details' && (
                                    <div className="m-2">
                                        <AnswerSheet 
                                            settings={{
                                                buttons	:	{
                                                    back	:	{
                                                        enabled	:	true
                                                    },
                                                    confirm	:	{
                                                        enabled	:	true,
                                                        title	:	t('Next')
                                                    }
                                                },
                                                fileDownloaderOptions: {
                                                    downloadRequestOptions: {
                                                        headers: {
                                                            Authorization: `Bearer ${props.token}`
                                                        },
                                                        payload: {},
                                                        requestUrl: ({
                                                            questionId,
                                                            attachmentId
                                                        }) => {
                                                            return `${API_BASE_URL}/report/attachment/${attachmentId}/download`
                                                        }
                                                    }
                                                }
                                            }}
                                            questions={ questionnaireData.questions }
                                            answers={ questionnaireData.answers }
                                            onConfirmed={() => {
                                                setActiveTab('declaration');
                                            }}
                                            onBackButtonClicked={() => {
                                                setActiveTab('fill-questions-step');
                                            }}
                                        />
                                    </div>
                                )
                            }

                            {
                                activeTab.name === 'declaration' && (
                                    <div className="declaration-confirm-form m-2 mt-0">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-3">
                                                    { t('Declaration of Accuracy and Completeness') }
                                                </CardTitle>

                                                <p className="mb-5">
                                                    {
                                                        t('I hereby affirm that the information provided in this questionnaire is true, accurate, and complete to the best of my knowledge and belief. I understand that any false statements, omissions, or misrepresentations may result in consequences as determined by the entity requesting this information (which could include legal actions or disqualification from a process, depending on the context). I commit to notify the relevant parties immediately should any information change after the submission of this questionnaire.')
                                                    }
                                                </p>

                                                <div>
                                                    <Form.Check
                                                        onChange={({ target }) => setDeclarationFormConfirmed(target.checked)} 
                                                        type='checkbox' 
                                                        id={`confirm-declaration-checkbox`} 
                                                        label={t('I confirm the information above.')} />
                                                </div>
                                            </CardBody>
                                        </Card>

                                        <div className="d-flex justify-content-end align-items-center mb-4">
                                            <Button color="primary"
                                                disabled={ !declarationFormConfirmed || isCreateNewFormSubmiting }
                                                onClick={ handleSubmit }
                                                type="button">
                                                    {
                                                        isCreateNewFormSubmiting ? (
                                                            <>
                                                                <Spinner className="me-2" animation="border" size="sm"/>
                                                                {t('Submitting')}...
                                                            </>
                                                        ) : (
                                                            <>{t('Submit')}</>
                                                        )
                                                    }
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                ) : (
                    <p className='text-center'>{t('Please wait')}...</p>
                )}
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token } = state.Login;
	const { Organization, ExitConfirmPrompt} = state;
	
	return {
		token,
		Organization,
		exitConfirmPromptOptions	:	ExitConfirmPrompt
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
			hideConfirmPrompt	:	() => confirmPromptHide(),
		})(ThirdPartyQuestioner)
	)
);