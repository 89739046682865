import {
    Settings as ModuleDataProtectionSettings
} from 'src/modules/data-protection/exports';

import {
    Settings as ModuleTPDDSettings
} from 'src/modules/3rd-party-management/exports';

const AUTH_DOMAIN = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const AZURE_AUTH_DOMAIN = process.env.REACT_APP_AZURE_AUTH_ENDPOINT;
const AI_DOMAIN = process.env.REACT_APP_AI + "api/";
const AUTH_API_DOMAIN = AUTH_DOMAIN + "api/";
export const API_BASE_URL = AUTH_DOMAIN + "api";
const CHAT_BOT_DOMAI = process.env.REACT_APP_CHATBOT_API_ENDPOINT + "api/"
const RISK_AI_DOMAIN = process.env.REACT_APP_RISK_AI;
export const USER_MANAGEMENT_URL = process.env.REACT_APP_CUSTOMER_API_ENDPOINT + "api";
export const CM_USER_MANAGEMENT_URL = process.env.REACT_APP_CUSTOMER_API_ENDPOINT+"api/cm";
// let AUTH_API_DOMAIN = ''
// // if (process?.env?.NODE_ENV === 'development') {
// // 	AUTH_API_DOMAIN = '/api/';
// // } else {
// // }
// AUTH_API_DOMAIN = AUTH_DOMAIN + 'api/';

export const AUTH_API_DOMAIN_URL = AUTH_API_DOMAIN;
export const API_URL_SIGN_IN = AUTH_API_DOMAIN + "auth.signin";
export const API_URL_LOG_OUT = AUTH_API_DOMAIN + "auth.signout";
export const API_URL_REGISTER = AUTH_API_DOMAIN + "register";
export const API_URL_GET_USER_DETAIL = AUTH_API_DOMAIN + "auth.current";
export const CM_API_URL_GET_USER_DETAIL = AUTH_API_DOMAIN + "cm/auth.current";

export const API_URL_CREATE_THIRD_PARTY_ACCOUNT =
  AUTH_API_DOMAIN + "thirdParty.register";
export const API_URL_REPORT_THIRD_PARTY_CATEGORY_LIST =
  AUTH_API_DOMAIN + "thirdParty.category.list";
export const API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "thirdParty.questions.list";
export const CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "cm/thirdParty.questions.list";

export const API_URL_FETCH_COUNTRIES =   API_BASE_URL + '/report.country.list';

export const API_URL_ADMIN_AND_TASK_OWNERS_LIST        =  API_BASE_URL + "/analystAndAdminAnalystAndTaskOwners.list";

export const API_URL_SET_PASSWORD = AUTH_API_DOMAIN + "user.setPassword";
export const API_URL_CHECK_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.verifyPasswordToken";
export const API_URL_VERIFY_OTP = AUTH_API_DOMAIN + "auth.exchangeToken";
export const API_URL_RESEND_OTP = AUTH_API_DOMAIN + "auth.resendOtp";

export const API_URL_REQUEST_RESET_PASSWORD =
  AUTH_API_DOMAIN + "user.forgetPassword";
export const API_URL_EXCHANGE_RESET_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.exchangeResetPasswordToken";
export const API_URL_VERIFY_RESET_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.verifyResetPasswordToken";
export const API_URL_RESET_PASSWORD = AUTH_API_DOMAIN + "user.resetPassword";
export const API_URL_CHANGE_LANGUAGE = AUTH_API_DOMAIN + "user.switchLanguage";
export const API_URL_CHANGE_PASSWORD = AUTH_API_DOMAIN + "user.changePassword";

export const API_URL_AZURE_LOGIN = AZURE_AUTH_DOMAIN + "azure_login";
export const API_URL_AZURE_CALLBACK = AZURE_AUTH_DOMAIN + "azure_callback";

// for testing purpose
export const API_URL_TRANSLATION_LIST = process.env.REACT_APP_TRANSLATION_API;

// Report routes
export const API_URL_CREATE_REPORT = AUTH_API_DOMAIN + "report.create";
export const CM_API_URL_CREATE_REPORT = AUTH_API_DOMAIN + "cm/report.create";
export const API_URL_GET_ANALYST_REPORT_LIST = AUTH_API_DOMAIN + "report.list";
export const CM_API_URL_GET_ANALYST_REPORT_LIST =
  AUTH_API_DOMAIN + "cm/report.list";
export const API_URL_GET_ANALYST_REPORT_LISTS_COUNT =
  AUTH_API_DOMAIN + "reports/count";
export const CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT =
  AUTH_API_DOMAIN + "cm/reports/count";
export const API_URL_GET_REPORT_DELETED_LIST =
  AUTH_API_DOMAIN + "report.listDeletedCases";
export const API_URL_GET_REPORT_LIST = AUTH_API_DOMAIN + "report.listMyCases";
export const CM_API_URL_GET_REPORT_LIST =
  AUTH_API_DOMAIN + "cm/report.listMyCases";
export const API_URL_GET_REPORT_DETAIL = AUTH_API_DOMAIN + "report.view";
export const CM_API_URL_GET_REPORT_DETAIL = AUTH_API_DOMAIN + "cm/report.view";
export const API_URL_REPORT_UPLOAD_FILE =
  AUTH_API_DOMAIN + "report.uploadAttachment";
export const CM_API_URL_REPORT_UPLOAD_FILE =
  AUTH_API_DOMAIN + "cm/report.uploadAttachment";
export const API_URL_REPORT_UPLOAD_MULTIPLE_FILE =
  AUTH_API_DOMAIN + "report.uploadAttachments";
export const CM_API_URL_REPORT_UPLOAD_MULTIPLE_FILE =
  AUTH_API_DOMAIN + "cm/report.uploadAttachments";
export const API_URL_ADD_COMMENT = AUTH_API_DOMAIN + "report.addComment";
export const CM_API_URL_ADD_COMMENT = AUTH_API_DOMAIN + "cm/report.addComment";
export const API_URL_ADD_INTERNAL_COMMENT =
  AUTH_API_DOMAIN + "report.addCommentInternal";
export const CM_API_URL_ADD_INTERNAL_COMMENT =
  AUTH_API_DOMAIN + "cm/report.addCommentInternal";
export const API_URL_GET_COMMENT_LIST = AUTH_API_DOMAIN + "report.listComment";
export const CM_API_URL_GET_COMMENT_LIST =
  AUTH_API_DOMAIN + "cm/report.listComment";

export const API_URL_GET_INTERNAL_COMMENT_LIST =
  AUTH_API_DOMAIN + "report.listCommentInternal";
export const CM_API_URL_GET_INTERNAL_COMMENT_LIST =
  AUTH_API_DOMAIN + "cm/report.listCommentInternal";

// export const API_URL_REPORT_LINK_CASE = AUTH_API_DOMAIN + "report.linkCase";
export const API_URL_REPORT_ADD_LINK_CASE = AUTH_API_DOMAIN + "report/add_link_case";
export const API_URL_REPORT_REMOVE_LINK_CASE = AUTH_API_DOMAIN + "report/remove_link_case";
export const CM_API_URL_REPORT_ADD_LINK_CASE = AUTH_API_DOMAIN + "cm/report/add_link_case";
export const CM_API_URL_REPORT_REMOVE_LINK_CASE = AUTH_API_DOMAIN + "cm/report/remove_link_case";
// export const CM_API_URL_REPORT_LINK_CASE =
//   AUTH_API_DOMAIN + "cm/report.linkCase";
export const API_URL_UPDATE_REPORT_STATUS =
  AUTH_API_DOMAIN + "report.updateStatus";
export const CM_API_URL_UPDATE_REPORT_STATUS =
  AUTH_API_DOMAIN + "cm/report.updateStatus";
export const API_URL_REPORT_ASSIGNED_ANALYST =
  AUTH_API_DOMAIN + "report.changeAnalyst";
export const CM_API_URL_REPORT_ASSIGNED_ANALYST =
  AUTH_API_DOMAIN + "cm/report.changeAnalyst";
export const API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS =
  AUTH_API_DOMAIN + "report.downloadUserAttachment";
export const API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS =
  AUTH_API_DOMAIN + "report.downloadAnalystAttachment";
export const API_URL_REPORT_CONFIRM_OPEN =
  AUTH_API_DOMAIN + "report.markAsRead";
export const API_URL_REPORT_MARK_AS_DELETE =
  AUTH_API_DOMAIN + "report.markAsDelete";
export const CM_API_URL_REPORT_MARK_AS_DELETE =
  AUTH_API_DOMAIN + "cm/report.markAsDelete";
export const API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION =
  AUTH_API_DOMAIN + "report.changeAdminDescription";
  export const CM_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION =
  AUTH_API_DOMAIN + "cm/report.changeAdminDescription";
export const API_URL_REPORT_UPDATE_ADMIN_CATEGORIES =
  AUTH_API_DOMAIN + "report.changeAdminCategory";
  export const CM_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES =
  AUTH_API_DOMAIN + "cm/report.changeAdminCategory";
export const API_URL_REPORT_DELETE = AUTH_API_DOMAIN + "report.delete";
export const CM_API_URL_REPORT_DELETE = AUTH_API_DOMAIN + "cm/report.delete";
export const API_URL_REPORT_CATEGORY_LIST =
  AUTH_API_DOMAIN + "report-category.list";
export const CM_API_URL_REPORT_CATEGORY_LIST =
  AUTH_API_DOMAIN + "report-category.list";
export const API_URL_REPORT_CATEGORY_LIST_CASE =
  AUTH_API_DOMAIN + "report-category-case.list";
export const CM_API_URL_REPORT_CATEGORY_LIST_CASE =
  AUTH_API_DOMAIN + "cm/report-category-case.list";
export const API_URL_UPDATE_REPORT_RISK_RATE =
  AUTH_API_DOMAIN + "report.updateRiskRate";
export const CM_API_URL_UPDATE_REPORT_RISK_RATE =
  AUTH_API_DOMAIN + "cm/report.updateRiskRate";
export const API_URL_REPORT_TRANSLATOR_CONTECNT =
  AUTH_API_DOMAIN + "translate-content.json";
export const CM_API_URL_REPORT_TRANSLATOR_CONTECNT =
  AUTH_API_DOMAIN + "cm/translate-content.json";
export const API_URL_LANGUAGE_ORGANIZATION =
  AUTH_API_DOMAIN + "language.organization.list";
export const API_URL_UPDATE_DESCRIPTION =
  AUTH_API_DOMAIN + "voice_report/edit_description";
export const API_URL_ANALYST_LIST = AUTH_API_DOMAIN + "analyst.list";
export const API_URL_GENERATE_RANDOM_USERNAME =
  AUTH_API_DOMAIN + "generateUsername?language=";

export const API_URL_REPORT_INTRNAL_COMMENTS_SEARCH =
  AUTH_API_DOMAIN + "report.mentionCommentSearch?query";
export const CM_API_URL_REPORT_INTRNAL_COMMENTS_SEARCH =
  AUTH_API_DOMAIN + "cm/report.mentionCommentSearch?query";

// Risk Assesment
export const API_URL_FETCH_RISK_MATRIX = AUTH_API_DOMAIN + "risk_rate/matrix";
export const CM_API_URL_FETCH_RISK_MATRIX =
  AUTH_API_DOMAIN + "cm/risk_rate/matrix";
export const API_URL_FETCH_RISK_RATINGS = AUTH_API_DOMAIN + "risk_rate/ratings";
export const CM_API_URL_FETCH_RISK_RATINGS =
  AUTH_API_DOMAIN + "cm/risk_rate/ratings";
export const API_URL_FETCH_RISK_CATEGORIES =
  AUTH_API_DOMAIN + "risk_rate_category/index";
export const CM_API_URL_FETCH_RISK_CATEGORIES =
  AUTH_API_DOMAIN + "cm/risk_rate_category/index";
export const API_URL_FETCH_RISK_LIKELIHOODS =
  AUTH_API_DOMAIN + "risk_rate/likelihoods";
export const CM_API_URL_FETCH_RISK_LIKELIHOODS =
  AUTH_API_DOMAIN + "risk_rate/likelihoods";
export const API_URL_FETCH_RISK_LEGAL_ENTETIES =
  AUTH_API_DOMAIN + "legal_entity/index";
export const API_URL_CREATE_RISK = AUTH_API_DOMAIN + "risk_rate/create";
export const CM_API_URL_CREATE_RISK = AUTH_API_DOMAIN + "cm/risk_rate/create";
export const API_URL_RISK_UPLOAD_FILE =
  AUTH_API_DOMAIN + "risk_rate/upload_file";
export const CM_API_URL_RISK_UPLOAD_FILE =
  AUTH_API_DOMAIN + "cm/risk_rate/upload_file";
export const API_URL_FETCH_RISK_LOCATIONS =
  AUTH_API_DOMAIN + "report.country.list";
export const CM_API_URL_FETCH_RISK_LOCATIONS =
  AUTH_API_DOMAIN + "cm/report.country.list";
export const API_URL_RISK_UPLOAD_FILES =
  AUTH_API_DOMAIN + "risk_rate/upload_files";
export const CM_API_URL_RISK_UPLOAD_FILES =
  AUTH_API_DOMAIN + "cm/risk_rate/upload_files";
export const API_URL_FETCH_MANAGERS = AUTH_API_DOMAIN + "manager.list";
export const CM_API_URL_FETCH_MANAGERS = AUTH_API_DOMAIN + "cm/manager.list";
export const API_URL_FETCH_DAMAGES = AUTH_API_DOMAIN + "risk_rate/consequences";
export const API_URL_RISK_COSTOMIZATION =
  AUTH_API_DOMAIN + "risk_rate/customization";
export const CM_API_URL_RISK_COSTOMIZATION =
  AUTH_API_DOMAIN + "cm/risk_rate/customization";
export const API_URL_RISK_RESTORE_COSTOMIZATION =
  AUTH_API_DOMAIN + "risk_rate/customization_restore";
export const CM_API_URL_RISK_RESTORE_COSTOMIZATION =
  AUTH_API_DOMAIN + "risk_rate/customization_restore";


  // RISK AI
export const  API_URL_RISK_AI_TRANSLATION = CHAT_BOT_DOMAI + "risk/change.get";
export const  API_URL_RISK_AI_GET_ADDITIONAL_DETAILS = CHAT_BOT_DOMAI + "risk/risk-ai.get";
export const API_URL_GET_RISK_AI_CLASS = RISK_AI_DOMAIN + "promts";
// export const API_URL_RISK_POST_COMMENT =
// AUTH_API_DOMAIN + "task_comment/create";

// Task Manager
export const API_URL_GET_TASK_MANAGER = AUTH_API_DOMAIN + "tasks";
export const API_URL_BASE_TASK_MANAGER = AUTH_API_DOMAIN + "task";
export const API_URL_TASK_UPLOAD_FILES = AUTH_API_DOMAIN + "task/upload_files";
export const CM_API_URL_TASK_UPLOAD_FILES =
  AUTH_API_DOMAIN + "cm/task/upload_files";
export const API_URL_TASK_UPLOAD_FILE = AUTH_API_DOMAIN + "task/upload_file";
export const CM_API_URL_TASK_UPLOAD_FILE =
  AUTH_API_DOMAIN + "cm/task/upload_file";
export const API_URL_TASK_POST_COMMENT =
  AUTH_API_DOMAIN + "task_comment/create";
export const CM_API_URL_TASK_POST_COMMENT =
  AUTH_API_DOMAIN + "cm/task_comment/create";
export const API_URL_TASK_GET_COMMENTS = AUTH_API_DOMAIN;
export const API_URL_TASK_DOWNLOAD_COMMENT_FILES = AUTH_API_DOMAIN;
export const API_URL_TASK_DETAILS = AUTH_API_DOMAIN;

export const API_URL_GET_KANBAN_BOARD = AUTH_API_DOMAIN + "task/kanban_board";
export const CM_API_URL_GET_KANBAN_BOARD =
  AUTH_API_DOMAIN + "cm/task/kanban_board";

// Organization
export const API_URL_VERIFY_ORGANIZATION =
  AUTH_API_DOMAIN + "organization.verify";
export const API_URL_EDIT_INFO_ORGANIZATION =
  AUTH_API_DOMAIN + "organization.edit";
export const API_URL_GET_INFO_ORGANIZATION =
  AUTH_API_DOMAIN + "organization.info";

// Organization
export const CM_API_URL_VERIFY_ORGANIZATION =
AUTH_API_DOMAIN + "cm/organization.verify";
export const CM_API_URL_EDIT_INFO_ORGANIZATION =
AUTH_API_DOMAIN + "cm/organization.edit";
export const CM_API_URL_GET_INFO_ORGANIZATION =
AUTH_API_DOMAIN + "cm/organization.info";

// QuestionnaireDesigner
export const API_URL_QUESTION_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "questions.list";
export const CM_API_URL_QUESTION_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "cm/questions.list";
export const API_URL_QUESTION_DETAILS_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "questions-details.list";
export const CM_API_URL_QUESTION_DETAILS_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "cm/questions-details.list";
export const API_URL_FETCH_CAPTCHA = AUTH_API_DOMAIN + "user.getCaptcha"

export const GD_API_URL_QUESTION_DETAILS_FORM_ORGANIZATION =
AUTH_API_DOMAIN + "gd/questions-details.list";

export const API_URL_QUESTION_GENERATOR_GET_LIST =
  AUTH_API_DOMAIN + "questions-analyst-admin.list";
export const CM_API_URL_QUESTION_GENERATOR_GET_LIST =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.list";
export const API_URL_QUESTION_GENERATOR_UPDATE_LIST =
  AUTH_API_DOMAIN + "questions-analyst-admin.add";
export const CM_API_URL_QUESTION_GENERATOR_UPDATE_LIST =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.add";
export const API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS =
  AUTH_API_DOMAIN + "questions-analyst-admin.remove";
export const CM_API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.remove";
export const API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS =
  AUTH_API_DOMAIN + "questions-analyst-admin.subquestions.remove";
export const CM_API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.subquestions.remove";

// Admin User Management
export const API_URL_ADMIN_GET_USER_LOGS =
  AUTH_API_DOMAIN + "employee.edit.log";
export const API_URL_ADMIN_GET_USER_MANAGEMENT_LIST =
USER_MANAGEMENT_URL + "/user/list";
export const CM_API_URL_ADMIN_GET_USER_MANAGEMENT_LIST =
CM_USER_MANAGEMENT_URL + "/user/list";
export const API_URL_ADMIN_GET_USER_COUNTS_BY_ROLE =
USER_MANAGEMENT_URL + "/organization/licences";
export const CM_API_URL_ADMIN_GET_USER_COUNTS_BY_ROLE =
API_BASE_URL + "/organization/licences";
export const API_URL_ADMIN_GET_WB_OTHER_MODULE_USER_LIST  =  
USER_MANAGEMENT_URL + "/modules/other_users";
export const CM_API_URL_ADMIN_GET_WB_OTHER_MODULE_USER_LIST  =  
CM_USER_MANAGEMENT_URL + "/modules/other_users";
export const API_URL_ADMIN_DELETE_WB_USER_MANAGEMENT =  
USER_MANAGEMENT_URL + "/user/delete";
export const CM_API_URL_ADMIN_DELETE_WB_USER_MANAGEMENT =  
CM_USER_MANAGEMENT_URL + "/user/delete";
export const API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST =
  AUTH_API_DOMAIN + "partner.clients.list";
export const API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL =
USER_MANAGEMENT_URL + "/user/detail";
export const CM_API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL =
CM_USER_MANAGEMENT_URL + "/user/detail";
export const API_URL_ADMIN_UPDATE_USER_MANAGEMENT =
USER_MANAGEMENT_URL + "/user/edit";
export const CM_API_URL_ADMIN_UPDATE_USER_MANAGEMENT =
CM_USER_MANAGEMENT_URL + "/user/edit";

export const API_URL_ADMIN_UPDATE_PROFILE_MANAGEMENT =
USER_MANAGEMENT_URL + "/profile/edit";
export const CM_API_URL_ADMIN_UPDATE_PROFILE_MANAGEMENT =
CM_USER_MANAGEMENT_URL + "/profile/edit";

export const API_URL_ADMIN_CREATE_USER_MANAGEMENT =
USER_MANAGEMENT_URL + "/user/create";
export const CM_API_URL_ADMIN_CREATE_USER_MANAGEMENT =
CM_USER_MANAGEMENT_URL + "/user/create";
export const API_URL_ADMIN_CREATE_EXISTING_USER =
USER_MANAGEMENT_URL + "/modules/add_user";
export const CM_API_URL_ADMIN_CREATE_EXISTING_USER =
CM_USER_MANAGEMENT_URL + "/modules/add_user";
export const API_URL_ADMIN_DELETE_USER_MANAGEMENT =
  AUTH_API_DOMAIN + "employee.delete";

export const API_URL_ADMIN_GET_CM_OTHER_MODULE_USER_LIST  =  
AUTH_API_DOMAIN + "cm/modules/other_users";
export const API_URL_ADMIN_DELETE_CM_USER_MANAGEMENT =  
AUTH_API_DOMAIN + "cm/employee.delete";


export const API_URL_ACTIVE_DIRECTORY =
  AUTH_API_DOMAIN + "organization/active_directory/edit";
export const API_URL_ACTIVE_DIRECTORY_FETCH =
  AUTH_API_DOMAIN + "organization/active_directory/index";

// Chat Live
export const API_URL_GET_STARTED_CHAT = AUTH_API_DOMAIN + "chat.getStarted";
export const API_URL_SEND_MESSAGE_CHAT = AUTH_API_DOMAIN + "chat.sendMessage";
export const API_URL_END_CHAT = AUTH_API_DOMAIN + "chat.finish";
export const API_URL_ANALYST_REPLY_CHAT = AUTH_API_DOMAIN + "chat.replyMessage";
export const API_URL_ANALYST_GET_LIST_CHAT = AUTH_API_DOMAIN + "chat.list";
export const API_URL_ANALYST_GET_CONVERSATION_CHAT =
  AUTH_API_DOMAIN + "chat.getConversation";
export const API_URL_ANALYST_DELETE_CONVERSATION_CHAT =
  AUTH_API_DOMAIN + "chat.deleteConversation";
export const API_URL_REMIND_UNANSWERED_CHAT =
  AUTH_API_DOMAIN + "chat.remindUnansweredChat";

// Notification
export const API_URL_NOTIFICATION_LIST = AUTH_API_DOMAIN + "notification.list";
export const CM_API_URL_NOTIFICATION_LIST = AUTH_API_DOMAIN + "cm/notification.list";
export const API_URL_GET_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notificationSetting.list";
export const CM_API_URL_GET_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notificationSetting.list";
export const API_URL_UPDATE_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notification.edit";
export const CM_API_URL_UPDATE_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notification.edit";
export const API_URL_NOTIFICATION_MARK_READ =
  AUTH_API_DOMAIN + "notification.markAsRead";
export const CM_API_URL_NOTIFICATION_MARK_READ =
  AUTH_API_DOMAIN + "notification.markAsRead";
export const API_URL_NOTIFICATION_MARK_ALL_READ =
  AUTH_API_DOMAIN + "notification.markAllAsRead";

// e-learning
export const API_URL_GET_E_LEARNING_VIDEOS = AUTH_API_DOMAIN + "elearning.list";
export const API_URL_GET_E_LEARNING_LESSONS = `${process.env.REACT_APP_ELEARNING_API}api/getall`;
export const API_URL_GET_E_LEARNING_DETAILS = `${process.env.REACT_APP_ELEARNING_API}api/getdetails`;
export const API_URL_GET_E_LEARNING_AGENDA = `${process.env.REACT_APP_ELEARNING_API}api/getagenda`;
export const API_URL_GET_E_LEARNING_COUNTER = `${process.env.REACT_APP_ELEARNING_API}api/counter`;
export const API_URL_POST_E_LEARNING_PLAYER_TOKEN = `${process.env.REACT_APP_ELEARNING_API}api/updatescormtoken`;

export const API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST =
  AUTH_API_DOMAIN + "analystAndAdminAnalyst.list";

// Dashboard
export const API_URL_ADMIN_DASHBOARD = AUTH_API_DOMAIN + "dashboard";
export const CM_API_URL_ADMIN_DASHBOARD = AUTH_API_DOMAIN + "cm/dashboard";
export const API_URL_ADMIN_DASHBOARD_PARTNER_LIST =
  AUTH_API_DOMAIN + "organization/partners";
export const CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST =
  AUTH_API_DOMAIN + "cm/organization/partners";
export const API_URL_ADMIN_DASHBOARD_LAST_CASES =
  AUTH_API_DOMAIN + "last_cases";
export const CM_API_URL_ADMIN_DASHBOARD_LAST_CASES =
  AUTH_API_DOMAIN + "cm/last_cases";
export const API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS =
  AUTH_API_DOMAIN + "dashboard/download";
export const CM_API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS =
  AUTH_API_DOMAIN + "cm/dashboard/download";

// Feature Flagging
export const API_URL_FEATURE_FLAGGING =
  AUTH_API_DOMAIN + "user.featureFlagging";

export const API_URL_CM_FEATURE_FLAGGING =
  AUTH_API_DOMAIN + "cm/user.featureFlagging";

// Customer API
export const API_URL_CUSTOMER_API = AUTH_API_DOMAIN;

// Documents/User Manuals
export const API_URL_GET_DOCUMENTS = AUTH_API_DOMAIN + "user_manual/list";
export const API_URL_DOWNLOAD_DOCUMENTS = AUTH_API_DOMAIN + "user_manual";
export const API_URL_UPLOAD_DOCUMENTS = AUTH_API_DOMAIN + "user_manual/create";
export const API_URL_CONSENT_APPROVEMENT = AUTH_API_DOMAIN + "consent/approve";

// QR
export const API_URL_GET_QR_CODE = AUTH_API_DOMAIN + "download.qrcode";

// Languages
export const API_URL_GET_LANGUAGES = AUTH_API_DOMAIN + "language/list";

export const API_URL_GET_TUTORIAL_LESSONS = `${process.env.REACT_APP_ELEARNING_API}api/getalltutorials`;
export const API_URL_GET_TUTORIAL_DETAILS = `${process.env.REACT_APP_ELEARNING_API}api/gettutorialdetails`;
export const API_URL_GET_TUTORIAL_ADMIN_LIST = `${process.env.REACT_APP_ELEARNING_API}api/gettutorialsforadmin`;

// Administration
export const API_URL_GET_HISTORY_LOGS = AUTH_API_DOMAIN + "logs";
export const API_URL_GET_USERS = AUTH_API_DOMAIN + "users";

// GRC Dashboard
export const API_URL_GRC_DASHBOARD_CUSTOMIZATION = process.env.REACT_APP_GRC_API + "dashboard/customization.get";
export const API_URL_GRC_DASHBOARD_STATUS = process.env.REACT_APP_GRC_API + "dashboard/status.get";
export const API_URL_GRC_DASHBOARD_RISKS_TASKS = process.env.REACT_APP_GRC_API + "dashboard/risks-tasks.get";
export const API_URL_GRC_DASHBOARD_TASKS_STATUS = process.env.REACT_APP_GRC_API + "dashboard/tasks-status.get";
export const API_URL_GRC_DASHBOARD_SUBMITTED_CASES = process.env.REACT_APP_GRC_API + "dashboard/submitted-cases.get";
// Voices
export const API_URL_DELETE_VOICE_BY_ANALYST_ADMIN =
  AUTH_API_DOMAIN + "voice_report/delete_voice";

// AI
export const API_URL_SPEECH_CREATE = AI_DOMAIN + "voice-report/";
export const API_URL_SPEECH_UPLOAD_VOICE = AI_DOMAIN + "upload_file/";
export const API_URL_GET_VOICE_REPORT_DETAIL = AI_DOMAIN + "voice_reports/";
export const CM_API_URL_GET_VOICE_REPORT_DETAIL = AI_DOMAIN + "cm/voice_reports/";
export const API_URL_GET_SPEECH_TO_TEXT =
  AI_DOMAIN + "transcription/transcribe/";
export const API_URL_GET_VOICE_FILES = AI_DOMAIN + "voice-files/";
export const API_URL_SAVE_AI_TRANSCRIPTION =
  AUTH_API_DOMAIN + "voice_report/edit_transcription";
export const API_USER_UPDATE_MANUAL_TRANSCRIPTION =
  AUTH_API_DOMAIN + "voice_report/edit_manual_transcription";
export const API_DOWNLOAD_VOICE_FILES =
  AUTH_API_DOMAIN + "voice_report/download_voice";
export const API_URL_MY_TASKS =
  AUTH_API_DOMAIN + "task/my-tasks";


  // History logs
  export const API_URL_DOWNLOAD_LOGS = AUTH_API_DOMAIN + "logs/download";
  export const CM_API_URL_DOWNLOAD_LOGS = AUTH_API_DOMAIN + "cm/logs/download";
  export const API_URL_VOICE_CHANGE_MANUALTRANSCRIPT = (id) =>  AUTH_API_DOMAIN + `voice_report/${id}/change_transcript`

// Feature Flagging
export const MODULES_FEATURES_ROUTES = {
    wb  :   API_URL_FEATURE_FLAGGING,
    cm  :   API_URL_CM_FEATURE_FLAGGING,
    gd  :   ModuleDataProtectionSettings.getFeatureFlaggingUrl,
    tp  :   ModuleTPDDSettings.getFeatureFlaggingUrl
};

export const MODULES_SETTINGS_ROUTES = {
    wb  :   API_URL_VERIFY_ORGANIZATION,
    cm  :   CM_API_URL_VERIFY_ORGANIZATION,
    gd  :   ModuleDataProtectionSettings.getSettingsUrl,
    tp  :   ModuleTPDDSettings.getSettingsUrl
}

export const MODULES_SAVE_SETTINGS_ROUTES = {
    wb  :   API_URL_EDIT_INFO_ORGANIZATION,
    cm  :   CM_API_URL_EDIT_INFO_ORGANIZATION
}

export const MODULES_NOTIFICATIONS_ROUTES = {
    wb  :   {
        fetch           :   API_URL_NOTIFICATION_LIST,
        markAsRead      :   API_URL_NOTIFICATION_MARK_READ,
        markAllAsRead   :   API_URL_NOTIFICATION_MARK_ALL_READ,
        removeNotificationBaseUrl: AUTH_API_DOMAIN + "notification/"
    },
    cm  :   {
        fetch           :   CM_API_URL_NOTIFICATION_LIST,
        markAsRead      :   CM_API_URL_NOTIFICATION_MARK_READ,
        markAllAsRead   :   API_URL_NOTIFICATION_MARK_ALL_READ,
        removeNotificationBaseUrl: AUTH_API_DOMAIN + "notification/"
    },
    gd  :   {
        fetch           :   ModuleDataProtectionSettings.getNotificationsUrl,
        markAsRead      :   ModuleDataProtectionSettings.markNotificationAsReadUrl,
        markAllAsRead   :   ModuleDataProtectionSettings.markAllNotificationsAsReadUrl,
        removeNotificationBaseUrl   :   ModuleDataProtectionSettings.removeNotificationBaseUrl
    },
    tp  :   {
        fetch           :   ModuleTPDDSettings.getNotificationsUrl,
        markAsRead      :   ModuleTPDDSettings.markNotificationAsReadUrl,
        markAllAsRead   :   ModuleTPDDSettings.markAllNotificationsAsReadUrl,
        removeNotificationBaseUrl   :   ModuleTPDDSettings.removeNotificationBaseUrl
    }
}