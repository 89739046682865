import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";

const ManagementTeam = ({
    t,
    people
}) => {

    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('d')}) ${t('Management')}`}
            </Text>
        
            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Position')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Email')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Phone')}</Text>
                    </View>
                </View>

                {people.map((person, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= people.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {`${t(person.title)} ${person.name} ${person.lastName || ''}`}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.position}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.email || ''}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.phoneNumber || ''}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
};

export default withNamespaces()(ManagementTeam);

