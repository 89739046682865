import * as React from 'react'
import {
    HiChevronDoubleDown,
    HiChevronDoubleUp,
    HiMenuAlt4
} from 'react-icons/hi'

export const TasksStatuses = {
    NEW: {
        title: 'New',
        color: '#FFEE93'
    },
    IN_PROGRESS: {
        title: 'In Progress',
        color: '#8792E8'
    },
    REVIEW: {
        title: 'Review',
        color: '#85FF8A'
    },
    CLOSED: {
        title: 'Closed',
        color: '#EDECEC'
    },
    DELETED: {
        title: 'Deleted',
        color: '#FFDADA'
    }
}

export const TasksPriorities = {
    low: {
        title: 'Low',
        color: '#1CBB8C',
        icon: <HiChevronDoubleDown />
    },
    medium: {
        title: 'Medium',
        color: '#FCC34B',
        icon: <HiMenuAlt4 />
    },
    high: {
        title: 'High',
        color: '#DC3545',
        icon: <HiChevronDoubleUp />
    }
}

export const RisksRates = {
    low: {
        title: 'Low',
        color: '#1CBB8C'
    },
    medium: {
        title: 'Medium',
        color: '#FCC34B'
    },
    high: {
        title: 'High',
        color: '#DC3545'
    },

    insignificant: {
        title: 'Insignificant',
        color: '#4EB8B1'
    },
    minor: {
        title: 'Minor',
        color: '#59C94F'
    },
    noticeable: {
        title: 'Noticeable',
        color: '#FFA049'
    },
    critical: {
        title: 'Critical',
        color: '#FF4949'
    },
    'existence - threatening': {
        title: 'Existence - threatening',
        color: '#822626'
    }
}

export const TasksImpacts = {
    both: {
        title: 'Both'
    },
    likelihood: {
        title: 'Likelihood'
    },
    damage: {
        title: 'Damage'
    }
}

/**
 * The colors are the keys that api returns
 */
export const ResidualRisksRates = {
    "4EB8B1": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "59C94F": {
        title: "Minor",
        color: "#59C94F"
    },
    "FFA049": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "FF4949": {
        title: "Critical",
        color: "#FF4949"
    },
    "822626": {
        title: "Existence - threatening",
        color: "#822626"
    }
}
