import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import FlagOnIcon from "../../../../assets/images/flag_on.svg";
import FlagOffIcon from "../../../../assets/images/flag_off.svg";

import OwnThirdpartyUsersService from "../../../../apis/admin/OwnThirdpartyUsersService";

import SupplierService from "../../../../apis/common/SupplierService";

import SupplierExportService from "src/modules/3rd-party-management/apis/admin/SupplierExportService";

import { EMPTY_LIST } from "../../../../../../common/constants";

import CreateModal from '../../suppliers/new/components/AIHelpModal';

import { toast } from "react-toastify";

import Spinner from 'react-bootstrap/Spinner';

import { pdf } from "@react-pdf/renderer";

import SupplierDetailPDF from "../exports/Supplier.jsx";

import { saveAs } from "file-saver";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter
} from "reactstrap";

import DateUtils from "src/services/utils/DateUtils";

import Score from "src/modules/3rd-party-management/components/score";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import {
    API_BASE_URL,
    RisksRates,
    SupplierLatestReportsStatuses
} from "src/modules/3rd-party-management/constants";

import EditSupplierGeneralInfosModal from '../details/tabs-content/basic-information/components/edit-general-infos-modal';

import FilteringComponent from "./components/FilteringComponent";

import {
    SupplierStatuses
} from "src/modules/3rd-party-management/constants";

import axios from "axios";
import { hasUserAccessToEditSupplier } from "src/modules/3rd-party-management/helpers/users";
import UserUtils from "src/services/utils/UserUtils";

const OwnSuppliersList = (props) => {
    const userUtils = new UserUtils();

    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25,
        sortField: null,
        sortOrder: null
    });

    const [ tableResultTotalCount, setTableResultTotalCount ] = useState(0);

    const [usersList, setUsersList] = useState([]);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [ supplierIDToRemoveFlag, setSupplierIDToRemoveFlag ] = useState(null);
    const [ selectedSupplierToEditId, setSelectedSupplierToEditId ] = useState(null);
    const [ selectedSupplierToExport, setSelectedSupplierToExport ] = useState(null);
    const [ excelExportInprocess, setExcelExportInprocess ] = useState(false)

    const dateUtils = new DateUtils();

    const {
        actionsRef,
        user
    } = props;

    const tableColumns = [
        {
            dataField :   "highestRisk",
            text      :   props.t("Risk Rating"),
            sort      :   true,
            key       :   1,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent, row) => {
                return (
                    <i style={{
                        color: `${RisksRates[(cellContent || 'medium').toLowerCase()]?.color}`
                    }} className="ri-checkbox-blank-circle-fill font-size-20"></i>
                );
            }
        },
        // {
        //     dataField :   "score",
        //     text      :   props.t("DISS-CO Score"),
        //     sort      :   true,
        //     key       :   2,
        //     style     :   {
        //         width : '120px'
        //     },
        //     formatter : (cellContent, row) => {
        //         if(parseInt(row.score || 0) < 10){
        //             return (
        //                 <Score  
        //                     outOf={ 10 } 
        //                     value={ row.score } 
        //                     displayNumericValue={ true } 
        //                 />
        //             )
        //         }
                
        //         return null;
        //     }
        // },
        {
            dataField :   "secondaryId",
            text      :   props.t("ID"),
            sort      :   false,
            key       :   3,
            style     :   {
                width : '80px'
            },
            formatter : (_cellContent, row) => {
                return (
                    <Link 
                        className="text-dark" 
                        to={`/admin/3rd-party/suppliers/${row.id}/details?tab=overview`}
                    >
                        { row.secondaryId }
                    </Link>
                );
            }
        },
        {
            dataField :   "name",
            text      :   props.t("Company"),
            sort      :   true,
            key       :   4,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent, row) => {
                return (
                    <Link 
                        className="text-dark" 
                        to={`/admin/3rd-party/suppliers/${row.id}/details?tab=overview`}
                    >
                        { cellContent }
                    </Link>
                );
            }
        },
        {
            dataField :   "shortName",
            text      :   props.t("Short Name"),
            sort      :   false,
            key       :   5,
            style     :   {
                width : '120px'
            }
        },
        {
            dataField :   "country",
            text      :   props.t("Country"),
            sort      :   true,
            key       :   6,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return (
                    <span>{props.t(cellContent)}</span>
                );
            }
        },
        {
            dataField :   "updatedAt",
            text      :   props.t("Created on"),
            sort      :   true,
            key       :   7,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, row) => {
                return (
                    <div>
                        <span>
                            { dateUtils.convertDateToDate(row.createdAt, INTERNATIONAL_DATE_FORMAT) }
                        </span><br/>
                        <span className="text-muted">
                            {props.t('Updated on')}: { dateUtils.convertDateToDate(cellContent || row.createdAt, INTERNATIONAL_DATE_FORMAT) }
                        </span>
                    </div>
                )
            }
        },
        {
            dataField :   "contact_persons",
            text      :   props.t("Responsible Person"),
            sort      :   false,
            key       :   8,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                const persons = (cellContent && Array.isArray(cellContent)) ? cellContent : [];

                return persons.map((person) => {
                    return person.name
                }).join(', ');
            }
        },
        {
            dataField :   "lastReportStatus",
            text      :   props.t("Last Report Status"),
            sort      :   false,
            key       :   9,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent) => {
                return (
                    <div className='badge p-2 text-dark' style={{
                        backgroundColor: `${SupplierLatestReportsStatuses[cellContent]?.color}`
                    }}>
                        { props.t(SupplierLatestReportsStatuses[cellContent]?.title) }
                    </div>
                )
            }
        },
        {
            dataField :   "responsible_person",
            text      :   props.t("Assigned Analyst"),
            sort      :   false,
            key       :   10,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return cellContent?.name
            }
        },
        {
            dataField :   "status",
            text      :   props.t("Status"),
            sort      :   true,
            key       :   11,
            style     :   {
                width : '80px'
            },
            formatter : (cellContent) => {
                return renderUserStatus(cellContent);
            }
        },
        {
            dataField :   "flag",
            text      :   props.t('Flag'),
            sort      :   true,
            key       :   12,
            style     :   {
                width : '80px'
            },
            formatter : (cellContent, row) => {
                if(row.flag){
                    return (
                        <Button onClick={() => {
                            hasUserAccessToEditSupplier(user, row.responsible_person?.id) && 
                                setSupplierIDToRemoveFlag(row)
                        }} size="sm" outline className="border-0 toggle-supplier-flag-btn">
                            {
                                !!row.isLoading ? (
                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                ) : (
                                    <img alt="" src={ FlagOnIcon } />
                                )
                            }
                        </Button>
                    ); 
                }
                else{
                    return (
                        <Button onClick={() => {
                            hasUserAccessToEditSupplier(user, row.responsible_person?.id) && 
                                handleToggleSupplierFlagMutation.mutate(row)
                        }} size="sm" outline className="border-0 toggle-supplier-flag-btn">
                            {
                                !!row.isLoading ? (
                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                ) : (
                                    <img alt="" src={ FlagOffIcon } />
                                )
                            }
                        </Button>
                    ); 
                }
            }
        },
        {
            dataField :   "actions",
            text      :   props.t("Action"),
            sort      :   false,
            key       :   13,
            formatter : (cellContent, row) => {
                return renderUserActions(row);
            }
        }
    ];

    const handleFetchUsersQuery = useQuery({
		queryKey: [
            '3rd-party-management-list-own-suppliers', 
            (new URLSearchParams(
                Object.fromEntries(Object.entries(filters).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
		queryFn: async () => {
			const service = OwnThirdpartyUsersService.getInstance();

            return await service.list(filters);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(props.t('An error occurred while fetching companies list.'), {
				type: 'error',
			});
		},
	});

    const {
        refetch     :   fetchSupplierExportData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-own-supplier-export-data', selectedSupplierToExport?.id],
		queryFn: async () => {
			const service = SupplierExportService.getInstance();

            return await service.fetchExportData(selectedSupplierToExport.id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: () => {
			toast(props.t('An error occurred while fetching company data.'), {
				type: 'error',
			});
		},
        onSuccess: async (supplierExportData) => {
            const fileName = `company-${selectedSupplierToExport.secondaryId}.pdf`;

            const blob = await pdf( 
                <SupplierDetailPDF 
                    organization={props.organization} 
                    supplierData={supplierExportData}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setSelectedSupplierToExport(null);
        }
	});

    const handleToggleSupplierFlagMutation = useMutation({
        mutationFn: async (supplier) => {
            const service = SupplierService.getInstance();

            supplier.isLoading = true;

            return await service.toggleFlag(supplier.id);
        },
        onSuccess: ({
            flag    :   newFlag
        }, supplier) => {
            setSupplierIDToRemoveFlag(null);

            supplier.flag = newFlag;
            supplier.isLoading = false;

            toast(props.t("The flag status changed successfully"), {
                type: "success",
            });
        },
        onError: (data, supplier) => {
            supplier.isLoading = false;

            toast(props.t("An error occurred while toggling flag status."), {
                type: "error",
            });
        }
    });

    const NoDataIndication = () => (
        (handleFetchUsersQuery.isFetched && !usersList.length) ? <div className="alert m-0" role="alert">
            <p style={{
                    textAlign: "center",
                    marginBottom: 0
                }}>
                {props.t(EMPTY_LIST)}
            </p>
        </div> : <></>
    );

    const handleExportExcel = () => {
        setExcelExportInprocess(true)

        axios.get(`${API_BASE_URL}/organization/own_companies_excel`,
            {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
                onDownloadProgress: (e) => {
                    let percentCompleted = Math.round(e.loaded * 100 / 
                        e.total);

                    if(percentCompleted >= 100){
                        setExcelExportInprocess(false)
                    }
                },
                params: filters
            }
        ).then((e) => {
            const url = window.URL.createObjectURL(
                new Blob([e.data], { type: "*" })
            );

            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `own-companies.xlsx`);

            document.body.appendChild(link);

            link.click();
            link.remove();
        }).catch(() => {
            toast(props.t("An error occurred while exporting as excel."), {
                type: "error",
            });

            setExcelExportInprocess(false)
        })
    }

    useEffect(() => {
        if(handleFetchUsersQuery.data){
            setUsersList(handleFetchUsersQuery?.data?.thirdParties || []);

            setTableResultTotalCount( handleFetchUsersQuery.data.itemsCount || 0);
        }
    }, [handleFetchUsersQuery.data]);

    useEffect(() => {
        if(selectedSupplierToExport && selectedSupplierToExport.id){
            fetchSupplierExportData()
        }
    }, [selectedSupplierToExport]);

    useEffect(() => {
        if(actionsRef && actionsRef.current){
            actionsRef.current = {
                reloadList : () => {
                    handleFetchUsersQuery.refetch();
                }
            };
        }
    }, []);

    const renderUserStatus = (status) => {
        const statusInfos = SupplierStatuses[status];

        return (
            <div className={`badge p-2 ${statusInfos?.color}`}>
                { props.t(statusInfos?.title || 'Unkown') }
            </div>
        );
    }

    const renderUserActions = (item) => {
        return (
            <div className="actions">
                {hasUserAccessToEditSupplier(user, item.responsible_person?.id) && (
                    <button onClick={() => {
                        setSelectedSupplierToEditId(item.id)
                    }} className="btn btn-small text-primary">
                        <i className="ri-pencil-line"></i>
                    </button>
                )}

                <button className="btn btn-small text-primary" onClick={() => {
                    setSelectedSupplierToExport(item)
                }}>
                    <i className="ri-download-2-line"></i>
                </button>
            </div>
        )
    }

    const handleTableChange = (type, options) => {
        const {
            page,
            sizePerPage,

            sortField,
            sortOrder
        } = options;

        switch(type){
            case 'pagination':
                    setFilters({
                        ...filters,
                        pageIndex   :   page,
                        pageSize    :   sizePerPage
                    });
                break;
            case 'sort':
                setFilters({
                        ...filters,
                        sortField   :   sortField,
                        sortOrder   :   sortOrder.toUpperCase()
                    })
                break;
            default:
                return false;
        }
    }

    const renderModals = () => {
        const { t } = props;

        const toggleRemoveFlagConfirmModal = () => setSupplierIDToRemoveFlag(null);

        const mutationInProcess = handleToggleSupplierFlagMutation.isFetching || handleToggleSupplierFlagMutation.isLoading;

        return (
            <>
                <CreateModal 
                    modal={showCreateModal}
                    setModal={setShowCreateModal}
                    onComplete={() => {
                        setShowCreateModal(false);
                    }}
                />

                <Modal isOpen={ !!supplierIDToRemoveFlag } toggle={ toggleRemoveFlagConfirmModal }>
                    <ModalHeader toggle={ toggleRemoveFlagConfirmModal }>
                        { t('You are removing flag') }
                    </ModalHeader>
                    
                    <ModalBody>
                        {
                            `${t('Do you like to remove this company from the monitoring system')}?`
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={ mutationInProcess } onClick={ () => setSupplierIDToRemoveFlag(null) } color="secondary" outline>
                            {t('No')}
                        </Button>

                        <Button disabled={ mutationInProcess } onClick={ () => handleToggleSupplierFlagMutation.mutate(supplierIDToRemoveFlag) } color="primary">
                            {
                                mutationInProcess ? (
                                    <>
                                        <Spinner animation="border" variant="white" size="sm" className="me-1"/>
                                        {`${t('Updating')}...`}
                                    </>
                                ) : (
                                    <>{t('Yes')}</>
                                )
                            }
                        </Button>
                    </ModalFooter>
                </Modal>

                <EditSupplierGeneralInfosModal 
                    closeModal={() => setSelectedSupplierToEditId(null)}
                    supplierId={selectedSupplierToEditId}
                    onComplete={() => handleFetchUsersQuery.refetch()}
                />

                <Modal isOpen={ !!selectedSupplierToExport } toggle={ () => setSelectedSupplierToExport(null) }>
                    <ModalHeader toggle={ () => setSelectedSupplierToExport(null) }>
                        <div>
                            <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                            {t('Company due diligence report')}
                        </div>
                    </ModalHeader>
                    
                    <ModalBody>
                        {t('Exporting as PDF')}...
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ () => setSelectedSupplierToExport(null) } color="secondary" outline>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    return (
        <div className="list-suppliers p-4">
            <div className="d-flex justify-content-end mb-3 mt-3">
                <FilteringComponent 
                    usedIn="own"
                    onChange={(newFilters) => {
                        setFilters({
                            ...filters,
                            ...newFilters,
                            pageIndex: 1
                        });
                    }}
                />

                {userUtils.isAnalystAdmin(user) && (
                    <Link className='btn btn-primary btn-outline me-2' to={"/admin/3rd-party/suppliers/create?type=own"}>
                        <i className="ri-add-line align-middle me-2" />
                        {props.t("Add Own Company")}
                    </Link>
                )}

                <Button color="primary" outline className="me-2" disabled={excelExportInprocess} onClick={handleExportExcel}>
                    {
                        excelExportInprocess ? (
                        <>
                            <Spinner animation="border" variant="secondary" size="sm"/>{' '}
                            { props.t("Exporting") }...
                        </>
                        ) : (
                            <>
                                <i className="ri-file-excel-2-line align-middle me-2" />
                                { props.t("Export Excel") }
                            </>
                        )
                    }
                </Button>
            </div>

            <PaginationProvider
                pagination={
                    paginationFactory({
                        custom              :   true,
                        page                :   filters.pageIndex,
                        sizePerPage         :   filters.pageSize,
                        totalSize           :   tableResultTotalCount,
                        withFirstAndLast    :   true,
                        alwaysShowAllBtns   :   true,
                        prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {props.t('Back')}</span>,
                        nextPageText        :   <span>{props.t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                        prePageTitle        :   props.t('Pre page'),
                        firstPageTitle      :   props.t('First page'),
                        lastPageTitle       :   props.t('Last page'),
                        showTotal           :   false,
                        paginationTotalRenderer :   (from, to, size) => {
                            return (
                                <span className="react-bootstrap-table-pagination-total">
                                    {`${props.t('Total Items')} ${size}`}
                                </span>
                            )
                        },
                        hideSizePerPage     :   false,
                        sizePerPageList     :   [
                            {
                                text: '25', 
                                value: 25
                            }, 
                            {
                                text: '50', 
                                value: 50
                            }
                        ]
                    })
                }>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <React.Fragment>
                            <Row>
                                <Col sm="12">
                                    <BootstrapTable
                                        remote={{
                                            pagination: true,
                                            filter: false, 
                                            sort: true,
                                            cellEdit: false,
                                            search: false
                                        }}
                                        loading={ handleFetchUsersQuery.isFetching || handleFetchUsersQuery.isLoading }
                                        overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : `${props.t("Loading")}...` }) }
                                        onTableChange={ handleTableChange }
                                        defaultSorted={[]}
                                        keyField={"id"}
                                        responsive
                                        bordered={ false }
                                        data={ usersList }
                                        striped={true}
                                        columns={tableColumns}
                                        wrapperClasses="table-responsive"
                                        classes={"table tpdd-table"}
                                        headerWrapperClasses={"thead-light"}
                                        style={{
                                            overflowX: "auto",
                                        }}
                                        noDataIndication={ () => <NoDataIndication /> }
                                        { ...paginationTableProps }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <div className="tpdd-pagination-style-1">
                                        <PaginationTotalStandalone
                                            { ...paginationProps }
                                        />
                                        
                                        <div>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                            
                                            <SizePerPageDropdownStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }

            </PaginationProvider>

            { renderModals() }
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user : state.Login.user,
      token,
      organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(OwnSuppliersList)))
);