import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class SupplierOverviewService {
	static instance;

	static getInstance() {
		if (!SupplierOverviewService.instance) {
			SupplierOverviewService.instance = new SupplierOverviewService();
		}

		return SupplierOverviewService.instance;
	}

    async fetchStatistics(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/overview/statistics`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || {};
    }   

    async fetchLastActivities(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/overview/last_activities`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || [];
    }   

    async fetchLatestFindings(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/overview/last_findings`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data?.data || [];
    }   
}
