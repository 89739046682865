import axios from 'axios';

import {
  API_BASE_URL
} from '../../constants/index';

export default class SupplierInternalCommunicationService {
	static instance;

	static getInstance() {
		if (!SupplierInternalCommunicationService.instance) {
			SupplierInternalCommunicationService.instance = new SupplierInternalCommunicationService();
		}

		return SupplierInternalCommunicationService.instance;
	}

  async mentionCommentSearch(supplierId, params = {}){
    const response = await axios.get(
        `${API_BASE_URL}/supplier/${supplierId}/mentionCommentSearch`, 
        {
            params  :   params,
            headers :   {
              Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`
            }
        }
    );

    return response.data?.data || [];
  }
}
