const roles = {
  tp: [
    { value: "ROLE_ANALYST", label: "Analyst"},
    { value: "ROLE_ANALYST_ADMIN", label: "Analyst Admin" },
    { value: "ROLE_THIRD_PARTY", label: "Contact Person" },
    
  ],
  gd: [
    { value: "ROLE_ANALYST", label: "Analyst" },
    { value: "ROLE_ANALYST_ADMIN", label: "Analyst Admin" },
    { value: "ROLE_EMPLOYEE", label: "Employee" },
    { value: "ROLE_TASK_OWNER", label: "Task Owner" },
  ],
  cm: [
    { value: "ROLE_ANALYST", label: "Analyst" },
    { value: "ROLE_ANALYST_ADMIN", label: "Analyst Admin" },
    { value: "ROLE_EMPLOYEE", label: "Employee" },
    { value: "ROLE_TASK_OWNER", label: "Task Owner" },
  ],
  wb: [
    { value: "ROLE_ANALYST", label: "Analyst" },
    { value: "ROLE_ANALYST_ADMIN", label: "Analyst Admin" },
    { value: "ROLE_EMPLOYEE", label: "Employee" },
    { value: "ROLE_TASK_OWNER", label: "Task Owner" },
  ]
}
export const RoleOptions = (module)=> {
    return roles[module];
}

