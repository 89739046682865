import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import CustomizationService from "src/modules/3rd-party-management/apis/admin/CustomizationService";
import {
  ContentState,
  convertToRaw,
  EditorState,
} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { TbLoader } from "react-icons/tb";
import draftToHtml from "draftjs-to-html";

const IntroModal = ({
  t,
  modal,
  setModal,
  translation,
  fetchOrganizationDetails,
  closeModal,
}) => {
  const toggle = () => closeModal();
  const [pageTitle, setPageTitle] = useState(translation?.title);
  const [mainTranslation, setMainTranslation] = useState(null);

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      if (!mainTranslation || !mainTranslation.editorState) {
        throw new Error("EditorState is not defined");
      }

      const rawContentState = convertToRaw(
        mainTranslation.editorState.getCurrentContent()
      );
      const introHtml = draftToHtml(rawContentState);
      const payload = {
        title: pageTitle,
        intro: introHtml,
        language: translation.language_code,
      };

      const service = CustomizationService.getInstance();
      return await service.editTranslation(payload);
    },
    onSuccess: () => {
      fetchOrganizationDetails.refetch();
      closeModal();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    setPageTitle(translation?.title);
    resetTranslation();
  };

  // On Editor State Change
  const handleEditorStateChange = (editorState) => {
    setMainTranslation((currentTrans) => ({
      ...currentTrans,
      editorState,
    }));
  };

  //   Reset Translation
  const resetTranslation = () => {
    let contentState;
    const { intro } = translation;
    if (intro) {
        contentState = ContentState.createFromBlockArray(htmlToDraft(intro).contentBlocks)
    } else {
      contentState = ContentState.createFromText("");
    }

    setMainTranslation({
      ...mainTranslation,
      editorState: EditorState.createWithContent(contentState),
    });
  };

  /****************************************** USE EFFECTS *************************************** */
  useEffect(() => {
    if (translation) {
      resetTranslation();
    }
  }, [translation]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <ModalHeader className="border-0" toggle={toggle}>
          {t("Translations")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="customization-accordion-col">
              <h6 className="mb-3">
                {t("Smart Integrity Platform - Reporting Channel Landing Page")}
              </h6>
              <p>
                {t(
                  "This will appear on your landing page. Provide any information you would like for internal and external parties to understand about reporting on the Smart Integrity Platform."
                )}
              </p>
              <Row className="mt-5">
                <h4>{t("Translation") + ": " + t(translation?.language_name)}</h4>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="12" className="mt-3 mb-3">
                  <FormGroup>
                    <Label for="pageTitle">{t("Page Title") + ":"}</Label>
                    <Input
                      name="pageTitle"
                      type="text"
                      disabled={
                        handleSubmitMutation.isLoading ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                      value={pageTitle}
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label for="introText">{t("Intro Text") + ":"}</Label>
                  {mainTranslation && (
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      editorState={mainTranslation.editorState}
                      onEditorStateChange={handleEditorStateChange}
                      stripPastedStyles={true}
                    />
                  )}
                </Col>
              </Row>

              <Row className="p-4">
                <Col sm="12" className="d-flex justify-content-end">
                  <Button
                    onClick={restore}
                    color="primary"
                    type="button"
                    outline
                    className="me-2"
                    disabled={
                      handleSubmitMutation.isLoading ||
                      fetchOrganizationDetails.isLoading ||
                      fetchOrganizationDetails.isFetching
                    }
                  >
                    {t("Restore")}
                  </Button>

                  <Button
                    color="primary"
                    onClick={handleSubmitMutation.mutate}
                    type="submit"
                    disabled={
                      handleSubmitMutation.isLoading ||
                      fetchOrganizationDetails.isLoading ||
                      fetchOrganizationDetails.isFetching
                    }
                  >
                    {handleSubmitMutation.isLoading ? <TbLoader /> : t("Save")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(IntroModal)))
);
