import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import AIGetHelpIcon from "src/modules/3rd-party-management/assets/images/microchip/white.png";
import AIBotty from "src/modules/3rd-party-management/assets/images/bottyAi-2.png";

import { Button, Col, Row, Progress } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import FindingsService from "src/modules/3rd-party-management/apis/admin/FindingsService";
import { toast } from "react-toastify";

import ModerateIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Moderate.svg";
import WeakIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Weak.svg";
import StrongIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Strong.svg";

const SanctionDetailBody = ({
    finding,
    t,
    setAiBasedRiskAssessmentModalStatus
}) => {
    const [ aiScoreClass, setAiScoreClass ] = useState(null);

    const handleFetchFindingScoreQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-finding-ai-score-query'],
		queryFn: async () => {
			const service = FindingsService.getInstance();

            return await service.getAIScore({
                promt : `${finding.news_agency || ''}, ${finding.about_url || ''}`
            });
		},
		cacheTime: 0,
        enabled: false,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching ai score.'), {
				type: 'error',
			});
		},
	});

    const renderAiScoreClassImage = () => {
        let resultImage;

        const scoreClass = (aiScoreClass || '').toString().toLowerCase();

        if(scoreClass === 'class a'){
            resultImage = WeakIcon;
        }
        else if(scoreClass === 'class b'){
            resultImage = ModerateIcon;
        }
        else{
            resultImage = StrongIcon;
        }

        return (
            <img src={ resultImage } alt="" />
        );
    }

    const renderAiScoreClassBox = () => {
        if(handleFetchFindingScoreQuery.isLoading || handleFetchFindingScoreQuery.isFetching || !aiScoreClass){
            return (
                <Col sm="12">
                    <div className="dt-field dt-skeleton w-100" style={{height: '220px'}}></div>    
                </Col>
            );
        }
        
        return (
            <div className="side-box diss-co-score">
                <div className="scoring">
                    { renderAiScoreClassImage() }
                </div>
            </div>
        );
    }

    useEffect(() => {
        if(finding){
            handleFetchFindingScoreQuery.refetch();
        }
    }, [finding]);

    useEffect(() => {
        if(handleFetchFindingScoreQuery.data && handleFetchFindingScoreQuery.data.out){
            setAiScoreClass(handleFetchFindingScoreQuery.data.out);
        }
    }, [ handleFetchFindingScoreQuery.data ]);

    const displayRelevancyScore = finding && ('relevancyScore' in finding);

    const relevancyScore = parseInt(finding?.relevancyScore);

    const sanctionData = JSON.parse(finding.body);

    return (
        <Row className="d-flex flex-lg-row-reverse">
            <Col sm="12" md="12" lg="4" xl="3" className="d-none d-lg-block">
                <Row>
                    <Col sm="6" md="6" lg="12">
                        <div className="side-box ai-help-box mb-4">
                            <h5>
                                { t('Risk Assessment Support by AI') }
                            </h5>

                            <div className="image-box">
                                <img src={AIBotty} alt="Risk Assessment Support by AI" />
                            </div>

                            <Button onClick={() => {
                                setAiBasedRiskAssessmentModalStatus(true)
                            }} className="d-flex justify-content-center w-100" color="primary">
                                <img className="me-2" src={AIGetHelpIcon} alt="" />
                                { t('Get Help From AI') }
                            </Button>
                        </div>
                    </Col>
                    
                    {displayRelevancyScore && (
                        <Col sm="6" md="6" lg="12">
                            <div className="side-box relevancy-score mb-4">
                                <h5>
                                    { t('Relevancy Score') }
                                </h5>

                                <div className="score-progress-container mt-2 w-100">
                                    <Progress className={relevancyScore < 40 ? 'danger' : (relevancyScore <= 69 ? 'warning' : 'success') } value={relevancyScore} />
                                    <span className="percent">{relevancyScore}%</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    
                    <Col sm="6" md="6" lg="12">
                        { renderAiScoreClassBox() }
                    </Col>
                </Row>
            </Col>

            <Col sm="12" md="12" lg="8" xl="9">
                <div className="finding-content">
                    <div className="alert alert-info">
                        <p className="mb-0">
                            {t('Attention: the Hit can be a false positive as there are many similar names, sepcially in arabic speaking Region')}
                        </p>
                    </div>

                    <div className="sanction-table-container">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td colSpan='2'>
                                        <div className="finding-content-title mb-3">
                                            <h5>
                                                {(finding.title)}
                                            </h5>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Description')}</th>
                                    <td>
                                        { (sanctionData.description || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Last Name')}</th>
                                    <td>
                                        { (sanctionData.lastName || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Given Names')}</th>
                                    <td>
                                        { (sanctionData.givenName || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Spouse')}</th>
                                    <td>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Parents')}</th>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Siblings')}</th>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Birthdate')}</th>
                                    <td>
                                        { (sanctionData.birthDate || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Birthplace')}</th>
                                    <td>
                                        { (sanctionData.birthPlace || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Citizenship')}</th>
                                    <td>
                                        { (sanctionData.citizenship || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Occupations')}</th>
                                    <td>
                                        { (sanctionData.occupations || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Positions')}</th>
                                    <td>
                                        { (sanctionData.positions || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Political Parties')}</th>
                                    <td>
                                        { (sanctionData.politicalParties || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Links')}</th>
                                    <td>
                                        { (sanctionData.links || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Source Country')}</th>
                                    <td>
                                        { (sanctionData.sourceCountry || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Source Description')}</th>
                                    <td>
                                        { (sanctionData.sourceDescription || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Source Website')}</th>
                                    <td>
                                        { (sanctionData.sourceWebsite || []).map((i) => <p>{i}</p>) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Sanctions')}</th>
                                    <td>
                                        { (sanctionData.sanctions || []).map((san) => {
                                            return (san.sanctionDetail || []).map((i) => <p>{i}</p>)
                                        }) }
                                    </td>
                                </tr>   
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default withNamespaces()(
    SanctionDetailBody
);