import { withNamespaces } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../Styles";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import { TasksPriorities, TasksStatuses } from "src/modules/3rd-party-management/constants";

const Tasks = ({
    t,
    tasks
}) => {
    const dateUtils = new DateUtils();

    return (
        <View break>
            <View style={styles.sectionSeparator}>
                <Text>{t('TASKS')}</Text>
            </View>
        
            <View style={styles.section} >
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('#')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Title')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Created On')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Deadline')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Task Manager')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Task Owner')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Priority')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Status')}</Text>
                        </View>
                    </View>

                    {tasks.map((task, i) => {
                        const rowStyle = {
                            ...styles.tableRow,
                            ...(((i + 1) >= tasks.length) && {...styles.tableLastRow})
                        }

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        T{task.secondary_id}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.manager}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.analyst?.full_name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {t(TasksPriorities[task.priority]?.title)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {t(TasksStatuses[task.status]?.title)}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
};

export default withNamespaces()(Tasks);

