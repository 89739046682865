import { useCallback, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { 
    Button, 
    Card, 
    CardHeader, 
    Col, 
    Container, 
    Label, 
    ListGroup, 
    ListGroupItem, 
    Row 
} from 'reactstrap';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML } from 'draft-js';
import { TbLoader } from 'react-icons/tb';

import {
	DESCRIPTION_NOT_UPDATED_SUCCESSFULLY,
	DESCRIPTION_UPDATED_SUCCESSFULLY,
	UPDATE_DAMAGE_SUCCESSFUL,
	UPDATE_DAMAGE_FAILED,
	UPDATE_LIKELIHOOD_SUCCESSFUL,
	UPDATE_LIKELIHOOD_FAILED,
	UPDATE_CATEGORY_SUCCESSFUL,
	UPDATE_CATEGORY_FAILED,
	FETCH_RISK_FAILED,
	UPDATE_OWNER_SUCCESSFUL,
	UPDATE_OWNER_FAILED,
	FETCH_COMMENTS_FAILED,
	SUBCATEGORY_IS_REQUIRED,
	CATEGORY_IS_REQUIRED,
	DESCRIPTION_IS_REQUIRED,

    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import {
	API_BASE_URL,
    ResidualRisksRates
} from 'src/modules/3rd-party-management/constants';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { HiChevronDoubleDown, HiChevronDoubleUp, HiMenuAlt4 } from 'react-icons/hi';
import Select from 'react-select';
import axios from 'axios';
import DateUtils from 'src/services/utils/DateUtils';
import { toast } from "react-toastify";

import DownloadAttachments from '../../attachments';
import CreateTaskModal from '../../tasks/new';
import CommentsComponent from '../../comments'
import { useQuery } from '@tanstack/react-query';

import SupplierRiskAssessmentService from "src/modules/3rd-party-management/apis/admin/SupplierRiskAssessmentService";
import { isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { hasUserAccessToEditSupplier } from 'src/modules/3rd-party-management/helpers/users';

const RiskDetails = function ({
    t,
    user,
    token,
    
    supplierInfos,

    supplierId,
    riskId,

    taskCreationSettings = {
        enabled         :   false,
        creationPayload :   {}
    },

    onMitigationTaskClicked,
    onRiskDataFetched
}) {
	const dateUtils = new DateUtils();

	const [createTaskModalStatus, setCreateTaskModalStatus] = useState(false);

	const [ risk, setRisk ] = useState(null);
	const [ comments, setComments ] = useState(null);

    const [ owners, setOwners ] = useState([]);
    const [ categories, setCategories]= useState([]);
    const [ locations, setLocations ] = useState([]);
    const [ damages, setDamages ] = useState([]);
    const [ likelihoods, setLikelihoods ] = useState([]);
    const [ currency, setCurrency ] = useState(null);
    const [ tableColors, setTableColors ] = useState(null);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedCategoryOtherString, setSelectedCategoryOtherString] = useState(null);
	const [categoryOtherError, setCategoryOtherError] = useState(null);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] = useState(null);
	const [subCategoryOtherError, setSubCategoryOtherError] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedDamage, setSelectedDamage] = useState(null);
	const [selectedLikelihood, setSelectedLikelihood] = useState(null);
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [descriptionError, setDescriptionError] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);

	const [loadingCategory, setLoadingCategory] = useState(false);
	const [loadingDamage, setLoadingDamage] = useState(false);
	const [loadingLikelihood, setLoadingLikelihood] = useState(false);
	const [loadingOwner, setLoadingOwner] = useState(false);
	const [loadingDescription, setLoadingDescription] = useState(false);
	const [loadingComments, setLoadingComments] = useState(false);

    const handleFetchRisksCategories = useQuery({
		queryKey: ['fetch-risks-categories'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchCategories();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching categories.'), {
				type: 'error',
			});
		},
	});

    const handleFetchRisksLocations = useQuery({
		queryKey: ['fetch-risks-locations'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchLocations();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching locations.'), {
				type: 'error',
			});
		},
	});

    const handleFetchRisksMatrix = useQuery({
		queryKey: ['fetch-supplier-risks-matrix', supplierId],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchMatrix(supplierId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching matrix.'), {
				type: 'error',
			});
		},
	});

    const handleFetchRisksOwners = useQuery({
		queryKey: ['fetch-risks-owners'],
		queryFn: async () => {
			const service = SupplierRiskAssessmentService.getInstance();

            return await service.fetchOwners();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching owners.'), {
				type: 'error',
			});
		},
	});

	const handleChangeCategory = (category) => {
		if (category.baseLabel.toLowerCase() !== 'other') {
			setSelectedCategoryOtherString('');
			setSelectedSubCategoryOtherString('');
		}

		setSelectedCategory(category);

		if (category.subCategories?.length === 1) {
			handleChangeSubCategory(category.subCategories[0]);
		} 
        else {
			handleChangeSubCategory(null);
		}
	};

	const handleChangeSubCategory = (subCategory) => {
		if (subCategory && subCategory.baseLabel.toLowerCase() !== 'other') {
			setSelectedSubCategoryOtherString(null);
		}

		setSelectedSubCategory(subCategory);
	};

	const handleChangeOtherCategoryString = (value) => {
		setSelectedCategoryOtherString(value);
	};

	const handleChangeOtherSubCategoryString = (value) => {
		setSelectedSubCategoryOtherString(value);
	};

	const handleSubmitChangeCategory = async () => {
		try {
			if (
				(selectedCategory.baseLabel.toLowerCase() === 'other' && !selectedCategoryOtherString) ||
				(selectedSubCategory.baseLabel.toLowerCase() === 'other' && !selectedSubCategoryOtherString)
			) {
				if (!selectedCategoryOtherString) setCategoryOtherError(t(CATEGORY_IS_REQUIRED));
				if (!selectedSubCategoryOtherString) setSubCategoryOtherError(t(SUBCATEGORY_IS_REQUIRED));

				return;
			} else {
				setCategoryOtherError(null);
				setSubCategoryOtherError(null);
			}

			setLoadingCategory(true);

			const data = {
				category:
					selectedCategory.baseLabel.toLowerCase() === 'other' ? null : selectedSubCategory.baseLabel.toLowerCase() === 'other' ? selectedCategory.value : selectedSubCategory.value,
				otherCategory: selectedCategoryOtherString ? selectedCategoryOtherString : null,
				otherSubCategory: selectedSubCategoryOtherString ? selectedSubCategoryOtherString : null,
			};

			const result = await axios.post(`${API_BASE_URL}/risk_rate/${riskId}/change_category`,
				data,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
                toast(t(UPDATE_CATEGORY_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_CATEGORY_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_CATEGORY_FAILED), {
                type: 'error',
            });
		}

		setLoadingCategory(false);
	};

	const handleChangeRiskDamage = async (damage) => {
		try {
			setLoadingDamage(true);

			const result = await axios.post(`${API_BASE_URL}/risk_rate/${riskId}/update_damage`,
				{ damage: damage.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				
                setRisk({
                    ...risk,
                    damage  :   {
                        id      :   damage.value
                    }
                });

                toast(t(UPDATE_DAMAGE_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_DAMAGE_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_DAMAGE_FAILED), {
                type: 'error',
            });
		}

		setLoadingDamage(false);
	};

	const handleChangeRiskLikelihood = async (likelihood) => {
		try {
			setLoadingLikelihood(true);
	
			const result = await axios.post(`${API_BASE_URL}/risk_rate/${riskId}/update_likelihood`,
				{ likelihood: likelihood.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					}
				},
			);

			if (result.status === 200) {
                setRisk({
                    ...risk,
                    likelihood  :   {
                        id      :   likelihood.value
                    }
                });

                toast(t(UPDATE_LIKELIHOOD_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_LIKELIHOOD_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_LIKELIHOOD_FAILED), {
                type: 'error',
            });
		}

		setLoadingLikelihood(false);
	};

	const handleFetchRisk = useCallback(
		async () => {
			try {
				const result = await axios.get(`${API_BASE_URL}/risk_rate/${riskId}/show`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						}
					},
				);

				if (result.status === 200) {
					const newRisk = result.data.data;
					newRisk.legal_entities = newRisk?.legal_entities.map(entity => ({
						value: entity.id,
						label: entity.title
					}))
					
					setRisk(newRisk);
                    onRiskDataFetched && onRiskDataFetched(newRisk);
				} 
                else {
                    toast(t(FETCH_RISK_FAILED), {
                        type: 'error',
                    });
				}
			} catch (error) {
                toast(t(FETCH_RISK_FAILED), {
                    type: 'error',
                });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const handleChangeRiskOwner = async (owner) => {
		try {
			setLoadingOwner(true);

			const result = await axios.post(`${API_BASE_URL}/risk_rate/${riskId}/assign_analyst`,
				{ owner: owner.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
                setRisk({
                    ...risk,
                    owner_object: {
                        id: owner.value,
                        name: owner.label
                    }
                });

                toast(t(UPDATE_OWNER_SUCCESSFUL), {
                    type: 'success',
                });
			} 
            else {
                toast(t(UPDATE_OWNER_FAILED), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(UPDATE_OWNER_FAILED), {
                type: 'error',
            });
		}

		setLoadingOwner(false);
	};

	const handleDescriptionSubmit = async () => {
		try {
			if (!description.getCurrentContent().hasText()) {
				setDescriptionError(DESCRIPTION_IS_REQUIRED);
				return;
			} 
            else {
				setDescriptionError(null);
			}

			setLoadingDescription(true);

			const result = await axios.post(`${API_BASE_URL}/risk_rate/${riskId}/edit`,
				{
					description             :   convertToHTML(description.getCurrentContent()),
					descriptionAttachments  :   [],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
                toast(t(DESCRIPTION_UPDATED_SUCCESSFULLY), {
                    type: 'success',
                });
			} 
            else {
                toast(t(DESCRIPTION_NOT_UPDATED_SUCCESSFULLY), {
                    type: 'error',
                });
			}
		} 
        catch (error) {
            toast(t(DESCRIPTION_NOT_UPDATED_SUCCESSFULLY), {
                type: 'error',
            });
		}

		setLoadingDescription(false);
	};

	const handleFetchComments = useCallback(
		async () => {
			setComments(null);
			setLoadingComments(true);

			try {
				const result = await axios.get(`${API_BASE_URL}/risk_rate/${riskId}/comments`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						}
					},
				);

				if (result.status === 200) {
					setComments(result.data.data);
				} 
                else {
                    toast(t(FETCH_COMMENTS_FAILED), {
                        type: 'error',
                    });
				}
			} 
            catch (error) {
                toast(t(FETCH_COMMENTS_FAILED), {
                    type: 'error',
                });
			}

			setLoadingComments(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const handleUpdateAttachmentName = (name, id) => {
		const index = risk.description_attachments.findIndex((i) => i.id === id);

		if (index > -1) {
			const description_attachments = Array.from(risk.description_attachments);
			description_attachments[index].origin_name = name;

			setRisk({
				...risk,
				description_attachments,
			});
		}
	};

    const findAndUpdateRiskLocation = () => {
        if(!locations.length) return;

        const location = locations.find((l) => l.value === risk?.location?.id);
        setSelectedLocation(location);
    }

    const findAndUpdateRiskCategory = () => {
        if(!categories.length) return ;

        if (risk.parent_category?.is_other_category) {
            const category = categories.find((c) => c.baseLabel.toLowerCase() === 'other');
            setSelectedCategory({
                value: category.value,
                baseLabel: category.baseLabel,
                label: t(category.baseLabel),
                subCategories: category.subCategories,
            });
            setSelectedCategoryOtherString(risk.parent_category.name);
            setSelectedSubCategory({
                value: category.subCategories[0]?.value,
                baseLabel: category.subCategories[0]?.baseLabel,
                label: t(category.subCategories[0]?.baseLabel),
            });
            setSelectedSubCategoryOtherString(risk.category.name);
        } 
        else {
            const category = categories.find((c) => c.value === risk.parent_category?.id);
            setSelectedCategory({
                value: category?.value,
                baseLabel: category?.baseLabel,
                label: t(category?.baseLabel),
                subCategories: category?.subCategories,
            });

            setSelectedCategoryOtherString('');

            if (risk.category?.is_other_category) {
                const subCategory = (category?.subCategories || []).find((c) => c.baseLabel.toLowerCase() === 'other');
                setSelectedSubCategory({
                    value: subCategory?.value,
                    baseLabel: subCategory?.baseLabel,
                    label: t(subCategory?.baseLabel),
                });
                setSelectedSubCategoryOtherString(risk.category?.name);
            } else {
                const subCategory = category?.subCategories.find((c) => c.value === risk.category?.id);
                setSelectedSubCategory({
                    value: subCategory?.value,
                    baseLabel: subCategory?.baseLabel,
                    label: t(subCategory?.baseLabel),
                });
                setSelectedCategoryOtherString('');
            }
        }
    }

    const findAndUpdateRiskDamage = () => {
        if(!damages.length) return;

        const damage = damages.find((d) => d.value === risk?.damage?.id);
        setSelectedDamage(damage);
    }

    const findAndUpdateRiskLikelihood = () => {
        if(!likelihoods.length) return;

        const likelihood = likelihoods.find((l) => l.value === risk?.likelihood?.id);

        if(likelihood) {
            setSelectedLikelihood({
                value   :   likelihood?.value,
                label   :   t(likelihood?.label),
            });
        }
    }

    const findAndUpdateRiskOwner = () => {
        if(!owners.length) return;
        const owner = owners.find((o) => o.value === risk?.owner_object?.id);
		setSelectedOwner(owner);
    }

    useEffect(() => {
        if(handleFetchRisksCategories.data){
            setCategories(handleFetchRisksCategories.data.map((item) => {
                return {
                    value           :   item.id,
                    label           :   t(item.name),
                    baseLabel       :   item.name,
                    subCategories   :   item.sub_categories.map((sub) => {
                        return {
                            value       :   sub.id,
                            baseLabel   :   sub.name,
                            label       :   t(sub.name),
                        };
                    }),
                };
            }));
        }
    }, [handleFetchRisksCategories.data]);

    useEffect(() => {
        if(handleFetchRisksLocations.data){
            setLocations(handleFetchRisksLocations.data.map((item) => {
                return {
                    value       :   item.id,
                    baseLabel   :   item.name,
                    label       :   t(item.name),
                };
            }));
        }
    }, [handleFetchRisksLocations.data]);

    useEffect(() => {
        if(handleFetchRisksMatrix.data){
            setLikelihoods(
                (handleFetchRisksMatrix.data?.likelihoods || []).map((l) => {
                    return {
                        value   :   l.id,
                        label   :   l.title
                    }
                }) 
            );
            setCurrency(handleFetchRisksMatrix.data.currency);
            setDamages(
                handleFetchRisksMatrix.data.consequences.map((item) => {
                    return {
                        value   : item.id,
                        label   : handleFetchRisksMatrix.data.currency + " " + Number(
                            item.title
                        ).toLocaleString(
                            localStorage.getItem("i18nextLng")
                        ),
                    };
                })
            );

            // calculating matrix colors for caption
            if (handleFetchRisksMatrix.data.matrix && handleFetchRisksMatrix.data.ratings) {
                const flattedMatrix = handleFetchRisksMatrix.data.matrix.flat();
                const items = [];

                for (const item of handleFetchRisksMatrix.data.ratings) {
                    items.push({
                        label   :   item.name,
                        color   :   flattedMatrix.find((i) => i.name === item.name).color,
                    });
                }

                setTableColors(items);
            }
        }
    }, [handleFetchRisksMatrix.data]);

    useEffect(() => {
        if(handleFetchRisksOwners.data){
            setOwners(handleFetchRisksOwners.data.map((item) => {
                return {
                    value   :   item.id,
                    label   :   `${item.first_name} ${item.last_name}`,
                };
            }));
        }
    }, [handleFetchRisksOwners.data]);

    useEffect(() => {
        handleFetchRisk();
        handleFetchComments();
    }, []);

    useEffect(() => {
        if(risk){
            findAndUpdateRiskLocation();
            findAndUpdateRiskCategory();
            findAndUpdateRiskDamage();
            findAndUpdateRiskLikelihood();
            findAndUpdateRiskOwner();

            const blocksFromHTML = risk.description ? convertFromHTML(risk?.description) : '';
			const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
			setDescription(EditorState.createWithContent(state));
        }
    }, [ risk, categories, locations, damages, likelihoods, currency, tableColors, owners, t ]);

    const isEntitiesEnabled = isFeatureEnabledForActiveModule('entity_management');
    const entityRowColsSizes = isEntitiesEnabled ? {
        sm: 12,
        md: 3,
        lg: 3
    } : {
        sm: 12,
        md: 4,
        lg: 4
    };

    const canUserEdit = (supplierInfos.isFetching || supplierInfos.isLoading) ? 
        false :  
        (
            hasUserAccessToEditSupplier(user, supplierInfos.data?.assignedAnalyst.id) || 
            user.id === risk?.manager_object?.id ||
            user.id === risk?.owner_object?.id
        )

    const matrixIsLoading = 
            handleFetchRisksMatrix.isFetching ||
            handleFetchRisksMatrix.isLoading

	return (
		<>
			<CreateTaskModal
				isOpen={createTaskModalStatus}
				closeModal={() => setCreateTaskModalStatus(false)}
				owners={owners}
				initialTaskStatus={{ value: 'NEW', label: t('New') }}
				priorities={[
					{
						value: 'low',
						label: (
							<>
								<HiChevronDoubleDown className='text-info' />
								<span style={{ marginLeft: 4 }}>{t('Low')}</span>
							</>
						),
					},
					{
						value: 'medium',
						label: (
							<>
								<HiMenuAlt4 className='text-success' />
								<span style={{ marginLeft: 4 }}>{t('Medium')}</span>
							</>
						),
					},
					{
						value: 'high',
						label: (
							<>
								<HiChevronDoubleUp className='text-danger' />
								<span style={{ marginLeft: 4 }}>{t('High')}</span>
							</>
						),
					},
				]}
                isMitigation
				successCreation={() => handleFetchRisk()}
                creationRequestPayload={{
                    riskRate                :   riskId,
                    ...(taskCreationSettings?.payload || {})
                }}
			/>

			<Container fluid>
				{
                    canUserEdit && taskCreationSettings?.enabled && (
                        <Row className="mb-4">
                            <Col sm="12" className="d-flex justify-content-end align-items-center">
                                <Button
                                    color='primary'
                                    onClick={() => setCreateTaskModalStatus(true)}>
                                    {t('Create Task')}
                                </Button>
                            </Col>
                        </Row>
                    )
                }

				<Row>
					<Col lg='8' md='8' sm='12'>
						<AvForm>
							<Row className='mb-3'>
								{/* category */}
								<Col
									sm='12'
									md={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 2 : 5}
									lg={selectedCategory && selectedCategory.baseLabel.toLowerCase() === 'other' ? 2 : 5}
								>
									<Label className='form-label text-dark'>{`${t('Risk category')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<Select
											name='category'
											classNamePrefix='select2-selection'
											options={categories.map((c) => {
												return {
													value: c.value,
													baseLabel: c.baseLabel,
													label: t(c.baseLabel),
													subCategories: c.subCategories,
												};
											})}
											value={selectedCategory}
											onChange={handleChangeCategory}
											placeholder={t('Select...')}
											menuPortalTarget={document.body}
                                            isDisabled={ !canUserEdit }
										/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* other category input */}
								<Col sm='12' md={3} lg={3} hidden={!selectedCategory || (selectedCategory && selectedCategory.baseLabel.toLowerCase() !== 'other')}>
									<Label className='form-label text-dark'>{`${t('Other category')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<>
											<AvField
												errorMessage={t('This field cannot be blank')}
												className='form-control'
												name='otherCat'
												type='text'
												value={selectedCategoryOtherString}
												onChange={(e) => handleChangeOtherCategoryString(e.target.value)}
											/>
											<p className='text-danger'>{!selectedCategoryOtherString ? t(categoryOtherError) : ''}</p>
										</>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* sub category */}
								<Col
									sm='12'
									md={selectedSubCategory && selectedSubCategory.baseLabel.toLowerCase() === 'other' ? 2 : 5}
									lg={selectedSubCategory && selectedSubCategory.baseLabel.toLowerCase() === 'other' ? 2 : 5}
								>
									<Label className='form-label text-dark'>{`${t('Subcategory')}: `}</Label>
									{!loadingCategory && categories && risk ? (
										<Select
											name='categorsubcategory'
											classNamePrefix='select2-selection'
											placeholder={t('Select...')}
											options={
												selectedCategory && selectedCategory.subCategories
													? selectedCategory.subCategories.map((c) => {
															return {
																value: c.value,
																baseLabel: c.baseLabel,
																label: t(c.baseLabel),
																subCategories: c.subCategories,
															};
													  })
													: []
											}
											value={selectedSubCategory}
											onChange={handleChangeSubCategory}
											menuPortalTarget={document.body}
                                            isDisabled={!canUserEdit}
										/>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* other sub category input */}
								<Col sm='12' md={3} lg={3} hidden={!selectedSubCategory || selectedSubCategory.baseLabel.toLowerCase() !== 'other'}>
									<Label className='form-label text-dark'>{`${t('Other')}: `}</Label>

									{!loadingCategory && categories && risk ? (
										<>
											<AvField
												errorMessage={t('This field cannot be blank')}
												className='form-control'
												name='otherCat'
												type='text'
												value={selectedSubCategoryOtherString}
												onChange={(e) => handleChangeOtherSubCategoryString(e.target.value)}
											/>
											<p className='text-danger'>{!selectedSubCategoryOtherString ? t(subCategoryOtherError) : ''}</p>
										</>
									) : (
										<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
									)}
								</Col>

								{/* submit categories */}
								<Col sm='12' md={2} lg={2} className='mt-4 pt-1'>
									{categories && risk && canUserEdit ? (
										<Button style={{ width: '100%' }} color='primary' onClick={(e) => handleSubmitChangeCategory(risk.id, token)}>
											{t('Save')}
										</Button>
									) : null}
								</Col>
							</Row>
						</AvForm>

						<Row className='mt-5'>
							{/* location */}
							<Col {...entityRowColsSizes}>
								<Label className='form-label'>{`${t('location')} `}</Label>
								<br />
								{locations && risk ? (
									<Select
										name='location'
										classNamePrefix='select2-selection'
										options={locations}
										isDisabled={true}
										placeholder={t('Select...')}
										value={selectedLocation}
										menuPortalTarget={document.body}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>

							{/* involved entities */}
                            {isEntitiesEnabled && (
                                <Col {...entityRowColsSizes}>
                                    <Label className='form-label'>{`${t('Involved entities')} `}</Label>
                                    <br />
                                    {risk ? (
                                        <>
                                            <Select
                                                name='category'
                                                classNamePrefix='select2-selection'
                                                options={[]}
                                                placeholder={"..."}
                                                styles={{
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex: 100000
                                                    })
                                                }}
                                                isDisabled={true}
                                            />
                                            <div className='mt-1'>
                                            {risk?.legal_entities && risk?.legal_entities?.length > 0 ? risk?.legal_entities.map((value, index) => (
                                                <div className="tag" key={index} style={{cursor:'not-allowed'}}>
                                                    {t(value.label)}
                                                    <span className="tag-close">
                                                    &times;
                                                    </span>
                                                </div>
                                            )): null}
                                        </div>
                                    </>
                                    ) : (
                                        <div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
                                    )}
                                </Col>
                            )}

							{/* damage */}
							<Col {...entityRowColsSizes}>
								<Label className='form-label'>{`${t('Damage')} `}</Label>
								<br />
								{!loadingDamage && damages && risk ? (
									<Select
										name='damage'
										classNamePrefix='select2-selection'
										options={damages}
										placeholder={t('Select...')}
										value={selectedDamage}
										onChange={(e) => {
											handleChangeRiskDamage(e);
										}}
										menuPortalTarget={document.body}
                                        isDisabled={!canUserEdit}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list'></div>
								)}
							</Col>

							{/* likelihood */}
							<Col {...entityRowColsSizes}>
								<Label className='form-label'>{`${t('Likelihood')} `}</Label>
								<br />
								{!loadingLikelihood && likelihoods && risk ? (
									<Select
										name='likelihood'
										classNamePrefix='select2-selection'
										options={likelihoods.map((l) => {
											return {
												value: l.value,
												label: t(l.label),
											};
										})}
										placeholder={t('Select...')}
										value={selectedLikelihood}
										onChange={(e) => {
											handleChangeRiskLikelihood(e);
										}}
										menuPortalTarget={document.body}
                                        isDisabled={!canUserEdit}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list'></div>
								)}
							</Col>
						</Row>

						<Row className='mt-5'>
							<Col sm='12' md='12' lg='12'>
								{risk ? (
									<>
										<Label className='form-label'>{`${t('Description')} `}</Label>
										<br />
										<Editor
											editorState={description}
											readOnly={loadingDescription}
											toolbarClassName='toolbarClassName'
											wrapperClassName='wrapperClassName'
											editorClassName='editorClassName'
											onEditorStateChange={(e) => setDescription(e)}
											placeholder={t('Add a description...')}
											editorStyle={{
												height: '10rem',
												overflowY: 'scroll',
												borderColor: '#c9c9c9',
												paddingRight: '2em',
											}}
											toolbar={{
												options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
												inline: {
													options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
													bold: { className: 'bordered-option-classname' },
													italic: { className: 'bordered-option-classname' },
													underline: { className: 'bordered-option-classname' },
													strikethrough: {
														className: 'bordered-option-classname',
													},
													code: { className: 'bordered-option-classname' },
												},
												blockType: {
													className: 'bordered-option-classname',
												},
												fontSize: {
													className: 'bordered-option-classname',
												},
												fontFamily: {
													className: 'bordered-option-classname',
												},
											}}
										/>
										<br />
										<p className='text-danger'>{descriptionError ? t(descriptionError) : ''}</p>
									</>
								) : null}
							</Col>
						</Row>
						<br />
						<Row>
							{/* submit description */}
							{risk && canUserEdit ? (
								<Col lg='2' md='2' sm='6'>
									<Button color='primary' style={{ width: '100%', display: 'block' }} onClick={(e) => handleDescriptionSubmit()}>
										{loadingDescription ? <TbLoader /> : t('Save')}
									</Button>
								</Col>
							) : null}

							{/* attachments */}
							{risk && risk.description_attachments && risk.description_attachments.length > 0 ? (
								<Col lg='10' md='10' sm='6'>
									<DownloadAttachments 
                                        type={'risk'}
                                        showAttachments={false} 
                                        files={risk.description_attachments} 
                                    />
								</Col>
							) : null}
						</Row>
					</Col>
					<Col lg='4' md='4' sm='12'>
						<Card outline body className='border border-primary rounded' style={{ width: 'auto' }}>
							<CardHeader>{t('Details')}</CardHeader>
							<br />
							<ListGroup flush style={{ borderRadius: '0' }}>
								{/* manager */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Risk Manager')}</p>
										</Col>
										<Col>
											{risk ? (
												<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
													{risk.manager_object?.name}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* owner */}
								<ListGroupItem className='border-bottom border-primary '>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '36px' }}>{t('Risk owner')}</p>
										</Col>
										<Col>
											{!loadingOwner && owners && risk ? (
												<Select
													required
													name='owner'
													classNamePrefix='select2-selection'
													options={owners}
													isDisabled={user.id !== risk.manager_object?.id}
													value={selectedOwner}
													onChange={(e) => {
														handleChangeRiskOwner(e);
													}}
												></Select>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* risk rating */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '27px' }}>{t('Risk rating')}</p>
										</Col>
										<Col>
											{tableColors && risk ? (
												<span
													className='badge text-white rounded p-2'
													style={{
														marginBottom: 0,
														display: 'block',
														fontWeight: 'bold',
														backgroundColor: `#${tableColors.find((i) => i.label === risk.rating)?.color}`,
													}}
												>
													{t(risk.rating)}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 26 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* created at */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Created')}</p>
										</Col>
										<Col>
											{risk ? (
												<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
													{dateUtils.convertTimeStampToDate(risk.created_at, INTERNATIONAL_DATE_FORMAT)}
												</Label>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* tasks */}
								<ListGroupItem>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>{t('Risk Mitigation')}</p>
										</Col>
										<Col>
											{risk && risk.tasks ? (
												risk.tasks.map((t, i) => {
													return (
														t.status === "DELETED" ?
														<span>
															{`T${t.secondary_id}`}
															<span hidden={i === risk.tasks.length - 1}>{', '}</span>
														</span> :
														<span className='text-decoration-underline' style={{
															cursor:	'pointer',
														}} onClick={() => onMitigationTaskClicked && onMitigationTaskClicked(t.id)}>
															{`T${t.secondary_id}`}
															<span hidden={i === risk.tasks.length - 1}>{', '}</span>
														</span>
													);
												})
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
						</Card>

                        <Card 
                            outline 
                            body 
                            style={{ width: 'auto' }}
                            className={`border border-primary rounded ${risk && !risk.residualRisk?.result ? 'd-none': ''}`}
                        >
							<CardHeader>
                                {t('Residual Risk')}
                            </CardHeader>

							<ListGroup flush style={{ borderRadius: '0' }}>
								{/* Damage */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '26px' }}>
                                                {t('Damage')}
                                            </p>
										</Col>
										<Col>
											{!matrixIsLoading && risk ? (
												<span className='p-2' style={{ marginBottom: 0, display: 'block' }}>
													{handleFetchRisksMatrix?.data?.currency + " " + Number(
                                                        risk.residualRisk?.damage
                                                    ).toLocaleString(
                                                        localStorage.getItem("i18nextLng")
                                                    )}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* Likelihood */}
								<ListGroupItem className='border-bottom border-primary '>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '36px' }}>
                                                {t('Likelihood')}
                                            </p>
										</Col>
										<Col>
											{risk ? (
												<span className='p-2' style={{ marginBottom: 0, display: 'block' }}>
                                                    {t(risk.residualRisk?.likelihood)}
                                                </span>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* risk rating */}
								<ListGroupItem className='border-bottom border-primary border-0'>
									<Row>
										<Col>
											<p style={{ marginBottom: 0, lineHeight: '27px' }}>{t('Risk rating')}</p>
										</Col>
										<Col>
											{risk ? (
												<span
													className='badge text-white rounded p-2'
													style={{
														marginBottom: 0,
														display: 'block',
														fontWeight: 'bold',
														backgroundColor: `${ResidualRisksRates[risk.residualRisk?.result]?.color}`,
													}}
												>
                                                    {t(ResidualRisksRates[risk.residualRisk?.result]?.title)}
                                                </span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 26 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
						</Card>
					</Col>
				</Row>

				<CommentsComponent
					useCase={'risk'}
					id={riskId}
					owner={risk?.owner}
					manager={risk?.manager_object?.name}
					descriptionAttachments={risk?.description_attachments}
					successDeletion={(fileId) => {
						setRisk({
							...risk,
							description_attachments: risk.description_attachments.filter((f) => f.id !== fileId),
						});
					}}
					creationDate={risk ? dateUtils.convertTimestampToOnlyDate(risk.created_at) : null}
					comments={comments}
					fetchComments={() => handleFetchComments()}
					loadingComments={loadingComments}
					updateAttachmentName={(name, id) => {
						handleUpdateAttachmentName(name, id);
					}}
				/>
			</Container>
		</>
	);
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    return {
        user            :   state.Login.user,
        token
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(RiskDetails))
);