import * as React from 'react'
import { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

const Tabs = ({ t, user }) => {
    const history = useHistory()
    const location = useLocation()
    const roles = user?.roles || []

    const [activeTab, setActiveTab] = useState('')

    const isActiveTab = (currentTab, tab) => currentTab === tab

    const tabsLinks = [
        {
            key: 'overview',
            title: 'Overview'
        },
        {
            key: 'my-risks',
            title: 'My Risks'
        }
    ]

    if (roles?.includes('ROLE_ANALYST_ADMIN')) {
        tabsLinks.splice(1, 0, {
            key: 'all-risks',
            title: 'All Risks'
        })
        tabsLinks.push({
            key: 'mitigation-tasks',
            title: 'Mitigation Task'
        })
    }

    useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab')

        if (!tab) {
            history.push({
                search: '?tab=overview'
            })
        } else {
            setActiveTab(tab)
        }
    }, [location.search])

    return (
        <div
            style={{
                backgroundColor: '#D1D2D6'
            }}
        >
            <Nav tabs className='custom-style-1'>
                {tabsLinks.map((link) => {
                    return (
                        <NavItem
                            className={
                                isActiveTab(activeTab, link.key) ? 'active' : ''
                            }
                            key={link.key}
                        >
                            <NavLink
                                className={
                                    isActiveTab(activeTab, link.key)
                                        ? 'active'
                                        : ''
                                }
                                onClick={() =>
                                    history.push({
                                        search: '?tab=' + link.key
                                    })
                                }
                            >
                                {t(link.title)}
                            </NavLink>
                        </NavItem>
                    )
                })}
            </Nav>
        </div>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.Login.user
    }
}

export default withNamespaces()(connect(mapStatetoProps, {})(memo(Tabs)))
