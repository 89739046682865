import axios from 'axios';

import {
    API_BASE_URL
} from '../../constants/index';

export default class OwnThirdpartyUsersService {
    static instance;
    resource = `${API_BASE_URL}`;

    static getInstance() {
        if (!OwnThirdpartyUsersService.instance) {
            OwnThirdpartyUsersService.instance = new OwnThirdpartyUsersService();
        }

        return OwnThirdpartyUsersService.instance;
    }

    async list(params = {}){
        const response = await axios.get(`${this.resource}/organization/own_companies`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });

        return response.data.data
    } 
}
