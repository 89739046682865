import axios from 'axios'
export default class RiskManagementService {
    static instance

    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    async fetchOverview(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_management/overview`,
            {
                params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || {} // Assuming data is structured similarly
    }

    async fetchRiskDetails(riskId, params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_rate/${riskId}/show`,
            {
                params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || {}
    }

    async fetchAllRisks(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_management/all`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchMitigationTasks(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_management/mitigation_tasks`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchMyRisks(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_management/my_risks`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data?.data || []
    }

    async fetchOwners(payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/analystAndAdminAnalyst.list`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async fetchManagers(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/manager.list`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async fetchCategories(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_rate_category/index`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async fetchLocations(payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/report.country.list`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async fetchMatrix(params = {}) {
        const response = await axios.get(
            `${this.baseUrl}/risk_rate/matrix`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateRiskCategory(riskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/risk_rate/${riskId}/change_category`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateRiskDamage(riskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/risk_rate/${riskId}/update_damage`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateRiskLikelihood(riskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/risk_rate/${riskId}/update_likelihood`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateRiskOwner(riskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/risk_rate/${riskId}/assign_analyst`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async updateRiskDescription(riskId, payload = {}) {
        const response = await axios.post(
            `${this.baseUrl}/risk_rate/${riskId}/edit`,
            payload,
            {
                params: {},
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }

    async deleteRisk(riskId) {
        const response = await axios.delete(
            `${this.baseUrl}/risk_rate/${riskId}/delete`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
                    )}`
                }
            }
        )

        return response.data.data
    }
}
