import {
    ACTION_EDIT_INFO_ORGANIZATION,
    ACTION_EDIT_INFO_ORGANIZATION_SUCCESS,
    ACTION_EDIT_INFO_ORGANIZATION_FAILURE,
    ACTION_GET_INFO_ORGANIZATION_FAILURE,
    ACTION_GET_INFO_ORGANIZATION_SUCCESS,
    ACTION_REPLACE_MODULE_CUSTOM_INFOS,
    ACTION_GET_INFO_ORGANIZATION
} from './actionTypes';

export const getInfoOrganization = (organization) => ({
    type        :   ACTION_GET_INFO_ORGANIZATION,
    payload     :   organization
});

export const editInfoOrganization = (logo, intro, name) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION,
    payload: {logo, intro, name}
});

export const onEditInfoOrganizationSuccess = (response) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION_SUCCESS,
    payload: response
});

export const onEditInfoOrganizationFailure = (error) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION_FAILURE,
    payload: error
});

export const onGetInfoOrganizationFailure = (error) => ({
    type: ACTION_GET_INFO_ORGANIZATION_FAILURE,
    payload: error
});

export const onGetInfoOrganizationSuccess = (organizationInfo) => ({
    type: ACTION_GET_INFO_ORGANIZATION_SUCCESS,
    payload: organizationInfo
});

export const replaceModuleCustomInfos = (organizationInfo) => ({
    type: ACTION_REPLACE_MODULE_CUSTOM_INFOS,
    payload: organizationInfo
});