import { memo, useCallback, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    Row,
    Col
} from 'reactstrap'
import { 
    ModulesActions
} from 'src/modules/risk-management/drivers'
import { connect } from 'react-redux'

const RiskMatrix = function (props) {
    const { matrix, consequences, likelihoods, t, Modules } = props
    const {
        riskIdGenerator
    } = ModulesActions[Modules.active] || {
        riskIdGenerator: ({ risk }) => {
            return risk?.secondaryId
        }
    }
    const history = useHistory()

    const [colsDrops, setColdsDrops] = useState([])

    const toggleColDrop = (colId) => {
        setColdsDrops((drops) => {
            const newDrops = { ...drops }
            newDrops[colId] = !newDrops[colId]
            return newDrops
        })
    }

    const renderRisksDrop = (colId, risks) => {
        return risks?.length ? (
            <Dropdown
                direction='left'
                isOpen={!!colsDrops[colId]}
                toggle={() => toggleColDrop(colId)}
            >
                <DropdownToggle className='else-risks-drop-btn'>
                    <i className='ri-arrow-down-s-line' />
                </DropdownToggle>
                <DropdownMenu>
                    {risks.map((risk, index) => {
                        return (
                            <DropdownItem
                                onClick={() =>
                                    history.push(
                                        `/admin/risk-management/${risk.id}/details?incomming_tab=overview`
                                    )
                                }
                                key={index}
                            >
                                {riskIdGenerator({
                                    t,
                                    risk
                                })}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        ) : null
    }

    const renderRiskBullets = useCallback((risks) => {
        const result = risks.map((r, index) => (
            <span key={index} className='dt-matrix-risk-bullet' />
        ))

        if (risks.length) {
            result.push(
                <div key={21} className='dt-matrix-risk-bullet-else'>
                    {risks.length}
                </div>
            )
        }

        return result
    }, [])

    const isMobile = window.matchMedia('(max-width: 768px)').matches

    return (
        <Container>
            <h5 className='mb-4'>{t('Risk Matrix')}</h5>
            <Row>
                <Col>
                    {matrix && consequences && likelihoods ? (
                        <table className='risk-managment-matrix '>
                            <tbody>
                                {matrix ? (
                                    <tr>
                                        <td />
                                        <td />
                                        <td
                                            className='dt-matrix-x-axis-label py-1'
                                            colSpan={matrix.length}
                                        >
                                            <span className='fw-normal'>
                                                {t('Consequence')}
                                            </span>
                                        </td>
                                    </tr>
                                ) : null}

                                {/* consequences */}
                                {consequences ? (
                                    <tr>
                                        <td />
                                        <td />
                                        {consequences.map((i) => (
                                            <td
                                                key={i.id}
                                                className='dt-matrix-info dt-matrix-x-axis py-0'
                                            >
                                                <div className='py-1'>
                                                    <span
                                                        hidden={isMobile}
                                                        className='dt-matrix-x-axis-value '
                                                        style={{
                                                            fontSize: '13px',
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        {'> ' +
                                                            (
                                                                Number(
                                                                    i?.title
                                                                ) / 1000
                                                            ).toLocaleString(
                                                                'en-US'
                                                            ) +
                                                            'K'}
                                                    </span>
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ) : null}

                                {matrix
                                    ? matrix.map((row, rowIndex) => {
                                          return (
                                              <tr key={row.rowIndex}>
                                                  {rowIndex === 0 ? (
                                                      <td
                                                          style={{
                                                              textAlign:
                                                                  'center',
                                                              position:
                                                                  'relative'
                                                          }}
                                                          className='dt-matrix-y-axis-label'
                                                          rowSpan={
                                                              matrix.length + 1
                                                          }
                                                      >
                                                          <span
                                                              hidden={isMobile}
                                                              style={{
                                                                  display:
                                                                      'block',
                                                                  transform:
                                                                      'rotate(270deg)',
                                                                  fontWeight: 500,
                                                                  position:
                                                                      'absolute',
                                                                  left: '-78px',
                                                                  width: '200px',
                                                                  height: '30px'
                                                              }}
                                                          >
                                                              {t('Likelihood')}
                                                          </span>
                                                      </td>
                                                  ) : null}

                                                  {/* likelihoods */}
                                                  {likelihoods ? (
                                                      <>
                                                          <td
                                                              // style={{ width: '6%' }}
                                                              className='dt-matrix-y-axis'
                                                          >
                                                              <span className='dt-matrix-y-axis-value'>
                                                                  {'> ' +
                                                                      likelihoods[
                                                                          rowIndex
                                                                      ].label +
                                                                      '%'}
                                                              </span>
                                                          </td>
                                                      </>
                                                  ) : null}

                                                  {/* columns */}
                                                  {row.map((col, colIndex) => {
                                                      return (
                                                          <td
                                                              key={col.id}
                                                              style={{
                                                                  backgroundColor: `#${col.color}`,
                                                                  width: '156px'
                                                              }}
                                                              className='dt-matrix-column'
                                                          >
                                                              <div>
                                                                  {col?.risks &&
                                                                  col?.risks
                                                                      ?.length >
                                                                      0
                                                                      ? renderRiskBullets(
                                                                            col.risks
                                                                        )
                                                                      : null}
                                                              </div>
                                                              <span>
                                                                  {col?.risks &&
                                                                      renderRisksDrop(
                                                                          col.id,
                                                                          col.risks
                                                                      )}
                                                              </span>
                                                          </td>
                                                      )
                                                  })}
                                              </tr>
                                          )
                                      })
                                    : null}
                            </tbody>
                        </table>
                    ) : (
                        <table className='risk-managment-matrix'>
                            <tbody>
                                {/* x axis label */}
                                <tr>
                                    <td />
                                    <td />
                                    <td
                                        className='dt-matrix-x-axis-label'
                                        colSpan='5'
                                    >
                                        <div>
                                            <span className='fw-normal'>
                                                {t('Consequence')}
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            width: '4%',
                                            position: 'relative'
                                        }}
                                        className='dt-matrix-y-axis-label'
                                        rowSpan='6'
                                    >
                                        <span
                                            hidden={isMobile}
                                            style={{
                                                display: 'block',
                                                transform: 'rotate(270deg)',
                                                fontWeight: 500,
                                                position: 'absolute',
                                                left: '-78px',
                                                width: '200px',
                                                height: '30px'
                                            }}
                                        >
                                            {t('Likelihood')}
                                        </span>
                                    </td>
                                    <td
                                        style={{ width: '6%' }}
                                        className='dt-matrix-y-axis'
                                    >
                                        <div>
                                            <span className='dt-matrix-y-axis-value dt-skeleton' />
                                        </div>
                                    </td>

                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                </tr>

                                <tr>
                                    <td
                                        style={{ width: '6%' }}
                                        className='dt-matrix-y-axis'
                                    >
                                        <div>
                                            <span className='dt-matrix-y-axis-value dt-skeleton' />
                                        </div>
                                    </td>
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                </tr>
                                <tr>
                                    <td
                                        style={{ width: '6%' }}
                                        className='dt-matrix-y-axis'
                                    >
                                        <div>
                                            <span className='dt-matrix-y-axis-value dt-skeleton' />
                                        </div>
                                    </td>
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                </tr>
                                <tr>
                                    <td
                                        style={{ width: '6%' }}
                                        className='dt-matrix-y-axis'
                                    >
                                        <div>
                                            <span className='dt-matrix-y-axis-value dt-skeleton' />
                                        </div>
                                    </td>

                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                </tr>
                                <tr>
                                    <td
                                        style={{ width: '6%' }}
                                        className='dt-matrix-y-axis'
                                    >
                                        <div>
                                            <span className='dt-matrix-y-axis-value dt-skeleton' />
                                        </div>
                                    </td>

                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                    <td
                                        style={{ width: '16%' }}
                                        className='dt-matrix-column dt-skeleton'
                                    />
                                </tr>
                            </tbody>
                        </table>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

const mapToState = (state) => {
    const { Modules } = state

    return {
        Modules
    }
}

export default withNamespaces()(
    connect(mapToState)(
        memo(RiskMatrix)
    )
)
