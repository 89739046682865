import { withNamespaces } from 'react-i18next';
import { memo, useEffect, useRef, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { hasUserAccessToModule, isFeatureEnabledForActiveModule } from 'src/helpers/module_helper';
import { connect } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { hasUserAccessToEditSupplier } from 'src/modules/3rd-party-management/helpers/users';

const LoadingComponent = () => {
    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <div>
                <Nav tabs className="custom-style-1">
                    <NavItem className='active'>
                        <NavLink className='active'>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem> 
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                    <NavItem>
                        <NavLink>
                            <div className="dt-field dt-skeleton"></div>
                        </NavLink>
                    </NavItem>  
                </Nav>
            </div>
        </div>
    )
}

const Tabs = memo(({
    t, 
    modules, 
    isLoading, 
    supplierData,
    onActiveTabChange,
    user
}) => {
    const navBarRef = useRef();
    const scrollBarRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [ activeTab, setActiveTab ] = useState("");
    const [ navbarWidth, setNavbarWidth ] = useState('0px');
    const [ tabsToShow, setTabsToShow ] = useState([]);

    const tabsShouldBeHidden = [
        'scoring',
        'related-incidents'
    ];

    const ownCompanyTabItems = [
        {
            key     :   "overview",
            title   :   "Overview"
        },
        {
            key     :   "basic-information",
            title   :   "Basic Information"
        },
        {
            key     :   "products",
            title   :   "Products"
        },
        {
            key     :   "findings",
            title   :   "Findings",
            feature :   "news_monitoring"
        },
        {
            key     :   "reports",
            title   :   "Reports"
        },
        {
            key     :   "bafa-questionnaire",
            title   :   "Report Details"
        },
        {
            key     :   "related-incidents",
            title   :   "Related Incidents"
        },
        {
            key     :   "internal-communication",
            title   :   "Communication"
        },
        {
            key     :   "risk-assessment",
            title   :   "Risk Assessment"
        },
        {
            key     :   "task-manager",
            title   :   "Task Management"
        },
        {
            key     :   "history-log",
            title   :   "History Log"
        }
    ];

    const creditorTabItems = [
        {
            key     :   "overview",
            title   :   "Overview"
        },
        {
            key     :   "basic-information",
            title   :   "Basic Information"
        },
        {
            key     :   "scoring",
            title   :   "Scoring"
        },
        {
            key     :   "products",
            title   :   "Products"
        },
        {
            key     :   "findings",
            title   :   "Findings",
            feature :   "news_monitoring"
        },
        {
            key     :   "related-incidents",
            title   :   "Related Incidents"
        },
        {
            key     :   "reports",
            title   :   "Reports"
        },
        {
            key     :   "communication",
            title   :   "External Communication"
        },
        {
            key     :   "internal-communication",
            title   :   "Internal Communication"
        },
        {
            key     :   "risk-assessment",
            title   :   "Risk Assessment"
        },
        {
            key     :   "task-manager",
            title   :   "Task Management"
        },
        {
            key     :   "history-log",
            title   :   "History Log"
        }
    ];

    const readOnlyAnalystTabItemsForOwn = [
        {
            key     :   "basic-information",
            title   :   "Basic Information"
        },
        {
            key     :   "products",
            title   :   "Products"
        },
        {
            key     :   "risk-assessment",
            title   :   "Risk Assessment"
        },
        {
            key     :   "task-manager",
            title   :   "Task Management"
        },
        {
            key     :   "reports",
            title   :   "Reports"
        },
        {
            key     :   "bafa-questionnaire",
            title   :   "Report Details"
        }
    ]

    const readOnlyAnalystTabItemsForCreditor = [
        {
            key     :   "basic-information",
            title   :   "Basic Information"
        },
        {
            key     :   "products",
            title   :   "Products"
        },
        {
            key     :   "risk-assessment",
            title   :   "Risk Assessment"
        },
        {
            key     :   "task-manager",
            title   :   "Task Management"
        }
    ]

    const getNavWidth = () => {
        if(navBarRef && navBarRef.current){
            return ((navBarRef.current.querySelector('.nav'))?.scrollWidth || 0) + 'px';
        }
        return '0px';
    }

    useEffect(() => {
        setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
        if(!(new URLSearchParams(location.search)).get('tab')){
            return history.push({
                search : '?tab=overview'
            })
        }

        let timeoutId;

        const resizeEventHandler = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                scrollBarRef.current.updateScroll();
            }, 500);
        };

        window.addEventListener('resize', resizeEventHandler);

        return () => {
            clearTimeout(timeoutId);

            window.removeEventListener('resize', resizeEventHandler);
        }
    }, []);

    useEffect(() => {
        if(hasUserAccessToModule('wb') || hasUserAccessToModule('cm')){
            tabsShouldBeHidden.splice(tabsShouldBeHidden.findIndex((tabToHide) => tabToHide === 'related-incidents'), 1)
        }
        
        if(supplierData && modules.featuresLoadingStatus === 'loaded' && user){
            if(hasUserAccessToEditSupplier(user, supplierData.assignedAnalyst?.id)){
                if(parseInt(supplierData.score || 0) < 10){
                    tabsShouldBeHidden.splice(tabsShouldBeHidden.findIndex((tabToHide) => tabToHide === 'scoring'), 1)
                }

                if(supplierData.isOwnCompany){
                    setTabsToShow(ownCompanyTabItems.filter((link) => {
                        return (
                            !tabsShouldBeHidden.includes(link.key) && 
                            (!link.feature || isFeatureEnabledForActiveModule(link.feature))
                        )
                    }));
                }
                else{
                    setTabsToShow(creditorTabItems.filter((link) => {
                        return (
                            !tabsShouldBeHidden.includes(link.key) && 
                            (!link.feature || isFeatureEnabledForActiveModule(link.feature))
                        )
                    }));
                }
            }
            else{
                const readOnlyTabs = supplierData.isOwnCompany ? readOnlyAnalystTabItemsForOwn : readOnlyAnalystTabItemsForCreditor;
                setTabsToShow(readOnlyTabs.filter((link) => {
                    return (
                        !tabsShouldBeHidden.includes(link.key) && 
                        (!link.feature || isFeatureEnabledForActiveModule(link.feature))
                    )
                }));
            }
        }
    }, [ 
        supplierData, 
        modules.list,
        modules.featuresLoadingStatus,
        user
    ]);

    useEffect(() => {
        setNavbarWidth(getNavWidth());
    }, [ tabsToShow ]);

    useEffect(() => {
        if(!isLoading && activeTab && tabsToShow.length > 0){
            const tab = tabsToShow.find((tab) => tab.key === activeTab);

            if(tab){
                onActiveTabChange && onActiveTabChange({ tab });
            }
            else{
                history.push({
                    search : `?tab=${tabsToShow[0].key}`
                });
            }
        }
    }, [
        activeTab,
        tabsToShow,
        isLoading
    ]);

    if(modules.featuresLoadingStatus === 'loading' || isLoading || !user){
        return (
            <LoadingComponent />
        )
    }

    if(!supplierData){
        return null;
    }

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <PerfectScrollbar ref={scrollBarRef}>
                <div ref={navBarRef} style={{
                    width: navbarWidth
                }}>
                    <Nav tabs className="custom-style-1">
                        {
                            tabsToShow.map((link) => {
                                return (
                                    <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                                        <NavLink
                                            className={isActiveTab(activeTab, link.key) ? 'active': ''}
                                            onClick={() => history.push({
                                                search : '?tab=' + link.key
                                            })}>
                                            {t(link.title)}
                                        </NavLink>
                                    </NavItem>
                                )
                            })
                        }   
                    </Nav>
                </div>
            </PerfectScrollbar>
        </div>
	);
});

const mapStatetoProps = (state) => {
    const { Modules } = state;
    const { user } = state.Login;

    return {
        modules  :   Modules,
        user
    };
};

export default withNamespaces()(connect(
    mapStatetoProps,
    {}
)(Tabs));
