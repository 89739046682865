import { withNamespaces } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import IndustriesService from "src/modules/3rd-party-management/apis/common/IndustriesService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter,
    Label,
    Alert
} from "reactstrap";
import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import Spinner from 'react-bootstrap/Spinner';
import SupplierService from "src/modules/3rd-party-management/apis/common/SupplierService";

const EditSupplierGeneralInfosModal = ({
    t,
    lng,
    supplierId,
    closeModal,
    onComplete
}) => {
    const editGeneralInfosFormRef = useRef();

    const [ showIndustryDeletionError, setShowIndustryDeletionError ] = useState(null);
    const [ selectedIndustries, setSelectedIndustries ] = useState([]);  
    const [industriesQuery, setIndustriesQuery] = useState({
        isLoading: false,   
        itemsCount: 0,
        pageIndex: 1,
        pageSize: 1000,
        searchText: '',
        items: []
    });

    const {
        refetch     :   refetchIndustries
    } = useQuery({
        queryKey: [
            '3rd-party-management-search-industries-query',
            lng
        ],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            setIndustriesQuery((currentState) => {
                return {
                    ...currentState,
                    isLoading: true
                }
            });

            return await service.fetchList({
                language: lng !== 'en' ? lng : undefined,
                pageSize: industriesQuery.pageSize,
                pageIndex: industriesQuery.pageIndex,
                name: industriesQuery.searchText || undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            toast(t('An error occurred while fetching industries list.'), {
                type: 'error',
            });
        },
        onSuccess: (response) => {
            setIndustriesQuery((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    // itemsCount: parseInt(response.itemsCount),
                    // pageIndex: parseInt(response.pageIndex),
                    // pageSize: parseInt(response.pageSize),
                    items: (response || []).map((industry) => {
                        return {
                            value : industry.id,
                            label : `${industry.class} - ${industry.title}`,
                            keywords: industry?.keywords || []
                        }
                    })
                }
            });
        },
        onSettled: () => {
            setIndustriesQuery((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });
        }
    });

    const {
        data        :   selectedSupplierToEditInfos,
        refetch     :   fetchSupplierInfos,
        isFetching  :   supplierInfosIsFetching
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos-modal', supplierId],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: () => {
			toast(t('An error occurred while fetching the basic information.'), {
				type: 'error',
			});
		}
	});

    const {
        mutate      :   updateSupplierGeneralInfosMutation,
        isLoading   :   updateSupplierGeneralInfosMutationInProcess   
    } = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.updateGeneralInfos(payload);
        },
        onSuccess: () => {
            toast(t("General information updated successfully."), {
                type: "success",
            });
            onComplete && onComplete();
            closeModal();
        },
        onError: () => {
            toast(t("An error occurred while updating General information."), {
                type: "error",
            });
        }
    });

    const handleValidateIndustryDeletion = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.validateIndustryDeletion(payload);
        }
    });

    useEffect(() => {
        if(supplierId){
            refetchIndustries();
            fetchSupplierInfos();
        }
    }, [supplierId]);

    useEffect(() => {
        if(selectedSupplierToEditInfos?.industries){
            const result = (selectedSupplierToEditInfos.industries || []).map((selectedIndustry) => {
                const translatedIndustry = (industriesQuery.items || []).find((industry) => industry.value === selectedIndustry.id);
        
                return {
                    value: selectedIndustry.id,
                    label: translatedIndustry?.label || selectedIndustry.name,
                    baseLabel: translatedIndustry?.baseLabel,
                    keywords: translatedIndustry?.keywords
                }
            });
        
            setSelectedIndustries(result);
        }
    }, [ selectedSupplierToEditInfos ]);

    useEffect(() => {
        if(selectedIndustries.length){
            const result = selectedIndustries.map((selectedIndustry) => {
                const translatedIndustry = (industriesQuery.items || []).find((industry) => industry.value === selectedIndustry.value);

                return translatedIndustry || selectedIndustry;
            });
        
            setSelectedIndustries(result);
        }
    }, [
        t,
        industriesQuery.items
    ]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            refetchIndustries();
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [ industriesQuery.searchText ]);

    const MultiValueRemove = (props) => {
        const isLoading = props.data.isLoading; 
        return (
            <components.MultiValueRemove {...props}>
                {isLoading && <Spinner color="secondary" size="sm" />}
            </components.MultiValueRemove>
        );
    };

    const handleChangeIndsutries = (selectedOptions, e) => {
        const industryInput = editGeneralInfosFormRef.current._inputs['industries'];
        const selectedValues = selectedOptions.map(option => String(option.value));
        industryInput.value = selectedValues; // Assigning the array directly
        industryInput.validate();

        if(e.action === 'remove-value'){
            if(!e.removedValue.isNew){
                setSelectedIndustries((currentState) => {
                    return [
                        ...currentState.map((industry) => {
                            return {
                                ...industry,
                                isLoading: industry.value === e.removedValue.value ? true : !!(industry.isLoading)
                            }
                        })
                    ]
                });

                handleValidateIndustryDeletion.mutate({
                    supplier    :   supplierId,
                    industry    :    e.removedValue.value
                }, {
                    onSettled: () => {
                        setSelectedIndustries((currentState) => {
                            return [
                                ...currentState.map((industry) => {
                                    return {
                                        ...industry,
                                        isLoading: false
                                    }
                                })
                            ]
                        });
                    },
                    onError: ({ response: { data } }) => {
                        setShowIndustryDeletionError(data.error)
                    },
                    onSuccess: () => {
                        setSelectedIndustries((currentState) => {
                            return [
                                ...currentState.filter((industry) => industry.value !== e.removedValue.value)
                            ]
                        });
                    }
                });
            }
            else{
                setSelectedIndustries((currentState) => {
                    return [
                        ...currentState.filter((industry) => industry.value !== e.removedValue.value)
                    ]
                });
            }
        }
        else{
            setSelectedIndustries((currentState) => {
                return [
                    ...selectedOptions.map((industry) => {
                        const isNew = e.option.value === industry.value ? true : !!(industry.isNew)
                        return {
                            ...((currentState || []).find((i) => i.value === industry.value)),
                            ...industry,
                            isNew: isNew
                        }
                    })
                ]
            });
        }
    }

    const sizes = [];

    for(const sizeKey in CompanySizes){
        sizes.push({
            value : sizeKey,
            label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
        });
    }

    return (
        <>
            <Modal isOpen={ supplierId } backdrop="static" keyboard={ false } size="xl">   
                <AvForm ref={ editGeneralInfosFormRef } onValidSubmit={(e, values) => {
                    updateSupplierGeneralInfosMutation({
                        supplier    :   supplierId,
                        generalInfo :   values
                    });
                }} className="needs-validation m-2">
                    <ModalHeader toggle={closeModal}>
                        {t("General Information")}
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-name-input">
                                    {t("Company Name")}
                                </Label>
                                {supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <AvField
                                        name="name"
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        id="edit-general-infos-name-input"
                                        defaultValue={selectedSupplierToEditInfos?.name}
                                    />
                                )}
                            </Col>

                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-shortname-input">
                                    {t("Short Name")}
                                </Label>
                                {supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <AvField
                                        name="shortName"
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: false }
                                        }}
                                        id="edit-general-infos-shortname-input"
                                        defaultValue={selectedSupplierToEditInfos?.shortName}
                                    />
                                )}
                            </Col>

                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-brands-input">
                                    {t("Brands")}
                                </Label>
                                {supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <AvField
                                        name="brands"
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: false }
                                        }}
                                        id="edit-general-infos-brands-input"
                                        defaultValue={selectedSupplierToEditInfos?.brands}
                                    />
                                )}
                            </Col>

                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-industry-input">
                                    {t("Industries")}
                                </Label>
                                {
                                    supplierInfosIsFetching ? (
                                        <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                    ) : (
                                        <>
                                            <Select
                                                isClearable={false}
                                                isDisabled={true}
                                                isMulti
                                                isLoading={ industriesQuery.isLoading }
                                                placeholder={t("Select") + "..."}
                                                classNamePrefix="select2-selection"
                                                id='edit-general-infos-industry-input'
                                                options={ industriesQuery.items }
                                                menuPortalTarget={ document.body }
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                onChange={ handleChangeIndsutries }
                                                components={{ MultiValueRemove }}
                                                filterOption={(e) => e}
                                                value={selectedIndustries}
                                                onInputChange={(input) => {
                                                    setIndustriesQuery((currentState) => {
                                                        return {
                                                            ...currentState,
                                                            searchText: input
                                                        }
                                                    })
                                                }}
                                            />
                                            <AvField
                                                name="industries"
                                                type="hidden" 
                                                errorMessage={t("This field cannot be blank")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={(selectedIndustries || []).map((industry) => industry.value.toString())}
                                            />
                                        </>
                                    )
                                }
                            </Col>

                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-company-size-input">
                                    {t("Company Size")}
                                </Label>
                                {supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <>
                                        <Select
                                            placeholder={t("Select") + "..."}
                                            classNamePrefix="select2-selection"
                                            id='edit-general-infos-company-size-input'
                                            options={ sizes }
                                            menuPortalTarget={ document.body }
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(e) => {
                                                const sizeInput = editGeneralInfosFormRef.current._inputs['companySize'];
                                                if(sizeInput){
                                                    sizeInput.value = e.value;
                                                    sizeInput.validate();
                                                }
                                            }}
                                            defaultValue={selectedSupplierToEditInfos?.employeeSize && {
                                                value   :   selectedSupplierToEditInfos.employeeSize,
                                                label   :   CompanySizes[selectedSupplierToEditInfos.employeeSize]?.title
                                            }}
                                        />
                                        <AvField
                                            name="companySize"
                                            type="hidden" 
                                            errorMessage={t("This field cannot be blank")}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            defaultValue={ selectedSupplierToEditInfos?.employeeSize }
                                        />
                                    </>
                                )}
                                
                            </Col>

                            <Col md="6" sm="12" lg="4" className="mb-3">
                                <Label for="edit-general-infos-website-input">
                                    {t("Website")}
                                </Label>
                                {supplierInfosIsFetching ? (
                                    <p style={{width: '100%', height: '34px'}} className={`dt-field dt-skeleton`}></p>
                                ) : (
                                    <AvField
                                        name="websiteUrl"
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                            pattern: {
                                                value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                                                errorMessage: t("Please enter a valid URL"),
                                            },
                                        }}
                                        id="edit-general-infos-website-input"
                                        defaultValue={selectedSupplierToEditInfos?.websiteUrl}
                                    />
                                )}
                            </Col>
                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={closeModal} color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button disabled={ updateSupplierGeneralInfosMutationInProcess } color="primary" type="submit" size="md">
                            {
                                updateSupplierGeneralInfosMutationInProcess ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <i className="ri-pencil-line align-middle me-2"></i>
                                )
                                
                            }
                            { t('Update') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>

            <Modal isOpen={ showIndustryDeletionError } backdrop="static" keyboard={ false } size="lg">   
                <ModalHeader toggle={ () => setShowIndustryDeletionError(null) }>
                    <div className="d-flex align-items-center justify-content-start">
                        {t('Deletion Error')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <Alert color="danger" className="d-flex justify-content-start">
                        <div className="d-flex align-items-center me-2">
                            <i className="ri-close-circle-line" style={{fontSize: '32px'}}/>
                        </div>
                        <div className="d-flex align-items-start justify-content-center flex-column">
                            <strong>{t('Error')}</strong>
                            {t("In order to remove the desired industry, the following products must be removed from the supplier's product list")}
                        </div>
                    </Alert>
                        <ul>
                            {(showIndustryDeletionError?.products || []).map((p) => <li className="mb-2">{`${p.class} - ${t(p.name)}`}</li>)}
                        </ul>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => setShowIndustryDeletionError(null)} color="primary" size="md">
                        {t('Close')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default withNamespaces()(EditSupplierGeneralInfosModal);