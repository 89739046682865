import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import TableFilter from "src/components/TableFilter";
import SupplierRiskAssessmentService from "src/modules/3rd-party-management/apis/admin/SupplierRiskAssessmentService";
import DateUtils from "src/services/utils/DateUtils";

const FilteringComponent = ({
    t,
    owners,
    countries,
    onChange
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        dateFrom: null,
        dateTo: null,
        manager: null,
        owner: null,
        supplierIds: null,
        companyIds: null,
        country: null,
        risk_id: null,
        legal_entity: null,
        category: null
    });

    const [ subCategories, setSubCategories ] = useState({
        category: null,
        items: []
    })

    const initRef = useRef(false);

    const dateUtils = new DateUtils();
    
    const riskService = SupplierRiskAssessmentService.getInstance()

    const handleFetchRisksManagers = useQuery({
        queryKey: [
            '3rd-party-management-module-fetch-managers'
        ],
        queryFn: async () => {
            return await riskService.fetchManagers()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching managers.'), {
                type: 'error'
            })
        }
    })

    const {
        isFetching: ratingsAreFetching,
        data: ratings
    } = useQuery({
        queryKey: [
            '3rd-party-management-module-fetch-ratings-list-query'
        ],
        queryFn: async () => {
            return await riskService.fetchRatings()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching risk ratings.'), {
                type: 'error',
            });
        }
    })

    const {
        data: categories,
        isFetching: categoriesAreFetching
    } = useQuery({
        queryKey: ['3rd-party-management-module-fetch-risks-categories'],
        queryFn: async () => {
            return await riskService.fetchCategories()
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching categories.'), {
                type: 'error',
            });
        }
    })

    const tableFilterTabs = useMemo(() => {
        return [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Creation Date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters?.dateFrom,
                    to: tableFilters?.dateTo
                }
            },
            {
                type: 'radio_list',
                name: 'risk_id',
                title: 'Risk rating',
                icon: <i className="ri-bar-chart-line" />,
                isLoading: ratingsAreFetching,
                defaultValue: tableFilters?.risk_id,
                data: ratings,
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return t(item.name) 
                }
            },
            {
                type: 'radio_list',
                name: 'owner',
                title: 'Owner',
                icon: <i className="ri-user-line" />,
                isLoading: owners.isFetching,
                defaultValue: tableFilters?.owner,
                data: owners.data || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.first_name + ' ' + item.last_name
                }
            },
            {
                type: 'radio_list',
                name: 'manager',
                title: 'Manager',
                icon: <i className="ri-user-settings-fill" />,
                isLoading:
                    handleFetchRisksManagers.isLoading ||
                    handleFetchRisksManagers.isFetching,
                defaultValue: tableFilters?.manager,
                data: handleFetchRisksManagers.data || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.full_name
                }
            },
            {
                type: 'radio_list',
                name: 'category',
                title: 'Category',
                icon: <i className="ri-filter-3-line" />,
                isLoading: categoriesAreFetching,
                defaultValue: tableFilters?.category,
                data: categories || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                onFilterChange: (selectedCategroy) => {
                    setSubCategories((currentState) => {
                        return {
                            ...currentState,
                            category: selectedCategroy
                        }
                    })
                },
                formatter: (item) => {
                    return t(item.name)
                }
            },
            {
                type: 'radio_list',
                name: 'sub_category',
                title: 'Sub Category',
                icon: <i className="ri-list-settings-line" />,
                isLoading: categoriesAreFetching,
                defaultValue: tableFilters?.sub_category,
                data: subCategories.items || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return t(item.name)
                }
            },
            {
                type: 'radio_list',
                name: 'location',
                title: 'Country',
                icon: <i className="ri-global-line" />,
                isLoading: countries.isFetching,
                defaultValue: tableFilters?.location,
                data: countries.data || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.name
                }
            }
        ]
    }, [
        tableFilters,

        owners,
        handleFetchRisksManagers,

        countries,

        ratingsAreFetching,
        ratings,

        categories,
        categoriesAreFetching,

        subCategories,

        t
    ])

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('filter-date' in filters) {
                result.dateFrom = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD'
                )

                result.dateTo = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            if ('owner' in filters) {
                result.owner =
                    filters.owner !== 'all' ? filters.owner : undefined
            }

            if ('manager' in filters) {
                result.manager =
                    filters.manager !== 'all' ? filters.manager : undefined
            }

            if ('location' in filters) {
                result.location =
                    filters.location !== 'all' ? filters.location : undefined
            }

            if ('risk_id' in filters) {
                result.risk_id =
                    filters.risk_id !== 'all' ? filters.risk_id : undefined
            }

            if ('category' in filters) {
                result.category =
                    filters.category !== 'all' ? filters.category : undefined
            }

            if ('sub_category' in filters) {
                result.sub_category =
                    filters.sub_category !== 'all' ? filters.sub_category : undefined
            }

            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                dateFrom: null,
                dateTo: null,
                owner: null,
                manager: null,
                location: null,
                risk_id: null,
                category: null,
                sub_category: null
            })
        }
    }

    useEffect(() => {
        if(categories && subCategories.category){
            setSubCategories((currentState) => {
                const newState = {...currentState}
                newState.items = (categories.find((cat) => cat.id === subCategories.category))?.sub_categories || []
                return newState
            })
        }
    }, [ 
        categories,
        subCategories.category
    ])

    useEffect(() => {
        if(!initRef.current){
            initRef.current = true;
            return;
        }

        onChange && onChange(tableFilters)
    }, [ 
        tableFilters,
        onChange
    ]);

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='filter-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

export default withNamespaces()(FilteringComponent);