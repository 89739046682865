import { createRef } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { RiInformationLine } from "react-icons/ri";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    UncontrolledAlert
} from "reactstrap";

const CropperModal = ({
    t,
    image,
    onCancel,
    onDone
}) => {
    const cropperRef = createRef()

    const handleCropFile = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            
            cropper.getCroppedCanvas().toBlob((blob) => {
                if (blob) {
                    onDone(
                        new File([blob], "cropped-image.jpg", { type: "image/jpeg" })
                    )
                }
            }, "image/jpeg");
        }
        else{
            toast(t("Failed to load the image editor. Please try again or refresh the page."), {
                type: "error"
            });
        }
    }

    return (
        <Modal
            isOpen={ !!image }
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <ModalHeader>
                {t("Crop & Upload Image")}
            </ModalHeader>
            <ModalBody>
                <UncontrolledAlert 
                    dis
                    color="info" 
                    className="d-flex justify-content-start"
                >
                    <div className="d-flex align-items-center me-2">
                        <RiInformationLine size='32' className="me-2" />
                    </div>
                    <div className="d-flex align-items-start justify-content-center flex-column">
                        <strong>
                            {t('Usage Note')}
                        </strong>
                        {t('Adjust the image within the fixed crop area to ensure the best fit for the landing page. Once satisfied, click Done to continue.')}
                    </div>
                </UncontrolledAlert>

                <Cropper
                    ref={cropperRef}
                    style={{ height: 450, width: "100%" }}
                    zoomTo={0}
                    src={image ? URL.createObjectURL(image) : null}
                    aspectRatio={16 / 5}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                    guides={true}
                    viewMode={1}
                    scalable={false}
                    cropBoxResizable={false}
                    cropBoxMovable={true}
                    dragMode="none"
                    autoCropArea={true}
                />
            </ModalBody>

            <ModalFooter>
                <Button 
                    color="danger"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>

                <Button 
                    color="primary"
                    onClick={handleCropFile}
                >
                    {t('Done')}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default withNamespaces()(
    CropperModal
);
