import { withNamespaces } from "react-i18next";
import { Alert, Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { connect } from 'react-redux';
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import { EMPTY_LIST } from "src/common/constants";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";

const ManagementTeam = ({
    t,
    Organization,
    people,
    onTeamChange,
    readOnly = false
}) => {
    const [ showPersonModal, setShowPersonModal ] = useState(false);

    const [ personToDelete, setPersonToDelete ] = useState(null);

    const [ editingPersonValues, setEditingPersonValues ] = useState(null);

    const [ personTitle, setPersonTitle ] = useState({
        show    :   false,
        value   :   'Mr.'
    });

    const avFormRef = useRef();

    const getLanguageName = (id) => t(
        ((Organization?.languages || []).find((lng) => lng?.language?.id === id))?.name
    )

    const togglePersonModal = () => setShowPersonModal(!showPersonModal);

    const handleCreationModalSubmit = (e, values) => {
        if(editingPersonValues && editingPersonValues.personId){
            const newTeam = people.map((item) => {
                if(item.id === editingPersonValues.personId){
                    return {
                        ...values.person,
                        id: editingPersonValues.personId,
                        isNew: !!editingPersonValues.isNew
                    }
                }
                else{
                    return {...item}
                }
            });

            onTeamChange && onTeamChange(newTeam);
        }
        else{
            onTeamChange && onTeamChange([
                ...people,
                {
                    ...values.person,
                    id: `rand-id-${Date.now()}-${Math.random()}`,
                    isNew: true
                }
            ]);
        }

        togglePersonModal();
    }

    const handlePersonDelete = () => {
        onTeamChange && onTeamChange(people.filter((item) => {
            return item.id !== personToDelete.id
        }));

        setPersonToDelete(null);
    }

    const showTeamPersonEditingForm = (person) => {
        setPersonTitle({
            show    :   false,
            value   :   person.title
        });

        setEditingPersonValues({
            'person[title]'         :   person.title,
            'person[name]'          :   person.name,
            'person[position]'      :   person.position,
            'person[language]'      :   person.language,
            'person[email]'         :   person.email,
            'person[phoneNumber]'   :   person.phoneNumber,
            'personId'              :   person.id,
            'isNew'                 :   !!person.isNew
        });
    }

    const renderCreateAndEditModals = () => {
        const languages = (Organization?.languages || []).map((lng) => {
            return {
                value   :   lng?.language?.id,
                label   :   t(lng.name),
                code    :   lng.language_code,
            }
        });

        return (
            <Modal isOpen={ showPersonModal } onClosed={() => {
                editingPersonValues && setEditingPersonValues(null);
            }} backdrop="static" keyboard={ false } size="xl" className="contact-person-creation-modal">
                
                <AvForm ref={ avFormRef } onValidSubmit={ handleCreationModalSubmit } className="needs-validation m-2" model={ editingPersonValues }>
                    <ModalHeader toggle={ togglePersonModal }>
                        {t(editingPersonValues?.personId ? "Edit Person" : "Add A New Person")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="new-team-person-name">
                                    {t("Name")}
                                </Label>

                                <div className="d-flex flex-wrap position-relative">
                                    <ButtonDropdown className="position-absolute" 
                                        isOpen={ personTitle.show } 
                                        toggle={() => {
                                            setPersonTitle((currentState) => {
                                                return {
                                                    ...currentState,
                                                    show    :   !currentState.show
                                                }
                                            });
                                        }} 
                                        style={{
                                            left: "0px",
                                            top: "0px",
                                            width: "74px",
                                            height: "33px"
                                        }}>
                                            <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                                <span style={{marginRight: '8px'}}>
                                                    { t(personTitle.value || 'Mr.') }
                                                </span>
                                            </DropdownToggle>
                                            
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { 
                                                    setPersonTitle((currentState) => {
                                                        return {
                                                            ...currentState,
                                                            value   :   'Mr.'
                                                        }
                                                    });
                                                }}>
                                                    { t('Mr.') }
                                                </DropdownItem>

                                                <DropdownItem onClick={() => { 
                                                    setPersonTitle((currentState) => {
                                                        return {
                                                            ...currentState,
                                                            value   :   'Mrs.'
                                                        }
                                                    });
                                                }}>
                                                    { t('Mrs.') }
                                                </DropdownItem>

                                                <DropdownItem onClick={() => { 
                                                    setPersonTitle((currentState) => {
                                                        return {
                                                            ...currentState,
                                                            value   :   'Mx.'
                                                        }
                                                    });
                                                }}>
                                                    { t('Mx.') }
                                                </DropdownItem>
                                            </DropdownMenu>
                                    </ButtonDropdown>

                                    <AvInput 
                                        id={`person-title`}
                                        name={`person[title]`}
                                        type="hidden"
                                        value={ personTitle.value || 'Mr.'}
                                    />

                                    <AvField
                                        id="new-team-person-name"
                                        name={`person[name]`}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        style={{
                                            paddingLeft: '85px'
                                        }}
                                        groupAttrs={{
                                            className: 'w-100'
                                        }}
                                    />
                                </div>
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-position">
                                    {t("Position")}
                                </Label>
                                <AvField
                                    name="person[position]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="new-team-person-position"
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-language">
                                    {t("Language")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='new-team-person-language'
                                    options={ languages }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={ (e) => {
                                        const languageInput = avFormRef.current._inputs['person[language]'];
                                        languageInput.value = e.value;
                                        languageInput.validate();
                                    } }
                                    defaultValue={editingPersonValues && {
                                        value   :   editingPersonValues['person[language]'],
                                        label   :   getLanguageName(editingPersonValues['person[language]'])
                                    }}
                                />
                                <AvField
                                    name="person[language]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="4" sm="12">
                                <Label for="new-team-person-email">
                                    {t("Email")}
                                </Label>
                                <AvField
                                    name="person[email]"
                                    type="email"
                                    className="form-control"
                                    validate={{
                                        required: {
                                            value: false,
                                            errorMessage: t("This field cannot be blank"),
                                        },
                                        email: { errorMessage: t("Your email is invalid") },
                                    }}
                                    id="new-team-person-email"
                                />
                            </Col>

                            <Col md="4" sm="12">
                                <Label for="new-team-person-phone-number">
                                    {t("Phone Number")}
                                </Label>

                                <LocalizedPhoneInput
                                    inputClass="w-100"
                                    inputStyle={{
                                        height  :   '38px'
                                    }}
                                    country={ "us" }
                                    disableInitialCountryGuess={ false }
                                    disableCountryGuess={ false }
                                    onChange={ (e) => {
                                        const input = avFormRef.current?._inputs['person[phoneNumber]'];
                                        if(input){
                                            input.value = e;
                                            input.validate();
                                        }
                                    }}
                                    value={ editingPersonValues && editingPersonValues['person[phoneNumber]'] }
                                />

                                <AvField
                                    name={`person[phoneNumber]`}
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: false }
                                    }}
                                    id={`new-team-person-phone-number`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ togglePersonModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button onClick={ () => avFormRef.current.submit() } color="primary" type="button" size="md">
                            {
                                editingPersonValues?.personId ? (
                                    <i className="ri-pencil-line align-middle me-2"></i>
                                ) : (
                                    <i className="ri-add-line align-middle me-2"></i>
                                )
                            }
                            { t(editingPersonValues?.personId ? 'Update Person' : 'Add Person') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    const renderDeletePersonConfirmModal = () => {
        return (
            <Modal isOpen={ !!personToDelete } backdrop="static" size="md">
                
                <ModalHeader toggle={() => setPersonToDelete(null)}>
                    {t("Confirmation Form")}
                </ModalHeader>

                <ModalBody>
                    <p>
                        {t('Do you want to delete the person?')}
                    </p>
                </ModalBody>

                <ModalFooter>
                    <Button  
                        onClick={handlePersonDelete} 
                        color="danger"
                        type="button"
                        size="md"
                    >
                        { t('Delete') }
                    </Button>

                    <Button onClick={() => setPersonToDelete(null)} color="secondary" type="button" outline>
                        { t('Cancel') }
                    </Button>
                </ModalFooter>
            </Modal>
        ) 
    }

    useEffect(() => {
        if(editingPersonValues){
            setShowPersonModal(true);
        }
    }, [ editingPersonValues ]);

    useEffect(() => {
        if(!showPersonModal){
            // reset title on modal close
            setPersonTitle({
                show    :   false,
                value   :   'Mr.'
            })
        }
    }, [showPersonModal]);

    return (
        <div className="contact-people pt-0">
            <div className="list">
                {!people.length && (
                    <Alert color='warning'>
                        {t(EMPTY_LIST)}
                    </Alert>
                )}

                {people.map((person) => {
                    return (
                        <div className="item mb-3">
                            <Row className="mb-2">
                                <Col sm="12" md="4">
                                    <Label className="form-label">
                                        {t('Full Name')}:
                                    </Label>
                                    <p>
                                        { `${t(person.title)} ${person.name}` }
                                    </p>
                                </Col>

                                <Col sm="12" md="4">
                                    <Label className="form-label">
                                        {t('Position')}:
                                    </Label>
                                    <p>
                                        { person.position }
                                    </p>
                                </Col>

                                <Col sm="12" md="4">
                                    <Label className="form-label">
                                        {t('Language')}:
                                    </Label>
                                    <p>
                                        { t(getLanguageName(person.language)) }
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="4">
                                    <Label className="form-label">
                                        {t('Email')}:
                                    </Label>
                                    <p>
                                        { person.email }
                                    </p>
                                </Col>

                                <Col sm="12" md="4">
                                    <Label className="form-label">
                                        {t('Phone Number')}:
                                    </Label>
                                    <LocalizedPhoneInput
                                        readOnly={true}
                                        inputClass="w-100"
                                        country={ "us" }
                                        disableInitialCountryGuess={ false }
                                        disableCountryGuess={ false }
                                        value={person.phoneNumber}
                                    />
                                </Col>
                            </Row>

                            {!readOnly && (
                                <Row>
                                    <Col sm="12" className="d-flex justify-content-end">
                                        <Button 
                                            onClick={() => {
                                                showTeamPersonEditingForm(person)
                                            }} 
                                            color="primary" type="button" className="me-2" outline>
                                                <i className="ri-pencil-line me-1 align-middle font-size-18" />
                                                {t('Edit Person')}
                                        </Button>

                                        <Button 
                                            onClick={() => {
                                                setPersonToDelete(person)
                                            }} 
                                            color="danger" type="button" outline>
                                                <i className="ri-delete-bin-line me-1 align-middle font-size-18" />
                                                {t('Delete Person')}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )
                })}
            </div>

            {
                !readOnly && (
                    <>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={() => { setShowPersonModal(true) }} 
                                color="primary" 
                                outline>
                                    <i className="ri-add-line align-middle me-2 font-size-20"></i>
                                    {t('Add A New Person')}
                            </Button>
                        </div>
                        { renderCreateAndEditModals() }
                        { renderDeletePersonConfirmModal() }
                    </>
                )
            }
        </div>
    );
}

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(connect(
    mapStatetoProps,
    {}
)(ManagementTeam));