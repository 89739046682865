export const ROUTE_PATH_ADMIN_ROOT = "/admin/3rd-party";
export const ROUTE_PATH_THIRDPARTY_ROOT = "/third-party/3rd-party";

/**
 * admin routes
 */
export const ROUTE_PATH_ADMIN_DASHBOARD= ROUTE_PATH_ADMIN_ROOT + "/dashboard";
export const ROUTE_PATH_ADMIN_THIRD_PARTIES= ROUTE_PATH_ADMIN_ROOT + "/manager";
export const ROUTE_PATH_ADMIN_OWN_COMPANY_THIRD_PARTIES= ROUTE_PATH_ADMIN_ROOT + "/manager/own";
export const ROUTE_PATH_ADMIN_HOME= ROUTE_PATH_ADMIN_THIRD_PARTIES;
export const ROUTE_PATH_ADMIN_LIST_REPORTS= ROUTE_PATH_ADMIN_ROOT + "/reports";
export const ROUTE_PATH_ADMIN_KANBAN_BOARD= ROUTE_PATH_ADMIN_ROOT + "/kanban-board";
export const ROUTE_PATH_ADMIN_VIEW_DOCUMENTS= ROUTE_PATH_ADMIN_ROOT + "/documents";
export const ROUTE_PATH_ADMIN_SETTINGS= ROUTE_PATH_ADMIN_ROOT + "/administration/settings";
export const ROUTE_PATH_ADMIN_CUSTOMIZATION= ROUTE_PATH_ADMIN_ROOT + "/administration/customization";
export const ROUTE_PATH_ADMIN_USER_LIST= ROUTE_PATH_ADMIN_ROOT + "/administration/users";
export const ROUTE_PATH_ADMIN_QUESTIONNAIRES= ROUTE_PATH_ADMIN_ROOT + "/administration/questionnaires";
export const ROUTE_PATH_ADMIN_QUESTIONNAIRE_DESIGNER= ROUTE_PATH_ADMIN_ROOT + "/administration/questionnaires/:slug";
export const ROUTE_PATH_ADMIN_DOCUMENTS= ROUTE_PATH_ADMIN_ROOT + "/administration/documents";
export const ROUTE_PATH_ADMIN_HISTORY_LOGS= ROUTE_PATH_ADMIN_ROOT + "/administration/history-logs";
export const ROUTE_PATH_ADMIN_FAQ= ROUTE_PATH_ADMIN_ROOT + "/faq";
export const ROUTE_PATH_ADMIN_USER_MANUAL= ROUTE_PATH_ADMIN_ROOT + "/user_manual";

/**
 * thirdparty routes
 */
export const ROUTE_PATH_THIRDPARTY_HOME                 =   ROUTE_PATH_THIRDPARTY_ROOT + "/home";

export const ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE   =   ROUTE_PATH_THIRDPARTY_ROOT + "/questioner";

export const ROUTE_PATH_THIRD_PARTY_REPORT_LIST         =   ROUTE_PATH_THIRDPARTY_ROOT + "/reports";

export const ROUTE_PATH_THIRD_PARTY_BASIC_INFOS         =   ROUTE_PATH_THIRDPARTY_ROOT + "/basic-information";

export const ROUTE_PATH_THIRD_PARTY_REPLACE_CONTACT_PERSON         =   ROUTE_PATH_THIRDPARTY_ROOT + "/replace-contact-person";

export const ROUTE_PATH_THIRD_PARTY_REPORT_DETAILS      =   ROUTE_PATH_THIRDPARTY_ROOT + "/reports/:id";

/*
* common
*/