import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'

const DeletionFileModal = function (props) {
    const { t, isOpen, acceptDeletion, close, fileId } = props

    return (
        <React.Fragment>
            <Modal size='lg' scrollable isOpen={isOpen} backdrop='static'>
                <ModalHeader toggle={() => close()}>
                    {t('Delete file')}
                </ModalHeader>

                <ModalBody>
                    <AvForm className='needs-validation'>
                        <Row>
                            <Label>{t('Are you sure?')}</Label>
                        </Row>

                        <ModalFooter>
                            <Button
                                color='danger'
                                className='waves-effect waves-light'
                                type='submit'
                                onClick={() => {
                                    acceptDeletion(fileId)
                                }}
                            >
                                {t('Delete').toUpperCase()}
                            </Button>
                            <Button
                                color='secondary'
                                className='waves-effect waves-light'
                                type='button'
                                onClick={() => {
                                    close()
                                }}
                            >
                                {t('Cancel').toUpperCase()}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withNamespaces()(DeletionFileModal)
