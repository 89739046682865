/* eslint-disable no-undef */
import { withNamespaces } from 'react-i18next'
import { Component } from 'react'
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Spinner,
    UncontrolledDropdown
} from 'reactstrap'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import { 
    ModulesApiRoutes
} from 'src/modules/risk-management/drivers'
import { AiFillCaretDown } from 'react-icons/ai'
import { connect } from 'react-redux'

class DownloadAttachments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinner: {
                id: '',
                active: false
            },
            dropdown: {
                id: '',
                active: false
            },
            availableFiles: [],
            toggleDropDown: false
        }

        this.apiRotues = ModulesApiRoutes[props.Modules.active]

        this.attachFile()
    }

    handleClickDownloadAttch = (id, name) => {
        this.setState({
            spinner: {
                id: `download-file-${id}`,
                active: true
            }
        })
        this.handleDownload(id, name)
    }

    handleDownload = (id, name) => {
        const url = `${this.apiRotues.API_BASE_URL}/task/attachment/${id}/download_file`

        const token = `Bearer ${localStorage.getItem(
            'LOCAL_STORAGE_KEY_ACCESS_TOKEN'
        )}`

        name.split('.').pop()
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.setRequestHeader('Authorization', token)
        xhr.responseType = 'arraybuffer'
        xhr.onload = function (e) {
            if (this.status === 200) {
                var blob = new Blob([this.response], { type: '*' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = name
                link.click()
            }
        }
        xhr.send()

        this.setState({
            spinner: {
                id: '',
                active: false
            }
        })
    }

    attachFile = () => {
        const { files } = this.props
        const { availableFiles } = this.state
        if (files) {
            files.map((file) => {
                availableFiles.push({
                    value: file.id,
                    title: file.origin_name
                })
            })
        }
    }

    toggle = () => this.setState({ toggleDropDown: this.state.toggleDropDown })

    render() {
        const {
            t,
            showAttachments,
            deletionModalActivated,
            onDeletionModalClosed,
            onUploadModalChange,
            onDeletionModalChange,
            comment,
            commentSection,
            files
        } = this.props
        return (
            <>
                <div
                    hidden={showAttachments}
                    style={{ textAlign: commentSection ? 'right' : 'left' }}
                >
                    <div
                        style={{
                            display: 'inline-flex',
                            position: 'relative',
                            textAlign: 'left'
                        }}
                    >
                        <UncontrolledDropdown>
                            <DropdownToggle className='bg-primary' caret>
                                {t('Download')} <AiFillCaretDown />
                            </DropdownToggle>
                            <DropdownMenu>
                                {files.map((item, index) => (
                                    <DropdownItem
                                        text
                                        key={`download-file-${index}`}
                                    >
                                        <div
                                            className='d-flex'
                                            style={{
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <span
                                                onClick={() =>
                                                    this.handleClickDownloadAttch(
                                                        item.id,
                                                        item.origin_name
                                                    )
                                                }
                                                style={{
                                                    // width: "100%",
                                                    cursor: 'pointer'
                                                    // userSelect: "none",
                                                    // fontSize: 14,
                                                }}
                                            >
                                                {this.props.t(item.origin_name)}
                                            </span>
                                            {this.state.spinner.id ===
                                                `download-file-${index}` &&
                                                this.state.spinner.active && (
                                                    <Spinner
                                                        size='sm'
                                                        color='primary'
                                                    />
                                                )}
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/* </Dropdown> */}
                    </div>
                </div>

                {/** ************************ ONLY ATTACHMENTS **************************** */}
                <div hidden={!showAttachments} style={{ textAlign: 'left' }}>
                    <div
                        style={{
                            // display: "inline-flex",
                            position: 'relative',
                            borderBottom: '1px solid #c9c9c9'
                        }}
                    >
                        <ul>
                            {files.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        // style={{ width: "48rem" }}
                                    >
                                        <Row
                                            style={{
                                                backgroundColor:
                                                    index % 2 === 0
                                                        ? '#dee2e6'
                                                        : 'white'
                                            }}
                                        >
                                            <Col sm='12' md='7' lg='7'>
                                                <span
                                                    className='text-primary'
                                                    onClick={() =>
                                                        this.handleClickDownloadAttch(
                                                            item.id,
                                                            item.origin_name
                                                        )
                                                    }
                                                    style={{
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        fontSize: 14
                                                    }}
                                                >
                                                    {t(item.origin_name)}
                                                </span>
                                            </Col>
                                            <Col sm='12' md='5' lg='5'>
                                                <span
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    className='text-primary'
                                                    onClick={() =>
                                                        onUploadModalChange(
                                                            item,
                                                            comment
                                                        )
                                                    }
                                                >
                                                    <a>
                                                        {' '}
                                                        {t(
                                                            'Update this version'
                                                        )}
                                                    </a>
                                                </span>
                                                <span>{' | '}</span>
                                                <span
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    className='text-danger'
                                                    onClick={() =>
                                                        onDeletionModalChange(
                                                            item,
                                                            comment
                                                        )
                                                    }
                                                >
                                                    {t('Delete')}
                                                </span>
                                            </Col>
                                        </Row>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                {/** ************************* DELETE CONFIRMATION MODAL ***************************************/}
                <DeleteConfirmationModal
                    t={t}
                    isFile
                    modalActivated={deletionModalActivated}
                    onModalClosed={onDeletionModalClosed}
                />
            </>
        )
    }
}

const mapToState = (state) => {
    const { Modules } = state

    return {
        Modules
    }
}

export default withNamespaces()(
    connect(mapToState)(
        DownloadAttachments
    )
)
